import {reportScheduleDefault} from "../../hooks/useProjectStatusReportOperations";

export const REPORT_SCHEDULE_ACTIONS = {
    RESET: 'reset',
    SET_TYPE: 'setType',
    SET_PERIODICITY: 'setPeriodicity',
    SET_FREQUENCY: 'setFrequency',
    SET_WEEK_DAY: 'setWeekDay',
    SET_MONTH_DAY: 'setMonthDay',
}

export const reportScheduleInitialState  = {
    ...reportScheduleDefault,
}

export function projectStatusReportScheduleReducer(state, action) {
    switch (action.type) {
        case REPORT_SCHEDULE_ACTIONS.RESET:
            return action.payload;
        case REPORT_SCHEDULE_ACTIONS.SET_TYPE:
            return {
                ...state,
                ...reportScheduleInitialState,
                type: action.payload,
            };
        case REPORT_SCHEDULE_ACTIONS.SET_PERIODICITY:
            return {
                ...state,
                periodicity: action.payload,
            };
        case REPORT_SCHEDULE_ACTIONS.SET_FREQUENCY:
            return {
                ...state,
                frequency: action.payload,
            };
        case REPORT_SCHEDULE_ACTIONS.SET_WEEK_DAY:
            return {
                ...state,
                week_day: action.payload,
            };
        case REPORT_SCHEDULE_ACTIONS.SET_MONTH_DAY:
            return {
                ...state,
                month_day: action.payload,
            };
        default:
            throw Error('Unknown action.');
    }
}
