import './queriesMenu.css';
import { Collapse, Menu, Button, message, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
const { Panel } = Collapse;
import QueryEditorPage from "../queryEditorPage/queryEditorPage";
import selectedTabContentState from "../../atoms/selectedTabContent";
import {useRecoilState} from "recoil";
import queryEditorSelectedQueryState from "../../atoms/queryEditorSelectedQuery";
import {useEffect, useState} from "react";
import QueryEditItem from "../queryEditItem/queryEditItem";
import QueryItem from "../queryItem/queryItem";
import deletedQueryState from "../../atoms/deletedQuery";
import openedDebugQueriesState from "../../atoms/openedDebugQueries";
import queryUpdatedState from "../../atoms/queryUpdated";
import forceMappingUpdateState from "../../atoms/forceMappingUpdate";
import isQueryCreatedState from "../../atoms/isQueryCreated";
import {RolesMapper, scanSubTypes} from "../../constants";
import {useLocation, useNavigate, useParams} from "react-router-dom";

const QueriesMenu = ({queries, topic, apiClient, orgId, onCreate, loadQueries, total, scanType, isVioletXOnly, loading, disabled}) => {

    const {pathname} = useLocation();
    const navigate = useNavigate();
    const {summary} = useParams();
    const [, setSelectedTabContent] = useRecoilState(selectedTabContentState);
    const [, setDeletedQuery] = useRecoilState(deletedQueryState);
    const [queryToEdit, setQueryToEdit] = useState(null);
    const [queryEditorSelectedQuery, setQueryEditorSelectedQuery] = useRecoilState(queryEditorSelectedQueryState);
    const [openedDebugQueries, setOpenedDebugQueries] = useRecoilState(openedDebugQueriesState);
    const [, setQueryUpdated] = useRecoilState(queryUpdatedState);
    const [isQueryCreated, setIsQueryCreated] = useRecoilState(isQueryCreatedState);
    const [forceQueryUpdate, setForceQueryUpdate] = useRecoilState(forceMappingUpdateState);
    const [isDisabled, setIsDisabled] = useState(true);

    let lastDeletedQuery = null;

    useEffect(() => {
        setIsDisabled((scanType === scanSubTypes.SYSTEM) || (scanType === scanSubTypes.VIOLET_X && !isVioletXOnly) || disabled);
    })

    useEffect(async () => {
        if (isQueryCreated) {
            setIsQueryCreated(false);
            await addQuery();
        }
    },[isQueryCreated]);

    useEffect(() => {
        if (queryEditorSelectedQuery && queryEditorSelectedQuery !== summary) {
            const newPathName = pathname.split('/').slice(0, -1).join('/');
            navigate(`${newPathName}/${queryEditorSelectedQuery.replace('opened-query-', '')}`);
        }
    },[queryEditorSelectedQuery])

    useEffect(async () => {
        if (!pathname.includes('summary') || !pathname.includes('preview')) {
            const queryId = pathname.split("/").slice(-1)[0];
            const query = queries.find((q) => q.id === +queryId);
            if (query) {
                setSelectedTabContent({page: <QueryEditorPage
                        deletedQuery={lastDeletedQuery}
                        scanType={scanType}
                        isVioletXOnly={isVioletXOnly}
                        scanTopic={topic.toUpperCase()}
                        api={apiClient}
                        query={query}
                        orgId={orgId}
                        loadQueries={loadQueries}
                        onSaveQuery={saveQuery}
                    />});
                setActiveQuery(`opened-query-${queryId}`);
                await openQuery(query);
            }
        }
    },[pathname]);

    const getOpenedQueriesState = (updatedOpenedDebugQueries) => {
        return JSON.parse(JSON.stringify(updatedOpenedDebugQueries ?? openedDebugQueries));
    }

    const saveQuery = async (query, name, openedQueriesDebug) => {
        try {
            const queryToEdit = Object.assign({}, query);
            queryToEdit.name = name;
            const params = Object.assign({}, queryToEdit);
            delete params.id;
            delete params.distributedQueryId;
            await apiClient.get().queriesApi.queryUpdate(queryToEdit.id, params);
            await apiClient
                .get()
                .distributedQueriesApi
                .distributedQueryPatch(
                    [`id:${queryToEdit.distributedQueryId}`],
                    {
                        name: queryToEdit.name,
                        queryId: queryToEdit.id
                    }
                );
            await loadQueries();
            const openedQueries = getOpenedQueriesState(openedQueriesDebug);
            const index = openedQueries.queries.findIndex(q => q.query.id === queryToEdit.id);
            openedQueries.queries[index].query.name = queryToEdit.name;
            openedQueries.queries[index].label = queryToEdit.name;
            setOpenedDebugQueries(openedQueries);
            setQueryUpdated(queryToEdit);
            message.success('Query has been successfully updated');
        }
        catch(err) {
            console.log(err)
            message.error('Unable to update query');
        }
    }

    const deleteQuery = async (query) => {
        try {
            const queryToDelete = await apiClient.get().queriesApi.queryGetById(query.id);
            const params = JSON.parse(JSON.stringify(queryToDelete.data));
            params.active = false;
            delete params.id;
            await apiClient.get().queriesApi.queryUpdate(query.id, params);
            await loadQueries();
            setDeletedQuery(query);
            message.success('Query has been successfully deleted');
        } catch (err) {
            console.log(err);
            await message.error('Unable to delete query');
        }
    }

    const setActiveQuery = (queryKey) => {
        setQueryEditorSelectedQuery(queryKey);
    }

    const onEdit = (q) => {
        setQueryToEdit(q);
    }

    const addQuery = async (e) => {
        e?.stopPropagation();
        const createdQuery = await onCreate();
        setSelectedTabContent({page: <QueryEditorPage onSaveQuery={saveQuery} scanTopic={topic.toUpperCase()} api={apiClient} query={createdQuery} orgId={orgId} />});
        setActiveQuery(`opened-query-${createdQuery.id}`);
        await openQuery(createdQuery);
    }

    const getDistributedQuery = async (query) => {
        const distributedQueryId = query.distributedQueryId;
        const distributedQuery = await apiClient.get().distributedQueriesApi.distributedQueryGetById(distributedQueryId);
        return distributedQuery.data;
    }

    const loadDataIfNeeded = async (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            await loadQueries(null, true);
        }
    }

    const openQuery = async (queryToAdd) => {
        const queries = [...openedDebugQueries.queries];
        if (queries.some(q => q.key === `opened-query-${queryToAdd.id}`)) {
            return;
        }
        const queriesState = getOpenedQueriesState();
        const distQuery = await getDistributedQuery(queryToAdd);
        const currentQuery = {
            query: queryToAdd,
            distQuery: distQuery,
            label: queryToAdd.name,
            key: `opened-query-${queryToAdd.id}`,
            children: null
        };
        queries.push(currentQuery);
        queriesState.activeKey = currentQuery.key;
        queriesState.queries = queries;
        setOpenedDebugQueries(queriesState);
    }

    const getQueriesMenu = () => {
        const queryTab = [];
        queries.forEach((q) => {
            const tabKey = `opened-query-${q.id}`;
            const label = queryToEdit && queryToEdit.id === q.id ?
                <QueryEditItem query={q} onCancelEdit={() => setQueryToEdit(null)} onSaveTitle={saveQuery}/>
                : <QueryItem disabled={isDisabled} query={q} onDelete={deleteQuery} onEdit={onEdit} />
            const queryInfo = {
                "data-testid": `tab-query-${q.id}`,
                key: tabKey,
                title: q.name,
                label: label,
                disabled: false,
                onClick: async () => {
                    const state = getOpenedQueriesState();
                    const isAdded = state.queries.find(o => o.query.id === q.id);
                    if (forceQueryUpdate !== q.id) {
                        setForceQueryUpdate(null);
                    }
                    if (!isAdded) {
                        await openQuery(q);
                    }
                    setSelectedTabContent({page: <QueryEditorPage
                            deletedQuery={lastDeletedQuery}
                            scanType={scanType}
                            isVioletXOnly={isVioletXOnly}
                            scanTopic={topic.toUpperCase()}
                            api={apiClient}
                            query={q}
                            orgId={orgId}
                            loadQueries={loadQueries}
                            onSaveQuery={saveQuery}
                        />});
                    setActiveQuery(tabKey);
                }
            }
            queryTab.push(queryInfo);
        });

        return <Menu
            mode="inline"
            items={queryTab}
            selectedKeys={[queryEditorSelectedQuery]}
        >
        </Menu>
    }

    const genExtra = () => {
        return <Button disabled={isDisabled} icon={ <PlusOutlined />} onClick={addQuery} style={{ width: '24px', height: '24px'}} data-testid="add-query-button" />
    }

    return !loading ? <div data-testid="queries-menu" className="queries-menu-panel" onScrollCapture={loadDataIfNeeded}>
        <Collapse
            defaultActiveKey={['queries-menu-header']}
            expandIconPosition={'start'}
            key="queries-menu-panel"
            bordered={false}
            style={{
                backgroundColor: '#FFFFFF'
            }}
        >
            <Panel key="queries-menu-header" className="queries-menu" header={`Queries (${total})`} extra={genExtra()}>
                {getQueriesMenu()}
            </Panel>
        </Collapse>
    </div> : <div className="device-summary-spin" data-testid="device-summary-spin">
        <Spin size="large" />
    </div>
}

export default QueriesMenu;