import { StockOutlined, ScanOutlined, ProjectOutlined} from '@ant-design/icons';
import {useEffect, useState} from "react";
import {useNavigate, useParams, useLocation, Outlet } from "react-router-dom";
import './integrationPage.css'
import { Menu } from 'antd';

export default function IntegrationsPage() {
    const navigate = useNavigate();
    const {orgId} = useParams();
    const location = useLocation();

    const tabs = [
        { "data-testid": 'tab-assets-scanners', title: 'Scanners', key:'scanners', label: 'Scanners', icon: <ScanOutlined />, disabled: false, onClick: () => { onTabSelect('scanners')}},
        { "data-testid": 'tab-assets-analysis', title: 'Analysis', key:'analysis-wrapper', label: 'Analysis', icon: <StockOutlined />, disabled: true},
        { "data-testid": 'tab-assets-project', title: 'Project management', key:'project-wrapper', label: 'Project management', icon: <ProjectOutlined />, disabled: true},
    ];

    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState(['scanners']);

    const onTabSelect = (tab) => {
            navigate(`/clients/${orgId}/integrations/${tab}`);
    }

    useEffect(() => {
        const path = location.pathname.split('/');
        const selectedTab = tabs.find((t) => path.includes(t.key)) ?? tabs[0];
        setDefaultSelectedKeys([selectedTab.key]);
    },[location.pathname]);

    return <>
        <h3 className={"page-title"}>Integrations</h3>
        <div className="integrations-wrapper">
            <Menu
                items={tabs}
                defaultSelectedKeys={defaultSelectedKeys}
                mode="inline"
            />
            <Outlet/>
        </div>
    </>
}
