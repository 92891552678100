import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Keycloak from 'keycloak-js';
import KeycloakOptions from './keycloakOptions';
import ApiClient from "./apiClient";

import reportWebVitals from './reportWebVitals';

export const keycloakConfig = {
    url: process.env.REACT_APP_KEYCLOAK_OPTIONS_URL,
    realm: process.env.REACT_APP_KEYCLOAK_OPTIONS_REALM,
    clientId: process.env.REACT_APP_KEYCLOAK_OPTIONS_CLIENT_ID,
}

export const KeycloakLoginOptions = {
    redirectUri: window.location.origin + '/projects'
}

export const keycloakInitOption = {
    pkceMethod: 'S256',
    enableLogging: false,
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/internal/silent-check-sso.htm',
};

const REFRESH_CHECK_FREQUENCY = 60;
let accessScope;

export default class AuthenticationService {

    keycloak;
    apiClient;
    isAuthenticated = false;
    refreshTokenRate = 0;

    constructor(apiClient) {
        this.keycloak = new Keycloak(keycloakConfig);
        this.apiClient = apiClient;
    }

    init = async () => {
        this.isAuthenticated = await this.keycloak.init(keycloakInitOption);
        return this.process(this.isAuthenticated);
    }

    login = async () => {
        return this.keycloak.login(KeycloakLoginOptions);
    }

    logout = async () => {
        return this.keycloak.logout();
    }

    process = async (isAuthenticated) => {
        if (isAuthenticated) {
            await this.storeToken();
            this.refreshTokenRate = this.getRefreshRate();
                await this.defineAccess();
        }
    }

    defineAccess = async () => {
        const response = await this.apiClient.get().authApi.authUser();
        accessScope = response.data;
    }

    storeToken = async () => {
        localStorage.removeItem('react-token');
        localStorage.removeItem('react-refresh-token');
        localStorage.setItem('react-token', this.keycloak.token);
        localStorage.setItem('react-refresh-token', this.keycloak.refreshToken);
    }

    refreshToken = async () => {
        return this.keycloak.updateToken(REFRESH_CHECK_FREQUENCY * KeycloakOptions.updateTokenMinValidity);
    }

    getRefreshRate = () => {
        const tokenExp = parseInt(this.keycloak.tokenParsed.exp);
        const dateNow = new Date().getTime() / 1000;
        return (parseInt((tokenExp - dateNow).toString()) * 1000);
    }
}

window.authInstance = new AuthenticationService(new ApiClient());

window.authInstance
    .init()
    .then(() => {
        ReactDOM.render(<React.StrictMode><App auth={authInstance} isAuthenticated={authInstance.isAuthenticated} accessScope={accessScope} /></React.StrictMode>, document.getElementById('root'));
    })
    .catch((err) => {
        console.error("Authenticated Failed", err);
    });

reportWebVitals();
