import {Navigate, useMatch} from 'react-router-dom';
import {cloneElement, useEffect, useState} from 'react';
import {policyAllowed} from "@violetx-mvp/commons";
import {useRecoilState} from "recoil";
import organizationNameState from "./atoms/organizationName";
import ApiClient from "./apiClient";
import organizationPoliciesState from "./atoms/organizationPolicies";


function ScopeBasedRoute({pathname, children, accessScope, claims, redirectOnFailTo, api}) {
    const [organizationName, setOrganizationName] = useRecoilState(organizationNameState);
    const [, setOrganizationPolicies] = useRecoilState(organizationPoliciesState);
    const [redirectToDefaultPage, setRedirectToDefaultPage] = useState(null);

    const computedMatch = useMatch(pathname);

    let isAccessAllowed = claims?.every(claim => policyAllowed(accessScope, claim))??true;
    const apiClient = api ? api : new ApiClient();
    let pathToRedirect = redirectOnFailTo;

    const organizationId = computedMatch?.params.orgId;

    if (!policyAllowed(accessScope, 'organization') && +organizationId !== +accessScope.organizationId && isAccessAllowed) {
        if (pathToRedirect === '') {
            const index = computedMatch.pattern.path.split('/').indexOf(':orgId');
            let url = computedMatch.pathname.split('/');
            url[index] = accessScope.organizationId;
            url = url.join('/');
            pathToRedirect = url;
        }
       isAccessAllowed = false;
    }

    useEffect(async () => {
        if (redirectToDefaultPage) {
            setRedirectToDefaultPage(null);
        }
        if (!organizationName && organizationId) {
            let loadedCompany = await apiClient.get()
                .orgsApi.organizationGetById(organizationId);
            if (loadedCompany.data?.active) {
                setOrganizationName(loadedCompany.data.name);
                setOrganizationPolicies(loadedCompany.data.policies)
            } else {
                setOrganizationName(null);
                setOrganizationPolicies(null);
                setRedirectToDefaultPage('/');
            }
        }
    },[organizationId]);

    return (
        isAccessAllowed && !redirectToDefaultPage ? (
            cloneElement(children, {match: computedMatch, accessScope: accessScope})
        ) : (
                <Navigate
                    to={{
                        pathname: redirectToDefaultPage ?? pathToRedirect,
                    }}
                />
            )
    );
}

export default ScopeBasedRoute;
