import {Button, message} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import AddNewCompanyModal from "../addNewCompanyModal/addNewCompanyModal";
import ClientsController, {GENERAL_ERROR, COMPANY_EXISTS_ERROR} from "../../controllers/clientsController";
import {useNavigate} from "react-router-dom";


const AddNewCompanyButton = ({ requestCompanyModalOpen }) => {

    const clientsController = new ClientsController({});

    const [isAddCompanyModalVisible, setIsAddCompanyModalVisible] = useState(false);
    const [isCompanyExists, setIsCompanyExists] = useState(false);
    const [isCompanyAdded, setIsCompanyAdded] = useState(false);
    const [addedCompanyId, setAddedCompanyId] = useState(null);

    const navigate = useNavigate();

    const showHideAddCompanyModal = () => {
        setIsCompanyExists(false);
        setIsAddCompanyModalVisible(!isAddCompanyModalVisible);
    }

    useEffect(() => {
        if (requestCompanyModalOpen !== null) {
            setIsAddCompanyModalVisible(requestCompanyModalOpen);
        }
    }, [requestCompanyModalOpen]);

    const addCompany = async (companyName) => {
        setIsCompanyExists(false);
        const companyId = await clientsController.addNewCompany(companyName);
        if (companyId === GENERAL_ERROR) {
            message.error('An error occurred while try to create company');
            return;
        }
        if (companyId === COMPANY_EXISTS_ERROR) {
            setIsCompanyExists(true);
            return;
        }
        setAddedCompanyId(companyId);
        setIsCompanyAdded(true);
        message.success('Company has been successfully created');
        showHideAddCompanyModal();
    }

    const redirectToCompanySummary = (companyId) => {
        navigate(`/clients/${companyId}/summary`);
    }

    return (
        <>
            <Button
                type="primary"
                icon={<PlusOutlined style={{fontSize: '16px'}}/>}
                data-testid="add-client"
                onClick={showHideAddCompanyModal}
            >New Company</Button>
            {isAddCompanyModalVisible && <AddNewCompanyModal redirectToCompanySummary={redirectToCompanySummary}
                orgId={addedCompanyId} isAddCompanyVisible={isAddCompanyModalVisible} onCancelCompanyAdd={showHideAddCompanyModal}
                    onAddNewCompany={addCompany} isCompanyExists={isCompanyExists} isCompanyAdded={isCompanyAdded}
            />}
        </>
  );
}

export default AddNewCompanyButton;