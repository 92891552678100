import './documentsHeader.css';
import {ArrowLeftOutlined, DeleteOutlined, FolderOpenOutlined} from '@ant-design/icons';
import {Link, useParams} from 'react-router-dom';
import {Button} from "antd";

const DocumentsHeader = ({folderName, previousFolder, folderId, onDelete}) => {
  const {orgId, projectId} = useParams();
  const linkToRedirect = !!previousFolder ? `/clients/${orgId}/projects/details/${projectId}/documents/${previousFolder}`
    : `/clients/${orgId}/projects/details/${projectId}/documents`;

  const deleteFolder = async () => {
    await onDelete(folderId, true, `/clients/${orgId}/projects/details/${projectId}/documents`);
  }

  return (
    <div className="documents-page-header" data-testid="documents-page-header">
      {!!folderName ?
        <div className="documents-page-folder-header">
          <div data-testid="documents-page-folder-header">
            <Link data-testid="documents-page-header-redirect-button" to={linkToRedirect}
                  className="documents-page-header-back-button">
              <ArrowLeftOutlined style={{fontSize: '24px', marginRight: '8px', color: '#262626'}}/>
            </Link>
            <FolderOpenOutlined style={{fontSize: '24px', marginRight: '8px'}}/>
            <span>{folderName}</span>
          </div><div>
          <Button onClick={deleteFolder} icon={<DeleteOutlined style={{ fontSize: '12px', color: '#F5222D' }}/>} danger></Button>
        </div>
        </div>
        : <div className="documents-page-title" data-testid="documents-page-default-header">Documents</div>
      }

    </div>
  );
};

export default DocumentsHeader;
