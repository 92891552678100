import './notifications.css';
import {Badge, Popover} from "antd";
import {BellOutlined} from "@ant-design/icons";
import React, {cloneElement, useEffect, useState} from "react";
import useNotificationsOperations from "../../hooks/useNotificationsOperations";
import AssignedNotification from "./assignedNotification/assignedNotification";
import moment from "moment";
import DeadlineTomorrowNotification from "./deadlineTomorrowNotification/deadlineTomorrowNotification";
import {useRecoilValue, useSetRecoilState} from "recoil";
import taskAssignedNotificationsState from "../../atoms/taskAssignedNotifications";
import seenNotificationsState from "../../atoms/seenNotifications";
import StatusReportReadyNotification from "./statusReportReadyNotification/statusReportReadyNotification";
import deadlineTomorrowNotificationsState from "../../atoms/deadlineTomorrowNotification";
import projectStatusReportNotificationsState from "../../atoms/projectStatusReportNotification";
import seenNotificationState from "../../atoms/seenNotification";

let lastLoadedTimestamp = null;
let notifications = [];
const Notifications = ({offset, accessScope, apiClient}) => {

    const [loadNotifications, markNotificationAsRead, markAllNotificationAsRead] = useNotificationsOperations()
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const setAssignedTaskNotifications = useSetRecoilState(taskAssignedNotificationsState);
    const setDeadlineTomorrowNotifications = useSetRecoilState(deadlineTomorrowNotificationsState);
    const setProjectStatusReportNotifications = useSetRecoilState(projectStatusReportNotificationsState);
    const [, setRequestRender] = useState(null);
    const seenNotification = useRecoilValue(seenNotificationState);
    const setSeenNotification = useSetRecoilState(seenNotificationState);
    const setSeenNotifications = useSetRecoilState(seenNotificationsState);
    const seenNotifications = useRecoilValue(seenNotificationsState);

    const notificationsMapper = {
        "TASK_ASSIGNED": <AssignedNotification/>,
        "PROJECT_STATUS_REPORT": <StatusReportReadyNotification/>,
        "TASK_DEADLINE_TOMORROW": <DeadlineTomorrowNotification/>
    }

    useEffect(async () => {
        if (seenNotification) {
            const index = notifications.findIndex(n => n.id === seenNotification.id);
            await markNotificationAsRead(seenNotification.id);
            notifications[index].isSeen = true;
            setRequestRender(new Date());
            setTaskAssignedUnseenNotifications();
            setSeenNotification(null);
        }
    }, [seenNotification]);

    useEffect(async () => {
        if (seenNotifications.length > 0) {
            await markAllNotificationAsRead(seenNotifications);
            for (const seenNotification of seenNotifications) {
                const index = notifications.findIndex(n => n.id === seenNotification.id);
                notifications[index].isSeen = true;
            }
            setRequestRender(new Date());
            setTaskAssignedUnseenNotifications();
            setSeenNotifications([]);
        }
    }, [seenNotifications]);

    const setTaskAssignedUnseenNotifications = () => {
        const notificationsCopy = JSON.parse(JSON.stringify(notifications));
        const unSeenTaskAssignedNotifications = notificationsCopy.filter(n => n.type === 'TASK_ASSIGNED' && n.isSeen === false);
        const unSeenDeadlineTomorrowNotifications = notificationsCopy.filter(n => n.type === 'TASK_DEADLINE_TOMORROW' && n.isSeen === false);
        const unSeenProjectStatusReportNotifications = notificationsCopy.filter(n => n.type === 'PROJECT_STATUS_REPORT' && n.isSeen === false);
        setAssignedTaskNotifications(unSeenTaskAssignedNotifications);
        setDeadlineTomorrowNotifications(unSeenDeadlineTomorrowNotifications);
        setProjectStatusReportNotifications(unSeenProjectStatusReportNotifications);
    }

    const loadNotificationsData = async (dateRange) => {
        const loadedNotifications = await loadNotifications(dateRange, accessScope.userId);
        const currentNotifications = [...notifications];
        if (loadedNotifications.length > 0) {
            loadedNotifications.forEach(n => {
                const existingNotification = currentNotifications.find(nc => nc.id === n.id);
                if (!existingNotification) {
                    currentNotifications.push(n);
                }
            });
            currentNotifications.sort((a, b) => {
                if (a.id > b.id) return -1;
                if (a.id < b.id) return 1;
                return 0;
            });
            notifications = currentNotifications;
            setRequestRender(new Date());
            setTaskAssignedUnseenNotifications();
        }
    }

    const markAsRead = async () => {
        const unSeenNotifications = notifications.filter(n => n.isSeen === false);
        await markAllNotificationAsRead(unSeenNotifications);
        notifications.forEach(n => n.isSeen = true);
    }

    const getDateRange = () => {
        if (lastLoadedTimestamp) {
            const result = `${lastLoadedTimestamp.subtract(1, 'minutes').utc().format()}..${lastLoadedTimestamp.add(1, 'days').utc().format()}`
            lastLoadedTimestamp = moment();
            return result;
        }
        lastLoadedTimestamp = moment();
        return `${moment().startOf('day').subtract(14, 'days').utc().format()}..${lastLoadedTimestamp.add(1, 'days').utc().format()}`
    }

    useEffect(async () => {
        await loadNotificationsData(getDateRange());
        processNotifications();
    }, []);

    const processNotifications = () => {
        setTimeout(async () => {
            await loadNotificationsData(getDateRange());
            processNotifications();
        }, 5000);
    }

    const getContent = (notificationsToUse) => {
        return notificationsToUse.map((notification, index) => {
            const component = notificationsMapper[notification.type];
            const notificationCopy = cloneElement(component, {
                data: notification,
                onActionCallback: async () => {
                    await markNotificationAsRead(notification.id);
                    notifications[index].isSeen = true;
                    setIsPopoverVisible(false);
                    setTaskAssignedUnseenNotifications();
                }
            })
            const bgColor = notification.isSeen ? '#FFFFFF' : '#F8FBFF';
            return (
                <div key={`notification-${notification.id}`} className="notification-item" style={{
                    backgroundColor: bgColor,
                }}>
                    <notificationCopy.type {...notificationCopy.props} background={bgColor} apiClient={apiClient}/>
                </div>
            )
        });
    }

    const notificationsToUse = Array.isArray(notifications) ? notifications : [];
    const notificationsCount = notificationsToUse.filter(n => n.isSeen === false).length;

    const header = <div className="notification-header">
        <div>
            <Badge data-testid="notify-count" count={notificationsCount} size="small" overflowCount={999}
                   style={{fontSize: "10px"}} offset={[15, 6]}>
                Notifications
            </Badge>
        </div>
        <div onClick={markAsRead}>Mark all as read</div>
    </div>

    const bellIconBgColor = isPopoverVisible ? '#F5F5F5' : '#FFFFFF';
    let componentToDisplay = <BellOutlined style={{color: '#262626', fontSize: '16px', cursor: 'pointer'}}/>;
    if (notificationsToUse.length > 0) {
        componentToDisplay = <div className="notifications">
            <Popover content={getContent(notificationsToUse)} title={header} trigger="click" className="notifications-popover"
                     overlayStyle={{
                        maxHeight: '100px'
                    }}
                     open={isPopoverVisible}
                     onOpenChange={(visible) => {
                         setIsPopoverVisible(visible);
                     }}
            >
                <Badge data-testid="notify-count" count={notificationsCount} size="small" overflowCount={999}
                       style={{fontSize: "10px"}} offset={[offset, 7]}>
                    <BellOutlined data-testid='bell-icon' style={{color: '#262626', fontSize: '16px', cursor: 'pointer', backgroundColor: bellIconBgColor, padding: 4}}/>
                </Badge>
            </Popover>
        </div>
    }
    return componentToDisplay;
}

export default Notifications;
