import React, {useEffect, useState} from 'react';
import './vulnerabilities.css'
import { Table, Tag, Statistic, Space } from 'antd';
import Dots from "../../assets/images/dots-devices.svg";
import OpenInNewPage from '../../assets/images/OpenInNewPage.svg'
import ApiClient from "../../apiClient";
import {formatDate} from "../utils";
import VulnerabilitiesExport from "../vulnerabilitiesExport/vulnerabilitiesExport";

const columns = [
    {
        title: 'Detected',
        dataIndex: 'timestamp',
        key: 'timestamp',
        width: '200px',
        render: (_, { timestamp }) => {
            return (
                formatDate(timestamp)
            )
        },
    },
    {
        title: 'CVE ID',
        dataIndex: 'cveId',
        key: 'cveId',
        align: 'left',
        render: (text) =>
            <a target="_blank" href={`https://nvd.nist.gov/vuln/detail/${text}`}>
                <Space><img src={OpenInNewPage} alt=""/></Space>{text}</a>,
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        align: 'left',
        render: (_, row) => {
            return `${row['cveId']} ${row['name']}`;
        }
    },
    {
        title: 'Source',
        key: 'source',
        align: 'left',
        render: () => {
            return 'VioletX Agent'
        }
    },
    {
        title: 'Severity',
        dataIndex: 'severity',
        key: 'severity',
        render: (_, { severity }) => {
            let color;
            let title;
            if(Number(severity) >= 9.0) {
                color = 'red';
                title = 'Critical';
            } else if(Number(severity) >= 7.0) {
                color = 'orange';
                title = 'High';
            } else if(Number(severity) >= 4.0) {
                color = 'yellow';
                title = 'Medium';
            } else if(Number(severity) >= 0.1) {
                color = 'green';
                title = 'Low';
            } else {
                color = 'blue';
                title = 'Info';
            }
            return (
                <>
                    <Tag color={color} key={severity}>
                        {title}
                    </Tag>
                </>
            )},
    },
    {
        title: '',
        dataIndex: 'actionItems',
        key: 'actionItems',
        render: (text, record, index) => {
            return <a data-testid={`dots-menu-${index}`} className="action-menu-container">
                <Space>
                    <img src={Dots} alt=""/>
                </Space>
            </a>
        },
        className: 'table-action',
        width: '56px'
    }
];

export default function Vulnerabilities({api, deviceId}) {

    const apiClient = api ? api : new ApiClient();

    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [status, setStatus] = useState({});

    useEffect(async () =>{
        await getData();
    }, [deviceId])

    async function getData(page) {
        const result = await apiClient.get().scanResultsApi.scanResultList(
            [],
            [],
            [`scanTarget:VULNERABILITIES`, `deviceId:${deviceId}`],
            ['resultExpand', 'deviceInfo'],
            page ?? 1,
            10
        );
        const status = await apiClient.get().scanResultsApi.scanResultVulnerabilitiesCount(
            '',
            [`scanTarget:VULNERABILITIES`, `deviceId:${deviceId}`]
        );
        setStatus({...status.data});
        setData([...result.data.data.map(d => {return {...d.result, id: d.resultExpandIdx }})]);
        setTotal(result.data.page.total);
    }

    const onRowChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys)
    }

    function Stat({ critical, high, medium, low, info }) {
        return <div className="stats-container">
            <div>
                <Statistic title="Critical" value={critical} />
            </div>
            <div>
                <Statistic title="High" value={high} />
            </div>
            <div>
                <Statistic title="Medium" value={medium} />
            </div>
            <div>
                <Statistic title="Low" value={low} />
            </div>
            <div>
                <Statistic title="Info" value={info} />
            </div>
        </div>
    }

    async function handlePaging(page) {
        await getData(page);
    }

    return <div className="vulnerabilities" data-testid="vulnerabilities">
            <h2 className="inventory-title">Vulnerabilities</h2>

        <div className="controls-container">
            <Stat critical={status.critical ?? 0} high={status.high ?? 0} medium={status.medium ?? 0} info={status.info ?? 0} low={status.low ?? 0}/>
            <div><VulnerabilitiesExport apiClient={apiClient} disabled={false} deviceId={deviceId}/></div>
        </div>

        <div className="table-wrapper">
            <Table
                data-testid={`change-data-table`}
                columns={columns}
                dataSource={data}
                rowKey={'id'}
                scroll={{
                    y: 'calc(100vh - 442px)'
                }}
                rowSelection={{
                    selectedRowKeys: selectedRowKeys,
                    onChange: onRowChange
                }}
                pagination={{
                    total: total,
                    onChange: handlePaging,
                    showSizeChanger: false
                }}
            />
        </div>
    </div>
}
