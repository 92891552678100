import './templatesFilter.css'
import {Dropdown} from 'antd';
import {CloseOutlined, DownOutlined} from '@ant-design/icons';
import {useState} from "react";

const TemplatesFilter = ({setTemplateType, templateType}) => {

    const items = [{key: 'VX_SERVICE', label: 'vX Service'}, {key: 'DEFAULT', label: 'Default'}];

    const [isDropdownOpened, setIsDropdownOpened] = useState(false)

    const clearTemplateType = (e) => {
        setTemplateType(null);
        e.stopPropagation();
    }

    return <div className="templates-filter">
        <Dropdown
            trigger={['click']}
            onOpenChange={e => setIsDropdownOpened(e)}
            menu={{
                items, onClick: (e) => {
                    setTemplateType(e.key);
                    setIsDropdownOpened(false);
                }
            }}
        >
            <div data-testid="template-dropdown" className={`dropdown ${templateType && 'selected'} ${isDropdownOpened && 'active'}`}>
                <span>Template type{templateType && `: ${items.find(e => e.key === templateType).label}`}</span>
                {templateType ?
                    <CloseOutlined data-testid="close-template-type" style={{fontSize: '12px'}} onClick={clearTemplateType}/> :
                    <DownOutlined/>}
            </div>
        </Dropdown>
    </div>
}

export default TemplatesFilter;