import React from 'react';
import './queryExport.css';
import * as utils from "../utils";
import {Button, Dropdown, Menu, Modal, Progress, Typography} from 'antd';
import {CloseCircleFilled, DownloadOutlined, ExportOutlined} from '@ant-design/icons';
import exportFromJSON from 'export-from-json'

const DOWNLOAD_PAGE_LIMIT = 20;

export default class QueryExport extends React.Component {

    EXPORT_TYPES = {
        csv: { title: '.CSV', type: exportFromJSON.types.csv },
        txt: { title: '.TXT', type: exportFromJSON.types.txt },
        json: { title: '.JSON', type: exportFromJSON.types.json },
    }

    EXPORT_STATES = {
        NONE: 'NONE',
        PROGRESS: 'PROGRESS',
        DOWNLOAD: 'DOWNLOAD',
        ERROR: 'ERROR',
    }

    constructor(props) {
        super(props);
        this.apiClient = props.apiClient;
        this.isLive = props.isLive;
        this.query = props.query;
        this.cloud = props.cloud;
        this.organizationId = props.organizationId;
        this.state = {
            exportType: null,
            exportPercent: 0,
            exportState: this.EXPORT_STATES.NONE,
            exportError: '',
        };
        this.data = [];
        this.headers = [];
    }

    UNSAFE_componentWillReceiveProps(nextProps, _nextContext) {
        if(this.isLive !== nextProps.isLive) {
            this.isLive = nextProps.isLive;
        }
        if(this.query !== nextProps.query) {
            this.query = nextProps.query;
        }
    }

    doExport(exportType) {
        this.data = [];
        this.headers = [];
        this.setState(
            { exportType: exportType, exportState: this.EXPORT_STATES.PROGRESS, exportPercent: 0, exportError: '' },
            this.getQueryResults
        );
    }

    doDownload() {
        const data = this.data;
        const fileName = this.query.name.replace(/\s+/g,"_");
        const exportType = this.state.exportType.type;
        exportFromJSON({ data, fileName, exportType });
        this.setState({exportState: this.EXPORT_STATES.NONE});
    }

    doCancel() {
        this.data = [];
        this.headers = [];
        this.setState({ exportType: null, exportState: this.EXPORT_STATES.NONE, exportPercent: 0, exportError: '' })
    }

    getQueryResultsPage = async (page) => {
        const response = await this.apiClient
            .get()
            .distributedQueryResultsApi
            .distributedQueryResultList(
                [],
                ['-timestamp'],
                ['execute:COMPLETED'
                    ,`distributedQueryId:${this.query.distributedQueryId}`
                    ,`organizationId:${this.organizationId}`
                    ,'active:true'],
                ['userFirstName', 'userLastName', 'deviceType', 'deviceInfo', 'deviceTimestamp'],
                page,
                DOWNLOAD_PAGE_LIMIT
            );
        const responseData = await response.data;
        const data = [];
        responseData.data.map((item) => {
            const itemData = this.cloud ? {
                name: item.deviceInfo.name,
                deviceType: utils.devicePlatformName(item.deviceType),
                timestamp: item.timestamp,
            } : {
                userFirstName: item.userFirstName,
                userLastName: item.userLastName,
                deviceType: utils.devicePlatformName(item.deviceType),
                timestamp: item.timestamp,
            }
            JSON.parse(item.rawData)?.map((jsonData) => {
                data.push({ ...itemData, ...jsonData });
            });
        })
        return { data: data, page: responseData.page };
    }

    getQueryResults = async () => {
        const queryResults = [];
        try {
            let page = 1;
            do {
                const result = await this.getQueryResultsPage(page);
                if(this.state.exportState !== this.EXPORT_STATES.NONE) {
                    queryResults.push(...result.data)
                    const lastPage = result.page.last || 1;
                    this.setState({exportPercent: (page / lastPage) * 100});
                    page = result.page.next;
                } else {
                    page = null;
                }
            } while (page !== null);
            if(this.state.exportState !== this.EXPORT_STATES.NONE) {
                this.data = queryResults;
                this.headers = this.cloud ? [
                    {label: 'Name', key: 'name'},
                    {label: 'Device Type', key: 'deviceType'},
                    {label: 'Timestamp', key: 'timestamp'},
            ] : [
                    {label: 'First Name', key: 'userFirstName'},
                    {label: 'Last Name', key: 'userLastName'},
                    {label: 'Device Type', key: 'deviceType'},
                    {label: 'Timestamp', key: 'timestamp'},
                ];
                if (this.data.length > 0) {
                    this.headers.push(
                        ...Object.keys(this.data[0])
                            .filter((key) => {
                                return this.cloud
                                    ? ['id', 'name', 'deviceType', 'timestamp'].indexOf(key) === -1
                                    : ['id', 'userFirstName', 'userLastName', 'deviceType', 'timestamp'].indexOf(key) === -1;
                            })
                            .map((key) => {
                                return {label: key, key: key};
                            })
                    );
                }
                setTimeout(() => {
                    this.setState({exportState: this.EXPORT_STATES.DOWNLOAD});
                }, 500);
            }
        } catch (e) {
            this.error = e.message;
            this.setState({exportState: this.EXPORT_STATES.ERROR, exportError: e.message});
        }
    }

    items = [
        { key: `json`, label: <div className="query-export-menu-item-json" onClick={() => this.doExport(this.EXPORT_TYPES.json)}>{this.EXPORT_TYPES.json.title}</div>},
        { key: `csv`, label: <div className="query-export-menu-item-csv" onClick={() => this.doExport(this.EXPORT_TYPES.csv)}>{this.EXPORT_TYPES.csv.title}</div>},
    ];

    render() {
        return <div className="query-export-container">
            <Dropdown className="query-export-dropdown" disabled={this.props.disabled}
                      menu={{items: this.items}}>
                <Button className="query-export-container-btn">
                    <ExportOutlined /> Export as
                </Button>
            </Dropdown>
            <Modal centered
                className="query-export-modal-dialog"
                open={this.state.exportState !== this.EXPORT_STATES.NONE}
                closable={false}
                footer={null}>
                <span className="query-export-modal-title">Exporting as {this.state.exportType?.title}</span>
                <Button shape="circle"
                        className="query-export-modal-close-btn"
                        icon={<CloseCircleFilled className="query-export-modal-close-btn-icon"/>}
                        onClick={() => this.doCancel()}/>
                <div className="query-export-modal-body">
                    {this.state.exportState === this.EXPORT_STATES.PROGRESS
                        ? <Progress size="small" status="active"
                                    className="query-export-modal-progress"
                                    strokeColor={"#1890FF"}
                                    showInfo={false}
                                    percent={this.state.exportPercent} />
                        : null}
                    {this.state.exportState === this.EXPORT_STATES.DOWNLOAD
                        ? <Button className="query-export-modal-download-btn"
                                  onClick={() => this.doDownload()}>
                            <DownloadOutlined /> Download</Button>
                        : null}
                    {this.state.exportState === this.EXPORT_STATES.ERROR
                        ? <Typography.Text
                            className="query-export-modal-error"
                            type="danger">Export error: {this.state.exportError}</Typography.Text>
                        : null}
                </div>
            </Modal>
        </div>
    }

}
