import './summaryHeader.css';
import HeaderBack from '../../assets/images/header-back.svg';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import {RolesMapper, scanSubTypes} from "../../constants";

const SummaryHeader = ({onBack, type, onSave, onDelete, name, getImage, disabled, disableDelete, commandButtons, isVioletXOnly}) => {

    const getDeviceIcon = () => {
        return <>{getImage()}</>
    }
    const allowCommands = (type !== scanSubTypes.SYSTEM) && !(type === scanSubTypes.VIOLET_X && !isVioletXOnly);

    return <div className="summary-header" data-testid="summary-header">
        <div className='name-wrapper'>
            <img src={HeaderBack} alt="Back" onClick={onBack} data-testid="back-button" />
            {getDeviceIcon()}
            <div className="scan-name" data-testid="scan-name" title={name}>{name}</div>
        </div>
        {allowCommands ? <div className="buttons">
            <div className="header-additional-button">
                {commandButtons}
            </div>
            <div className="header-buttons">
                <Tooltip title="Delete">
                    <Button className='button' danger icon={<DeleteOutlined />} disabled={disableDelete} data-testid="delete-button" onClick={onDelete}/>
                </Tooltip>
                <Button type='primary' data-testid="save-button" onClick={onSave} disabled={disabled}>Save</Button>
            </div>
        </div>
        : null}
    </div>
}

export default SummaryHeader;