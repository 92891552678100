import './baseLayout.css';
import React, {useState, useEffect, useCallback} from 'react';
import {RolesMapper} from "../../constants";
import {Layout, message, Modal, Button} from "antd";
import { policyAllowed } from "@violetx-mvp/commons";
import RuleMenu from "../ruleMenu/ruleMenu";
import { CheckCircleOutlined } from "@ant-design/icons";
const { Sider } = Layout;
import ApiClient from "../../apiClient";
import {Outlet, useParams} from "react-router-dom";
import { UserMenu } from "../userMenu/userMenu";
import {useRecoilValue} from "recoil";
import isRuleMenuCollapsed from "../../atoms/isRuleMenuCollapsed";
import { HomeOutlined } from "@ant-design/icons";
import Notifications from "../notifications/notifications";

function BaseLayout(props) {
    const [allowedOneOrganization, setAllowedOneOrganization] = useState(false);
    const isCollapsed = useRecoilValue(isRuleMenuCollapsed);
    const [requestedProjects, setRequestedProjects] = useState(0);
    const apiClient = props.api ? props.api : new ApiClient();
    const {orgId} = useParams();
    const [isResetPasswordLinkSentModal, setResetPasswordLinkSentModal] = useState(false);

    useEffect(() => {
        setAllowedOneOrganization(policyAllowed(props.accessScope, 'ORGANIZATION')
            ? false : props.accessScope?.organizationId);
    }, [props.accessScope]);

    useEffect(async () => {
        if (props.accessScope?.roleId === RolesMapper.UBER_ADMIN) {
            try {
                const result = await apiClient.get().notifierApi.notifierStatus(orgId);
                setRequestedProjects(result.data[0].total_project_requested);
            } catch (e) {
                message.error(e);
            }
        }
    },[props.accessScope, orgId]);

    const getOffset = () => {
        switch (true) {
            case requestedProjects <= 9:
                return 2;
            case requestedProjects <= 99:
                return 6;
            case requestedProjects <= 999:
                return 10;
            default:
                return 14;
        }
    }

    const onResetPasswordLinkSentModal = useCallback(() => {
        setResetPasswordLinkSentModal(true);
    }, []);

    const performLogout = useCallback(() => {
        props.onLogout();
    }, []);

    return (
        <Layout data-testid='layout'>
            <Sider collapsed={isCollapsed} className="layout-sider">
                <RuleMenu requestedProjects={requestedProjects} api={props.api} accessScope={props.accessScope} allowedOneOrganization={allowedOneOrganization} />
            </Sider>
            <Layout.Content>
                <Layout.Header className="no-line-height">
                    <Modal
                        open={isResetPasswordLinkSentModal}
                        onOk={performLogout}
                        closable={false}
                        footer={null}
                        width={536}
                        bodyStyle={{height: 254, padding: 32, paddingBottom: 24}}
                    >
                        <div className="password-changed-modal">
                            <div className="password-changed-modal-content">
                                <div>
                                    <CheckCircleOutlined style={{
                                        color: '#52C41A',
                                        fontSize: 24
                                    }} />
                                </div>
                                <div>
                                    <div>Change Password Link Sent</div>
                                    <div>
                                        <p>We've just sent you a link to change your password. For security reasons, you'll be logged off from your current session.</p>
                                        <p>Click the link in your email to set up a new password. Once you've done that, you can log back in with your updated credentials.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="password-changed-modal-footer">
                                <Button onClick={props.onLogout} type="primary"
                                        data-testid="reset-password-link-sent"
                                        style={{ width: 83}}
                                >Ok</Button>
                            </div>
                        </div>
                    </Modal>
                    <div>
                        <div style={{display:'none'}}>
                            <span><HomeOutlined style={{color:"#8C8C8C", fontSize: '14px'}} /></span>
                            <span className="separator">/</span>
                            <span><HomeOutlined style={{color:"#8C8C8C", fontSize: '14px'}} /><span className="title">VITech</span></span>
                            <span className="separator">/</span>
                            <span>Datasets</span>
                        </div>
                    </div>
                    <Notifications offset={0} accessScope={props.accessScope} apiClient={apiClient}/>
                    <div>
                        <div style={{marginLeft: `${requestedProjects >= 10 ? getOffset() * 1.5 : 0}px`}} className="dropdown-container">
                            <UserMenu api={apiClient} onLogout={props.onLogout} userId={props.accessScope?.userId} onResetPasswordLinkSent={onResetPasswordLinkSentModal} />
                        </div>
                    </div>
                </Layout.Header>
                <div className={`app-container`}>
                    <Outlet />
                </div>
            </Layout.Content>
        </Layout>
    );
}

export default BaseLayout;
