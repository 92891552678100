export const clientsInitialState = {
    filters: ['active:true'],
    sort: ['+name'],
    searchValue: '',
    expands: ['projectCount', 'pointOfContact', 'vxPointOfContact', 'lastInteraction'],
    page: 1
}

export const CLIENTS_ACTIONS = {
    FILTERS: 'filters',
    SEARCH_VALUE: 'searchValue',
    PAGE: 'page',
    RESET_STATE: 'resetState',
    CHANGE_GROUP: 'changeGroup'
}

export function clientsReducer(state, action) {
    switch (action.type) {
        case CLIENTS_ACTIONS.FILTERS:
            return {
                ...state,
                page: 1,
                filters: action.payload,
            };
        case CLIENTS_ACTIONS.SEARCH_VALUE:
            return {
                ...state,
                searchValue: action.payload,
                page: 1
            };
        case CLIENTS_ACTIONS.PAGE:
            return {
                ...state,
                page: action.payload,
            };
        case CLIENTS_ACTIONS.CHANGE_GROUP:
            return {
                ...state,
                searchValue: '',
                page: 1,
                filters: action.payload,
            };
        case CLIENTS_ACTIONS.RESET_STATE:
            return clientsInitialState;
        default:
            throw Error('Unknown action.');
    }
}