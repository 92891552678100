import {Card, Tag} from 'antd';
import {SettingOutlined} from '@ant-design/icons';
import DevicesScanner from '../../assets/images/devices-scanner.svg';
import CloudsScanner from '../../assets/images/clouds-scanner.svg';
import './integrationOverview.css'
import {useNavigate, useParams} from "react-router-dom";

const { Meta } = Card;

const IntegrationOverview = () => {

    const navigate = useNavigate();
    const {orgId} = useParams();

    function goToRoute(route) {
        navigate(route);
    }

    return (
        <div className="overview-block" data-testid='integration-wrapper'>
                <Card className="card-block">
                    <div className="image">
                        <img src={DevicesScanner} alt="devices"/>
                    </div>
                    <Meta
                        title={"Rule devices scanner"}
                        description={"This integration is based on osquery.io and gathers information related to the security of devices."}
                        className="card-description"
                    />
                    <div className="tags-wrapper">
                        <Tag>Rule</Tag>
                        <Tag>osquery.io</Tag>
                        <Tag>Devices</Tag>
                    </div>
                    <div className="card-setting" data-testid='redirect-to-devices' onClick={() => {goToRoute(`/clients/${orgId}/integrations/scanner-devices/violetx-scans`)}}>
                        <SettingOutlined key="setting"/>
                        <span className='setting'>See and create scans</span>
                    </div>
                </Card>
                <Card className="card-block">
                    <div className="image">
                        <img src={CloudsScanner} alt="clouds"/>
                    </div>
                    <Meta
                        title={"Rule clouds scanner"}
                        description={"This integration is based on Steampipe and gathers information related to the security of clouds."}
                        className="card-description"
                    />
                    <div className="tags-wrapper">
                        <Tag>Rule</Tag>
                        <Tag>Steampipe</Tag>
                        <Tag>Clouds</Tag>
                    </div>
                    <div className="card-setting" data-testid='redirect-to-clouds' onClick={() => {goToRoute(`/clients/${orgId}/integrations/scanner-clouds/violetx-scans`)}}>
                        <SettingOutlined key="setting"/>
                        <span className='setting'>See and create scans</span>
                    </div>
                </Card>
        </div>
    )
}

export default IntegrationOverview;
