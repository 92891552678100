import './reportTablePreview.css';
import { Empty, Table } from 'antd';
import {distributedQueryMapping, QUERY_TRANSFORM_FIELDS} from '@violetx-mvp/commons'
import {formatCustomFields, formatDate} from "../utils";

const ReportTablePreview = ({mappingFields, results, customFields}) => {

    const getPreviewTable = (fields, data, customFields) => {
        data = data.map(d => {
            if (d.deviceInfo?.arch === 'CLOUD') {
                d.userFirstName = d.deviceInfo.name;
                d.userLastName = '';
            }
            return d;
        });
        const dataSource = data
            .flatMap(r => distributedQueryMapping(r, {fields: fields, customFields: customFields}))
            .map((r, i) => {
                r.key=`preview-data-${i}`;
                return r;
            });

        const dataToDisplay = [];

        dataSource.forEach(d => {
            d.result.forEach((d1, index) => {
                d1.key = `${d.key}-${index}`;
                d1.rawData = formatCustomFields(d1.customFields);
                d1.Timestamp = !d1.Timestamp ? '' : formatDate(d1.Timestamp);
                for (const d1Key in d1) {
                    if (Array.isArray(d1[d1Key])) {
                        d1[d1Key] = d1[d1Key].join(' ');
                    } else if (typeof d1[d1Key] === "object" ) {
                        d1[d1Key] = JSON.stringify(d1[d1Key]);
                    }
                }
                for (let field in d1) {
                    if (d1[field] !== null && d1[field] !== undefined) {
                        d1[field] = `${d1[field]}`
                    }
                }
                dataToDisplay.push(d1);
            });
        });

        const columns = fields.filter(field => {
            return QUERY_TRANSFORM_FIELDS.indexOf(field.target) !== -1;
        }).map((field, index) => {
                return {
                    title: field.target,
                    dataIndex: field.target,
                    key: `preview-col-${index}`,
                    align: 'left'
                }
        });
        columns.push({
            title: 'Raw data',
            dataIndex: 'rawData',
            key: `preview-col-${100}`,
            align: 'left'
        });


        return <div>
            <div className="preview-title">Dashboard table preview</div>
            <div>
                <Table dataSource={dataToDisplay} columns={columns} pagination={false} />
            </div>
        </div>;
    }

    return <div className="report-table-preview" data-testid="report-table-preview">
        {results.length === 0
            ? <div className="empty-container" data-testid="empty-container"><Empty/></div>
            : getPreviewTable(mappingFields, results, customFields)}
    </div>
}

export default ReportTablePreview;
