import './stat.css';
import { Statistic  } from 'antd';

const Stat = ({ items }) => {
    return <div className="stats-container">
        {items.map((i, key) => <div key={`stat-${key}`}>
                <Statistic title={i.title} value={i.value} />
            </div>
        )}
    </div>
}

export default Stat;