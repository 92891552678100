import {GENERAL_ERROR} from "../../controllers/clientsController";
import {useEffect, useState} from "react";
import {message} from "antd";
import Stat from "../stat/stat";
import useClientsOperations from "../../hooks/useClientsOperations";

const ClientsStat = ({clientGroup, reload, onStatLoaded}) => {

    const [clientsStatus, setClientsStatus] = useState(null);
    const [loadStatistic] = useClientsOperations();

    async function getStat(selectedGroup) {
        const result = await loadStatistic(selectedGroup);
        if (result === GENERAL_ERROR) {
            message.error('Failed to load clients statistic');
        }
        setClientsStatus(result);
        onStatLoaded(result);
    }

    useEffect(async () => {
        await getStat(clientGroup);
    }, [clientGroup, reload]);

    return (<Stat items={[
            {title: 'Clients', value: clientsStatus?.total},
            {title: 'Active projects', value: clientsStatus?.activeProjectsCount}
        ]}/>
    );
}

export default ClientsStat;