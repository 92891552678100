import './assetsSummary.css';
import { Statistic } from 'antd';
import { LaptopOutlined, ArrowsAltOutlined } from '@ant-design/icons';

const RiskStatTitle = ({title, icon}) => {
    return <div className="risk-stat-title">
        {icon} {title}
    </div>
}

const RiskStatValue = ({value, icon}) => {
    return <div>{value} {icon}</div>
}

const RiskStat = () => {

    return <div className="risk-stat" data-testid="risk-stat">
        <Statistic title={<RiskStatTitle title="Inventory risk score" icon={<LaptopOutlined />} />}
                   value={0.34} formatter={(value) => <RiskStatValue value={value} icon={<ArrowsAltOutlined />}/> } />
    </div>
}

const AssetsSummary = () => {
    return <div className="assets-summary" data-testid="assets-summary">
        <div>
            <h1 data-testid="assets-summary-title">Risks</h1>
            <div>
                <RiskStat/>
            </div>
        </div>
    </div>
}

export default AssetsSummary;