import { atom } from "recoil";

const openedDebugQueriesState = atom({
    key: 'openedDebugQueriesState',
    default: {
        activeKey: null,
        queries: []
    }
});

export default openedDebugQueriesState;