import './addNewCompanyModal.css';
import {Input, Modal} from "antd";
import UploadImage from "../uploadImage/uploadImage";
import React, {useEffect, useState} from "react";
import ApiClient from "../../apiClient";

const AddNewCompanyModal = ({isAddCompanyVisible, onAddNewCompany, onCancelCompanyAdd,
                                isCompanyAdded, isCompanyExists, api, orgId, redirectToCompanySummary}) => {

    const apiClient = api || new ApiClient();
    const [companyName, setCompanyName] = useState('');
    const addCompanyInputRef = React.createRef();

    useEffect(() => {
        if (isAddCompanyVisible) {
            addCompanyInputRef.current.focus();
        }
    }, [isAddCompanyVisible]);
    const handleCompanyName = (e) => {
        const isFirstCharSpace = e.target.value.length > 0 && e.target.value[0] === ' ';
        if (isFirstCharSpace) {
            return false;
        }
        setCompanyName(e.target.value);
    }

    const handleAddNewCompany = async () => {
        const nameTrimmed = companyName.trim();
        if (nameTrimmed.length === 0) {
            return false;
        }
        onAddNewCompany(nameTrimmed);
    }

    return (
        <Modal title="Add a new company" wrapClassName="company-add-edit-modal" width={390}
               open={isAddCompanyVisible} onOk={handleAddNewCompany}
               okText="Add"
               okButtonProps={{'data-testid': 'add-company', disabled: !companyName.length}}
               cancelButtonProps={{'data-testid': 'cancel-add'}}
               key={isAddCompanyVisible ? 'opened-modal' : 'closed-modal'}
               onCancel={onCancelCompanyAdd}>
            <p style={{marginBottom: '8px'}}><label htmlFor="companyName">Company name</label></p>
            <div>
                <Input id="companyName" className="edit-input" data-testid="addCompanyNameInput"
                       value={companyName} onChange={handleCompanyName} ref={addCompanyInputRef}/>
                {isCompanyExists ? <div className="error" data-testid="company-exists-error">* Company already exists</div> :
                    <div className="space">&nbsp;</div>}
            </div>
            <UploadImage bucketName={'organizations'} api={apiClient} id={orgId} name={companyName[0]?.toUpperCase()}
                         isImageSaved={isCompanyAdded} redirect={redirectToCompanySummary}/>
        </Modal>

    );
}

export default AddNewCompanyModal;