import './projectsTemplatesList.css';
import {
    ArrowLeftOutlined,
    EyeOutlined,
    EyeInvisibleOutlined,
    FolderOpenOutlined,
    PlusOutlined,
    CaretUpOutlined, CaretDownOutlined
} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import ProjectsTemplate from "../projectsTemplate/projectsTemplate";
import ApiClient from "../../apiClient";
import React, {useEffect, useState} from "react";
import {Button, Dropdown, Empty, Input, message} from "antd";
import {RolesMapper} from "../../constants";
import TemplatesFilter from "../templatesFilter/templatesFilter";
import EmptyPage from "../emptyPage/emptyPage";
import noProjects from "../../assets/images/no-clients.svg";
import empty from "../../assets/images/empty-search-result.svg";
import {getShortLabel} from "../utils";

const ProjectsTemplatesList = ({match, api, showBackButton, accessScope}) => {

    const { Search } = Input;
    const limit = 20;

    const [templates, setTemplates] = useState([]);
    const [projects, setProjects] = useState([]);
    const [templateType, setTemplateType] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [isAllGroupsLoaded, setIsAllGroupsLoaded] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [clients, setClients] = useState([]);
    const [selectedItem, setSelectedItem] = useState({name: null, id: null});
    const [isDropDownOpened, setIsDropDownOpened] = useState(false);

    const navigate = useNavigate();
    const apiClient = api || new ApiClient();

    useEffect( async () => {
        await loadTemplates();
        if (match?.params.orgId) {
            await loadProjects();
        }
    },[match?.params]);

    useEffect( async () => {
        await loadTemplates();
    },[templateType, selectedItem]);

    useEffect( async () => {
        await loadItems();
    },[]);

    const navigateToAdd = () => {
        navigate(`/projects/templates/add`)
    }

    const icon = isDropDownOpened ? <CaretUpOutlined/> : <CaretDownOutlined/>


    const loadProjects = async () => {
        const filter = ['active:true', `organizationId:${match?.params.orgId}`];
        const loadedProjects = await apiClient.get().projectApi.projectList(
            [],
            ['+name'],
            filter,
            [],
            1,
            50
        );

        const convertedProjects = loadedProjects.data.data.map((item) => {
            return {
                templateId: item.templateId,
            }
        });
        setProjects(convertedProjects);
    }

    const loadTemplates = async () => {
        try {
            let page = 1;
            const filter = templateType ? ['active:true', `projectType:${templateType}`] : ['active:true'];
            const selectedGroupId = isNaN(selectedItem.id) ? selectedItem.id?.split('-')[0] : null;
            if (selectedGroupId) {
                    filter.push(`restrictByGroupId:${selectedGroupId}`);
                } else if(selectedItem.id || match?.params.orgId) {
                    filter.push(`restrictByOrgId:${selectedItem.id ?? match?.params.orgId}`);
                    filter.push('hideVxOnly');
            }
            const templates = await apiClient.get().templateApi.templateList(
                '',
                [`+id`],
                filter,
                ['userFirstName', 'userLastName'],
                page,
                50
            );
            setTemplates(templates.data.data);
        } catch (err) {
            message.error('An error occurred while try to get templates');
        }
    }

    const deleteTemplate = async (template) => {
        await apiClient.get().templateApi.templatePatch([`id:${template.id}`], {active: false});
        await loadTemplates();
    }

    const getOption = (option, index, itemType, isLastGroupItem) => {
        return <div className={isLastGroupItem ? "lead-item last-group" : "lead-item"} data-testid={`option-item-${index}`}>
            {itemType === 'groups' ? <FolderOpenOutlined/> : <></>}
            <span style={{marginLeft: '4px'}}>{getShortLabel(option.name)}</span>
        </div>
    }

    const getClients = async (refresh, currentPage) =>
        await apiClient.get().orgsApi.organizationList(
            refresh ? '' : searchValue,
            [`+name`],
            ['active:true'],
            [],
            currentPage ?? page,
            limit
        );


    const getGroups = async (refresh, currentPage) =>
        await apiClient.get().groupsApi.groupsList(
            refresh ? '' : searchValue,
            [`+name`],
            ['active:true'],
            [],
            currentPage ?? page,
            limit
        );

    const onItemSelect = async (value, name) => {
        setSelectedItem({name: name, id: value});
        setIsDropDownOpened(false);
        await loadItems(true, true);
    }

    const loadItems = async (onSearch, refresh, currentPage) => {
        setIsLoading(true);
        if (onSearch) {
            setPage(1);
            currentPage = 1;
            setIsAllGroupsLoaded(false);
        }
        let optionsToDisplay = [];
        let loadedGroups = null;
        if (!isAllGroupsLoaded || refresh || onSearch) {
            loadedGroups = await getGroups(refresh, currentPage);
            optionsToDisplay = loadedGroups.data.data.map((o, i) => {
                const isLastGroupItem = !loadedGroups.data.page.next && i === loadedGroups.data.data.length - 1;
                const label = getOption(o, i, 'groups', isLastGroupItem);
                return {
                    label: label,
                    key: `${o.id}-g`,
                    onClick: () => onItemSelect(`${o.id}-g`, o.name)
                }
            });
        }
        if (!loadedGroups?.data.page.next) {
            if (!isAllGroupsLoaded) {
                setIsAllGroupsLoaded(true);
                setPage(1);
                currentPage = 1;
            }
            const loadedCompanies = await getClients(refresh, currentPage);
            optionsToDisplay = [...optionsToDisplay, ...loadedCompanies.data.data.map((o, i) => {
                const label = getOption(o, i);
                return {
                    label: label,
                    key: o.id,
                    onClick: () => onItemSelect(o.id, o.name)
                }
            })];
        }
        if (optionsToDisplay.length > 0 || onSearch) {
            const uniqueArray = optionsToDisplay.filter(obj => !clients.find(e => e.key === obj.key));
            setClients(onSearch ? [...optionsToDisplay] : [...clients,...uniqueArray]);

        }
        setIsLoading(false);
    }

    const handleSearchValue = (e) => {
        setSearchValue(e.target.value);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.stopPropagation();
        }
    }

    async function onScroll(e) {
        const scrolledDown = parseInt(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight;
        if (scrolledDown && !isLoading) {
            setPage(page + 1);
            await loadItems(false, false, page + 1);
        }
    }

    const handleDropdownVisibility = async (isOpen) => {
        setSearchValue('');
        if (isOpen) {
            setIsDropDownOpened(true);
        } else {
            setIsDropDownOpened(false);
            await loadItems(true, true);
        }
    }

    const getSearch = (menu) => (
        <div className="template-dropdown-menu">
            <Search placeholder="Search" style={{ height: 24 }}
                    className="template-options-search"
                    data-testid="options-search"
                    onSearch={() => loadItems(true)}
                    value={searchValue}
                    onChange={handleSearchValue}
                    onKeyDown={handleKeyDown}
            />
            <div data-testid="scroll" onScroll={onScroll}>{clients.length > 0 ? menu : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}</div>
        </div>
    )



    const navigateToEdit = async (template) => {
        navigate(`/projects/templates/summary/${template.id}`)
    }

    const backUrl = match?.params.orgId ? `/clients/${match.params.orgId}/projects` : `/projects`

    return <div className="projects-templates-list">
        {showBackButton && <div className="arrow-wrapper">
            <ArrowLeftOutlined data-testid='go-back' onClick={() => navigate(backUrl)} className='arrow'/>
            <span className="projects-templates" data-testid="header">Project templates</span>
        </div>
        }
        {!selectedItem.id ? <div className="commands-buttons">
            <div>Project templates</div>
            {accessScope.roleId === RolesMapper.UBER_ADMIN && !showBackButton && <div>
                <Dropdown
                    dropdownRender={getSearch}
                    menu={{items: clients}}
                    trigger="click"
                    className="searchable-select"
                    data-testid="searchable-clients-select"
                    onOpenChange={handleDropdownVisibility}>
                    <Button className="view-as" type="default" data-testid="select-client"><EyeOutlined/>View as</Button>
                </Dropdown>
                <Button className="add-template" type="primary" icon={<PlusOutlined style={{ fontSize: '11px'}} />} data-testid="add-project" onClick={navigateToAdd}>Add</Button>
            </div>}
        </div> :
            <div className="view-banner-wrapper">
                <div className="view-banner">
                    <span>Project templates seen as</span>
                    <Dropdown
                        dropdownRender={getSearch}
                        menu={{items: clients}}
                        trigger="click"
                        overlayClassName="template-dropdown"
                        data-testid="searchable-clients-select"
                        placement="bottomLeft"
                        arrow
                        onOpenChange={handleDropdownVisibility}>
                        <span className="selected-client">{selectedItem.name}<span className="caret-icon">{icon}</span></span>
                    </Dropdown>
                </div>
                <Button className="view-as" type="default" data-testid="select-client"
                        onClick={() => setSelectedItem({name: null, id: null})}><EyeInvisibleOutlined/>Exit View as</Button>

            </div>}
        <TemplatesFilter setTemplateType={setTemplateType} templateType={templateType}/>
        {templates.length === 0 ? <EmptyPage
                image={!showBackButton ? noProjects : empty}
                title="There are no templates yet"
                subTitle={!showBackButton ? "Please add a new template to start." : "Stay tuned for updates"}
            cssClassName={!showBackButton ? "empty-template-page" : 'empty-project-template-page'}
            onAddClick={!showBackButton ? navigateToAdd : null}
            addButtonText="Add new template"
        /> :
            <div className="templates-wrapper">
            {templates.map(t => {
                const isReadonly = projects ? projects.find(p => p.templateId === t.id) !== undefined : true;
                return <ProjectsTemplate
                onDelete={deleteTemplate}
                onEdit={navigateToEdit}
                accessScope={accessScope}
                orgId={match?.params.orgId}
                readonly={isReadonly}
                allowModification={(accessScope.roleId === RolesMapper.UBER_ADMIN) && !match?.params.orgId} api={apiClient}
                key={`project-template-${t.id}`} template={t}
            />})}
        </div>}
    </div>
}

export default ProjectsTemplatesList;
