import GroupsController from "../controllers/groupsController";
import ClientsController from "../controllers/clientsController";

const useGroupOperations = (api) => {

    const groupsController = new GroupsController({apiClient: api});
    const clientsController = new ClientsController({apiClient: api});

    const addToGroup = (clientsIds, groupId, onClientAddedToGroup) => {
        const promises = clientsIds.map(clientId => {
            return new Promise((resolve) => {
                const result = groupsController.addClientToGroup(clientId, groupId);
                return resolve(result);
            });
        });
        Promise.all(promises).then(() => {
            if (onClientAddedToGroup) {
                onClientAddedToGroup();
            }
        });
    }

    const addClientToGroups = async (groupsIds, clientId) => {
        if (groupsIds.length > 0) {
                for (const groupId of groupsIds) {
                    await clientsController.addToGroup(clientId, groupId);
            }
        }
    }

    const addToNewGroup = async (clientsIds) => {
        const groupName = `Group ${Date.now()}`;
        const createdGroupId = await groupsController.createGroup(groupName);
        addToGroup(clientsIds, createdGroupId);
        return { id: createdGroupId, name: groupName };
    }

    const getList = async (page, searchText, limit, filter) => {
        return await groupsController.list(page, searchText, true, limit, filter)
    }

    return [addToGroup, addClientToGroups, addToNewGroup, getList];
};

export default useGroupOperations;
