import './documentsTable.css';
import {Dropdown, Space, Table, Tooltip} from 'antd';
import UploadDocumentIcon from '../../../assets/images/upload-document.svg';
import {
  DeleteOutlined,
  FolderOutlined,
  StarOutlined
} from '@ant-design/icons';
import moment from 'moment/moment';
import {UserAvatar} from '../../userAvatar/userAvatar';
import {Link, useParams} from 'react-router-dom';
import {useEffect, useMemo, useState} from 'react';
import Dots from "../../../assets/images/dots-devices.svg";

const getFormattedDate = (customDate) => {
  const currentYear = new Date().getFullYear();
  const momentDate = moment(customDate);
  if (momentDate.year() - currentYear > 1) {
    return momentDate.format('D MMMM, YYYY');
  } else {
    return momentDate.format('D MMMM');
  }
};

const DocumentsTable = ({dataSource, isSearching, pagination, setPagination, onDocumentDelete, onDocumentSelected, parentDocument, selectedDocument}) => {
  const {orgId, projectId} = useParams();
  const [selectedRowKey, setSelectedRowKey] = useState(selectedDocument?.id);

  useEffect(() => {
    setSelectedRowKey(selectedDocument?.id)
  }, [selectedDocument]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Name',
        key: 'documentsTableDocumentName',
        dataIndex: 'name',
        render: (documentName, record) => {
          const isFolder = record?.documentType?.toLowerCase() === 'folder';
          return <div className="documents-page-table-document-name-column">
            {isFolder ?
              <Link onClick={e => e.stopPropagation()} to={`/clients/${orgId}/projects/details/${projectId}/documents/${record.id}`}
                    className="documents-page-table-folder-record">
                <FolderOutlined style={{fontSize: '19px'}}/>
                <span>{documentName}</span>
              </Link>
              : <>
                <img src={UploadDocumentIcon} alt="upload-document"/>
                <a className="document-download" href={record.url} onClick={e => e.stopPropagation()} target="_blank" title={documentName}>{documentName}</a>
              </>}
          </div>;
        }
      },
      {
        title: '',
        key: `documentsTableIsFavourite`,
        width: 34,
        dataIndex: 'isFavourite',
        render: () => {
          return <StarOutlined/>;
        }
      },
      {
        title: 'Created',
        key: 'documentsTableDocumentCreatedDate',
        dataIndex: 'dateCreated',
        width: 108,
        render: (createdDate) => {
          return <span>{getFormattedDate(createdDate)}</span>;
        }
      },
      {
        title: 'Added',
        key: 'userFileName',
        dataIndex: 'avatar',
        width: 108,
        render: (avatarUrl, record) => {
          return <Tooltip placement="top" title={`${record.userFirstName} ${record.userLastName}`}>
                    <span>
                        <UserAvatar name={`${record.userFirstName} ${record.userLastName}`} fileName={record.userFileName}/>
                    </span>
          </Tooltip>
        }
      },
      {
        title: '',
        key: 'documentsTableOperations',
        width: 40,
        render: (text, record, index) => {
          const items = [
            { key: `delete-${index}`, danger:true, title: 'Delete', label: <div data-testid={`delete-${index}`} onClick={(e)=> {onDocumentDelete(record.id, record.documentType === 'folder', null, parentDocument); e.stopPropagation();}}>Delete</div>, icon: <DeleteOutlined/>},
          ];
          return <Dropdown menu={{items}} placement="bottomRight"  overlayClassName="overlay-dot-menu" openClassName="open-dot-menu" className="documents-dot-menu" trigger='click' disabled={false}>
            <a data-testid={`dots-menu-${index}`} className="action-menu-container" onClick={e => e.stopPropagation()}>
              <Space>
                <img src={Dots} alt=""/>
              </Space>
            </a>
          </Dropdown>
        },
        className: 'table-action',
      }

    ];
  }, [orgId, projectId]);

  return <>
    <Table
      data-testid="documents-page-table"
      columns={columns}
      dataSource={dataSource}
      loading={isSearching}
      sele
      rowKey={record => record.id}
      pagination={pagination}
      scroll={{y: 'calc(100vh - 386px)'}}
      rowClassName={ (record) => {
        if (record?.id === selectedRowKey) {
          return 'ant-table-row-selected';
        }
        return '';
      }}
      onRow={(record) => {
        return {
          onClick: () => { setSelectedRowKey(record.id); onDocumentSelected(record) }
        };
      }}
      onChange={({current}) => setPagination({...pagination, current: current})}
      rowSelection={{
      }}
    />
  </>;
};

export default DocumentsTable;
