import './App.css';
import './customAnt/ant.css';
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import {RecoilRoot} from 'recoil';
import AssetInventoryPage from "./components/assetInventoryPage/assetInventoryPage";
import IntegrationsPage from "./components/integrationsPage/integrationsPage";
import IntegrationsConfigPage from "./components/integrationsConfigPage/integrationsConfigPage";
import UserPage from "./components/user/userPage";
import ScopeBasedRoute from "./scopeBasedRoute";
import UnconstructedPage from "./components/unconstructedPage/unconstructedPage";
import UsersList from "./components/usersList/usersList";
import ClientsList from "./components/clientsList/clientsList";
import BaseLayout from "./components/baseLayout/baseLayout";
import Scan from "./components/scan/scan";
import DeviceScans from "./components/deviceScans/deviceScans";
import IntegrationOverview from "./components/integrationOverview/integrationOverview";
import DataSets from "./components/datasets/datasets";
import MasterDataSets from "./components/masterDataSets/masterDataSets";
import ProjectsList from "./components/projectsList/projectsList";
import ProjectDescriptionPage from "./components/projectDescriptionPage/projectDescriptionPage";
import TemplateSummaryPage from "./components/templateSummaryPage/templateSummaryPage";
import ProjectsTemplatesList from "./components/projectsTemplatesList/projectsTemplatesList";
import TasksList from "./components/tasksList/tasksList";
import ClientSummary from "./components/clientSummary/clientSummary";
import LoggedOut from "./components/loggedOut/loggedOut";

import { useIdleTimer } from 'react-idle-timer';
import ListPage from "./components/listPage/listPage";
import VendorList from "./components/vendorList/vendorList";

function App(props) {

    const organizationId = props.accessScope?.organizationId;
    const userId = props.accessScope?.userId;
    const defaultPath = `/clients/${organizationId}/projects`;

    const isLoginRequired = () => {
        return !props.isAuthenticated && window.location.pathname !== '/logged-out';
    }

    const onIdle = async () => {
        const url = window.location;
        const baseUrl = url.protocol + "//" + url.host;
        await props.auth.refreshToken();
        props.auth.keycloak.logout({
            'redirectUri': `${baseUrl}/logged-out?r=` + window.location.href
        });
    }

    if (isLoginRequired()) {
        props.auth.login();
        return;
    }

    const idleTimeOut = process.env.REACT_APP_IDLE_TIMEOUT * 1000 * 60 || 30 * 1000 * 60;

    if (props.isAuthenticated) {
        useIdleTimer({
            timeout: idleTimeOut,
            onIdle: onIdle
        });
    }

    return (
        <RecoilRoot>
            <Router>
                {props.isAuthenticated &&
                    <Routes>
                        <Route exact path="" element={<BaseLayout accessScope={props.accessScope} onLogout={props.auth.logout} />}>
                            <Route path="projects" element={
                                <ScopeBasedRoute pathname="/projects" children={<ProjectsList showTemplates={false} />}
                                                 accessScope={props.accessScope} claims={['projects']} redirectOnFailTo={defaultPath} />}
                            />
                            <Route path="projects/:page" element={
                                <ScopeBasedRoute pathname="/projects/:page" children={<ProjectsList showTemplates={false} />}
                                                 accessScope={props.accessScope} claims={['templates']} redirectOnFailTo={defaultPath} />}
                            />
                            <Route path="clients/:orgId/*" element={<UnconstructedPage />}/>
                            <Route path="*" element={<UnconstructedPage />}/>
                            <Route path="/clients/:orgId/data-sets" element={<DataSets />}>
                                <Route path="master-data-sets" element={
                                    <ScopeBasedRoute pathname="/clients/:orgId/data-sets/master-data-sets" children={<MasterDataSets/>}
                                                     accessScope={props.accessScope} claims={['integration']} redirectOnFailTo={defaultPath}/>}
                                />
                            </Route>
                            <Route path="clients/:orgId/users" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/users" children={<UsersList />}
                                                 accessScope={props.accessScope} claims={['user']} redirectOnFailTo={defaultPath} />}
                            />
                            <Route path='projects/templates/add' element={
                                <ScopeBasedRoute pathname="/projects/templates/add" children={<TemplateSummaryPage/>}
                                                 accessScope={props.accessScope} claims={['projects']} redirectOnFailTo={defaultPath}
                                />}
                            />
                            <Route path='projects/templates/:page/:templateId' element={
                                <ScopeBasedRoute pathname="/projects/templates/:page/:templateId" children={<TemplateSummaryPage/>}
                                                 accessScope={props.accessScope} claims={['projects']} redirectOnFailTo={defaultPath}
                                />}
                            />
                            <Route path="clients/:orgId/projects" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/projects" children={<ProjectsList showTemplates={true} />}
                                                 accessScope={props.accessScope} claims={['projects']} redirectOnFailTo={`/clients/${organizationId}/projects`} />}
                            />
                            <Route path="clients/:orgId/projects/templates" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/projects/templates" children={<ProjectsTemplatesList showBackButton={true} />}
                                                 accessScope={props.accessScope} claims={['templates']} redirectOnFailTo={`/clients/${organizationId}/projects/templates`} />}
                            />
                            <Route path='clients/:orgId/projects/details' element={
                                <ScopeBasedRoute pathname="/clients/:orgId/projects/details" children={<ProjectDescriptionPage />}
                                                 accessScope={props.accessScope} claims={['projects']} redirectOnFailTo={`/clients/${organizationId}/projects/details`}
                                />}
                            />
                            <Route path="clients/:orgId/projects" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/projects" children={<ProjectsList showTemplates={false} />}
                                                 accessScope={props.accessScope} claims={['projects']} redirectOnFailTo={`/clients/${organizationId}/projects`} />}
                            />
                            <Route path='/clients/:orgId/projects/details/:projectId' element={
                                <ScopeBasedRoute pathname="/clients/:orgId/projects/details/:projectId" children={<ProjectDescriptionPage allowOwner={false} />}
                                                 accessScope={props.accessScope} claims={['projects']} redirectOnFailTo={defaultPath}
                                />}
                            />
                            <Route path='/:orgId/:taskPrefix' element={
                                <ScopeBasedRoute pathname="/:orgId/:taskPrefix" children={<ProjectDescriptionPage allowOwner={false} />}
                                                 accessScope={props.accessScope} claims={['projects']} redirectOnFailTo={`/${organizationId}/:taskPrefix`}
                                />}
                            />
                            <Route path='clients/:orgId/projects/details' element={
                                <ScopeBasedRoute pathname="/clients/:orgId/projects/details" children={<ProjectDescriptionPage allowOwner={false} />}
                                                 accessScope={props.accessScope} claims={['projects']} redirectOnFailTo={`/clients/${organizationId}/projects/details`}
                                />}
                            />
                            <Route path='/clients/:orgId/projects/details/:projectId/:page' element={
                                <ScopeBasedRoute pathname="/clients/:orgId/projects/details/:projectId/:page" children={<ProjectDescriptionPage accessScope={props.accessScope} />}
                                                 accessScope={props.accessScope} claims={['projects']} redirectOnFailTo={defaultPath}
                                />}
                            />
                            <Route path='/clients/:orgId/projects/details/:projectId/documents' element={
                                <ScopeBasedRoute pathname="/clients/:orgId/projects/details/:projectId/documents" children={<ProjectDescriptionPage accessScope={props.accessScope} />}
                                                 accessScope={props.accessScope} claims={['projects']} redirectOnFailTo={defaultPath}
                                />}
                            />
                            <Route path='/clients/:orgId/projects/details/:projectId/documents/:documentId' element={
                                <ScopeBasedRoute pathname="/clients/:orgId/projects/details/:projectId/documents/:documentId" children={<ProjectDescriptionPage accessScope={props.accessScope} />}
                                                 accessScope={props.accessScope} claims={['projects']} redirectOnFailTo={defaultPath}
                                />}
                            />
                            <Route path="clients/:orgId/users/add" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/users/add" children={<UserPage />}
                                                 accessScope={props.accessScope} claims={['user']} redirectOnFailTo={defaultPath} />}
                            />
                            <Route path="clients/:orgId/users/:userId/info" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/users/:userId/info" children={<UserPage />}
                                                 accessScope={props.accessScope} claims={['user']} redirectOnFailTo={defaultPath} />}
                            />
                            <Route path="clients/:orgId/users/:userId/devices" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/users/:userId/devices" children={<UserPage />}
                                                 accessScope={props.accessScope} claims={['user']} redirectOnFailTo={defaultPath} />}
                            />

                            <Route path="/clients/:orgId/integrations" element={<IntegrationsPage />}>
                                <Route path="scanners" element={
                                    <ScopeBasedRoute pathname="/clients/:orgId/integrations/scanners" children={<IntegrationOverview/>}
                                                     accessScope={props.accessScope} claims={['integration']} redirectOnFailTo={defaultPath}/>}
                                />
                            </Route>
                            <Route path="/clients/:orgId/integrations/scanner-devices/:scan" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/integrations/scanner-devices/:scan" children={<DeviceScans showTitle={true} allowExport={true} />}
                                                 accessScope={props.accessScope} claims={['integration']} redirectOnFailTo={defaultPath}/>}
                            />
                            <Route path="/clients/:orgId/integrations/scanner-devices/:scan/add" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/integrations/scanner-devices/:scan/add" children={<Scan />}
                                                 accessScope={props.accessScope} claims={['integration']} redirectOnFailTo={defaultPath} />}
                            />
                            <Route path="/clients/:orgId/integrations/scanner-devices/:scan/:scanId/:summary" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/integrations/scanner-devices/:scan/:scanId/:summary" children={<Scan/>}
                                                 accessScope={props.accessScope} claims={['integration']} redirectOnFailTo={defaultPath} />}
                            />
                            <Route path="/clients/:orgId/integrations/scanner-clouds" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/integrations/scanner-clouds" children={<DeviceScans showTitle={true} allowExport={true} />}
                                                 accessScope={props.accessScope} claims={['integration']} redirectOnFailTo={defaultPath} />}
                            />
                            <Route path="/clients/:orgId/integrations/scanner-clouds/:scan" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/integrations/scanner-clouds/:scan" children={<DeviceScans showTitle={true} allowExport={true} />}
                                                 accessScope={props.accessScope} claims={['integration']} redirectOnFailTo={defaultPath} />}
                            />
                            <Route path="/clients/:orgId/integrations/scanner-clouds/:scan/add" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/integrations/scanner-clouds/:scan/add" children={<Scan />}
                                                 accessScope={props.accessScope} claims={['integration']} redirectOnFailTo={defaultPath} />}
                            />
                            <Route path="/clients/:orgId/integrations/scanner-clouds/:scan/:scanId/:summary" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/integrations/scanner-clouds/:scan/:scanId/:summary" children={<Scan/>}
                                                 accessScope={props.accessScope} claims={['integration']} redirectOnFailTo={defaultPath} />}
                            />

                            <Route path='/clients' element={
                                <ScopeBasedRoute pathname="/clients" children={<ClientsList />}
                                                 accessScope={props.accessScope} claims={['organization']} redirectOnFailTo={defaultPath}
                                />}
                            />
                            <Route path="clients/:orgId/assets/summary" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/assets/summary" children={<AssetInventoryPage userId={userId} />}
                                                 accessScope={props.accessScope} claims={['assets']} redirectOnFailTo={defaultPath}
                                />}
                            />
                            <Route path="clients/:orgId/assets/devices" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/assets/devices" children={<AssetInventoryPage userId={userId} />}
                                                 accessScope={props.accessScope} claims={['assets']} redirectOnFailTo={defaultPath}
                                />}
                            />
                            <Route path="clients/:orgId/assets/devices/:deviceId/device-summary" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/assets/devices/:deviceId/device-summary" children={<AssetInventoryPage userId={userId} />}
                                                 accessScope={props.accessScope} claims={['assets']} redirectOnFailTo={defaultPath}
                                />}
                            />
                            <Route path="clients/:orgId/assets/devices/:deviceId/vulnerabilities" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/assets/devices/:deviceId/vulnerabilities" children={<AssetInventoryPage userId={userId} />}
                                                 accessScope={props.accessScope} claims={['assets']} redirectOnFailTo={defaultPath}
                                />}
                            />
                            <Route path="clients/:orgId/assets/clouds" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/assets/clouds" children={<AssetInventoryPage userId={userId} />}
                                                 accessScope={props.accessScope} claims={['assets']} redirectOnFailTo={defaultPath}
                                />}
                            />
                            <Route path="clients/:orgId/assets/clouds/add-cloud" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/assets/clouds/add-cloud" children={<AssetInventoryPage userId={userId} />}
                                                 accessScope={props.accessScope} claims={['assets']} redirectOnFailTo={defaultPath}
                                />}
                            />
                            <Route path="clients/:orgId/assets/clouds/:deviceId/cloud-summary" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/assets/clouds/:deviceId/cloud-summary" children={<AssetInventoryPage userId={userId} />}
                                                 accessScope={props.accessScope} claims={['assets']} redirectOnFailTo={defaultPath}
                                />}
                            />
                            <Route path="clients/:orgId/assets/clouds/:deviceId/issues" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/assets/clouds/:deviceId/issues" children={<AssetInventoryPage userId={userId} />}
                                                 accessScope={props.accessScope} claims={['assets']} redirectOnFailTo={defaultPath}
                                />}
                            />
                            <Route path="/integrations-config/devices/scanner-devices/:scan" element={
                                <ScopeBasedRoute pathname="/integrations-config/devices/scanner-devices/:scan" children={<IntegrationsConfigPage />}
                                                 accessScope={props.accessScope} claims={['integrations-config']} redirectOnFailTo={`/clients/${organizationId}/integrations/scanners`} />}
                            />
                            <Route path="/integrations-config/devices/scanner-devices/:scan/add" element={
                                <ScopeBasedRoute pathname="/integrations-config/devices/scanner-devices/:scan/add" children={<IntegrationsConfigPage />}
                                                 accessScope={props.accessScope} claims={['integrations-config']} redirectOnFailTo={`/clients/${organizationId}/integrations/scanners`} />}
                            />
                            <Route path="/integrations-config/devices/scanner-devices/:scan/:scanId/:summary" element={
                                <ScopeBasedRoute pathname="/integrations-config/devices/scanner-devices/:scan/:scanId/:summary" children={<IntegrationsConfigPage />}
                                                 accessScope={props.accessScope} claims={['integrations-config']} redirectOnFailTo={`/clients/${organizationId}/integrations/scanners`} />}
                            />
                            <Route path="/integrations-config/clouds/scanner-clouds/:scan" element={
                                <ScopeBasedRoute pathname="/integrations-config/clouds/scanner-clouds/:scan" children={<IntegrationsConfigPage />}
                                                 accessScope={props.accessScope} claims={['integrations-config']} redirectOnFailTo={`/clients/${organizationId}/integrations/scanners`} />}
                            />
                            <Route path="/integrations-config/clouds/scanner-clouds/:scan/add" element={
                                <ScopeBasedRoute pathname="/integrations-config/clouds/scanner-clouds/:scan/add" children={<IntegrationsConfigPage />}
                                                 accessScope={props.accessScope} claims={['integrations-config']} redirectOnFailTo={`/integrations-config`} />}
                            />
                            <Route path="/integrations-config/clouds/scanner-clouds/:scan/:scanId/:summary" element={
                                <ScopeBasedRoute pathname="/integrations-config/clouds/scanner-clouds/:scan/:scanId/:summary" children={<IntegrationsConfigPage />}
                                                 accessScope={props.accessScope} claims={['integrations-config']} redirectOnFailTo={`/clients/${organizationId}/integrations/scanners`} />}
                            />
                            <Route path="/integrations-config/clouds/scanner-clouds/:scan/:scanId/:summary" element={
                                <ScopeBasedRoute pathname="/integrations-config/clouds/scanner-clouds/:scan/:scanId/:summary" children={<IntegrationsConfigPage />}
                                                 accessScope={props.accessScope} claims={['integrations-config']} redirectOnFailTo={`/clients/${organizationId}/integrations/scanners`} />}
                            />
                            <Route path="/tasks" element={
                                <ScopeBasedRoute pathname="/tasks" children={<TasksList />}
                                                 accessScope={props.accessScope} claims={['user']} redirectOnFailTo={defaultPath} />}
                            />
                            <Route path="clients/:orgId/vendors/list" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/vendors/list" children={<VendorList />}
                                                 accessScope={props.accessScope} claims={['user']} redirectOnFailTo={defaultPath} />}
                            />
                            <Route path="/clients/:orgId/tasks" element={
                                <ScopeBasedRoute pathname="/clients/:orgId/tasks" children={<TasksList />}
                                                 accessScope={props.accessScope} claims={['user']} redirectOnFailTo={`/clients/${organizationId}/tasks`} />}
                            />
                            <Route path="/clients/:orgsId/summary" element={
                                <ScopeBasedRoute pathname="/clients/:orgsId/summary" children={<ClientSummary />}
                                                 accessScope={props.accessScope} claims={['organization']} redirectOnFailTo={defaultPath} />}
                            />
                            <Route path="" element={
                                <ScopeBasedRoute pathname="/projects" children={<ProjectsList showTemplates={false} />}
                                                 accessScope={props.accessScope} claims={['projects']} redirectOnFailTo={defaultPath} />}
                            />
                            <Route path="*" element={<UnconstructedPage />}/>
                            <Route path="data-sets/master-data-sets" element={<UnconstructedPage />}/>
                        </Route>
                        <Route
                            path="/clients/:orgId/integrations/scanner-devices"
                            element={<Navigate to="summary" />}
                        />
                        <Route
                            path="/clients/:orgId/integrations/scanner-clouds"
                            element={<Navigate to="summary" />}
                        />
                        <Route
                            path="/clients/:orgId/integrations/scanner-devices/:scan/:scanId"
                            element={<Navigate to="summary" />}
                        />
                        <Route
                            path="/clients/:orgId/users/:userId"
                            element={<Navigate to="info" />}
                        />
                        <Route
                            path="/clients/assets/*"
                            element={<Navigate to="/" />}
                        />
                        <Route
                            path="/assets/*"
                            element={<Navigate to="/" />}
                        />
                        <Route
                            path="/clients/:orgId/assets"
                            element={<Navigate to="devices" />}
                        />
                        <Route
                            path="/clients/:orgId/assets/devices/:deviceId"
                            element={<Navigate to="device-summary" />}
                        />
                        <Route
                            path="/clients/:orgId/assets/clouds/:deviceId"
                            element={<Navigate to="cloud-summary" />}
                        />
                        <Route path="/clients/:orgId/integrations/devices/scanner-devices/summary"
                               element={<Navigate to={`/clients/${organizationId}/integrations/scanner-devices/summary`} />}
                        />
                    </Routes>
                }
                {!props.isAuthenticated && <Routes>
                        <Route path="logged-out" element={<LoggedOut auth={props.auth} />} />
                    </Routes>}

            </Router>
        </RecoilRoot>
    );
}

export default App;
