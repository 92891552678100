import React, {useEffect, useState} from 'react';
import './userSummary.css';
import {Button, Input, Radio, Statistic, Tooltip, Typography} from 'antd';
import {
    CheckOutlined,
    CheckSquareOutlined,
    LockOutlined,
    QuestionCircleOutlined,
    SendOutlined
} from '@ant-design/icons';
import {useRecoilState} from 'recoil';

import userSummaryState from "../../atoms/userSummary";
import moment from "moment";
import {RolesMapper} from "../../constants";
import isResetPasswordSentState from "../../atoms/isResetPasswordSent";
import organizationPoliciesState from "../../atoms/organizationPolicies";

const UserSummary = ({user, pattern, accessScope, doUserActionEmail}) => {

    const [userId, setUserId] = useState(null);
    const [userFirstName, setUserFirstName] = useState(null);
    const [userLastName, setUserLastName] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [userType, setUserType] = useState(null);
    const [userRole, setUserRole] = useState(RolesMapper.ORG_ADMIN);
    const [userCurrentRole, setCurrentUserRole] = useState();
    const [emailStatus, setEmailStatus] = useState('');
    const [jobTitle, setJobTitle] = useState(null);
    const [userStatus, setUserStatus] = useState('');
    const [userInviteExpired, setUserInviteExpired] = useState('');
    const [userInviteExpiredHour, setUserInviteExpiredHour] = useState(-1);
    const [isResetPasswordSent, setIsResetPasswordSent] = useRecoilState(isResetPasswordSentState);

    const [userState, setUserState] = useRecoilState(userSummaryState);
    const [organizationPolicies] = useRecoilState(organizationPoliciesState);

    const onResetClick =() => {
        doUserActionEmail();
        setTimeout(()=> {
            setIsResetPasswordSent(false);
        }, [5000]);
    }

    useEffect( () => {
        setUserState(null);
        if (user) {
            setUserId(user.id);
            setUserFirstName(user.firstName);
            setCurrentUserRole(user.roleId);
            setUserLastName(user.lastName);
            setUserRole(user.roleId);
            setUserEmail(user.email);
            setUserType(user.type);
            setJobTitle(user.jobTitle);
            setUserStatus(user.status);
            setUserInviteExpired(user.inviteExpired);
        }
    },[user]);

    const onEmailChange = (value) => {
        const isEmailValid = pattern.test(value);
        pattern.test(value);
        if (isEmailValid) {
            setEmailStatus('');
        } else {
            setEmailStatus('error');
        }
        setUserEmail(value);
    }

    useEffect(() => {
        if (userEmail !== null) {
            const newState = Object.assign({}, userState);
            newState.firstName = userFirstName;
            newState.lastName = userLastName;
            newState.email = userEmail;
            newState.type = userType;
            newState.jobTitle = jobTitle;
            newState.roleId = userRole
            setUserState(Object.assign({}, newState));
        }
    },[userFirstName, userLastName, userEmail, userType, jobTitle, userRole]);

    const tooltipText = <div className="title-wrapper">
        <div className="title-block">
            <span>Org Admin</span> can see only the projects on own side
        </div>
        <div>
            <span>Uber Admin</span> can see all client’s projects, and create templates
        </div>
    </div>;

    const getUserSummaryInviteContainer = () => {
        if (userStatus === 'ALL_SET') {
            return (<>
                    <Button danger={!isResetPasswordSent}
                            data-testid="user-summary-invite-button-password"
                            className={isResetPasswordSent ? 'reset-sent' : ''}
                            style={{flex: 'auto'}}
                            icon={isResetPasswordSent ? <CheckSquareOutlined/> : <LockOutlined />}
                            onClick={onResetClick}>{isResetPasswordSent ? 'Password change link is sent to users email!' : 'Reset password'}
                    </Button>
                </>)
        } else {
            const isInviteExpired = userInviteExpiredHour <= 0
            return (<>
                <Typography>
                    {!isInviteExpired && <CheckOutlined style={{color:'#52C41A'}}/>}
                    {!isInviteExpired ? `Invite link sent (${userInviteExpiredHour} hours left)` : 'Send the invite link to user'}
                </Typography>
                <Button type="primary"
                        data-testid="user-summary-invite-button-resend"
                        icon={<SendOutlined />}
                        onClick={doUserActionEmail}>
                    {!isInviteExpired ? 'Resend' : 'Send'}</Button>
            </>)
        }
    }

    useEffect(() => {
        if(userInviteExpired) {
            let now = moment();
            let end = moment(Date.parse(userInviteExpired));
            setUserInviteExpiredHour(moment.duration(end.diff(now)).hours());
        } else {
            setUserInviteExpiredHour(-1);
        }
    },[userInviteExpired])

    return <div className='user-summary' data-testid='scan-summary'>
        <h1>
            Personal info
        </h1>
        <div className="statistic">
            <Statistic title="Risk score" value='0.12' />
        </div>
        <div className="name-group">
            <div>
                <div className="label">Name</div>
                <Input data-testid='name-input' className="info" placeholder='User Name' value={userFirstName} onChange={(e) => setUserFirstName(e.target.value)}/>
            </div>
           <div>
               <div className="label">Surname</div>
               <Input data-testid='surname-input' className="info" placeholder='User Surname' value={userLastName} onChange={(e) => setUserLastName(e.target.value)} />
           </div>
        </div>
        <div className="label">e-mail</div>
        <Input data-testid='email-input' status={emailStatus} className="info" value={userEmail} onChange={(e) => onEmailChange(e.target.value)} />
        {emailStatus === 'error' && <span className="error-description">Email seems to be wrong</span>}
        <div className="label">Job Title</div>
        <Input data-testid='job-input' className="info" value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} />
        <div className="label">Type</div>
        <Radio.Group className="buttons" onChange={(e) => setUserType(e.target.value)} value={userType} >
            <Radio.Button data-testid='EMPLOYEE' value="EMPLOYEE">Employee</Radio.Button>
            <Radio.Button data-testid='CONTRACTOR' value="CONTRACTOR">Contractor</Radio.Button>
            <Radio.Button data-testid='CONSULTANT' value="CONSULTANT">Consultant</Radio.Button>
        </Radio.Group>
        <div className="label">Location</div>
        <Input className="info" disabled/>
        {organizationPolicies?.allowUberRole
            && accessScope?.roleId === RolesMapper.UBER_ADMIN
            && +userCurrentRole !== RolesMapper.UBER_ADMIN
            && <>
            <div className="label-wrapper">
                <div className="label">User type</div>
                <Tooltip title={tooltipText} ><QuestionCircleOutlined/></Tooltip>
            </div>
            <Radio.Group className="buttons" onChange={(e) => setUserRole(e.target.value)} value={userRole} >
                <Radio.Button data-testid='ORG_ADMIN' value={ RolesMapper.ORG_ADMIN}>Org Admin</Radio.Button>
                <Radio.Button data-testid='UBER_ADMIN' value={ RolesMapper.UBER_ADMIN}>Uber Admin</Radio.Button>
            </Radio.Group>

        </>}
        {userId && <>
            {userStatus === 'ALL_SET'
                ? <div data-testid="user-summary-invite-label-password" className="label">Password</div>
                : <div data-testid="user-summary-invite-label-invite" className="label">Invite link</div>}
            <div className="user-summary-invite-container">
                {getUserSummaryInviteContainer()}
            </div>
        </>}
    </div>
}

export default UserSummary;
