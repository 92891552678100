import './editTaskSidebar.css';
import {
    ArrowsAltOutlined,
    CheckOutlined,
    LinkOutlined,
    MoreOutlined,
    CloseOutlined,
    EditOutlined,
    CopyOutlined,
    DeleteOutlined,
    CheckSquareOutlined, ExportOutlined
} from '@ant-design/icons';
import {Input, Dropdown, Button, Radio, Checkbox, DatePicker, Menu, Modal, Tooltip, message, InputNumber} from 'antd';
import React, {useState, useEffect, useRef, useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {showDeactivateConfirm} from "../utils";
import dayjs from 'dayjs';
import TaskDetailsModal from "../taskDetailsModal/taskDetailsModal";
import CheckCircle from '../../assets/images/CheckCircle.svg';
import AssigneesContainer from "../assigneesContainer/assigneesContainer";
import AppComment from "../taskComments/taskComments";
import {UserAvatar} from "../userAvatar/userAvatar";
import SearchableSelect from "../searchableSelect/searchableSelect";
import useTemplateTaskOperations from "../../hooks/useTemplateTaskOperations";
import useTemplateTaskLinkageOperations from "../../hooks/useTemplateTaskLinkageOperations";
import AppCommentTextArea from "../appCommentTextArea/appCommentTextArea";
import {useRecoilValue, useSetRecoilState} from "recoil";
import taskAssignedNotificationsState from "../../atoms/taskAssignedNotifications";
import deadlineTomorrowNotificationsState from "../../atoms/deadlineTomorrowNotification";
import seenNotificationsState from "../../atoms/seenNotifications";

const EditTaskSidebar = ({task, api, closeSidebar, customFormat,
                             refresh, users, orgId, showDetailsModal, onModalClose, loggedUserId, isTemplates, templateId, onTaskUpdating}) => {

    const { RangePicker } = DatePicker;
    const day = 1000 * 60 * 60 * 24;
    const navigate = useNavigate();

    const [allowDueDate, setAllowDueDate] = useState(task.dueDate);
    const [description, setDescription] = useState(task.description);
    const [duration, setDuration] = useState(task.duration);
    const [isDurationActive, setIsDurationActive] = useState(false);
    const [editingName, setEditingName] = useState(false);
    const [editedName, setEditedName] = useState(task.name);
    const [isTaskModalOpened, setIsTaskModalOpened] = useState(false);
    const [isTextareaActive, setIsTextAreaActive] = useState(false);
    const durationRef = useRef(null);
    const closeDurationRef = useRef(null);
    const nameRef = useRef(null);
    const closeNameRef = useRef(null);
    const descriptionRef = useRef(null);
    const closeDescriptionRef = useRef(null);
    const contentWrapperRef = useRef(null);
    const [isLinkCopiedModal, setIsLinkCopiedModal] = useState(false);
    const [isLinkCopied, setIsLinkCopied] = useState(false);
    const [commentText, setCommentText] = useState('');
    const [refreshComments, setRefreshComments] = useState(Date.now());
    const [taskList, setTaskList] = useState([]);
    const [templateList, setTemplateList] = useState([]);
    const [dependencyTask, setDependencyTask] = useState(task.linkageName ? {name: task.linkageName[0], key: task.linkageKey?.[0]} : null)
    const [showDropdownTask, setShowDropdownTask] = useState(false);
    const [commentTextAreaHeight, setCommentTextAreaHeight] = useState(30);

    const [getTemplateTasks, , updateTemplateTask] = useTemplateTaskOperations(api);
    const [createTemplateTasksLinkage, deleteTemplateTasksLinkage] = useTemplateTaskLinkageOperations(api);

    const taskNameInputRef = useRef(null);
    const descriptionInputRef = useRef(null);

    const setSeenNotifications = useSetRecoilState(seenNotificationsState);
    const assignedTasksNotifications = useRecoilValue(taskAssignedNotificationsState);
    const deadlineTomorrowNotifications = useRecoilValue(deadlineTomorrowNotificationsState);

    async function onDeactivate () {
        onTaskUpdating && onTaskUpdating(true);
        if (isTemplates) {
            await updateTemplateTask(task.id, {active: false});
        } else {
            await api.get().tasksApi.taskPatch([`id:${task.id}`], {active: false});
        }
        message.success('Task has been successfully deleted');
        refresh(true);
    }
    const itemsMenu = [
        { key: `duplicate-task`, title: 'Duplicate task', className: 'icon-dropdown', label: <div>Duplicate task</div>, icon: <CopyOutlined />, disabled: true},
        { key: `delete-task`, title: 'Delete task', className: 'icon-dropdown', danger: true, label: <div onClick={ ()=> showDeactivateConfirm('task', onDeactivate)}>Delete task</div>, icon: <DeleteOutlined />},
    ];

    const { TextArea } = Input;

    useEffect(() => {
        if (isTextareaActive) {
            if (descriptionInputRef.current) {
                descriptionInputRef.current.focus();
            }
        }
    }, [isTextareaActive]);
    const textArea = () => {
        return (
            <TextArea
                className="description"
                data-testid="textarea"
                placeholder="Add description"
                onFocus={() => setIsTextAreaActive(true)}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                autoSize={true}
                ref={descriptionInputRef}
            />
        )
    }

    useEffect(() => {
        if (editingName) {
            taskNameInputRef.current.focus();
        }
    }, [editingName]);

    useEffect(async()=>{
        if (!isTemplates) {
            await loadTasks();
        } else {
            await loadTemplatesTasks();
        }
    },[task.phaseId]);

    useEffect(() => {
        setDependencyTask(task.linkageName ? {name: task.linkageName[0], key: task.linkageKey?.[0]} : null);
    }, [task.linkageKey, task.linkageName]);

    useEffect(() => {
        if (!dependencyTask) {
            setDependencyTask('none');
        }
    }, [dependencyTask]);

    useEffect(() => {
        setDuration(task.duration);
    }, [task.duration]);

    useEffect(() => {
        async function handleClickOutsideName(event) {
            if (nameRef.current && !nameRef.current.contains(event.target) && closeNameRef && !closeNameRef.current.contains(event.target)) {
                await handleSaveName();
            }
        }
        document.addEventListener("mousedown", handleClickOutsideName);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideName);
        };
    }, [nameRef, editedName, closeNameRef]);

    useEffect(() => {
        if (showDetailsModal) {
            setIsTaskModalOpened(true);
        }
    }, [showDetailsModal]);

    useEffect(() => {
        const taskNotification = assignedTasksNotifications.find((taskNotification) => taskNotification.params.taskKey === task.prefix);
        const taskTomorrowNotification = deadlineTomorrowNotifications.find((taskNotification) => taskNotification.params.taskKey === task.prefix);
        let notifications = [];
        if (taskNotification) {
            notifications.push(taskNotification);
        }
        if (taskTomorrowNotification) {
            notifications.push(taskTomorrowNotification);
        }
        setSeenNotifications(notifications);
    }, []);

    useEffect(() => {
        async function handleClickOutsideDescription(event) {
            if (descriptionRef.current && !descriptionRef.current.contains(event.target) && closeDescriptionRef && !closeDescriptionRef.current.contains(event.target)) {
                await handleSaveDescription();
            }
        }
        document.addEventListener("mousedown", handleClickOutsideDescription);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideDescription);
        };
    }, [closeDescriptionRef, description, descriptionRef]);

    useEffect(() => {
        async function handleClickOutsideDuration(event) {
            if (durationRef.current && !durationRef.current.contains(event.target) && closeDurationRef && !closeDurationRef.current.contains(event.target)) {
                await handleSaveDuration();
            }
        }
        document.addEventListener("mousedown", handleClickOutsideDuration);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideDuration);
        };
    }, [closeDurationRef, duration, durationRef]);

    useEffect(async () => {
        if (!allowDueDate && task.dueDate) {
            await updateTask({dateDueTo: null})
        }
    }, [allowDueDate])

    const loadTasks = async () => {
        let load = true;
        let page = 1;
        const tasks = [{id: 'none', name: 'None'}];
        while (load) {
            const loadedTasks = await api.get().tasksApi.taskList(
                '',
                [`+id`],
                ['active:true', `projectId:${task.projectId}`],
                [],
                page,
                50
            );
            tasks.push(...loadedTasks.data.data);
            page = loadedTasks.data.page.next;
            load = page != null;
        }
        setTaskList(tasks.filter((t)=>t.id !== task.id))
        return tasks
    };

    const loadTemplatesTasks = async () => {
        let load = true;
        let page = 1;
        const templates = [{id: 'none', name: 'None'}];
        while (load) {
            const loadedTemplates = await getTemplateTasks(page, '', templateId, null, null, null);
            templates.push(...loadedTemplates.data);
            page = loadedTemplates.page;
            load = loadedTemplates.hasNextPage;
        }
        setTemplateList(templates.filter((t)=>t.id !== task.id))
        return templates
    }

    const handleSaveDescription = async () => {
        setIsTextAreaActive(false);
        if (description.trim().length === 0) {
            setDescription('');
        }
        await updateTask({description: description.trim()});
    };

    const handleCloseEditDescription = () => {
        setDescription(task.description);
        setIsTextAreaActive(false);
    }

    const handleSaveName = async () => {
        setEditingName(false);
        if (editedName.trim().length > 0) {
            await updateTask({name: editedName});
        } else {
            setEditedName(task.name);
        }
    };
    const handleEditName = () =>{
        setEditingName(true);
    }

    const handleEditDuration = () => {
        setIsDurationActive(true);
    }

    const handleCloseEdit = () => {
        setEditedName(task.name);
        setEditingName(false);
    }

    const getEndDate = () => new Date(new Date(task.start).getTime() + (day * duration));

    const handleSaveDuration = async () => {
        setIsDurationActive(false);
        if(isTemplates){
            return await updateTask({duration});
        } else {
            return await updateTask({duration, endDate: getEndDate()});
        }
    }

    const handleCloseDurationEdit = () => {
        setDuration(task.duration);
        setIsDurationActive(false);
    }

    const updateTask = async (field) => {
        onTaskUpdating && onTaskUpdating(true);
        if (isTemplates) {
            await updateTemplateTask(task.id, field);
        } else {
            await api.get().tasksApi.taskPatch([`id:${task.id}`], field);
        }
        refresh();
    }

    const showModal = () => {
        setIsTaskModalOpened(!isTaskModalOpened);
    };

    const hideModal = () => {
        setIsTaskModalOpened(false);
        onModalClose();
    };

    const getTaskShortUrls = (isModal) => {
        const port = location.port ? `:${location.port}` : '';
        const url = `${location.protocol}//${location.hostname}${port}/${task.orgId}/${task.prefix}`;
        navigator.clipboard.writeText(url);
        if (isModal) {
            setIsLinkCopiedModal(true);
        } else {
            setIsLinkCopied(true);
        }
    }

    const handleOnKeyDown = (e) => {
        if (e.key === 'Escape') {
            handleCloseDurationEdit();
        }
    }

    const getModalTitle = () => {
        if (isTemplates) {
            return;
        }
        return <div className="task-details-title" data-testid="task-details-title">
            <div className="prefix"><span>{task.projectPrefix} </span> <span>/</span>{task.prefix}</div>
            <div className="icon-container">
                <ArrowsAltOutlined onClick={hideModal} className="popup-icon" style={{marginRight: 22}} />
                <div className={`copy-link ${isLinkCopiedModal ? 'copy-link-check' : ''}`}>
                {isLinkCopiedModal ? <Tooltip title="Link copied to the clipboard"
                                              showArrow={false} placement="bottom" defaultOpen={true} trigger="click"
                                              overlayClassName="copied-message copied-message-modal"
                    ><img alt="" src={CheckCircle} onClick={() => getTaskShortUrls(true)} />
                    </Tooltip>
                    : <LinkOutlined onClick={() => getTaskShortUrls(true)} className="copy-link" data-testid="copy-link-modal" />
                }
                </div>
                <Dropdown placement="bottomRight" dropdownRender={()=> <Menu className="task-dropdown" items={itemsMenu}/>} trigger='click' disabled={false}>
                    <a data-testid={`dropdown-task`} onClick={(e) => e.preventDefault()}>
                        <MoreOutlined data-testid="dropdown" className="popup-icon" style={{marginRight: 18}}/>
                    </a>
                </Dropdown>
                <CloseOutlined onClick={() => {hideModal(); closeSidebar()}} data-testid="close-popup"/>
            </div>
        </div>
    }

    const getTaskName = () => {
        return <div className="task-name">
            {editingName ?
                <span ref={nameRef}>
                        <TextArea ref={taskNameInputRef} maxLength={255} autoSize={{ minRows: 1, maxRows: 8 }} data-testid="input-value" value={editedName} onChange={(e)=> setEditedName(e.target.value)} />
                    </span>
                :
                <p onClick={handleEditName} data-testid="edit-name">{editedName}</p>}
            {!editingName  && <EditOutlined onClick={handleEditName}/>}
            {editingName && <div className="edit-icons" data-testid="edit-icons"><span className="check-outlined">
                    <CheckOutlined data-testid="check" onClick={handleSaveName} /> </span><CloseOutlined ref={closeNameRef} data-testid="close" onClick={handleCloseEdit}/>
            </div> }
        </div>
    }

    const getDescription = () => {
        return <div className="stats-container description-container">
            <p className="subtitle">Description</p>
            {isTextareaActive ?
                <div className="text-area-container">
                    <div className="edit-icons"><span className="check-outlined">
                            <CheckOutlined data-testid="save-description" onClick={()=>handleSaveDescription()} /> </span><CloseOutlined ref={closeDescriptionRef} data-testid="close-icon" onClick={()=>handleCloseEditDescription()}/>
                    </div>
                    <span ref={descriptionRef}>{textArea()}</span>
                </div> :
                <>
                    {textArea()}
                    <EditOutlined onClick={() => setIsTextAreaActive(true)}/>
                </>}
        </div>
    }

    const getDuration = () => {
        return <div className="stats-container duration-container">
            <p className="subtitle">{isTemplates ? 'Estimate' : 'Duration'}</p>
            {isDurationActive ?
                <span ref={durationRef}>
                    <InputNumber onKeyDown={handleOnKeyDown} onPressEnter={handleSaveDuration} autoFocus={true} data-testid="input-duration" min={0} max={1000} value={duration} onChange={(value)=> setDuration(value)} />
                    </span>
                :
                <div onClick={handleEditDuration} data-testid="edit-duration">{duration ?? 0} day{duration !== 1 ? 's' : ''}</div>}
            {!isDurationActive  && <EditOutlined onClick={handleEditDuration}/>}
            {isDurationActive && <div className="edit-icons" data-testid="edit-icons"><span className="check-outlined">
                    <CheckOutlined data-testid="check" onClick={handleSaveDuration} /> </span><CloseOutlined ref={closeDurationRef} data-testid="close" onClick={handleCloseDurationEdit}/>
            </div>}
        </div>
    }

    const saveComment = async (text) => {
        if (text.trim().length === 0) {
            return;
        }
        onTaskUpdating && onTaskUpdating(true);
        await api.get().taskCommentApi.taskCommentCreate({
            organizationId: orgId,
            projectId: task.projectId,
            taskId: task.id,
            text: text,
            authorId: loggedUserId,
            active: true,
            dateCreated: (new Date()).toISOString()
        });
        setCommentText('');
        setRefreshComments(Date.now());
        refresh();
        setTimeout(() => {
            contentWrapperRef.current.scroll({ top: contentWrapperRef.current.scrollHeight, behavior: 'smooth' });
        }, 500);
    }

    const onDateChange = async (date) => {
        const [start, end] = date;
        const duration = (new Date(end).getTime() - new Date(start).getTime()) / day;
        await updateTask({
            startDate: start,
            endDate: end,
            duration: Math.round(duration)
        });
        setDuration(Math.round(duration));
    }

    const handleKeyPress = useCallback(async (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            await saveComment(commentText);
        }
    }, [commentText]);

    const onOpenDropdown = () => setShowDropdownTask(true);

    const handleContentWrapperCommentHeight = (currentCommentHeight) => {
        setCommentTextAreaHeight(currentCommentHeight);
    }

    const getTask = (onOpenDropdown) => {
        if(dependencyTask?.name){
            return (
                <>
                    <div className="dependency-task-container" data-testid="dependency" onClick={onOpenDropdown}>
                        <CheckSquareOutlined/>
                        <span>
                            <span className="dependency-name">{dependencyTask?.name}</span>
                            {!isTemplates && <span className="see-task-container">
                                <Button className="see-task" type="primary" ghost
                                        onClick={() => navigate(`/${orgId}/${dependencyTask?.key}`)}><ExportOutlined/>
                                    See task
                                </Button>
                         </span>}
                        </span>

                    </div>
                </>
            )
        } else {
            return <div onClick={onOpenDropdown}>
                {'None'}
            </div>
        }
    }

    const taskDropDownLabel = taskList?.find(t => t.name === dependencyTask?.name)?.name ?? 'None';
    const templateDropDownLabel = templateList?.find(t => t.name === dependencyTask?.name)?.name ?? 'None';

    const onSelectedValueChanged = async (value) => {
        onTaskUpdating && onTaskUpdating(true);
        if(isTemplates){
            await deleteTemplateTasksLinkage([`templateTaskId: ${task.id}`]);
            setShowDropdownTask(false);
            if (!value || value === 'none') {
                refresh();
                return;
            }
            await createTemplateTasksLinkage({templateTaskId: task.id, boundTemplateTaskId: value});
        } else {
            await api.get().tasksLinkageApi.tasksLinkageDeleteMany([`taskId: ${task.id}`]);
            setShowDropdownTask(false);
            if (!value || value === 'none') {
                refresh();
                return;
            }
            await api.get().tasksLinkageApi.tasksLinkageCreate({taskId: task.id, boundTaskId: value});
        }
        refresh();
    }

    const optionsRenderer = (t) => t.name === 'None' ? <span className='none'>{t.name}</span> : t.name;
    const contentCssClass = isTemplates ? 'content-wrapper' : 'content-wrapper-comment';

    const renderSearchableSelect = (selectedValueLabel, onSelectedValueChanged, options, onDropdownVisibleChange) => (
        <SearchableSelect
            selectedValueLabel={selectedValueLabel}
            className={`task-select`}
            onSelectedValueChanged={onSelectedValueChanged}
            options={options}
            allowClear={true}
            width={'231px'}
            optionsRenderer={optionsRenderer}
            onDropdownVisibleChange={onDropdownVisibleChange}
            defaultOpen={true}
            selectEmptyDropdown={true}
        />
    );

    return <div className="sidebar-wrapper" data-testid="sidebar">
        <Modal
            closable={false}
            title={getModalTitle()}
            open={isTaskModalOpened}
            onOk={() => {}}
            onCancel={hideModal}
            width={900}
            height={644}
            footer={null}
            className="task-edit-popup"
        >
            <TaskDetailsModal
                api={api}
                getTask={getTask}
                onModalClose={onModalClose}
                task={task}
                users={users}
                refresh={refresh}
                customFormat={customFormat}
                onTaskUpdate={updateTask}
                allowDueDate={allowDueDate}
                setAllowDueDate={setAllowDueDate}
                editedName={editedName}
                setEditedName={setEditedName}
                onNameSave={handleSaveName}
                description={description}
                setDescription={setDescription}
                projectId={task.projectId}
                orgId={orgId}
                loggedUserId={loggedUserId}
                onCommentsRequested={() => setRefreshComments(Date.now())}
                taskList={taskList}
                select={renderSearchableSelect}
                dependencyTask={dependencyTask}
            />
        </Modal>
        <div className="sidebar-header">
            {!isTemplates && <div className="prefix"><span>{task.projectPrefix} </span> <span>/</span> {task.prefix}</div>}
            {isTemplates && <div></div>}
            <div className="icon-container">
                {!isTemplates && <ArrowsAltOutlined onClick={showModal} data-testid="show-modal-details" />}
                {!isTemplates && <div className={`copy-link ${isLinkCopiedModal ? 'copy-link-check' : ''}`}>
                    {isLinkCopied ?  <Tooltip title="Link copied to the clipboard"
                                              showArrow={false} placement="bottom" defaultOpen={true} trigger="click"
                                              overlayClassName="copied-message copied-message-sidebar"
                        >
                            <img alt="" src={CheckCircle} data-testid="copy-link" onClick={() => getTaskShortUrls(false)}/>
                        </Tooltip>
                        : <LinkOutlined onClick={() => getTaskShortUrls(false)} className="copy-link"
                                        data-testid="copy-link"/>
                    }
                </div>}
                  <Dropdown
                            dropdownRender={()=> <Menu className="task-dropdown" items={itemsMenu}/>} trigger='click' disabled={false}>
                        <a data-testid={`dropdown-task`} onClick={(e) => e.preventDefault()}>
                            <MoreOutlined data-testid="dropdown"/>
                        </a>
                    </Dropdown>
                <CloseOutlined onClick={() => closeSidebar()} data-testid="close-sidebar"/>
            </div>
        </div>
        <div className={contentCssClass} ref={contentWrapperRef} style={isTemplates ? {} : {height: `calc(100% - ${commentTextAreaHeight + 113}px)`}}>
            {getTaskName()}
            {getDescription()}
            {!isTemplates && <div className="stats-container status">
                <p className="subtitle">Status</p>
                <Radio.Group data-testid="status" className="buttons" onChange={(e) => updateTask({status: e.target.value})} value={task.status} >
                    <Radio.Button data-testid='TODO' value="TODO">To do</Radio.Button>
                    <Radio.Button data-testid='IN PROGRESS' value="IN PROGRESS">In progress</Radio.Button>
                    <Radio.Button data-testid='DONE' value="DONE">Done</Radio.Button>
                </Radio.Group>
            </div>}
            <div className="stats-container">
                <p className="subtitle">Priority</p>
                <Radio.Group data-testid="priority" className="buttons" onChange={(e) => updateTask({priority: e.target.value})} value={task.priority} >
                    <Radio.Button data-testid='DEFAULT'  value="DEFAULT">Default</Radio.Button>
                    <Radio.Button data-testid='CRITICAL' value="CRITICAL">Critical</Radio.Button>
                    <Radio.Button data-testid='BLOCKER' value="BLOCKER">Blocker</Radio.Button>
                </Radio.Group>
            </div>
            {!isTemplates && <div className="stats-container data-picker">
                <p className="subtitle">Due to</p>
                <Checkbox data-testid="checkbox" className="checkbox" checked={allowDueDate} onChange={(e) => setAllowDueDate(e.target.checked)}/>
                <DatePicker format={customFormat}
                            placeholder="Deadline date"
                            data-testid="data-picker"
                            onChange={async (date) => {
                                await updateTask({dateDueTo: date})
                            }}
                            disabled={!allowDueDate}
                            value={task.dueDate ? dayjs(task.dueDate) : null}
                />
            </div>}
            {!isTemplates && <div className="stats-container duration">
                <p className="subtitle">Start and end date</p>
                <RangePicker onChange={onDateChange}
                             data-testid="date-picker"
                             allowClear={false}
                             format={customFormat}
                             value={task.start ? [dayjs(task.start), dayjs(task.end)] : null} />
            </div>}
            {getDuration()}
            <div className="stats-container dependency-task">
                <p className="subtitle">Dependency on task</p>
                {!isTemplates ?  <>
                    {!showDropdownTask && (taskList?.length > 0 || !taskList?.length) && getTask(onOpenDropdown)}
                     {showDropdownTask && renderSearchableSelect(taskDropDownLabel, onSelectedValueChanged, taskList, setShowDropdownTask)}
                </> :
                 <>
                     {!showDropdownTask && (templateList?.length > 0 || !templateList?.length) && getTask(onOpenDropdown)}
                     {showDropdownTask && renderSearchableSelect(templateDropDownLabel, onSelectedValueChanged, templateList, setShowDropdownTask)}
                 </>
                }
              </div>
                {!isTemplates && <div className="stats-container">
                    <p className="subtitle">Created by</p>
                <div className="assignees-container">
                    <UserAvatar name={task.createdBy} fileName={task.fileName}/>
                    <p className="created-by">{task.createdBy}</p>
                </div>
            </div>}
            {!isTemplates &&
                <div className="stats-container assignee">
                    <p className="subtitle">Assignee</p>
                    <AssigneesContainer task={task} users={users} updateTask={updateTask}/>
                </div>
            }
            {!isTemplates &&
                <div className="comments-section">
                    <AppComment api={api} loggedUserId={loggedUserId} taskId={task.id} orgId={orgId}
                                  projectId={task.projectId} requestReload={refreshComments} refresh={refresh} />
                </div>
            }
        </div>
        {!isTemplates &&
            <AppCommentTextArea onResize={handleContentWrapperCommentHeight} commentText={commentText} handleKeyPress={handleKeyPress} saveComment={saveComment} setCommentText={setCommentText}/>
        }
    </div>
}

export default EditTaskSidebar;
