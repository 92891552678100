import { useState } from 'react';
import UsersController, {GENERAL_ERROR} from "../controllers/usersController";

const useUsersOperations = (api) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const usersController = new UsersController({apiClient: api});

    const getUberAdmins = async () => {
        setLoading(true);
        const result = await usersController.loadUbers();
        if (result === GENERAL_ERROR) {
            setLoading(false);
            setError('Unable to load uber admins');
        }
        return result;
    }

    return [getUberAdmins, loading, error];
};

export default useUsersOperations;
