import './deviceScans.css';
import DevicesScanner from '../../assets/images/devices-scanner.svg';
import CloudsScanner from '../../assets/images/clouds-scanner.svg';

import {
    Dropdown,
    Space,
    Tag,
    message,
} from 'antd';
import {
    DeleteOutlined,
    SettingOutlined,
    EditOutlined,
    ShareAltOutlined,
    ToolOutlined,
    ArrowLeftOutlined,
    InfoCircleOutlined
} from '@ant-design/icons';
import ApiClient from "../../apiClient";
import {RolesMapper, scanSubTypes} from "../../constants";

import {
    useParams,
    useNavigate,
    useLocation
} from "react-router-dom";

import {
    useEffect,
    useState
} from "react";

import Dots from "../../assets/images/dots-devices.svg";
import ListPage from "../listPage/listPage";
import EmptyPage from "../emptyPage/emptyPage";
import {showDeactivateConfirm} from "../utils";
import SidebarLayout from "../sidebarLayout/sidebarLayout";
import {useRecoilState} from "recoil";
import selectedScansState from "../../atoms/selectedScans";
import selectedScansKeysState from "../../atoms/selectedScansKeys";
import ScanListCommands from "../scanListCommands/scanListCommands";
import scanDeletedState from "../../atoms/scanDeleted";
import noProjects from "../../assets/images/no-clients.svg";

const limit = 20;

const DeviceScans = ({api, accessScope, showVioletXOnly, showTitle, allowExport, type}) => {

    let apiClient = api;

    if (!apiClient) {
        apiClient = new ApiClient();
    }

    const {orgId, scan} = useParams();
    const {pathname} = useLocation();
    const clouds = pathname.split('/').find((item)=>item === "scanner-clouds");
    type = type ?? (clouds === 'scanner-clouds' ? "CLOUDS": "DEVICES");
    const title = type === 'CLOUDS' ? "VX Clouds Scans" : "VX Devices Scans";
    const navigate = useNavigate();
    const [, setSelectedScans] = useRecoilState(selectedScansState);
    const [, setSelectedScansKeys] = useRecoilState(selectedScansKeysState);
    const [, setScanDeletedState] = useRecoilState(scanDeletedState);

    const titlesMap = {
        'summary': 'Summary',
        'system-scans': 'System scans',
        'violetx-scans': 'VioletX scans',
        'custom-scans': 'Custom scans',
    }

    const typesMap = {
        'system-scans': scanSubTypes.SYSTEM,
        'violetx-scans': scanSubTypes.VIOLET_X,
        'custom-scans': scanSubTypes.CUSTOM,
    }

    const tabs = [];
    tabs.push({ "data-testid": 'tab-summary', title: 'Summary', key:'summary', label: 'Summary', icon: <InfoCircleOutlined />, disabled: false, page: <h1>Summary</h1>, onClick: () => { onTabSelect(['summary'])} });
    if (accessScope.roleId === RolesMapper.UBER_ADMIN) {
        tabs.push({ "data-testid": 'tab-system-scans', title: 'System scans', key:'system-scans', label: 'System scans', icon: <SettingOutlined />, disabled: false, page: <h1>System scans</h1>, onClick: () => { onTabSelect(['system-scans'])} })
    }

    tabs.push({ "data-testid": 'tab-violetx-scans', title: 'VioletX scans', key:'violetx-scans', label: 'VioletX scans', icon: <ShareAltOutlined />, disabled: false, page: <h1>VioletX scans</h1>, onClick: () => { onTabSelect(['violetx-scans'])} });
    tabs.push({ "data-testid": 'tab-custom-scans', title: 'Custom scans', key:'custom-scans', label: 'Custom scans', icon: <ToolOutlined />, disabled: false, page: <h1>Custom scans</h1>, onClick: () => { onTabSelect(['custom-scans'])} });

    const onTabSelect = (tab) => {
        setDefaultSelectedKeys(tab);
        const link = pathname.slice(0, pathname.lastIndexOf("/"));
        navigate(`${link}/${tab}`);
    }

    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState(null);

    useEffect(() => {
        if (!showVioletXOnly) {
            setSelectedScans([]);
            setSelectedScansKeys([]);
        }
    },[type, defaultSelectedKeys]);

    useEffect(() => {
        if(tabs && scan) {
            const selectedTab = tabs.find((t) => t.key === scan);
            if (!selectedTab && scan === 'system-scans') {
               return navigate(`/clients/${orgId}/integrations/${type.toLowerCase()}/scanner-${type.toLowerCase()}/${[tabs[0].key]}`);
            }
            setDefaultSelectedKeys([selectedTab.key]);
        }
    },[scan]);


    const onDeviceScanDelete = async (scans) => {
        const plural = scans.length > 1 ? 's' : '';
        const filter = scans.map(i => `id:${i}`);
        try {
            await apiClient.get().scansApi.scanPatch(filter, {active:false});
            message.success(`Scan${plural} has been successfully deleted`);
            setSelectedScansKeys([]);
            setSelectedScans([]);
            setScanDeletedState(Date.now());
        }
        catch (err) {
            message.error(`An error occurred while try to delete scan${plural}`);
        }

    }

    const showDeleteScanConfirm = (scans) => {
        const scansTitle = scans.length <= 1 ? 'scan' : 'scans';
        showDeactivateConfirm(scansTitle, async () => await onDeviceScanDelete(scans));
    }

    const redirectToScan = (item) => {
        const link = pathname.slice(0, pathname.lastIndexOf("/"));
        navigate(`${link}/${defaultSelectedKeys}/${item}/summary`);
    }

    const addScan = () => {
        const link = pathname.slice(0, pathname.lastIndexOf("/"));
        navigate(`${link}/${defaultSelectedKeys}/add`);
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            render: (text, record, index) => {
                return <a onClick={() => redirectToScan(record.id)} data-testid={`scans-${index}`}>{text}</a>
            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            align: 'left',
            render: (text) => <Tag color={'blue'}>{text[0] + text?.toLowerCase().slice(1)}</Tag>
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            key: 'tags',
            align: 'left',
            render: (_, { tags }) => (
                <>
                    {tags.map((tag) => {
                        return (
                            <Tag key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'Queries',
            dataIndex: 'queries',
            key: 'queries',
            align: 'right'
        },
        {
            title: 'Success',
            dataIndex: 'success',
            key: 'success',
            align: 'right',
            render: () => {
                return 0;
            }
        },
        {
            title: '',
            dataIndex: 'actionItems',
            key: 'actionItems',
            render: (text, record, index) => {
                if ((record.type !== scanSubTypes.SYSTEM) && !(record.type === scanSubTypes.VIOLET_X && !showVioletXOnly)) {
                    const items = [
                        { key: `open-edit-${index}`, title: 'Open and edit', label: <div data-testid={`edit-${index}`} onClick={() => redirectToScan(record.id)}>Open and edit</div>, icon: <EditOutlined />},
                        { key: `deactivate-${index}`, title: 'Delete', label: <div data-testid={`deactivate-${index}`} onClick={() => showDeleteScanConfirm([record.id])}>Deactivate</div>, icon: <DeleteOutlined />, danger: true},
                    ];
                    return <Dropdown trigger='click' menu={{items}}>
                        <a data-testid={`dots-menu-${index}`} className="action-menu-container">
                            <Space>
                                <img src={Dots} alt=""/>
                            </Space>
                        </a>
                    </Dropdown>
                }
                return null;
            },
            className: 'table-action',
            width: 50
        }
    ];

    const loadScans = async (page, searchValue) => {
        const orgToLoad = orgId ?? accessScope.organizationId;
        const filter = [`organizationId:${orgToLoad}`, 'active:true', `topic:${type}`, `type:${typesMap[scan]}`];
        if (typesMap[scan]==="SYSTEM" || typesMap[scan]==="VIOLET_X") {
            filter.splice(0, 1);
        }
        const loadedScans = await apiClient.get().scansApi.scanList(
            searchValue.split(' '),
            [``],
            filter,
            ['queryActiveCount', 'deviceCount'],
            page,
            limit
        );
        const convertedScans = loadedScans.data.data.map((item, index) => {
            return {
                id: item.id,
                key: `scan-${item.id}-${index}`,
                name: item.name,
                type: item.type,
                tags: ['tag 1', 'tag 2', 'tag 3'],
                queries: item.queryActiveCount,
                success: 0
            }
        });

        return {
            data: convertedScans,
            totalPages: loadedScans.data.page.total
        }
    }

    const getCommandButtons = (type) => {
        const allowCommands = (type !== scanSubTypes.SYSTEM) && !(type === scanSubTypes.VIOLET_X && !showVioletXOnly);
        return <ScanListCommands allowExport={allowExport} disabled={!allowCommands} onAddScan={addScan} onDelete={showDeleteScanConfirm} />;
    }

    const getListView = (tab, hideLocation) => {
        const topic = typesMap[tab.key];
        const disableEdit = (topic === scanSubTypes.SYSTEM) || (topic === scanSubTypes.VIOLET_X && !showVioletXOnly);

        const emptyPage = <EmptyPage
            title="Scans list is empty"
            image={noProjects}
            dataTestId="empty-page"
            subTitle="This company is ready for adding new scans."
            addButtonText="Add new scan"
            onAddClick={addScan}
            isReadOnly={disableEdit}
        />

        const commandButtons = getCommandButtons(typesMap[tab.key]);
        return scan !== 'summary' ? <ListPage
            columns={columns}
            key={type}
            topic={type}
            title={titlesMap[tab.key]}
            showTitle={showTitle}
            commandButtons={commandButtons}
            emptyPage={emptyPage}
            searchPlaceholder="Scans search"
            getData={loadScans}
            onRowChange={() => {}}
            scroll={{y: 'calc(100vh - 390px)'}}
            limit={limit}
            location={!hideLocation ? pathname : null}
            allowEdit={commandButtons.length > 0}
        /> : null
    }

    const getTabs = () => {
        return tabs.map(t => {
            t.page = getListView(t);
            return t;
        });
    }

    const allTabs = getTabs();
    const selected = allTabs.find(t => t.key === scan);

    const content = showVioletXOnly ? getListView(selected, true) : <SidebarLayout
        sidebarTabs={allTabs}
        defaultSelectedPage={selected}
        defaultSelectedKeys={defaultSelectedKeys}
        menuContainerCssClass="scans-menu"
        sideBarStyle={
            {
                width: '220px',
                paddingTop: '28px',
                height: '100%',
                backgroundColor: '#FFF',
                color: '#262626'
            }
        }
    />;

    function goToRoute(route) {
        navigate(route);
    }

    return <div data-testid="device-scans" className="scans-page">
        <div className="scans-page-header">
            {!showVioletXOnly ?
                <>
                    <ArrowLeftOutlined data-testid='arrow-back' className={"arrow"} onClick={() => goToRoute(`/clients/${orgId}/integrations/scanners`)}/>
                    <img className={"header-image"} src={clouds === "scanner-clouds" ? CloudsScanner : DevicesScanner} alt={"device"}/>
                    <h3>{clouds === "scanner-clouds" ? "Rule clouds scanner" : "Rule devices scanner"}</h3>
                    <p>{clouds === "scanner-clouds" ? "Clouds scanners" : "Devices scanners"}</p>
                </>
                : <h1>{title}</h1>
            }
        </div>
        <div className="height">
            {defaultSelectedKeys ? content : null}
        </div>
    </div>

}

export default DeviceScans;
