const KeycloakOptions = {
    url: process.env.REACT_APP_KEYCLOAK_OPTIONS_URL,
    realm: process.env.REACT_APP_KEYCLOAK_OPTIONS_REALM,
    clientId: process.env.REACT_APP_KEYCLOAK_OPTIONS_CLIENT_ID,
    scope: 'openid profile email',
    responseMode: 'query',
    updateTokenMinValidity: 5,
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/internal/silent-check-sso.htm',
};

export default KeycloakOptions;
