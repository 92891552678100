import './appComment.css';
import { Input, Tooltip } from 'antd';
import {useCallback, useEffect, useState} from "react";
import ClickOutsideComponent from "../clickOutsideHandler/clickOutsideHandler";
import {UserAvatar} from "../userAvatar/userAvatar";
import {formatMessageDate} from "../utils";

const AppComment = ({comment, onEdit, onDelete, loggedUserId, dateNow, useUTC, isEditAllowed}) => {

    const { TextArea } = Input;

    const [text, setText] = useState(comment.text);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        setText(comment.text);
    }, [comment]);

    const doEditCancel = (e, text) => {
        e.stopPropagation();
        e.preventDefault();
        setIsEdit(false);
        setText(text)
    }

    const onEditInternal = useCallback((e) => {
        if (text.trim().length > 0) {
            e.stopPropagation();
            e.preventDefault();
            setIsEdit(true);
        }
    }, [comment]);

    const onDeleteInternal = useCallback(() => {
        setIsEdit(false);
        onDelete(comment);
    }, [comment]);

    const onTextClickedOutside = useCallback((comment, text) => {
        if (text.trim().length > 0) {
            setIsEdit(false);
            comment.text = text;
            onEdit(comment);
        }
    }, [comment]);

    const commentDate = formatMessageDate(comment.dateCreated, dateNow, useUTC);

    return <div className="task-comment" data-testid={`task-comment-${comment.id}`}>
        <div className="task-comment-container">
            <div className="assignees-info">
                <UserAvatar fileName={comment.userFileName} name={`${comment.userFirstName} ${comment.userLastName}`}/>
            </div>
            <div className="text-container">
                <div className="name">{comment.userFirstName} {comment.userLastName}
                    <span data-testid="comment-date" className="comment-date">
                        <Tooltip title={commentDate.fullDate}>
                            {commentDate.date}
                        </Tooltip>
                    </span>
                </div>
                <div className="text">
                    {isEdit ?
                        <div className="text-area-container">
                            <ClickOutsideComponent onClickOutside={() => onTextClickedOutside(comment, text)} cssClassName="description-click-container">
                                <TextArea
                                    className="comment-text-textarea"
                                    data-testid="textarea"
                                    placeholder="Add description"
                                    value={text}
                                    onChange={(e) => {setText(e.target.value)}}
                                    autoSize={{ minRows: 1, maxRows: 18 }}
                                />
                            </ClickOutsideComponent>
                        </div> :
                        <>
                            {text}
                        </>}
                </div>
            </div>
        </div>
        {loggedUserId === comment.authorId && isEditAllowed ?
            <div className="command-buttons">
                {isEdit ? <span onClick={() => onTextClickedOutside(comment, text)} role="save-comment" data-testid={`comment-save-${comment.id}`}>Save</span>
                    : <span onClick={(e) => onEditInternal(e)} data-testid={`comment-edit-${comment.id}`}>Edit</span>
                }
                {isEdit ? <span onClick={(e) => doEditCancel(e, comment.text)} data-testid={`comment-cancel-${comment.id}`}>Cancel</span>
                    : <span onClick={() => onDeleteInternal()} data-testid={`comment-delete-${comment.id}`}>Delete</span>
                }
            </div>
            : null
        }
    </div>
}

export default AppComment;
