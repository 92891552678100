import './clientsList.css';
import {Input, Button, message} from 'antd';
import {ExportOutlined} from '@ant-design/icons';
import React, {useEffect, useReducer, useState} from 'react';
import ApiClient from "../../apiClient";
import useQuery from "../../hooks/useQuery";
import ClientsGroups from "../clientsGroups/clientsGroups";
import VxPointOfContactFilter from "../vxPointOfContactFilter/vxPointOfContactFilter";
import ClientsTable from "../clientsTable/clientsTable";
import AddToGroupModal from "../addToGroupModal/addToGroupModal";
import AddNewCompanyButton from "../addNewCompanyButton/addNewCompanyButton";
import ClientsStat from "../clientsStat/clientsStat";
import AddClientsToGroupButton from "../addClientsToGroupButton/addClientsToGroupButton";
import CurrentGroupContext from './selectedGroupContext';
import AddSingleClientToGroupsButton from "../addSingleClientToGroupsButton/addSingleClientToGroupsButton";
import ClientsGroupEditableTitle from "../clientsGroupEditableTitle/clientsGroupEditableTitle";
import RemoveClientsFromGroupButton from "../removeClientsFromGroupButton/removeClientsFromGroupButton";
import {clientsReducer, clientsInitialState, CLIENTS_ACTIONS} from "./clientsReducer";
import useGroupsOperations from "../../hooks/useGroupsOperations";
import useUsersOperations from "../../hooks/useUsersOperations";
import {DateFilter} from "../dateFilter/dateFilter";
import moment from "moment";

let selectedClientForMenu = null;
let page = 1;
let totalPages = 0;

export default function ClientsList({ api, accessScope }) {

    const now = moment().startOf('day');
    const lastInteractionDeadlines =  [
        {
            key: 'Today',
            timestamp: `${now.utc().format()}..${moment().startOf('day').add(1, 'days').utc().format()}`,
            label: 'Today',
        },
        {
            key: 'Last 7 days',
            timestamp: `${moment().startOf('day').subtract(7, 'days').utc().format()}..${moment().startOf('day').add(1, 'days').utc().format()}`,
            label: 'Last 7 days',
        },
        {
            key: 'Last 14 days',
            timestamp: `${moment().startOf('day').subtract(14, 'days').utc().format()}..${moment().startOf('day').add(1, 'days').utc().format()}`,
            label: 'Last 14 days',
        },
        {
            key: 'More than 14 days',
            timestamp: `..${moment().startOf('day').subtract(14, 'days').utc().format()}..`,
            label: 'More than 14 days',
        }
    ];

    const [reloadStatistic, setReloadStatistic] = useState(new Date())
    const [currentGroup, setCurrentGroup] = useState(null);

    const apiClient = api || new ApiClient();
    const { Search } = Input;

    const query = useQuery();

    const [isImageSaved, setIsImageSaved] = useState(false);
    const [isEmptyPage, setIsEmptyPage] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const [selectedClientsKeys, setSelectedClientsKeys] = useState([]);
    const [isAddToGroupModalOpen, setIsAddToGroupModalOpen] = useState(false);
    const [uberAdmins, setUberAdmins] = useState([false]);
    const [defaultVxPointOfContact, setDefaultVxPointOfContact] = useState(null);

    const [addToGroup, addClientToGroups, addToNewGroup] = useGroupsOperations(api);
    const [getUberAdmins] = useUsersOperations(api);
    const [requestCompanyModalOpen, setRequestCompanyModalOpen] = useState(null);
    const [totalClients, setTotalClients] = useState(0);

    const getInitialState = () => {
        const initClientsState = JSON.parse(JSON.stringify(clientsInitialState));
        initClientsState.filters.push(`vx_point_of_contact_id:${accessScope?.userId}`);
        if (query.get('q')) {
            initClientsState.searchValue = query.get('q');
        }
        return initClientsState;
    }

    const [clientsState, clientsDispatch] = useReducer(clientsReducer, getInitialState());

    useEffect(() => {
        const searchText = query.get('q') || '';
        clientsDispatch({
            type: CLIENTS_ACTIONS.SEARCH_VALUE,
            payload: searchText
        });
        setSearchValue(searchText);
    }, [query.get('q')]);

    useEffect(async () => {
        let filtersCopy = JSON.parse(JSON.stringify(clientsState.filters));
        filtersCopy = filtersCopy.filter(el => el.startsWith('groupId') === false);
        if (currentGroup) {
            filtersCopy.push(`groupId:${currentGroup.id}`);
        }
        clientsDispatch({type: CLIENTS_ACTIONS.CHANGE_GROUP, payload: filtersCopy});
        setSearchValue('');
        setRequestCompanyModalOpen(null);
        setSelectedClientsKeys([]);
    }, [currentGroup]);

    const isAllClients = () => currentGroup === null;
    const getClientIdFromKey = (key) => key.split('-')[1];

    useEffect(async () => {
        if (isImageSaved) {
            clientsDispatch({type: CLIENTS_ACTIONS.PAGE, payload: 1});
            setIsImageSaved(false);
        } else {
            setIsImageSaved(false);
        }
    }, []);

    const handlePagination = async (currentPage) => {
        clientsDispatch({type: CLIENTS_ACTIONS.PAGE, payload: currentPage});
    }

    useEffect( async () => {
        await loadUberAdmins();
    }, []);

    const onSearch = async (value) => {
        clientsDispatch({type: CLIENTS_ACTIONS.SEARCH_VALUE, payload: value});
    }

    const updateCurrentGroupName = (name) => {
        const currentGroupCopy = JSON.parse(JSON.stringify(currentGroup));
        currentGroupCopy.name = name;
        setCurrentGroup(currentGroupCopy);
    }

    const onAddToNewGroup = async () => {
        const clientsIds = selectedClientsKeys.map(k => getClientIdFromKey(k));
        const newGroup = await addToNewGroup(clientsIds);
        message.success('Clients added to group successfully');
        setCurrentGroup(newGroup);
        resetPageAndUpdateStatistic();
    }

    const onAddToGroup = async (group) => {
        const reloadData = async () => {
            setCurrentGroup(group);
        }
        const clientsIds = selectedClientsKeys.map(k => getClientIdFromKey(k));
        await addToGroup(clientsIds, group.id, reloadData);
        resetPageAndUpdateStatistic();
        message.success('Clients added to group successfully');
    }

    const showAddToGroupModal = (record) => {
        selectedClientForMenu = record;
        setIsAddToGroupModalOpen(true);
    };

    const loadUberAdmins = async () => {
        const uberAdmins = await getUberAdmins();
        const defaultUber = uberAdmins.find(u => u.id === accessScope?.userId);
        defaultUber.name +=' (me)';
        setDefaultVxPointOfContact(defaultUber);
        setUberAdmins(uberAdmins);
    }

    const onVxPointOfContactChange = async (vxPointOfContact) => {
        let filtersCopy = JSON.parse(JSON.stringify(clientsState.filters));
        filtersCopy = filtersCopy.filter(el => !el.startsWith('vx_point_of_contact_id'));
        if (vxPointOfContact.length > 0) {
            filtersCopy.push(...vxPointOfContact.map(el => `vx_point_of_contact_id:${el.id}`))
        }
        clientsDispatch({type: CLIENTS_ACTIONS.FILTERS, payload: filtersCopy});
    }

    const onDateChange = async (days, filter) => {
        let filtersCopy = JSON.parse(JSON.stringify(clientsState.filters));
        filtersCopy = filtersCopy.filter(el => !el.startsWith(filter));
        if (days) {
            filtersCopy.push(`${filter}:${days}`);
        }
        clientsDispatch({type: CLIENTS_ACTIONS.FILTERS, payload: filtersCopy});
    }

    const onClientsTableRowSelected = (selectedRowKeys) => {
        setSelectedClientsKeys(selectedRowKeys);
    }

    const onAddClientToGroups = async (groupsIds, clientId) => {
        if (groupsIds.length > 0) {
            await addClientToGroups(groupsIds, clientId);
            message.success('Clients added to group(s) successfully');
            setIsAddToGroupModalOpen(false);
        }
    }

    const onCompanyDeactivate = async () => {
        let page = clientsState.page;
        if ((totalPages - 1) === ((page - 1) * 10) && page !== 1) {
            page -= 1;
        }
        clientsDispatch({type: CLIENTS_ACTIONS.PAGE, payload: page});
        reloadStatisticData();
    }

    const reloadStatisticData = () => {
        setReloadStatistic(new Date());
    }

    const onTitleUpdated = (group) => {
        updateCurrentGroupName(group.name);
    }

    const onGroupDeleted = () => {
        setCurrentGroup(null);
    }

    const onClientsLoaded = (clientsInfo) => {
        totalPages = clientsInfo.totalPages;
        setIsEmptyPage(clientsInfo.data.length === 0);
    }

    const resetPageAndUpdateStatistic = () => {
        clientsDispatch({
            type: CLIENTS_ACTIONS.PAGE,
            payload: 1
        });
        reloadStatisticData();
    }

    const onStatLoaded = (statInfo) => {
        setTotalClients(statInfo?.total || 0);
    }

    const onRequestCompanyModalOpen = () => {
        setRequestCompanyModalOpen(new Date());
    }

    // END NEW CODE

    const selectedClientsIds = selectedClientsKeys.map(k => getClientIdFromKey(k));
    const disableSearch = isEmptyPage && searchValue.length === 0 && totalClients === 0;

    return <>
        <CurrentGroupContext.Provider value={{ currentGroup, setCurrentGroup }}>

            <h2 className="clients">Clients</h2>
            <div className="clients-list-container" data-testid="clients-list-container">

                <ClientsGroups api={apiClient} />

                <div className="divider"></div>

                <div style={{width: 'calc(100% - 290px)'}}>

                    <div className="clients-edit-name">
                        {isAllClients() && <h1>All clients</h1>}
                        {!isAllClients() && <ClientsGroupEditableTitle currentGroup={currentGroup} onTitleUpdated={onTitleUpdated} onGroupDeleted={onGroupDeleted}/>}
                    </div>

                    <ClientsStat onStatLoaded={onStatLoaded} clientGroup={isAllClients() ? null : currentGroup.id} reload={reloadStatistic}/>

                    <div className="search-container">
                        <div>
                            <Search disabled={disableSearch} placeholder="Clients search" className="search-input"
                                    data-testid="client-search"
                                    onSearch={onSearch} value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}/>
                        </div>
                        <div className="command-buttons">
                            {isAllClients() ?
                                <AddClientsToGroupButton isDisabled={selectedClientsKeys.length < 1} onAddToNewGroup={onAddToNewGroup} onAddToGroup={onAddToGroup} />
                                : <RemoveClientsFromGroupButton selectedIds={selectedClientsIds}
                                                                onClientsRemoved={resetPageAndUpdateStatistic} groupId={currentGroup.id} api={apiClient}/>
                            }
                            <Button icon={<ExportOutlined style={{fontSize: '12px'}}/>} disabled={true}/>
                            {isAllClients() ?
                                <AddNewCompanyButton requestCompanyModalOpen={requestCompanyModalOpen} />:
                                <AddSingleClientToGroupsButton currentGroup={currentGroup} onAddClients={resetPageAndUpdateStatistic}/>
                            }
                        </div>
                    </div>

                    <div className="filters-wrapper">
                        <VxPointOfContactFilter
                            onChange={onVxPointOfContactChange}
                            uberAdmins={uberAdmins}
                            defaultSelected={defaultVxPointOfContact || null}
                            loggedUserId={accessScope?.userId}
                        />
                        <DateFilter filterText="Special Due Date" onFilterChange={(date) => onDateChange(date, 'due_date')}/>
                        <DateFilter filterText="Last interaction" onFilterChange={(date) => onDateChange(date, 'last_interaction')} dueDates={lastInteractionDeadlines}/>
                    </div>

                    <ClientsTable
                        api={apiClient}
                        clientsState={clientsState}
                        isClientsLoaded={onClientsLoaded}
                        pagingInfo={{page, totalPages, handlePagination}}
                        onCompanyDeactivate={onCompanyDeactivate}
                        onRowSelected={onClientsTableRowSelected}
                        onClientsLoaded={onClientsLoaded}
                        onClientRemovedFromGroup={resetPageAndUpdateStatistic}
                        onRequestCompanyModalOpen={onRequestCompanyModalOpen}
                        groupsOperations={{
                            showAddToGroupModal
                        }}
                    />
                </div>
            </div>

            {isAddToGroupModalOpen && <AddToGroupModal
                isModalOpened={isAddToGroupModalOpen}
                api={apiClient}
                requestModalClose={() => setIsAddToGroupModalOpen(false)}
                onAddToGroup={onAddClientToGroups}
                selectedClient={selectedClientForMenu}
            />}
        </CurrentGroupContext.Provider>
    </>
}