import React, {useEffect, useState} from 'react';
import './scanSummary.css';
import { Input, Radio, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {useRecoilState} from 'recoil';
import {RolesMapper, scanSubTypes} from "../../constants";
import scanSummaryState from "../../atoms/scanSummary";

const {TextArea} = Input;

const ScanSummaryPage = ({scan, accessScope, isVioletXOnly, type }) => {

    const [activeType, setActiveType] = useState();
    const [scanName, setScanName] = useState(null);
    const [selectTimeType, setSelectTimeType] = useState('seconds');
    const [activeScanInterval, setActiveScanInterval] = useState('');
    const [description, setDescription] = useState();

    const [scanState, setScanState] = useRecoilState(scanSummaryState);

    const allowSystemScan = scan && (type === scanSubTypes.SYSTEM) && accessScope.roleId === RolesMapper.UBER_ADMIN;

    const menu = {
        onClick : (el) => setSelectTimeType(el.key),
    items: [
        {
            label: 'seconds',
            key: 'seconds',
        },
        {
            label: 'minutes',
            key: 'minutes',
        },
        {
            label: 'hours',
            key: 'hours',
        },
        {
            label: 'days',
            key: 'days',
        },
        {
            label: 'weeks',
            key: 'weeks',
        },
    ]};

    const countSeconds = () => {
        switch (selectTimeType) {
            case 'minutes' :
                    return  60;
            case 'hours':
                    return 60 * 60;
            case 'days':
                    return 60 * 60 * 24;
            case 'weeks':
                    return 60 * 60 * 24 * 7;
            case 'seconds':
            default :
                return 1
        }
    }

    useEffect( () => {
        setScanState(null)
        if (scan) {
            setScanName(scan.name);
            setActiveScanInterval(scan.interval ? scan.interval.toString() : '');
            setDescription(scan.description);
        }
        setActiveType(type);
    },[]);

    useEffect(() => {
        if (scanName !== null) {
            const newState = Object.assign({}, scanState);
            newState.name = scanName;
            newState.type = activeType;
            newState.interval = activeScanInterval === '' ? '' :  Math.round(activeScanInterval * countSeconds());
            newState.description = description
            setScanState(Object.assign({}, newState));
        }
    },[activeType, scanName, selectTimeType, activeScanInterval, description]);

    const onFrequencyInputChange = (e) => {
        const isInteger = /^[0-9.]+$/;
        if (e.target.value === '' || isInteger.test(e.target.value)) {
            setActiveScanInterval(e.target.value);
        }
    }

    let isDisabled = false;

    if (scan) {
        isDisabled = (type === scanSubTypes.SYSTEM) || (type === scanSubTypes.VIOLET_X && !isVioletXOnly);
    }

    return <div className='scan-summary' data-testid='scan-summary'>
        <h1>
            Scan summary
        </h1>
        <div className="label">Name</div>
        <Input disabled={isDisabled} data-testid='name-input' className="info" placeholder='Scan Name' value={scanName} onChange={(e) => setScanName(e.target.value)}/>
        <div className="label">Description</div>
        <TextArea disabled={isDisabled} data-testid='text-input' className="info" value={description} onChange={(e) => setDescription(e.target.value)} maxLength={500} rows={5} />
        <div className="label">Tags</div>
        <Input className="info" disabled/>
        <div className="label">Type</div>
        <Radio.Group disabled={isDisabled} className="buttons" onChange={(e) => setActiveType(e.target.value)} value={activeType} >
            {allowSystemScan && <Radio.Button value="SYSTEM">System</Radio.Button>}
            {isVioletXOnly && <Radio.Button value="VIOLET_X">VioletX</Radio.Button>}
            {!isVioletXOnly && <Radio.Button value="CUSTOM" data-testid={"CUSTOM"}>Custom</Radio.Button>}
        </Radio.Group>
        <div className="label">Scan period</div>
        <div className="frequency-wrapper">
            <div>Every</div>
            <Input disabled={isDisabled} data-testid="frequency-input" className="time" value={activeScanInterval} onChange={onFrequencyInputChange}/>
            <Dropdown menu={menu} disabled={isDisabled}>
                <Button>
                    {selectTimeType}
                    <DownOutlined />
                </Button>
            </Dropdown>
        </div>
    </div>

}

export default ScanSummaryPage;
