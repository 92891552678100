import React, {useEffect, useState} from "react";
import {
    CaretDownOutlined,
    CaretUpOutlined,
    UserOutlined,
    LogoutOutlined,
    LockOutlined,
    BuildOutlined,
    ApiOutlined
} from '@ant-design/icons';
import './userMenu.css';
import {Dropdown, Space, Modal, Input, Button, message} from 'antd';
import ApiClient from "../../apiClient";
import SidebarLayout from "../sidebarLayout/sidebarLayout";
import UploadImage from "../uploadImage/uploadImage";
import {UserAvatar} from "../userAvatar/userAvatar";


export function UserMenu({api, onLogout, userId, onResetPasswordLinkSent}) {
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const [isMyProfileOpened, setIsMyProfileOpened] = useState(false)
    const [userFirstName, setUserFirstName] = useState(null);
    const [userLastName, setUserLastName] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [emailStatus, setEmailStatus] = useState('');
    const [jobTitle, setJobTitle] = useState(null);
    const [user, setUser] = useState(null);
    const [isImageSaved, setIsImageSaved] = useState(false);
    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState(['personal-info']);
    const pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    const apiClient = api ? api : new ApiClient();
    const [handleChangeImage, setHandleChangeImage] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [prevState, setPrevState] = useState(null);
    const [handleReload, setHandleReload] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);

    useEffect(() => {
        if (user) {
            setUserFirstName(user.firstName);
            setUserLastName(user.lastName);
            setUserEmail(user.email);
            setJobTitle(user.jobTitle);
        }
    }, [user]);

    useEffect(async () => {
        await loadUserInfo();
        setHandleReload(true);
        setIsImageSaved(false);
    }, [handleReload]);

    useEffect(() => {
        const isFormNotChanged = !handleChangeImage && prevState?.firstName === userFirstName && prevState?.email === userEmail && prevState?.lastName === userLastName && prevState?.jobTitle === jobTitle ;
        const isFormEmpty = userFirstName?.trim().length <= 0 || userLastName?.trim().length <= 0 || userEmail?.trim().length <= 0;
        setDisabled(isFormNotChanged || isFormEmpty);
    }, [userFirstName, userLastName, userEmail, jobTitle, handleChangeImage]);

    const onMenuClose = () => {
        setIsMyProfileOpened(false);
        setIsMenuOpened(false);
        setUserFirstName(user?.firstName);
        setUserLastName(user?.lastName);
        setUserEmail(user?.email);
        setJobTitle(user?.jobTitle);
        setEmailStatus('');
        setIsEmailValid(true);
        setHandleChangeImage(false);
    }

    const onEmailChange = (value) => {
        const isValid = pattern.test(value);
        if (isValid) {
            setEmailStatus('');
            setIsEmailValid(true);
        } else {
            setEmailStatus('error');
            setIsEmailValid(false);
        }
        setUserEmail(value);
    }

    const loadUserInfo = async () => {
        const filter = ['active:true', `id:${userId}`];
        try {
            const user = await apiClient.get().userApi.userList(
                '',
                [],
                filter,
                ['deviceCount', "userEmail"],
                1,
                1
            );
            const userData = user.data.data[0];
            const newState = Object.assign({}, prevState);
            newState.firstName = userData.firstName;
            newState.lastName = userData.lastName;
            newState.email = userData.email;
            newState.jobTitle = userData.jobTitle;
            setPrevState(Object.assign({}, newState));
            setUser(userData);
        } catch (err) {
        }
    }

    const items = [
        {
            label: <div data-testid="my-profile-label">My profile</div>,
            icon: <UserOutlined/>,
            key: '0',
            onClick: () => setIsMyProfileOpened(true)
        },
        {
            label: <div>Log out</div>,
            icon: <LogoutOutlined/>,
            key: '1',
            onClick: () => onLogout()
        }
    ];

    const icon = isMenuOpened ? <CaretUpOutlined/> : <CaretDownOutlined/>

    const onTabSelect = (tab) => {
        setDefaultSelectedKeys(tab);
    }

    const getTabs = () => {
        return [
            {
                'data-testid': 'personal-info',
                key: 'personal-info',
                title: 'Personal info',
                label: 'Personal info',
                icon: <UserOutlined/>,
                onClick: () => onTabSelect(['personal-info']),
                disabled: false,
            },
            {
                'data-testid': 'tab-user-integrations',
                key: 'integrations',
                title: 'Integrations',
                label: 'Integrations',
                icon: <ApiOutlined/>,
                disabled: true,
                onClick: () => onTabSelect(['integrations']),
                page: <div>Integrations</div>,
            },
            {
                'data-testid': 'tab-user-permissions',
                key: 'permissions',
                title: 'Permissions',
                label: 'Permissions',
                icon: <BuildOutlined/>,
                disabled: true,
                page: <div>Permissions</div>,
            },
        ];
    }, getSelectedTab = () => {

        let result = null;
        const tabs = getTabs();

        const prevSelectedKey = defaultSelectedKeys.length > 0
            ? defaultSelectedKeys[defaultSelectedKeys.length - 1]
            : null;

        if (prevSelectedKey) {
            for (const tab of tabs) {
                if (tab.key === prevSelectedKey) {
                    result = tab;
                }
            }
        }

        return result;
    };

    let defaultTab = getSelectedTab();
    let defaultOpenKeys = ['personal-info'];

    const saveUser = async () => {
        setIsImageSaved(true);
        if (prevState?.firstName === userFirstName && prevState?.lastName === userLastName && prevState?.email === userEmail && prevState?.jobTitle === jobTitle) {
            return;
        }
        try {
            await apiClient.get().userApi.userPatch([`id:${userId}`],
                {
                    firstName: userFirstName ?? user?.firstName,
                    lastName: userLastName ?? user?.lastName,
                    email: userEmail ?? user?.email,
                    jobTitle: jobTitle ?? user?.jobTitle
                });
            await loadUserInfo();
            setIsImageSaved(false);
            message.success('User has been successfully edited');
        } catch (err) {
            setIsImageSaved(false);
            if (err.response?.data?.details?.["userParams.email"]) {
                message.error('The email is not unique');
            } else {
                message.error('An error occurred while try to edit user');
            }
        }
    }

    const executeActionEmail = async (userId) => {
        try {
            await apiClient.get().authApi.executeActionsEmail({
                userId: userId,
                redirectUri: window.location.origin,
            });
            setIsMyProfileOpened(false);
            onResetPasswordLinkSent();
        } catch (err) {
            message.error('Unable to send reset password link');
        }
    }

    const onResetClick = async () => {
        await executeActionEmail(userId);
    }

    return (
        <>
            <Dropdown
                arrow={false}
                menu={{
                    items,
                }}
                trigger={['click']}
                onOpenChange={(state) => setIsMenuOpened(state)}
                overlayStyle={{minWidth: '185px'}}
            >
                <Space>
                    <div className="user-menu" data-testid='user-dropdown'>
                        <UserAvatar fileName={user?.fileName} bucketName={"users"}
                                    name={user?.firstName + ' ' + user?.lastName} api={apiClient}/>
                        <div className="display-name" data-testid="display-name" title={`${user?.firstName} ${user?.lastName}`}>{`${user?.firstName} ${user?.lastName}`}</div>
                        <div className="caret-icon">{icon}</div>
                    </div>
                </Space>
            </Dropdown>
            <Modal title="My profile" wrapClassName="user-add-profile-modal" width={644}
                   open={isMyProfileOpened}
                   okText="Save"
                   onOk={saveUser}
                   okButtonProps={{disabled: disabled || !isEmailValid}}
                   cancelButtonProps={{'data-testid': 'cancel-save'}}
                   key={isMyProfileOpened ? 'opened-modal' : 'closed-modal'}
                   onCancel={onMenuClose}>
                <div>
                    <SidebarLayout
                        containerCssClass="users-layout"
                        defaultSelectedPage={defaultTab}
                        defaultSelectedKeys={defaultSelectedKeys}
                        defaultOpenKeys={defaultOpenKeys}
                        sidebarTabs={getTabs()}
                        menuContainerCssClass="users-layout-menu"
                        collapsed={false}
                        sideBarStyle={
                            {
                                width: '168px',
                                height: '100%',
                                backgroundColor: '#FFFFFF'
                            }
                        }
                    />
                </div>
                <div className="profile-picture-container" data-testid='profile-container'>
                    <UploadImage setIsModalOpened={setHandleReload} prevFileName={user?.fileName}
                                 setHandleChange={setHandleChangeImage} api={api} bucketName={'users'} id={userId}
                                 isImageSaved={isImageSaved}
                                 name={user?.firstName[0].toUpperCase() + user?.lastName[0].toUpperCase()}/>
                    <div className="name-group">
                        <div>
                            <div className="label">Name</div>
                            <Input data-testid='name-input' className="info first" placeholder='User Name'
                                   value={userFirstName}
                                   onChange={(e) => setUserFirstName(e.target.value)}/>
                        </div>
                        <div>
                            <div className="label">Surname</div>
                            <Input data-testid='surname-input' className="info" placeholder='User Surname'
                                   value={userLastName} onChange={(e) => setUserLastName(e.target.value)}/>
                        </div>
                    </div>
                    <div className="label">e-mail</div>
                    <Input data-testid='email-input' status={emailStatus} className="info" value={userEmail}
                           onChange={(e) => onEmailChange(e.target.value)}/>
                    {emailStatus === 'error' && <span className="error-description">Email seems to be wrong</span>}
                    <div className="label">Job Title</div>
                    <Input data-testid='job-input' className="info" value={jobTitle}
                           onChange={(e) => setJobTitle(e.target.value)}/>
                    <div className="label-password">Password</div>
                    <Button className="button-password" icon={<LockOutlined/>} danger data-testid="change-password" onClick={onResetClick}>Change
                        password</Button>
                </div>
            </Modal>
        </>
    )
}
