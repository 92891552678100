import {Button} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {formatMessageDate} from "../../utils";
import './deadlineTomorrowNotification.css';

const DeadlineTomorrowNotification = ({data, onActionCallback, apiClient}) => {
    const [notificationData, setNotificationData] = useState(data);
    const navigate = useNavigate();

    useEffect(() => {
        setNotificationData(data);
    }, [data]);

    const onActionButtonClicked = useCallback(async () => {
        await onActionCallback(notificationData.id);
        navigate(`/${notificationData.params.organizationId}/${notificationData.params.taskKey}`);
    }, [notificationData, onActionCallback, navigate]);
    return (
        <>
            <div className="notification-item-header">
                <div>
                    Tomorrow is the deadline for your task
                    <span> {notificationData.params.taskName} </span>
                    on the<span> {notificationData.params.projectName}</span> project
                </div>
                <div>{formatMessageDate(notificationData.dateCreated, new Date(), true, true).date}</div>
            </div>
            <div className="notification-action">
                <Button size="small" onClick={onActionButtonClicked}>See the task</Button>
            </div>
        </>
    );
}

export default DeadlineTomorrowNotification;