import './clientsTable.css';
import {Dropdown, message, Modal, Space, Table, Tooltip} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {UserAvatar} from "../userAvatar/userAvatar";
import Dots from "../../assets/images/dots-devices.svg";
import {useNavigate} from "react-router-dom";
import {
    DeleteOutlined,
    EnterOutlined,
    ExclamationCircleOutlined,
    FolderAddOutlined,
    MinusCircleOutlined
} from "@ant-design/icons";
import {showDeactivateConfirm} from "../utils";
import ClientsController, {GENERAL_ERROR} from "../../controllers/clientsController";
import CurrentGroupContext from "../clientsList/selectedGroupContext";
import useDataList from "../../hooks/useDataList";
import ClientsEmptyPage from "../clientsEmptyPage/clientsEmptyPage";
import GroupsController from "../../controllers/groupsController";

const  ClientsTable = ({clientsState, pagingInfo,
                          groupsOperations, onCompanyDeactivate, onRowSelected, api, onClientsLoaded,
                           onClientRemovedFromGroup, onRequestCompanyModalOpen
}) => {

    const {currentGroup, } = useContext(CurrentGroupContext);
    const clientsController = new ClientsController({apiClient: api});
    const groupsController = new GroupsController({apiClient: api});

    const [clients, setClients] = useState({
        data: [],
        total: 0,
        totalPages: 0
    });

    const [fetch, loading, error] = useDataList(clientsController);

    const [selectedClientsKeys, setSelectedClientsKeys] = useState([]);
    const navigate = useNavigate();

    useEffect( () => {
        if (error !== null) {
            console.error('unable to load clients', error);
            message.error('An error occurred while try to load clients');
        }
    }, [error]);

    useEffect(async () => {
        setSelectedClientsKeys([]);
        const data = await fetch(clientsState);
        setClients(data);
        onClientsLoaded(data);
    }, [clientsState]);

    const redirectToCompany = (item) => {
        navigate(`/clients/${item.id}/summary`);
    }

    const rowSelected = (row) => {
        setSelectedClientsKeys(row);
        onRowSelected(row);
    }

    const companyDeactivate = async (item) => {
        const result = await clientsController.deactivateCompany(item.id);
        if (result === GENERAL_ERROR) {
            message.error('An error occurred while try to delete company');
            return;
        }
        message.success('Company has been successfully deleted');
        onCompanyDeactivate(item);
    }

    const showDeactivateCompanyConfirm = (item) => {
        showDeactivateConfirm('company', () => companyDeactivate(item), true);
    }

    const removeSingleClientFromGroup = async (clientId) => {
        await groupsController.removeSingleClientFromGroup(clientId, currentGroup.id);
        message.success('Client removed from group successfully');
        onClientRemovedFromGroup(clientId);
    }

    const onRemoveClientFromGroup = (clientId) => {
        Modal.confirm({
            'data-testid': 'delete-phase-confirm',
            className: 'remove-clients',
            title: <strong className="delete-phase-confirm-title">Remove company from the group</strong>,
            icon: <ExclamationCircleOutlined style={{ color: '#FAAD14'}} />,
            content: `You about remove company from the group. Do you want to proceed?`,
            okText: "Remove",
            cancelText: "Cancel",
            cancelButtonProps: {type: 'primary'},
            okButtonProps: {'data-testid': 'delete-phase-button-ok', type: 'default', danger: 'danger' },
            onOk: async (close) => { await removeSingleClientFromGroup(clientId); return close; },
        });
    }

    const getTableMenuItems = (index, record) => {
        const items = [
            { key: `enter-${index}`, title: 'Open this company', label: <div data-testid={`enter-${index}`} onClick={() => redirectToCompany(record)}>Open this company</div>, icon: <EnterOutlined />},
        ];

        if (currentGroup !== null) {
            items.push({ key: `remove-group-${index}`, title: 'Remove from the group', label: <div data-testid={`remove-group-${index}`} onClick={() => onRemoveClientFromGroup(record.id)}>Remove from the group</div>, icon: <MinusCircleOutlined />})
        } else {
            items.push({ key: `group-${index}`, title: 'Add to group', label: <div data-testid={`group-${index}`} onClick={() => groupsOperations.showAddToGroupModal(record)}>Add to group</div>, icon: <FolderAddOutlined />});
        }

        items.push({
            title: '',
            key: `divider-${index}`,
            type: 'divider'
        })
        items.push({ key: `deactivate-${index}`, title: 'Deactivate', label: <div data-testid={`deactivate-${index}`} onClick={() => showDeactivateCompanyConfirm(record)}>Deactivate</div>, icon: <DeleteOutlined />, danger: true});

        return items;
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            className: 'no-breaks',
            width: '250px',
            render: (text, record) => {
                return <a onClick={() => redirectToCompany(record)}>{record.name}</a>
            }
        },
        {
            title: 'Client contact',
            dataIndex: 'clientContact',
            key: 'clientContact',
            align: 'left',
            className: 'no-breaks',
            width: 130,
            render: (text, record) => {
                if (
                    record.contact.firstName !== null ||
                    record.contact.lastName !== null ||
                    record.contact.fileName !== null
                ) {
                    const contact = record.contact;
                    const name = `${contact.firstName} ${contact.lastName}`;
                    return <Tooltip placement="top" title={name}>
                        <span>
                         <UserAvatar name={name} fileName={contact.fileName}/>
                     </span>
                    </Tooltip>
                }
                return <div></div>
            }
        },
        {
            title: 'vX contact',
            dataIndex: 'vXContact',
            key: 'vXContact',
            align: 'left',
            className: 'no-breaks',
            width: 130,
            render: (text, record) => {
                if (
                    record.vxContact.firstName !== null ||
                    record.vxContact.lastName !== null ||
                    record.vxContact.fileName !== null
                ) {
                    const contact = record.vxContact;
                    const name = `${contact.firstName} ${contact.lastName}`;
                    return <Tooltip placement="top" title={name}>
                        <span>
                         <UserAvatar name={name} fileName={contact.fileName}/>
                     </span>
                    </Tooltip>
                }
                return <div></div>
            }
        },
        {
            title: 'Active projects',
            dataIndex: 'activeProjects',
            key: 'activeProjects',
            align: 'right',
            className: 'no-breaks',
            width: 130,
        },
        {
            title: 'Requested projects',
            dataIndex: 'requestedProjects',
            key: 'requestedProjects',
            align: 'right',
            className: 'no-breaks',
            width: 130,
        },
        {
            title: 'Special Due Date',
            dataIndex: 'dueDate',
            key: 'dueDate',
            align: 'right',
            className: 'no-breaks',
            width: 130,
        },
        {
            title: 'Last interaction',
            dataIndex: 'lastInteraction',
            key: 'lastInteraction',
            align: 'right',
            className: 'no-breaks',
            width: 130
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (text, record, index) => {
                const items = getTableMenuItems(index, record);
                return <Dropdown menu={{items}} trigger='click' >
                    <a data-testid={`dots-menu-${index}`} className="action-menu-container">
                        <Space>
                            <img src={Dots} alt=""/>
                        </Space>
                    </a>
                </Dropdown>
            },
            className: 'table-action',
            width: 40
        }
    ];

    const clientsCount = clients.data.length === 0;
    const isAllClients = currentGroup === null;
    const isSearch = clientsState.searchValue.length > 0;
    const isFiltersSelected = isAllClients ? clientsState.filters.length > 1
        : clientsState.filters.length > 2;

    const tableContainerCSSName = (!loading && clients.data.length === 0) && (isFiltersSelected || isSearch) ? 'empty-container' : ''

    const emptyPage = <ClientsEmptyPage onRequestCompanyModalOpen={onRequestCompanyModalOpen}
        clientsCount={clientsCount} isAllClients={isAllClients} isFiltersSelected={isFiltersSelected}
        isSearch={isSearch} />;

    return <div className={`clients-table ${tableContainerCSSName}`}>
        {!loading && clients.data.length === 0 && !isFiltersSelected && !isSearch && emptyPage}
        {((!loading && clients.data.length !== 0) || isFiltersSelected || isSearch) && <Table
            dataSource={clients.data}
            columns={columns}
            style={{maxWidth: '100%'}}
            scroll={{y: 'calc(100vh - 386px)'}}
            loading={loading}
            rowSelection={{
                selectedRowKeys: selectedClientsKeys,
                onChange: rowSelected
            }}
            pagination={
                {
                    current: clientsState.page,
                    total: clients.totalPages,
                    showSizeChanger: false,
                    pageSize: 10,
                    onChange: pagingInfo.handlePagination
                }
            }
        />}
        {!loading && clients.data.length === 0 && emptyPage}
    </div>
}

export default ClientsTable;