import ApiClient from "../apiClient";

const GENERAL_ERROR = 'GENERAL_ERROR';

export default class VendorsController {

    apiClient = null;

    constructor(props) {
        this.apiClient = props.apiClient || new ApiClient();
    }
    vendorList = async (page, searchValue, listFilter, limit, expand) => {
        try {
            let filter = ['active:true'];
            const loadedVendors = await this.apiClient.get().orgsApi.organizationList(
                searchValue,
                [`+name`],
                listFilter ?? filter,
                expand ?? [],
                page,
                limit ?? 10
            )
            return  {
                data: loadedVendors.data?.data,
                totalPages: loadedVendors.data.page.total,
            };
        } catch (e) {
            console.error(e);
            return GENERAL_ERROR;
        }
    }

    deleteVendorsLinkage = async (filter) => {
        await this.apiClient.get().vendorsApi.organizationsLinkageDeleteMany(filter);
    }

}
