import React from 'react';
import TextArea from 'antd/es/input/TextArea';

const ModalTextAreaItem = ({title, value, onChange, height}) => {
  return (
    <div className="documents-page-modal-text-area-item" data-testid="documents-page-modal-text-area-item">
      <div className='documents-page-modal-text-area-title'>{title}</div>
      <TextArea data-testid="documents-page-modal-text-area" value={value} onChange={onChange}
                style={{ height: height, maxHeight: 300 }} placeholder='Add a document description'/>
    </div>
  );
};

export default ModalTextAreaItem;
