import moment from "moment/moment";
import {Task} from "./templateTaskType";
export function calculateDate (task: Task) {
    const startDate = moment();
    const endDate = moment();
    if (task.daysFromNow && task.duration != null) {
        startDate.add(task.daysFromNow - task.duration, 'days');
        endDate.add(task.daysFromNow, 'days');
    } else {
        endDate.add(task.duration ?? 0, 'days');
    }
    return [startDate.toDate(), endDate.toDate()];
}
