import React, {useState} from "react";
import "gantt-task-react/dist/index.css";
import { ViewMode } from "gantt-task-react";
import {Radio} from "antd";

export const ViewSwitcher = ({onViewModeChange}) => {
    const [activeMode, setActiveMode] = useState(ViewMode.Month);
    const onChange = (mode) => {
        onViewModeChange(mode);
        setActiveMode(mode);
    }

    return (
        <div className="view-container">
            <Radio.Group data-testid="switcher" onChange={(e) => onChange(e.target.value)} value={activeMode}>
                <Radio.Button value={ViewMode.Day}>
                    Day
                </Radio.Button>
                <Radio.Button data-testid="Week" value={ViewMode.Week}>
                    Week
                </Radio.Button>
                <Radio.Button value={ViewMode.Month}>
                    Month
                </Radio.Button>
            </Radio.Group>
        </div>
    );
};
