import './folderDetails.css';
import {
    CloseOutlined,
    DeleteOutlined,
    SendOutlined,
    EditOutlined,
    CheckOutlined,
} from "@ant-design/icons";
import {Tooltip, Input} from "antd";
import {UserAvatar} from "../../userAvatar/userAvatar";
import {useCallback, useEffect, useRef, useState} from "react";
import DocumentCommentsSection from "../documentCommentsSection/documentCommentsSection";

const FolderDetails = ({folder, onClose, onFolderDelete, onFolderEdit, loggedUserId, api}) => {

    const [folderName, setFolderName] = useState(folder.name);
    const [description, setDescription] = useState(folder.description);

    const [isDescriptionEditActive, setIsDescriptionEditActive] = useState(false);
    const [isEditingName, setIsEditingName] = useState(false);

    const descRef = useRef(null);
    const nameRef = useRef(null);
    const closeDescRef = useRef(null);
    const closeNameRef = useRef(null);
    const contentWrapperRef = useRef(null);

    const { TextArea } = Input;

    useEffect(() => {
        async function handleClickOutsideDescription(event) {
            if (descRef.current && !descRef.current.contains(event.target) && closeDescRef && !closeDescRef.current.contains(event.target)) {
                await handleSaveDescription();
            }
        }
        document.addEventListener('mousedown', handleClickOutsideDescription);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideDescription);
        };
    }, [closeDescRef, description, descRef]);

    useEffect(() => {
        async function handleClickOutsideFolderName(event) {
            if (nameRef.current && !nameRef.current.contains(event.target) && closeNameRef && !closeNameRef.current.contains(event.target)) {
                await handleSaveFolderName();
            }
        }
        document.addEventListener("mousedown", handleClickOutsideFolderName);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideFolderName);
        };
    }, [nameRef, folderName, closeNameRef]);

    useEffect(() => {
        setFolderName(folder.name);
        setDescription(folder.description);
    }, [folder]);

    const handleSaveDescription = async () => {
        setIsDescriptionEditActive(false);
        if (description.trim().length === 0) {
            setDescription('');
        }
        folder.description = description;
        await onFolderEdit(folder);
    };

    const getDescriptionTextArea = () => {
        return (
            <TextArea
                className="description"
                data-testid="textarea"
                placeholder="Add description"
                onFocus={() => setIsDescriptionEditActive(true)}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                autoSize={true}
                rows={18}
            />
        )
    }

    const handleEditName = () =>{
        setIsEditingName(true);
    }

    const handleCloseEdit = () => {
        setFolderName(folder.name);
        setIsEditingName(false);
    }

    const handleSaveFolderName = async () => {
        setIsEditingName(false);
        if (folderName.trim().length > 0) {
            folder.name = folderName;
            await onFolderEdit(folder);
        } else {
            setFolderName(folder.name);
        }
    };

    const getFolderName = () => {
        return <div className="folder-edit-name">
            {isEditingName ?
                <span ref={nameRef} className="folder-name-container">
                        <Input style={{width: 340}} maxLength={128} data-testid="input-value" value={folderName} onChange={(e)=> setFolderName(e.target.value)} />
                    </span>
                :
                <div className="folder-name" onClick={handleEditName} data-testid="edit-name">{folder.name}</div>
            }
            {!isEditingName  && <EditOutlined className="folder-name-edit-icon" onClick={handleEditName}/>}
            {isEditingName && <div className="edit-icons" data-testid="edit-icons"><span className="check-outlined">
                    <CheckOutlined data-testid="check" onClick={handleSaveFolderName} /> </span><CloseOutlined ref={closeNameRef} data-testid="close" onClick={handleCloseEdit}/>
            </div> }
        </div>
    }

    const handleCloseEditDesc = () => {
        setDescription(folder.description);
        setIsDescriptionEditActive(false);
    }

    const onDocumentSaved = useCallback(() => {
        setTimeout(() => {
            contentWrapperRef.current.scroll({ top: contentWrapperRef.current.scrollHeight, behavior: 'smooth' });
        }, 500);
    }, []);

    return <div className="folder-details" ref={contentWrapperRef}>
        <div className="document-details-header">
            <div>Folder</div>
            <div className="document-commands">
                <div><SendOutlined style={{ fontSize: '16px', color: '#262626' }} /></div>
                <div><DeleteOutlined data-testid="delete-document-button" style={{ fontSize: '16px', color: '#F5222D' }} onClick={async () => { await onFolderDelete(folder.id, false, null)}} /></div>
                <div><CloseOutlined onClick={onClose} style={{ fontSize: '16px', color: '#262626' }} data-testid="close-doc-details"/></div>
            </div>
        </div>
        <div className="document-details-body">
            <div className="document-row">
                {getFolderName()}
            </div>
            <div className="document-row">
                <div>Description</div>
                <div className="description-container">
                    {isDescriptionEditActive ?
                        <div className="text-area-container">
                            <div className="edit-icons"><span className="check-outlined">
                            <CheckOutlined data-testid="save-description" onClick={()=>handleSaveDescription()} /> </span><CloseOutlined ref={closeDescRef} data-testid="close-icon" onClick={()=>handleCloseEditDesc()}/>
                            </div>
                            <span ref={descRef}>{getDescriptionTextArea()}</span>
                        </div> :
                        <>
                            {getDescriptionTextArea()}
                            <EditOutlined onClick={() => setIsDescriptionEditActive(true)}/>
                        </>}
                </div>
            </div>
            <div className="document-row">
                <div>Created</div>
                <div>
                    <Tooltip placement="top" title={`${folder.userFirstName} ${folder.userLastName}`}>
                    <span>
                        <UserAvatar name={`${folder.userFirstName} ${folder.userLastName}`} fileName={folder.userFileName}/>
                    </span>
                        <span className="user-name">{folder.userFirstName} {folder.userLastName}</span>
                    </Tooltip>
                </div>
            </div>
            <DocumentCommentsSection
                api={api}
                loggedUserId={loggedUserId}
                document={folder}
                onDocumentCommentSaved={onDocumentSaved}
            />
        </div>
    </div>
}

export default FolderDetails;