import './projectsList.css';
import {useEffect, useReducer, useState} from 'react';
import Stat from '../stat/stat';
import noProjects from "../../assets/images/no-clients.svg";
import EmptyPage from "../emptyPage/emptyPage";
import emptySearchResult from "../../assets/images/empty-search-result.svg";
import {Input, Table, Dropdown, Space, Button, Tooltip, message, Progress, Menu, Modal, Checkbox} from 'antd';
import Dots from '../../assets/images/dots-devices.svg';
import {
    EnterOutlined,
    EditOutlined,
    PlusOutlined,
    DownOutlined,
    CloseOutlined,
    DeleteOutlined,
    ProjectOutlined,
    CopyOutlined, ExportOutlined
} from '@ant-design/icons';
import ApiClient from "../../apiClient";
import useQuery from "../../hooks/useQuery";
import {formatDateNoTime, formatMessageDate, getDeadlines, getTag, showDeactivateConfirm} from "../utils";
import { useNavigate } from "react-router-dom";
import ProjectsTemplates from "../projectsTemplates/projectsTemplates";
import ProjectsTemplatesList from "../projectsTemplatesList/projectsTemplatesList";
import {UserAvatar} from "../userAvatar/userAvatar";
import ProjectsTemplatesModal from "../projectsTemplatesModal/projectsTemplatesModal";
import {RolesMapper} from "../../constants";
import OwnerFilter from "../ownerFilter/ownerFilter";
import ClientsFilter from "../clientsFilter/clientsFilter";
import {useRecoilValue} from "recoil";
import taskAssignedNotificationsState from "../../atoms/taskAssignedNotifications";
import deadlineTomorrowNotificationsState from "../../atoms/deadlineTomorrowNotification";
import projectStatusReportNotificationsState from "../../atoms/projectStatusReportNotification";

let page = 1;
const limit = 10;
const unassigned = {id: 0, name: 'Unassigned'};

const clientsOwnersInitialState = { selectedClients: [], selectedOwners: [] };

function clientsOwnersReducer(state, action) {
    switch (action.type) {
        case 'SET_SELECTED_CLIENTS':
            return { ...state, selectedClients: action.payload };
        case 'SET_SELECTED_OWNERS':
            return { ...state, selectedOwners: action.payload };
        case 'SET_SELECTED_CLIENTS_AND_OWNERS':
            return { ...state, selectedClients: action.payload.clients, selectedOwners: action.payload.owners };
        default:
            throw new Error();
    }
}


const ProjectsList = ({match, api, showTemplates, accessScope, loadOnInit}) => {

    const { Search } = Input;
    const projectStatuses = ['Requested', 'Active', 'Ask to close', 'Done', 'Draft'];

    const [isEmptyPage, setIsEmptyPage] = useState(false);
    const [projects, setProjects] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [isProjectsLoaded, setIsProjectsLoaded] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [uberAdmins, setUberAdmins] = useState([]);
    const [responsibleList, setResponsibleList] = useState([]);
    const [defaultResponsible, setDefaultResponsible] = useState({});
    const [status, setStatus] = useState({total_projects: 0,
        active_projects: 0,
        requested_projects: 0,
        done_projects: 0,
        total_tasks: 0,
        completed: 0,
        in_progress: 0});
    const [selectedPage, setSelectedPage] = useState(match?.params.page || 'list');
    const [isProjectTemplatesModalOpened, setIsProjectTemplatesModalOpened] = useState(false);
    const [modalKey, setModalKey] = useState('templates-modal-close');
    const [selectedFilters, setSelectedFilters] = useState(['Requested', 'Active']);
    const [isDropdownActive, setIsDropdownActive] = useState(false);
    const [deadline, setDeadline] = useState(null);

    const apiClient = api || new ApiClient();
    const query = useQuery();
    const navigate = useNavigate();

    const assignedTasksNotifications = useRecoilValue(taskAssignedNotificationsState);
    const deadlineTomorrowNotifications = useRecoilValue(deadlineTomorrowNotificationsState);
    const projectStatusReportNotifications = useRecoilValue(projectStatusReportNotificationsState);

    const [clientsOwnersState, clientsOwnersDispatch] = useReducer(
        clientsOwnersReducer,
        clientsOwnersInitialState,
        () => clientsOwnersInitialState);

    const items = getDeadlines();

    function toCapitalLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const showDeactivateUserConfirm = (id, e) => {
        e.stopPropagation();
        showDeactivateConfirm('project', () => onDeactivate(id));
    }
    const onDeactivate = async(id) =>{
        await apiClient.get().projectApi.projectPatch([`id:${id}`], {active: false});
        message.success('Project has been successfully  deleted');
        if ((totalPages - 1) === ((page - 1) * limit) && page !== 1) {
            page -= 1;
        }
        await loadProjects();
        await loadStatus();
    }

    const getDropdownCssClassName = (selected, dropdown) => {
        const baseClassName = 'dropdown';
        const activeClass = isDropdownActive === dropdown ? 'active' : '';
        const selectedClass = selected ? 'selected' : '';
        return `${baseClassName} ${activeClass} ${selectedClass}`;
    };

    const usersToDisplay = () => {
        uberAdmins.sort((a, b) => {
            const aInResponsibleList = responsibleList.find(el => el?.id === a.id);
            const bInResponsibleList = responsibleList.find(el => el?.id === b.id);
            if (aInResponsibleList && !bInResponsibleList) {
                return -1;
            } else if (!aInResponsibleList && bInResponsibleList) {
                return 1;
            } else {
                return 0;
            }
        });
        return <div className='status-wrapper responsible'>
        <div className="user-item">
            <Checkbox checked={responsibleList.find(el => el?.id === defaultResponsible?.id)} onChange={(e) => onCheckboxChange(defaultResponsible, e.target.checked, setResponsibleList, responsibleList)}/>
            <UserAvatar size={26} name={defaultResponsible.name} fileName={defaultResponsible.fileName}/>
            <span className='name'>{defaultResponsible.name}</span>
        </div>
        <div className="user-item" >
            <Checkbox data-testid='checkbox-unassigned' checked={responsibleList.find(el => el?.id === 0)} onChange={(e) => onCheckboxChange(unassigned, e.target.checked, setResponsibleList, responsibleList)}/>
            <span style={{marginLeft: '8px'}}>Unassigned</span>
        </div>
        <div className="separator"/>
        {
            uberAdmins.map(u => {
        return <div className="user-item" key={u.id}>
                <Checkbox checked={responsibleList.find(el => el?.id === u.id)} onChange={(e) => onCheckboxChange(u, e.target.checked, setResponsibleList, responsibleList)}/>
                <UserAvatar size={26} name={u.name} fileName={u.fileName}/>
                <span className='name'>{u.name}</span>
            </div>
    })}

    </div>}

    const getFilterText = (value) =>{
        value = value.map(el => {
            switch (el) {
                case 'Draft':
                    return 'Archived';
                case 'Ask to close':
                    return 'Asked to close';
                default:
                    return el;
            }
        });
        return value.length > 1 ? `${value[0]}+${value.length -1}` : value[0];
    }

    const redirectToClient = (e, record) => {
        e.stopPropagation();
        navigate(`/clients/${record.organizationId}/summary`)
    }


    const onCheckboxChange = async (item, checked, setItems, prevValue) => {
        checked ?
            setItems([...prevValue, item]) :
            setItems([...prevValue.filter(el => el !== item)]);
    }

    const getItems = () => <div className='status-wrapper'>
        {projectStatuses.map(item =><div key={item}>
                <Checkbox checked={selectedFilters.find(el => el === item)} onChange={(e) => onCheckboxChange(item, e.target.checked, setSelectedFilters, selectedFilters)}/>
                {getTag(item.toUpperCase().replace(/ /g,'_'))}
        </div>
        )}
    </div>;

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '35%',
            render: (text, record) => {
                const notificationsCount = assignedTasksNotifications.filter(n => n.params.projectId === record.id).length
                    + deadlineTomorrowNotifications.filter(n => n.params.projectId === record.id).length
                    + projectStatusReportNotifications.filter(n => n.params.projectId === record.id).length;
                return <span>{notificationsCount > 0 ?
                    <div className="notification-informer"/> :
                    <div className="notification-informer notification-informer-empty"/>}
                     <a style={{color: '#1890FF'}}>{record.name}</a>
                </span>
            }
        },
        match?.params.orgId ? null : {
            title: 'Client',
            dataIndex: 'client',
            key: 'client',
            width: '108px',
            render: (_, record) => {
               return <span onClick={(e) => redirectToClient(e, record) } style={{color: '#1890FF'}}>{record.client}</span>
            }
        },
        {
            title: 'Owner',
            dataIndex: 'owner',
            key: 'owner',
            width: '80px',
            render: (text, record) => {
                return <Tooltip placement="top" title={record.lead}>
                    <span>
                        <UserAvatar name={record.lead} fileName={record.fileName}/>
                    </span>
                </Tooltip>
            }
        },
        {
            title: 'Progress',
            dataIndex: 'progress',
            key: 'progress',
            width: '17%',
            render: (text, record) => {
                return <div className="task-stat"><span style={{color: '#1890FF'}}>{record.tasksDone}/{record.totalTasks} tasks</span>
                   <Progress size="small"
                                 status="active"
                                 strokeColor={"#1890FF"}
                                 showInfo={false}
                                 percent={record.progress} />
                </div>
            }
        },
        {
            title: 'Due date',
            dataIndex: 'dueDate',
            key: 'dueDate',
            align: 'left',
            width: '160px'
        },
        {
            title: 'Alerts',
            dataIndex: 'alerts',
            key: 'alerts',
            width: '72px'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'left',
            width: '100px',
            render: (_, { status }) => getTag(status)
        },
        {
            title: 'Last message',
            dataIndex: 'message',
            key: 'message',
            render: (text, record) => {
                if (!record.dateCreated) {
                    return;
                }
                const commentDate = formatMessageDate(record.dateCreated, null, true, true);
                return <div className={`message-wrapper ${record.authorRoleId === RolesMapper.UBER_ADMIN ? 'uber' : ''}`}>
                    <div>
                        <span>{record.authorFirstName} {record.authorLastName}</span>
                        <span>{commentDate.date}</span>
                    </div>
                    <div>{record.text}</div>
                </div>
            },
            width: 196
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (text, record, index) => {
                const items = [
                    { key: `enter-${index}`, title: 'Open this project', label: <div data-testid={`enter-${index}`} onClick={() => navigate(`/clients/${record.organizationId}/projects/details/${record.id}`)}>Open this project</div>, icon: <EnterOutlined />},
                    { key: `edit-${index}`, title: 'Edit project', label: <div data-testid={`edit-${index}`} onClick={() => {navigate(`/clients/${record.organizationId}/projects/details/${record.id}`)}}>Edit</div>, icon: <EditOutlined />},
                    { key: `delete-${index}`, danger:true, title: 'Delete project', label: <div data-testid={`delete-${index}`} onClick={(e)=> showDeactivateUserConfirm(record.id, e)}>Delete</div>, icon: <DeleteOutlined/>},
                ];
                return <Dropdown menu={{items}} onClick={e => e.stopPropagation()} trigger='click' disabled={false}>
                    <a data-testid={`dots-menu-${index}`} className="action-menu-container">
                        <Space>
                            <img src={Dots} alt=""/>
                        </Space>
                    </a>
                </Dropdown>
            },
            className: 'table-action',
            width: 50
        }
    ].filter(Boolean);

    const projectsMenuItems = [
        {
            label: 'Projects',
            key: 'list',
            icon: <ProjectOutlined />,
            "data-testid": 'projects-menu'
        },
        {
            label: 'Project templates',
            key: 'templates',
            icon: <CopyOutlined />,
            "data-testid": 'templates-menu'
        }
    ];

    const onMenuClick = (e) => {
        setSelectedPage(e.key);
        navigate(`/projects/${e.key}`);
    };


    const redirectToCreate = () => {
        navigate(`/clients/${match?.params.orgId}/projects/details`);
    }

    const loadStatus = async () => {
        const filter = ['active:true'];
        if (match?.params.orgId) {
            filter.push(`organizationId:${match?.params.orgId}`);
        }
        const newStatus = await apiClient.get().projectApi.projectStatus(
            '',
            [``],
            filter,
            [],
            1,
            10
        );
        setStatus({...newStatus.data.data[0]});
    }

    const loadProjects = async (search) => {
        const searchData = search || searchValue;
        setIsProjectsLoaded(false);
        const searchItems = searchData.split(' ');
        const filter = ['active:true'];
        if (match?.params.orgId) {
            filter.push(`organizationId:${match?.params.orgId}`);
        } else {
            filter.push(...selectedFilters.map(el => `status:${el.toUpperCase().replace(/ /g,'_')}`));
            filter.push(...responsibleList.map(el => `responsible_id:${el?.id === 0 ? null : el?.id}`))
            if (clientsOwnersState.selectedClients.length > 0) {
                filter.push(...clientsOwnersState.selectedClients.map(el => `organizationId:${el}`));
            }
            if (clientsOwnersState.selectedOwners.length > 0) {
                filter.push(...clientsOwnersState.selectedOwners.map(el => `userId:${el}`));
            }
        }
        if (deadline) {
            const timestamp = items.find(el => el.key === deadline).timestamp;
            filter.push(`dateDueTo:${timestamp}`);
        }
        const loadedProjects = await apiClient.get().projectApi.projectList(
            searchItems,
            [`+dateDueTo`, `+name`],
            filter,
            ['userFirstName', 'userLastName', 'userFileName', 'tasksTotal', 'organizationName', 'lastMessage'],
            page,
            limit
        );

        const convertedProjects = loadedProjects.data.data.map((item, index) => {
            return {
                id: item.id,
                key: `project-${item.id}-${index}`,
                name: item.name,
                prefix: item.prefix,
                organizationId: item.organizationId,
                dueDate: item.dateDueTo ? formatDateNoTime(Date.parse(item.dateDueTo)) : '',
                alerts: 0,
                totalTasks: item.tasksTotal,
                tasksDone: item.taskDone,
                progress: item.tasksTotal > 0 ? item.taskDone * 100 / item.tasksTotal : 0,
                fileName: item.userFileName,
                lead: `${item.userFirstName} ${item.userLastName}`,
                templateId: item.templateId,
                authorFirstName: item.authorFirstName,
                authorLastName: item.authorLastName,
                authorRoleId: item.authorRoleId,
                text: item.text,
                dateCreated: item.dateCreated,
                status: item.status,
                client: item.organizationName,
                responsibleId: item.responsibleId
            }
        });
        setProjects(convertedProjects);
        setTotalPages(loadedProjects.data.page.total);
        setIsEmptyPage(convertedProjects.length === 0 && searchValue.length === 0);
        setIsProjectsLoaded(true);
    }

    const loadUberAdmins = async () => {
        if (accessScope?.roleId === RolesMapper.UBER_ADMIN) {
            let isLoading = true;
            let page = 1;
            const users = [];
            while (isLoading) {
                const uberAdmins = await apiClient.get().userApi.userList(
                    '',
                    ['+firstName'],
                    ['active:true', `roleId:${RolesMapper.UBER_ADMIN}`],
                    [],
                    page,
                    50
                );
                for (const uberAdmin of uberAdmins.data.data) {
                    users.push({
                        id: uberAdmin.id,
                        name: `${uberAdmin.firstName} ${uberAdmin.lastName}`,
                        fileName: uberAdmin.fileName
                    });
                }
                page = uberAdmins.data.page.next;
                isLoading = uberAdmins.data.page.next !== null;
            }
            setUberAdmins(users);
            const defaultResponsible = users.find(u => u.id === accessScope?.userId);
            const userIndex = users.findIndex(item => item === defaultResponsible);
            const defaultUser = users.splice(userIndex, 1)[0];
            defaultUser.name +=' (me)';
            setDefaultResponsible(defaultUser);
            if (!query.get('client')) {
                setResponsibleList([defaultResponsible]);
            }
        }
    }

    const loadTemplates = async () => {
        try {
            let page = 1;
            let filter = ['active:true'];
            if (match?.params.orgId) {
                filter.push(`restrictByOrgId:${match?.params.orgId}`);
                filter.push('hideVxOnly');
            }
            const templates = await apiClient.get().templateApi.templateList(
                '',
                [`+id`],
                filter,
                ['userFirstName', 'userLastName'],
                page,
                50
            );
            setTemplates(templates.data.data);
        } catch (err) {
            message.error('An error occurred while try to get templates');
        }
    }

    const initDataLoad = async () => {
        await loadStatus();
        await loadUberAdmins();
        const q = query.get('q') || '';
        setSearchValue(q);
        if (loadOnInit) {
            await loadProjects(q);
        }
    }

    useEffect( async () => {
        if (query.get('client') && query.get('status')) {
            clientFilterChanged([query.get('client')]);
            setSelectedFilters([toCapitalLetter(query.get('status'))]);
            return;
        }
        if (accessScope?.roleId === RolesMapper.ORG_ADMIN && projects.length === 0) {
            await loadProjects();
        }
        return () => {
            page = 1;
        }
    }, [query.get('client'), query.get('status')]);

    useEffect( () => {
        if (!match?.params.page){
            setSelectedPage('list')
        }
    }, [match?.params.page]);

    useEffect( () => {
        if (match?.params.orgId) {
            setTemplates([]);
        }
    },[match?.params.orgId]);

    useEffect(async () => {
        await initDataLoad();
    }, [match?.params.orgId]);

    useEffect(async () => {
        page = 1;
        await loadProjects();
    }, [selectedFilters, responsibleList, deadline, clientsOwnersState]);

    useEffect( async () => {
        await loadTemplates();
    },[match?.params.orgId]);

    useEffect(async () => {
        if (query.get('t')) {
            await initDataLoad();
            await loadProjects('')
        }
    }, [query.get('t')]);

    const onSearch = async (value) => {
        page = 1;
        setSearchValue(value);
        await loadProjects();
    }

    const handlePagination = async (currentPage) => {
        page = currentPage;
        await loadProjects();
    }

    const clearDeadline = (e) => {
        setDeadline(null);
        setIsDropdownActive(false);
        e.stopPropagation();
    }

    const showProjectTemplatesModal = () => {
        setIsProjectTemplatesModalOpened(true);
        setModalKey('templates-modal-open')
    };
    const hideProjectTemplatesModal = () => {
        setIsProjectTemplatesModalOpened(false);
        setModalKey('templates-modal-close')
    };
    const getProjectTemplatesModalTitle = () => {
        return <div className="projects-templates-title" data-testid="projects-templates-title">
            <div>Project templates</div>
            <CloseOutlined onClick={() => {hideProjectTemplatesModal()}} data-testid="close-popup"/>
        </div>
    }

    const getProjectTemplatesModalFooter = () => {
        return <div className="projects-templates-footer">
            <Button style={{width: 90}} onClick={hideProjectTemplatesModal} data-testid="projects-templates-footer-cancel">Close</Button>
        </div>
    }

    const clientFilterChanged = (args) => {
        clientsOwnersDispatch({
            type: 'SET_SELECTED_CLIENTS_AND_OWNERS',
            payload: { clients: args, owners: [] }
        });
    }

    const ownerFilterChanged = (args) => {
        clientsOwnersDispatch({ type: 'SET_SELECTED_OWNERS', payload: args });
    }

    const getAllUbersList = () => {
        const ubers = JSON.parse(JSON.stringify(uberAdmins));
        ubers.push(defaultResponsible);
        return ubers;
    }

    const tableContainerCSSName = (!!+status.total_projects && projects.length === 0 && isProjectsLoaded) ? 'empty-container' : 'table-container'

    const projectListPage =  <div className="projects-list-container">

        {showTemplates && <ProjectsTemplates templates={templates} projects={projects} accessScope={accessScope} orgId={match?.params.orgId} /> }

        <Modal
            closable={false}
            key={modalKey}
            title={getProjectTemplatesModalTitle()}
            open={isProjectTemplatesModalOpened}
            onOk={() => {}}
            onCancel={hideProjectTemplatesModal}
            width={1088}
            height={718}
            footer={getProjectTemplatesModalFooter()}
            className="projects-templates-popup"
        >
            <ProjectsTemplatesModal api={apiClient} templates={templates} accessScope={accessScope} orgId={match?.params.orgId} projects={projects} />
        </Modal>

        <h1>Projects</h1>

        {!!+status.total_projects && match?.params.orgId && <Stat items={[
            { title: 'Projects', value: status.total_projects },
            { title: 'Tasks', value: status.total_tasks },
            { title: 'In progress', value: status.in_progress },
            { title: 'Completed', value: status.completed },
        ]}/>}

        {!!+status.total_projects && !match?.params.orgId && <Stat items={[
            { title: 'Total projects', value: status.total_projects },
            { title: 'Requested', value: status.requested_projects },
            { title: 'Active', value: status.active_projects },
            { title: 'Completed', value: status.done_projects },
            { title: 'Alerts', value: 0 },
        ]}/>}



        <div className="search-container">
            <div>
                {!!+status.total_projects
                    && <Search disabled={isEmptyPage} placeholder="Project search" className="search-input" data-testid="project-search"
                            onSearch={onSearch} value={searchValue} onChange={(e) => setSearchValue(e.target.value) } />}
            </div>
            <div className="command-buttons">
                {match?.params.orgId && <Button icon={<ExportOutlined style={{ fontSize: '11px'}} />} data-testid="add-from-template" onClick={showProjectTemplatesModal}>From a template</Button>}
                {match?.params.orgId && <Button type="primary" icon={<PlusOutlined style={{ fontSize: '11px'}} />} data-testid="add-project" onClick={redirectToCreate}>Add</Button>}
            </div>
        </div>
        {!match?.params.orgId && <div className="filters-wrapper">
            <Dropdown
                trigger={['click']}
                style={{ width: '250px' }}
                dropdownRender={() => usersToDisplay()}
                onOpenChange={(e)=> setIsDropdownActive(e ? 'requested' : false)}
            >
                <div data-testid="requested-dropdown" className={getDropdownCssClassName(responsibleList.length !== 0, 'requested')} >
                    <span>{`Responsible${responsibleList.length > 0 ? ': ' + getFilterText(responsibleList.map(u => u?.name)) : ''}`}</span>
                    <DownOutlined />
                </div>
            </Dropdown>
            <Dropdown
                trigger={['click']}
                onOpenChange={(e)=> setIsDropdownActive(e ? 'status' : false)}
                dropdownRender={() => getItems()}
            >
                <div className={getDropdownCssClassName(selectedFilters.length !== 0, 'status')} >
                    <span>{`Status${selectedFilters.length > 0 ? ': ' + getFilterText(selectedFilters) : ''}`}</span>
                    <DownOutlined />
                </div>
            </Dropdown>
            <Dropdown
                trigger={['click']}
                onOpenChange={(e)=> setIsDropdownActive(e ? 'deadline' : false)}
                menu={{items, onClick: (e) => {
                        setDeadline(e.key);
                        setIsDropdownActive(false);
                    }
            }}
            >
                <div data-testid="deadline-dropdown" className={getDropdownCssClassName(deadline, 'deadline')} >
                    <span>Deadline{deadline && `: ${deadline}`}</span>
                    {deadline ? <CloseOutlined data-testid="close-deadline" style={{fontSize: '12px'}} onClick={clearDeadline}/> : <DownOutlined />}
                </div>
            </Dropdown>
            <ClientsFilter onChange={clientFilterChanged} defaultSelected={clientsOwnersState.selectedClients} api={apiClient} accessScope={accessScope} />
            <OwnerFilter
                onChange={ownerFilterChanged}
                clients={clientsOwnersState.selectedClients}
                api={apiClient}
                ubersAdmins={getAllUbersList()}
            />
        </div>
        }
        {!!+status.total_projects && <div className={`${tableContainerCSSName}`}>
            <Table
                dataSource={projects}
                columns={columns}
                scroll={{y: 'calc(100vh - 56px)'}}
                loading={!isProjectsLoaded}
                rowClassName={() => match?.params.orgId ? '' : 'large-row'}
                onRow={(record) => {
                    return {
                        onClick: () => navigate(`/clients/${record.organizationId}/projects/details/${record.id}`)
                    }
                }}
                pagination={
                    {
                        current: page,
                        total: totalPages,
                        showSizeChanger: false,
                        pageSize: 10,
                        onChange: handlePagination
                    }
                }
            />
        </div>}

        {!+status.total_projects && !match?.params.orgId && <EmptyPage
            image={emptySearchResult}
            title="There are no projects yet"
            subTitle="Stay tuned for updates"
        />}

        {!+status.total_projects && match?.params.orgId && <EmptyPage
            image={noProjects}
            title="There are no projects yet"
            subTitle="Please add a new project to start."
            onAddClick={redirectToCreate}
            addButtonText="Add new project"
        />}

        {(!!+status.total_projects && projects.length === 0 && searchValue.length > 0) && <EmptyPage
            image={emptySearchResult}
            title="No matches found"
            subTitle="Please, try another search."
        />}

    </div>


    const templatesPage = <ProjectsTemplatesList accessScope={accessScope} api={apiClient} match={match}/>

    return <div className="project-page-tabs">
        {!match?.params.orgId && <Menu selectedKeys={[selectedPage]} mode="horizontal" items={projectsMenuItems} onClick={onMenuClick} />}
        {selectedPage === 'list' ? projectListPage : templatesPage}
    </div>
}

export default ProjectsList;
