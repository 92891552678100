import {Button} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {formatMessageDate} from "../../utils";
import './statusReportReadyNotification.css';

const StatusReportReadyNotification = ({data, onActionCallback, apiClient}) => {
    const [notificationData, setNotificationData] = useState(data);
    const navigate = useNavigate();

    useEffect(() => {
        setNotificationData(data);
    }, [data]);

    const onActionButtonClicked = useCallback(async () => {
        await onActionCallback(notificationData.id);
        navigate(`/clients/${notificationData.params.organizationId}/projects/details/${notificationData.params.projectId}/report?reportDate=${notificationData.params.reportDate}`);
    }, [notificationData, onActionCallback, navigate]);

    return (
        <>
            <div className="notification-item-header" data-testid={`notification-status-report-ready-${notificationData.id}`}>
                <div>
                    Status Report for
                    <span className="assignee-notification-name">{notificationData.params.organizationName} </span>
                    in<span className="assignee-notification-name">{notificationData.params.projectName} </span>
                    project is ready
                </div>
                <div>{formatMessageDate(notificationData.dateCreated, new Date(), true, true).date}</div>
            </div>
            <div className="notification-action">
                <Button size="small" onClick={onActionButtonClicked}>See the report</Button>
            </div>
        </>
    );
}

export default StatusReportReadyNotification;
