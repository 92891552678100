import './projectsTemplatesModal.css';
import ProjectsTemplate from "../projectsTemplate/projectsTemplate";
import {useEffect, useState} from "react";
import TemplatesFilter from "../templatesFilter/templatesFilter";
import EmptyPage from "../emptyPage/emptyPage";
import empty from "../../assets/images/empty-search-result.svg";
import {message} from "antd";

const ProjectsTemplatesModal = ({templates, accessScope, orgId, projects, api}) => {

    const [templateType, setTemplateType] = useState();
    const [templatesToDisplay, setTemplatesToDisplay] = useState(templates);

    const loadTemplates = async (templateType) => {
        try {
            let filter = templateType ? ['active:true', `projectType:${templateType}`] : ['active:true'];
            filter.push(`restrictByOrgId: ${orgId}`);
            const templates = await api.get().templateApi.templateList(
                '',
                [`+id`],
                filter,
                ['userFirstName', 'userLastName'],
                1,
                50
            );
            setTemplatesToDisplay(templates.data.data);
        } catch (err) {
            message.error('An error occurred while try to get templates');
        }
    }

    useEffect(async () => {
        if (templateType !== undefined) {
            await loadTemplates(templateType);
        }
    },[templateType]);

    return <div className="projects-templates-modal">
       <div className="title" data-testid="projects-templates-modal-title">Project templates</div>
        <TemplatesFilter setTemplateType={setTemplateType} templateType={templateType}/>
        {templatesToDisplay.length === 0 ? <EmptyPage
            image={empty}
            title="There are no templates yet"
            subTitle="Stay tuned for updates"
            cssClassName="empty-modal "
        /> : <div className="templates-container">
            {templatesToDisplay.map((t) => {
                const isReadonly = projects ? projects.find(p => p.templateId === t.id) !== undefined : true;
                return <ProjectsTemplate api={api} template={t} key={`template-${t.id}`} accessScope={accessScope} orgId={orgId} readonly={isReadonly} />
            })}
        </div>}
    </div>
}

export default ProjectsTemplatesModal;