import React, {useEffect, useState} from 'react';
import './reportPreview.css';
import DataPreviewTable from "../dataPreviewTable/dataPreviewTable";
import exportFromJSON from 'export-from-json';
import {Button, Dropdown, Modal, Progress, Space, Input, Menu} from 'antd';
import {CloseCircleFilled, DownloadOutlined, ExportOutlined, DownOutlined} from '@ant-design/icons';
import {QUERY_TRANSFORM_FIELDS, SOFTWARE_TRANSFORM_FIELDS} from '@violetx-mvp/commons'
import {getShortLabel} from "../utils";

export default function ReportPreview ({scanId, scan, api, isVioletXOnly, orgId}) {

    const EXPORT_STATES = {
        NONE: 'NONE',
        PROGRESS: 'PROGRESS',
        DOWNLOAD: 'DOWNLOAD',
    }

    const EXPORT_TYPES = {
        csv: { title: '.CSV', type: 'csv' },
        json: { title: '.JSON', type: 'json' },
    }

    const orgsToDisplay = 5;

    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [exportPercent, setExportPercent] = useState(0);
    const [dataToExport, setDataToExport] = useState([]);
    const [exportState, setExportState] = useState(EXPORT_STATES.NONE);
    const [exportType, setExportType] = useState(null);
    const [organizations, setOrganizations] = useState([]);
    const [totalOrganizations, setTotalOrganizations] = useState(null);
    const [search, setSearch] = useState('');
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const { Search } = Input;

    useEffect(async () => {
        if (!isVioletXOnly) {
            await getData();
        }
        await getOrganizationData();
        },[]);

    const items = [
        {
            label: EXPORT_TYPES.csv.title,
            key: EXPORT_TYPES.csv.type,
        },
        {
            label: EXPORT_TYPES.json.title,
            key: EXPORT_TYPES.json.type,
        },
    ];

    const handleMenuClick = async (e) => {
        setExportType(e.key);
        await doExport();
    }

    const menu = {
        items: items,
        onClick: handleMenuClick,
    }

    const transformFields = scan.target === 'SOFTWARE'
        ? SOFTWARE_TRANSFORM_FIELDS
        : QUERY_TRANSFORM_FIELDS;

    async function getData(scrolled, organization) {
        const filter = [`scanId:${scanId}`, 'active:true', `organizationId:${organization ?? orgId}`];
        const result = await api.get().queriesApi.queryList([],
            [''],
            filter,
            ['resultPreview'],
            scrolled ? page + 1 : 1,
            10
        );
        setData(organization ? [...result.data.data] : [...data, ...result.data.data]);
    }

    async function getOrganizationData(){
        const filter = [`scanId:${scanId}`, 'active:true']
        const loadedCompanies = await api.get().orgsApi.organizationList(
            search,
            [`+name`],
            filter,
            ['userCount', 'scanCount', 'deviceCount', 'cloudCount'],
            1,
            orgsToDisplay
        );
        setOrganizations([...loadedCompanies.data.data.map(o => {
            return {
            key: o.id, onClick: () => onOrganizationSelect(o), label: getShortLabel(o.name)
            }
        })]);
        search.length === 0 && setTotalOrganizations(loadedCompanies.data.page.total);
    }

    function onDownload() {
        const fileName = "scan_result";
        dataToExport.sort((a, b) => (a.query > b.query) ? 1 : -1);
        if (exportType === EXPORT_TYPES.csv.type) {
            let csvToExport = [];
            dataToExport.forEach(query => query.result.forEach((res, i) => csvToExport.push({query : i === 0 ? query.query : '', ...res})));
            exportFromJSON({ data: csvToExport, fileName, exportType });
        } else {
            exportFromJSON({ data: dataToExport, fileName, exportType });
        }
        setExportState(EXPORT_STATES.NONE);
        setDataToExport([]);
    }

    function onCancel() {
        setExportState(EXPORT_STATES.NONE);
        setExportType(null);
        setDataToExport([]);
        setExportPercent(0);
    }

    async function doExport () {
        setExportState(EXPORT_STATES.PROGRESS);
        let page = 1;
        const newExportData = [];
        const filter = [`scanId:${scanId}`, 'active: true', `organizationId:${selectedOrganization?.id ?? orgId}`];
        do {
            const result = await api.get().scanResultsApi.scanResultList([],
                [''],
                filter,
                [],
                page,
                20
            );
            let queryResult;
            queryResult = result.data.data.map((query) => ({query: query.source.queryName, result: query.result.result}));
            const preparedData = [];
            queryResult.forEach((r) => {
                const result = [];
                let customs = [];
                r.result.forEach(current => {
                    const res = {};
                    Object.keys(current).forEach(key => {
                        if (transformFields.indexOf(key) !== -1) {
                            res[key] = current[key];
                        }
                        if (key === 'customFields') {
                            Object.keys(current[key]).forEach(customKey => {
                                customs.push(current[key][customKey]);
                            })
                        }
                    });
                    customs = customs.map((el) => {
                        if (typeof el === 'object') {
                            return JSON.stringify(el);
                        } else {
                            return el
                        }
                    })
                    res.rawData = customs.join(', ');
                    customs = [];
                    result.push(res);
                });
                preparedData.push({
                    query: r.query,
                    result: result
                });
            });
            newExportData.push(...dataToExport, ...preparedData);
            const lastPage = result.data.page.last || 1;
            setExportPercent((page / lastPage) * 100);
            page = result.data.page.next;
        } while (page !== null);
        setTimeout(() => {
            setExportState(EXPORT_STATES.DOWNLOAD);
        }, 500);
        setDataToExport([...newExportData]);
    }

    async function onScroll(e) {
        const scrolledDown = parseInt(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight;
        if (scrolledDown) {
            setPage(page + 1);
            await getData(true);
        }
    }
    async function onOrganizationSelect(org) {
        setPage(1);
        await getData(false, org.id);
        setSelectedOrganization(org);
    }

    const displayResult = organizations.length === 0 ?
        <div className="empty-result-wrapper" key="no-result">
            <div className="empty-result-image"/>
            <div className="empty-result-title">No matches found</div>
        </div> : <Menu items={organizations}/>;

    async function onSearch () {
        await getOrganizationData();
    }

    return <div className="report-preview">
        <div className={!isVioletXOnly ? "report-preview-header" : ""}>
            <h2 className="report-preview-title">Dashboard data preview</h2>
            <div className={isVioletXOnly && "report-preview-buttons"}>
                {isVioletXOnly && <div className={"dropdown-header"}>
                    <p className={"test-header"}>Test Environment</p>
                    <Dropdown data-testid="dropdown" className="query-export-dropdown" dropdownRender={() => <div className="query-export-dropdown">
                        {orgsToDisplay < totalOrganizations && <Search
                            placeholder="Search"
                            onSearch={onSearch}
                            className="search-wrapper-report"
                            data-testid="search"
                            value={search}
                            onChange={(e)=>{setSearch(e.target.value)}}
                        />}
                        {displayResult}
                    </div>}>
                        <Button className="organization-container-btn" onClick={(e) => e.preventDefault()}>
                            <Space>
                                <p className={"selected-organization"}>{selectedOrganization?.name ?? 'Choose'}</p>
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                </div>}
                <Dropdown disabled={!(selectedOrganization || !isVioletXOnly)} className="query-export-dropdown" menu={menu}>
                    <Button className={isVioletXOnly ? "violetx-export-container-btn" : "export-container-btn"}>
                        <ExportOutlined /> Export as
                    </Button>
                </Dropdown>
            </div>

        </div>
        <div className="tables" onScroll={onScroll} data-testid="report-preview">
            {data.length > 0 ? data.map((tableData, i) =>
               <DataPreviewTable orgId={selectedOrganization?.id ?? orgId} i={i} isVioletXOnly={isVioletXOnly} key={tableData.id} tableData={tableData} api={api} scanId={scanId} scan={scan}/>
            ) : <div className="empty-list-page">
                <div className="page-empty">
                    <div className='empty-img'>
                    </div>
                    <div className="empty-page-header">Choose a test environment</div>
                    <div className="empty-page-header">to preview</div>
                    <div className="empty-page-text">To see the data preview, please choose your test environment</div>
                </div>
            </div>}
        </div>
        <Modal centered
               className="query-export-modal-dialog"
               open={exportState !== EXPORT_STATES.NONE}
               closable={false}
               footer={null}
               data-testid='modal'>
            <span className="query-export-modal-title">Exporting as .{exportType?.toUpperCase()}</span>
            <Button shape="circle"
                    className="query-export-modal-close-btn"
                    data-testid='cancel'
                    icon={<CloseCircleFilled className="query-export-modal-close-btn-icon"/>}
                    onClick={onCancel}/>
            <div className="query-export-modal-body">
                {exportState === EXPORT_STATES.PROGRESS
                    && <Progress size="small" status="active"
                                className="query-export-modal-progress"
                                strokeColor={"#1890FF"}
                                showInfo={false}
                                percent={exportPercent} />}
                {exportState === EXPORT_STATES.DOWNLOAD
                    && <Button data-testid='download-file' className="query-export-modal-download-btn"
                              onClick={onDownload}>
                        <DownloadOutlined /> Download</Button>}
            </div>
        </Modal>
    </div>
}
