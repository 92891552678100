import './addCloudForm.css';
import '../deviceViewEdit/deviceViewEdit.css';

import {
    Form,
    Input,
    Radio
} from 'antd';

import AWSForm from "../awsForm/awsForm";
import GCPForm from "../gcpForm/gcpForm";

import {useEffect, useState} from "react";

const AddCloudForm = ({onNameChanged, onTypeChanged, onDataChanged}) => {

    const DEFAULT_NAME = '';
    const DEFAULT_ACCOUNT_TYPE = 'aws';

    const [name, setName] = useState(DEFAULT_NAME);
    const [accountType, setAccountType] = useState(DEFAULT_ACCOUNT_TYPE);

    useEffect( () => {
        onNameChanged(DEFAULT_NAME);
        onTypeChanged(DEFAULT_ACCOUNT_TYPE);
    },[]);

    const setCloudAccountName = (name) => {
        setName(name);
        onNameChanged(name);
    }

    const handleAccountTypeChange = (e) => {
        const accountType = e.target.value;
        setAccountType(accountType);
        onTypeChanged(accountType);
    }

    const content = accountType === 'aws' ? <AWSForm onDataChanged={onDataChanged}/> : <GCPForm onDataChanged={onDataChanged}/>

    return <div className="add-cloud-form" data-testid="device-aws-add">
        <h1>New Cloud account</h1>
        <Form
            layout="vertical"
            className="device-view-edit-form"
        >
            <Form.Item label="Name">
                <Input style={{height: 40}} placeholder="Cloud account name" value={name} onChange={(e) => setCloudAccountName(e.target.value)} data-testid="text-name"/>
            </Form.Item>
        </Form>
        <h2>Configuration</h2>
            <div className="account-type-switch">
                <Radio.Group onChange={handleAccountTypeChange} value={accountType}>
                    <Radio.Button value="aws" data-testid="aws-cloud-type">AWS</Radio.Button>
                    <Radio.Button value="gcp" data-testid="gcp-cloud-type">GCP</Radio.Button>
                </Radio.Group>
            </div>
            <div>
                {content}
            </div>
    </div>
}

export default AddCloudForm;