export function policyAllowed(authUser: any, claim: string, _context?: any): boolean {
    const test = claimToPolicy(claim);
    return authUser?.policies?.every((p: any) => {
        if(p.resource === test.resource) {
            if (test.resourceType === '' || p.resourceType === test.resourceType) {
                if (test.scope === '' || p.scope === test.scope) {
                    if(p.permission === 'DENY') {
                        return false;
                    }
                }
            }
        }
        return true;
    })??true;
}

export function claimToPolicy(claim: string) {
    const claimArr = claim.split(':');
    return {
        resource: (claimArr[0]??'').toUpperCase(),
        resourceType: (claimArr[1]??'').toUpperCase(),
        scope: (claimArr[2]??'').toUpperCase(),
        permission: (claimArr[3]??'').toUpperCase(),
    }
}