import {useState} from 'react';
import NotificationsController, {GENERAL_ERROR} from "../controllers/notificationsController";

const useNotificationsOperations = (api) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const notificationsController = new NotificationsController({apiClient: api});

    const loadNotifications = async (dateRange, receiverId) => {
        setLoading(true);
        const result = await notificationsController.listNotifications(dateRange, receiverId);
        if (result === GENERAL_ERROR) {
            setError('Failed to load notifications');
        }
        setLoading(false);
        return result;
    }

    const markNotificationAsRead = async (notificationId) => {
        setLoading(true);
        const result = await notificationsController.markNotificationAsRead(notificationId);
        setLoading(false);
        return result;
    }

    const markAllNotificationAsRead = async (notifications) => {
        setLoading(true);
        const result = await notificationsController.markAllNotificationAsRead(notifications);
        setLoading(false);
        return result;
    }

    return [loadNotifications, markNotificationAsRead, markAllNotificationAsRead, loading, error];
};

export default useNotificationsOperations;
