import QueryItem from "../queryItem/queryItem";
import QueryEditItem from "../queryEditItem/queryEditItem";
import React, {useCallback, useState} from "react";
import {message} from "antd";
import GroupsController from "../../controllers/groupsController";
import {showDeactivateConfirm} from "../utils";

const ClientsGroupEditableTitle = ({ currentGroup, api, onTitleUpdated, onGroupDeleted }) => {

    const groupsController = new GroupsController({ apiClient: api });

    const [isEditMode, setIsEditMode] = useState(false);

    const deleteGroup = useCallback(async (group) => {
        try {
            await groupsController.updateGroup(currentGroup.id, {name: group.name, active: false});
            onGroupDeleted(group);
            message.success('Group has been successfully deleted');
        } catch (err) {
            message.error('Unable to delete group');
        }
    }, [currentGroup, onGroupDeleted]);

    const handleDeleteGroup = useCallback((group) => {
        showDeactivateConfirm('group', () => deleteGroup(group))
    }, [currentGroup, onGroupDeleted]);

    const onEdit = useCallback(() => {
        setIsEditMode(true);
    }, []);

    const saveGroup = async (group, newName) => {
        try {
            await groupsController.updateGroup(group.id, {name: newName, active: true});
            cancelEdit();
            onTitleUpdated({ id: group.id, name: newName });
        } catch (e) {
            message.error('Unable to update group');
        }
    }

    const cancelEdit = () => {
        setIsEditMode(false);
    }

    return (
        <>
            {!isEditMode && <QueryItem style={{display: "flex"}} disabled={false} query={{
                id: currentGroup.id,
                name: currentGroup.name
            }} onDelete={handleDeleteGroup} onEdit={onEdit}/>}
            {isEditMode && <QueryEditItem query={{
                id: currentGroup.id,
                name: currentGroup.name
            }} onSaveTitle={saveGroup} onCancelEdit={cancelEdit} disabled={false}/>}
        </>
    )
}

export default ClientsGroupEditableTitle;
