import './queryEditor.css';
import {useEffect, useRef, useState} from "react";
import {useRecoilState} from 'recoil';
import {Button, message, Modal} from 'antd';
import Timeout from "../../assets/images/timeout-icon.svg";
import Warning from '../../assets/images/warning-black.svg';
import QueryInventory from "../queryInventory/queryInventory";
import Splitter, {SplitDirection} from '@devbookhq/splitter'
import * as utils from "../utils";
import { PlayCircleOutlined, StopOutlined, UploadOutlined, WarningOutlined } from '@ant-design/icons';
import DebugScanResults from "../debugScanResults/debugScanResults";
import QueryEditableTitle from "../queryEditableTitle/queryEditableTitle";
import queryUpdatedState from "../../atoms/queryUpdated";
import deletedQueryState from "../../atoms/deletedQuery";
import openedDebugQueriesState from "../../atoms/openedDebugQueries";
import forceMappingUpdateState from "../../atoms/forceMappingUpdate";
import queryTextState from "../../atoms/queryText";
import {scanSubTypes} from "../../constants";
import SqlEditor from "../sqlEditor/sqlEditor";

const pagingInfo = {
    page: 1,
    showSizeChanger: false,
    limit: 10,
    totalPages: 1,
    handlePagination: () => {}
}

const QueryEditor = ({query, distQuery, orgId, api, scanTopic, useSplitter, loadQueries, onSaveQuery, scanType, isVioletXOnly}) => {

    const defaultQueryText = query.resultEvaluation ? query.query : distQuery.query;
    const isDisabled = (scanType === scanSubTypes.SYSTEM) || (scanType === scanSubTypes.VIOLET_X && !isVioletXOnly)

    const DEFAULT_TIMEOUT_LIMIT = 180;
    const [resize, setResize] = useState([40, 60]);
    const [executionTimeout, setExecutionTimeout] = useState(distQuery.executionTimeout || DEFAULT_TIMEOUT_LIMIT);
    const [isInProgress, setIsInProgress] = useState(false);
    const [mappingFields, setMappingFields] = useState([]);
    const [mappingInfo, setMappingInfo] = useState(null);
    const [queryName, setQueryName] = useState('');
    const [triggerRun, setTriggerRun] = useRecoilState(forceMappingUpdateState);
    const distributionQuery = JSON.parse(JSON.stringify(distQuery));
    const [, setDeletedQuery] = useRecoilState(deletedQueryState);
    const [queryUpdated, setQueryUpdated] = useRecoilState(queryUpdatedState);
    const [openedDebugQueries, setOpenedDebugQueries] = useRecoilState(openedDebugQueriesState);
    const [queryText, setQueryText] = useRecoilState(queryTextState(query.id));

    const [executionState, setExecutionState] = useState({
        queryResults: [],
        debugResultsCount: 0,
        finishedDevices: 0,
        debugTimeout: 0,
        platforms: [],
        failedCount: 0
    });
    const apiClient = api;

    const interval = useRef(null);

    const setDefaultExecutionTimeout = async () => {
        if (!executionTimeout) {
            await updateExecutionTimeout(DEFAULT_TIMEOUT_LIMIT);
            setExecutionTimeout(DEFAULT_TIMEOUT_LIMIT);
        } else {
            await updateExecutionTimeout(executionTimeout)
        }
    }

    const updateExecutionTimeout = async (timeout) => {
        if (!timeout) {
            return;
        }
        const params = JSON.parse(JSON.stringify(distributionQuery));
        params.executionTimeout = timeout;
        delete params.id;
        delete params.organizationId;
        await apiClient
            .get()
            .distributedQueriesApi
            .distributedQueryPatch(
                [`id:${distributionQuery.id}`],
                params
            );
    }

    const changeExecutionTimeout = async (value) => {

        if (value.length > 0 && isNaN(parseInt(value))) {
            value = '';
        }
        const convertedTimeout = parseInt(value) || '';

        if (value.length > 4) {
            return;
        }

        setExecutionTimeout(convertedTimeout);
    }

    useEffect(() => {
        if (!queryText) {
            setQueryText(defaultQueryText);
        }
       return () => {
           clearInterval(interval.current);
           interval.current = null;
       }
    }, []);

    useEffect(async () => {
        await getQueryStatus(distributionQuery.id, 1);
    }, []);

    useEffect(() => {
        if (queryUpdated && queryUpdated.id === query.id) {
            setQueryName(queryUpdated.name);
        } else {
            setQueryName(null);
        }
    }, [queryUpdated]);

    useEffect(() => {
        let fields = [];
        let customFields = [];
        const activeQueries = executionState.queryResults.filter(query => query.execute !== 'RUN' && query.execute !== 'POPULATED')
        const resultWithData = activeQueries.filter(q => q.rawDataFields !== null && q.rawDataFields !== undefined);
        if (query.resultEvaluation && !triggerRun) {
            const parsed = JSON.parse(query.resultEvaluation);
            fields = parsed.fields?.map(el => el.source) ?? [];
            setMappingFields(fields);
        }
        if (resultWithData.length > 0) {
            const rawData = resultWithData[0].rawDataFields;
            fields = rawData.split(',').map((field) => field);
            setMappingFields(fields);
        }
        if (mappingInfo === null) {
            try {
                const parsed = JSON.parse(query.resultEvaluation??'{}');
                fields = parsed && parsed.fields ? parsed.fields : [];
                customFields = parsed && parsed.customFields ? parsed.customFields.map(el => el) : [];
                setMappingInfo({fields, customFields});
            }
            catch (e) {
                console.log('incorrect mapping data', query.resultEvaluation, e);
            }
        }
    }, [query, distQuery, executionState.queryResults]);

    const handleQueryTextChange = (value) => {
        setQueryText(value);
    }
    const updateQuery = async () => {
        await apiClient
            .get()
            .distributedQueriesApi
            .distributedQueryPatch(
                [`id:${distributionQuery.id}`],
                {
                    query: queryText,
                    name: distributionQuery.name,
                    queryId: query.id,
                    executionTimeout: executionTimeout
                }
            );
    }

    const updateQueryStatus = async (status) => {
        if (status === 'RUN' && isInProgress === false) {
            updateExecutionState([], 0, 0, 0, []);
            setMappingFields([]);
            setMappingInfo({ fields: [], customFields: []});
        }
        if (status === 'RUN') {
            setIsInProgress(true);
        }
        if (status === 'STOPPED') {
            clearInterval(interval.current);
            setIsInProgress(false);
        }
        try {
            await updateQuery();
            await apiClient
                .get()
                .distributedQueryResultsApi
                .distributedQueryResultPatch(
                    [`distributedQueryId:${distributionQuery.id}`],
                    {
                        execute: status,
                        executionTimeout: executionTimeout
                    }
                );
            setIsInProgress(status === 'RUN');
            if (status === 'RUN') {
                await getQueryStatus(distributionQuery.id);
            }
        } catch (err) {
            console.log(err);
            showError('Unable to run query');
        }
    }

    const updateExecutionState = (queryResults, debugResultsCount, finishedDevices, debugTimeout, platforms, failedCount) => {
        const state = JSON.parse(JSON.stringify(executionState));
        if (queryResults !== false) {
            state.queryResults = queryResults;
        }
        if (debugResultsCount !== false) {
            state.debugResultsCount = debugResultsCount;
        }
        if (debugTimeout !== false) {
            state.debugTimeout = debugTimeout;
        }
        if (finishedDevices !== false) {
            state.finishedDevices = finishedDevices;
        }
        if (platforms !== false) {
            state.platforms = platforms;
        }
        if (failedCount !== false) {
            state.failedCount = failedCount;
        }
        setExecutionState(state);
    }

    const prepareQueryResults = (queryResults) => {
        return queryResults?.map(queryResult => {
            return {
                ...queryResult
                , isDeviceOnline: utils.deviceIsOnline(queryResult.deviceTimestamp)
            }
        })??[];
    }

    const getQueryStatus = async (id, page) => {

        clearTimeout(interval.current);

        const totals = await apiClient
            .get()
            .distributedQueryResultsApi
            .distributedQueryResultStatusList(
                [],
                [],
                [`distributedQueryId:${id}`
                    ,`organizationId:${orgId}`
                    ,'active:true'],
                1,
                10
            );

        const data = totals.data.data[0];
        if (data) {
            const platforms = data.completedDeviceTypes.length > 0 ? [...data.completedDeviceTypes.split(',')] : [];
            const response = await apiClient
                .get()
                .distributedQueryResultsApi
                .distributedQueryResultList(
                    [],
                    [],
                    [`distributedQueryId:${id}` ,`organizationId:${orgId}` ,'active:true'],
                    ['userFirstName', 'userLastName', 'deviceType', 'deviceInfo', 'deviceTimestamp'],
                    page || 1,
                    10
                );

            const results = prepareQueryResults(response.data.data);

            const finishedDevices = parseInt(data.STOPPED) +
                parseInt(data.COMPLETED) +
                parseInt(data.FAILED) +
                parseInt(data.TIMEOUT);

            pagingInfo.totalPages = response.data.page.total;
            pagingInfo.page = page || 1;

            const isInProgress = parseInt(data.RUN) + parseInt(data.POPULATED) > 0;
            updateExecutionState(results, data.total, finishedDevices, parseInt(data.TIMEOUT), platforms, parseInt(data.FAILED));
            setIsInProgress(isInProgress);
            if (isInProgress) {
                interval.current = setTimeout(async () => {
                    await getQueryStatus(id);
                }, 5000);
            }
        } else {
            setIsInProgress(false);
            updateExecutionState( [], 0, 0, 0, [], 0);
        }
    }

    const showError = (text) => {
        return message.error(text);
    }

    const showMessage = (text) => {
        return message.success(text);
    }

    const applyAsLive = async () => {

        const requestParams = JSON.parse(JSON.stringify(Object.assign({}, query)));
        const currentQuery = JSON.parse(JSON.stringify(Object.assign({}, query)));
        delete requestParams.id;
        delete requestParams.distributedQueryId;

        requestParams.query = queryText;
        currentQuery.query = distributionQuery.query;
        requestParams.name = queryName ?? query.name;

        const response = await apiClient
            .get()
            .distributedQueryResultsApi
            .distributedQueryResultStatusList(
                [],
                [],
                [`distributedQueryId:${query.distributedQueryId}`
                    , `organizationId:${orgId}`
                    , 'active:true']
            );

        const resultStatus = response.data.data[0];
        requestParams.deviceTypes = resultStatus?.completedDeviceTypes ?? '';
        requestParams.resultEvaluation = JSON.stringify(mappingInfo);
        requestParams.resultType = 'json';

        await apiClient.get().queriesApi.queryUpdate(query.id, requestParams);
        await apiClient.get()
            .distributedQueriesApi
            .distributedQueryPatch(
                [`id:${distributionQuery.id}`],
                {
                    query: queryText,
                     name: queryName ?? distributionQuery.name,
                    queryId: query.id,
                    executionTimeout: distributionQuery.executionTimeout
                }
            );
        requestParams.id = query.id;
        requestParams.distributedQueryId = query.distributedQueryId;
        setQueryUpdated(requestParams);
        const openedQueries = JSON.parse(JSON.stringify(openedDebugQueries));
        const index = openedQueries.queries.findIndex(q => q.query.id === query.id);
        if (index >= 0) {
            openedQueries.queries[index].query = requestParams;
            setOpenedDebugQueries(openedQueries);
        }
        showMessage('Query successfully updated');
    }

    const deleteQuery = async (query) => {
        try {
            const current = await apiClient.get().queriesApi.queryGetById(query.id);
            const params = JSON.parse(JSON.stringify(current.data));
            params.active = false;
            delete params.id;
            await apiClient.get().queriesApi.queryUpdate(query.id, params);
            await loadQueries();
            setDeletedQuery(query);
            message.success('Query has been successfully deleted');
        } catch (err) {
            console.log(err);
            await message.error('Unable to delete query');
        }
    }


    const doPublish = async () => {
        if (executionState.platforms.length === 0) {
            const apply = () => applyAsLive();
            Modal.confirm({
                title: 'Apply as Live',
                icon: <WarningOutlined style={{ color: '#FF4D4F'}} />,
                content: 'Attention: query,  that you are about to Apply didn\'t return a response from any device. Do you want to apply changes anyway?',
                okText: 'Continue',
                cancelText: 'Cancel',
                className: 'apply-as-live',
                cancelButtonProps: {type: 'primary'},
                okButtonProps: {'data-testid': 'applyAsLive', type: 'default' },
                onOk() {
                    apply();
                }
            });
        } else {
            await applyAsLive();
        }
    }

    const onMappingChanged = (mappingInfo) => {
        setMappingInfo(mappingInfo);
    }

    const onResizeFinished = (gutterIdx, newSizes) => {
        setResize(newSizes);
    }

    const executeButtonIcon = isInProgress ? <StopOutlined/> : <PlayCircleOutlined/>
    const isApplyButtonInError = isInProgress === false && executionState.platforms.length === 0;

    pagingInfo.handlePagination = getQueryStatus;
    const currentQuery = JSON.parse(JSON.stringify(query));
    currentQuery.name = queryName || query.name;

    const container = <div className="editor-container" data-testid="editor-container">
        {!isDisabled ? <div className="command-buttons" data-testid="command-buttons">
            <Button className="command-button" onClick={async () => {
                await updateQueryStatus(isInProgress ? 'STOPPED' : 'RUN');
                setTriggerRun(query.id);
            }}
                    type="primary" icon={executeButtonIcon} data-testid="run-query">
                {isInProgress ? 'Stop' : 'Run'}
            </Button>
            <QueryEditableTitle query={currentQuery} onEdit={onSaveQuery} onDelete={deleteQuery} disabled={isDisabled} />
            <Button className="command-button" data-testid="publish-query" icon={<UploadOutlined/>} disabled={isDisabled || isInProgress} danger={isApplyButtonInError} onClick={doPublish}>
                Publish
            </Button>
        </div> : <div className="disabled-text-wrapper"><img className="warning" src={Warning} alt=''/><span>This is a system query and cannot be edited</span></div>}
        <div>
            <SqlEditor disabled={isDisabled || isInProgress}
                       defaultText={queryText}
                       handleEditorChange={handleQueryTextChange}
                       saveQuery={updateQuery}
                       scanTopic={scanTopic}
            />
            {!isDisabled &&<div className="query-config-container" data-testid="query-config-container">
                <div className="timeout-limit">
                    <div>Timeout limit</div>
                    <div>
                        <span><img src={Timeout} alt={""}/></span>
                        <span>
                                    <input
                                        data-testid="executionTimeout"
                                        value={executionTimeout}
                                        disabled={isInProgress}
                                        onBlur={setDefaultExecutionTimeout}
                                        onChange={event => changeExecutionTimeout(event.target.value)}
                                    />
                                </span>
                        <span>sec</span>
                    </div>
                </div>
                <div className="query-config">
                    <div>
                        <QueryInventory platforms={executionState.platforms} inventoryName={"Platforms"}/>
                        <QueryInventory scanTopic={scanTopic} orgId={orgId} disabled={isInProgress} api={apiClient} query={query} inventoryName={"Debug inventory"}/>
                    </div>
                </div>
            </div>}
        </div>
    </div>;

    const resultPanel = <div className="results-container" data-testid="results-container">
        { <DebugScanResults scanTopic={scanTopic} mappingInfo={mappingInfo} onMappingChanged={onMappingChanged} fields={mappingFields} pagingInfo={pagingInfo} apiClient={apiClient} query={query} orgId={orgId} isInProgress={isInProgress} executionInfo={executionState}/> }
    </div>;

    return <div className="query-editor">
        {useSplitter ?
            <Splitter
                gutterClassName="editor-gutter"
                direction={SplitDirection?.Vertical}
                initialSizes={!isDisabled && resize }
                draggerClassName="editor-dragger"
                onResizeFinished={onResizeFinished}
            >
                {container}
                {!isDisabled ? resultPanel : <></>}
            </Splitter>
        : <>
            {container}
            {!isDisabled ? resultPanel : <></>}
        </>}

    </div>
}

export default QueryEditor;
