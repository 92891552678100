import SidebarLayout from "../sidebarLayout/sidebarLayout";
import {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams } from "react-router-dom";
import DeviceScans from "../deviceScans/deviceScans";
import {
    LaptopOutlined,
    CloudOutlined
} from '@ant-design/icons';

import './integrationsConfig.css';
import Scan from "../scan/scan";

export default function IntegrationsConfigPage({ accessScope, api }) {

    const navigate = useNavigate();
    const location = useLocation();
    const {summary} = useParams();

    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState(['devices-scans']);

    const onTabSelect = (tab, page) => {
        navigate(`/integrations-config/${page}/scanner-${page}/violetx-scans`);
    }

    const tabs = [
        { key:"devices-scans", "data-testid": 'tab-assets-devices-scans', title: 'VX Devices Scanner', label: 'VX Devices Scanner', disabled: false, icon: <LaptopOutlined/>,
            page: <DeviceScans allowExport={false} type="DEVICES" api={api} accessScope={accessScope} showVioletXOnly={true}/>, onClick: () => onTabSelect(['devices-scans'], 'devices') },
        {
            key:"cloud-scans", "data-testid": 'tab-assets-cloud-steampipe', title: 'VX Clouds Scanner', label: 'VX Clouds Scanner', disabled: false, icon: <CloudOutlined/>,
            page: <DeviceScans allowExport={false} type="CLOUDS" api={api} accessScope={accessScope} showVioletXOnly={true} />, onClick: () => onTabSelect(['cloud-scans'], 'clouds')
        }
    ];

    useEffect(() => {
        const path = location.pathname.split('/');
        const selectedTab = path.includes('devices') ? tabs.find((t) => t.key === tabs[0].key) : tabs.find((t) => t.key === tabs[1].key)
        setDefaultSelectedKeys([selectedTab.key]);
    },[location]);

    const pathname = location.pathname;
    let defaultTab;

    let defaultOpenKeys = ['devices-scans'];

    if (location.pathname.includes('clouds')) {
        defaultOpenKeys = ['cloud-scans'];
    }

    const summaryTab =
        {
            key: 'scan-summary',
            "data-testid": 'tab-assets-scan-summary',
            title: 'VX Devices Scanner',
            label: 'VX Devices Scanner',
            disabled: false,
            icon: <LaptopOutlined/>,
            page: <Scan api={api} isVioletXScan={true} accessScope={accessScope} organizationId={accessScope.organizationId} topic={defaultSelectedKeys[0] === 'devices-scans' ? 'DEVICES' : 'CLOUDS'}/>
        }

    switch (true) {
        case summary !== undefined || pathname.includes('add'):
            defaultTab = summaryTab;
            break;
        case pathname.includes('scanner-devices'):
            defaultTab = tabs[0];
            break;
        case pathname.includes('scanner-clouds'):
            defaultTab = tabs[1];
            break;
    }

    return <SidebarLayout
                defaultSelectedPage={defaultTab}
                defaultSelectedKeys={defaultSelectedKeys}
                defaultOpenKeys={defaultOpenKeys}
                sidebarTabs={tabs}
                collapsed={true}
                sideBarStyle={
                    {
                        width: '248px',
                        height: '100%',
                        backgroundColor: '#F5F5F5'
                    }
                }
            />
}