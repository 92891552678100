import ApiClient from "../apiClient";

export default class ProjectsStatusReportController {
    apiClient = null;

    constructor() {
        this.apiClient = new ApiClient();
    }

    getById = async (projectId) => {
        let result = null;
        try {
            const response = await this.apiClient.get().projectApi.projectGetById(projectId);
            result = response.data;
        } catch (error) {
            console.error('Error getting project by id', error);
        }
        return result;
    }

    setSchedule = async (projectId, schedule) => {
        try {
            await this.apiClient.get().projectApi
                .projectPatch(
                    [`id:${projectId}`],
                    {statusReportSchedule: JSON.stringify(schedule)}
                );
        } catch (error) {
            console.error('Error setting status report schedule', error);
        }
    }

}
