import React from 'react';
import { Input, Button } from 'antd';
import { PictureOutlined, PaperClipOutlined, SmileOutlined } from '@ant-design/icons';

const AppCommentTextArea = ({commentText, setCommentText, handleKeyPress, saveComment, onResize}) => {

    const {TextArea} = Input;

    return (
        <div className="input-wrapper">
            <TextArea
                type="text"
                placeholder="Type your comment here."
                data-testid="comment-area"
                value={commentText}
                autoSize={{ minRows: 2, maxRows: 8 }}
                onChange={(e) => setCommentText(e.target.value)}
                onKeyDown={handleKeyPress}
                onResize={(e) => onResize(e.offsetHeight)}
            />
            <div className="icon-container">
                <PictureOutlined disabled={true} />
                <PaperClipOutlined disabled={true} />
                <SmileOutlined disabled={true} />
                <Button
                    type="primary"
                    size={'63px'}
                    onClick={() => saveComment(commentText)}
                    disabled={commentText.trim().length === 0}
                >
                    Send
                </Button>
            </div>
        </div>
    );
};

export default AppCommentTextArea;
