import './vxPointOfContactFilter.css';
import {useEffect, useState} from "react";
import {DownOutlined} from "@ant-design/icons";
import {Checkbox, Dropdown} from "antd";
import {UserAvatar} from "../userAvatar/userAvatar";

const UNASSIGNED_CONTACT = {id: null, name: 'Unassigned'};

const VxPointOfContactFilter = ({ onChange, uberAdmins, defaultSelected, loggedUserId }) => {

    const [isDropdownActive, setIsDropdownActive] = useState(false);
    const [selectedContacts, setSelectedContacts] = useState([defaultSelected]);


    useEffect(() => {
        setSelectedContacts([defaultSelected]);
    }, [defaultSelected]);

    const onCheckboxChange = async (item, checked) => {
        let newSelectedContacts = checked ?
            ([...selectedContacts, item]) :
            ([...selectedContacts.filter(el => el !== item)]);
        setSelectedContacts(newSelectedContacts);
        onChange(newSelectedContacts);
    }

    const getCssClassName = (selected, dropdown) => {
        const baseClassName = 'dropdown';
        const activeClass = isDropdownActive === dropdown ? 'active' : '';
        const selectedClass = selected ? 'selected' : '';
        return `${baseClassName} ${activeClass} ${selectedClass}`;
    };

    const getFilterText = (value) => {
        return value.length > 1 ? `${value[0]}+${value.length -1}` : value[0];
    }

    const usersToDisplay = () => {

        uberAdmins.sort((a, b) => {
            const aInSelectedContacts = selectedContacts.find(el => el?.id === a.id);
            const bInSelectedContacts = selectedContacts.find(el => el?.id === b.id);
            if (aInSelectedContacts && !bInSelectedContacts) {
                return -1;
            } else if (!aInSelectedContacts && bInSelectedContacts) {
                return 1;
            } else {
                return 0;
            }
        });

        const loggedUser = uberAdmins.find(u => u.id === loggedUserId);
        const ubersToUse = uberAdmins.filter(u => u.id !== loggedUserId);

        return <div className="status-wrapper responsible">
            <div className="user-item">
                <Checkbox checked={selectedContacts.find(el => el?.id === loggedUserId)}
                          onChange={(e) => onCheckboxChange(loggedUser, e.target.checked)}/>
                <UserAvatar size={26} name={loggedUser.name} fileName={loggedUser.fileName}/>
                <span className='name'>{loggedUser.name}</span>
            </div>
            <div className="user-item">
                <Checkbox checked={selectedContacts.find(el => el?.id === null)}
                          onChange={(e) => onCheckboxChange(UNASSIGNED_CONTACT, e.target.checked)}/>
                <span style={{marginLeft: '8px'}}>Unassigned</span>
            </div>
            <div className="separator"/>
            {
                ubersToUse.map(u => {
                    return <div className="user-item" key={u.id}>
                        <Checkbox checked={selectedContacts.find(el => el?.id === u.id)}
                                  onChange={(e) => onCheckboxChange(u, e.target.checked)}/>
                        <UserAvatar size={26} name={u.name} fileName={u.fileName}/>
                        <span className='name'>{u.name}</span>
                    </div>
                })}
        </div>
    }


    return <div className="vxPointOfContact-filter">
        <Dropdown
            trigger={['click']}
            style={{width: '250px'}}
            dropdownRender={() => usersToDisplay()}
            onOpenChange={(e) => setIsDropdownActive(e ? 'requested' : false)}
        >
            <div data-testid="requested-dropdown" className={getCssClassName(selectedContacts.length !== 0, 'requested')} >
                <span className="filter-label">{`vX Point of contact${selectedContacts.length > 0 ? ': ' + getFilterText(selectedContacts.map(u => u?.name)) : ''}`}</span>
                <DownOutlined />
            </div>
        </Dropdown>
    </div>;
};

export default VxPointOfContactFilter;