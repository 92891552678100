import './mappingGrid.css';
import {QUERY_TRANSFORM_FIELDS, QUERY_TRANSFORM_RESERVED_FIELDS} from "@violetx-mvp/commons";
import {useEffect, useState} from "react";
import { Select } from 'antd';
import {useRecoilState} from "recoil";
import forceMappingUpdateState from "../../atoms/forceMappingUpdate";
import MappingArrow from "../../assets/images/mapping-arrow.svg";

const MappingGrid = ({fields, onMappingChanged, allowedMenuItems, mappingInfo, disabled, isInProgress}) => {

    const [, setForceMappingUpdate] = useRecoilState(forceMappingUpdateState);
    const [,setForceUpdate] = useState(Date.now());
    const mappingItems = mappingInfo.fields || [];
    const [combinedFields, setCombinedFields] = useState([]);

    useEffect(() => {
        const fieldsToDisplay = getOptions(fields);
        const combined = [...QUERY_TRANSFORM_FIELDS];
        fieldsToDisplay.forEach((f) => {
            if (QUERY_TRANSFORM_FIELDS.indexOf(f) === -1) {
                combined.push(f.label);
            }
        });
        setCombinedFields(combined);
        if (!mappingInfo || mappingInfo.fields.length === 0) {
           const newMappingItems = mappingItems;
            QUERY_TRANSFORM_RESERVED_FIELDS.forEach(f => {
               newMappingItems.push({source: f, target: f});
            });
           onMappingChanged({fields: newMappingItems, customFields: mappingInfo.customFields});
       } else {
           setForceUpdate(Date.now());
       }
        return () => {
           setForceMappingUpdate(null);
       }
    }, [disabled]);

    useEffect(() => {
        if (isInProgress) {
            onMappingChanged({fields: [], customFields: []});
        }
    }, [isInProgress]);

    useEffect(() => {
        QUERY_TRANSFORM_FIELDS.forEach(f => {
            if (!fields.some(field => field.toLowerCase() === f.toLowerCase())) {
                fields.unshift(f);
            }
        });
        setForceUpdate(Date.now());
    }, [fields]);

    const removeMappingItem = (fieldName) => {
        const copy = JSON.parse(JSON.stringify(mappingItems));
        const index = copy.findIndex(o => o.target === fieldName);
        if (index > -1) {
            copy.splice(index, 1);
            onMappingChanged({fields: copy, customFields: mappingInfo.customFields});
        }
    }

    const doItemMap = (fieldName, value) => {

        if (!value) {
            removeMappingItem(fieldName);
            return;
        }
        const copy = JSON.parse(JSON.stringify(mappingItems));
        const existingIndex = copy.findIndex(o => o.target === fieldName);
        if (existingIndex > -1) {
            copy.splice(existingIndex, 1);
        }
        copy.push({
            source: value,
            target: fieldName
        })
        onMappingChanged({fields: copy, customFields: mappingInfo.customFields});
    }

    const getMappedItems = () => {

        const mappedItems = [...mappingInfo.customFields || []];

        if (mappingInfo && mappingInfo.fields.length > 0) {
            mappingInfo.fields.forEach(m => mappedItems.push(m.source));
        }

        return mappedItems;
    }

    const getMappingDropDown = (fieldName, index) => {

        const items = [];

        if (!disabled) {
            allowedMenuItems.forEach((t, i) => {
                items.push({
                    label: t,
                    value: t,
                    key: `mapping-filed-${i}`
                });
            });
        }

        const mappedItems = getMappedItems();
        const customs = disabled ? [] : getOptions(fields);
            customs.forEach((t, i) => {
                if (mappedItems.indexOf(t.value) === -1) {
                    items.push({
                        label: t.label,
                        value: t.value,
                        key: `mapping-filed-custom-${i}`
                    });
                }
            });

        const mappedItem = isInProgress ? false : mappingItems.find(i => i.target === fieldName);
        let valueToDisplay = 'Select field to map';
        if (mappedItem) {
            valueToDisplay = mappedItem.source;
        }

        const className = valueToDisplay !== 'Select field to map' ? '' : 'empty-select';
        const allowClear = valueToDisplay !== 'Select field to map';

        return <Select
            data-testid={`mapping-item-${index}`}
            disabled={disabled}
            className={className}
            placeholder="Select field to map"
            onChange={(t) => doItemMap(fieldName, t)}
            onClear={(t) => doItemMap(fieldName, t)}
            defaultValue={valueToDisplay}
            value={valueToDisplay}
            allowClear={allowClear}
            options={items}
        />
    }

    const getOptions = (fields) => {
        if (!fields || fields.length === 0) {
            return [];
        }
        const result = [];
        const mappedItems = getMappedItems();
        fields.forEach(f => {
            if (mappedItems.indexOf(f) === -1 && QUERY_TRANSFORM_FIELDS.indexOf(f) === -1) {
                result.push({
                    label: f,
                    value: f
                });
            }
        })
        return result;
    }

    const handleCustomChange = (value) => {
        onMappingChanged({ fields: mappingItems, customFields: value});
    };

    const getCustomValues = () => {
        const customValues = [];
            if (mappingInfo.customFields && mappingInfo.customFields.length > 0) {
                mappingInfo.customFields.forEach(f => {
                    if (QUERY_TRANSFORM_RESERVED_FIELDS.indexOf(f) === -1) {
                        customValues.push(f)
                    }
                });
            }
        return customValues;
    }

    const fieldsToDisplay = disabled ? [] : getOptions(fields);
    const customFieldsValue = disabled ? [] : getCustomValues();

    return (
        <div className="mapping-grid">
            <div className="header">
                <div>Dashboard</div>
                <div></div>
                <div>Query fields</div>
            </div>
            {combinedFields.map((field, index) => {
                if (QUERY_TRANSFORM_FIELDS.indexOf(field) !== -1) {
                    return <div className="row" key={`mapping-field-${index}`}>
                        <div className="row-custom">
                            <div>{field}</div>
                            <div><img src={MappingArrow} alt={""}/></div>
                            <div >{getMappingDropDown(field, index)}</div>
                        </div>
                    </div>
                }
            })}
            <div className="row">
                <div className="row-custom">
                    <div className="align-vertical">Raw data</div>
                    <div><img src={MappingArrow} alt={""}/></div>
                    <div>
                        <Select
                            disabled={disabled}
                            mode="multiple"
                            style={{
                                width: '100%',
                            }}
                            defaultValue={customFieldsValue}
                            value={customFieldsValue}
                            placeholder="Please select"
                            onChange={handleCustomChange}
                            options={fieldsToDisplay}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MappingGrid;

