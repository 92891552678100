import './templateSummary.css';
import {Button, Checkbox, Form, Input, Select, InputNumber, message, Radio} from "antd";
import {
    SaveOutlined,
    FolderOpenOutlined,
    QuestionCircleOutlined,
    FieldTimeOutlined,
    UserOutlined,
    MessageOutlined,
    OrderedListOutlined,
    PieChartOutlined
} from "@ant-design/icons";
import SearchableSelect from "../searchableSelect/searchableSelect";
import React, {useEffect, useState} from "react";
import ApiClient from "../../apiClient";
import UploadImage from "../uploadImage/uploadImage";
import {useNavigate} from "react-router-dom";
import {UserAvatar} from "../userAvatar/userAvatar";

const TemplateSummary = ({loggedUser, template, users, api, onSave}) => {

    const apiClient = api || new ApiClient();
    const navigate = useNavigate();
    const limit = 20;

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [allowQuestionnaire, setAllowQuestionnaire] = useState(false);
    const [allowEstimate, setAllowEstimate] = useState(false);
    const [allowResponsible, setAllowResponsible] = useState(true);
    const [responsible, setResponsible] = useState(loggedUser.userId);
    const [estimate, setEstimate] = useState(null);
    const [forms, setForms] = useState([]);
    const [isImageSaved, setIsImageSaved] = useState(false);
    const [templateId, setTemplateId] = useState(template ? template.id : null);
    const [selectedForm, setSelectedForm] = useState('');
    const [type, setType] = useState('VX_SERVICE');
    const [availableFor, setAvailableFor] = useState(template ? template.type : 'PUBLIC');
    const [searchValue, setSearchValue] = useState('');
    const [clients, setClients] = useState([]);
    const [selectedClients, setSelectedClients] = useState(template ? template.selectedClients??[] : []);
    const [selectChanged, setSelectChanged] = useState(template ? template.selectedClients??[] : []);
    const [enableChangeSelect, setEnableChangeSelect] = useState(true);
    const [page, setPage] = useState(1);
    const [isAllGroupsLoaded, setIsAllGroupsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { TextArea, Search } = Input;

    const isDataChanged = () => {
        let result = true;
        if (availableFor === 'SELECTED_CLIENTS' && selectedClients.length === 0) {
            result = false;
        }
        return result;
    }

    const getGroups = async (refresh, currentPage) =>
        await apiClient.get().groupsApi.groupsList(
            refresh ? '' : searchValue,
            [`+name`],
            ['active:true'],
            [],
            currentPage ?? page,
            limit
        );

    const getClients = async (refresh, currentPage) =>
        await apiClient.get().orgsApi.organizationList(
            refresh ? '' : searchValue,
            [`+name`],
            ['active:true'],
            [],
            currentPage ?? page,
            limit
        );


    const loadClients = async (onSearch, refresh, currentPage) => {
        setIsLoading(true);
        if (onSearch) {
            setPage(1);
            currentPage = 1;
            setIsAllGroupsLoaded(false);
        }
        let optionsToDisplay = [];
        let loadedGroups = null;
        if (!isAllGroupsLoaded || refresh || onSearch) {
            loadedGroups = await getGroups(refresh, currentPage);
            optionsToDisplay = loadedGroups.data.data.map((o, i) => {
                const isLastGroupItem = !loadedGroups.data.page.next && i === loadedGroups.data.data.length - 1;
                const label = ownerOptionRenderer(o, i, 'groups', isLastGroupItem);
                return {
                    label: label,
                    value: `${o.id}-g`
                }
            });
        }
        if (!loadedGroups?.data.page.next) {
            if (!isAllGroupsLoaded) {
                setIsAllGroupsLoaded(true);
                setPage(1);
                currentPage = 1;
            }
            const loadedClients = await getClients(refresh, currentPage);
            optionsToDisplay = [...optionsToDisplay, ...loadedClients.data.data.map((o, i) => {
                const label = ownerOptionRenderer(o, i, 'organizations');
                return {
                    label: label,
                    value: o.id
                }
            })];
        }
        if (optionsToDisplay.length > 0 || onSearch) {
            const uniqueArray = optionsToDisplay.filter(obj => !clients.find(e => e.value === obj.value));
            setClients(onSearch ? [...optionsToDisplay] : [...clients,...uniqueArray]);
        }
        setIsLoading(false);
    }

    useEffect(async () => {
        setIsImageSaved(false);
        await loadForms();
        await loadClients();
        const defaultSelectedClients = await getLabel();
        setSelectedClients(defaultSelectedClients);
        setSelectChanged([...defaultSelectedClients]);
    }, []);

    const loadForms = async () => {
        const response = await apiClient.get().templateApi.formsList()
        const forms = response.data?.items?.map(f => ({
            label: f.title,
            value: f.id
        }))??[];
        setForms(forms);
    }

    useEffect(() => {
        if (enableChangeSelect) {
            setSelectedClients([...selectChanged]);
        } else {
            searchValue.length > 0 && setSearchValue(searchValue.substring(0, searchValue.length - 1));
            setEnableChangeSelect(true);
            setSelectChanged([...selectedClients]);
        }
    },[selectChanged, enableChangeSelect, searchValue])

    useEffect(() => {
        if (template !== null) {
            setName(template.title);
            setDescription(template.description);
            setType(template.projectType);
            if (template.estimate !== null) {
                setEstimate(template.estimate);
                setAllowEstimate(true);
            }
            if (template.formId?.length > 0) {
                setSelectedForm(template.formId);
                setAllowQuestionnaire(true);
            }
            if (template.userId) {
                setResponsible(template.userId);
                setAllowResponsible(true);
            } else {
                setAllowResponsible(false);
            }
        }
    }, [template]);

    const ownerOptionRenderer = (option, index, bucketName, isLastGroupItem) => {
        return <div className={isLastGroupItem ? "lead-item last-group" : "lead-item"} data-testid={`option-item-${index}`}>
            {bucketName === 'groups' ? <FolderOpenOutlined/> : <UserAvatar showOnlyFirstLetter={true} bucketName={bucketName} size={26} fileName={option.fileName} key={option.name} name={option.name}/>}
            <span style={{marginLeft: '4px'}}>{option.name}</span>
        </div>
    }

    const getLabel = async () => {
        const promises = [];
        const filter = ['active:true'];
        let loadGroupData = false;
        let loadClientsData = false;
        const clientsFilter = ['active:true'];
        const defaultSelectedGroups = template?.selectedClientsGroups??[];
        defaultSelectedGroups.map(label => {
            if (!clients.find(v => v.id === label)) {
                filter.push(`id:${label}`);
                loadGroupData = true;
            }
        });
        loadGroupData && promises.push(new Promise((resolve) => {
            const group = apiClient.get().groupsApi.groupsList(
                '',
                [`+name`],
                filter,
                [],
                1,
                50
            );
            resolve(group);
        }))
        selectedClients.map(label => {
            if (!clients.find(v => v.id === label)) {
                clientsFilter.push(`id:${label}`);
                loadClientsData = true;
            }
        });
        loadClientsData && promises.push(new Promise((resolve) => {
                    const client = apiClient.get().orgsApi.organizationList(
                        '',
                        [`+name`],
                        clientsFilter,
                        [],
                        1,
                        50
                    );
                    resolve(client);
                }))
        let results = await Promise.all(promises);
        results = results.flatMap(data => data.data.data);
        return results.map(client => {
            const isClient = client.hasOwnProperty('fileName')
            return {
                label: ownerOptionRenderer(client, client.id, isClient ? 'organizations' : 'groups'),
                key:  isClient ? client.id : `${client.id}-g`,
                value: isClient ? client.id : `${client.id}-g`
            };
        });
    }

    const processQuestionnaire = (isChecked) => {
        if (!isChecked) {
            setSelectedForm(null);
        }
        setAllowQuestionnaire(isChecked);
    }

    const saveTemplate = async () => {

        let id = template ? template.id : null;

        const templateToSave = {
            title: name,
            description,
            estimate,
            type: availableFor,
            formId: selectedForm
        };

        if (availableFor === 'SELECTED_CLIENTS') {
            const groupIdsToAdd = [];
            const clientIdsToAdd = [];
            selectedClients.map(e => {
                const value = e.value ? e.value : e;
                typeof(value) === "string" ? groupIdsToAdd.push(+value.split('-')[0]) : clientIdsToAdd.push(value);
            });
            templateToSave.selectedClientsGroups = groupIdsToAdd.length > 0 ? JSON.stringify(groupIdsToAdd) : null;
            templateToSave.selectedClients = clientIdsToAdd.length > 0 ? JSON.stringify(clientIdsToAdd) : null;
        }

        if (type === 'VX_SERVICE') {
            templateToSave.userId = responsible
        }

        templateToSave.projectType = type;

        templateToSave.active = true;
        templateToSave.projectType = type;
        const response = await apiClient.get().templateApi.templateCreate(templateToSave);
        message.success(`Template has been successfully created`);
        id = response.data.templateId;

        setTemplateId(id);
        setIsImageSaved(true);
        navigate(`/projects/templates/summary/${id}`);
    }

    const allowSave = () => {
        if (!isDataChanged()) {
            return true;
        }
        return (!name || name.trim().length === 0) ||
            (!description || description.trim().length === 0) ||
            (allowResponsible ? !responsible : false);
    }

    async function onScroll(e) {
        const scrolledDown = parseInt(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight;
        if (scrolledDown && !isLoading) {
            setPage(page + 1);
            await loadClients(false, false, page + 1);
        }
    }

    const handleSearchValue = (e) => {
        setSearchValue(e.target.value);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.stopPropagation();
        }
    }

    const handleClientsChange = (value) => {
        setSelectChanged(value);
    }

    const onKeyDown = (e) => {
        if (e.key === 'Backspace') {
            setEnableChangeSelect(false);
        }
    }

    const getSearch = (menu) => (
        <>
            <Search placeholder="Search" style={{ height: 24 }}
                    className="options-search"
                    data-testid="options-search"
                    onSearch={() => loadClients(true)}
                    value={searchValue}
                    onChange={handleSearchValue}
                    onKeyDown={handleKeyDown}
            />
            {menu}
        </>
    )

    const handleDropdownVisibility = async (isOpen) => {
        if (isOpen) {
            setSearchValue('');
        } else {
            await loadClients(true, true);
        }
    }

    const onImageUploaded = () => {
        onSave(templateId);
        navigate(`/projects/templates/summary/${templateId}`);
        setIsImageSaved(false);
    }

    const defaultResponsible = users.find(u => u.id === responsible);
    const responsibleDropDownLabel = defaultResponsible ? defaultResponsible.id : 'Loading...';

    return <>
            <div className="template-summary">
            <div className="form-header">
                <h1 data-testid="templete-summary-title">Template summary</h1>
                <Button type="primary" data-testid="save-template" onClick={() => saveTemplate()}
                                        disabled={allowSave()}><SaveOutlined/> Save</Button>
            </div>
            <div className="wrapper">
                <Form
                    layout="vertical"
                    className="project-view-edit-form"
                >
                    <Form.Item label="Project template type">
                        <Radio.Group data-testid="type" className="buttons" onChange={(e) => setType(e.target.value)}
                                     value={type}>
                            <Radio.Button data-testid='VX_SERVICE' value='VX_SERVICE'>vX Service</Radio.Button>
                            <Radio.Button data-testid='DEFAULT' value='DEFAULT'>Default</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                        <>
                            <Form.Item label="Name">
                                <Input placeholder="Name" defaultValue={name} value={name} data-testid="text-name"
                                       onChange={(e) => {
                                           setName(e.target.value);
                                       }}/>
                            </Form.Item><Form.Item label="Description">
                            <TextArea autoSize={{
                                minRows: 3,
                                maxRows: 6,
                            }}
                                      multiple={true} cols={4} placeholder="Description example" value={description}
                                      data-testid="text-description" onChange={(e) => {
                                setDescription(e.target.value);
                            }}/>
                        </Form.Item>
                        </>
                    <Form.Item label="Template Picture">
                        <UploadImage
                            bucketName={'templates'}
                            api={api}
                            redirect={() => {
                            }}
                            id={templateId}
                            name=""
                            prevFileName={template ? template.fileName : null}
                            isImageSaved={isImageSaved}
                            uploadText="Drop a template picture here"
                            showTitle={false}
                            showUploadTitle={false}
                            width={209}
                            height={100}
                            preferAspect={16 / 9}
                            onImageUploaded={onImageUploaded}
                        />
                    </Form.Item>
                    { type === 'VX_SERVICE' &&
                        <Form.Item label={<Checkbox checked={allowQuestionnaire} onChange={(e) => {
                            processQuestionnaire((e.target.checked))
                        }}>Questionnaire</Checkbox>}>
                            <Select
                                disabled={!allowQuestionnaire}
                                placeholder="Choose questionnaire"
                                defaultValue={selectedForm}
                                value={selectedForm}
                                onChange={(value) => setSelectedForm(value)}
                                options={forms}
                            />
                        </Form.Item>}
                    {type === 'VX_SERVICE' && <><Form.Item label={<Checkbox checked={allowEstimate} onChange={(e) => {
                        setAllowEstimate((e.target.checked));
                        setEstimate(null)
                    }}>Estimate</Checkbox>}>
                        <InputNumber style={{width: 331}} placeholder="0" disabled={!allowEstimate}
                                     defaultValue={estimate} value={estimate} min={1}
                                     onChange={(value) => setEstimate(value)} data-testid="estimate-textbox"/><span
                        className="form-suffix">days</span>
                    </Form.Item>
                        <Form.Item label="Responsible">
                            <SearchableSelect
                                disabled={!allowResponsible}
                                selectedValueLabel={responsibleDropDownLabel}
                                onSelectedValueChanged={setResponsible}
                                options={users}
                                optionsRenderer={ownerOptionRenderer}
                            />
                        </Form.Item>
                    </>}
                    <h2>Access to template</h2>
                    <Form.Item label="Available for">
                        <Radio.Group data-testid="type" className="buttons"
                                     onChange={(e) => setAvailableFor(e.target.value)} value={availableFor}>
                            <Radio.Button data-testid='PUBLIC' value='PUBLIC'>Public</Radio.Button>
                            <Radio.Button data-testid='VX_ONLY' value='VX_ONLY'>vX only</Radio.Button>
                            <Radio.Button data-testid='SELECTED_CLIENTS' value='SELECTED_CLIENTS'>Selected
                                clients</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    {availableFor === 'SELECTED_CLIENTS' && <Form.Item label="Clients list">
                        <Select
                            dropdownRender={getSearch}
                            onKeyDown={onKeyDown}
                            mode="multiple"
                            labelInValue={selectedClients[0]?.label}
                            className="searchable-select"
                            popupClassName="searchable-select-popup"
                            data-testid="searchable-clients-select"
                            style={{width: '100%'}}
                            value={selectedClients}
                            onChange={handleClientsChange}
                            searchValue=''
                            options={clients}
                            onPopupScroll={onScroll}
                            onDropdownVisibleChange={handleDropdownVisibility}
                        />
                    </Form.Item>}
                </Form>
                {type === "VX_SERVICE" && <div className="vX-wrapper">
                    <div className="template-label">vX Service</div>
                    <div className="header">
                        vX Service projects that could be requested by clients, with enhanced features:
                    </div>
                    <div>
                        <QuestionCircleOutlined/>
                        Questionnaire
                    </div>
                    <div>
                        <FieldTimeOutlined/>
                        Project Estimation
                    </div>
                    <div>
                        <UserOutlined/>
                        vX Side Responsible Person
                    </div>
                    <div>
                        <MessageOutlined/>
                        Client Chat
                    </div>
                    <div>
                        <OrderedListOutlined/>
                        Project Phases
                    </div>
                    <div>
                        <PieChartOutlined/>
                        Status report
                    </div>
                </div>}
            </div>
        </div>
    </>
}

export default TemplateSummary;
