import './projectsTemplates.css';
import {useCallback, useRef, useState} from "react";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import ProjectsTemplate from "../projectsTemplate/projectsTemplate";
import {useNavigate} from "react-router-dom";

const ProjectsTemplates = ({templates, mockScrollTo, accessScope, orgId, projects}) => {

    const carousel = useRef(null);
    const carouselWrapper = useRef(null);
    const slideRef = useRef(0);
    const innerContainer = useRef(0);
    const [isNextDisabled, setIsNextDisabled] = useState(false);
    const [isBackDisabled, setIsBackDisabled] = useState(true);

    const navigate = useNavigate();

    const contentStyle = {
        margin: 0,
        height: '260px',
        color: '#fff',
        background: '#fff',
        display: 'flex',
        gap: 23
    };

    const goToPrev = useCallback(() => {

        let containerWidth = 900;

        if (slideRef.current
            && slideRef.current.childNodes.length > 0) {
            containerWidth = slideRef.current.childNodes[1].clientWidth;
        }

        if (mockScrollTo) {
            carousel.current.scrollTo = () => {
                carousel.current.scrollLeft = carousel.current.scrollLeft - containerWidth;
            };
        }

        carousel.current.scrollTo({
                top: 0,
                left: carousel.current.scrollLeft - containerWidth,
                behavior: 'smooth'
            });

    }, [templates]);

    const goToNext = useCallback(() => {

        let containerWidth = 900;

        if (slideRef.current
            && slideRef.current.childNodes.length > 0) {
            containerWidth = slideRef.current.childNodes[1].clientWidth || 900;
        }

        if (mockScrollTo === true) {
            carousel.current.scrollTo = () => {
                carousel.current.scrollLeft =
                    Math.max(carousel.current.scrollLeft + containerWidth, containerWidth);
            };
        }

        carousel.current.scrollTo({
            top: 0,
            left: Math.max(carousel.current.scrollLeft + containerWidth, containerWidth),
            behavior: 'smooth'
        });

    }, [templates]);

    const handleScroll = (event) => {
        setIsBackDisabled(event.currentTarget.scrollLeft <= 0);
        setIsNextDisabled(Math.ceil(event.currentTarget.scrollLeft) + event.currentTarget.offsetWidth >= innerContainer.current.offsetWidth);
    };

    const getSlides = () => {

        const cards = templates
            .map((t) => {
                return <ProjectsTemplate key={`project-template-${t.id}`} template={t} accessScope={accessScope} orgId={orgId}/>;
        });

        return <div key={`slides`} ref={innerContainer}>
            <h3 style={contentStyle}>
                {cards}
            </h3>
        </div>;
    }

    const backColor = isBackDisabled ? '#BFBFBF': '#262626';
    const nextColor = isNextDisabled ? '#BFBFBF': '#262626';

    return <div className="projects-templates">
        <div className="section-header">
            <div>Explore projects templates</div>
            <div><a data-testid='see-all' onClick={() => navigate(`templates`)}>See all</a></div>
        </div>
        <div className="templates-list" ref={slideRef}>
            <div className="left-arrow">
                <LeftOutlined onClick={goToPrev} data-testid="navigate-back" style={{
                    fontSize: 24,
                    color: backColor
                }} />
            </div>
            <div className="carousel-wrapper" ref={carouselWrapper}>
                <div className="carousel" ref={carousel} onScroll={handleScroll}>
                    {getSlides()}
                </div>
            </div>
            <div className="right-arrow"><RightOutlined data-testid="navigate-next" onClick={goToNext} style={{
                fontSize: 24,
                color: nextColor
            }} /></div>
        </div>
    </div>
}

export default ProjectsTemplates;