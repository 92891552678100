import {
    Button,
} from 'antd';
import {
    DeleteOutlined,
    PlusOutlined,
    SettingOutlined,
    ExportOutlined,
} from '@ant-design/icons';
import {useRecoilState} from "recoil";
import selectedScansState from "../../atoms/selectedScans";

const ScanListCommands = ({ disabled, onDelete, onAddScan, allowExport }) => {

    const [selectedScans, ] = useRecoilState(selectedScansState);

    if (disabled) {
        return [];
    }

    const commandButtons = [];

    commandButtons.push(<Button key="command-button-1" danger icon={<DeleteOutlined style={{ fontSize: '12px'}} />} data-testid="delete-scan" disabled={selectedScans.length === 0} onClick={() => onDelete(selectedScans)} />);
    if (allowExport) {
        commandButtons.push(<Button key="command-button-2" icon={<ExportOutlined style={{ fontSize: '12px'}} />} disabled={true} />);
    }
    commandButtons.push(<Button key="command-button-3" type="primary" className='add' icon={<PlusOutlined style={{ fontSize: '11px'}} />} data-testid="add-scan" onClick={onAddScan}>Add</Button>);
    commandButtons.push(<Button key="command-button-4" icon={<SettingOutlined style={{ fontSize: '12px'}} />} disabled={true} />);

    return commandButtons;

}

export default ScanListCommands;