import {useState} from "react";
import TemplateTaskController, {GENERAL_ERROR} from "../controllers/templateTaskController";

const useTemplateTaskOperations = (api) => {

    const [error, setError] = useState(null);

    const templateTaskController = new TemplateTaskController({apiClient: api});

    const getTemplateTasks = async (page, search, templateId, taskFilter, expand, templateTaskPhaseId) => {
        const result = await templateTaskController.listTemplateTask(page, search, templateId, taskFilter, expand, templateTaskPhaseId);
        if (result === GENERAL_ERROR) {
            setError('Unable to load template tasks');
        }
        return result;
    }

    const getUpdatedTask = async (id) => {
        const result = await templateTaskController.loadUpdatedTask(id);
        if (result === GENERAL_ERROR) {
            setError('Unable to load template task');
        }
        return result;
    }

    const updateTemplateTask = async (id, params) => {
        await templateTaskController.patchTemplateTask(id, params);
    }

    return [getTemplateTasks, getUpdatedTask, updateTemplateTask, error];
};

export default useTemplateTaskOperations;