export const initialState = {
    groupsList: [],
    searchValue: ''
}

export const ACTIONS = {
    GROUPS_LIST: 'groupsList',
    SEARCH_VALUE: 'searchValue',
    RESET_STATE: 'resetState'
}

export function addClientsToGroupsReducer(state, action) {
    switch (action.type) {
        case ACTIONS.GROUPS_LIST:
            return {
                ...state,
                groupsList: action.payload,
            };
        case ACTIONS.SEARCH_VALUE:
            return {
                ...state,
                searchValue: action.payload,
            };
        case ACTIONS.RESET_STATE:
            return initialState;
            default:
                throw Error('Unknown action.');
    }
}