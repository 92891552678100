import './projectsTemplate.css';
import ApiClient from "../../apiClient";
import { PopupButton } from '@typeform/embed-react'
import {useEffect, useRef, useState} from "react";
import { message, Button} from 'antd';
import {generateProjectPrefix} from "../utils";
import {DeleteOutlined, EditOutlined,  SendOutlined} from "@ant-design/icons";
import {showDeactivateConfirm} from "../utils";
import {useNavigate} from "react-router-dom";
import {isNumber} from "util";

const ProjectsTemplate = ({api, template, allowModification, onEdit, onDelete, orgId, accessScope, skipPopup}) => {
    const apiClient = api || new ApiClient();
    const [orgImage, setOrgImage] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const inputRef = useRef(null);
    const navigate = useNavigate();

    useEffect( async () => {
        await loadAvatar();
    },[]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.open();
        }
    }, [inputRef, showPopup]);

    const closePopup = () => {
        setShowPopup(false);
    }

    const getPrefix = async () => {
        let newPrefix = null;
        const prefix = generateProjectPrefix(template.title);
        let loading = true;
        let currentPage = 1;
        const data = [];
        while (loading) {
            const projects = await apiClient.get().projectApi.projectList(
                `${prefix}`,
                [''],
                [`organizationId:${orgId}`],
                [],
                currentPage,
                50
            );
            for (const project of projects.data.data) {
                data.push({
                    prefix: project.prefix
                });
            }
            currentPage = projects.data.page.next;
            loading = projects.data.page.next !== null;
        }
        const prefixes = data.filter(e => e.prefix.startsWith(prefix));
        let lastPrefixIndex = Math.max(...prefixes.map(e => e.prefix.slice(2)), -1);
        if (lastPrefixIndex !== -1) {
            lastPrefixIndex++;
            newPrefix = prefix + lastPrefixIndex;
        }
        return newPrefix ?? prefix;
    }

    const onFormSubmitted = async (response) => {
        if (response && response.responseId) {
            try {
                const prefix = await getPrefix();
                let dateTo = null;
                const orgIdToUse = orgId || accessScope.organizationId;
                if (template.estimate) {
                    const currentDate = new Date();
                    dateTo = new Date((currentDate.setDate(currentDate.getDate() + template.estimate))).toISOString();
                }
                const projectParams = {
                    organizationId: orgIdToUse,
                    userId: accessScope.userId,
                    active: true,
                    name: template.title,
                    dateDueTo: dateTo,
                    prefix: prefix,
                    description: template.description,
                    status: 'REQUESTED',
                    templateId: template.id,
                    type: template.projectType,
                    responsibleId: template.userId ?? null,
                    formId: response.formId,
                };
                if(response.responseId !== true) {
                    projectParams.responseId = response.responseId;
                }
                await apiClient.get().projectApi.projectCreate(
                    projectParams
                );
                navigate(`/clients/${orgId}/projects?t=${Date.now()}`);
                message.success('Project has been successfully created');
            }
            catch (err) {
                if (err.response?.data?.details?.["projectParams.prefix"]) {
                    message.error('Project prefixalready exists');
                } else {
                    message.error('An error occurred while trying to save project');
                }
            }
        }
    };

    const getPopup = (item) =>
        <PopupButton ref={inputRef} id={item}
                     style={{display: 'none'}}
                     onClose={closePopup}
                     autoClose="100"
                     onSubmit={onFormSubmitted}
        />;

    const loadAvatar = async () => {
        if (template.fileName) {
            try {
                const image = await apiClient.get().filesApi.imageGetByFileName(template.fileName, 'templates');
                const uint8Array = new Uint8Array(image.data.resultBuffer.data);
                const blob = new Blob([uint8Array], { type: image.data.type });
                const blobURL = URL.createObjectURL(blob);
                setOrgImage(blobURL);
            } catch (err) {
            }
        }
    }

    const doDeleteConfirmation = () => {
        showDeactivateConfirm('template', () => onDelete(template));
    }

    const processEdit = () => {
        onEdit(template);
    }

    return <div className="project-template-slide-item">
        {template.projectType === "VX_SERVICE" && <div className="template-label">vX Service</div>}
        <div className="template-image"><img src={orgImage} alt=""/></div>
        <div className="template-title" title={template.title}>{template.title}</div>
        <div className="template-description" title={template.description}>{template.description}</div>
        {!allowModification && <Button type="primary" data-testid='show-popup' className="template-request" onClick={ () => {
            skipPopup || !template.formId
                ? onFormSubmitted({responseId: true})
                : setShowPopup(template.formId)}
        }><SendOutlined style={{
            width: 16,
            height: 16
        }}/>Request</Button>}
        {showPopup && getPopup(showPopup)}
        {allowModification && <div className="template-modification">
            <Button style={{
                width: 161,
                height: 32,
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: 400,
            }} onClick={processEdit}><EditOutlined style={{
                width: 16,
                height: 16
            }}/>Edit</Button>
            <Button danger={true} style={{
                width: 32,
                height: 32,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }} onClick={doDeleteConfirmation}><DeleteOutlined/></Button>
        </div>}
    </div>
}

export default ProjectsTemplate;
