import ApiClient from "../apiClient";

export default class TemplateController {
    apiClient = null;

    constructor(props) {
        this.apiClient = props?.apiClient || new ApiClient();
    }

    patchTemplate = async (filter, params) => {
        return await this.apiClient.get().templateApi.templatePatch(filter, params);
    }

    loadForms = async () => {
        return await this.apiClient.get().templateApi.formsList();
    }

}