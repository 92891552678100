import './documentsOperations.css';
import Search from 'antd/es/input/Search';
import {DeleteOutlined, FileTextOutlined, FolderOutlined, PlusOutlined, SendOutlined} from '@ant-design/icons';
import {Button, Dropdown} from 'antd';
import {useState} from 'react';
import LinkModalWindow from '../modalWindows/linkModal/linkModalWindow';
import FolderModalWindow from '../modalWindows/folderMoldal/folderModalWindow';

const DocumentsOperations = ({onDocumentSearch, isSearching, onDocumentCreate, currentFolder, isEmptyPage}) => {
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);

  function openLinkModal() {
    setIsLinkModalOpen(true);
  }

  function closeLinkModal() {
    setIsLinkModalOpen(false);
  }

  function openFolderModal() {
    setIsFolderModalOpen(true);
  }

  function closeFolderModal() {
    setIsFolderModalOpen(false);
  }

  const addFileItem = {
    key: 'document-page-add-document-menu-item-link',
    label: (
      <div className="document-page-add-document-menu-item-container" onClick={openLinkModal}>
        <FileTextOutlined style={{fontSize: '16px', marginRight: '8px'}}/>
        <span data-testid="add-document-button">Add document</span>
      </div>
    ),
  };

  const addFolderItem = {
    key: 'document-page-add-document-menu-item-folder',
    label: (
      <div className="document-page-add-document-menu-item-container" onClick={openFolderModal}>
        <FolderOutlined style={{fontSize: '16px', marginRight: '8px'}}/>
        <span>Add folder</span>
      </div>
    ),
  };

  const getAddItemButton = () => {
    return currentFolder > -1 ?
        <Button data-testid="add-document" type="primary" onClick={openLinkModal}
                icon={<PlusOutlined style={{fontSize: '11px', width: '16px'}}/>}>Add</Button>
        :
        <Dropdown
            menu={{
              items
            }}
            placement="bottomLeft"
        >
          <Button data-testid="add-document" type="primary"
                  icon={<PlusOutlined style={{fontSize: '11px', width: '16px'}}/>}>Add</Button>
        </Dropdown>
  }

  const items = currentFolder > -1 ? [addFileItem] : [addFileItem, addFolderItem];

  return (
    <div className={isEmptyPage ? "flex-right" : "documents-page-operations"} data-testid="documents-page-operations">
      {!isEmptyPage && <Search
        placeholder="Documents search"
        style={{width: 205}}
        onSearch={onDocumentSearch}
        loading={isSearching}
        data-testid="documents-page-search-input"
      />}
      <div className="documents-page-bulk-operations">
        {!isEmptyPage && <>
          <Button className="document-page-bulk-send-button" icon={<SendOutlined/>} disabled/>
          <Button className="document-page-bulk-delete-button" icon={<DeleteOutlined/>} disabled/>
        </>}
        {getAddItemButton()}
        <LinkModalWindow isModalOpen={isLinkModalOpen} handleCancel={closeLinkModal}
                         onDocumentSave={onDocumentCreate}/>
        <FolderModalWindow isModalOpen={isFolderModalOpen} handleCancel={closeFolderModal}
                           onDocumentCreate={onDocumentCreate}/>
      </div>
    </div>
  );
};

export default DocumentsOperations;
