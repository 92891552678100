import {Modal} from 'antd';
import {useEffect, useState} from 'react';
import ModalInputItem from '../modalInputItem';
import ModalTextAreaItem from '../modalTextAreaItem';
import '../linkModalWindow.css';

const LinkModalWindow = ({isModalOpen, handleCancel, onDocumentSave, document}) => {
  const [documentName, setDocumentName] = useState('');
  const [documentDescription, setDocumentDescription] = useState('');
  const [documentUrl, setDocumentUrl] = useState('');

  useEffect(() => {
    if (document) {
      setDocumentName(document.name);
      setDocumentDescription(document.description);
      setDocumentUrl(document.url);
    }
  }, [document]);

  function changeDocumentName(event) {
    setDocumentName(event.target.value);
  }

  function changeDocumentDescription(event) {
    setDocumentDescription(event.target.value);
  }

  function changeDocumentUrl(event) {
    setDocumentUrl(event.target.value);
  }

  function clearInputs() {
    setDocumentName('');
    setDocumentDescription('');
    setDocumentUrl('');
  }

  function onCloseModal() {
    clearInputs();
    handleCancel();
  }

  async function onOk() {
    await onDocumentSave(documentName, documentDescription, "link", documentUrl, document?.id);
    onCloseModal();
  }

  const title = document ? 'Edit document' : 'Add a new document';
  const okText = document ? 'Save' : 'Add';

  return (
    <Modal data-testid="documents-page-link-modal-window" className="documents-page-link-modal-window"
           title={title} open={isModalOpen} onOk={onOk} onCancel={onCloseModal} centered
            width={390} okText={okText} okButtonProps={{ disabled: !documentName || !documentUrl ,className: 'width-90', 'data-testid': 'btn-button' }} cancelButtonProps={{ className: 'width-90' }}>
      <ModalInputItem title="Document name" value={documentName} onChange={changeDocumentName}/>
      <ModalInputItem title="Link to a document" value={documentUrl} onChange={changeDocumentUrl}/>
      <ModalTextAreaItem title="Description" value={documentDescription} onChange={changeDocumentDescription} height={88}/>
    </Modal>
  );
};

export default LinkModalWindow;
