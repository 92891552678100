import './gcpSummary.css';
import {
    Form,
    Input,
} from 'antd';
import loadedAssetDeviceState from '../../atoms/loadedAssetDevice';
import {useRecoilValue} from 'recoil';
import * as utils from "../utils";
import moment from "moment/moment";
import {useEffect, useState} from "react";
import {formatDate} from "../utils";

const GcpSummary = ({device, onFormChanged}) => {

    const loadedAssetDevice = useRecoilValue(loadedAssetDeviceState);
    const deviceToUse = device || loadedAssetDevice;
    const [name, setName] = useState('');

    useEffect( () => {
        if (deviceToUse) {
            setName(deviceToUse.deviceInfo.name);
        }
    },[]);

    const getDeviceInfo = (info) => {
        const deviceName = utils.devicePlatformName(info.deviceType);
        const parsedData = info.deviceConfig && JSON.parse(info.deviceConfig);
        return {
            platform: deviceName,
            scanned: info.timestamp ? formatDate(info.timestamp) : 'unknown',
            accessKey: parsedData?.access_key,
            regions: parsedData?.regions,
            version: info.deviceInfo.version
        }
    }

    const gcpInfo = getDeviceInfo(deviceToUse);

    return <div className="gcp-summary" data-testid="device-gcp-edit">
        <h1>Cloud account summary</h1>
        <Form
            layout="vertical"
            className="device-view-edit-form"
        >
            <Form.Item label="Name">
                <Input placeholder="Name" value={name} data-testid="text-gcp-name" onChange={(e) => {
                    setName(e.target.value);
                    onFormChanged({name: e.target.value});
                }}/>
            </Form.Item>
        </Form>
        <div className="scan-details">
            <h2>Scan Details</h2>
            <table>
                <thead></thead>
                <tbody>
                {gcpInfo.platform &&
                    <tr>
                        <th>Platform:</th>
                        <td>{gcpInfo.platform}</td>
                    </tr>
                }
                {gcpInfo.scanned &&
                    <tr>
                        <th>Scanned:</th>
                        <td>{gcpInfo.scanned}</td>
                    </tr>
                }
                {gcpInfo.regions &&
                    <tr>
                        <th>Region:</th>
                        <td>{gcpInfo.regions}</td>
                    </tr>
                }
                {gcpInfo.version &&
                    <tr>
                        <th>Project name:</th>
                        <td>{gcpInfo.version}</td>
                    </tr>
                }
                </tbody>
            </table>
        </div>
    </div>
}

export default GcpSummary;