import './scan.css';
import { MailOutlined, TableOutlined, FileAddOutlined } from '@ant-design/icons';
import ChildSidebarPage from "../childSidebarPage/childSidebarPage";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Spin, message, Button} from 'antd';
import {useEffect, useState} from "react";
import ApiClient from "../../apiClient";
import ScanSummaryPage from "../scanSummary/scanSummaryPage";
import SummaryHeader from "../summaryHeader/summaryHeader";
import {useRecoilState, useResetRecoilState, useSetRecoilState} from 'recoil';

import scanSummaryState from "../../atoms/scanSummary";
import deviceIcon from "../../assets/images/device.svg";
import cloudIcon from "../../assets/images/clouds.svg";
import queryEditorSelectedQueryState from "../../atoms/queryEditorSelectedQuery";
import openedDebugQueriesState from "../../atoms/openedDebugQueries";
import QueriesMenu from "../queriesMenu/queriesMenu";
import selectedTabContentState from "../../atoms/selectedTabContent";
import ReportPreview from "../reportPreview/reportPreview";
import queryUpdatedState from "../../atoms/queryUpdated";
import isQueryCreatedState from "../../atoms/isQueryCreated";

import {RolesMapper, scanSubTypes} from "../../constants";
import {showDeactivateConfirm} from "../utils";

const Scan = ({isVioletXScan, accessScope, api, organizationId, getParamsFunc}) => {

    const apiClient = api ? api : new ApiClient();
    const navigate = useNavigate();
    let urlParams = useParams();
    if (getParamsFunc) {
        urlParams = getParamsFunc();
    }
    let {scanId, summary, scan, orgId} = urlParams;
    orgId = orgId ?? organizationId;
    const {pathname} = useLocation();
    const topic = pathname?.includes('scanner-devices') ? 'devices' : 'clouds';

    const typesMap = {
        'system-scans': scanSubTypes.SYSTEM,
        'violetx-scans': scanSubTypes.VIOLET_X,
        'custom-scans': scanSubTypes.CUSTOM,
    }

    const tabs = [
        { "data-testid": "tab-scan-summary", key: "summary", title: 'Summary', label: 'Summary', icon: <MailOutlined />, disabled: false, onClick: () => { onTabSelect(["summary"])} },
        { "data-testid": "tab-scan-report-preview", key: "preview", title: 'Dashboard data preview', label: 'Dashboard data preview', icon: <TableOutlined />, disabled: !scanId, onClick: () => { onTabSelect(["preview"])} },
    ];

    const [isLoading, setIsLoading] = useState(true);
    const [scanInfo, setScanInfo] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [queries, setQueries] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const setIsQueryCreated = useSetRecoilState(isQueryCreatedState);
    const [scanSummary] = useRecoilState(scanSummaryState);
    const [selectedTabContent, setSelectedTabContent] = useRecoilState(selectedTabContentState);
    const resetOpenedDebugQueries = useResetRecoilState(openedDebugQueriesState);

    const [queryEditorSelectedQuery, setQueryEditorSelectedQuery] = useRecoilState(queryEditorSelectedQueryState);
    const [openedDebugQueries, setOpenedDebugQueries] = useRecoilState(openedDebugQueriesState);
    const [queryUpdated, ] = useRecoilState(queryUpdatedState);

    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState(['summary']);

    const getImage = () => {
        const src = topic.toLowerCase() === "devices" ? deviceIcon : cloudIcon
        return <div className="query-icon position-right">
            <img src={src} className="title-img" alt=""/>
        </div>;
    }

    const onTabSelect = (tab) => {
        const newPathName = pathname.split('/').slice(0, -1).join('/');
        navigate(`${newPathName}/${tab}`);
    }

    useEffect(() => {
        return () => {
            resetOpenedDebugQueries();
            setSelectedTabContent(null);
            setQueryEditorSelectedQuery(null);
        }
    },[]);

    useEffect(() => {
        if (scan === 'system-scans' && accessScope.roleId !== RolesMapper.UBER_ADMIN) {
            return navigate(`/clients/${orgId}/integrations/scanner-devices/summary`);
        }
        if (pathname.includes('summary') || pathname.includes('preview')) {
            setQueryEditorSelectedQuery(null);
            setDefaultSelectedKeys(summary);
        }
    },[pathname]);

    useEffect(() => {
        if (openedDebugQueries.queries[0] === -1) {
            setDefaultSelectedKeys([tabs[0].key]);
            setOpenedDebugQueries({
                activeKey: null,
                queries: []
            });
            const newPathName = pathname.split('/').slice(0, -1).join('/');
            navigate(`${newPathName}/summary`);
        }
    }, [openedDebugQueries]);


    useEffect(()=> {
        let disableSave = true;
        if (scanSummary) {
            setQueryEditorSelectedQuery(null);
            if (!scanInfo) {
                disableSave = !(scanSummary.name?.trim() && scanSummary.type && scanSummary.interval)
            } else if (scanSummary.name.trim().length === 0) {
                disableSave = true;
            }
            else {
                Object.entries(scanSummary).forEach((item) => {
                    if (item[1] !== scanInfo[item[0]]) {
                        disableSave = false;
                    }
                })
            }
        }
        setDisabled(disableSave);
    },[scanSummary])

    const navigateToScanList = () => {
        navigate(pathname.split('/').slice(0, summary ? -2 : -1).join('/'));
    }

    async function onSave() {
        const type = isVioletXScan ? scanSubTypes.VIOLET_X : scanSummary?.type;
        const scanParams = {
            organizationId: orgId,
            name: scanSummary?.name,
            type: type,
            description: scanSummary?.description ?? '',
            topic: topic.toUpperCase(),
            target: 'COMPLIANCE',
            active: true,
            interval: scanSummary?.interval
        };
        try {
            if (scanInfo) {
                    await apiClient.get().scansApi.scanUpdate(scanId, scanParams);
                    await loadScanInfo();
                    await message.success('Scan has been successfully updated');
            } else {
                const response = await apiClient.get().scansApi.scanCreate(scanParams);
                const link = pathname.replace("/add", "");
                 navigate(`${link}/${response.data.scanId}/summary`);
                await message.success('Scan has been successfully created');
            }
        } catch (err) {
                message.error('Unable to save scan');
        }
    }

    const onDelete = () => {
        showDeactivateConfirm('scan', () => onDeactivate());
    }

    async function onDeactivate () {
        try {
            await apiClient.get().scansApi.scanPatch(`id:${scanId}`, {active: false});
            message.success(`Scan has been successfully deleted`);
            navigateToScanList();
        }
        catch (err) {
            message.error(`An error occurred while try to delete scan`);
        }
    }
    
    const addQuery = async () => {
        setIsQueryCreated(true);
    }

    const createQuery = async () => {
        let createdQuery = null;
        try {
            const dateNow = Date.now();
            const queryName = `New Query ${dateNow}`;
            const result = await apiClient.get().queriesApi.queryCreate(
                {
                    organizationId: orgId,
                    query: '',
                    active: true,
                    name: queryName,
                    scanId: scanId,
                    deviceTypes: '',
                    interval: 30,
                    resultEvaluation: null,
                    resultType: 'raw'
                }
            );

            const createdQueryId = result.data.id;
            const createdDistQuery = await apiClient
                .get()
                .distributedQueriesApi
                .distributedQueryCreate({
                    organizationId: orgId,
                    query: '',
                    name: queryName,
                    queryId: createdQueryId
                });
            createdQuery = await loadQueries(createdQueryId);
            createdQuery.distributedQueryId = createdDistQuery.data.id;
            message.success('Query has been successfully created');

        } catch (err) {
            console.log(err);
            await message.error('Unable to create query');
        }
        return createdQuery;
    }

    const loadScanInfo = async () => {
        setIsLoading(true);
        const filter = ['active:true', `id:${scanId}`, `type:${typesMap[scan]}`, `organizationId:${orgId}`];
        if (typesMap[scan]==="SYSTEM" || typesMap[scan]==="VIOLET_X") {
            filter.pop();
        }
        const scans = await apiClient.get().scansApi.scanList(
            '',
            [],
            filter,
            ['deviceCount'],
            1,
            1
        );
        const scanData = scans.data.data[0];
        setScanInfo(scanData);
        setIsLoading(false);
        setOpenedDebugQueries({
            activeKey: null,
            queries: []
        });
    }

    const loadQueries = async (queryToReturn, loadQueries) => {
        if (loadQueries) {
            if (queries.length >= +total) {
                return;
            }
            setCurrentPage(currentPage + 1);
        } else {
            setCurrentPage(1);
            setIsLoading(true);
        }
        const loadedQueries = await apiClient.get().queriesApi.queryList([],
            ['-id'],
            [`scanId:${scanId}`, 'active:true'],
            ['distributedQueryId'],
            loadQueries ? currentPage + 1 : 1,
            50
        );
        const newQueries = loadedQueries.data.data;
        setTotal(loadedQueries.data.page.total);
        if (loadQueries) {
            setQueries([...queries, ...newQueries]);
        } else {
            setQueries([...newQueries]);
            setIsLoading(false);
        }
        if (queryToReturn) {
            return newQueries.find(q => q.id === queryToReturn);
        }
    }

    useEffect(async () => {
        if(scanId) {
            await loadScanInfo();
            await loadQueries();
        } else {
            setIsLoading(false);
        }
    }, [scanId]);

    useEffect(() => {
        if (!queryUpdated) {
            return;
        }
        const existingQueryIndex = queries.findIndex(q => q.id === queryUpdated.id);
        const queriesCopy = JSON.parse(JSON.stringify(queries));
        queriesCopy[existingQueryIndex] = queryUpdated;
        setQueries(queriesCopy);
    }, [queryUpdated]);

    if (isLoading) {
        return <div className="scan-spin" data-testid="device-summary-spin">
            <Spin size="large" />
        </div>
    }

    tabs[0].page = <ScanSummaryPage type={typesMap[scan]} scan={scanInfo} accessScope={accessScope} isVioletXOnly={isVioletXScan}/>;

    tabs[1].page = <ReportPreview isVioletXOnly={isVioletXScan} orgId={orgId} scanId={scanId} scan={scanInfo} api={apiClient}></ReportPreview>

    const allowAddNewQueries = (scanSummary && scanSummary.type === scanSubTypes.SYSTEM) || (scanSummary && scanSummary.type === scanSubTypes.VIOLET_X && !isVioletXScan);

    const scanHeaderCommands = allowAddNewQueries ? [] : [
        <Button key={'scanHeaderButton'} disabled={!scanId} icon={<FileAddOutlined/>} onClick={addQuery} data-testid="add-new-query">Add new query</Button>
    ];

    const scanType = scanSummary ? scanSummary.type : typesMap[scan];
    const selectedSidebarKey = queryEditorSelectedQuery === null ? defaultSelectedKeys : ['tab-scan-queries', queryEditorSelectedQuery];
    let selected = tabs.find(t => t.key === summary);
    selected = selected ? selected : selectedTabContent;

    return selected || !summary ? <ChildSidebarPage
        defaultSelectedPage={selected}
        menuContainerCssClass={'scan-menu-control'}
        sidebarControls={[<QueriesMenu disabled={!scanId} isVioletXOnly={isVioletXScan} scanType={scanType} key={"queries-menu-key"} total={total} loadQueries={loadQueries} onCreate={createQuery} queries={queries} apiClient={apiClient} orgId={orgId} topic={topic} />]}
        defaultSelectedKeys={selectedSidebarKey}
        header={<SummaryHeader isVioletXOnly={isVioletXScan} type={scanType} commandButtons={scanHeaderCommands} onBack={navigateToScanList} disabled={disabled} disableDelete={!scanInfo} name={scanInfo ? scanInfo.name : "Scan Name"} getImage={getImage} onSave={onSave} onDelete={onDelete}/>}
        sidebarTabs={tabs}
    /> : <QueriesMenu queries={queries} apiClient={apiClient} loading={true} isVioletXOnly={isVioletXScan} orgId={orgId} topic={topic} />
}

export default Scan;
