import {useState} from 'react';
import ClientsController, {GENERAL_ERROR} from "../controllers/clientsController";

const useClientsOperations = (api) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const clientsController = new ClientsController({apiClient: api});

    const loadStatistic = async (groupId) => {
        setLoading(true);
        const result = await clientsController.loadStatistic(groupId);
        if (result === GENERAL_ERROR) {
            setError('Failed to load clients statistic');
        }
        setLoading(false);
        return result;
    }

    const listClients = async (page, searchValue, exceptGroupId, limit, listFilter) => {
        return await clientsController.listClients(page, searchValue, exceptGroupId, limit, listFilter);
    }

    return [loadStatistic, loading, error, listClients];
};

export default useClientsOperations;
