import './sidebarLayout.css';
import { Menu } from 'antd';
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const Sidebar = ({tabs, selectedTab, sideBarStyle, onMenuItemClick, defaultOpenKeys,
                     defaultSelectedKeys, sidebarControls, menuContainerCssClass, path, collapsed }) => {

    const [tabsToRender, setTabsToRender] = useState([]);
    const [isCollapsed, setIsCollapsed] = useState(localStorage.getItem('collapse') === 'true');
    const navigate = useNavigate();

    const assignOnClickHandler = (items, handler) => {
        const tabsToDisplay = [];
        items.forEach((item) => {
            if (!item.children) {
                const onClick = item.onClick;
                item.onClick = () => handler(item, onClick);
            }
            tabsToDisplay.push(item);
            if (item.children && item.children.length > 0) {
                assignOnClickHandler(item.children, handler);
            }
        });
        return tabsToDisplay;
    }

    const onClick = (item, tabClick) => {
        if (tabClick) {
            tabClick();
        }
        onMenuItemClick(item || selectedTab);
        if (path) {
            let pathName = path.split('/');
            pathName[pathName.length - 1] = item.key;
            pathName = pathName.join('/');
            navigate(`../${pathName}`);
        }
    }
    const handleCollapse = () => {
        setIsCollapsed(!isCollapsed);
        localStorage.setItem('collapse', !isCollapsed);
    }

    useEffect(() => {
        const tabsToDisplay = assignOnClickHandler(tabs, onClick);
        setTabsToRender(tabsToDisplay);
    }, [tabs]);

    return (
        <div data-testid="sidebar-menu" className={`full-height ${menuContainerCssClass}`}>
            {collapsed && <div data-testid="handle-collapse" className={`${isCollapsed ? 'image-non-collapsed' : 'image-collapsed'}`} onClick={handleCollapse}/>}
            <Menu
                style={isCollapsed && collapsed ? {width: '64px', height: '100%', backgroundColor: '#F5F5F5'} : sideBarStyle}
                className={collapsed && "menu-sidebar"}
                mode="inline"
                defaultSelectedKeys={defaultSelectedKeys}
                defaultOpenKeys={!isCollapsed ? defaultOpenKeys : null}
                items={tabsToRender}
                selectedKeys={defaultSelectedKeys}
                inlineCollapsed={isCollapsed && collapsed}
            >
            </Menu>
            {sidebarControls}
        </div>
    );
}

const SidebarLayout = ({ sidebarTabs, sideBarStyle, defaultSelectedPage, defaultSelectedKeys,
                           defaultOpenKeys, sidebarControls, menuContainerCssClass, path, contentStyle, collapsed, containerCssClass}) => {

    const [selectedTab, setSelectedTab] = useState(sidebarTabs[0]);
    const onSidebarClick = (tab) => {
        setSelectedTab(tab);
    }
    useEffect(() => {
        if (defaultSelectedPage) {
            setSelectedTab(defaultSelectedPage);
        }
    },[defaultSelectedPage]);

    return <div className={`sidebar-layout ${containerCssClass}`} data-testid="sidebar-layout">
        <div data-testid="sidebar">
            <Sidebar
                selectedTab={selectedTab}
                onMenuItemClick={onSidebarClick}
                path={path}
                tabs={sidebarTabs}
                sideBarStyle={sideBarStyle}
                defaultOpenKeys={defaultOpenKeys}
                defaultSelectedKeys={defaultSelectedKeys}
                sidebarControls={sidebarControls}
                menuContainerCssClass={menuContainerCssClass}
                collapsed={collapsed}
            /></div>
        <div style={contentStyle} data-testid="content">{selectedTab.page}</div>
    </div>
}

export default SidebarLayout;