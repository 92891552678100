import './devices.css';
import React, { useEffect, useState} from "react";
import ApiClient from "../../apiClient";
import {formatDate, showDeactivateConfirm} from "../utils";
import Dots from "../../assets/images/dots-devices.svg";
import {Dropdown, Space, Table, message, Statistic, Button, Input, Tooltip} from 'antd';
import noClients from "../../assets/images/no-search-result.svg";
import { DeleteOutlined, PlusOutlined, SettingOutlined, ExportOutlined, SendOutlined, EditOutlined } from '@ant-design/icons';
import {useNavigate, useParams} from "react-router-dom";
import {UserAvatar} from "../userAvatar/userAvatar";

let page = 1;
const limit = 20;
let searchValue = '';

const Devices = ({api}) => {

    const apiClient = api || new ApiClient();

    const [devices, setDevices] = useState([]);
    const [devicesStatus, setDevicesStatus] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [isDevicesLoaded, setIsDevicesLoaded] = useState(true);
    const [isEmptyPage, setIsEmptyPage] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const navigate = useNavigate();
    const {orgId} = useParams();


    const { Search } = Input;

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            render: (text, record, index) => {
                return <a data-testid={`name-${index}`} onClick={() => redirectToSummary(record)}>{text}</a>
            }
        },
        {
            title: 'Owner',
            dataIndex: 'owner',
            key: 'owner',
            align: 'left',
            render: (text, record) => {
                return <Tooltip placement="top" title={text}>
                    <span>
                        <UserAvatar name={text} fileName={record.fileName}/>
                    </span>
                </Tooltip>
            }
        },
        {
            title: 'OS',
            dataIndex: 'os',
            key: 'os',
            align: 'left'
        },
        {
            title: 'Scanned',
            dataIndex: 'scanned',
            key: 'scanned',
            align: 'right'
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (text, record, index) => {
                const items = [
                    { key: `open-edit-${index}`, title: 'Open and edit', label: <div data-testid={`edit-${index}`}  onClick={() => redirectToSummary(record)}>Open and edit</div>, icon: <EditOutlined />},
                    { key: `deactivate-${index}`, title: 'Delete', label: <div data-testid={`deactivate-${index}`}  onClick={() => showDeleteDeviceConfirm(record)}>Delete</div>, icon: <DeleteOutlined />, danger: true},
                ];
                return <Dropdown trigger='click' menu={{items}}>
                    <a data-testid={`dots-menu-${index}`} className="action-menu-container">
                        <Space>
                            <img src={Dots} alt=""/>
                        </Space>
                    </a>
                </Dropdown>
            },
            className: 'table-action',
            width: 50
        }
    ];

    const loadDevices = async (search) => {
        const searchData = search || searchValue;
        setIsDevicesLoaded(false);
        const searchItems = searchData.split(' ');
        const loadedDevices = await apiClient.get().devicesApi.deviceList(
            searchItems,
            [],
            ['active:true', 'scanTopic:DEVICES', `organizationId:${orgId || -1}`],
            ['userInfo'],
            page,
            limit
        );

        const convertedDevices = loadedDevices.data.data.map((item, index) => {
            return {
                id: item.id,
                key: `device-${item.id}-${index}`,
                owner: `${item.userFirstName} ${item.userLastName}`,
                fileName: item.userFileName,
                os: item.deviceInfo?.name ?? 'Unknown name',
                name: item.deviceInfo?.computer_name ?? 'Unknown name',
                scanned: !item.timestamp ? 'Unknown' : formatDate(item.timestamp)
            }
        });
        setDevices(convertedDevices);
        setTotalPages(loadedDevices.data.page.total);
        if (convertedDevices.length === 0) {
            setIsEmptyPage(true);
        } else {
            setIsEmptyPage(false);
        }
        setIsDevicesLoaded(true);
    }

    const handlePagination = async (currentPage) => {
        page = currentPage;
        setSelectedRow([]);
        setSelectedRowKeys([]);
        await loadDevices();
    }

    const redirectToSummary = (item) => {
        navigate(`/clients/${orgId}/assets/devices/${item.id}/device-summary`);
    }

    const onDeviceDelete = async (item) => {
        const filter = [];
        if (item) {
            filter.push(`id:${item.id}`);
        } else {
            selectedRow.forEach(el => filter.push(`id:${el}`));
        }
        const plural = filter.length <= 1 ? '' : 's';
        try {
            await apiClient.get().devicesApi.devicePatch(filter, {active: false});
            message.success(`Device${plural} has been successfully deleted`);
            if ((totalPages - filter.length) === ((page - 1) * limit) && page !== 1) {
                page -= 1;
                await loadDevices();
            } else {
                await loadDevices();
            }
            await loadStatus();
            setSelectedRowKeys([]);
            setSelectedRow([]);
        } catch (err) {
            message.error(`An error occurred while try to delete device${plural}`);
        }

    }

    const showDeleteDeviceConfirm = (item) => {
        const deviceTitle = selectedRow.length <= 1 ? 'device' : 'devices'
        showDeactivateConfirm(deviceTitle, () => onDeviceDelete(selectedRow.length > 0 ? null : item));
    }

    const onSearch = async (value) => {
        searchValue = value;
        page = 1;
        await loadDevices();
    }

    useEffect(async () => {
        searchValue = '';
        page = 1;
    }, []);

    useEffect(async () => {
        await loadDevices('');
        await loadStatus();
    }, [orgId]);

    const onRowChange =  (selectedRowKeys, selectedRows) => {
        const selectedUsers = [];
        selectedRows.map(el =>{
            selectedUsers.push(el.id);
        } )
        setSelectedRow(selectedUsers);
        setSelectedRowKeys(selectedRowKeys);
    }

    const loadStatus = async () => {
        const status = await apiClient.get().devicesApi.deviceStatus(
            '',
            [``],
            ['active:true', 'scanTopic:DEVICES', `organizationId:${orgId || -1}`],
            1,
            20
        );
        setDevicesStatus(status.data.data[0]);
    }

    return <div className="devices" data-testid="devices">
        <h1>Devices</h1>
        <div className="stats-container">
            <div>
                <Statistic title="Devices" value={devicesStatus?.total} />
            </div>
            <div>
                <Statistic title="Safe" value='816' />
            </div>
            <div>
                <Statistic title="At risk" value='816' />
            </div>
            <div>
                <Statistic title="Devices risk score" value='0.34' />
            </div>
            <div>
                <Statistic title="Vulnerabilities" value='12' />
            </div>
        </div>
        <div className="search-container">
            <div>
                <Search disabled={isEmptyPage && searchValue.length === 0} placeholder="Devices search" className="search-input" data-testid="device-search" onSearch={onSearch} />
            </div>
            <div className="command-buttons">
                <Button icon={<SendOutlined style={{ fontSize: '12px'}} />} disabled={true} />
                <Button icon={<DeleteOutlined style={{ fontSize: '12px'}} />} danger data-testid="delete-device" disabled={selectedRow?.length < 1} onClick={showDeleteDeviceConfirm} />
                <Button icon={<ExportOutlined style={{ fontSize: '12px'}} />} disabled={true} />
                <Button type="primary" className='add' icon={<PlusOutlined style={{ fontSize: '11px'}} />} data-testid="add-user" disabled={true}>Add</Button>
                <Button icon={<SettingOutlined style={{ fontSize: '12px'}} />} disabled={true} />
            </div>
        </div>
        <div className='filter'>
            <div> <span className='plus'>+</span>Add Filter</div>
        </div>

        {!(isEmptyPage && searchValue.length <= 0) && <div className={isEmptyPage ? 'empty-container' : ''}>
            <Table
                rowSelection={{
                    selectedRowKeys: selectedRowKeys,
                    onChange: onRowChange
                }}
                dataSource={devices}
                columns={columns}
                scroll={{y: 'calc(100vh - 420px)'}}
                loading={!isDevicesLoaded}
                pagination={
                    {
                        current: page,
                        total: totalPages,
                        showSizeChanger: false,
                        pageSize: 20,
                        onChange: handlePagination
                    }
                }
            />
        </div>}

        {isEmptyPage && <div data-testid="empty-list" className="empty-list-page-wrapper">
            <div className="empty-list-page">
                <div className="clients-empty">
                    <div>
                        <img src={noClients} alt=""/>
                    </div>
                    <div>Device list is empty</div>
                    <div>Stay tuned for updates</div>

                </div>
            </div>
        </div>}
    </div>
}

export default Devices;
