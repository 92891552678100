import './documentComments.css';
import {useEffect, useState} from "react";
import AppComment from "../../appComment/appComment";

const DocumentComments = ({ api, loggedUserId, projectId, orgId, documentId, requestReload }) => {

    const [comments, setComments] = useState([]);

    useEffect(async () => {
        await loadComments();
    }, [documentId, requestReload]);

    const loadComments = async () => {
        let page = 1;
        let allComments = []
        while (page) {
            const loadedComments = await api.get().documentCommentApi.documentCommentList(
                [],
                [`+dateCreated`],
                ['active:true', `projectId:${projectId}`, `documentId:${documentId}`, `organizationId:${orgId}`],
                ['userFirstName', 'userLastName', 'userFileName'],
                page
            );
            page = loadedComments.data.page.next;
            if (loadedComments.data.data.length > 0) {
                allComments = allComments.concat(loadedComments.data.data);
            }
        }
        setComments(allComments.length > 0 ? allComments : []);
    }

    const onCommentDelete = async (comment) => {
        await api.get().documentCommentApi.documentCommentPatch([
            `id:${comment.id}`], {active: false});
        await loadComments();
    }

    const onCommentEdit = async (comment) => {
        await api.get().documentCommentApi.documentCommentPatch([
            `id:${comment.id}`], {text: comment.text});
        await loadComments();
    }

    const getComments = (loadedComments) => {
        return loadedComments.length > 0 ? <div className="document-comments">
            { loadedComments.map((c, index) => <AppComment isEditAllowed={true} key={`task-comment-${index}`} comment={c} onEdit={onCommentEdit} onDelete={onCommentDelete} loggedUserId={loggedUserId} />)}
        </div> : null;
    }

    return getComments(comments);
}

export default DocumentComments;