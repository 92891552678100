import './addCloud.css';
import ChildSidebarPage from "../childSidebarPage/childSidebarPage";
import DeviceSummaryHeader from "../deviceSummaryHeader/deviceSummaryHeader";
import {useNavigate} from "react-router-dom";
import ApiClient from "../../apiClient";
import AddCloudForm from "../addCloudForm/addCloudForm";
import {useEffect, useState} from "react";
import { PieChartOutlined, BugOutlined } from '@ant-design/icons';

const AddCloud = ({orgId, api, userId}) => {

    const apiClient = api ? api : new ApiClient();
    const navigate = useNavigate();
    const [cloudAccountName, setCloudAccountName] = useState('');
    const [accountType, setAccountType] = useState('');
    const [formData, setFormData] = useState({});
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const [validateForm, setValidateForm] = useState(null);

    const addCloudForm = <AddCloudForm
        onNameChanged={(name) => {
            setCloudAccountName(name);
            setValidateForm(Date.now());
        }}
        onTypeChanged={(type) => {
            setAccountType(type);
        }}
        onDataChanged={(data) => {
            setFormData(data);
            setValidateForm(Date.now());
        }}
    />;

    const defaultTabs = [
        { "data-testid": "tab-cloud-summary", key: "tab-cloud-summary", title: 'Summary', label: 'Summary', icon: <PieChartOutlined />, disabled: false, page: addCloudForm},
        { "data-testid": "tab-cloud-vulnerabilities", key: "tab-cloud-vulnerabilities", title: 'Issues', label: 'Issues', icon: <BugOutlined />, disabled: true },
    ];

    const isFormValid = () => {
        let result = true;
        if (Object.keys(formData).length === 0) {
            result = false;
        }
        Object.keys(formData).forEach((key) => {
           if (formData[key].length === 0) {
               result = false;
           }
        });
        if (cloudAccountName.length === 0) {
            result = false;
        }
        return result;
    }

    useEffect(() => {
        return () => {
            setIsSaveDisabled(false);
            setFormData({});
        }
    }, []);

    useEffect(() => {
        const isValid = isFormValid();
        if (isSaveDisabled !== isValid) {
            setIsSaveDisabled(isValid);
        }
    }, [validateForm]);

    const getConfigForAWS = () => {

        const awsRegions = formData.regions.replace(/\s/g, '').split(',').map(s => `"${s}"`).join(',');
        const deviceInfo = `{"arch":"CLOUD","name":"${cloudAccountName}","platform":"aws","platform_like":"cloud","version":"","private_key":"${formData.accessKey}"}`;
        const deviceConfig = `secret_key="${formData.secretKey}"\naccess_key="${formData.accessKey}"\nregions=[${awsRegions}]`;

        return {
            info: deviceInfo,
            config: deviceConfig
        }
    }

    const getConfigForGCP = () => {
        const deviceInfo = `{"arch":"CLOUD","name":"${cloudAccountName}","platform":"gcp","version":"${formData.projectId}","private_key":"${formData.privateKey}"}`;
        return {
            info: deviceInfo,
            config: JSON.stringify(formData.deviceConfig)
        }
    }

    const saveCloud = async () => {

        const device = accountType === 'aws' ? getConfigForAWS() : getConfigForGCP();
        const dateNow = (new Date()).toISOString();

        try {
            const deviceParams = {
                "userId": userId,
                "organizationId": orgId,
                "enrollSecret": "",
                "scanTopic": "CLOUDS",
                "deviceType": accountType,
                "deviceConfig": device.config,
                "deviceInfo": device.info,
                "timestamp": dateNow,
                "active": true
            }

            const response = await apiClient.get().devicesApi.deviceCreate(deviceParams);
            navigate(`/clients/${orgId}/assets/clouds/${response.data.deviceId}/cloud-summary`);
        }
        catch (err) {
            console.log(err);
        }
    }

    const onCancel = () => {
        navigate(`/clients/${orgId}/assets/clouds`);
    }

    return <ChildSidebarPage
        defaultSelectedKeys={[defaultTabs[0].key]}
        menuContainerCssClass={'scan-menu-control'}
        header={<DeviceSummaryHeader
            onBack={onCancel}
            disabled={!isSaveDisabled}
            showDelete={false}
            showCancel={true}
            showOnlineStatus={false}
            onCancel={onCancel}
            device={{
                deviceType: accountType,
                timestamp: '',
                deviceName: "Cloud account name"
            }} onSave={saveCloud} />}
        sidebarTabs={defaultTabs}
    />
}

export default AddCloud;