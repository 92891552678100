import ApiClient from "../apiClient";

export default class GroupsController {

    GROUPS_PER_PAGE = 20;

    DEFAULT_SORT = ['+name'];
    DEFAULT_FILTER = ['active: true'];

    apiClient = null;

    constructor(props) {
        this.apiClient = props.apiClient || new ApiClient();
    }

    list = async (page, searchText, showStats, limit = this.GROUPS_PER_PAGE, filter = this.DEFAULT_FILTER) => {
        const loadedGroups = await this.apiClient.get().groupsApi.groupsList(
            searchText,
            this.DEFAULT_SORT,
            filter,
            [],
            page,
            limit
        );
        return showStats ? {
            data: loadedGroups.data.data,
            page: loadedGroups.data.page.next,
            hasNextPage: loadedGroups.data.page.next !== null
        } : loadedGroups.data.data;
    }

    removeSingleClientFromGroup = async (clientId, groupId) => {
        await this.apiClient.get().organizationsGroupsApi.organizationsGroupsDeleteMany(
            [[`organizationId:${clientId}`], `groupId:${groupId}`]);
    }

    addClientToGroup = async (clientId, groupId) => {
        return await this.apiClient.get().organizationsGroupsApi.organizationsGroupsCreate({
            organizationId: clientId,
            groupId: groupId
        });
    }

    createGroup = async (name) => {
        const result = await this.apiClient.get().groupsApi.groupsCreate({
            name: name,
            active: true
        });
        return result.data.GroupsId;
    }

    updateGroup = async (groupId, params) => {
        return await this.apiClient.get().groupsApi.groupsUpdate(groupId, params);
    }
}
