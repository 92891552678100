import './tasks-templates.css';
import TasksList from "../tasksList/tasksList";
import ApiClient from "../../apiClient";

const TasksTemplates = ({api, accessScope, template}) => {
    const apiClient = api || new ApiClient();
    return <div className="tasks-templates">
        <TasksList api={apiClient} accessScope={accessScope} isTemplates={true} template={template}/>
    </div>
}

export default TasksTemplates;