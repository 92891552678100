import ApiClient from "../../apiClient";

class EditorUtils {

    api;
    scanTopic;
    dbSchemeCache = {};

    constructor(monacoWrapper, api, scanTopic) {
        this.monacoWrapper = monacoWrapper;
        this.api = api || new ApiClient();
        this.scanTopic = scanTopic;
        this.provideCompletionItems = this.provideCompletionItems.bind(this);
    }

    getText(model, position) {

        const textUntilPosition = model.getValueInRange({
            startLineNumber: position.lineNumber,
            startColumn: position.column,
            endLineNumber: position.lineNumber + 1000,
            endColumn: position.column + 1000,
        });

        let text = textUntilPosition.toLowerCase().replaceAll(/\n/g, ' ');
        text = text.replace(/\s+/g, ' ');

        return text;
    }

    async getAutocompleteItems(tableName) {

        let table = null;

        let dbScheme = await this.getDbScheme();
        dbScheme.tables.forEach(t => {
            const res = t.tables.find(tb => tb.name === tableName);
            if (res) {
                table = res;
            }
        });

        let result = [];

        if (table) {
            result = table.columns.map(t => {
                return {
                    label: t.name,
                    kind: this.monacoWrapper.languages.CompletionItemKind.Field,
                    insertText: t.name
                }});
        }

        return result;
    }

    removeSubQueries(text) {
        const pattern = /\([^()]*\)/g;
        let result = text;
        while (pattern.test(result)) {
            result = result.replace(pattern, "");
        }
        return result;
    }

    getAllTables(text) {

        const queryRegex = /^[^]*?(?=SELECT|$)/is;
        const matches = text.match(queryRegex);
        const splitRegex = /\bFROM\s+(\w+(?:,\s*\w+)*)|\bJOIN\s+(\w+)/gi;

        let allTables = [];

        if (matches && matches.length > 0 && matches[0].length > 0) {
            allTables = matches[0].match(splitRegex) || [];
        }

        let result = [];

        allTables.forEach((t) => {
            const value = t.split(',').map(v => v.replace('join', '').replace('from', '').trim());
            result = result.concat(value);
        });

        return result;
    }

    async getSuggestions(model, position) {

        let text = this.getText(model, position);
        text = this.removeSubQueries(text);
        const tables = this.getAllTables(text);

        let suggestions = [];

        if (tables.length > 0) {
            for (const t of tables) {
                const table = t.replace(')', ' ').replace(';', ' ').trim();
                const items = await this.getAutocompleteItems(table);
                suggestions = suggestions.concat(items);
            }
        }

        return suggestions;
    }

    async provideCompletionItems(model, position) {

        let queryTables = [];
        let dbScheme = await this.getDbScheme();
        dbScheme.tables.forEach((tables) => {
            const result = tables.tables.map(t => {
                return {
                    label: t.name,
                    kind: this.monacoWrapper.languages.CompletionItemKind.Class,
                    insertText: t.name
                }});
            queryTables = queryTables.concat(result);
        });

        const fields = await this.getSuggestions(model, position);

        if (fields) {
            queryTables = queryTables.concat(fields);
        }

        return {
            suggestions: queryTables
        };

    }

    async getDbScheme() {
        if(this.scanTopic === 'DEVICES') {
            return await this.loadDbScheme('osquery');
        } else if (this.scanTopic === 'CLOUDS') {
            return await this.loadDbScheme('steampipe');
        }
    }

    async loadDbScheme(schemeName) {
        if(!this.dbSchemeCache[schemeName]) {
            this.dbSchemeCache[schemeName] = (await this.api.get().filesApi.editorScheme(schemeName, [])).data;
        }
        return this.dbSchemeCache[schemeName];
    }
}

export default EditorUtils;
