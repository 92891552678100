import { useState } from 'react';


const useDataList = (controller) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const getSearchText = (text) => text.split(' ');

    const fetch = async (fetchState) => {
        try {
            setLoading(true);
            const fetchedData = await controller.find(
                getSearchText(fetchState.searchValue),
                fetchState.sort,
                fetchState.filters,
                fetchState.expands,
                fetchState.page,
            );
            setLoading(false);
            return fetchedData;
        } catch (error) {
            console.log('error', error);
            setError(error);
            setLoading(false);
        }
    };

    return [fetch, loading, error];
};

export default useDataList;
