import './taskComments.css';
import {useEffect, useState} from "react";
import AppComment from "../appComment/appComment";

const TaskComments = ({ api, loggedUserId, requestReload, taskId, projectId, orgId, onCommentsRequested, refresh }) => {

    const [comments, setComments] = useState([]);

    useEffect(async () => {
        await loadComments();
    }, [requestReload]);

    const loadComments = async () => {
        let page = 1;
        let allComments = []
        while (page) {
            const loadedComments = await api.get().taskCommentApi.taskCommentList(
                [],
                [`+dateCreated`],
                ['active:true', `projectId:${projectId}`, `taskId:${taskId}`, `organizationId:${orgId}`],
                ['userFirstName', 'userLastName', 'userFileName'],
                page
            );
            page = loadedComments.data.page.next;
            if (loadedComments.data.data.length > 0) {
                allComments = allComments.concat(loadedComments.data.data);
            }
        }
        setComments(allComments.length > 0 ? allComments : []);
        if (onCommentsRequested) {
            onCommentsRequested();
        }
    }

    const onCommentDelete = async (comment) => {
        await api.get().taskCommentApi.taskCommentPatch([`id:${comment.id}`], {active: false});
        await loadComments();
        refresh && refresh();
    }

    const onCommentEdit = async (comment) => {
        await api.get().taskCommentApi.taskCommentPatch([`id:${comment.id}`], {text: comment.text});
        await loadComments();
    }

    const getComments = (loadedComments) => {
        return loadedComments.length > 0 ? <div className="task-comments">
            { loadedComments.map((c, index) => <AppComment isEditAllowed={true} key={`task-comment-${index}`} comment={c} onEdit={onCommentEdit} onDelete={onCommentDelete} loggedUserId={loggedUserId} />)}
        </div> : null;
    }

    return getComments(comments);
}

export default TaskComments;