import React, {useEffect, useRef, useState} from "react";
import Editor, {loader, useMonaco} from "@monaco-editor/react";
import * as monaco from 'monaco-editor';
import EditorUtils from "./editorUtils";
import './sqlEditor.css';
import ApiClient from "../../apiClient";

const SqlEditor = ({handleEditorChange, disabled, defaultText, scanTopic, saveQuery }) => {

    const monacoWrapper = useMonaco();
    const [isEditing, setIsEditing] = useState(false);
    const timeoutIdRef = useRef(null);

    if (process.env.NODE_ENV !== 'test') {
        loader.config({ monaco });
        monaco.editor.defineTheme('violet-x', {
            base: 'vs',
            inherit: true,
            rules: [{ background: '#FAFAFA' }],
            colors: {
                'editor.background': '#FAFAFA',
            }
        });
        monaco.editor.setTheme('violet-x');
    }

    useEffect(() => {
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }

        timeoutIdRef.current = setTimeout(() => {
            if (isEditing) {
                setIsEditing(false);
                saveQuery();
            }
        }, 2000);

        return () => clearTimeout(timeoutIdRef.current);
    }, [defaultText]);

    const onTextChange = (value) => {
        setIsEditing(true);
        handleEditorChange(value);
    }

    useEffect(() => {

        let editorDisposable = null;

        if (monacoWrapper) {
            const editorUtils = new EditorUtils(monacoWrapper, new ApiClient(), scanTopic);
            editorDisposable = monacoWrapper
                .languages
                .registerCompletionItemProvider('sql', {
                    provideCompletionItems: editorUtils.provideCompletionItems
                });
        }

        return () => {
            if (editorDisposable) {
                editorDisposable.dispose();
            }
        }

    }, [monacoWrapper]);

    return <>
        <Editor
            className="sql-editor"
            keepCurrentModel={false}
            defaultLanguage="sql"
            defaultValue={defaultText}
            onChange={onTextChange}
            disabled={disabled}
            theme="violet-x"
            height="100%"
            options={{
                readOnly: disabled,
                fontSize: '14px',
                fontFamily: 'Roboto Mono',
                fontWeight: '500'
            }}
        />
    </>
}

export default SqlEditor;
