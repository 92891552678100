import ApiClient from "../apiClient";

export default class TemplateTaskLinkageController {
    apiClient = null;

    constructor(props) {
        this.apiClient = props?.apiClient || new ApiClient();
    }

    templateTasksLinkageCreate = async (params) => {
        return await this.apiClient.get().templateTaskLinkageApi.templateTasksLinkageCreate(params);
    }

    templateTasksLinkageDelete = async (filter) => {
        return await this.apiClient.get().templateTaskLinkageApi.templateTasksLinkageDeleteMany(filter);
    }

}