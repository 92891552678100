import './editTemplateSummary.css';
import {Form, Input, Select, message, Radio, InputNumber} from "antd";
import {
    FolderOpenOutlined,
    EditOutlined, CheckOutlined, CloseOutlined
} from "@ant-design/icons";
import SearchableSelect from "../searchableSelect/searchableSelect";
import React, {useEffect, useRef, useState} from "react";
import UploadImage from "../uploadImage/uploadImage";
import {UserAvatar} from "../userAvatar/userAvatar";
import ClickOutsideComponent from "../clickOutsideHandler/clickOutsideHandler";
import useTemplateOperations from "../../hooks/useTemplateOperations";
import useClientsOperations from "../../hooks/useClientsOperations";
import useGroupsOperations from "../../hooks/useGroupsOperations";

export const EditTemplateSummary = ({ loggedUser, template, users, onSave, api}) => {

    const limit = 20;

    const [name, setName] = useState('');
    const [templateDescription, setTemplateDescription] = useState('');
    const [allowResponsible, setAllowResponsible] = useState(true);
    const [responsible, setResponsible] = useState(loggedUser.userId);
    const [estimate, setEstimate] = useState(null);
    const [forms, setForms] = useState([]);
    const [isImageSaved, setIsImageSaved] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [clients, setClients] = useState([]);
    const [selectedClients, setSelectedClients] = useState(template ? template.selectedClients??[] : []);
    const [selectChanged, setSelectChanged] = useState(template ? template.selectedClients??[] : []);
    const [selectedForm, setSelectedForm] = useState('');
    const [type, setType] = useState('VX_SERVICE');
    const [templateAvailableFor, setTemplateAvailableFor] = useState(template ? template.type : 'PUBLIC');
    const [enableChangeClientsListSelect, setEnableChangeClientsListSelect] = useState(true);
    const [page, setPage] = useState(1);
    const [isAllGroupsLoaded, setIsAllGroupsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [editingName, setEditingName] = useState(false);
    const [editEstimate, setEditEstimate] = useState(false);
    const [editingDescription, setEditingDescription] = useState(false);
    const [editingQuestionnaire, setEditingQuestionnaire] = useState(false);
    const { Search } = Input;
    const closeNameRef = useRef(null);
    const templateNameRef = useRef(null);
    const templateDescriptionRef = useRef(null);
    const templateCloseDescriptionRef = useRef(null);
    const estimateRef = useRef(null);
    const closeEstimateRef = useRef(null);
    const [showDropdownResponsible, setShowDropdownResponsible] = useState(false);
    const [showDropdownClients, setShowDropdownClients] = useState(false);
    const searchRef = useRef(null);
    const templateDescriptionInputRef = useRef(null);
    const [updateTemplate, loadForms] = useTemplateOperations(api);
    const [,,, listClients] = useClientsOperations(api);
    const [,,, getGroupsList] = useGroupsOperations(api);

    const { TextArea } = Input;

    useEffect(() => {
        async function handleClickOutsideDescription(event) {
            if (templateDescriptionRef.current && !templateDescriptionRef.current.contains(event.target) && templateCloseDescriptionRef && !templateCloseDescriptionRef.current.contains(event.target)) {
                await handleSaveDescription();
            }
        }
        document.addEventListener("mousedown", handleClickOutsideDescription);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideDescription);
        };
    }, [templateCloseDescriptionRef, templateDescription, templateDescriptionRef]);

    useEffect(() => {
        if (editingDescription) {
            templateDescriptionInputRef.current?.focus();
        }
    }, [editingDescription]);

    const getClients = async (refresh, currentPage) =>
        await listClients(
            currentPage ?? page,
            refresh ? '' : searchValue,
            null);
    const getGroups = async (refresh, currentPage) =>
        await getGroupsList(
            currentPage ?? page,
            refresh ? '' : searchValue,
            limit);

    const loadClients = async (onSearch, refresh, currentPage) => {
        setIsLoading(true);
        if (onSearch) {
            setPage(1);
            currentPage = 1;
            setIsAllGroupsLoaded(false);
        }
        let optionsToDisplay = [];
        let loadedGroups = null;
        if (!isAllGroupsLoaded || refresh || onSearch) {
            loadedGroups = await getGroups(refresh, currentPage);
            optionsToDisplay = loadedGroups.data.map((o, i) => {
                const isLastGroupItem = !loadedGroups.hasNextPage && i === loadedGroups.length - 1;
                const label = ownerOptionRenderer(o, i, 'groups', isLastGroupItem);
                return {
                    label: label,
                    value: `${o.id}-g`
                }
            });
        }
        if (!loadedGroups?.hasNextPage) {
            if (!isAllGroupsLoaded) {
                setIsAllGroupsLoaded(true);
                setPage(1);
                currentPage = 1;
            }
            const loadedClients = await getClients(refresh, currentPage);
            optionsToDisplay = [...optionsToDisplay, ...loadedClients.map((o, i) => {
                const label = ownerOptionRenderer(o, i, 'organizations');
                return {
                    label: label,
                    value: o.id
                }
            })];
        }
        if (optionsToDisplay.length > 0 || onSearch) {
            const uniqueArray = optionsToDisplay.filter(obj => !clients.find(e => e.value === obj.value));
            setClients(onSearch ? [...optionsToDisplay] : [...clients,...uniqueArray]);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (showDropdownClients) {
            searchRef.current?.focus();
        }
    }, [searchRef, showDropdownClients]);

    useEffect(() => {
        if (editingName) {
            templateNameRef.current?.focus();
        }
    }, [templateNameRef, editingName]);

    useEffect(() => {
        if (editEstimate) {
            estimateRef.current?.focus();
        }
    }, [estimateRef, editEstimate]);

    useEffect(async () => {
        setIsImageSaved(false);
        await getForms();
        await loadClients();
        const defaultSelectedClients = await getLabel();
        setSelectedClients(defaultSelectedClients);
        setSelectChanged([...defaultSelectedClients]);
    }, []);

    const getForms = async () => {
        const response = await loadForms();
        const forms = response.data?.items?.map(f => ({
            label: f.title,
            value: f.id
        }))??[];
        setForms(forms);
    }

    useEffect(() => {
        if (enableChangeClientsListSelect) {
            setSelectedClients([...selectChanged]);
        } else {
            searchValue.length > 0 && setSearchValue(searchValue.substring(0, searchValue.length - 1));
            setEnableChangeClientsListSelect(true);
            setSelectChanged([...selectedClients]);
        }
    },[selectChanged, enableChangeClientsListSelect, searchValue])

    useEffect(() => {
        if (template !== null) {
            setName(template.title);
            setTemplateDescription(template.description);
            setType(template.projectType);
            setIsImageSaved(false);
            if (template.estimate !== null) {
                setEstimate(template.estimate);
            }
            if (template.formId?.length > 0) {
                setSelectedForm(template.formId);
            }
            if (template.userId) {
                setResponsible(template.userId);
                setAllowResponsible(true);
            } else {
                setAllowResponsible(false);
            }
        }
    }, [template, template.fileName]);

    const getLabel = async () => {
        const clientsGroupsPromises = [];
        const filter = ['active:true'];
        let loadGroupsData = false;
        let loadClientsData = false;
        const clientsFilter = ['active:true'];
        const defaultSelectedGroups = template?.selectedClientsGroups??[];
        defaultSelectedGroups.map(label => {
            if (!clients.find(v => v.id === label)) {
                filter.push(`id:${label}`);
                loadGroupsData = true;
            }
        });
        loadGroupsData && clientsGroupsPromises.push(new Promise((resolve) => {
            const group = getGroupsList(
                1,
                '',
                50,
                filter);
            resolve(group);
        }))
        selectedClients.map(label => {
            if (!clients.find(v => v.id === label)) {
                clientsFilter.push(`id:${label}`);
                loadClientsData = true;
            }
        });
        loadClientsData && clientsGroupsPromises.push(new Promise((resolve) => {
            const client = listClients(
                1,
                '',
                null,
                50,
                clientsFilter
            );
            resolve(client);
        }))
        let results = await Promise.all(clientsGroupsPromises);
        results = results.flatMap(data => data.data ? data.data : data);
        return results.map(client => {
            const isClient = client.hasOwnProperty('fileName')
            return {
                label: ownerOptionRenderer(client, client.id, isClient ? 'organizations' : 'groups'),
                key:  isClient ? client.id : `${client.id}-g`,
                value: isClient ? client.id : `${client.id}-g`
            };
        });
    }

    const ownerOptionRenderer = (option, index, bucketName, isLastGroupItem) => {
        return <div className={isLastGroupItem ? "lead-item last-group" : "lead-item"} data-testid={`option-item-${index}`}>
            {bucketName === 'groups' ? <FolderOpenOutlined/> : <UserAvatar showOnlyFirstLetter={true} bucketName={bucketName} size={26} fileName={option.fileName} key={option.name} name={option.name}/>}
            <span style={{marginLeft: '4px'}}>{option.name}</span>
        </div>
    }

    const saveTemplate = async (value) => {
        let id = template ? template.id : null;

        if (value === null) {
            setSelectedForm(null);
        }
        const templateToSave = {
            title: name,
            description: templateDescription,
            estimate,
            formId: value
        };

        if (templateAvailableFor === 'SELECTED_CLIENTS') {
            const groupsToAdd = [];
            const clientIdsToAdd = [];
            selectedClients.map(e => {
                const value = e.value ? e.value : e;
                typeof(value) === "string" ? groupsToAdd.push(+value.split('-')[0]) : clientIdsToAdd.push(value);
            });
            templateToSave.selectedClientsGroups = groupsToAdd.length > 0 ? JSON.stringify(groupsToAdd) : null;
            templateToSave.selectedClients = clientIdsToAdd.length > 0 ? JSON.stringify(clientIdsToAdd) : null;
        }

        if (type === 'VX_SERVICE') {
            templateToSave.userId = responsible
        }
        templateToSave.projectType = type;
        await updateTemplate([`id:${id}`], templateToSave);
        message.success(`Template has been successfully saved`);
        setIsImageSaved(true);
        await onSave(template.id);
    }

    const handleSearchValue = (e) => {
        setSearchValue(e.target.value);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.stopPropagation();
        }
    }

    const handleClientsChange = (value) => {
        setShowDropdownClients(true);
        setSelectChanged(value);
    }

    const handleSaveType = async (value) => {
        await updateTemplate([`id:${template.id}`], {type: value});
        message.success(`Template has been successfully saved`);
        setTemplateAvailableFor(value);
    }

    const handleSaveResponsible = async (value) => {
        await updateTemplate([`id:${template.id}`], {userId: value});
        message.success(`Template has been successfully saved`);
        setResponsible(value);
    }

    async function onScroll(e) {
        const scrolledDown = parseInt(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight;
        if (scrolledDown && !isLoading) {
            setPage(page + 1);
            await loadClients(false, false, page + 1);
        }
    }

    const onKeyDown = (e) => {
        if (e.key === 'Backspace') {
            setEnableChangeClientsListSelect(false);
        }
    }

    const handleOnKeyDown = (e, callBack) => {
        if (e.key === 'Escape') {
            callBack()
        }
    }

    const getSearch = (menu) => (
        <>
            <Search placeholder="Search" style={{ height: 24 }}
                    className="options-search"
                    data-testid="options-search"
                    onSearch={() => loadClients(true)}
                    value={searchValue}
                    onChange={handleSearchValue}
                    onKeyDown={handleKeyDown}
                    ref={searchRef}
            />
            {menu}
        </>
    )
    const handleDropdownVisibility = async (isOpen) => {
        setShowDropdownClients(false);
        if (isOpen) {
            setSearchValue('');
            searchRef.current?.focus();
        } else {
            await saveTemplate();
            await onSave(template.id);
            if (!selectedClients[0]?.label) {
                const mewSelectedClients = selectedClients.map(id => clients.find(client => client.value === id));
                setSelectedClients(mewSelectedClients);
            }
            await loadClients(true, true);
        }
    }

    const onImageUploaded = async () => {
        await onSave(template.id);
    }

    const handleSaveName = async () => {
        setEditingName(false);
        if (name.trim().length > 0) {
            await saveTemplate();
            await onSave(template.id);
        } else {
            setName(template.title);
        }
    };
    const handleSaveEstimate = async () => {
        if (template.estimate === estimate) {
            setEditEstimate(false);
            message.error('data is invalid')
            return
        }
        setEditEstimate(false);
        await saveTemplate();
    };
    const handleEditName = () =>{
        setEditingName(true);
    }
    const handleCloseEdit = () => {
        setName(template.title);
        setEditingName(false);
    }

    const handleCloseEditEstimate = () => {
        setEstimate(template.estimate)
        setEditEstimate(false)
    }
    const changeProjectName = (value) => {
        setName(value);
    }

    const handleEditDescription = () =>{
        setEditingDescription(true);
    }

    const handleEditQuestionnaire = () =>{
        setEditingQuestionnaire(true);
    }
    const handleCloseEditDescription = () => {
        setTemplateDescription(template.description);
        setEditingDescription(false);
    }
    const getTitle = () => {
        return forms.find(f => f.value === selectedForm)?.label;
    }

    const handleSaveDescription = async () => {
        setEditingDescription(false);
        if (templateDescription.trim().length === 0) {
            setTemplateDescription('');
        }
        await saveTemplate();
    };
    const handleSaveQuestionnaire  = async (value) => {
        await saveTemplate(value);
        await onSave(template.id)
        setEditingQuestionnaire(false);
    };

    const getResponsible = ()  => {
        const option = users.find(u => u.id === responsibleDropDownLabel)
        return <div onClick={() => setShowDropdownResponsible(true)}>
            {option && ownerOptionRenderer(option, responsibleDropDownLabel, 'users')}
        </div>
    }
    const getSelectedClientsList = () => {
        if (selectedClients.length === 0) {
            return <span data-testid="selected-clients-0" onClick={() => setShowDropdownClients(true)}>None</span>
        }
        return selectedClients.map((c, id) => {
        return <span className="clients-list" data-testid={`selected-clients-${id}`} key={c.value} onClick={() => setShowDropdownClients(true)}>
            {c.label}
        </span>})
    }

    const getResponsibleDropdown = () => <SearchableSelect
            disabled={!allowResponsible}
            selectedValueLabel={responsibleDropDownLabel}
            onSelectedValueChanged={handleSaveResponsible}
            options={users}
            onDropdownVisibleChange={() => setShowDropdownResponsible(false)}
            defaultOpen={true}
            optionsRenderer={ownerOptionRenderer}
        />
    const getTemplateName = () => {
        return <div className="template-name">
            {editingName ?
                <span>
                        <ClickOutsideComponent onClickOutside={handleSaveName}>
                            <Input onKeyDown={(e) => handleOnKeyDown(e, handleCloseEdit)} onPressEnter={handleSaveName} className="edit-title" ref={templateNameRef} placeholder="Name" value={name} data-testid="text-name" onChange={(e) => changeProjectName(e.target.value)}/>
                        </ClickOutsideComponent>
                    </span>
                :
                <p onClick={handleEditName} data-testid="edit-name">{name}</p>}
            {!editingName  && <EditOutlined onClick={handleEditName}/>}
            {editingName && <div className="edit-icons" data-testid="edit-icons"><span className="check-outlined">
                    <CheckOutlined data-testid="check" onClick={handleSaveName} /> </span><CloseOutlined ref={closeNameRef} data-testid="close" onClick={handleCloseEdit}/>
            </div> }
        </div>
    }
    const textArea = () => {
        return (
            <TextArea placeholder="Description" value={templateDescription}
                      data-testid="text-description"
                      onFocus={() => setEditingDescription(true)}
                      onChange={(e) => setTemplateDescription(e.target.value)}
                      autoSize={true}
                      ref={templateDescriptionInputRef}
            />
        )
    }

    const getDescriptionTemplate = () => {
        return <div className={editingDescription ? "description-container active" : "description-container"}>
            <div className="subtitle"><span>Description</span>
                {!editingDescription && <EditOutlined className="edit-icon" onClick={handleEditDescription}/>}
                {editingDescription ?
                    <div className="edit-icons edit-icons-description"><span className="check-outlined">
                       <CheckOutlined data-testid="save-description"
                                      onClick={handleSaveDescription}/> </span><CloseOutlined ref={templateCloseDescriptionRef}
                                                                                              data-testid="close-icon"
                                                                                              onClick={handleCloseEditDescription}/>
                    </div> : null}
            </div>
            {editingDescription ?
                <div className="text-area-container">
                        <span ref={templateDescriptionRef}>{textArea()}</span>
                </div> :
                <>
                    <div onClick={handleEditDescription} className="project-description"
                         data-testid="description">{templateDescription}</div>
                </>}
        </div>
    }

    const getQuestionnaireTemplate = () => {
        return <div className={editingQuestionnaire ? "description-container active" : "description-container"}>
            <div className="subtitle"><span>Questionnaire</span>
                {!editingQuestionnaire && <EditOutlined className="edit-icon" onClick={handleEditQuestionnaire}/>}
            </div>
            {editingQuestionnaire ?
                <div className="text-area-container">
                    <Select
                        placeholder="Choose questionnaire"
                        defaultValue={selectedForm}
                        value={selectedForm}
                        onChange={(value = null) => handleSaveQuestionnaire(value)}
                        onDropdownVisibleChange={() => setEditingQuestionnaire(false)}
                        options={forms}
                        defaultOpen={true}
                        allowClear={true}
                    />
                </div> :
                <>
                    <div onClick={handleEditQuestionnaire} className="template-questionnaire" placeholder="Questionnaire"
                         data-testid="questionnaire">{getTitle()}</div>
                </>}
        </div>
    }

    const getEstimate = () =>{
            return <div className="template-estimate">
                <div className='template-details-subtitle'>Estimate</div>
                {editEstimate ?
                    <ClickOutsideComponent onClickOutside={handleSaveEstimate}>
                    <span className="edit-input">
                        <InputNumber onKeyDown={(e) => handleOnKeyDown(e, handleCloseEditEstimate)}
                               onPressEnter={handleSaveEstimate} ref={estimateRef} type="number"
                               className="estimate-input" min={0} max={1000}
                               value={estimate} data-testid="estimate-name"
                               onChange={(value) => setEstimate(value)}/>
                    </span>
                    </ClickOutsideComponent>
                    :
                    <span onClick={() => setEditEstimate(true)} data-testid="edit-estimate">{estimate}
                        <span className='estimate-ending'>day{+estimate === 1 ? '' : 's'}</span>
                    </span>}
                {editEstimate &&
                    <div className="edit-icons" data-testid="edit-icons"><span className="check-outlined estimate">
                    <CheckOutlined data-testid="check" onClick={handleSaveEstimate}/> </span><CloseOutlined
                        ref={closeEstimateRef} data-testid="close" onClick={handleCloseEditEstimate}/>
                    </div>}
                {!editEstimate && <EditOutlined className="edit-icon" onClick={() => setEditEstimate(true)}/>}
            </div>
    }


    const defaultResponsible = users.find(u => u.id === responsible);
    const responsibleDropDownLabel = defaultResponsible ? defaultResponsible.id : 'Loading...';


    return <div className={`template-summary ${template.id && 'template-position'}`}>
    <div>
        <div className="form-header">
            {getTemplateName()}
        </div>
        <Form
            layout="vertical"
            className="project-view-edit-form edit-template"
        >
            {template.id && <><Form.Item className="form-item">{getDescriptionTemplate()}</Form.Item></>}
            <Form.Item className="form-item" label="Template Picture">
                <UploadImage
                    bucketName={'templates'}
                    api={api}
                    showUploadTitle={false}
                    redirect={() => {}}
                    id={template.id}
                    name=""
                    setImageSaved={setIsImageSaved}
                    prevFileName={template ? template.fileName : null}
                    isImageSaved={isImageSaved}
                    uploadText="Drop a template picture here"
                    width={209}
                    height={100}
                    preferAspect={16/9}
                    onImageUploaded={onImageUploaded}
                />
            </Form.Item>
            {type === 'VX_SERVICE' && <Form.Item className="form-item">{getQuestionnaireTemplate()}</Form.Item> }
        </Form>
    </div>
        <Form
            layout="vertical"
            className="project-view-edit-form template-container-form"
        >
            <span className="separator"/>
          <div className="edit-template">
              <div className="title">Project details</div>
              {template.projectType === 'VX_SERVICE' ? <>
                  <div className="label-wrapper">
                      <span>Type</span>
                      <div className="template-label">vX Service</div>
                  </div>
              </> : <div className="label-wrapper">
                  <span>Type</span>
                  <div className="type-default">Default</div>
              </div>
              }
              {type === 'VX_SERVICE' && <><Form.Item>
                  {getEstimate()}
              </Form.Item>
              </>}
              {type === 'VX_SERVICE' && <><Form.Item>
                  <div className='template-container'>
                      <div className='template-details-subtitle'>Responsible</div>
                      {!showDropdownResponsible ? getResponsible() : getResponsibleDropdown()}
                  </div>
              </Form.Item>
              </>}
              <Form.Item>
                  <div className='template-container'>
                      <div className='template-details-subtitle'>Available for</div>
                      <Radio.Group size="small" data-testid="type" className="buttons" onChange={(e) => handleSaveType(e.target.value)}
                                   value={templateAvailableFor}>
                          <Radio.Button data-testid='PUBLIC' value='PUBLIC'>Public</Radio.Button>
                          <Radio.Button data-testid='VX_ONLY' value='VX_ONLY'>vX only</Radio.Button>
                          <Radio.Button data-testid='SELECTED_CLIENTS' value='SELECTED_CLIENTS'>Selected
                              clients</Radio.Button>
                      </Radio.Group>
                  </div>
              </Form.Item>
              {templateAvailableFor === 'SELECTED_CLIENTS' && <Form.Item>
                      {showDropdownClients ? <Select
                          dropdownRender={getSearch}
                          onKeyDown={onKeyDown}
                          mode="multiple"
                          labelInValue={selectedClients[0]?.label}
                          className="searchable-select"
                          popupClassName="searchable-select-popup"
                          data-testid="searchable-clients-select"
                          style={{width: '100%'}}
                          value={selectedClients}
                          onChange={handleClientsChange}
                          autoFocus={true}
                          defaultOpen={true}
                          searchValue=''
                          options={clients}
                          onPopupScroll={onScroll}
                          onDropdownVisibleChange={handleDropdownVisibility}
                      /> : <div className="clients-list-wrapper">{getSelectedClientsList()}</div>}
              </Form.Item>}
          </div>
        </Form>
    </div>
}
export default EditTemplateSummary;