import React, {useEffect, useState} from 'react';
import './dataPreviewTable.css'
import { Table } from 'antd';
import {QUERY_TRANSFORM_FIELDS, SOFTWARE_TRANSFORM_FIELDS} from "@violetx-mvp/commons";
import {ToTopOutlined} from '@ant-design/icons';
import {formatCustomFields, formatDate} from "../utils";

export default function DataPreviewTable ({tableData, i, api, scanId, scan, orgId}) {
    const [data, setData] = useState([]);
    const [hideFullData, setHideFullData] = useState(true);
    const [fullData, setFullData] = useState([]);
    const [total, setTotal] = useState(0);

    function transformColumnData(data) {
        for (const dataKey in data) {
            if (Array.isArray(data[dataKey])) {
                data[dataKey] = data[dataKey].join(' ');
            } else if (typeof data[dataKey] === "object") {
                data[dataKey] = JSON.stringify(data[dataKey]);
            }
        }
    }

    useEffect(() => {
        if (tableData.resultPreview && tableData.resultPreview[0] !== null) {
            tableData.resultPreview.forEach((data, index) => {
                data.rawData = formatCustomFields(data.customFields);
                data.Timestamp = !data.Timestamp ? 'Unknown' : formatDate(data.Timestamp);
                data.key = `tableData-${index}-${i}`;
                transformColumnData(data);
            });
            setData([...tableData.resultPreview])
        } else {
            setData([]);
        }
        setHideFullData(true);
    },[tableData]);

    const showFullData = async (show, page) => {
        if (show) {
            const filter = [`organizationId:${orgId}`, `scanId:${scanId}`, `sourceId:${tableData.id}`, 'active: true'];
            const result = await api.get().scanResultsApi.scanResultList([],
                [''],
                filter,
                ['resultExpand', 'deviceInfo'],
                page ?? 1,
                10
            );
            setTotal(result.data.page.total)
            let fullTableData = result.data.data.map((data, index) => {
                if(!data.result) {
                    return {key : `tableData-${index}-${i}`};
                }
                if (data.deviceInfo?.arch === 'CLOUD') {
                    data.result.Owner = data.deviceInfo.name
                }
                data.result.rawData = formatCustomFields(data.result.customFields);
                data.result.Timestamp = !data.result.Timestamp ? 'Unknown' : formatDate(data.result.Timestamp);
                transformColumnData(data.result);
                return {...data.result, key : `tableData-${index}-${i}`};
            });
            setFullData(fullTableData);
        }
        setHideFullData(!show);
    }

    const columns = [];
    const dataToDisplay = hideFullData ? data : fullData;
    const transformFields = scan.target === 'SOFTWARE'
        ? SOFTWARE_TRANSFORM_FIELDS
        : QUERY_TRANSFORM_FIELDS;

    if (data.length > 0) {
        Object.keys(data[0]).forEach(key => {
            if (transformFields.indexOf(key) !== -1) {
                columns.push({
                    title: key,
                    key: key,
                    dataIndex: key,
                    align: 'left'
                });
            }
        });
        columns.push({
            title: 'Raw data',
            dataIndex: 'rawData',
            key: `preview-col-${100}`,
            align: 'left'
        });
        dataToDisplay.forEach(d => {
            for (let field in d) {
                if (d[field] !== null && d[field] !== undefined) {
                    d[field] = `${d[field]}`
                }
            }
        })
    }

    async function handlePaging(page) {
        await showFullData(true, page);
    }

    const getButton = () => !hideFullData ?
            <div className='collapse' data-testid={`collapse-${i}`} onClick={() => showFullData(false)}>
                <ToTopOutlined />
                <div className='collapse-text'>Collapse</div>
            </div> :
            <div className='view-all-wrapper'>
                <div className='view-all' data-testid={`view-${i}`} onClick={() => showFullData(true)}>View all</div>
            </div>

    return <>
        <Table
            data-testid={`report-preview-table-${i}`}
            title={() => <div className='title'>{tableData.name}</div>}
            key={i}
            columns={columns}
            dataSource={dataToDisplay}
            pagination={hideFullData ? false : {
                total: total,
                onChange: handlePaging,
                showSizeChanger: false
            }}
        />
        {tableData.resultPreview && tableData.resultPreview[0] !== null && getButton()}
    </>
}
