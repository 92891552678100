import './clientSummary.css';
import {
    Form,
    Input,
    Checkbox,
    DatePicker,
    Button,
    message,
    Radio, Select,
} from 'antd';
import {
    ExportOutlined,
    CheckOutlined,
    CloseOutlined,
    EditOutlined,
    FolderOpenOutlined,
    ArrowLeftOutlined,
    DeleteOutlined,
    UserAddOutlined
} from '@ant-design/icons';
import React, {useEffect, useRef, useState} from "react";
import dayjs from 'dayjs'

import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import SearchableSelect from "../searchableSelect/searchableSelect";
import {UserAvatar} from "../userAvatar/userAvatar";
import {formatDateNoTime, showDeactivateConfirm} from "../utils";
import ApiClient from "../../apiClient";
import {RolesMapper} from "../../constants";
import {useNavigate} from "react-router-dom";
import ClickOutsideComponent from "../clickOutsideHandler/clickOutsideHandler";
import UploadImage from "../uploadImage/uploadImage";


dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

let groupsDropDownHold = false;

const ClientSummary = ({ match, api  }) => {

    const customFormat = (value) => value?.format('MMMM DD, YYYY');
    const clientId = match.params.orgsId;

    const [name, setName] = useState('');
    const [defaultName, setDefaultName] = useState('');
    const [description, setDescription] = useState(null);
    const [defaultDescription, setDefaultDescription] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [editingName, setEditingName] = useState(false);
    const [editingDescription, setEditingDescription] = useState(false);
    const [dueDate, setDueDate] = useState(null);
    const [interaction, setInteraction] = useState(null);
    const [activeProjectsCount, setActiveProjectCount] = useState(0)
    const [requestedProjectsCount, setRequestedProjectsCount] = useState(0)
    const [pointContactId, setPointContactId] = useState(0)
    const [vxPointContactId, vxSetPointContactId] = useState(0)
    const [allowDueDate, setAllowDueDate] = useState(false);
    const [users, setUsers] = useState([]);
    const [showDropdownContact, setShowDropdownContact] = useState(false);
    const [showDropdownAdmin, setShowDropdownAdmin] = useState(false);
    const [isImageSaved, setImageSaved] = useState(false);
    const [uberAdmins, setUberAdmins] = useState([]);

    const [groups, setGroups] = useState([]);
    const [groupsPage, setGroupsPage] = useState(1);
    const [groupsList, setGroupsList] = useState([]);

    const nameRef = useRef(null);
    const closeNameRef = useRef(null);
    const descriptionRef = useRef(null);
    const closeDescriptionRef = useRef(null);
    const groupsSearchRef = useRef(null);
    const commentTextInputRef = useRef(null);

    const apiClient = api ? api : new ApiClient();
    const navigate = useNavigate();

    const [groupsSearchValue, setGroupsSearchValue] = useState('');
    const [groupsDropDownEnabled, setGroupsDropDownEnabled] = useState(false);
    const { Search } = Input;

    const clientNameInputRef = useRef(null);

    useEffect(() => {
        if (editingDescription) {
            commentTextInputRef.current.focus();
        }
    }, [editingDescription]);

    useEffect(async () => {
        await loadClientsDetails()
    }, [clientId]);

    useEffect(async() => {
        await loadUsers()
    }, []);

    useEffect(async() => {
        if (groupsDropDownEnabled) {
            groupsSearchRef.current?.focus();
            await getOrgGroups(groupsPage || 1, '');
        }
    }, [groupsDropDownEnabled]);

    const loadClientsDetails = async () => {
            let client = await apiClient.get().orgsApi.organizationList(
                [],
                [`+name`],
                ['active:true', `id:${clientId}`],
                ['projectCount', 'lastInteraction', 'groups'],
                1,
                1
            );
            client = client.data.data[0];
            if (!client) {
                return navigate(`/clients`);
            }

            const groupsToSave = client.groups?.filter(g => g.id !== null && g.name !== null);

        setActiveProjectCount(client.activeProjectsCount);
        setRequestedProjectsCount(client.requestedProjectsCount);
        setName(client.name);
        setDescription(client.comment);
        setFileName(client.fileName);
        setAllowDueDate(client.dueDate !== null);
        client.dueDate && setDueDate(dayjs(client.dueDate));
        setPointContactId(client.pointOfContactId);
        vxSetPointContactId(client.vxPointOfContactId);
        setInteraction(client.lastInteraction);
        setGroups(groupsToSave?.map(g => ({label: g.name, key: g.id, value: g.id})) ?? []);
        setDefaultDescription(client.comment);
        setDefaultName(client.name);
    }

    const loadUsers = async () => {
        let load = true;
        let loadUbers = true;
        let page = 1;
        let pageUbers = 1;
        const result = [];
        const ubers = [];
        while (load) {
            const clients = await apiClient.get().userApi.userList(
                '',
                [''],
                ['active:true', `organizationId:${clientId}`],
                [],
                page,
                50
            );
                for (const client of clients.data.data) {
                    result.push({
                        id: client.id,
                        roleId: client.roleId,
                        name: `${client.firstName} ${client.lastName}`,
                        fileName: client.fileName
                    });
                }
                page = clients.data.page.next;
                load = clients.data.page.next !== null;
            }
        while (loadUbers) {
            const clients = await apiClient.get().userApi.userList(
                '',
                [''],
                ['active:true', `roleId:${RolesMapper.UBER_ADMIN}`],
                [],
                pageUbers,
                50
            );
            for (const client of clients.data.data) {
                ubers.push({
                    id: client.id,
                    roleId: client.roleId,
                    name: `${client.firstName} ${client.lastName}`,
                    fileName: client.fileName
                });
            }
            pageUbers = clients.data.page.next;
            loadUbers = clients.data.page.next !== null;
        }
            setUsers(result)
            setUberAdmins(ubers);
    }

    useEffect(() => {
        async function handleClickOutsideClientName(event) {
            if (nameRef.current && !nameRef.current.contains(event.target) && closeNameRef && !closeNameRef.current.contains(event.target)) {
                await handleSaveClientName();
            }
        }
        document.addEventListener("mousedown", handleClickOutsideClientName);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideClientName);
        };
    }, [nameRef, name, closeNameRef]);


    useEffect(() => {
        async function handleClickOutsideClientDescription(event) {
            if (descriptionRef.current && !descriptionRef.current.contains(event.target) && closeDescriptionRef && !closeDescriptionRef.current.contains(event.target)) {
                await handleSaveClientComments();
            }
        }
        document.addEventListener("mousedown", handleClickOutsideClientDescription);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideClientDescription);
        };
    }, [closeDescriptionRef, description, descriptionRef]);

    useEffect(() => {
        if (editingName) {
            clientNameInputRef.current.focus();
        }
    }, [editingName]);

    const changeClientName = (value) => {
        setName(value);
    }

    const handleSaveClientName = async () => {
        setEditingName(false);
        if (name?.trim().length > 0) {
            await onClientSave({name});
        } else {
            setName(defaultName);
        }
    };

    const handleEditDescription = () =>{
        setEditingDescription(true);
    }
    const handleCloseEditDescription = () => {
        setDescription(defaultDescription);
        setEditingDescription(false);
    }

    const handleSaveClientComments = async () => {
        setEditingDescription(false);
        if (description?.trim().length === 0) {
            setDescription('');
        }
        await onClientSave({comment: description});
    };

    const handleEditName = () =>{
        setEditingName(true);
    }

    const handleCloseEdit = () => {
        setName(defaultName);
        setEditingName(false);
    }

    const contactIcon = () => {
        return <Radio.Button
            onClick={()=>setShowDropdownContact(true)}
            className="contacts-icon"
            >
            <UserAddOutlined/>
    </Radio.Button>
    }

    const getClientName = () => {
       return <div className="client-name">
            {editingName ?
                <span ref={nameRef}>
                        <Input placeholder="Name" ref={clientNameInputRef} value={name} data-testid="text-name" onChange={(e) => changeClientName(e.target.value)}
                            onPressEnter={handleSaveClientName}
                               onKeyUp={(e) => {
                                      if (e.key === 'Escape') {
                                        handleCloseEdit();
                                      }
                               }}
                        />
                    </span>
                :
                <p onClick={handleEditName} data-testid="edit-name">{name}</p>}
            {!editingName  && <EditOutlined onClick={handleEditName}/>}
            {editingName && <div className="edit-icons" data-testid="edit-icons"><span className="check-outlined">
                    <CheckOutlined data-testid="check" onClick={handleSaveClientName} /> </span><CloseOutlined ref={closeNameRef} data-testid="close" onClick={handleCloseEdit}/>
            </div> }
        </div>
    }

    const getClientComments = () => {
        return <div className={editingDescription ? "description-container active" : "description-container"}>
            <div className="subtitle"><span>Comment</span>
                {!editingDescription && <EditOutlined className="edit-icon" onClick={handleEditDescription}/>}
                {editingDescription ? <div className="edit-icons edit-icons-description"><span className="check-outlined">
                       <CheckOutlined data-testid="save-description" onClick={handleSaveClientComments} /> </span><CloseOutlined ref={closeDescriptionRef} data-testid="close-icon" onClick={handleCloseEditDescription}/>
                </div> : null}
            </div>
                {editingDescription ?
                    <div className="text-area-container">
                        <ClickOutsideComponent onClickOutside={async()=> await handleSaveClientComments()} cssClassName="description-click-container">
                            <span ref={descriptionRef}>
                                 <Input.TextArea rows={4} placeholder="Comment" value={description} data-testid="text-description" onChange={(e) => setDescription(e.target.value)} ref={commentTextInputRef}/>
                            </span>
                        </ClickOutsideComponent>
                    </div> :
                    <>
                        <div onClick={handleEditDescription} className="description" placeholder="Comment" data-testid="description">{description}</div>
                    </>}
        </div>
    }

    const onClientSave = async (fieldToUpdate) => {
        try {
            await apiClient.get().orgsApi.organizationPatch(
                [`id:${clientId}`],
                fieldToUpdate
            );
            await loadClientsDetails();
            message.success(`Client has been successfully updated`);
        } catch (err) {
            message.error('An error occurred while trying to save client');
        }
    }

    const onDeactivate = async() =>{
        try {
            await apiClient.get().orgsApi.organizationPatch([`id:${clientId}`], {active: false});
            message.success('Client has been successfully deactivated');
            navigate(`/clients`);
        } catch (err) {
            message.error(`An error occurred while try to delete client`);
        }
    }

    const getContact = () => {
        const contact = users?.find(u => u?.id === pointContactId)
            return <div onClick={() => setShowDropdownContact(true)}>
                {pointContactId ? pointOptionRenderer(contact, contact?.id) : contactIcon()}
            </div>
    }
    const getAdmin = () => {
        const admin = uberAdmins?.find(u => u?.id === vxPointContactId)
            return <div onClick={() => setShowDropdownAdmin(true)}>
                {vxPointContactId ? pointOptionRenderer(admin, admin?.id) : contactIcon()}
            </div>
    }

    const pointOptionRenderer = (option, index) => {
        return <div className="lead-item" data-testid={`option-item-${index}`}>
            <UserAvatar fileName={option?.fileName} key={option?.name} name={option?.name}/>
            <span style={{marginLeft: '8px'}}>{option?.name}</span>
        </div>
    }

    const onSelectedValueChanged = async (value) => {
        setPointContactId(value)
        await onClientSave({pointOfContactId: value??null});
        setShowDropdownContact(false);
    }
    const onSelectedValueChangedAdmin = async (value) => {
        vxSetPointContactId(value)
        await onClientSave({vxPointOfContactId: value??null});
        setShowDropdownAdmin(false);
    }

    const contactDropDownLabel = users.find(u => u.id === pointContactId) && pointContactId;
    const adminDropDownLabel = uberAdmins.find(u => u.id === vxPointContactId) && vxPointContactId;

    const renderSearchableSelect = (selectedValueLabel, onSelectedValueChanged, options, onDropdownVisibleChange) => (
        <SearchableSelect
            selectedValueLabel={selectedValueLabel}
            className={`contact-select`}
            onSelectedValueChanged={onSelectedValueChanged}
            options={options}
            allowClear={true}
            optionsRenderer={pointOptionRenderer}
            onDropdownVisibleChange={onDropdownVisibleChange}
            defaultOpen={true}
            selectEmptyDropdown={true}
        />
    );

    const onImageUploaded = async () => {
        setImageSaved(false);
        await loadClientsDetails();
    }

    const handleGroupsDropdownVisibility = async (isOpen) => {
        if (!isOpen) {
            setGroupsDropDownEnabled(false);
        }
    }

    const getOrgGroups = async (page, text) => {
        if (page === 1) {
            setGroupsPage(1);
        }
        const loadedGroups = await apiClient.get().groupsApi.groupsList(
            text !== undefined ? text : groupsSearchValue,
            ['+name'],
            ['active: true'],
            [],
            page ?? groupsPage,
            10
        );

        const groupItems = loadedGroups.data.data.map(g => ({id: g.id, label: g.name, value: g.id, title: g.name, key: g.id}));
        setGroupsList(page > 1 ? [...groupsList ,...groupItems] : groupItems);
        groupsDropDownHold = false;
    }

    async function onScroll(e) {
        const scrolledDown = parseInt(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight;
        if (scrolledDown) {
            setGroupsPage(groupsPage + 1);
            await getOrgGroups(groupsPage + 1);
        }
    }

    const onGroupSelected = async (item) => {
        if (groupsDropDownHold) {
            groupsDropDownHold = false;
            return;
        }
        const groupsCopy = [...groups];
        groupsCopy.push(item);
        setGroups(groupsCopy);
        await apiClient.get().organizationsGroupsApi.organizationsGroupsCreate({
            organizationId: clientId,
            groupId: item.value
        });
    }

    const onGroupDeselected = async (item) => {
        if (groupsDropDownHold) {
            groupsDropDownHold = false;
            return;
        }
        setGroups(groups.filter(g => g.value !== item.value));
        await apiClient.get().organizationsGroupsApi.organizationsGroupsDeleteMany([[`organizationId:${clientId}`], `groupId:${item.value}`]);
    }

    const handleGroupsSearchValue = (e) => {
        setGroupsSearchValue(e.target.value);
    }

    const getGroupsSelectSearch = (menu) => (
        <>
            <Search placeholder="Search" style={{ height: 24 }}
                    ref={groupsSearchRef}
                    className="options-search"
                    data-testid="options-search"
                    onSearch={async () => { groupsDropDownHold = true; await getOrgGroups(1)}}
                    value={groupsSearchValue}
                    onChange={handleGroupsSearchValue}
                    onKeyDown={onGroupsSearchKeyDown}
            />
            {menu}
        </>
    )

    const onGroupsSearchKeyDown = (e) => {
        if (e.key === 'Backspace' || e.key === 'Enter') {
            groupsDropDownHold = true;
        }
    }

    const showGroupsDropDown = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!groupsDropDownEnabled) {
            setGroupsSearchValue('');
            await getOrgGroups(1, '');
            setGroupsDropDownEnabled(true);
        }
    }

    return <div className="client-page" data-testid="clients-page">
        <div className="client-summary-header">
            <div>
                <ArrowLeftOutlined onClick={() => navigate(`/clients`)} style={{ fontSize: '17px', color: '#262626', cursor: 'pointer' }}  /> <span className="client-name">{name || 'Client Name'}</span>
            </div>
            <div>
                <Button icon={<DeleteOutlined onClick={() => showDeactivateConfirm('company', onDeactivate, true)} style={{ fontSize: '12px', color: '#F5222D' }}/>} danger></Button>
            </div>
        </div>
        <div>
            <div className="client-summary">
                <span className="separator-header"></span>
                <div className="form-header">
                    {getClientName()}
                </div>
                <div className="client-summary-view">
                    <div>
                        <Form
                            layout="vertical"
                            className="client-view-edit-form"
                        >
                            <>
                                <Form.Item className="form-item">
                                    <Button className="visit-space" type="primary" ghost data-testid="save-client"
                                            onClick={() => navigate(`/clients/${clientId}/projects`)}><ExportOutlined/>Visit
                                        company space</Button>
                                </Form.Item>
                                <Form.Item className="form-item">{getClientComments()}</Form.Item>
                                <UploadImage bucketName={'organizations'} api={api} id={clientId}
                                             onImageUploaded={onImageUploaded} isImageSaved={isImageSaved}
                                             setImageSaved={setImageSaved} prevFileName={fileName} width={326}
                                             height={144} name={name[0]?.toUpperCase()}
                                             uploadText={"To change, drop a profile picture here"}/>
                            </>
                        </Form>
                    </div>
                    <div>
                        <span className="separator"></span>
                        <div className="client-summary-right-column">
                            <div className="title">Company details</div>
                            <div className="label-wrapper">
                                <span>Last interaction</span>
                                <div className="label-text">{interaction ? formatDateNoTime(interaction) : ''}</div>
                            </div>
                            <div className="label-wrapper">
                                <span>Point of contact</span>
                                {users.length > 0 && !showDropdownContact && getContact()}
                                {showDropdownContact && renderSearchableSelect(contactDropDownLabel, onSelectedValueChanged, users, setShowDropdownContact)}
                                {!users.length && !showDropdownContact && getContact()}
                            </div>

                            <div className="label-wrapper">
                                <span>vX Point of contact</span>
                                {!showDropdownAdmin && (uberAdmins.length > 0 || !uberAdmins.length) && getAdmin()}
                                {showDropdownAdmin && renderSearchableSelect(adminDropDownLabel, onSelectedValueChangedAdmin, uberAdmins, setShowDropdownAdmin)}
                            </div>
                            <div>
                                <div className={"label-wrapper"}>
                                     <span>
                                     <Checkbox checked={allowDueDate}
                                               onChange={(e) => setAllowDueDate(e.target.checked)}>
                                     Special Due Date
                                     </Checkbox>
                                    </span>
                                    <DatePicker className="date-picker" onChange={async (date) => {
                                        setDueDate(date);
                                        await onClientSave({dueDate: date});
                                    }} disabled={!allowDueDate} value={dueDate} format={customFormat}/>
                                </div>
                            </div>
                            <div className={groups.length > 0 ? "label-wrapper group" : "label-wrapper"}
                                 onClick={(e) => showGroupsDropDown(e)}
                                 onMouseDown={(e) => {e.preventDefault(); e.stopPropagation()}}
                                 data-testid="groups-label"
                            >
                                <span className="belongs-group">Belongs to groups</span>
                                <div className="group-wrapper">
                                    <div style={{width: 'calc(100% - 20px)'}}>
                                    {groupsDropDownEnabled && <Select
                                        dropdownRender={getGroupsSelectSearch}
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        labelInValue={true}
                                        className="searchable-select"
                                        popupClassName="searchable-select-popup"
                                        data-testid="searchable-groups-select"
                                        value={groups}
                                        onSelect={onGroupSelected}
                                        onDeselect={onGroupDeselected}
                                        searchValue=''
                                        options={groupsList}
                                        open={groupsDropDownEnabled}
                                        onPopupScroll={onScroll}
                                        onDropdownVisibleChange={handleGroupsDropdownVisibility}
                                    />}
                                    {(!groupsDropDownEnabled && groups.length === 0) && <div>None</div>}
                                    {(!groupsDropDownEnabled && groups.length > 0) && [...new Set(groups)].sort((a, b) => a.label.localeCompare(b.label)).map(g => (
                                        <p key={g.value}><FolderOpenOutlined className="icon-folder"/>{g.label}</p>
                                    ))
                                    }
                                    </div>
                                    {!groupsDropDownEnabled && <span className="groups-edit">
                                       <EditOutlined data-testid="groups-edit" style={{cursor: 'pointer'}} />
                                    </span>}
                                </div>
                            </div>
                            <div className="title">Projects</div>
                            <div className="label-wrapper requested">
                                <span>Requested</span>
                                <div className="request-wrapper">
                                    <p className="label-text">{requestedProjectsCount}</p>
                                </div>
                                <Button className="see-projects" type="primary" size="small" ghost data-testid="see-requested-projects"
                                        onClick={() => navigate(`/projects?client=${clientId}&status=requested`)}><ExportOutlined/>
                                    See projects
                                </Button>
                            </div>
                            <div className="label-wrapper active">
                                <span>Active</span>
                                <div className="request-wrapper">
                                    <p className="label-text">{activeProjectsCount}</p>
                                </div>
                                <Button className="see-projects" type="primary" size="small" ghost data-testid="see-active-projects"
                                        onClick={() => navigate(`/projects?client=${clientId}&status=active`)}><ExportOutlined/>
                                    See projects
                                </Button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ClientSummary;