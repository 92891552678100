import {Modal} from 'antd';
import {useState} from 'react';
import ModalInputItem from '../modalInputItem';
import ModalTextAreaItem from '../modalTextAreaItem';
import './folderModalWindow.css';

const FolderModalWindow = ({isModalOpen, handleCancel, onDocumentCreate}) => {
  const [documentName, setDocumentName] = useState('');
  const [documentDescription, setDocumentDescription] = useState('');

  function changeDocumentName(event) {
    setDocumentName(event.target.value);
  }

  function changeDocumentDescription(event) {
    setDocumentDescription(event.target.value);
  }

  function clearInputs() {
    setDocumentName('');
    setDocumentDescription('');
  }

  function onCloseModal() {
    clearInputs();
    handleCancel();
  }

  async function onOk() {
    await onDocumentCreate(documentName, documentDescription, "folder", null);
    onCloseModal();
  }

  return (
    <Modal data-testid="documents-page-folder-modal-window" className="documents-page-folder-modal-window" title="Add a new folder"
           open={isModalOpen} onOk={onOk} onCancel={onCloseModal} centered width={390} okText='Add'
           okButtonProps={{ disabled: !documentName, className: 'width-90' }} cancelButtonProps={{ className: 'width-90' }}>
      <ModalInputItem title="Folder name" value={documentName} onChange={changeDocumentName}/>
      <ModalTextAreaItem title="Description" value={documentDescription} onChange={changeDocumentDescription} height={160}/>
    </Modal>
  );
};

export default FolderModalWindow;
