import './masterDataSets.css'
import { Input, Table, Button, Tag, Space, Checkbox } from 'antd';
import { PlusOutlined, SettingOutlined } from '@ant-design/icons';
import {formatDate} from "../utils";
import SteamPipe from "../../assets/images/steam-pipe-user.svg";
import Osquery from "../../assets/images/osquery.svg";
import Dots from "../../assets/images/dots-devices.svg";

export default function MasterDataSets() {
    const { Search } = Input;


    const columns = [
        {
            title: 'Dataset name',
            dataIndex: 'Dataset-name',
            key: 'dataSetName',
            width: '30%',
            render: (_, { dataSetName }) => {
                return (
                    <>
                        <a target="_blank">{dataSetName}</a>
                    </>
                )
            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            align: 'left',
            width: '7.5%',
            render: (_, { type }) => {
                return (
                    <>
                        <Tag color={'gold'} key={type}>
                            {type}
                        </Tag>
                    </>
                )},
        },
        {
            title: 'Owner',
            dataIndex: 'owner',
            key: 'owner',
            align: 'left',
            width: '7.9%',
        },
        {
            title: 'Sensitive',
            key: 'sensitive',
            align: 'left',
            width: '7.9%',
            render: (_, { sensitive }) => {
                if(sensitive){
                    return (
                        <Checkbox className={"checkbox-custom"} checked={sensitive}/>
                    )
                }
        }
        },
        {
            title: 'Source',
            dataIndex: 'source',
            key: 'source',
            align: 'left',
            width: '7.5%',
            render: (_, { source }) => {
                let color;
                let title;
                if(source==='Manual') {
                    color = 'geekblue';
                    title = 'Manual';
                } else if(source==='Scanners') {
                    color = 'cyan';
                    title = 'Scanners';
                }

                return (
                    <>
                        <Tag color={color} key={source}>
                            {title}
                        </Tag>
                    </>
                )},
        },
        {
            title: 'Last updated by',
            dataIndex: 'last-update',
            key: 'lastUpdateUser',
            className: 'table-action',
            width: '11.2%',
            render: (_, { lastUpdateUser }) => {
                    return <>
                        <div className={"scanners-image"}>
                            <img src={lastUpdateUser[0]} alt={""}/>
                            <p>{lastUpdateUser[1]}</p>
                        </div>
                    </>
            },
        },
        {
            title: 'Last updated',
            dataIndex: 'last-update',
            align: 'right',
            key: 'lastUpdateDate',
            className: 'table-action',
            width: '14%',
            render: (_, { lastUpdateDate }) => {
                return (
                    formatDate(lastUpdateDate)
                )
            },
        },
        {
            title: 'Update Status',
            dataIndex: 'update-status',
            key: 'updateStatus',
            className: 'table-action',
            width: '10.3%',
            render: (_, { updateStatus }) => {
                let color;
                let title;
                if(updateStatus==='Approved') {
                    color = 'blue';
                    title = 'Approved';
                } else if(updateStatus==='Pending') {
                    color = 'orange';
                    title = 'Pending';
                } else if(updateStatus==='Automatically') {
                    color = 'cyan';
                    title = 'Automatically';
                }
                return (
                    <>
                        <Tag color={color} key={updateStatus}>
                            {title}
                        </Tag>
                    </>
                )},
        },
        {
            title: '',
            dataIndex: 'actionItems',
            key: 'actionItems',
            render: (text, record, index) => {
                return <a data-testid={`dots-menu-${index}`} className="action-menu-container">
                    <Space>
                        <img src={Dots} alt=""/>
                    </Space>
                </a>
            },
            className: 'table-action',
            width: '3.55%'
        }
    ];


    const data = [
        {
            key: '1',
            id: 1,
            dataSetName: 'People and permissions',
            type: 'Master',
            owner: 'Alice',
            sensitive: true,
            source: 'Manual',
            lastUpdateUser: [SteamPipe, 'Steampipe'],
            lastUpdate: "time",
            updateStatus: "Approved"
        },
        {
            key: '2',
            id: 2,
            dataSetName: 'Devices',
            type: 'Master',
            owner: 'Alice',
            sensitive: false,
            source: 'Manual',
            lastUpdateUser: [Osquery, 'OSQuery'],
            lastUpdate: "time",
            updateStatus: "Pending"
        },
        {
            key: '3',
            id: 3,
            dataSetName: 'Applications',
            type: 'Master',
            owner: 'Alice',
            sensitive: false,
            source: 'Scanners',
            lastUpdateUser: [Osquery, 'OSQuery'],
            lastUpdate: "time",
            updateStatus: "Automatically"
        },
        {
            key: '4',
            id: 4,
            dataSetName: 'Clouds',
            type: 'Master',
            owner: 'Alice',
            sensitive: false,
            source: 'Manual',
            lastUpdateUser: [Osquery, 'OSQuery'],
            lastUpdate: "time",
            updateStatus: "Pending"
        },
        {
            key: '5',
            id: 5,
            dataSetName: 'Devices vulnerabilities',
            type: 'Master',
            owner: 'Alice',
            sensitive: false,
            source: 'Scanners',
            lastUpdateUser: [Osquery, 'OSQuery'],
            lastUpdate: "time",
            updateStatus: "Automatically"
        },
        {
            key: '6',
            id: 6,
            dataSetName: 'Clouds vulnerabilities',
            type: 'Master',
            owner: 'Alice',
            sensitive: false,
            source: 'Scanners',
            lastUpdateUser: [SteamPipe, 'Steampipe'],
            lastUpdate: "time",
            updateStatus: "Automatically"
        },
    ];



return <div className={"master-container"}>
    <h3 className={"master-title"}>Master dataset</h3>
    <p className={"master-description"}>Master dataset is a collection of all security-related data in an organisation, "single source of truth" for security data.</p>
    <div className="search-container">
        <div>
            <Search placeholder="Datasets search" className="search-input" data-testid="datasets-search" disabled={true}/>
        </div>
        <div className="command-buttons">
            <Button type="primary" className='add' icon={<PlusOutlined style={{ fontSize: '11px'}}/>} disabled={true}>Add</Button>
            <Button icon={<SettingOutlined style={{ fontSize: '12px'}} />} disabled={true} />
        </div>
    </div>
    <div className='filter'>
        <div> <span className='plus'>+</span>Add Filter</div>
    </div>
    <div className="table-wrapper">
        <Table
            columns={columns}
            dataSource={data}
            rowKey={'id'}
        />
    </div>
</div>
}