import './queryEditorPage.css';
import {useEffect, useState} from "react";
import {Tabs} from 'antd';
import openedDebugQueriesState from '../../atoms/openedDebugQueries';
import deletedQueryState from '../../atoms/deletedQuery';
import {useRecoilState, useSetRecoilState} from "recoil";
import queryEditorSelectedQueryState from "../../atoms/queryEditorSelectedQuery";
import queryUpdatedState from "../../atoms/queryUpdated";
import QueryEditor from "../queryEditor/queryEditor";
import forceMappingUpdateState from "../../atoms/forceMappingUpdate";

const EditorWrapper = ({
                           loadQueries,
                           scanTopic,
                           distQuery,
                           queryId,
                           orgId,
                           api,
                           onSaveQuery,
                           scanType,
                           isVioletXOnly
                       }) => {

    const [openedDebugQueries, ] = useRecoilState(openedDebugQueriesState);
    const openedQuery = openedDebugQueries.queries.find(q => q.query.id === queryId);

    if (!openedQuery) {
        return null;
    }

    return <QueryEditor scanType={scanType} isVioletXOnly={isVioletXOnly} onSaveQuery={onSaveQuery} loadQueries={loadQueries} useSplitter={true} scanTopic={scanTopic} distQuery={distQuery} query={openedQuery.query} orgId={orgId} api={api}/>
}

const QueryEditorPage = ({api, orgId, scanTopic, loadQueries, onSaveQuery, scanType, isVioletXOnly}) => {

    const [openedDebugQueries, setOpenedDebugQueries] = useRecoilState(openedDebugQueriesState);
    const [deletedQuery, ] = useRecoilState(deletedQueryState);
    const [queryEditorSelectedQuery, setQueryEditorSelectedQuery] = useRecoilState(queryEditorSelectedQueryState);
    const [queryUpdated, ] = useRecoilState(queryUpdatedState);
    const [tabs, setTabs] = useState([]);

    const buildTabs = (openedState) => {

        const currentTabs = [];

        openedState.queries.forEach((q) => {

            const currentTab = tabs.find(t => t.queryId === q.query.id);

            if (currentTab) {
                currentTab.label = q.query.name;
                currentTabs.push(currentTab);
                return;
            }

            const copy = JSON.parse(JSON.stringify(q.query));
            const distQuery = JSON.parse(JSON.stringify(q.distQuery));

            const tabItem = {
                label: q.label,
                key: q.key,
                queryId: q.query.id,
                children: <EditorWrapper onSaveQuery={onSaveQuery} distQuery={distQuery} loadQueries={loadQueries} orgId={orgId} queryId={copy.id} scanTopic={scanTopic} api={api} scanType={scanType} isVioletXOnly={isVioletXOnly} />
            }
            currentTabs.push(tabItem);
        });

        setTabs(currentTabs);
    }
    const setForceQueryUpdate = useSetRecoilState(forceMappingUpdateState);

    const getOpenedQueriesState = () => {
        const openedQueries = [...openedDebugQueries.queries];
        const queriesState = JSON.parse(JSON.stringify(openedDebugQueries));
        queriesState.queries = openedQueries;
        return queriesState;
    }

    const onChange = (newActiveKey) => {
        setForceQueryUpdate(null);
        setQueryEditorSelectedQuery(newActiveKey);
        const queriesState = getOpenedQueriesState();
        queriesState.activeKey = newActiveKey;
        setQueryEditorSelectedQuery(newActiveKey);
        setOpenedDebugQueries(queriesState);
    };

    useEffect(() => {
        if (openedDebugQueries.activeKey !== null) {
            buildTabs(openedDebugQueries);
        }
    }, [openedDebugQueries.activeKey, queryUpdated, queryEditorSelectedQuery]);

    const remove = (targetKey) => {
        let newActiveKey = openedDebugQueries.activeKey;
        let lastIndex = openedDebugQueries.queries.findIndex(q => q.key === targetKey);
        const newPanes = openedDebugQueries.queries.filter((item) => item.key !== targetKey);
        if (newPanes.length) {
            if (lastIndex > 0) {
                newActiveKey = newPanes[lastIndex - 1].key;
            } else {
                newActiveKey = newPanes[0].key;
            }
        }
        const queriesState = getOpenedQueriesState();
        queriesState.activeKey = newActiveKey;
        queriesState.queries = newPanes.length > 0 ? newPanes : [-1];
        setOpenedDebugQueries(queriesState);
        setQueryEditorSelectedQuery(newActiveKey);
        buildTabs({queries: newPanes});
    };

    const onEdit = (targetKey, action) => {
        if (action === 'remove') {
            remove(targetKey);
        }
    };

    useEffect(() => {
        if (deletedQuery !== null) {
            remove(`opened-query-${deletedQuery.id}`);
        }
    }, [deletedQuery]);

    return <div className="query-editor-page" data-testid="query-editor-page">
            <Tabs
                type="editable-card"
                onChange={onChange}
                defaultActiveKey={queryEditorSelectedQuery}
                activeKey={queryEditorSelectedQuery}
                onEdit={onEdit}
                items={tabs}
                hideAdd={true}
                tabBarStyle={{
                    height: '34px'
                }}
            />
    </div>
}

export default QueryEditorPage;