import './cloudPlatforms.css';
import React, { useEffect, useState} from "react";
import ApiClient from "../../apiClient";
import {useNavigate, useParams} from "react-router-dom";
import Dots from "../../assets/images/dots-devices.svg";
import {Dropdown, Space, Table, Modal, message, Statistic, Button, Input} from 'antd';
import noClients from "../../assets/images/no-clients.svg";
import { DeleteOutlined, ExclamationCircleOutlined, PlusOutlined, SettingOutlined, ExportOutlined, SendOutlined, EditOutlined } from '@ant-design/icons';
import {formatDate, showDeactivateConfirm} from "../utils";

let page = 1;
const limit = 20;
let searchValue = '';

const CloudPlatforms = ({api}) => {

    const apiClient = api || new ApiClient();

    const [clouds, setClouds] = useState([]);
    const [cloudsStatus, setCloudsStatus] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [isCloudsLoaded, setIsCloudsLoaded] = useState(true);
    const [isEmptyPage, setIsEmptyPage] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const navigate = useNavigate();
    const {orgId} = useParams();

    const { Search } = Input;

    const columns = [
        {
            title: 'Account Name',
            dataIndex: 'accountName',
            key: 'accountName',
            align: 'left',
            render: (text, record, index) => {
                return <a data-testid={`accountName-${index}`} onClick={() => redirectToSummary(record)}>{text}</a>
            }
        },
        {
            title: 'Platform',
            dataIndex: 'platform',
            key: 'platform',
            align: 'left'
        },
        {
            title: 'Region',
            dataIndex: 'region',
            key: 'region',
            align: 'left'
        },
        {
            title: 'Last scanned',
            dataIndex: 'lastScanned',
            key: 'lastScanned',
            align: 'right'
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (text, record, index) => {
                const items = [
                    { key: `open-edit-${index}`, title: 'Open and edit', label: <div data-testid={`edit-${index}`}  onClick={() => redirectToSummary(record)}>Open and edit</div>, icon: <EditOutlined />},
                    { key: `deactivate-${index}`, title: 'Deactivate', label: <div data-testid={`deactivate-${index}`}  onClick={() => showDeleteDeviceConfirm(record)}>Delete</div>, icon: <DeleteOutlined />, danger: true},
                ];
                return <Dropdown trigger='click' menu={{items}}>
                    <a data-testid={`dots-menu-${index}`} className="action-menu-container">
                        <Space>
                            <img src={Dots} alt=""/>
                        </Space>
                    </a>
                </Dropdown>
            },
            className: 'table-action',
            width: 50
        }
    ];

    const loadClouds = async (search) => {
        setSelectedRowKeys([]);
        setSelectedRow([]);
        const searchData = search || searchValue;
        setIsCloudsLoaded(false);
        const searchItems = searchData.split(' ');
        const loadedClouds = await apiClient.get().devicesApi.deviceList(
            searchItems,
            [],
            ['active:true', 'scanTopic:CLOUDS', `organizationId:${orgId}`],
            ['userInfo'],
            page,
            limit
        );

        const convertedClouds = loadedClouds.data.data.map((item, index) => {
            return {
                id: item.id,
                key: `cloud-${item.id}-${index}`,
                accountName: `${item.deviceInfo.name}`,
                platform: item.deviceType.toUpperCase(),
                region: JSON.parse(item.deviceConfig)?.regions,
                lastScanned: !item.timestamp ? 'Unknown' : formatDate(item.timestamp)
            }
        });
        setClouds(convertedClouds);
        setTotalPages(loadedClouds.data.page.total);
        if (convertedClouds.length === 0) {
            setIsEmptyPage(true);
        } else {
            setIsEmptyPage(false);
        }
        setIsCloudsLoaded(true);
    }

    const handlePagination = async (currentPage) => {
        page = currentPage;
        setSelectedRow([]);
        setSelectedRowKeys([]);
        await loadClouds();
    }

    const redirectToSummary = (item) => {
        navigate(`/clients/${orgId}/assets/clouds/${item.id}/cloud-summary`);
    }

    const onCloudDelete = async (item) => {
        const filter = [];
        if (item) {
            filter.push(`id:${item.id}`);
        } else {
            selectedRow.forEach(el => filter.push(`id:${el}`));
        }
        const plural = filter.length <= 1 ? '' : 's';
        try {
            await apiClient.get().devicesApi.devicePatch(filter, {active: false});
            message.success(`Cloud${plural} has been successfully deleted`);
            if ((totalPages - filter.length) === ((page - 1) * limit) && page !== 1) {
                page -= 1;
                await loadClouds();
            } else {
                await loadClouds();

            }
            await loadStatus();
            setSelectedRowKeys([]);
            setSelectedRow([]);
        } catch (err) {
            message.error(`An error occurred while try to delete cloud${plural}`);
        }

    }

    const showDeleteDeviceConfirm = (item) => {
        const deviceTitle = selectedRow.length <= 1 ? 'cloud' : 'clouds';
        showDeactivateConfirm(deviceTitle, () => onCloudDelete(selectedRow.length > 0 ? null : item));
    }

    const onSearch = async (value) => {
        searchValue = value;
        page = 1;
        await loadClouds();
    }

    const loadStatus = async () => {
        const status = await apiClient.get().devicesApi.deviceStatus(
            '',
            [``],
            ['active:true', 'scanTopic:CLOUDS', `organizationId:${orgId}`],
            1,
            20
        );
        setCloudsStatus(status.data.data[0]);
    }

    useEffect(async () => {
        searchValue = '';
        page = 1;
    }, []);

    useEffect(async () => {
        await loadClouds('');
        await loadStatus();
    }, [orgId]);

    const onRowChange =  (selectedRowKeys, selectedRows) => {
        const selectedUsers = [];
        selectedRows.map(el =>{
            selectedUsers.push(el.id);
        } )
        setSelectedRow(selectedUsers);
        setSelectedRowKeys(selectedRowKeys)
    }

    const redirectToAddCloud = () => {
        navigate(`/clients/${orgId}/assets/clouds/add-cloud`);
    }

    return <div className="clouds" data-testid="clouds">
        <h1>Cloud platforms</h1>
        <div className="stats-container">
            <div>
                <Statistic title="Clouds" value={cloudsStatus?.total} />
            </div>
            <div>
                <Statistic title="Safe" value='816' />
            </div>
            <div>
                <Statistic title="At risk" value='816' />
            </div>
        </div>
        <div className="search-container">
            <div>
                <Search disabled={isEmptyPage && searchValue.length === 0} placeholder="Clouds search" className="search-input" data-testid="cloud-search" onSearch={onSearch} />
            </div>
            <div className="command-buttons">
                <Button icon={<SendOutlined style={{ fontSize: '12px'}} />} disabled={true} />
                <Button icon={<DeleteOutlined style={{ fontSize: '12px'}} />} danger data-testid="delete-cloud" disabled={selectedRow?.length < 1} onClick={showDeleteDeviceConfirm} />
                <Button icon={<ExportOutlined style={{ fontSize: '12px'}} />} disabled={true} />
                <Button onClick={redirectToAddCloud} type="primary" className='add' icon={<PlusOutlined style={{ fontSize: '11px'}} />} data-testid="add-user" disabled={false}>Add</Button>
                <Button icon={<SettingOutlined style={{ fontSize: '12px'}} />} disabled={true} />
            </div>
        </div>
        <div className='filter'>
            <div> <span className='plus'>+</span>Add Filter</div>
        </div>

        {!(isEmptyPage && searchValue.length <= 0) && <div className={isEmptyPage ? 'empty-container' : ''}>
            <Table
                rowSelection={{
                    selectedRowKeys: selectedRowKeys,
                    onChange: onRowChange
                }}
                dataSource={clouds}
                columns={columns}
                scroll={{y: 'calc(100vh - 420px)'}}
                loading={!isCloudsLoaded}
                pagination={
                    {
                        current: page,
                        total: totalPages,
                        showSizeChanger: false,
                        pageSize: 20,
                        onChange: handlePagination
                    }
                }
            />
        </div>}

        {isEmptyPage && <div data-testid="empty-list" className="empty-list-page-wrapper">
            <div className="empty-list-page">
                <div className="clients-empty">
                    <div>
                        <img src={noClients} alt=""/>
                    </div>
                    <div>Cloud list is empty</div>
                    <div>This company is ready for adding new cloud platforms.</div>
                    <div>
                        <Button className="add" data-testid="add-client-button" onClick={() => navigate(`/clients/${orgId}/assets/clouds/add-cloud`)}>Add new cloud</Button>
                    </div>
                </div>
            </div>
        </div>}
    </div>
}

export default CloudPlatforms;