import VendorsController from "../controllers/vendorsController";

const useVendorsOperation = (api) => {

    const vendorsController = new VendorsController({apiClient: api});

    const listVendors = async (page, searchValue, listFilter, limit, expand) => {
        return await vendorsController.vendorList(page, searchValue, listFilter, limit, expand);
    }
    const deleteVendorsLinkage = async (filter) => {
        await vendorsController.deleteVendorsLinkage(filter);
    }

    return [listVendors, deleteVendorsLinkage];
};

export default useVendorsOperation;