import {Input, Button} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {useEffect, useRef, useState} from "react";

const QueryEditItem = ({query, onSaveTitle, onCancelEdit, disabled}) => {

    const [queryName, setQueryName] = useState(query.name);
    const wrapperRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                saveTitle();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, queryName]);

    const saveTitle = () => {
        if (queryName.trim().length > 0) {
            onSaveTitle(query, queryName.trim());
        }
    }

    return <div className="query-edit-item" ref={wrapperRef}>
        <Input
            disabled={disabled}
            defaultValue={queryName}
            style={{
                width: '128px',
                height: '24px'
            }}
            ref={inputRef}
            data-testid={`query-name-input-${query.id}`}
            onChange={(e) => setQueryName(e.target.value)}
            onPressEnter={saveTitle}
            onKeyUp={(e) => {
                if (e.key === 'Escape') {
                    onCancelEdit();
                }
            }}
            status={queryName.trim().length === 0 ? 'error' : ''}
        />
        <div className="query-icons">
            <Button icon={ <CheckOutlined />} disabled={disabled} data-testid={`query-save-button-${query.id}`} onClick={saveTitle} type="primary" style={{ width: '24px', height: '24px', marginRight: '4px'}}/>
            <Button icon={ <CloseOutlined />} disabled={disabled} onClick={onCancelEdit} style={{ width: '24px', height: '24px'}} />
        </div>
    </div>
}

export default QueryEditItem;