import React, {useEffect, useRef, useState} from 'react';
import PCIcon from '../../assets/images/pc-icon.svg';
import InventoryClose from '../../assets/images/inventory-close.svg';
import InventoryRemove from '../../assets/images/inventory-remove.svg';
import Cloud from '../../assets/images/cloud.svg';
import {Button, message, Tree, Input} from 'antd';
import * as utils from '../utils';
import './queryInventory.css';
import {PlusOutlined} from '@ant-design/icons';
import ClickOutsideComponent from "../clickOutsideHandler/clickOutsideHandler";
import {devicePlatformName, devicePlatformSmallIcon} from "../utils";

const QueryInventory = ({query, inventoryName, scanTopic, api, orgId, isDisabled, platforms }) => {

    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [treeState, setTreeState] = useState({
        devices: [],
        checkedKeys: [],
        expandedKeys: [],
        users: [],
        page: 1,
        loadedDevices: {},
        searchValue: []
    });

    const { Search } = Input;

    const treeRef = useRef(null);

    const showHidePopup = () => {
        if (isDisabled) {
            return;
        }
        setIsPopupVisible(!isPopupVisible);
    }

    useEffect(async () => {
        if (query) {
            await loadInventoryItems();
            await loadItems();
        }
    }, [query]);

    useEffect(async () => {
        if (!query) {
            return;
        }
        if (!isPopupVisible) {
            setTreeState({
                devices: [],
                checkedKeys: [],
                expandedKeys: [],
                users: [],
                page: 1,
                loadedDevices: {},
                searchValue: []
            });
        }
        await loadItems();
    }, [isPopupVisible, query])

    const getSortedItems = (items) => {
        return items.sort((a, b) => {
            const sortStringA = `${a.lastName}${a.deviceType}`;
            const sortStringB = `${b.lastName}${b.deviceType}`;
            return sortStringA.localeCompare(sortStringB);
        });
    }

    const getItemsForDevices = (loadedUsers, loadedDevices) => {

        const result = [];

        loadedUsers.forEach((user) => {

            const item = {
                title: `${user.firstName} ${user.lastName}`,
                key: `user-${user.id}`,
                id: user.id,
                type: 'user',
                children: [],
            };

            const userDevices = loadedDevices[user.id];

            if (userDevices) {
                userDevices.forEach((d) => {
                    item.children.push({
                        title: utils.devicePlatformName(d.deviceType),
                        key: `device-${d.id}`,
                        id: d.id,
                        user: user,
                        type: 'device',
                    });
                });
            }
            result.push(item);
        });

        return result;
    }

    const getItemsForClouds = (loadedDevices) => {
        const result = [];
        for (const [, d] of Object.entries(loadedDevices)) {
            result.push({
                title: `${d.deviceInfo.name} ${utils.devicePlatformName(d.deviceType)}`,
                key: `device-${d.id}`,
                id: d.id,
                user: {id: d.userId, organizationId: d.organizationId},
                type: 'device',
            });
        }
        return result;
    };

    const getDataForDevices = async (page) => {
        const response = await api.get().userApi.userList(
            treeState.searchValue,
            ['+lastName','+firstName'],
            ['active:true',
                `organizationId:${orgId}`,
                `distributedQueryId:${query.distributedQueryId}`,
                `scanTopic:${scanTopic}`],
            ['deviceFilter', 'distributedQueryCount'],
            page,
            20
        );
        return response.data;
    }

    const getDataForClouds = async (page) => {
        const response = await api.get().devicesApi.deviceList(
            treeState.searchValue,
            ['+lastName','+firstName'],
            ['active:true', `scanTopic:${scanTopic}`, `organizationId:${orgId}`],
            ['userInfo'],
            page,
            20
        );
        return response.data;
    }

    const loadItems = async (reset) => {

        if (treeState.page === null && !reset) {
            return;
        }

        setIsDataLoaded(false);

        let loadedUsers = JSON.parse(JSON.stringify(treeState.users));
        let loadedDevices = JSON.parse(JSON.stringify(treeState.loadedDevices));
        let checkedKeys = [];
        let expandedKeys = JSON.parse(JSON.stringify(treeState.expandedKeys));
        let page = treeState.page;

        if (reset) {
            expandedKeys = [];
            loadedUsers = [];
            loadedDevices = [];
            page = 1;
        }

        let responseData;

        if (scanTopic === 'DEVICES') {
            responseData = await getDataForDevices(page);
            responseData.data.forEach(user => {
                const filteredDevice = user.deviceFilter.filter(device => device.deviceType);
                if (filteredDevice.length === 0) {
                    return;
                }
                loadedUsers.push(user);
                expandedKeys.push(`user-${user.id}`);
                loadedDevices[user.id] = filteredDevice;
            });

        } else {
            responseData = await getDataForClouds();
            responseData.data.forEach(device => {
                expandedKeys.push(`device-${device.id}`);
                loadedDevices[device.id] = device;
            });
        }

        const items = scanTopic === 'CLOUDS'
            ? getItemsForClouds(loadedDevices)
            :  getItemsForDevices(loadedUsers, loadedDevices);

        selectedDevices.forEach(s => {
            checkedKeys.push(`device-${s.deviceId}`);
        });

        setTreeState({
            devices: items,
            checkedKeys: checkedKeys,
            expandedKeys: expandedKeys,
            users: loadedUsers,
            page: responseData.page.next,
            loadedDevices: loadedDevices,
            searchValue: treeState.searchValue
        });

        setIsDataLoaded(true);
    }

    const removeFromInventory = async (deviceId) => {
        if (isDisabled) {
            return;
        }
        await removeDevice({id: deviceId});
        await loadInventoryItems();
        if (isPopupVisible) {
            showHidePopup();
        }
    }

    const getInventoryItems = () => {

        const sorted = getSortedItems(selectedDevices);

        return sorted.map((i, k) =>
            <div data-testid="inventory" className="inventory-item" key={k}>
                <img alt="" src={scanTopic === "CLOUDS" ? Cloud : PCIcon}/>
                <span>
                    {
                        scanTopic === "CLOUDS"
                            ? <span title={`${i.deviceInfo?.name}`}>{i.deviceInfo?.name}</span>
                            : <span title={`${i.firstName} ${i.lastName}`}>{i.firstName} {i.lastName}</span>
                    }
                       <span>({i.deviceName})</span>
                </span>
                <span data-testid={`remove-${k}`} onClick={() => removeFromInventory(i.deviceId)}><img alt="" src={InventoryRemove}/></span>
            </div>
        );
    }

    const loadInventoryItems = async () => {

        let loadedData;
        const inventoryResult = await api
            .get()
            .distributedQueryResultsApi
            .distributedQueryResultList(
                [],
                ['+userLastName'],
                [`distributedQueryId:${query.distributedQueryId}`
                    , `organizationId:${orgId}`
                    , 'active:true'],
                ['userFirstName', 'userLastName', 'deviceType', 'deviceTimestamp', 'deviceInfo'],
                0,
                50
            );

        loadedData = inventoryResult.data.data.map(i => {
            return {
                deviceId: i.deviceId,
                firstName: i.userFirstName,
                lastName: i.userLastName,
                deviceName: utils.devicePlatformName(i.deviceType),
                deviceType: i.deviceType,
                deviceTimestamp: i.timestamp,
                userId: i.userId,
                deviceInfo: i.deviceInfo,
                recordId: i.id
            };
        });
        setSelectedDevices(loadedData);
    }

    const loadDataIfNeeded = async () => {
        const target = treeRef.current;
        const bottom = target.scrollHeight - target.scrollTop === target.clientHeight;
        if (bottom) {
            await loadItems(false);
        }
    }

    const setExpandedKeys = (keys) => {
        const treeStateCopy = JSON.parse(JSON.stringify(treeState));
        treeStateCopy.expandedKeys = [...keys];
        setTreeState(treeStateCopy);
    }

    const onSearch = async () => {
        setTreeState({
            devices: [],
            checkedKeys: [],
            expandedKeys: [],
            users: [],
            page: 1,
            loadedDevices: {},
            searchValue: treeState.searchValue
        });
        await loadItems(true);
    }

    const setSearchValue = async (value) => {
        const searchValues = value.split(' ');
        const treeStateCopy = JSON.parse(JSON.stringify(treeState));
        treeStateCopy.searchValue = searchValues;
        setTreeState(treeStateCopy);
    }

    const getPopup = () => {
        if (!isPopupVisible) {
            return null;
        }
        const searchValue = treeState.searchValue ? treeState.searchValue.join(' ') : '';
            return <div className="inventory-edit-popup">
                <ClickOutsideComponent onClickOutside={() => setIsPopupVisible(false)}>
                    <div className="inventory-edit-header">
                        <div>{inventoryName}</div>
                        <img alt="" src={InventoryClose} onClick={showHidePopup}/>
                    </div>
                    <div className="inventory-search">
                        <Search disabled={isDisabled} placeholder="Search" style={{ height: 24 }} data-testid="inventory-search"
                                onSearch={onSearch} value={searchValue} onChange={(e) => setSearchValue(e.target.value) } />

                    </div>
                    <div className="inventory-tree" ref={treeRef} onScroll={loadDataIfNeeded}>
                        <Tree
                            data-testid="devices-tree"
                            checkable
                            treeData={treeState.devices}
                            onCheck={onCheck}
                            onSelect={() => {}}
                            expandedKeys={treeState.expandedKeys}
                            checkedKeys={treeState.checkedKeys}
                            onExpand={setExpandedKeys}
                        />
                        {(isPopupVisible && !isDataLoaded && treeState.page === 1) && <div className="spin">Loading...</div>}
                    </div>
                </ClickOutsideComponent>
            </div>
    }

    const addDevice = async (node) => {

        const loadedDevices = treeState.loadedDevices;

         const user = node.user;
         const device = scanTopic === 'CLOUDS' ? node : loadedDevices[user.id]?.find(d => d.id === node.id) ?? [];
         const itemIndex = selectedDevices.findIndex(i => i.deviceId === node.id);

         if (itemIndex === -1) {

             try {
                 await api
                         .get()
                         .distributedQueryResultsApi
                         .distributedQueryResultCreate(
                             {
                                 deviceId: device.id,
                                 userId: user.id,
                                 organizationId: user.organizationId,
                                 execute: 'NONE',
                                 distributedQueryId: query.distributedQueryId
                             }
                         );

             } catch (err) {
                 showError('Unable to save inventory item');
             }
         }

         return true;
    }

    const showError = (text) => {
        return message.error(text);
     }

    const removeDevice = async (node) => {

        try {
            const deviceToRemove = selectedDevices.find(s => s.deviceId === node.id);
            await api
                .get()
                .distributedQueryResultsApi
                .distributedQueryResultDelete(
                    deviceToRemove.recordId
                );

            const filteredSelectedDevices = selectedDevices.filter(d => d.id !== node.id);
            setSelectedDevices(filteredSelectedDevices);

        } catch (err) {
            showError('Unable to remove device from inventory');
        }

        return true;
    }

    const addDevicesForUser = async (node) => {

        const user = treeState.users.find(u => u.id === node.id);
        const devices = treeState.loadedDevices[user.id];

        if (!devices) {
            return;
        }

        const treeStateCopy = JSON.parse(JSON.stringify(treeState));

        const promises = [];

        for (const device of devices) {

            const itemIndex = selectedDevices.findIndex(i => i.deviceId === device.id);
            if (itemIndex === -1) {
                treeStateCopy.checkedKeys.push(`device-${device.id}`);
                promises.push(new Promise((resolve) => {
                    const result = addDevice({
                        user: user,
                        id: device.id
                    });
                    resolve(result);
                }));
            }
        }
        setTreeState(treeStateCopy);
        Promise.all(promises).then(() => {
            loadInventoryItems();
        });
    }

    const removeDevicesForUser = async (node) => {

        const user = treeState.users.find(u => u.id === node.id);
        const devices = treeState.loadedDevices[user.id];

        if (!devices) {
            return;
        }

        const keys = [];
        const promises = [];

        for (const device of devices) {
            const deviceToRemove = selectedDevices.find(d => d.deviceId === device.id);
            if (deviceToRemove) {
                keys.push(`device-${device.id}`);
                promises.push(new Promise((resolve) => {
                    const result = removeDevice({id: device.id});
                    resolve(result);
                }));
            }
        }

        const treeStateCopy = JSON.parse(JSON.stringify(treeState));
        const filtered = [];

        treeStateCopy.checkedKeys.forEach(k => {
           if (!keys.some(i => i === k)) {
               filtered.push(k);
           }
        });

        treeStateCopy.checkedKeys = filtered;
        setTreeState(treeStateCopy);
        Promise.all(promises).then(() => {
            loadInventoryItems();
        });
    }

    const onCheck = async (_checkedKeysValue, e) => {
        const node = e.node;
        if (node.type === 'device') {
            const treeStateCopy = JSON.parse(JSON.stringify(treeState));
            if (e.checked) {
                treeStateCopy.checkedKeys.push(`device-${node.id}`);
                setTreeState(treeStateCopy);
            } else {
                treeStateCopy.checkedKeys = treeStateCopy.checkedKeys.filter(d => d !== `device-${node.id}`);
                setTreeState(treeStateCopy);
            }
            const promises = [];
            promises.push(new Promise((resolve) => {
                const result = e.checked ? addDevice(node) : removeDevice(node);
                resolve(result);
            }));
            Promise.all(promises).then(() => {
                loadInventoryItems();
            });
        }

        if (node.type === 'user') {
            if (e.checked) {
                await addDevicesForUser(node);
            } else {
                await removeDevicesForUser(node);
            }
        }
    }

    const getPlatforms = () => platforms.map((el) => {
            return <div data-testid="platforms" className="inventory-item" key={el}>
            <img className="platform-icon" src={devicePlatformSmallIcon(el)} alt=''/>
            <span>{devicePlatformName(el)}</span>
            </div>
        });

    const addButtonStyle = isPopupVisible ? { width: '24px', height: '24px'} : { border: 0, width: '24px', height: '24px'};

    return <div className="query-inventory-container">
            {getPopup()}
            <div className="inventory-header">
                <span>{inventoryName}</span>
                {!platforms && <Button
                    className="show-hide-edit"
                    data-testid="show-hide-edit"
                    icon={ <PlusOutlined />}
                    onClick={showHidePopup}
                    style={addButtonStyle}
                />}
            </div>
            <div className="inventory-items">
                {platforms ? getPlatforms() : getInventoryItems()}
            </div>
        </div>
}

export default QueryInventory;