import EmptyPage from "../emptyPage/emptyPage";
import noClients from "../../assets/images/no-clients.svg";
import emptySearchResult from "../../assets/images/empty-search-result.svg";
import React from "react";

const ClientsEmptyPage = ({isAllClients, isSearch, onRequestCompanyModalOpen, isFiltersSelected}) => {
    const getClientEmptyPage = () => {
        if (isFiltersSelected) {
            return <EmptyPage
                image={emptySearchResult}
                title="No matches found"
            />
        }
        if (!isSearch) {
            return <EmptyPage
                image={noClients}
                title="Clients list is empty"
                subTitle={`Please add ${isAllClients ? 'new ' : ''}company to start.`}
                onAddClick={isAllClients ? onRequestCompanyModalOpen : null}
                addButtonText={isAllClients ? "Add new company" : null}
            />
        }
        if (isSearch) {
            return <EmptyPage
                cssClassName="empty-search-result"
                image={emptySearchResult}
                title="No matches found"
                subTitle="Please, try another search."
            />
        }
        return <div>Unknown state</div>;
    }
    return (
        <div>
            {getClientEmptyPage()}
        </div>
    )
}

export default ClientsEmptyPage;