import './deviceSummary.css';
import { MailOutlined, ExclamationCircleOutlined, PieChartOutlined, BugOutlined } from '@ant-design/icons';
import ChildSidebarPage from "../childSidebarPage/childSidebarPage";
import DeviceSummaryHeader from "../deviceSummaryHeader/deviceSummaryHeader";
import {useNavigate} from "react-router-dom";
import DeviceViewEdit from "../deviceViewEdit/deviceViewEdit";
import {Modal, Spin, message} from 'antd';
import {useEffect, useState} from "react";
import ApiClient from "../../apiClient";
import loadedAssetDeviceState from "../../atoms/loadedAssetDevice";
import {useRecoilState} from 'recoil';
import Vulnerabilities from "../vulnerabilities/vulnerabilities";
import AwsSummary from "../awsSummary/awsSummary";
import GcpSummary from "../gcpSummary/gcpSummary"
import {showDeactivateConfirm} from "../utils";

const DeviceSummary = ({match, api, type}) => {
    const orgId = match.params.orgId;
    const deviceId = match.params.deviceId;

    const onFormChanged = (data) => {
        setFormData(data);
        setValidateForm(Date.now());
    }

    const onTabClick = (tab) => {
        let path = match.pathname.split('/');
        path[path.length - 1] = tab;
        path = path.join('/');
        navigate(`../${path}`);
        setDefaultSelectedTab(tab);
    }

    const defaultTabs = type === 'cloud' ? [
            { "data-testid": "tab-aws-summary", key: "cloud-summary", title: 'Summary', label: 'Summary', icon: <PieChartOutlined />, disabled: false, page: <AwsSummary onFormChanged={onFormChanged} />, onClick: () => {onTabClick('cloud-summary')} },
            { "data-testid": "tab-cloud-vulnerabilities", key: "issues", title: 'Issues', label: 'Issues', icon: <BugOutlined />, disabled: false, page: <Vulnerabilities api={api} deviceId={deviceId}/>, onClick: () => {onTabClick('issues')} },

        ] :
        [
            { "data-testid": "tab-device-summary", key: "device-summary", title: 'Summary', label: 'Summary', icon: <MailOutlined />, disabled: false, page: <DeviceViewEdit />, onClick: () => {onTabClick('device-summary')} },
            { "data-testid": "tab-device-vulnerabilities", key: "vulnerabilities", title: 'Vulnerabilities', label: 'Vulnerabilities', icon: <MailOutlined />, disabled: false, page: <Vulnerabilities api={api} deviceId={deviceId}/>, onClick: () => {onTabClick('vulnerabilities')} },
            { "data-testid": "tab-device-patches", key: "tab-device-patches", title: 'Patches', label: 'Patches', icon: <MailOutlined />, disabled: true },
            { "data-testid": "tab-device-software-applications", key: "tab-device-software-applications", title: 'Software Applications', label: 'Software Applications', icon: <MailOutlined />, disabled: true },
            { "data-testid": "tab-device-scan-this-device", key: "tab-device-scan-this-device", title: 'Scan this device', label: 'Scan this device', icon: <MailOutlined />, disabled: true },
        ];
    const gcp = { "data-testid": "tab-gcp-summary", key: "cloud-summary", title: 'Summary', label: 'Summary', icon: <PieChartOutlined />, disabled: false, page: <GcpSummary onFormChanged={onFormChanged} />, onClick: () => {onTabClick('cloud-summary')} }

    const apiClient = api ? api : new ApiClient();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [loadedAssetDevice, setLoadedAssetDevice] = useRecoilState(loadedAssetDeviceState);
    const [tabs, setTabs] = useState(defaultTabs);
    const [defaultSelectedTab, setDefaultSelectedTab] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);
    const [changeNameOnly, setChangeNameOnly] = useState(false);
    const [formData, setFormData] = useState({});
    const [validateForm, setValidateForm] = useState(null);
    const [selectedTab, setSelectedTab] = useState(null);

    const validateCloudForm = (deviceType) =>  {
        let isConfigChanged = false;
        let isSaveDisabled = true;
        const fieldToCompare = {};
        if (deviceType === 'aws') {
            const parsed = JSON.parse(loadedAssetDevice.deviceConfig ?? "{}");
            fieldToCompare.name = formData.name;
            fieldToCompare.accessKey = parsed.access_key;
            fieldToCompare.regions = parsed.regions;
            fieldToCompare.secretKey = '';
            if (JSON.stringify(formData) !== JSON.stringify(fieldToCompare)) {
                isConfigChanged = true;
            }
            if (isConfigChanged && formData.accessKey && formData.regions && formData.secretKey && formData.name) {
                isSaveDisabled = false;
                setChangeNameOnly(false);
            }
        }
        if (!isConfigChanged && formData.name && formData.name !== loadedAssetDevice.deviceInfo.name) {
            isSaveDisabled = false;
            setChangeNameOnly(true);
        }
        return isSaveDisabled;
    }

    useEffect(() => {
        const isValid = validateCloudForm(loadedAssetDevice?.deviceType);
        if (isDisabled !== isValid) {
            setIsDisabled(isValid);
        }
    }, [validateForm]);

    const navigateToDeviceList = () => {
        navigate(type === 'cloud' ? `/clients/${orgId}/assets/clouds` : `/clients/${orgId}/assets/devices`);
    }

    const onSave = async () => {
        const scanParams = {
            organizationId: orgId,
            name: formData.name,
            accessKey: formData?.accessKey,
            secretKey: formData?.secretKey,
            regions: formData?.regions,
            active: true,
        };
        try {
            let deviceFields;
            if (loadedAssetDevice.deviceType === 'aws') {
                const awsRegions = formData.regions.replace(/\s/g, '').split(',').map(s => `"${s}"`).join(',');
                deviceFields = changeNameOnly ? {
                    deviceInfo: `{"arch":"CLOUD","name":"${scanParams.name}","platform":"aws","platform_like":"cloud","version":"","private_key":"${scanParams.accessKey}"}`,
                } : {
                    scanTopic: 'CLOUDS',
                    deviceType: 'aws',
                    deviceInfo: `{"arch":"CLOUD","name":"${scanParams.name}","platform":"aws","platform_like":"cloud","version":"","private_key":"${scanParams.accessKey}"}`,
                    deviceConfig: `secret_key="${scanParams.secretKey}"\naccess_key="${scanParams.accessKey}"\nregions=[${awsRegions}]`,
                };
            } else if (loadedAssetDevice.deviceType === 'gcp') {
                deviceFields = {
                    scanTopic: 'CLOUDS',
                    deviceType: 'gcp',
                    deviceInfo: `{"arch":"CLOUD","name":"${scanParams.name}","platform":"gcp","version":"${loadedAssetDevice.deviceInfo.version}","private_key":"${loadedAssetDevice.deviceInfo.private_key}"}`,
                    deviceConfig: loadedAssetDevice.deviceConfig,
                };
            }
            await apiClient.get().devicesApi.devicePatch([`id:${loadedAssetDevice.id}`], deviceFields);
            await loadDeviceInfo();
            await message.success('Device has been successfully updated');
        } catch (err) {
            message.error('Unable to save scan');
        }
    }

    const onDelete = () => {
         showDeactivateConfirm('device', () => onDeviceDelete());
    }

    const onDeviceDelete = async () => {
        try {
            await apiClient.get().devicesApi.devicePatch([`id:${deviceId}`], {active: false});
            message.success('Device has been successfully deleted');
            navigateToDeviceList();
        } catch (err) {
            return async () => {
                await message.error(err);
            }
        }
    }

    const loadDeviceInfo = async () => {
        setIsLoading(true);
        const filter = ['active:true', `id:${deviceId}`, `organizationId:${orgId}`];
        const devices = await apiClient.get().devicesApi.deviceList(
            '',
            [],
            filter,
            ['userInfo'],
            1,
            1
        );
        const device = devices.data.data[0];
        if (!device) {
            return navigateToDeviceList();
        }
        if (device.deviceType === 'gcp') {
            defaultTabs[0] = gcp;
        }
        const defaultTab = defaultTabs.find((t) => match.pathname.includes(t.key))
        setSelectedTab(defaultTab);
        setDefaultSelectedTab(defaultTab.key);
        setTabs([...defaultTabs]);
        setLoadedAssetDevice(device);
        setIsLoading(false)
    }

    useEffect(async () => {
        await loadDeviceInfo();
    }, []);

    if (isLoading) {
        return <div className="device-summary-spin" data-testid="device-summary-spin">
            <Spin size="large" />
        </div>
    }

    return <ChildSidebarPage
            defaultSelectedKeys={[defaultSelectedTab]}
            defaultSelectedPage={selectedTab}
            className='devices-content'
            menuContainerCssClass={'scan-menu-control'}
            header={<DeviceSummaryHeader onBack={navigateToDeviceList} device={{
                deviceType: loadedAssetDevice.deviceType,
                timestamp: loadedAssetDevice.timestamp,
                deviceName: loadedAssetDevice.scanTopic === 'CLOUDS' ? loadedAssetDevice.deviceInfo?.name : loadedAssetDevice.deviceInfo?.computer_name
            }} onSave={onSave} disabled={isDisabled} onDelete={onDelete} showDelete={true}/>}
            sidebarTabs={tabs}
        />
}

export default DeviceSummary;
