import './documentsList.css';
import {useCallback, useEffect, useState} from 'react';
import DocumentsOperations from './documentsOperations/documentsOperations';
import DocumentDetails from './documentDetails/documentDetails';
import TableFilter from './tableFilter/tableFilter';
import DocumentsTable from './documentsTable/documentsTable';
import DocumentsHeader from './documentsHeader/documentsHeader';
import {useNavigate, useParams} from 'react-router-dom';
import ApiClient from '../../apiClient';
import EmptyPage from '../emptyPage/emptyPage';
import noDocuments from '../../assets/images/no-clients.svg';
import {showDeactivateConfirm} from "../utils";
import {message} from "antd";
import FolderDetails from "./folderDetails/folderDetails";

const initialFolderIds = {
  currentFolderName: '',
  previousFolderId: null
};

let pagination = {current: 1, limit: 10, total: 0};

const DocumentsList = ({loggedUser, api}) => {
  const [documents, setDocuments] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [isEmptyPage, setIsEmptyPage] = useState(false);
  const [, setIsLoaded] = useState(false);
  const [folderIds, setFolderIds] = useState(initialFolderIds);
  const {orgId, documentId, projectId} = useParams();
  const navigate = useNavigate();
  const apiClient = api ?? new ApiClient();
  const [selectedDocument, setSelectedDocument] = useState(null);

  async function loadDocuments(parentDocumentId, search) {
    const parentDocument = parentDocumentId || null;
    try {
      const documentsResponse = await apiClient.get().documentApi.documentsList(
        search,
        [`+document_type`],
        [`parentDocument:${parentDocument}`, `organizationId:${orgId}`, `projectId:${projectId}`, 'active:true'],
        [],
          pagination.current, pagination.limit);
      pagination = documentsResponse.data.page;
      const documentsList = documentsResponse.data.data;
      if (!search.length && !documentsList.length) {
        setIsEmptyPage(true);
      } else {
        setIsEmptyPage(false);
      }
      setDocuments(documentsList);
      setIsLoaded(true);
    } catch (error) {
      message.error(`Error while loading documents list`);
    }
  }

  useEffect(async () => {
    if (documentId > -1) {
      const currentDocument = await apiClient.get().documentApi.documentsGetById(documentId);
      setFolderIds({
        currentFolderName: currentDocument.data?.name,
        previousFolderId: currentDocument.data?.parentDocument
      });
    }
    await loadDocuments(null, []);
    if (searchValue) return;
    setSelectedDocument(null);
    if (documentId > -1) {
      const currentDocument = await apiClient.get().documentApi.documentsGetById(documentId);
      setFolderIds({
        currentFolderName: currentDocument.data?.name,
        previousFolderId: currentDocument.data?.parentDocument
      });
      await loadDocuments(documentId, []);
      setSelectedDocument(null);
    }
    else {
      setFolderIds(initialFolderIds);
      pagination.current = 1;
      await loadDocuments(null, []);
    }
  }, [documentId]);

  async function onDocumentSearch(value) {
    setIsSearching(true);
    pagination.current = 1;
    setSearchValue(value);
    const loadDocument = documentId > -1 ? documentId : null;
    const searchItems = value ? value : [];
    await loadDocuments(loadDocument, searchItems);
    setIsSearching(false);
  }

  const onDocumentDelete = useCallback(async (id, isFolder, redirectTo, parentDocument) => {
    const entity = isFolder ? 'folder' : 'document';
    const capitalized = entity.charAt(0).toUpperCase() + entity.slice(1);
    showDeactivateConfirm(entity, async () => {
      try {
        await apiClient.get().documentApi.documentsPatch([`id:${id}`], {active: false});
        if (isFolder) {
          await apiClient.get().documentApi.documentsPatch([`parent_document:${id}`], {active: false});
        }
        const searchItems = searchValue.length ? searchValue : [];
        if (redirectTo) {
          await navigate(redirectTo);
        } else {
          if (pagination.total -1 <= pagination.current * pagination.limit - pagination.limit) {
            pagination.current = pagination.current -1;
          }
          await loadDocuments(parentDocument, searchItems);
        }
        setSelectedDocument(null);
        message.success(`${capitalized} has been successfully deleted`);
      } catch (err) {
        message.error(`An error occurred while try to delete ${entity}`);
      }
    });
  }, [documentId]);

  async function onDocumentEdit(document) {
    setSelectedDocument(document);
    const documentsParams = {
      "organizationId": orgId,
      "projectId": projectId,
      "userId": loggedUser?.userId,
      "name": document.name,
      "description": document.description ?? null,
      "documentType": document.documentType,
      "url": document.url,
      "dateCreated": document.dateCreated,
      "parentDocument": documentId ?? null,
      "active": true,
    };
    await apiClient.get().documentApi.documentsUpdate(document.id, documentsParams);
    const parentDocument = documentId > -1 ? documentId : null;
    const searchItems = searchValue.length ? searchValue : [];
    await loadDocuments(parentDocument, searchItems);
  }

  async function onDocumentCreate(documentName, documentDescription, documentType, documentUrl) {
    if (!documentName || !documentType || !projectId || (documentType === 'link' && !documentUrl)) {
      return;
    }
    try {
      const documentsParams = {
        "organizationId": orgId,
        "projectId": projectId,
        "userId": loggedUser?.userId,
        "name": documentName,
        "description": documentDescription ?? null,
        "documentType": documentType,
        "url": documentUrl,
        "parentDocument": documentId ?? null,
        "dateCreated": new Date(Date.now()),
        "active": true,
      };
      const loadDocument = documentId > -1 ? documentId : null;
      const searchItems = searchValue.length ? searchValue : [];
      await apiClient.get().documentApi.documentsCreate(documentsParams);
      await loadDocuments(loadDocument, searchItems);
    } catch (error) {
      message.error(`Error while creating document`);
    }
  }

  const closeDocumentDetails = () => {
    setSelectedDocument(null);
  }

  const setPage = async (page) => {
    pagination = page;
    const loadDocument = documentId > -1 ? documentId : null;
    const searchItems = searchValue.length ? searchValue : [];
    await loadDocuments(loadDocument, searchItems);
  }

  const containerCssClassName = selectedDocument ? 'sidebar-opened' : 'no-sidebar';
  return <div className="documents-page-container" data-testid="documents-page-container">
      <DocumentsHeader folderName={folderIds?.currentFolderName} folderId={documentId} previousFolder={folderIds?.previousFolderId} onDelete={onDocumentDelete}/>
      <DocumentsOperations onDocumentSearch={onDocumentSearch} isSearching={isSearching}
                           onDocumentCreate={onDocumentCreate} currentFolder={documentId}
                           isEmptyPage={isEmptyPage}/>
      {isEmptyPage && <EmptyPage
          image={noDocuments}
          cssClassName="documents-empty-page"
          title="There are no documents yet"
          subTitle="Please add a new document to start."
      />}
      {!isEmptyPage && <TableFilter/>}
      <div className="documents-table-container">
        {!isEmptyPage && <div className={containerCssClassName}><DocumentsTable parentDocument={documentId} dataSource={documents} isSearching={isSearching} pagination={pagination} selectedDocument={selectedDocument}
                                              setPagination={setPage} onDocumentDelete={onDocumentDelete} onDocumentSelected={setSelectedDocument}/></div>}
        {(selectedDocument && selectedDocument.documentType === 'link') && <DocumentDetails api={apiClient} loggedUserId={loggedUser.userId} document={selectedDocument} onClose={closeDocumentDetails} onDocumentDelete={onDocumentDelete} onDocumentEdit={onDocumentEdit} />}
        {(selectedDocument && selectedDocument.documentType === 'folder') && <FolderDetails api={apiClient} loggedUserId={loggedUser.userId} folder={selectedDocument} onClose={closeDocumentDetails} onFolderDelete={onDocumentDelete} onFolderEdit={onDocumentEdit} />}
      </div>
  </div>;
};

export default DocumentsList;
