import './debugScanResults.css';
import QueryExport from "../queryExport/queryExport";
import {Progress, Table, Tabs, Empty} from "antd";
import * as utils from "../utils";
import {useEffect, useState} from "react";
import ResultMapping from "../resultMapping/resultMapping";
import ReportTablePreview from "../reportTablePreview/reportTablePreview";
import TagError from '../../assets/images/tag-error.svg';
import TagTimeout from '../../assets/images/tag-timeout.svg';
import {formatDate} from "../utils";

const ErrorTag = ({status}) => {
    const className = status.execute === 'FAILED' ? 'tag-error' : 'tag-timeout';
    const label = status.execute === 'FAILED' ? 'Error' : 'Timeout';
    const icon = status.execute === 'FAILED' ? TagError : TagTimeout;
    const errorText = status.message ? status.message[0].toUpperCase() + status.message.slice(1) : null;
    return <div className='error-wrapper'>
        <div className={`error-tag ${className}`}>
            <img src={icon} alt={label}/>
            <div>{label}</div>
        </div>
        {errorText && <div className='error-text'>{errorText}</div>}
    </div>
}

const DebugScanResults = ({ apiClient, scanTopic, query, isInProgress, orgId, executionInfo, pagingInfo, fields, mappingInfo, onMappingChanged }) => {

    const [isFirstRun, setIsFirstRun] = useState(false);
    const [mappingFields, setMappingFields] = useState([]);
    const [mappingResults, setMappingResults] = useState([]);

    const getResultDescription = (counter, total, percentage, timeoutQueriesCount) => {
        return <>
            <div>{counter} of {total}</div>
            {timeoutQueriesCount > 0 &&
                <span className="timeout-label"><span>{timeoutQueriesCount} {getText(timeoutQueriesCount, 'Timeout')}</span></span>}
            <Progress
                percent={percentage}
                showInfo={false}
            >
            </Progress>
        </>
    }

    const getText = (count, text) => {
        return count > 1 ? `${text}s`: text
    }

    const handlePaging = (page) => {
        pagingInfo.handlePagination(query.distributedQueryId, page);
    }

    const getRowClassName = (record) => {
        let className = '';
        switch (record.status) {
            case 'TIMEOUT':
                className = 'row-timeout';
                break;
            case 'FAILED':
                className = 'row-error';
                break;
        }
        return className;
    }

    const getResultsTable = (users) => {

        const cols = [
            {
                title: 'Inventory',
                dataIndex: 'inventory',
                key: 'inventory',
                width: 50,
                align: 'left'
            },
            {
                title: 'Owner',
                dataIndex: 'owner',
                key: 'owner',
                width: 140,
                align: 'left'
            },
            {
                title: 'Timestamp',
                dataIndex: 'timestamp',
                key: 'timestamp',
                width: 210,
                align: 'left'
            },
            {
                title: 'Results',
                dataIndex: 'results',
                key: 'results',
                align: 'left',
                className: 'debug-scan-results-column'
            }
        ];

        const dataSource = [];

        users.forEach((user, index) => {
            const deviceName = utils.devicePlatformName(user.deviceType);
            const owner = user.deviceInfo?.arch === 'CLOUD' ? user.deviceInfo.name : `${user.userFirstName} ${user.userLastName}`;
            const result = user.rawData || 'No data';
            const isInError = user.execute === 'FAILED' || user.execute === 'TIMEOUT';
            const tag = user.execute === 'FAILED' || user.execute === 'TIMEOUT' ? <ErrorTag status={user}/> : null;
            dataSource.push({
                inventory: deviceName,
                owner: owner,
                results: isInError? tag : result,
                timestamp: !isInError ? formatDate(user.timestamp) : '',
                key: `result-${index}`,
                status: user.execute
            })
        });

        return <div className="results-table">
            <Table
                className="table-view-grid"
                dataSource={dataSource}
                columns={cols}
                rowClassName={(record) => {
                    return getRowClassName(record);
                }}
                pagination={
                isInProgress || users.length === 0
                    ? {}
                    : {
                        current: pagingInfo.page,
                        total: pagingInfo.totalPages,
                        showSizeChanger: false,
                        pageSize: pagingInfo.limit,
                        onChange: handlePaging
                    }
                } />
        </div>;
    }

    const preparePreviewFields = (items, customFields) => {
        if (items) {
            setMappingResults(items);
        }
        const mappingObject = items || mappingResults;
        const fields = [];
        mappingObject.forEach((m) => {
            fields.push(m);
        });
        setMappingFields(fields);
        onMappingChanged({fields, customFields});
    }

    const onTabChanged = (tab) => {
        if (tab === 'preview') {
            preparePreviewFields(null, mappingInfo.customFields);
        }
    }

    useEffect(() => {
            if (!mappingInfo) {
                return;
            }
            const result = [];
            mappingInfo.fields.forEach(i => {
                result.push(i)
            });
            setMappingResults(result);
        }
    , [])

    const showResult = (state, isInProgress) => {

        const { debugResultsCount, queryResults, finishedDevices, debugTimeout, failedCount } = state;

        const total = debugResultsCount;
        const activeQueries = queryResults.filter(query => query.execute !== 'RUN' && query.execute !== 'POPULATED');
        const percentage = Math.floor(finishedDevices / total * 100);

        const resultLiveMessage = <span>{finishedDevices} of {total} machines are scanned</span>;

        if (isFirstRun === null && isInProgress) {
            setIsFirstRun(false);
        }

        const customs = mappingInfo ? mappingInfo.customFields : [];

        const queryResultsTab = () =>
            isFirstRun !== null
                ?
                <>
                    <div className="results-title">Query results
                        <QueryExport isLive={false}
                                     query={query}
                                     organizationId={orgId}
                                     apiClient={apiClient}
                                     cloud={scanTopic === 'CLOUDS'}
                                     disabled={isInProgress || activeQueries.length === 0 }
                        />
                    </div>
                    {isInProgress ? <div className="results-description-progress">
                            {getResultDescription(finishedDevices, total, percentage, debugTimeout)}
                        </div>
                        : <div className="results-description">
                            {resultLiveMessage}
                            {debugTimeout > 0 &&
                                <span
                                    className="timeout-label"><span>{debugTimeout} {getText(debugTimeout, 'Timeout')}</span></span>}
                            {failedCount > 0 &&
                                <span
                                    className="failed-label"><span>{failedCount} {getText(failedCount, 'Error')}</span></span>}
                        </div>}
                    {getResultsTable(activeQueries)}
                </>
                : <div className="empty-container"><Empty/></div>

        const reportTablePreview = () => <ReportTablePreview mappingFields={mappingFields} results={activeQueries} customFields={customs}/>

        return (
            <div className="debug-scan-results" data-testid="debug-scan-results">
                <ResultMapping query={query} result={fields} onMappingChanged={(mappingInfo) => {
                    preparePreviewFields(mappingInfo.fields, mappingInfo.customFields);
                }}  mappingInfo={mappingInfo} isInProgress={isInProgress} disabled={isInProgress}/>
                <Tabs defaultActiveKey="results" onChange={onTabChanged} items={[{
                    label: "Query results",
                    key: "results",
                    children: queryResultsTab()
                },
                {
                    label: "Report table preview",
                    key: "preview",
                    children: reportTablePreview()
                }]}/>
            </div>
        );
    }

    return <div>{showResult(executionInfo, isInProgress)}</div>
}

export default DebugScanResults;