import {Input} from 'antd';
import './linkModal/modalInputItem.css';

const ModalInputItem = ({title, value, onChange}) => {
  return (
    <div className="documents-page-modal-input-item" data-testid="documents-page-modal-input-item">
      <div className='documents-page-modal-input-title'>{title}</div>
      <Input data-testid="documents-page-modal-input" value={value} onChange={onChange}/>
    </div>
  );
};

export default ModalInputItem;
