import {Gantt, ViewMode} from "gantt-task-react";
import {useEffect, useState} from "react";
import {Layout, Spin, Tooltip} from "antd";
import "gantt-task-react/dist/index.css";
import {ViewSwitcher} from "../viewSwitcher/ViewSwitcher";
import "./chartList.css";
import EditTaskSidebar from "../editTaskSidebar/editTaskSidebar";
import ApiClient from "../../apiClient";
import moment from "moment/moment";
import {
    CaretDownFilled,
    CaretRightFilled,
    ExclamationCircleOutlined,
    FolderOpenOutlined,
    FolderOutlined
} from "@ant-design/icons";
import TooltipComponent from "../tooltip/tooltipComponent";
import useTemplateTaskOperations from "../../hooks/useTemplateTaskOperations";
import {calculateDate} from "@violetx-mvp/commons";
import {useNavigate, useParams} from "react-router-dom";
import {useRecoilValue} from "recoil";
import taskAssignedNotificationsState from "../../atoms/taskAssignedNotifications";
import deadlineTomorrowNotificationsState from "../../atoms/deadlineTomorrowNotification";


export default function ChartList({api, accessScope, id, users, onRefresh, onModalClose, hasPhases, isTemplates }) {
    const {Content, Sider} = Layout;
    const projectStyles = {
        backgroundColor: "#67CB3466",
        progressColor: "#FFF",
        progressSelectedColor: "#67CB3466",
        backgroundSelectedColor: "#67CB3466",
    }
    const taskStyles = {
        backgroundColor: "#67CB3466",
        progressColor: "#67CB34",
        progressSelectedColor: "#67CB34",
        backgroundSelectedColor: "#67CB3466"
    }

    const blockerStyles = {
        backgroundColor: "#FA8C16",
        progressColor: "#FA8C16",
        progressSelectedColor: "#FA8C16",
        backgroundSelectedColor: "#FA8C16"
    }

    const getTaskStyles = (priority) => priority === 'BLOCKER' ? blockerStyles : taskStyles;

    let cycled;

    const [phases, setPhases] = useState([]);
    const [view, setView] = useState( ViewMode.Month);
    const [tasks, setTasks] = useState([]);
    const [isChecked, setIsChecked] = useState(true);
    const [expandedTask, setExpandedTasks] = useState({});
    const [editTask, setEditTask] = useState(null);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [clickedRow, setClickedRow] = useState(null);
    const [handleTemplateUpdated, setHandleTemplateUpdated] = useState(null);
    const [expandedPhases, setExpandedPhases] = useState([]);
    const [isTaskUpdating, setIsTaskUpdating] = useState(false);
    const [nonCollapsedTasks, setNonCollapsedTasks] = useState([]);
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const assignedTasksNotifications = useRecoilValue(taskAssignedNotificationsState);
    const deadlineTomorrowNotifications = useRecoilValue(deadlineTomorrowNotificationsState);

    const navigate = useNavigate();
    const { orgId } = useParams();

    const [getTemplateTasks, getUpdatedTask, updateTemplateTask] = useTemplateTaskOperations(api);

    const apiClient = api || new ApiClient();
    const customFormat = (value) => value.format('MMM DD, YYYY');

    const navigatePath = isTemplates ? `/projects/templates/summary/${id}` : `/clients/${orgId}/projects/details/${id}`;

    let columnWidth = 60;
    if (view === ViewMode.Month) {
        columnWidth = 300;
    } else if (view === ViewMode.Week) {
        columnWidth = 250;
    }

    useEffect(() => {
        if (handleTemplateUpdated) {
            const task = tasks.find((t) => t.id === handleTemplateUpdated);
            setEditTask(task);
            setHandleTemplateUpdated(null);
        }
    },[tasks, handleTemplateUpdated]);

    useEffect(() => {
        if (hasPhases) {
            const filteredPhases = phases.filter(p => p.tasks.length > 0);
            const notCollapsedPhases = filteredPhases.filter(p => expandedTask[p.id]);
            filteredPhases.forEach(p =>  p.id = isNaN(p.id) ?  p.id : `phase-${p.id}`);
            const openedTasks = [];
            filteredPhases.forEach(p => {
                openedTasks.push(p);
                const openedPhase = notCollapsedPhases.find(phase => phase.id === p.id);
                if (openedPhase) {
                    openedTasks.push(...openedPhase.tasks);
                }
            });
            setNonCollapsedTasks(openedTasks);
        }
    }, [phases, expandedTask, hasPhases]);

    useEffect(() => {
        if (!hasPhases && nonCollapsedTasks.find(t => t.isSelected)?.id !== editTask?.id) {
            const newTasks = tasks.map(t => {
                t.isSelected = t.id === editTask?.id;
                return t;
            });
            setNonCollapsedTasks(newTasks);
            return;
        }
        if (editTask && editTask?.id !== selectedTaskId) {
            phases.forEach(p => p.tasks.forEach(t => t.isSelected = false));
            const newTasks = nonCollapsedTasks.map((t) => {
                t.isSelected = t.id === editTask?.id;
                return t;
            });
            setNonCollapsedTasks([...newTasks]);
            setSelectedTaskId(editTask.id);
        } else if (!editTask && nonCollapsedTasks.find(t => t.isSelected)) {
            const newTasks = nonCollapsedTasks.map((t) => {
                t.isSelected = false;
                return t;
            });
            setNonCollapsedTasks([...newTasks])
        }
    },[editTask, nonCollapsedTasks, expandedTask, hasPhases, selectedTaskId]);

    const getProgress = (p) => (p.tasks.reduce((sum, number) => sum + +number.progress, 0) / p.tasks.length).toFixed(2);

    const loadInitData = async () => {
        let result = [];
        if (hasPhases) {
            result = await loadPhases();
        }
        const tasks = await loadTasks(result);
        if (tasks.length > 0) {
            handleTasks(tasks, result);
        }
    };

    const loadPhases = async () => {
        let load = true;
        let page = 1;
        let phases = [];
        while (load) {
            const loadedPhases = await getPhases(page);
            phases = processPhases(phases, loadedPhases.data.data);
            page = loadedPhases.data.page.next;
            load = page != null;
        }
        return phases;
    };

    const getPhases = async (page) => {
        if (isTemplates) {
            return await apiClient.get().templateTaskPhasesApi.templateTaskPhasesList(
                [],
                [`+id`],
                ['active:true', `templateId:${id}`],
                ['tasksCount'],
                page,
                50
            );
        } else {
            return await apiClient.get().taskPhaseApi.taskPhaseList(
                [],
                [`+id`],
                ['active:true', `projectId:${id}`],
                [],
                page,
                50
            );
        }
    };

    const processPhases = (phases, phasesData) => {
        for (const phase of phasesData) {
            phases[phase.id] = {
                id: phase.id,
                name: phase.name,
                tasks: []
            };
        }
        return phases;
    };

    const loadTasks = async (phases) => {
        let load = true;
        let page = 1;
        const tasks = [];
        const filter = phases.filter(Boolean).map(p => isTemplates ? `templateTaskPhaseId:${p.id}` : `taskPhaseId:${p.id}`);
        while (load) {
            const loadedTasks = await getTasks(page, filter);
            tasks.push(...loadedTasks.data);
            page = loadedTasks.page;
            load = loadedTasks.hasNextPage;
        }
        return processTasks(tasks);
    };

    const getTasks = async (page, filter) => {
        let loadedTasks;
        if (isTemplates) {
            loadedTasks = await getTemplateTasks(page, '', id, filter, ['templateTaskLinkage']);
        } else {
            loadedTasks = await apiClient.get().tasksApi.taskList(
                [],
                [`+id`],
                ['active:true', ...filter, `projectId: ${id}`],
                ['taskLinkage'],
                page,
                50
            );
            loadedTasks = {
                data: loadedTasks.data.data,
                page: loadedTasks.data.page.next,
                hasNextPage: loadedTasks.data.page.next != null
            }
        }
        return loadedTasks
    };

    const removeDependency = (task) => {
        delete task.dependencies;
        return tasks;
    }

    const setDependencies = (tasks) => {
        tasks.forEach(t => {
            if (t.linkageId?.[0]) {
                t.dependencies = t.linkageId;
            }
        });
        return tasks;
    }

    const getDaysFromNow = (id, tasksData, visited = new Set()) => {
        const obj = tasksData.find(t => t.id === id);
        if (visited.has(id)) {
            cycled = true;
            return 0; // Return 0 duration for cyclic dependencies
        }
        if (!obj?.linkageId?.[0]) {
            return obj.duration;
        }
        visited.add(id);
        const dependencyId = obj.linkageId[0];
        const duration = obj.duration + getDaysFromNow(dependencyId, tasksData, visited);
        obj.daysFromNow = duration;
        if (cycled) {
            obj.daysFromNow = obj.duration;
            cycled = false;
        }
        return duration;
    }

    const processTasks = (tasksData) => {
        if(!isTemplates) {
            return setDependencies(tasksData
                .filter(item => item.startDate && item.endDate))
                .map(item => ({
                    id: item.id,
                    name: item.name,
                    start: new Date(item.startDate),
                    end: new Date(item.endDate),
                    phaseId: item.taskPhaseId,
                    prefix: item.key,
                    projectPrefix: item.prefix,
                    progress: item.status === 'DONE' ? 100 : '0',
                    assignees: [
                        {
                            assigneeName: item.assigneeFirstName ? `${item.assigneeFirstName} ${item.assigneeLastName}` : '',
                            assigneeId: item.assigneeId,
                            fileName: item.assigneeFileName
                        },
                        {
                            assigneeName: item.assignee2FirstName ? `${item.assignee2FirstName} ${item.assignee2LastName}` : '',
                            assigneeId: item.assigneeId2,
                            fileName: item.assignee2FileName
                        }],
                    type: "task",
                    description: item.description,
                    linkageName: item.linkageName,
                    linkageKey: item.linkageKey,
                    linkageId: item.linkageId,
                    dependencies: item.dependencies,
                    priority: item.priority,
                    fileName: item.userFileName,
                    projectId: item.projectId,
                    orgId: item.organizationId,
                    status: item.status,
                    authorFirstName: item.authorFirstName,
                    authorLastName: item.authorLastName,
                    authorRoleId: item.authorRoleId,
                    dueDate: item.dateDueTo && moment(Date.parse(item.dateDueTo)).format("MMMM DD, YYYY"),
                    createdBy: item.userFirstName ? `${item.userFirstName} ${item.userLastName}` : '',
                    text: item.text,
                    dateCreated: item.dateCreated,
                    duration: item.duration,
                    styles: getTaskStyles(item.priority)
                }));
        } else {
            const tasksTemplateData = [];
            tasksData.forEach(obj => {
                getDaysFromNow(obj.id, tasksData);
            });
            tasksData.map((item)=> {
                if (item.type === 'project') {
                    tasksTemplateData.push(item);
                } else {
                    const [start, end] = calculateDate(item);
                    tasksTemplateData.push({
                        id: item.id,
                        name: item.name,
                        start: start,
                        end: end,
                        phaseId: item.phaseId ?? item.templateTaskPhaseId,
                        progress: '0',
                        type: "task",
                        project: item.project,
                        description: item.description,
                        linkageName: item.linkageName,
                        linkageKey: item.linkageId,
                        linkageId: item.linkageId,
                        priority: item.priority,
                        duration: item.duration,
                        styles: getTaskStyles(item.priority)
                    })
                }
               });
            return setDependencies(tasksTemplateData);
        }
    };

    const handleTasks = (tasks, phases) => {
        if (hasPhases) {
            tasks.forEach(t => phases[t.phaseId].tasks.push(t));
            setPhases(phases);
        } else {
            setTasks(tasks);
            setNonCollapsedTasks(tasks);
            setIsLoading(false);
        }
    };

    useEffect(async () => {
        if (hasPhases) {
            await loadInitData();
        }
    },[hasPhases]);

    useEffect(async () => {
        if(!hasPhases){
            await loadInitData();
        }
    },[editTask, hasPhases]);

    useEffect(() => {
        if (phases.length > 0 && isLoading) {
            setTasks(getInitTasks());
            setIsLoading(false);
        }
    },[phases]);

    const findExtremeDate = (dateArray, findMax) =>  {
        let extremeDate = null;
        for (const currentDate of dateArray) {
            if ((findMax && currentDate > extremeDate) || (!findMax && currentDate < extremeDate) || !extremeDate) {
                extremeDate = new Date(currentDate);
            }
        }
        return extremeDate;
    }

    const getInitTasks = () => {
        const tasks = [];
        phases.forEach((p) => {
            const startDate = findExtremeDate(p.tasks.map(t => t.start));
            const endDate = findExtremeDate(p.tasks.map(t => t.end), true);
            if (startDate && endDate) {
                tasks.push({
                    id: p.id,
                    start: startDate,
                    end: endDate,
                    name: p.name,
                    hideChildren: true,
                    progress: getProgress(p),
                    type: "project",
                    tasks: p.tasks,
                    styles: projectStyles
                });
            }
            p.tasks.map(t => {
                    tasks.push({
                        ...t,
                        project: p.id,
                    });
            });
        });
        return tasks;
    }

    const getStartEndDateForProject = (tasks, projectId) => {
        const projectTasks = tasks.filter((t) => t.project === projectId);
        let start = projectTasks[0].start;
        let end = projectTasks[0].end;

        for (const task of projectTasks) {
            if (start.getTime() > task.start.getTime()) {
                start = task.start;
            }
            if (end.getTime() < task.end.getTime()) {
                end = task.end;
            }
        }
        return [start, end];
    };
    const handleEditTask = (task) => {
        if (task.type === 'project') {
            return;
        }
        setEditTask(task)
        setClickedRow(task.id)
    };

    const getTaskListHeader = ({headerHeight}) => (
        <div
            style={{
                height: headerHeight,
                marginBottom: -1,
            }}
        />
    )

    const getTooltipComponent = () => (
        <div/>
    );

    const getTaskListTable = ({
                               tasks,
                               rowWidth,
                               rowHeight,
                               onExpanderClick
                           }) => (
            <div style={{ border: "1px solid #dfe1e5" }}>
                {tasks.map((item, i) => {
                    const isProject = item.type === "project";
                    const notificationsCount = assignedTasksNotifications.filter(n => n.params.taskKey === item.prefix).length
                        + deadlineTomorrowNotifications.filter(n => n.params.taskKey === item.prefix).length;
                    return (<div key={item.id} style={{position: 'relative'}}>
                        {notificationsCount > 0 ? <span className="notification"/> : null}
                        <div className="chart-table-row"
                            data-testid={`table-item-${i}`}
                            onClick={() => {isProject ? onExpanderClick(item) : handleEditTask(item)}}
                            style={{
                                height: rowHeight,
                                width: rowWidth,
                                background: clickedRow === item.id && editTask ? "#1890ff33" : null,
                                paddingLeft: isProject ? 10 : 40,
                            }}
                        >
                            <TooltipComponent>
                                <p
                                    onClick={() => onExpanderClick(item)}
                                    className="chart-name"
                                >
                                    {isProject && expandedTask[item.id] ?
                                        <>
                                            <CaretDownFilled className="chart-icon-opened"/><FolderOpenOutlined
                                            className="folder-icon"/>
                                        </>
                                        : ""}
                                    {isProject && !expandedTask[item.id] ?
                                        <>
                                            <CaretRightFilled className="chart-icon"/> <FolderOutlined
                                            className="folder-icon"/>
                                        </>
                                        : ""}
                                    <span>
                                        {item.name}
                                    </span>
                                </p>
                            </TooltipComponent>

                            {item.priority === 'BLOCKER' && <Tooltip title="Blocker">
                                <ExclamationCircleOutlined style={{color: "#FA8C16", marginRight: "8px"}}/>
                            </Tooltip>}
                        </div>
                    </div>);
                })}
            </div>
    );
    const handleTaskChange = async (task) => {
        setClickedRow(task.id);
        setIsTaskUpdating(true);
        const duration = Math.round((new Date(task.end).getTime() - new Date(task.start).getTime()) / (1000 * 60 * 60 * 24));
        if(isTemplates){
            await updateTemplateTask(task.id, {duration});
            await loadUpdatedTask(task.id);
            setIsTaskUpdating(false);
            return;
        } else {
            await api.get().tasksApi.taskPatch([`id:${task.id}`], {
                startDate: task.start,
                endDate: task.end,
                duration
            });
        }
        task.duration = duration;
        let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
        if (task.project) {
            const [start, end] = getStartEndDateForProject(newTasks, task.project);
            const project = newTasks[newTasks.findIndex((t) => t.id === task.project)];
            if (
                project.start.getTime() !== start.getTime() ||
                project.end.getTime() !== end.getTime()
            ) {
                const changedProject = {...project, start, end};
                newTasks = newTasks.map((t) =>
                    t.id === task.project ? changedProject : t
                );
            }
        }
        setTasks(newTasks);
        handleEditTask(task);
        setIsTaskUpdating(false);
    };

    const onTaskUpdate = async (handleDelete) => {
            onRefresh(editTask?.id, handleDelete);
            if (handleDelete) {
                // redirect if it was the last task
                tasks.length === 1 && navigate(navigatePath);
                setIsLoading(true);
                setPhases([]);
                setTasks([]);
                setExpandedTasks({});
                setEditTask(null);
                await loadInitData();
            } else {
                await loadUpdatedTask(editTask?.id);
            }
        setIsTaskUpdating(false);
    }

    const updateTasks = (task) => {
        let newTasks;
        newTasks = removeDependency(task)
        if (hasPhases) {
            let phase = tasks.find((t) => t.id === `phase-${task.taskPhaseId}`);
            phase.tasks = phase.tasks.map((t) => (t.id === task.id ? task : t));
            phase.progress = getProgress(phase);
            const [start, end] = getStartEndDateForProject(newTasks, task.project);
            if (
                phase.start.getTime() !== start.getTime() ||
                phase.end.getTime() !== end.getTime()
            ) {
                phase = {...phase, start, end}
            }
            task.project = `phase-${task.taskPhaseId}`;
            newTasks = tasks.map((t) => {
                if (t.id === task.id) {
                    return task;
                } else if (t.id === phase.id) {
                    return phase;
                } else {
                    return t;
                }
            });
            setTasks(setDependencies(newTasks));
        }
        setTasks(setDependencies(newTasks));
    }

    const loadUpdatedTask = async (id) => {
        let task;
        let updatedTask;
        if(isTemplates){
            task = await getUpdatedTask(id);
            task = task.data.data[0];
            let newTasks = [];
            const taskData = tasks.map(t => t.id === id ? task : t);
            newTasks = processTasks(taskData);
            task = newTasks.find((t) => t.id === task.id);
            if (hasPhases) {
                let phase = newTasks.find((t) => t.id === `phase-${task.phaseId}`);
                phase.tasks = phase.tasks.map((t) => (t.id === task.id ? task : t));
                phase.progress = getProgress(phase);
                task.project = `phase-${task.phaseId}`;
                const [start, end] = getStartEndDateForProject(newTasks, task.project);
                if (
                    phase.start.getTime() !== start.getTime() ||
                    phase.end.getTime() !== end.getTime()
                ) {
                    phase = {...phase, start, end}
                }
                newTasks = newTasks.map((t) => {
                    if (t.id === task.id) {
                        return task;
                    } else if (t.id === phase.id) {
                        return phase;
                    } else {
                        return t;
                    }
                });
            }
            setTasks(newTasks);
            setHandleTemplateUpdated(id);
            return;
        } else {
            updatedTask = await apiClient.get().tasksApi.taskList(
                [],
                [`+id`],
                ['active:true', `id:${id}`],
                ['lastMessage', 'taskLinkage'],
                1,
                1
            );
        }
        task = updatedTask.data.data[0];
            task.dueDate = task.dateDueTo && moment(Date.parse(task.dateDueTo)).format("MMMM DD, YYYY");
            task.assignees = [
                {
                    assigneeName: task.assigneeFirstName ? `${task.assigneeFirstName} ${task.assigneeLastName}` : '',
                    assigneeId: task.assigneeId,
                    fileName: task.assigneeFileName
                },
                {
                    assigneeName: task.assignee2FirstName ? `${task.assignee2FirstName} ${task.assignee2LastName}` : '',
                    assigneeId: task.assigneeId2,
                    fileName: task.assignee2FileName
                }];
            task.createdBy = task.userFirstName ? `${task.userFirstName} ${task.userLastName}` : '';
            task.start = new Date(task.startDate);
            task.projectPrefix = task.prefix;
            task.phaseId = task.taskPhaseId;
            task.prefix = task.key;
            task.end = new Date(task.endDate);
            task.progress = task.status === 'DONE' ? 100 : '0';
            task.type = "task";
            task.styles = getTaskStyles(task.priority);
            task.project = `phase-${task.taskPhaseId}`;
            updateTasks(task);
            setEditTask(task);
    }

    const handleExpanderClick = (task) => {
        setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
        setExpandedPhases([...expandedPhases, task])
        setExpandedTasks((prevExpandedTasks) => {
            return {
                ...prevExpandedTasks,
                [task.id]: !expandedTask[task.id],
            };
        });
    };

    return (
        <Spin spinning={isTaskUpdating}>
    <div className="chart-list-container" data-testid="chart-list-container">
        <ViewSwitcher
            onViewModeChange={(viewMode) => setView(viewMode)}
            onViewListChange={setIsChecked}
            isChecked={isChecked}
        />
            {isLoading && tasks.length === 0 ?
                <div className="template-summary-page-loader"><Spin size="large"/></div> :
                <Layout style={{background: 'none', height: 'calc(100vh - 300px)'}}>
                    <Content className={isTemplates && 'templates-chart'} style={{overflow: 'hidden', background: 'none'}}>
                        <Gantt
                            tasks={tasks}
                            viewMode={view}
                            onDateChange={handleTaskChange}
                            ganttHeight={'calc(100vh - 400px)'}
                            nonCollapsedTasks={nonCollapsedTasks}
                            onExpanderClick={handleExpanderClick}
                            listCellWidth={isChecked ? "295px" : ""}
                            columnWidth={columnWidth}
                            onClick={handleEditTask}
                            useTooltip={false}
                            rowHeight={48}
                            fontFamily="Lato, sans-serif"
                            todayColor="#E6F7FF"
                            timeStep={100}
                            TaskListHeader={getTaskListHeader}
                            TaskListTable={getTaskListTable}
                            TooltipContent={getTooltipComponent}
                        />
                    </Content>
                    {editTask && <Sider style={{background: 'none'}} width="441px"><EditTaskSidebar
                        loggedUserId={accessScope.userId}
                        showDetailsModal={showDetailsModal}
                        customFormat={customFormat}
                        key={editTask.id}
                        api={apiClient}
                        task={editTask}
                        onTaskUpdating={setIsTaskUpdating}
                        refresh={onTaskUpdate}
                        users={users}
                        orgId={1 ?? editTask?.orgId}
                        isShortUrl={true}
                        onModalClose={onModalClose}
                        isTemplates={isTemplates}
                        templateId={id}
                        closeSidebar={() => {
                            setEditTask(false);
                            setShowDetailsModal(false)
                        }}
                    /></Sider>}
                </Layout>}
    </div>
        </Spin>
    );
}
