import './addSingleClientGroupsButton.css';

import {Button, Checkbox, Dropdown, Input, message} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import React, {useEffect, useReducer, useRef, useState} from "react";

import {initialState, addSingleClientToGroupsReducer, ACTIONS} from "./addSingleClientToGroupsReducer";
import {getShortLabel} from "../utils";
import ClientsController from "../../controllers/clientsController";
import GroupsController from "../../controllers/groupsController";

const staticState = {
    page: 1,
    hasNextPage: true
}

const AddSingleClientToGroupsButton = ({onAddClients, currentGroup}) => {

    const clientsController = new ClientsController({});
    const groupsController = new GroupsController({});

    const [state, dispatch] = useReducer(addSingleClientToGroupsReducer, initialState);
    const clientsRef = useRef(null);

    const Search = Input.Search;

    const [isClientsDropdownVisible, setIsClientsDropdownVisible] = useState(false);
    const [selectedClients, setSelectedClients] = useState([]);

    useEffect(async () => {
        if (isClientsDropdownVisible) {
            await loadClients(true);
        }
    }, [isClientsDropdownVisible]);

    useEffect(() => {
        setSelectedClients([]);
    }, [currentGroup]);

    const loadClients = async (resetResults) => {
        if (!staticState.hasNextPage) { return; }
        const loadedClients = await clientsController.listClients(staticState.page, state.searchValue, currentGroup?.id);
        const convertedClients = loadedClients.map(client => {
            return {
                id: client.id,
                name: client.name,
                fileName: client.fileName
            }
        });
        staticState.hasNextPage = loadedClients.length > 0;
        let results = [];
        if (resetResults) {
            results = convertedClients;
        } else {
            return JSON.parse(JSON.stringify(state.clientsList)).concat(convertedClients);
        }
        dispatch({type: ACTIONS.CLIENTS_LIST, payload: results});
    }

    const resetState = () => {
        staticState.page = 1;
        staticState.hasNextPage = true;
        dispatch({type: ACTIONS.RESET_STATE})
    }

    const selectClient = (isChecked, client) => {
        setSelectedClients(
            isChecked ?
                [...selectedClients, client.id] :
                [...selectedClients.filter(el => el !== client.id)]
        )
    }

    const getMenuItems = () => {
        return state.clientsList?.map(c => {
            const isSelected = selectedClients.find((e) => e === c.id);
            return <div key={c.id} className="menu-item-wrapper">
                <Checkbox data-testid={`client-${c.id}`} checked={isSelected} onChange={(e) => selectClient(e.target.checked, c)}/>
                <div>{getShortLabel(c.name)}</div>
            </div>
        })
    }

    const onSearch = async () => {
        staticState.hasNextPage = true;
        staticState.page = 1;
        await loadClients(true);
    }

    const setSearchValue = (value) => {
        dispatch({type: ACTIONS.SEARCH_VALUE, payload: value});
    }

    const loadDataIfNeeded = async () => {
        const target = clientsRef.current;
        const bottom = target.scrollHeight - target.scrollTop === target.clientHeight;
        if (bottom && staticState.hasNextPage) {
            staticState.page += 1;
            await loadClients();
        }
    }

    const getClientsSearchOverlay = () => {
        const items = getMenuItems();
        return <div style={{minWidth: '248px'}} className="app-header-dropdown">
            <Search
                placeholder="Search"
                onSearch={onSearch}
                onPressEnter={onSearch}
                className="clients-search-wrapper search-wrapper"
                data-testid="search"
                value={state.searchValue}
                onChange={(e) => {
                    setSearchValue(e.target.value);
                }}
            />
            <div style={{height: '160px', overflowY: 'scroll'}} ref={clientsRef}
                 onScroll={loadDataIfNeeded}>{items}</div>
            <div className="add-button-wrapper">
                <div className="underline"/>
                <Button type="primary"
                        disabled={selectedClients.length < 1}
                        className="add-to-group-button"
                        onClick={() => addClientsToGroup(currentGroup, selectedClients)}
                        icon={<PlusOutlined style={{fontSize: '16px'}}/>}
                        data-testid="add-client-to-group">
                    Add
                </Button>
            </div>
        </div>
    }

    const addClientsToGroup = async (group, selectedClients) => {
        const reloadData = async () => {
            resetState();
            setIsClientsDropdownVisible(false);
            onAddClients();
        }
        const promises = [];
        if (selectedClients) {
            selectedClients.map(clientId => {
                promises.push(new Promise((resolve) => {
                    const result = groupsController.addClientToGroup(clientId, group.id);
                    resolve(result);
                }))
            })
        }
        Promise.all(promises).then(() => {
            reloadData();
        });
        message.success('Clients added to group successfully');
    }

    const dropdownStateChanged = async (isOpened) => {
        setIsClientsDropdownVisible(isOpened);
        if (!isOpened) {
            resetState();
        }
    }

    return (
        <Dropdown
            onOpenChange={dropdownStateChanged}
            destroyPopupOnHide={true}
            open={isClientsDropdownVisible}
            placement='bottomRight'
            trigger='click'
            dropdownRender={getClientsSearchOverlay}>
            <Button type="primary" icon={<PlusOutlined style={{fontSize: '16px'}}/>}
                    data-testid="add-client">Add to Group</Button>
        </Dropdown>
    )
}

export default AddSingleClientToGroupsButton;