import ApiClient from "../apiClient";

export const GENERAL_ERROR = 'GENERAL_ERROR';

export default class NotificationsController {
    apiClient = null;

    constructor(props) {
        this.apiClient = props?.apiClient || new ApiClient();
    }

    listNotifications = async (dateRange, receiverId) => {
        try {
            let filter = ['active:true', `receiverId:${receiverId}`, `dateCreated:${dateRange}`]
            const loadedNotifications = await this.apiClient.get().notificationsApi.notificationList(
                '',
                [`-dateCreated`],
                filter,
                [],
                1,
                50
            );
            return loadedNotifications.data?.data;
        } catch (e) {
            console.error(e);
            return GENERAL_ERROR;
        }
    }

    markNotificationAsRead = async (notificationId) => {
        try {
            await this.apiClient.get().notificationsApi.notificationPatch([`id:${notificationId}`], {isSeen:true});
        } catch (e) {
            console.error(e);
            return GENERAL_ERROR
        }
    }

    markAllNotificationAsRead = async (notifications) => {
        const notificationIds = notifications.map(n => `id:${n.id}`);
        try {
            await this.apiClient.get().notificationsApi.notificationPatch(notificationIds, {isSeen:true});
        } catch (e) {
            console.error(e);
            return GENERAL_ERROR
        }
    }
}