import ApiClient from "../apiClient";

export const GENERAL_ERROR = 'GENERAL_ERROR';

export default class TemplateTaskController {
    apiClient = null;

    constructor(props) {
        this.apiClient = props?.apiClient || new ApiClient();
    }

    listTemplateTask = async (page, search, templateId, taskFilter, expand, templateTaskPhaseId) => {
        try {
            let filter = ['active:true'];
            if (templateId) {
                filter.push(`templateId: ${templateId}`);
            }
            if (templateTaskPhaseId) {
                filter.push(`templateTaskPhaseId: ${templateTaskPhaseId}`);
            }
            if (taskFilter) {
                filter.push(...taskFilter);
            }
            const loadedData = await this.apiClient.get().templateTasksApi.templateTasksList(
                search,
                [`+id`],
                filter,
                expand ?? [],
                page,
                50
            );
            return  {
                data: loadedData.data.data,
                page: loadedData.data.page.next,
                hasNextPage: loadedData.data.page.next !== null
            };
        } catch (e) {
            console.error(e);
            return GENERAL_ERROR;
        }
    }

    loadUpdatedTask = async (id) => {
        try {
            return await this.apiClient.get().templateTasksApi.templateTasksList(
                [],
                [`+id`],
                ['active:true', `id: ${id}`],
                ['templateTaskLinkage'],
                1,
                1
            );
        } catch (e) {
            console.error(e);
            return GENERAL_ERROR;
        }
    }

    patchTemplateTask = async (id, params) => {
        return await this.apiClient.get().templateTasksApi.templateTasksPatch([`id:${id}`], params);
    }

}