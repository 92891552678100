import ProjectsStatusReportController from "../controllers/projectsStatusReportController";

export const REPORT_SCHEDULE_TYPE = {
    NOT_SET: 'NOT_SET',
    PERIODICITY: 'PERIODICITY',
}

export const REPORT_SCHEDULE_PERIODICITY = {
    DAY: 'DAY',
    WEEK: 'WEEK',
    MONTH: 'MONTH',
}

// ISO Week days start from 1 (Monday) to 7 (Sunday)
export const DAY_OF_WEEK_OPTIONS = [
    {label: 'Monday', value: 1},
    {label: 'Tuesday', value: 2},
    {label: 'Wednesday', value: 3},
    {label: 'Thursday', value: 4},
    {label: 'Friday', value: 5},
    {label: 'Saturday', value: 6},
    {label: 'Sunday', value: 7},
];

export const DAY_OF_MONTH_OPTIONS = [...Array(31)].map((each, index) => {
    return {label: index + 1, value: index + 1};
})
export const reportScheduleDefault  = {
    type: REPORT_SCHEDULE_TYPE.PERIODICITY,
    periodicity: REPORT_SCHEDULE_PERIODICITY.DAY,
    frequency: 1,
    week_day: [5],
    month_day: 1,
}

const useProjectStatusReportOperator = () => {

    const projectsStatusReportController = new ProjectsStatusReportController();

    const getSchedule = async (projectId) => {
        const project = await projectsStatusReportController.getById(projectId);
        return project?.statusReportSchedule ? project.statusReportSchedule : reportScheduleDefault;
    }

    const setSchedule = async (projectId, schedule) => {
        await projectsStatusReportController.setSchedule(projectId, schedule);
        return getSchedule(projectId)
    }

    const schedulePeriodicityText = (reportScheduleState) => {
        const frequency = reportScheduleState.frequency > 1 ? reportScheduleState.frequency + ' ' : '';
        const units = reportScheduleState.periodicity === REPORT_SCHEDULE_PERIODICITY.WEEK ? 'week' : 'month';
        if(reportScheduleState.type === REPORT_SCHEDULE_TYPE.NOT_SET) {
            return 'Schedule is not set';
        } else if(reportScheduleState.periodicity === REPORT_SCHEDULE_PERIODICITY.DAY) {
            return `Every ${reportScheduleState.frequency} day`;
        } else if(reportScheduleState.periodicity === REPORT_SCHEDULE_PERIODICITY.WEEK) {
            let quantity = reportScheduleState.week_day.length > 1
                ? 'selected days'
                : DAY_OF_WEEK_OPTIONS.find(d => d.value === reportScheduleState.week_day[0]).label;
            return `Every ${frequency}${units} on ${quantity}: `;
        } else if(reportScheduleState.periodicity === REPORT_SCHEDULE_PERIODICITY.MONTH) {
            let quantity = reportScheduleState.month_day + ' day';
            return `Every ${frequency}${units} on ${quantity}: `;
        }
    }

    return {getSchedule, setSchedule, schedulePeriodicityText};

}

export default useProjectStatusReportOperator;
