import './projectStatusReport.css';
import ApiClient from "../../apiClient";
import React, {useCallback, useEffect, useState} from "react";
import {Button, Layout, message, Select} from 'antd';
import {useParams} from "react-router-dom";
import {DownloadOutlined, LeftOutlined, RightOutlined} from '@ant-design/icons';
import {Content} from "antd/es/layout/layout";
import noReports from "../../assets/images/no-reports.svg";
import EmptyPage from "../emptyPage/emptyPage";
import moment from "moment";
import ProjectStatusReportSchedule from "../projectStatusReportSchedule/projectStatusReportSchedule";
import {reportScheduleInitialState} from "../projectStatusReportSchedule/projectStatusReportScheduleReducer";
import useProjectStatusReportOperator, {REPORT_SCHEDULE_TYPE} from "../../hooks/useProjectStatusReportOperations";
import useQuery from "../../hooks/useQuery";
import {useRecoilValue, useSetRecoilState} from "recoil";
import projectStatusReportNotificationsState from "../../atoms/projectStatusReportNotification";
import seenNotificationsState from "../../atoms/seenNotifications";

const ProjectStatusReport = ({accessScope, loggedUser, api}) => {
    const {projectId} = useParams();
    const query = useQuery();
    const apiClient = api ?? new ApiClient();
    const [statusReports, setStatusReports] = useState([]);
    const [statusReportHtml, setStatusReportHtml] = useState(null);
    const [statusReportIndex, setStatusReportIndex] = useState(-1);
    const [reportScheduleState, setReportScheduleState] = useState(reportScheduleInitialState);
    const projectStatusReportNotifications = useRecoilValue(projectStatusReportNotificationsState);
    const setSeenNotifications = useSetRecoilState(seenNotificationsState);

    const {getSchedule, setSchedule, schedulePeriodicityText} = useProjectStatusReportOperator();

    useEffect(async () => {
        let indexJson = []
        if(projectId) {
            try {
                indexJson = await reportDownloadIndex();
            } finally {
                setStatusReports(indexJson)
            }
        } else {
            setStatusReports([])
        }
        setReportScheduleState(await getSchedule(projectId));
    }, [projectId]);

    useEffect(() => {
        if(statusReports.length > 0) {
            const reportDate = query.get('reportDate');
            const reportIndex = statusReports.findIndex(r => r['report_date'] === reportDate);
            setStatusReportIndex(reportIndex !== -1 ? reportIndex : 0)
        } else {
            setStatusReportIndex(-1);
        }
    }, [query, statusReports]);

    useEffect(async () => {
        if(statusReportIndex !== -1) {
            let reportHtml= null;
            try {
                reportHtml = await reportDownloadHtml();
            } finally {
                setStatusReportHtml(reportHtml)
            }
        } else {
            setStatusReportHtml(null);
        }
    }, [statusReports, statusReportIndex]);

    useEffect(() => {
        if(projectId) {
            const statusReportNotification = projectStatusReportNotifications
                .filter(n => n.params.projectId.toString() === projectId.toString());
            setSeenNotifications(statusReportNotification);
        }
    }, [projectStatusReportNotifications, projectId, setSeenNotifications]);

    const onStatusReportIndexPrev = useCallback(() => {
        setStatusReportIndex(statusReportIndex + 1)
    }, [statusReportIndex])

    const onStatusReportIndexNext = useCallback(() => {
        setStatusReportIndex(statusReportIndex - 1)
    }, [statusReportIndex])

    const onStatusReportIndexSetDate = useCallback((e) => {
        setStatusReportIndex(statusReports.findIndex(r => r['report_date'] === e))
    }, [statusReportIndex])

    const onReportScheduleSave = useCallback(async (schedule) => {
        await setSchedule(projectId, schedule);
        setReportScheduleState(schedule);
    }, [projectId, reportScheduleState])

    const handleCustomAddButton = useCallback(() => {
        return reportScheduleState.type === REPORT_SCHEDULE_TYPE.NOT_SET
            ? <ProjectStatusReportSchedule reportScheduleState={reportScheduleState}
                                            buttonTitle='Schedule'
                                            onSave={onReportScheduleSave}/>
            : <></>
    }, [projectId, reportScheduleState, onReportScheduleSave])

    function csvToJsonArray(csvString) {
        const rows = csvString.split('\n').filter(l => l !== '');
        const headers = rows[0].split(",");
        return rows.slice(1).map(row => {
            const cells = row.split(",");
            let obj = {};
            headers.forEach((header, index) => {
                obj[header] = cells[index];
            });
            return obj;
        });
    }

    const statusReportDateToValue = (dateString) => {
        const inputDate = moment(dateString, "YYYY-MM-DD");
        const yearFromNow = moment().add(-1, 'years');
        if(inputDate.isAfter(yearFromNow)) {
            return inputDate.format("MMM DD");
        } else {
            return inputDate.format("MMM DD, YYYY");
        }
    };

    const reportDownloadIndex = async () => {
        let indexJson;
        try {
            const fileName = `violetx_status_report/${projectId}/index.csv`
            const response = await apiClient.get().filesApi.imageGetByFileName(fileName, 'datasets');
            const uint8Array = new Uint8Array(response.data.resultBuffer.data);
            const decoder = new TextDecoder("utf-8");
            indexJson = csvToJsonArray(decoder.decode(uint8Array));
        } catch (err) {
            indexJson = [];
        }
        return indexJson;
    }

    const reportDownloadHtml = async () => {
        let reportHtml;
        try {
            const fileName = statusReports[statusReportIndex]['report_html']
            const response = await apiClient.get().filesApi.imageGetByFileName(fileName, 'datasets');
            const uint8Array = new Uint8Array(response.data.resultBuffer.data);
            let decoder = new TextDecoder("utf-8");
            reportHtml = decoder.decode(uint8Array);
        } catch (err) {
            reportHtml = null;
        }
        return reportHtml;
    }

    const reportDownloadPdf = async () => {
        try {
            const fileName = statusReports[statusReportIndex]['report_pdf']
            const response = await apiClient.get().filesApi.imageGetByFileName(fileName, 'datasets');
            const uint8Array = new Uint8Array(response.data.resultBuffer.data);
            const url = window.URL.createObjectURL(new Blob([uint8Array]));
            const a = document.createElement('a')
            a.href = url;
            a.download = `status_report_project_${projectId}.pdf`;
            a.setAttribute('data-testid', 'project-status-report-download-link');
            document.body.appendChild(a);
            a.click();
        } catch (err) {
            message.error(`Error while downloading status report`);
        }
    }

    const emptyPageSubTitle = reportScheduleState.type === REPORT_SCHEDULE_TYPE.NOT_SET
        ? "Please set the schedule for the reports" : '';

    return <div className="project-status-report-container" data-testid="project-status-report-report-container">
        <h1 className="project-status-report-title" data-testid="project-status-report--title">Status report</h1>
        <div className="project-status-report-controls">
            <div className="project-status-report-controls-left">
                <div className="project-status-report-date-container"
                     data-testid="project-status-report-date-container"
                     style={{display: statusReports.length > 0 ? "flex" : "none"}}>
                    <Button className="project-status-report-date-prev"
                            data-testid="project-status-report-date-prev"
                            icon={<LeftOutlined />}
                            onClick={onStatusReportIndexPrev}
                            disabled={statusReportIndex === statusReports.length - 1}
                    />
                    <Select className="project-status-report-date-select"
                            data-testid="project-status-report-date-select"
                            options={statusReportIndex !== -1 ? statusReports.map(index => ({
                                label: statusReportDateToValue(index['report_date']),
                                value: index['report_date']
                            })) : []}
                            value={statusReports[statusReportIndex] ? statusReports[statusReportIndex]['report_date'] : null}
                            disabled={statusReportIndex === -1}
                            onChange={onStatusReportIndexSetDate}
                    />
                    <Button className="project-status-report-date-next"
                            data-testid="project-status-report-date-next"
                            icon={<RightOutlined />}
                            onClick={onStatusReportIndexNext}
                            disabled={statusReportIndex === 0}
                    />
                </div>
                <div className="project-status-report-schedule-container">
                    <span className="project-status-report-schedule-label"
                          data-testid="project-status-report-schedule-label">
                        {schedulePeriodicityText(reportScheduleState)}
                    </span>
                    <ProjectStatusReportSchedule reportScheduleState={reportScheduleState}
                                                 onSave={onReportScheduleSave}/>
                </div>
            </div>
            <div className="project-status-report-controls-right">
                <Button className="project-status-report-download" data-testid='project-status-report-download'
                        disabled={statusReports.length <= 0}
                        onClick={reportDownloadPdf}>
                    <DownloadOutlined />Download report</Button>
            </div>
        </div>
        <Layout style={{background:'none', height: 'calc(100vh - 250px)'}}>
            <Content style={{overflow:'auto', background:'none'}}>
                {statusReportHtml === null
                    ? <EmptyPage
                        title="There are no reports yet"
                        image={noReports}
                        dataTestId="project-status-report-empty-page"
                        subTitle={emptyPageSubTitle}
                        isReadOnly={false}
                        customAddButton={handleCustomAddButton}
                    />
                    : <div className="project-status-report-content" data-testid="project-status-report-content">
                        <div className="project-status-report-content-html" data-testid="project-status-report-content-html"
                             dangerouslySetInnerHTML={{__html: statusReportHtml}}/>
                    </div>
                }
            </Content>
        </Layout>
    </div>

}

export default ProjectStatusReport;
