import './vulnerabilitiesExport.css';

import {Button, Dropdown, Modal, Progress, Typography} from 'antd';
import {CloseCircleFilled, DownloadOutlined, ExportOutlined} from '@ant-design/icons';
import exportFromJSON from 'export-from-json'
import {useEffect, useState} from "react";
import {formatDate} from "../utils";

const VulnerabilitiesExport = ({ disabled, apiClient, deviceId }) => {

    const EXPORT_TYPES = {
        csv: { title: '.CSV', type: exportFromJSON.types.csv },
        txt: { title: '.TXT', type: exportFromJSON.types.txt },
        json: { title: '.JSON', type: exportFromJSON.types.json },
    }

    const EXPORT_STATES = {
        NONE: 'NONE',
        PROGRESS: 'PROGRESS',
        DOWNLOAD: 'DOWNLOAD',
        ERROR: 'ERROR',
    }

    const [exportState, setExportState] = useState(EXPORT_STATES.NONE);
    const [exportPercent, setExportPercent] = useState(0);
    const [exportType, setExportType] = useState(null);

    let exportError = '';

    const [data, setData] = useState([]);

    const headers = [
        {label: 'Detected', key: 'timestamp'},
        {label: 'CVE ID', key: 'cveId'},
        {label: 'Name', key: 'name'},
        {label: 'Source', key: 'VioletX Agent'},
        {label: 'Severity', key: 'severity'},
    ];

    const doCancel = () => {
        setData([]);
        setExportType(null);
        setExportState(EXPORT_STATES.NONE);
        setExportPercent(0);
        exportError = '';
    }

    const doDownload = () => {
        const fileName = 'vulnerabilities';
        const type = exportType.type;
        exportFromJSON({ data, fileName, exportType: type });
        setExportState(EXPORT_STATES.NONE);
    }

    const getVulnerabilitiesPage = async (page) => {
        const result = await apiClient.get().scanResultsApi.scanResultList(
            [],
            [],
            [`scanTarget:VULNERABILITIES`, `deviceId:${deviceId}`],
            ['resultExpand', 'deviceInfo'],
            page ?? 1,
            10
        );
        const data = [...result.data.data.map(d => {return {...d.result, id: d.resultExpandIdx }})];
        return { data: data, page: result.data.page };
    }

    const getSeverity = (severity) => {
        let title;
        if(Number(severity) >= 9.0) {
            title = 'Critical';
        } else if(Number(severity) >= 7.0) {
            title = 'High';
        } else if(Number(severity) >= 4.0) {
            title = 'Medium';
        } else if(Number(severity) >= 0.1) {
            title = 'Low';
        } else {
            title = 'Info';
        }
        return title;
    }

    const extractExportColumns = (rawData) => {

        return rawData.map(d => {

            const timestamp = formatDate(d.timestamp);
            const cveId = d.cveId;
            const name = `${d.cveId} ${d.name}`;
            const source = 'VioletX Agent';
            const severity = getSeverity(d.severity);

            return {
                timestamp,
                cveId,
                name,
                source,
                severity
            }
        });
    }

    const getVulnerabilitiesResults = async () => {
        const vulnerabilities = [];
        try {
            let page = 1;
            do {
                const result = await getVulnerabilitiesPage(page);
                const parsedData = extractExportColumns(result.data);
                if(exportState !== EXPORT_STATES.NONE) {
                    vulnerabilities.push(...parsedData);
                    const lastPage = result.page.last || 1;
                    setExportPercent((page / lastPage) * 100);
                    page = result.page.next;
                } else {
                    page = null;
                }
            } while (page !== null);
            if(exportState !== EXPORT_STATES.NONE) {
                setData(vulnerabilities);
                if (data.length > 0) {
                    headers.push(
                        ...Object.keys(data[0])
                            .map((key) => {
                                return {label: key, key: key};
                            })
                    );
                }
                setTimeout(() => {
                    setExportState(EXPORT_STATES.DOWNLOAD);
                }, 500);
            }
        } catch (e) {
            exportError = e.message;
            setExportState(EXPORT_STATES.ERROR);
        }
    }

    const doExport = async (exportType) => {
        setData([]);
        setExportType(exportType);
        setExportPercent(0);
        exportError = '';
        setExportState(EXPORT_STATES.PROGRESS);
    }

    useEffect(async () => {
       if (exportState === EXPORT_STATES.PROGRESS) {
           await getVulnerabilitiesResults();
       }
    }, [exportState]);

    const items = [
        { key: `json`, label: <div className="query-export-menu-item-json" onClick={() => doExport(EXPORT_TYPES.json)}>{EXPORT_TYPES.json.title}</div>},
        { key: `csv`, label: <div className="query-export-menu-item-csv" onClick={() => doExport(EXPORT_TYPES.csv)}>{EXPORT_TYPES.csv.title}</div>},
    ];

    return <div className="vulnerabilities-export-container">
        <Dropdown className="vulnerabilities-export-dropdown" disabled={disabled}
                  menu={{items}}>
            <Button className="vulnerabilities-export-container-btn" data-testid="export-as-button">
                <ExportOutlined /> Export as
            </Button>
        </Dropdown>
        <Modal centered
               className="vulnerabilities-export-modal-dialog"
               data-testid="vulnerabilities-export-modal-dialog"
               open={exportState !== EXPORT_STATES.NONE}
               closable={false}
               footer={null}>
            <span className="vulnerabilities-export-modal-title">Exporting as {exportType?.title}</span>
            <Button shape="circle"
                    className="vulnerabilities-export-modal-close-btn"
                    data-testid="modal-close"
                    icon={<CloseCircleFilled className="query-export-modal-close-btn-icon"/>}
                    onClick={doCancel}/>
            <div className="vulnerabilities-export-modal-body">
                {exportState === EXPORT_STATES.PROGRESS
                    ? <Progress size="small" status="active"
                                className="query-export-modal-progress"
                                strokeColor={"#1890FF"}
                                showInfo={false}
                                percent={exportPercent} />
                    : null}
                {exportState === EXPORT_STATES.DOWNLOAD
                    ? <Button className="vulnerabilities-export-modal-download-btn"
                              onClick={doDownload}>
                        <DownloadOutlined /> Download</Button>
                    : null}
                {exportState === EXPORT_STATES.ERROR
                    ? <Typography.Text
                        className="vulnerabilities-export-modal-error"
                        type="danger">Export error: {exportError}</Typography.Text>
                    : null}
            </div>
        </Modal>
    </div>
}

export default VulnerabilitiesExport;