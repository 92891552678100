import './user.css';
import { MailOutlined } from '@ant-design/icons';
import ChildSidebarPage from "../childSidebarPage/childSidebarPage";
import {useNavigate} from "react-router-dom";
import {Spin, message} from 'antd';
import {useEffect, useState} from "react";
import ApiClient from "../../apiClient";
import SummaryHeader from "../summaryHeader/summaryHeader";
import {useRecoilState, useSetRecoilState} from 'recoil';
import userSummaryState from "../../atoms/userSummary";
import UserSummary from "../userSummary/userSummary";
import DevicesList from "../devicesList/devicesList";
import {showDeactivateConfirm} from "../utils";
import {UserAvatar} from "../userAvatar/userAvatar";
import isResetPasswordSentState from "../../atoms/isResetPasswordSent";

const UserPage = ({api, match, accessScope}) => {

    const apiClient = api ? api : new ApiClient();
    const navigate = useNavigate();
    const userId = match.params.userId;
    const orgId = match.params.orgId;
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [defaultSelectedPage, setDefaultSelectedPage] = useState(null);
    const [page, setPage] = useState('');

    const [userSummary] = useRecoilState(userSummaryState);
    const setIsResetPasswordSent = useSetRecoilState(isResetPasswordSentState);
    const pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    const tabs = [
        { "data-testid": "tab-user-summary", key: "info", title: 'Personal info', label: 'Personal info', icon: <MailOutlined />, disabled: false, page: <UserSummary accessScope={accessScope} user={user} pattern={pattern} doUserActionEmail={doUserActionEmail}/>},
        { "data-testid": "tab-user-devices", key: "devices", title: 'Devices', label: 'Devices', icon: <MailOutlined />, disabled: !userId, page: <DevicesList orgId={orgId} api={apiClient} user={user} disabled={false} userId={userId}/>},
        { "data-testid": "tab-user-applications", key: "tab-user-applications", title: 'Applications', label: 'Applications', icon: <MailOutlined />, disabled: true},
        { "data-testid": "tab-user-projects", key: "tab-user-projects", title: 'Projects', label: 'Projects', icon: <MailOutlined />, disabled: true},
        { "data-testid": "tab-user-trainings", key: "tab-user-trainings", title: 'Trainings', label: 'Trainings', icon: <MailOutlined />, disabled: true},
        { "data-testid": "tab-user-documents", key: "tab-user-documents", title: 'Documents', label: 'Documents', icon: <MailOutlined />, disabled:true},
        { "data-testid": "tab-user-accounts", key: "tab-user-accounts", title: 'Accounts', label: 'Accounts', icon: <MailOutlined />, disabled: true},
        { "data-testid": "tab-user-permissions", key: "tab-user-permissions", title: 'Permissions', label: 'Permissions', icon: <MailOutlined />, disabled: true},
    ];

    const getImage = () => {
        return <div className="user-summary query-icon position-right">
            <UserAvatar name={`${user?.firstName} ${user?.lastName}`} fileName={user?.fileName}/>
        </div>;
    }

    useEffect(() => {
        const path = match.pathname.split('/');
        const newPage = path[path.length - 1];
        setDefaultSelectedPage(tabs.find((t) => t.key === newPage));
        setPage(newPage);
    }, [match]);

    useEffect(() => {
        let disableSave = true;
        if (userSummary) {
            const isEmailValid = pattern.test(userSummary.email)
            if (!isEmailValid) {
                return setDisabled(true);
            }
            if (!user) {
                disableSave = !(userSummary.firstName?.trim() && userSummary.lastName?.trim() && userSummary.type)
            } else if (userSummary.firstName?.trim().length <= 0 || userSummary.lastName?.trim().length <= 0) {
                return setDisabled(true);
            } else {
                Object.entries(userSummary).forEach((item) => {
                    if (item[1] !== user[item[0]]) {
                        disableSave = false;
                    }
                })
            }
        }
        setDisabled(disableSave)
    }, [userSummary])

    const navigateToUserList = () => {
        navigate(`/clients/${orgId}/users`);
    }

    const onDelete = () => {
        showDeactivateConfirm('user', () => onDeactivate());
    }

    async function onSave() {
        const userParams = {
            email: userSummary.email.toLowerCase(),
            firstName: userSummary.firstName,
            lastName: userSummary.lastName,
            type: userSummary.type,
            roleId: userSummary.roleId,
            jobTitle: userSummary.jobTitle
        };
        const userToCreate = {
            organizationId: orgId,
            roleId: 2,
            active: true,
            ...userParams
        }
        try {
            if (user) {
                await apiClient.get().userApi.userPatch([`id:${userId}`], userParams);
                await loadUserInfo();
                await message.success('User has been successfully updated');
            } else {
                const response = await apiClient.get().userApi.userCreate(userToCreate);
                await executeActionEmail(response.data.userId);
                navigate(`/clients/${orgId}/users/${response.data.userId}/info`);
                message.success('User has been successfully created');
            }
        } catch (err) {
            if (err.response?.data?.details?.["userParams.email"]) {
                message.error('The email is not unique');
            } else {
                message.error('Unable to save user');
            }
        }
    }

    async function onDeactivate() {
        try {
            await apiClient.get().userApi.userPatch(`id:${userId}`, {active: false});
            message.success(`User has been successfully deleted`);
            navigateToUserList();
        } catch (err) {
            message.error(`An error occurred while try to delete user`);
        }
    }

    async function doUserActionEmail() {
        if(userId) {
            await executeActionEmail(userId);
        }
        await loadUserInfo();
    }

    const loadUserInfo = async () => {
        setIsLoading(true);
        const filter = ['active:true', `id:${userId}`, `organizationId:${orgId}`];
        const user = await apiClient.get().userApi.userList(
            '',
            [],
            filter,
            ['deviceCount', "userEmail"],
            1,
            1
        );
        const userData = user.data.data[0];
        if (!userData) {
            return navigateToUserList();
        }
        setUser(userData);
        setIsLoading(false);
    }

    const executeActionEmail = async (userId) => {
        try {
            await apiClient.get().authApi.executeActionsEmail({
                userId: userId,
                redirectUri: window.location.origin,
            });
            message.success('The invite link successfully sent to user');
            setIsResetPasswordSent(true);
        } catch (err) {
            message.error('Unable to send invite link to user');
        }
    }

    useEffect(async () => {
        if (userId) {
            await loadUserInfo();
        } else {
            setIsLoading(false);
        }
    }, [userId]);

    if (isLoading) {
        return <div className="scan-spin" data-testid="user-summary-spin">
            <Spin size="large"/>
        </div>
    }

    return <ChildSidebarPage
        childSideBarStyle={{
            width: '256px',
            height: '100%',
            marginTop: '8px'
        }}
        defaultSelectedKeys={[page]}
        defaultSelectedPage={defaultSelectedPage}
        path={match.pathname}
        className='user-page'
        header={
            <SummaryHeader
                onBack={navigateToUserList}
                disabled={disabled}
                disableDelete={!user}
                name={user ? `${user.firstName} ${user.lastName}` : "User Name"}
                getImage={getImage}
                onSave={onSave}
                onDelete={onDelete}/>}
        sidebarTabs={tabs}
    />
}

export default UserPage;
