/* tslint:disable */
/* eslint-disable */
/**
 * @violetx-mvp/api-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.330.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Assignment
 */
export interface Assignment {
    [key: string]: any | any;

    /**
     * Assignment primary key
     * @type {number}
     * @memberof Assignment
     */
    'id': number;
    /**
     * Assignment organization id
     * @type {number}
     * @memberof Assignment
     */
    'organizationId': number;
    /**
     * 
     * @type {AssignmentAction}
     * @memberof Assignment
     */
    'action': AssignmentAction;
    /**
     * 
     * @type {AssignmentSource}
     * @memberof Assignment
     */
    'source': AssignmentSource;
    /**
     * Assignment source value
     * @type {string}
     * @memberof Assignment
     */
    'sourceValue': string;
    /**
     * 
     * @type {AssignmentTarget}
     * @memberof Assignment
     */
    'target': AssignmentTarget;
    /**
     * 
     * @type {AssignmentTargetType}
     * @memberof Assignment
     */
    'targetType': AssignmentTargetType;
    /**
     * Comma-separated string of assignment target values
     * @type {string}
     * @memberof Assignment
     */
    'targetValue': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AssignmentAction = {
    None: 'NONE',
    Include: 'INCLUDE',
    Exclude: 'EXCLUDE'
} as const;

export type AssignmentAction = typeof AssignmentAction[keyof typeof AssignmentAction];


/**
 * 
 * @export
 * @enum {string}
 */

export const AssignmentExpand = {
    None: 'NONE'
} as const;

export type AssignmentExpand = typeof AssignmentExpand[keyof typeof AssignmentExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface AssignmentFields
 */
export interface AssignmentFields {
    /**
     * 
     * @type {AssignmentAction}
     * @memberof AssignmentFields
     */
    'action'?: AssignmentAction;
    /**
     * Comma-separated string of assignment target values
     * @type {string}
     * @memberof AssignmentFields
     */
    'targetValue'?: string;
}
/**
 * Assignment collection single page representation
 * @export
 * @interface AssignmentPage
 */
export interface AssignmentPage {
    /**
     * 
     * @type {Array<Assignment>}
     * @memberof AssignmentPage
     */
    'data': Array<Assignment>;
    /**
     * 
     * @type {Pagination}
     * @memberof AssignmentPage
     */
    'page': Pagination;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface AssignmentParams
 */
export interface AssignmentParams {
    /**
     * 
     * @type {AssignmentAction}
     * @memberof AssignmentParams
     */
    'action': AssignmentAction;
    /**
     * 
     * @type {AssignmentSource}
     * @memberof AssignmentParams
     */
    'source': AssignmentSource;
    /**
     * Assignment source value
     * @type {string}
     * @memberof AssignmentParams
     */
    'sourceValue': string;
    /**
     * 
     * @type {AssignmentTarget}
     * @memberof AssignmentParams
     */
    'target': AssignmentTarget;
    /**
     * 
     * @type {AssignmentTargetType}
     * @memberof AssignmentParams
     */
    'targetType': AssignmentTargetType;
    /**
     * Comma-separated string of assignment target values
     * @type {string}
     * @memberof AssignmentParams
     */
    'targetValue': string;
    /**
     * Assignment organization id
     * @type {number}
     * @memberof AssignmentParams
     */
    'organizationId': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AssignmentSource = {
    None: 'NONE',
    Scan: 'SCAN',
    ScanTopic: 'SCAN_TOPIC',
    DistributedQuery: 'DISTRIBUTED_QUERY'
} as const;

export type AssignmentSource = typeof AssignmentSource[keyof typeof AssignmentSource];


/**
 * 
 * @export
 * @enum {string}
 */

export const AssignmentTarget = {
    None: 'NONE',
    Devices: 'DEVICES'
} as const;

export type AssignmentTarget = typeof AssignmentTarget[keyof typeof AssignmentTarget];


/**
 * 
 * @export
 * @enum {string}
 */

export const AssignmentTargetType = {
    None: 'NONE',
    DeviceId: 'DEVICE_ID',
    DeviceType: 'DEVICE_TYPE',
    ScanTopic: 'SCAN_TOPIC'
} as const;

export type AssignmentTargetType = typeof AssignmentTargetType[keyof typeof AssignmentTargetType];


/**
 * 
 * @export
 * @interface AuthUser
 */
export interface AuthUser {
    /**
     * 
     * @type {number}
     * @memberof AuthUser
     */
    'userId': number;
    /**
     * 
     * @type {number}
     * @memberof AuthUser
     */
    'organizationId': number;
    /**
     * 
     * @type {number}
     * @memberof AuthUser
     */
    'roleId': number;
    /**
     * 
     * @type {UserStatus}
     * @memberof AuthUser
     */
    'status': UserStatus;
    /**
     * 
     * @type {string}
     * @memberof AuthUser
     */
    'inviteExpired': string | null;
    /**
     * 
     * @type {Array<AuthUserPolicy>}
     * @memberof AuthUser
     */
    'policies': Array<AuthUserPolicy>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AuthUserPermission = {
    Org: 'ORG',
    Own: 'OWN',
    Deny: 'DENY'
} as const;

export type AuthUserPermission = typeof AuthUserPermission[keyof typeof AuthUserPermission];


/**
 * 
 * @export
 * @interface AuthUserPolicy
 */
export interface AuthUserPolicy {
    /**
     * 
     * @type {number}
     * @memberof AuthUserPolicy
     */
    'roleId': number;
    /**
     * 
     * @type {string}
     * @memberof AuthUserPolicy
     */
    'resource': string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserPolicy
     */
    'resourceType': string;
    /**
     * 
     * @type {AuthUserScope}
     * @memberof AuthUserPolicy
     */
    'scope': AuthUserScope;
    /**
     * 
     * @type {AuthUserPermission}
     * @memberof AuthUserPolicy
     */
    'permission': AuthUserPermission;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AuthUserScope = {
    View: 'VIEW',
    Modify: 'MODIFY'
} as const;

export type AuthUserScope = typeof AuthUserScope[keyof typeof AuthUserScope];


/**
 * Bucket name definition
 * @export
 * @enum {string}
 */

export const BucketName = {
    Organizations: 'organizations',
    Templates: 'templates',
    Users: 'users',
    Datasets: 'datasets'
} as const;

export type BucketName = typeof BucketName[keyof typeof BucketName];


/**
 * Device definition.
 * @export
 * @interface Device
 */
export interface Device {
    [key: string]: any | any;

    /**
     * Device primary key
     * @type {number}
     * @memberof Device
     */
    'id': number;
    /**
     * User primary key
     * @type {number}
     * @memberof Device
     */
    'userId': number;
    /**
     * Organization primary key
     * @type {number}
     * @memberof Device
     */
    'organizationId': number;
    /**
     * Device OSQuery client enroll secret
     * @type {string}
     * @memberof Device
     */
    'enrollSecret': string;
    /**
     * 
     * @type {ScanTopic}
     * @memberof Device
     */
    'scanTopic'?: ScanTopic;
    /**
     * Device OS platform key that can be:   darwin     for macOS hosts   freebsd    for FreeBSD hosts   linux      for any RedHat or Debian-based hosts   windows    for any Windows desktop or server hosts   any        for all platform keys
     * @type {string}
     * @memberof Device
     */
    'deviceType'?: string | null;
    /**
     * Device configuration
     * @type {string}
     * @memberof Device
     */
    'deviceConfig'?: string | null;
    /**
     * Device configuration unique id
     * @type {string}
     * @memberof Device
     */
    'deviceConfigId'?: string | null;
    /**
     * OSQuery device information
     * @type {any}
     * @memberof Device
     */
    'deviceInfo'?: any | null;
    /**
     * OSQuery log value timestamp or null
     * @type {string}
     * @memberof Device
     */
    'timestamp'?: string | null;
    /**
     * Device active state
     * @type {boolean}
     * @memberof Device
     */
    'active': boolean;
}
/**
 * Device expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const DeviceExpand = {
    DistributedQueriesCount: 'distributedQueriesCount',
    UserInfo: 'userInfo',
    AssignedScansCount: 'assignedScansCount',
    OrganizationName: 'organizationName'
} as const;

export type DeviceExpand = typeof DeviceExpand[keyof typeof DeviceExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface DeviceFields
 */
export interface DeviceFields {
    /**
     * 
     * @type {ScanTopic}
     * @memberof DeviceFields
     */
    'scanTopic'?: ScanTopic;
    /**
     * Device OS platform key that can be:   darwin     for macOS hosts   freebsd    for FreeBSD hosts   linux      for any RedHat or Debian-based hosts   windows    for any Windows desktop or server hosts   any        for all platform keys
     * @type {string}
     * @memberof DeviceFields
     */
    'deviceType'?: string | null;
    /**
     * Device configuration
     * @type {string}
     * @memberof DeviceFields
     */
    'deviceConfig'?: string | null;
    /**
     * Device configuration unique id
     * @type {string}
     * @memberof DeviceFields
     */
    'deviceConfigId'?: string | null;
    /**
     * OSQuery device information
     * @type {any}
     * @memberof DeviceFields
     */
    'deviceInfo'?: any | null;
    /**
     * OSQuery log value timestamp or null
     * @type {string}
     * @memberof DeviceFields
     */
    'timestamp'?: string | null;
    /**
     * Device active state
     * @type {boolean}
     * @memberof DeviceFields
     */
    'active'?: boolean;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface DeviceParams
 */
export interface DeviceParams {
    /**
     * Organization primary key
     * @type {number}
     * @memberof DeviceParams
     */
    'organizationId': number;
    /**
     * User primary key
     * @type {number}
     * @memberof DeviceParams
     */
    'userId': number;
    /**
     * Device OSQuery client enroll secret
     * @type {string}
     * @memberof DeviceParams
     */
    'enrollSecret': string;
    /**
     * 
     * @type {ScanTopic}
     * @memberof DeviceParams
     */
    'scanTopic'?: ScanTopic;
    /**
     * Device OS platform key that can be:   darwin     for macOS hosts   freebsd    for FreeBSD hosts   linux      for any RedHat or Debian-based hosts   windows    for any Windows desktop or server hosts   any        for all platform keys
     * @type {string}
     * @memberof DeviceParams
     */
    'deviceType'?: string | null;
    /**
     * Device configuration
     * @type {string}
     * @memberof DeviceParams
     */
    'deviceConfig'?: string | null;
    /**
     * Device configuration unique id
     * @type {string}
     * @memberof DeviceParams
     */
    'deviceConfigId'?: string | null;
    /**
     * OSQuery device information
     * @type {any}
     * @memberof DeviceParams
     */
    'deviceInfo'?: any | null;
    /**
     * OSQuery log value timestamp or null
     * @type {string}
     * @memberof DeviceParams
     */
    'timestamp'?: string | null;
    /**
     * Device active state
     * @type {boolean}
     * @memberof DeviceParams
     */
    'active': boolean;
}
/**
 * Device collection single page representation
 * @export
 * @interface DevicesPage
 */
export interface DevicesPage {
    /**
     * 
     * @type {Array<Device>}
     * @memberof DevicesPage
     */
    'data': Array<Device>;
    /**
     * 
     * @type {Pagination}
     * @memberof DevicesPage
     */
    'page': Pagination;
}
/**
 * Devices status result execution statistic
 * @export
 * @interface DevicesStatus
 */
export interface DevicesStatus {
    [key: string]: any | any;

    /**
     * Total count of all active devices
     * @type {number}
     * @memberof DevicesStatus
     */
    'total': number;
}
/**
 * Devices status collection single page representation
 * @export
 * @interface DevicesStatusPage
 */
export interface DevicesStatusPage {
    /**
     * 
     * @type {Array<DevicesStatus>}
     * @memberof DevicesStatusPage
     */
    'data': Array<DevicesStatus>;
    /**
     * 
     * @type {Pagination}
     * @memberof DevicesStatusPage
     */
    'page': Pagination;
}
/**
 * Distributed query collection single page representation
 * @export
 * @interface DistributedQueriesPage
 */
export interface DistributedQueriesPage {
    /**
     * 
     * @type {Array<DistributedQuery>}
     * @memberof DistributedQueriesPage
     */
    'data': Array<DistributedQuery>;
    /**
     * 
     * @type {Pagination}
     * @memberof DistributedQueriesPage
     */
    'page': Pagination;
}
/**
 * Distributed query definition.
 * @export
 * @interface DistributedQuery
 */
export interface DistributedQuery {
    [key: string]: any | any;

    /**
     * Distributed query primary key
     * @type {number}
     * @memberof DistributedQuery
     */
    'id': number;
    /**
     * Organization primary key
     * @type {number}
     * @memberof DistributedQuery
     */
    'organizationId': number;
    /**
     * Distributed query name
     * @type {string}
     * @memberof DistributedQuery
     */
    'name': string;
    /**
     * Distributed query query
     * @type {string}
     * @memberof DistributedQuery
     */
    'query': string;
    /**
     * Query primary key if linked query exist
     * @type {number}
     * @memberof DistributedQuery
     */
    'queryId'?: number | null;
    /**
     * Time in seconds that the distributed query should be expected to be executed
     * @type {number}
     * @memberof DistributedQuery
     */
    'executionTimeout'?: number | null;
}
/**
 * Distributed queries expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const DistributedQueryExpand = {
    DeviceCount: 'deviceCount'
} as const;

export type DistributedQueryExpand = typeof DistributedQueryExpand[keyof typeof DistributedQueryExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface DistributedQueryFields
 */
export interface DistributedQueryFields {
    /**
     * Distributed query name
     * @type {string}
     * @memberof DistributedQueryFields
     */
    'name'?: string;
    /**
     * Distributed query query
     * @type {string}
     * @memberof DistributedQueryFields
     */
    'query'?: string;
    /**
     * Query primary key if linked query exist
     * @type {number}
     * @memberof DistributedQueryFields
     */
    'queryId'?: number | null;
    /**
     * Time in seconds that the distributed query should be expected to be executed
     * @type {number}
     * @memberof DistributedQueryFields
     */
    'executionTimeout'?: number | null;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface DistributedQueryParams
 */
export interface DistributedQueryParams {
    /**
     * Organization primary key
     * @type {number}
     * @memberof DistributedQueryParams
     */
    'organizationId': number;
    /**
     * Distributed query name
     * @type {string}
     * @memberof DistributedQueryParams
     */
    'name': string;
    /**
     * Distributed query query
     * @type {string}
     * @memberof DistributedQueryParams
     */
    'query': string;
    /**
     * Query primary key if linked query exist
     * @type {number}
     * @memberof DistributedQueryParams
     */
    'queryId'?: number | null;
    /**
     * Time in seconds that the distributed query should be expected to be executed
     * @type {number}
     * @memberof DistributedQueryParams
     */
    'executionTimeout'?: number | null;
}
/**
 * Scan definition.
 * @export
 * @interface DistributedQueryResult
 */
export interface DistributedQueryResult {
    [key: string]: any | any;

    /**
     * Distributed query primary key
     * @type {number}
     * @memberof DistributedQueryResult
     */
    'id': number;
    /**
     * Scan primary key
     * @type {number}
     * @memberof DistributedQueryResult
     */
    'distributedQueryId': number;
    /**
     * Device primary key
     * @type {number}
     * @memberof DistributedQueryResult
     */
    'deviceId': number;
    /**
     * User primary key
     * @type {number}
     * @memberof DistributedQueryResult
     */
    'userId': number;
    /**
     * Organization primary key
     * @type {number}
     * @memberof DistributedQueryResult
     */
    'organizationId': number;
    /**
     * 
     * @type {DistributedQueryResultExecute}
     * @memberof DistributedQueryResult
     */
    'execute': DistributedQueryResultExecute;
    /**
     * The time of the distributed query perform to start
     * @type {string}
     * @memberof DistributedQueryResult
     */
    'executionStart'?: string | null;
    /**
     * Time in seconds that the distributed query should be expected to be executed
     * @type {number}
     * @memberof DistributedQueryResult
     */
    'executionTimeout'?: number | null;
    /**
     * The time of the distributed query populated
     * @type {string}
     * @memberof DistributedQueryResult
     */
    'populatedAt'?: string | null;
    /**
     * Distributed query execution status (\'0\'=success)
     * @type {string}
     * @memberof DistributedQueryResult
     */
    'status'?: string | null;
    /**
     * Distributed query execution message if present
     * @type {string}
     * @memberof DistributedQueryResult
     */
    'message'?: string | null;
    /**
     * Distributed query execution result in json format
     * @type {string}
     * @memberof DistributedQueryResult
     */
    'rawData'?: string | null;
    /**
     * Comma separated execution result json fields
     * @type {string}
     * @memberof DistributedQueryResult
     */
    'rawDataFields'?: string | null;
    /**
     * Distributed query execution time
     * @type {string}
     * @memberof DistributedQueryResult
     */
    'timestamp'?: string | null;
}
/**
 * Distributed query execution status
 * @export
 * @enum {string}
 */

export const DistributedQueryResultExecute = {
    None: 'NONE',
    Run: 'RUN',
    Populated: 'POPULATED',
    Completed: 'COMPLETED',
    Failed: 'FAILED',
    Timeout: 'TIMEOUT',
    Stopped: 'STOPPED'
} as const;

export type DistributedQueryResultExecute = typeof DistributedQueryResultExecute[keyof typeof DistributedQueryResultExecute];


/**
 * Distributed query result expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const DistributedQueryResultExpand = {
    UserFirstName: 'userFirstName',
    UserLastName: 'userLastName',
    DeviceType: 'deviceType',
    DeviceInfo: 'deviceInfo',
    DeviceTimestamp: 'deviceTimestamp'
} as const;

export type DistributedQueryResultExpand = typeof DistributedQueryResultExpand[keyof typeof DistributedQueryResultExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface DistributedQueryResultFields
 */
export interface DistributedQueryResultFields {
    /**
     * Distributed query execution time
     * @type {string}
     * @memberof DistributedQueryResultFields
     */
    'timestamp'?: string | null;
    /**
     * Time in seconds that the distributed query should be expected to be executed
     * @type {number}
     * @memberof DistributedQueryResultFields
     */
    'executionTimeout'?: number | null;
    /**
     * 
     * @type {DistributedQueryResultExecute}
     * @memberof DistributedQueryResultFields
     */
    'execute'?: DistributedQueryResultExecute;
    /**
     * The time of the distributed query perform to start
     * @type {string}
     * @memberof DistributedQueryResultFields
     */
    'executionStart'?: string | null;
    /**
     * The time of the distributed query populated
     * @type {string}
     * @memberof DistributedQueryResultFields
     */
    'populatedAt'?: string | null;
    /**
     * Distributed query execution status (\'0\'=success)
     * @type {string}
     * @memberof DistributedQueryResultFields
     */
    'status'?: string | null;
    /**
     * Distributed query execution message if present
     * @type {string}
     * @memberof DistributedQueryResultFields
     */
    'message'?: string | null;
    /**
     * Distributed query execution result in json format
     * @type {string}
     * @memberof DistributedQueryResultFields
     */
    'rawData'?: string | null;
    /**
     * Comma separated execution result json fields
     * @type {string}
     * @memberof DistributedQueryResultFields
     */
    'rawDataFields'?: string | null;
}
/**
 * Distributed query result collection single page representation
 * @export
 * @interface DistributedQueryResultPage
 */
export interface DistributedQueryResultPage {
    /**
     * 
     * @type {Array<DistributedQueryResult>}
     * @memberof DistributedQueryResultPage
     */
    'data': Array<DistributedQueryResult>;
    /**
     * 
     * @type {Pagination}
     * @memberof DistributedQueryResultPage
     */
    'page': Pagination;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface DistributedQueryResultParams
 */
export interface DistributedQueryResultParams {
    /**
     * Organization primary key
     * @type {number}
     * @memberof DistributedQueryResultParams
     */
    'organizationId': number;
    /**
     * User primary key
     * @type {number}
     * @memberof DistributedQueryResultParams
     */
    'userId': number;
    /**
     * Distributed query execution time
     * @type {string}
     * @memberof DistributedQueryResultParams
     */
    'timestamp'?: string | null;
    /**
     * Time in seconds that the distributed query should be expected to be executed
     * @type {number}
     * @memberof DistributedQueryResultParams
     */
    'executionTimeout'?: number | null;
    /**
     * Scan primary key
     * @type {number}
     * @memberof DistributedQueryResultParams
     */
    'distributedQueryId': number;
    /**
     * Device primary key
     * @type {number}
     * @memberof DistributedQueryResultParams
     */
    'deviceId': number;
    /**
     * 
     * @type {DistributedQueryResultExecute}
     * @memberof DistributedQueryResultParams
     */
    'execute': DistributedQueryResultExecute;
    /**
     * The time of the distributed query perform to start
     * @type {string}
     * @memberof DistributedQueryResultParams
     */
    'executionStart'?: string | null;
    /**
     * The time of the distributed query populated
     * @type {string}
     * @memberof DistributedQueryResultParams
     */
    'populatedAt'?: string | null;
    /**
     * Distributed query execution status (\'0\'=success)
     * @type {string}
     * @memberof DistributedQueryResultParams
     */
    'status'?: string | null;
    /**
     * Distributed query execution message if present
     * @type {string}
     * @memberof DistributedQueryResultParams
     */
    'message'?: string | null;
    /**
     * Distributed query execution result in json format
     * @type {string}
     * @memberof DistributedQueryResultParams
     */
    'rawData'?: string | null;
    /**
     * Comma separated execution result json fields
     * @type {string}
     * @memberof DistributedQueryResultParams
     */
    'rawDataFields'?: string | null;
}
/**
 * Distributed query result execution statistic
 * @export
 * @interface DistributedQueryStatus
 */
export interface DistributedQueryStatus {
    [key: string]: any | any;

    /**
     * Distributed query primary key
     * @type {number}
     * @memberof DistributedQueryStatus
     */
    'distributedQueryId': number;
    /**
     * Total count of all distributed queries
     * @type {number}
     * @memberof DistributedQueryStatus
     */
    'total': number;
    /**
     * The number of devices from which data were not received in the last 5 minutes
     * @type {number}
     * @memberof DistributedQueryStatus
     */
    'inactiveLast5m': number;
    /**
     * Shortest duration of distributed query execution in seconds. Calculated as the difference in seconds between the populatedAt time and timestamp of the result
     * @type {number}
     * @memberof DistributedQueryStatus
     */
    'durationShortest': number | null;
    /**
     * Longest duration of distributed query execution in seconds. Calculated as the difference in seconds between the populatedAt time and timestamp of the result
     * @type {number}
     * @memberof DistributedQueryStatus
     */
    'durationLongest': number | null;
    /**
     * Time of distributed query execution start
     * @type {string}
     * @memberof DistributedQueryStatus
     */
    'executionStart': string | null;
    /**
     * Timeout of distributed query execution in seconds
     * @type {number}
     * @memberof DistributedQueryStatus
     */
    'executionTimeout': number | null;
    /**
     * Comma-separated string with device types with COMPLETED execution of distributed query result
     * @type {string}
     * @memberof DistributedQueryStatus
     */
    'completedDeviceTypes': string;
    /**
     * Comma-separated string with device types with FAILED execution of distributed query result
     * @type {string}
     * @memberof DistributedQueryStatus
     */
    'failedDeviceTypes': string;
    /**
     * Comma separated string with all fields in result json
     * @type {string}
     * @memberof DistributedQueryStatus
     */
    'rawDataFields': string;
}
/**
 * Distributed query execution status collection single page representation
 * @export
 * @interface DistributedQueryStatusPage
 */
export interface DistributedQueryStatusPage {
    /**
     * 
     * @type {Array<DistributedQueryStatus>}
     * @memberof DistributedQueryStatusPage
     */
    'data': Array<DistributedQueryStatus>;
    /**
     * 
     * @type {Pagination}
     * @memberof DistributedQueryStatusPage
     */
    'page': Pagination;
}
/**
 * Document Comment entity definition.
 * @export
 * @interface DocumentComment
 */
export interface DocumentComment {
    [key: string]: any | any;

    /**
     * Document Comment primary key
     * @type {number}
     * @memberof DocumentComment
     */
    'id': number;
    /**
     * Project primary key
     * @type {number}
     * @memberof DocumentComment
     */
    'projectId': number;
    /**
     * Organization primary key
     * @type {number}
     * @memberof DocumentComment
     */
    'organizationId': number;
    /**
     * Author primary key
     * @type {number}
     * @memberof DocumentComment
     */
    'authorId': number;
    /**
     * Document Comment text
     * @type {string}
     * @memberof DocumentComment
     */
    'text': string;
    /**
     * Document Comment date created
     * @type {string}
     * @memberof DocumentComment
     */
    'dateCreated'?: string | null;
    /**
     * Document Comment active state
     * @type {boolean}
     * @memberof DocumentComment
     */
    'active': boolean;
    /**
     * Document Id
     * @type {number}
     * @memberof DocumentComment
     */
    'documentId': number;
}
/**
 * Project comment expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const DocumentCommentExpand = {
    UserFirstName: 'userFirstName',
    UserLastName: 'userLastName',
    UserFileName: 'userFileName'
} as const;

export type DocumentCommentExpand = typeof DocumentCommentExpand[keyof typeof DocumentCommentExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface DocumentCommentsFields
 */
export interface DocumentCommentsFields {
    /**
     * Organization primary key
     * @type {number}
     * @memberof DocumentCommentsFields
     */
    'organizationId'?: number;
    /**
     * Document Comment active state
     * @type {boolean}
     * @memberof DocumentCommentsFields
     */
    'active'?: boolean;
    /**
     * Project primary key
     * @type {number}
     * @memberof DocumentCommentsFields
     */
    'projectId'?: number;
    /**
     * Author primary key
     * @type {number}
     * @memberof DocumentCommentsFields
     */
    'authorId'?: number;
    /**
     * Document Comment text
     * @type {string}
     * @memberof DocumentCommentsFields
     */
    'text'?: string;
    /**
     * Document Comment date created
     * @type {string}
     * @memberof DocumentCommentsFields
     */
    'dateCreated'?: string | null;
    /**
     * Document Id
     * @type {number}
     * @memberof DocumentCommentsFields
     */
    'documentId'?: number;
}
/**
 * 
 * @export
 * @interface DocumentCommentsPage
 */
export interface DocumentCommentsPage {
    /**
     * 
     * @type {Array<DocumentComment>}
     * @memberof DocumentCommentsPage
     */
    'data': Array<DocumentComment>;
    /**
     * 
     * @type {Pagination}
     * @memberof DocumentCommentsPage
     */
    'page': Pagination;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface DocumentCommentsParams
 */
export interface DocumentCommentsParams {
    /**
     * Organization primary key
     * @type {number}
     * @memberof DocumentCommentsParams
     */
    'organizationId': number;
    /**
     * Document Comment active state
     * @type {boolean}
     * @memberof DocumentCommentsParams
     */
    'active': boolean;
    /**
     * Project primary key
     * @type {number}
     * @memberof DocumentCommentsParams
     */
    'projectId': number;
    /**
     * Author primary key
     * @type {number}
     * @memberof DocumentCommentsParams
     */
    'authorId': number;
    /**
     * Document Comment text
     * @type {string}
     * @memberof DocumentCommentsParams
     */
    'text': string;
    /**
     * Document Comment date created
     * @type {string}
     * @memberof DocumentCommentsParams
     */
    'dateCreated'?: string | null;
    /**
     * Document Id
     * @type {number}
     * @memberof DocumentCommentsParams
     */
    'documentId': number;
}
/**
 * Documents definition.
 * @export
 * @interface Documents
 */
export interface Documents {
    [key: string]: any | any;

    /**
     * Documents primary key
     * @type {number}
     * @memberof Documents
     */
    'id': number;
    /**
     * Organization primary key
     * @type {number}
     * @memberof Documents
     */
    'organizationId': number;
    /**
     * Project primary key
     * @type {number}
     * @memberof Documents
     */
    'projectId': number;
    /**
     * User primary key
     * @type {number}
     * @memberof Documents
     */
    'userId'?: number | null;
    /**
     * Documents name
     * @type {string}
     * @memberof Documents
     */
    'name': string;
    /**
     * Document description
     * @type {string}
     * @memberof Documents
     */
    'description'?: string;
    /**
     * Documents type documentType should be a folder or link
     * @type {string}
     * @memberof Documents
     */
    'documentType': DocumentsDocumentTypeEnum;
    /**
     * Documents download url (applicable for files)
     * @type {string}
     * @memberof Documents
     */
    'url'?: string | null;
    /**
     * Documents date created
     * @type {string}
     * @memberof Documents
     */
    'dateCreated'?: string | null;
    /**
     * ID parent folder (applicable for folders)
     * @type {number}
     * @memberof Documents
     */
    'parentDocument'?: number | null;
    /**
     * Document active state
     * @type {boolean}
     * @memberof Documents
     */
    'active': boolean;
}

export const DocumentsDocumentTypeEnum = {
    Folder: 'folder',
    Link: 'link'
} as const;

export type DocumentsDocumentTypeEnum = typeof DocumentsDocumentTypeEnum[keyof typeof DocumentsDocumentTypeEnum];

/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface DocumentsFields
 */
export interface DocumentsFields {
    /**
     * Organization primary key
     * @type {number}
     * @memberof DocumentsFields
     */
    'organizationId'?: number;
    /**
     * User primary key
     * @type {number}
     * @memberof DocumentsFields
     */
    'userId'?: number | null;
    /**
     * Document active state
     * @type {boolean}
     * @memberof DocumentsFields
     */
    'active'?: boolean;
    /**
     * Documents name
     * @type {string}
     * @memberof DocumentsFields
     */
    'name'?: string;
    /**
     * Project primary key
     * @type {number}
     * @memberof DocumentsFields
     */
    'projectId'?: number;
    /**
     * Document description
     * @type {string}
     * @memberof DocumentsFields
     */
    'description'?: string;
    /**
     * Documents type documentType should be a folder or link
     * @type {string}
     * @memberof DocumentsFields
     */
    'documentType'?: DocumentsFieldsDocumentTypeEnum;
    /**
     * Documents download url (applicable for files)
     * @type {string}
     * @memberof DocumentsFields
     */
    'url'?: string | null;
    /**
     * ID parent folder (applicable for folders)
     * @type {number}
     * @memberof DocumentsFields
     */
    'parentDocument'?: number | null;
}

export const DocumentsFieldsDocumentTypeEnum = {
    Folder: 'folder',
    Link: 'link'
} as const;

export type DocumentsFieldsDocumentTypeEnum = typeof DocumentsFieldsDocumentTypeEnum[keyof typeof DocumentsFieldsDocumentTypeEnum];

/**
 * 
 * @export
 * @interface DocumentsPage
 */
export interface DocumentsPage {
    /**
     * 
     * @type {Array<Documents>}
     * @memberof DocumentsPage
     */
    'data': Array<Documents>;
    /**
     * 
     * @type {Pagination}
     * @memberof DocumentsPage
     */
    'page': Pagination;
}
/**
 * Used for post request that should not contain an id
 * @export
 * @interface DocumentsParams
 */
export interface DocumentsParams {
    /**
     * Organization primary key
     * @type {number}
     * @memberof DocumentsParams
     */
    'organizationId': number;
    /**
     * User primary key
     * @type {number}
     * @memberof DocumentsParams
     */
    'userId'?: number | null;
    /**
     * Document active state
     * @type {boolean}
     * @memberof DocumentsParams
     */
    'active': boolean;
    /**
     * Documents name
     * @type {string}
     * @memberof DocumentsParams
     */
    'name': string;
    /**
     * Project primary key
     * @type {number}
     * @memberof DocumentsParams
     */
    'projectId': number;
    /**
     * Documents date created
     * @type {string}
     * @memberof DocumentsParams
     */
    'dateCreated'?: string | null;
    /**
     * Document description
     * @type {string}
     * @memberof DocumentsParams
     */
    'description'?: string;
    /**
     * Documents type documentType should be a folder or link
     * @type {string}
     * @memberof DocumentsParams
     */
    'documentType': DocumentsParamsDocumentTypeEnum;
    /**
     * Documents download url (applicable for files)
     * @type {string}
     * @memberof DocumentsParams
     */
    'url'?: string | null;
    /**
     * ID parent folder (applicable for folders)
     * @type {number}
     * @memberof DocumentsParams
     */
    'parentDocument'?: number | null;
}

export const DocumentsParamsDocumentTypeEnum = {
    Folder: 'folder',
    Link: 'link'
} as const;

export type DocumentsParamsDocumentTypeEnum = typeof DocumentsParamsDocumentTypeEnum[keyof typeof DocumentsParamsDocumentTypeEnum];

/**
 * Send an email to the user with a link they can click to execute UPDATE_PASSWORD action.
 * @export
 * @interface ExecuteActionEmail
 */
export interface ExecuteActionEmail {
    /**
     * 
     * @type {number}
     * @memberof ExecuteActionEmail
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof ExecuteActionEmail
     */
    'redirectUri'?: string;
}
/**
 * Organizations groups entity definition.
 * @export
 * @interface Groups
 */
export interface Groups {
    [key: string]: any | any;

    /**
     * Organizations groups primary key
     * @type {number}
     * @memberof Groups
     */
    'id': number;
    /**
     * Organizations groups name
     * @type {string}
     * @memberof Groups
     */
    'name': string;
    /**
     * Organizations groups active state
     * @type {boolean}
     * @memberof Groups
     */
    'active': boolean;
}
/**
 * Organizations groups expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const GroupsExpand = {
    OrganizationInfo: 'organizationInfo'
} as const;

export type GroupsExpand = typeof GroupsExpand[keyof typeof GroupsExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface GroupsFields
 */
export interface GroupsFields {
    /**
     * Organizations groups active state
     * @type {boolean}
     * @memberof GroupsFields
     */
    'active'?: boolean;
    /**
     * Organizations groups name
     * @type {string}
     * @memberof GroupsFields
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface GroupsPage
 */
export interface GroupsPage {
    /**
     * 
     * @type {Array<Groups>}
     * @memberof GroupsPage
     */
    'data': Array<Groups>;
    /**
     * 
     * @type {Pagination}
     * @memberof GroupsPage
     */
    'page': Pagination;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface GroupsParams
 */
export interface GroupsParams {
    /**
     * Organizations groups active state
     * @type {boolean}
     * @memberof GroupsParams
     */
    'active': boolean;
    /**
     * Organizations groups name
     * @type {string}
     * @memberof GroupsParams
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ImageDelete
 */
export interface ImageDelete {
    /**
     * 
     * @type {string}
     * @memberof ImageDelete
     */
    'fileName': string;
    /**
     * 
     * @type {number}
     * @memberof ImageDelete
     */
    'id': number;
}
/**
 * Import definition.
 * @export
 * @interface Import
 */
export interface Import {
    [key: string]: any | any;

    /**
     * Import primary key
     * @type {number}
     * @memberof Import
     */
    'id': number;
    /**
     * User primary key
     * @type {number}
     * @memberof Import
     */
    'userId': number;
    /**
     * Organization primary key
     * @type {number}
     * @memberof Import
     */
    'organizationId': number;
    /**
     * Import name
     * @type {string}
     * @memberof Import
     */
    'name': string;
    /**
     * Import url
     * @type {string}
     * @memberof Import
     */
    'url': string;
    /**
     * 
     * @type {ImportStatus}
     * @memberof Import
     */
    'status': ImportStatus;
    /**
     * Content of imported data
     * @type {string}
     * @memberof Import
     */
    'content'?: string;
    /**
     * Import active flag
     * @type {boolean}
     * @memberof Import
     */
    'active': boolean;
    /**
     * Import timestamp
     * @type {string}
     * @memberof Import
     */
    'timestamp': string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ImportExpand = {
    UserFirstName: 'userFirstName',
    UserLastName: 'userLastName'
} as const;

export type ImportExpand = typeof ImportExpand[keyof typeof ImportExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface ImportFields
 */
export interface ImportFields {
    /**
     * Import timestamp
     * @type {string}
     * @memberof ImportFields
     */
    'timestamp'?: string | null;
    /**
     * Import active flag
     * @type {boolean}
     * @memberof ImportFields
     */
    'active'?: boolean;
    /**
     * Import name
     * @type {string}
     * @memberof ImportFields
     */
    'name'?: string;
    /**
     * 
     * @type {ImportStatus}
     * @memberof ImportFields
     */
    'status'?: ImportStatus;
    /**
     * Import url
     * @type {string}
     * @memberof ImportFields
     */
    'url'?: string;
    /**
     * Content of imported data
     * @type {string}
     * @memberof ImportFields
     */
    'content'?: string;
}
/**
 * Device collection single page representation
 * @export
 * @interface ImportPage
 */
export interface ImportPage {
    /**
     * 
     * @type {Array<Import>}
     * @memberof ImportPage
     */
    'data': Array<Import>;
    /**
     * 
     * @type {Pagination}
     * @memberof ImportPage
     */
    'page': Pagination;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface ImportParams
 */
export interface ImportParams {
    /**
     * Organization primary key
     * @type {number}
     * @memberof ImportParams
     */
    'organizationId': number;
    /**
     * User primary key
     * @type {number}
     * @memberof ImportParams
     */
    'userId': number;
    /**
     * Import timestamp
     * @type {string}
     * @memberof ImportParams
     */
    'timestamp': string | null;
    /**
     * Import active flag
     * @type {boolean}
     * @memberof ImportParams
     */
    'active': boolean;
    /**
     * Import name
     * @type {string}
     * @memberof ImportParams
     */
    'name': string;
    /**
     * 
     * @type {ImportStatus}
     * @memberof ImportParams
     */
    'status': ImportStatus;
    /**
     * Import url
     * @type {string}
     * @memberof ImportParams
     */
    'url': string;
    /**
     * Content of imported data
     * @type {string}
     * @memberof ImportParams
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ImportStatus = {
    None: 'NONE',
    Created: 'CREATED',
    Uploaded: 'UPLOADED',
    Failed: 'FAILED',
    Converted: 'CONVERTED'
} as const;

export type ImportStatus = typeof ImportStatus[keyof typeof ImportStatus];


/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001
     */
    'type': string;
    /**
     * 
     * @type {any}
     * @memberof InlineResponse2001
     */
    'resultBuffer': any;
}
/**
 * 
 * @export
 * @interface InlineResponse201
 */
export interface InlineResponse201 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse201
     */
    'assignmentId': number;
}
/**
 * 
 * @export
 * @interface InlineResponse2011
 */
export interface InlineResponse2011 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2011
     */
    'deviceId': number;
}
/**
 * 
 * @export
 * @interface InlineResponse20110
 */
export interface InlineResponse20110 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20110
     */
    'organizationId': number;
}
/**
 * 
 * @export
 * @interface InlineResponse20111
 */
export interface InlineResponse20111 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20111
     */
    'projectCommentId': number;
}
/**
 * 
 * @export
 * @interface InlineResponse20112
 */
export interface InlineResponse20112 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20112
     */
    'projectId': number;
}
/**
 * 
 * @export
 * @interface InlineResponse20113
 */
export interface InlineResponse20113 {
    /**
     * 
     * @type {any}
     * @memberof InlineResponse20113
     */
    'data': any;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20113
     */
    'status': number;
}
/**
 * 
 * @export
 * @interface InlineResponse20114
 */
export interface InlineResponse20114 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20114
     */
    'scanResultId': number;
}
/**
 * 
 * @export
 * @interface InlineResponse20115
 */
export interface InlineResponse20115 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20115
     */
    'userId': number;
}
/**
 * 
 * @export
 * @interface InlineResponse20116
 */
export interface InlineResponse20116 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20116
     */
    'scanId': number;
}
/**
 * 
 * @export
 * @interface InlineResponse20117
 */
export interface InlineResponse20117 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20117
     */
    'taskCommentId': number;
}
/**
 * 
 * @export
 * @interface InlineResponse20118
 */
export interface InlineResponse20118 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20118
     */
    'taskPhaseId': number;
}
/**
 * 
 * @export
 * @interface InlineResponse20119
 */
export interface InlineResponse20119 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20119
     */
    'TasksLinkageId': number;
}
/**
 * 
 * @export
 * @interface InlineResponse2012
 */
export interface InlineResponse2012 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2012
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface InlineResponse20120
 */
export interface InlineResponse20120 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20120
     */
    'taskId': number;
}
/**
 * 
 * @export
 * @interface InlineResponse20121
 */
export interface InlineResponse20121 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20121
     */
    'TemplateTasksLinkageId': number;
}
/**
 * 
 * @export
 * @interface InlineResponse20122
 */
export interface InlineResponse20122 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20122
     */
    'templateId': number;
}
/**
 * 
 * @export
 * @interface InlineResponse2013
 */
export interface InlineResponse2013 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2013
     */
    'documentCommentId': number;
}
/**
 * 
 * @export
 * @interface InlineResponse2014
 */
export interface InlineResponse2014 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2014
     */
    'documentId': number;
}
/**
 * 
 * @export
 * @interface InlineResponse2015
 */
export interface InlineResponse2015 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2015
     */
    'GroupsId': number;
}
/**
 * 
 * @export
 * @interface InlineResponse2016
 */
export interface InlineResponse2016 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2016
     */
    'importId': number;
}
/**
 * 
 * @export
 * @interface InlineResponse2017
 */
export interface InlineResponse2017 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2017
     */
    'notificationId': number;
}
/**
 * 
 * @export
 * @interface InlineResponse2018
 */
export interface InlineResponse2018 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2018
     */
    'OrganizationsLinkageId': number;
}
/**
 * 
 * @export
 * @interface InlineResponse2019
 */
export interface InlineResponse2019 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2019
     */
    'OrganizationsGroupsId': number;
}
/**
 * Notification definition.
 * @export
 * @interface Notification
 */
export interface Notification {
    [key: string]: any | any;

    /**
     * Notification primary key
     * @type {number}
     * @memberof Notification
     */
    'id': number;
    /**
     * Notification receiver id primary key
     * @type {number}
     * @memberof Notification
     */
    'receiverId': number;
    /**
     * Notification type primary key
     * @type {string}
     * @memberof Notification
     */
    'type': string;
    /**
     * Params for notification
     * @type {any}
     * @memberof Notification
     */
    'params'?: any | null;
    /**
     * Notification active state
     * @type {boolean}
     * @memberof Notification
     */
    'active': boolean;
    /**
     * Notification seen state
     * @type {boolean}
     * @memberof Notification
     */
    'isSeen': boolean;
    /**
     * Notification date of creation
     * @type {string}
     * @memberof Notification
     */
    'dateCreated': string | null;
}
/**
 * Notification expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const NotificationExpand = {
    Test: 'TEST'
} as const;

export type NotificationExpand = typeof NotificationExpand[keyof typeof NotificationExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface NotificationFields
 */
export interface NotificationFields {
    /**
     * Notification active state
     * @type {boolean}
     * @memberof NotificationFields
     */
    'active'?: boolean;
    /**
     * Notification date of creation
     * @type {string}
     * @memberof NotificationFields
     */
    'dateCreated'?: string | null;
    /**
     * Notification receiver id primary key
     * @type {number}
     * @memberof NotificationFields
     */
    'receiverId'?: number;
    /**
     * Notification type primary key
     * @type {string}
     * @memberof NotificationFields
     */
    'type'?: string;
    /**
     * Params for notification
     * @type {any}
     * @memberof NotificationFields
     */
    'params'?: any | null;
    /**
     * Notification seen state
     * @type {boolean}
     * @memberof NotificationFields
     */
    'isSeen'?: boolean;
}
/**
 * 
 * @export
 * @interface NotificationPage
 */
export interface NotificationPage {
    /**
     * 
     * @type {Array<Notification>}
     * @memberof NotificationPage
     */
    'data': Array<Notification>;
    /**
     * 
     * @type {Pagination}
     * @memberof NotificationPage
     */
    'page': Pagination;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface NotificationParams
 */
export interface NotificationParams {
    /**
     * Notification active state
     * @type {boolean}
     * @memberof NotificationParams
     */
    'active': boolean;
    /**
     * Notification date of creation
     * @type {string}
     * @memberof NotificationParams
     */
    'dateCreated': string | null;
    /**
     * Notification receiver id primary key
     * @type {number}
     * @memberof NotificationParams
     */
    'receiverId': number;
    /**
     * Notification type primary key
     * @type {string}
     * @memberof NotificationParams
     */
    'type': string;
    /**
     * Params for notification
     * @type {any}
     * @memberof NotificationParams
     */
    'params'?: any | null;
    /**
     * Notification seen state
     * @type {boolean}
     * @memberof NotificationParams
     */
    'isSeen': boolean;
}
/**
 * Project status result execution statistic
 * @export
 * @interface NotifierStatus
 */
export interface NotifierStatus {
    /**
     * Total count of all requested projects
     * @type {number}
     * @memberof NotifierStatus
     */
    'total_project_requested': number;
}
/**
 * Organization entity
 * @export
 * @interface Organization
 */
export interface Organization {
    [key: string]: any | any;

    /**
     * Organization primary key
     * @type {number}
     * @memberof Organization
     */
    'id': number;
    /**
     * Organization name
     * @type {string}
     * @memberof Organization
     */
    'name': string;
    /**
     * Organization disabled state
     * @type {boolean}
     * @memberof Organization
     */
    'active': boolean;
    /**
     * Organization file name
     * @type {string}
     * @memberof Organization
     */
    'fileName'?: string | null;
    /**
     * Organization due date
     * @type {string}
     * @memberof Organization
     */
    'dueDate'?: string | null;
    /**
     * Organization comment
     * @type {string}
     * @memberof Organization
     */
    'comment'?: string | null;
    /**
     * Organization point of contact id
     * @type {number}
     * @memberof Organization
     */
    'pointOfContactId'?: number | null;
    /**
     * Organization vX point of contact id
     * @type {number}
     * @memberof Organization
     */
    'vxPointOfContactId'?: number | null;
    /**
     * 
     * @type {OrganizationPolicies}
     * @memberof Organization
     */
    'policies'?: OrganizationPolicies;
}
/**
 * Scan expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const OrganizationExpand = {
    UserCount: 'userCount',
    ScanCount: 'scanCount',
    DeviceCount: 'deviceCount',
    CloudCount: 'cloudCount',
    ProjectCount: 'projectCount',
    LastInteraction: 'lastInteraction',
    PointOfContact: 'pointOfContact',
    VxPointOfContact: 'vxPointOfContact',
    Groups: 'groups',
    VendorInfo: 'vendorInfo'
} as const;

export type OrganizationExpand = typeof OrganizationExpand[keyof typeof OrganizationExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface OrganizationFields
 */
export interface OrganizationFields {
    /**
     * Organization disabled state
     * @type {boolean}
     * @memberof OrganizationFields
     */
    'active'?: boolean;
    /**
     * Organization name
     * @type {string}
     * @memberof OrganizationFields
     */
    'name'?: string;
    /**
     * Organization file name
     * @type {string}
     * @memberof OrganizationFields
     */
    'fileName'?: string | null;
    /**
     * Organization due date
     * @type {string}
     * @memberof OrganizationFields
     */
    'dueDate'?: string | null;
    /**
     * Organization comment
     * @type {string}
     * @memberof OrganizationFields
     */
    'comment'?: string | null;
    /**
     * Organization point of contact id
     * @type {number}
     * @memberof OrganizationFields
     */
    'pointOfContactId'?: number | null;
    /**
     * Organization vX point of contact id
     * @type {number}
     * @memberof OrganizationFields
     */
    'vxPointOfContactId'?: number | null;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface OrganizationParams
 */
export interface OrganizationParams {
    /**
     * Organization disabled state
     * @type {boolean}
     * @memberof OrganizationParams
     */
    'active': boolean;
    /**
     * Organization name
     * @type {string}
     * @memberof OrganizationParams
     */
    'name': string;
    /**
     * Organization file name
     * @type {string}
     * @memberof OrganizationParams
     */
    'fileName'?: string | null;
    /**
     * Organization due date
     * @type {string}
     * @memberof OrganizationParams
     */
    'dueDate'?: string | null;
    /**
     * Organization comment
     * @type {string}
     * @memberof OrganizationParams
     */
    'comment'?: string | null;
    /**
     * Organization point of contact id
     * @type {number}
     * @memberof OrganizationParams
     */
    'pointOfContactId'?: number | null;
    /**
     * Organization vX point of contact id
     * @type {number}
     * @memberof OrganizationParams
     */
    'vxPointOfContactId'?: number | null;
}
/**
 * 
 * @export
 * @interface OrganizationPolicies
 */
export interface OrganizationPolicies {
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationPolicies
     */
    'allowUberRole'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationPolicies
     */
    'allowIntegrations'?: boolean;
}
/**
 * Organizations groups entity definition.
 * @export
 * @interface OrganizationsGroups
 */
export interface OrganizationsGroups {
    [key: string]: any | any;

    /**
     * Organizations groups primary key
     * @type {number}
     * @memberof OrganizationsGroups
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationsGroups
     */
    'organizationId': number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationsGroups
     */
    'groupId': number;
}
/**
 * Organizations groups expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const OrganizationsGroupsExpand = {
    ExpandInfo: 'expandInfo'
} as const;

export type OrganizationsGroupsExpand = typeof OrganizationsGroupsExpand[keyof typeof OrganizationsGroupsExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface OrganizationsGroupsFields
 */
export interface OrganizationsGroupsFields {
    /**
     * 
     * @type {number}
     * @memberof OrganizationsGroupsFields
     */
    'organizationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationsGroupsFields
     */
    'groupId'?: number;
}
/**
 * 
 * @export
 * @interface OrganizationsGroupsPage
 */
export interface OrganizationsGroupsPage {
    /**
     * 
     * @type {Array<OrganizationsGroups>}
     * @memberof OrganizationsGroupsPage
     */
    'data': Array<OrganizationsGroups>;
    /**
     * 
     * @type {Pagination}
     * @memberof OrganizationsGroupsPage
     */
    'page': Pagination;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface OrganizationsGroupsParams
 */
export interface OrganizationsGroupsParams {
    /**
     * 
     * @type {number}
     * @memberof OrganizationsGroupsParams
     */
    'organizationId': number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationsGroupsParams
     */
    'groupId': number;
}
/**
 * Organizations linkage entity definition.
 * @export
 * @interface OrganizationsLinkage
 */
export interface OrganizationsLinkage {
    [key: string]: any | any;

    /**
     * Organization linkage primary key
     * @type {number}
     * @memberof OrganizationsLinkage
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationsLinkage
     */
    'organizationsLinkageId': number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationsLinkage
     */
    'vendorId': number;
    /**
     * Vendor first name
     * @type {string}
     * @memberof OrganizationsLinkage
     */
    'firstName'?: string | null;
    /**
     * Vendor last name
     * @type {string}
     * @memberof OrganizationsLinkage
     */
    'lastName'?: string | null;
    /**
     * Vendor email
     * @type {string}
     * @memberof OrganizationsLinkage
     */
    'email'?: string | null;
    /**
     * Vendor title
     * @type {string}
     * @memberof OrganizationsLinkage
     */
    'title'?: string | null;
}
/**
 * Organizations linkage expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const OrganizationsLinkageExpand = {
    ExpandInfo: 'expandInfo'
} as const;

export type OrganizationsLinkageExpand = typeof OrganizationsLinkageExpand[keyof typeof OrganizationsLinkageExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface OrganizationsLinkageFields
 */
export interface OrganizationsLinkageFields {
    /**
     * 
     * @type {number}
     * @memberof OrganizationsLinkageFields
     */
    'organizationsLinkageId'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationsLinkageFields
     */
    'vendorId'?: number;
    /**
     * Vendor first name
     * @type {string}
     * @memberof OrganizationsLinkageFields
     */
    'firstName'?: string | null;
    /**
     * Vendor last name
     * @type {string}
     * @memberof OrganizationsLinkageFields
     */
    'lastName'?: string | null;
    /**
     * Vendor email
     * @type {string}
     * @memberof OrganizationsLinkageFields
     */
    'email'?: string | null;
    /**
     * Vendor title
     * @type {string}
     * @memberof OrganizationsLinkageFields
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface OrganizationsLinkagePage
 */
export interface OrganizationsLinkagePage {
    /**
     * 
     * @type {Array<OrganizationsLinkage>}
     * @memberof OrganizationsLinkagePage
     */
    'data': Array<OrganizationsLinkage>;
    /**
     * 
     * @type {Pagination}
     * @memberof OrganizationsLinkagePage
     */
    'page': Pagination;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface OrganizationsLinkageParams
 */
export interface OrganizationsLinkageParams {
    /**
     * 
     * @type {number}
     * @memberof OrganizationsLinkageParams
     */
    'organizationsLinkageId': number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationsLinkageParams
     */
    'vendorId': number;
    /**
     * Vendor first name
     * @type {string}
     * @memberof OrganizationsLinkageParams
     */
    'firstName'?: string | null;
    /**
     * Vendor last name
     * @type {string}
     * @memberof OrganizationsLinkageParams
     */
    'lastName'?: string | null;
    /**
     * Vendor email
     * @type {string}
     * @memberof OrganizationsLinkageParams
     */
    'email'?: string | null;
    /**
     * Vendor title
     * @type {string}
     * @memberof OrganizationsLinkageParams
     */
    'title'?: string | null;
}
/**
 * Scan collection single page representation
 * @export
 * @interface OrganizationsPage
 */
export interface OrganizationsPage {
    /**
     * 
     * @type {Array<Organization>}
     * @memberof OrganizationsPage
     */
    'data': Array<Organization>;
    /**
     * 
     * @type {Pagination}
     * @memberof OrganizationsPage
     */
    'page': Pagination;
}
/**
 * Organization status result execution statistic
 * @export
 * @interface OrganizationsStatus
 */
export interface OrganizationsStatus {
    [key: string]: any | any;

    /**
     * Total count of all active organizations
     * @type {number}
     * @memberof OrganizationsStatus
     */
    'total': number;
}
/**
 * Organizations status collection single page representation
 * @export
 * @interface OrganizationsStatusPage
 */
export interface OrganizationsStatusPage {
    /**
     * 
     * @type {Array<OrganizationsStatus>}
     * @memberof OrganizationsStatusPage
     */
    'data': Array<OrganizationsStatus>;
    /**
     * 
     * @type {Pagination}
     * @memberof OrganizationsStatusPage
     */
    'page': Pagination;
}
/**
 * OSQuery scheduled query definition.
 * @export
 * @interface Osquery
 */
export interface Osquery {
    [key: string]: any | any;

    /**
     * OSQuery primary key
     * @type {number}
     * @memberof Osquery
     */
    'id': number;
    /**
     * Scan primary key
     * @type {number}
     * @memberof Osquery
     */
    'scanId': number;
    /**
     * Organization primary key
     * @type {number}
     * @memberof Osquery
     */
    'organizationId': number;
    /**
     * OSQuery name
     * @type {string}
     * @memberof Osquery
     */
    'name': string;
    /**
     * OSQuery query
     * @type {string}
     * @memberof Osquery
     */
    'query': string;
    /**
     * OSQuery scheduling interval in seconds
     * @type {number}
     * @memberof Osquery
     */
    'interval': number;
    /**
     * 
     * @type {QueryResultType}
     * @memberof Osquery
     */
    'resultType': QueryResultType;
    /**
     * evaluation pattern (e.g. comma separated fields concatenation)
     * @type {string}
     * @memberof Osquery
     */
    'resultEvaluation'?: string | null;
    /**
     * Comma separated device types (e.g. windows, darwin, linux, freebsd)
     * @type {string}
     * @memberof Osquery
     */
    'deviceTypes'?: string | null;
    /**
     * Osquery active state
     * @type {boolean}
     * @memberof Osquery
     */
    'active': boolean;
}
/**
 * Osquery expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const OsqueryExpand = {
    DistributedQueryId: 'distributedQueryId',
    ResultPreview: 'resultPreview'
} as const;

export type OsqueryExpand = typeof OsqueryExpand[keyof typeof OsqueryExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface OsqueryFields
 */
export interface OsqueryFields {
    /**
     * Osquery active state
     * @type {boolean}
     * @memberof OsqueryFields
     */
    'active'?: boolean;
    /**
     * OSQuery name
     * @type {string}
     * @memberof OsqueryFields
     */
    'name'?: string;
    /**
     * OSQuery query
     * @type {string}
     * @memberof OsqueryFields
     */
    'query'?: string;
    /**
     * OSQuery scheduling interval in seconds
     * @type {number}
     * @memberof OsqueryFields
     */
    'interval'?: number;
    /**
     * 
     * @type {QueryResultType}
     * @memberof OsqueryFields
     */
    'resultType'?: QueryResultType;
    /**
     * evaluation pattern (e.g. comma separated fields concatenation)
     * @type {string}
     * @memberof OsqueryFields
     */
    'resultEvaluation'?: string | null;
    /**
     * Comma separated device types (e.g. windows, darwin, linux, freebsd)
     * @type {string}
     * @memberof OsqueryFields
     */
    'deviceTypes'?: string | null;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface OsqueryParams
 */
export interface OsqueryParams {
    /**
     * Organization primary key
     * @type {number}
     * @memberof OsqueryParams
     */
    'organizationId': number;
    /**
     * Osquery active state
     * @type {boolean}
     * @memberof OsqueryParams
     */
    'active': boolean;
    /**
     * OSQuery name
     * @type {string}
     * @memberof OsqueryParams
     */
    'name': string;
    /**
     * OSQuery query
     * @type {string}
     * @memberof OsqueryParams
     */
    'query': string;
    /**
     * Scan primary key
     * @type {number}
     * @memberof OsqueryParams
     */
    'scanId': number;
    /**
     * OSQuery scheduling interval in seconds
     * @type {number}
     * @memberof OsqueryParams
     */
    'interval': number;
    /**
     * 
     * @type {QueryResultType}
     * @memberof OsqueryParams
     */
    'resultType': QueryResultType;
    /**
     * evaluation pattern (e.g. comma separated fields concatenation)
     * @type {string}
     * @memberof OsqueryParams
     */
    'resultEvaluation'?: string | null;
    /**
     * Comma separated device types (e.g. windows, darwin, linux, freebsd)
     * @type {string}
     * @memberof OsqueryParams
     */
    'deviceTypes'?: string | null;
}
/**
 * Current page information on paginated list response
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * First page number
     * @type {number}
     * @memberof Pagination
     */
    'first': number;
    /**
     * Last page number
     * @type {number}
     * @memberof Pagination
     */
    'last': number;
    /**
     * Next page number
     * @type {number}
     * @memberof Pagination
     */
    'next': number | null;
    /**
     * Previous page number
     * @type {number}
     * @memberof Pagination
     */
    'prev': number | null;
    /**
     * Current page number
     * @type {number}
     * @memberof Pagination
     */
    'current': number;
    /**
     * Page max items
     * @type {number}
     * @memberof Pagination
     */
    'limit': number;
    /**
     * Number of all existed items
     * @type {number}
     * @memberof Pagination
     */
    'total': number;
}
/**
 * Project entity definition.
 * @export
 * @interface Project
 */
export interface Project {
    [key: string]: any | any;

    /**
     * Project primary key
     * @type {number}
     * @memberof Project
     */
    'id': number;
    /**
     * Organization primary key
     * @type {number}
     * @memberof Project
     */
    'organizationId': number;
    /**
     * User primary key
     * @type {number}
     * @memberof Project
     */
    'userId'?: number | null;
    /**
     * Project name
     * @type {string}
     * @memberof Project
     */
    'name': string;
    /**
     * Project prefix
     * @type {string}
     * @memberof Project
     */
    'prefix': string;
    /**
     * Project description
     * @type {string}
     * @memberof Project
     */
    'description'?: string;
    /**
     * log result should be a date
     * @type {string}
     * @memberof Project
     */
    'dateDueTo'?: string | null;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof Project
     */
    'status'?: ProjectStatus;
    /**
     * Project active state
     * @type {boolean}
     * @memberof Project
     */
    'active': boolean;
    /**
     * Responsible primary key
     * @type {number}
     * @memberof Project
     */
    'responsibleId'?: number | null;
    /**
     * Template primary key
     * @type {number}
     * @memberof Project
     */
    'templateId'?: number | null;
    /**
     * Response from typeform
     * @type {string}
     * @memberof Project
     */
    'response'?: string | null;
    /**
     * Response primary key
     * @type {string}
     * @memberof Project
     */
    'responseId'?: string | null;
    /**
     * Form primary key
     * @type {string}
     * @memberof Project
     */
    'formId'?: string | null;
    /**
     * 
     * @type {ProjectType}
     * @memberof Project
     */
    'type': ProjectType;
    /**
     * Form primary key
     * @type {string}
     * @memberof Project
     */
    'slackChannelId'?: string | null;
    /**
     * Project status report schedule expression
     * @type {string}
     * @memberof Project
     */
    'statusReportSchedule'?: string | null;
}
/**
 * Project Comment definition.
 * @export
 * @interface ProjectComment
 */
export interface ProjectComment {
    [key: string]: any | any;

    /**
     * Project Comment primary key
     * @type {number}
     * @memberof ProjectComment
     */
    'id': number;
    /**
     * Project primary key
     * @type {number}
     * @memberof ProjectComment
     */
    'projectId': number;
    /**
     * Organization primary key
     * @type {number}
     * @memberof ProjectComment
     */
    'organizationId': number;
    /**
     * Author primary key
     * @type {number}
     * @memberof ProjectComment
     */
    'authorId': number;
    /**
     * Project Comment description
     * @type {string}
     * @memberof ProjectComment
     */
    'text': string;
    /**
     * Project Comment date created
     * @type {string}
     * @memberof ProjectComment
     */
    'dateCreated'?: string | null;
    /**
     * Project Comment active state
     * @type {boolean}
     * @memberof ProjectComment
     */
    'active': boolean;
    /**
     * Slack TS
     * @type {string}
     * @memberof ProjectComment
     */
    'slackTs': string | null;
}
/**
 * Project comment expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const ProjectCommentExpand = {
    UserFirstName: 'userFirstName',
    UserLastName: 'userLastName',
    UserFileName: 'userFileName',
    IsExternal: 'isExternal'
} as const;

export type ProjectCommentExpand = typeof ProjectCommentExpand[keyof typeof ProjectCommentExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface ProjectCommentsFields
 */
export interface ProjectCommentsFields {
    /**
     * Organization primary key
     * @type {number}
     * @memberof ProjectCommentsFields
     */
    'organizationId'?: number;
    /**
     * Project Comment active state
     * @type {boolean}
     * @memberof ProjectCommentsFields
     */
    'active'?: boolean;
    /**
     * Project primary key
     * @type {number}
     * @memberof ProjectCommentsFields
     */
    'projectId'?: number;
    /**
     * Author primary key
     * @type {number}
     * @memberof ProjectCommentsFields
     */
    'authorId'?: number;
    /**
     * Project Comment description
     * @type {string}
     * @memberof ProjectCommentsFields
     */
    'text'?: string;
    /**
     * Project Comment date created
     * @type {string}
     * @memberof ProjectCommentsFields
     */
    'dateCreated'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectCommentsPage
 */
export interface ProjectCommentsPage {
    /**
     * 
     * @type {Array<ProjectComment>}
     * @memberof ProjectCommentsPage
     */
    'data': Array<ProjectComment>;
    /**
     * 
     * @type {Pagination}
     * @memberof ProjectCommentsPage
     */
    'page': Pagination;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface ProjectCommentsParams
 */
export interface ProjectCommentsParams {
    /**
     * Organization primary key
     * @type {number}
     * @memberof ProjectCommentsParams
     */
    'organizationId': number;
    /**
     * Project Comment active state
     * @type {boolean}
     * @memberof ProjectCommentsParams
     */
    'active': boolean;
    /**
     * Project primary key
     * @type {number}
     * @memberof ProjectCommentsParams
     */
    'projectId': number;
    /**
     * Author primary key
     * @type {number}
     * @memberof ProjectCommentsParams
     */
    'authorId': number;
    /**
     * Project Comment description
     * @type {string}
     * @memberof ProjectCommentsParams
     */
    'text': string;
    /**
     * Project Comment date created
     * @type {string}
     * @memberof ProjectCommentsParams
     */
    'dateCreated'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof ProjectCommentsParams
     */
    'slack_ts'?: any;
}
/**
 * Project expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const ProjectExpand = {
    OrganizationName: 'organizationName',
    UserFirstName: 'userFirstName',
    UserLastName: 'userLastName',
    UserFileName: 'userFileName',
    LastMessage: 'lastMessage',
    TasksTotal: 'tasksTotal'
} as const;

export type ProjectExpand = typeof ProjectExpand[keyof typeof ProjectExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface ProjectFields
 */
export interface ProjectFields {
    /**
     * Organization primary key
     * @type {number}
     * @memberof ProjectFields
     */
    'organizationId'?: number;
    /**
     * User primary key
     * @type {number}
     * @memberof ProjectFields
     */
    'userId'?: number | null;
    /**
     * Project active state
     * @type {boolean}
     * @memberof ProjectFields
     */
    'active'?: boolean;
    /**
     * Project name
     * @type {string}
     * @memberof ProjectFields
     */
    'name'?: string;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof ProjectFields
     */
    'status'?: ProjectStatus;
    /**
     * Project description
     * @type {string}
     * @memberof ProjectFields
     */
    'description'?: string;
    /**
     * 
     * @type {ProjectType}
     * @memberof ProjectFields
     */
    'type'?: ProjectType;
    /**
     * Project prefix
     * @type {string}
     * @memberof ProjectFields
     */
    'prefix'?: string;
    /**
     * log result should be a date
     * @type {string}
     * @memberof ProjectFields
     */
    'dateDueTo'?: string | null;
    /**
     * Responsible primary key
     * @type {number}
     * @memberof ProjectFields
     */
    'responsibleId'?: number | null;
    /**
     * Template primary key
     * @type {number}
     * @memberof ProjectFields
     */
    'templateId'?: number | null;
    /**
     * Response from typeform
     * @type {string}
     * @memberof ProjectFields
     */
    'response'?: string | null;
    /**
     * Response primary key
     * @type {string}
     * @memberof ProjectFields
     */
    'responseId'?: string | null;
    /**
     * Form primary key
     * @type {string}
     * @memberof ProjectFields
     */
    'formId'?: string | null;
    /**
     * Project status report schedule expression
     * @type {string}
     * @memberof ProjectFields
     */
    'statusReportSchedule'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectPage
 */
export interface ProjectPage {
    /**
     * 
     * @type {Array<Project>}
     * @memberof ProjectPage
     */
    'data': Array<Project>;
    /**
     * 
     * @type {Pagination}
     * @memberof ProjectPage
     */
    'page': Pagination;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface ProjectParams
 */
export interface ProjectParams {
    /**
     * Organization primary key
     * @type {number}
     * @memberof ProjectParams
     */
    'organizationId': number;
    /**
     * User primary key
     * @type {number}
     * @memberof ProjectParams
     */
    'userId'?: number | null;
    /**
     * Project active state
     * @type {boolean}
     * @memberof ProjectParams
     */
    'active': boolean;
    /**
     * Project name
     * @type {string}
     * @memberof ProjectParams
     */
    'name': string;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof ProjectParams
     */
    'status'?: ProjectStatus;
    /**
     * Project description
     * @type {string}
     * @memberof ProjectParams
     */
    'description'?: string;
    /**
     * 
     * @type {ProjectType}
     * @memberof ProjectParams
     */
    'type': ProjectType;
    /**
     * Project prefix
     * @type {string}
     * @memberof ProjectParams
     */
    'prefix': string;
    /**
     * log result should be a date
     * @type {string}
     * @memberof ProjectParams
     */
    'dateDueTo'?: string | null;
    /**
     * Responsible primary key
     * @type {number}
     * @memberof ProjectParams
     */
    'responsibleId'?: number | null;
    /**
     * Template primary key
     * @type {number}
     * @memberof ProjectParams
     */
    'templateId'?: number | null;
    /**
     * Response from typeform
     * @type {string}
     * @memberof ProjectParams
     */
    'response'?: string | null;
    /**
     * Response primary key
     * @type {string}
     * @memberof ProjectParams
     */
    'responseId'?: string | null;
    /**
     * Form primary key
     * @type {string}
     * @memberof ProjectParams
     */
    'formId'?: string | null;
    /**
     * Form primary key
     * @type {string}
     * @memberof ProjectParams
     */
    'slackChannelId'?: string | null;
    /**
     * Project status report schedule expression
     * @type {string}
     * @memberof ProjectParams
     */
    'statusReportSchedule'?: string | null;
}
/**
 * project status definition
 * @export
 * @enum {string}
 */

export const ProjectStatus = {
    Requested: 'REQUESTED',
    Draft: 'DRAFT',
    Active: 'ACTIVE',
    Done: 'DONE',
    AskToClose: 'ASK_TO_CLOSE'
} as const;

export type ProjectStatus = typeof ProjectStatus[keyof typeof ProjectStatus];


/**
 * Projects status collection single page representation
 * @export
 * @interface ProjectStatusPage
 */
export interface ProjectStatusPage {
    /**
     * 
     * @type {Array<ProjectsStatus>}
     * @memberof ProjectStatusPage
     */
    'data': Array<ProjectsStatus>;
    /**
     * 
     * @type {Pagination}
     * @memberof ProjectStatusPage
     */
    'page': Pagination;
}
/**
 * project type definition
 * @export
 * @enum {string}
 */

export const ProjectType = {
    VxService: 'VX_SERVICE',
    Default: 'DEFAULT'
} as const;

export type ProjectType = typeof ProjectType[keyof typeof ProjectType];


/**
 * Project status result execution statistic
 * @export
 * @interface ProjectsStatus
 */
export interface ProjectsStatus {
    [key: string]: any | any;

    /**
     * Total count of all projects
     * @type {number}
     * @memberof ProjectsStatus
     */
    'total_projects': number;
    /**
     * Total count of all requested projects
     * @type {number}
     * @memberof ProjectsStatus
     */
    'requested_projects': number;
    /**
     * Total count of all active projects
     * @type {number}
     * @memberof ProjectsStatus
     */
    'active_projects': number;
    /**
     * Total count of all completed projects
     * @type {number}
     * @memberof ProjectsStatus
     */
    'done_projects': number;
    /**
     * Total count of all active tasks
     * @type {number}
     * @memberof ProjectsStatus
     */
    'total_tasks': number;
    /**
     * Total count of all active completed tasks
     * @type {number}
     * @memberof ProjectsStatus
     */
    'completed': number;
    /**
     * Total count of all active tasks in progress
     * @type {number}
     * @memberof ProjectsStatus
     */
    'in_progress': number;
}
/**
 * Queries collection single page representation
 * @export
 * @interface QueriesPage
 */
export interface QueriesPage {
    /**
     * 
     * @type {Array<Osquery>}
     * @memberof QueriesPage
     */
    'data': Array<Osquery>;
    /**
     * 
     * @type {Pagination}
     * @memberof QueriesPage
     */
    'page': Pagination;
}
/**
 * Query result evaluation type: * `raw` - simple osquery log value in JSON format * `condition` - osquery log evaluate to true/false value depending on the given `resultEvaluation` field parameter * `formatted` - osquery log evaluate to string which is a concatenation of the field values   listed through a comma in `resultEvaluation` parameter * `json` - query log evaluate to json which is a mapping defined in `resultEvaluation` parameter
 * @export
 * @enum {string}
 */

export const QueryResultType = {
    Raw: 'raw',
    Condition: 'condition',
    Formatted: 'formatted',
    Json: 'json'
} as const;

export type QueryResultType = typeof QueryResultType[keyof typeof QueryResultType];


/**
 * Scan definition.
 * @export
 * @interface Scan
 */
export interface Scan {
    [key: string]: any | any;

    /**
     * Scan primary key
     * @type {number}
     * @memberof Scan
     */
    'id': number;
    /**
     * Organization primary key
     * @type {number}
     * @memberof Scan
     */
    'organizationId': number;
    /**
     * Scan name
     * @type {string}
     * @memberof Scan
     */
    'name': string;
    /**
     * 
     * @type {ScanType}
     * @memberof Scan
     */
    'type': ScanType;
    /**
     * 
     * @type {ScanTopic}
     * @memberof Scan
     */
    'topic': ScanTopic;
    /**
     * 
     * @type {ScanTarget}
     * @memberof Scan
     */
    'target': ScanTarget;
    /**
     * Scan active state
     * @type {boolean}
     * @memberof Scan
     */
    'active': boolean;
    /**
     * Scan scheduling interval in seconds
     * @type {number}
     * @memberof Scan
     */
    'interval'?: number;
    /**
     * Scan description
     * @type {string}
     * @memberof Scan
     */
    'description'?: string;
}
/**
 * Scan expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const ScanExpand = {
    QueryCount: 'queryCount',
    QueryActiveCount: 'queryActiveCount',
    DeviceCount: 'deviceCount'
} as const;

export type ScanExpand = typeof ScanExpand[keyof typeof ScanExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface ScanFields
 */
export interface ScanFields {
    /**
     * 
     * @type {ScanTarget}
     * @memberof ScanFields
     */
    'target'?: ScanTarget;
    /**
     * Scan active state
     * @type {boolean}
     * @memberof ScanFields
     */
    'active'?: boolean;
    /**
     * Scan name
     * @type {string}
     * @memberof ScanFields
     */
    'name'?: string;
    /**
     * Scan description
     * @type {string}
     * @memberof ScanFields
     */
    'description'?: string;
    /**
     * 
     * @type {ScanType}
     * @memberof ScanFields
     */
    'type'?: ScanType;
    /**
     * Scan scheduling interval in seconds
     * @type {number}
     * @memberof ScanFields
     */
    'interval'?: number;
    /**
     * 
     * @type {ScanTopic}
     * @memberof ScanFields
     */
    'topic'?: ScanTopic;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface ScanParams
 */
export interface ScanParams {
    /**
     * 
     * @type {ScanTarget}
     * @memberof ScanParams
     */
    'target': ScanTarget;
    /**
     * Organization primary key
     * @type {number}
     * @memberof ScanParams
     */
    'organizationId': number;
    /**
     * Scan active state
     * @type {boolean}
     * @memberof ScanParams
     */
    'active': boolean;
    /**
     * Scan name
     * @type {string}
     * @memberof ScanParams
     */
    'name': string;
    /**
     * Scan description
     * @type {string}
     * @memberof ScanParams
     */
    'description'?: string;
    /**
     * 
     * @type {ScanType}
     * @memberof ScanParams
     */
    'type': ScanType;
    /**
     * Scan scheduling interval in seconds
     * @type {number}
     * @memberof ScanParams
     */
    'interval'?: number;
    /**
     * 
     * @type {ScanTopic}
     * @memberof ScanParams
     */
    'topic': ScanTopic;
}
/**
 * Scan result definition.
 * @export
 * @interface ScanResult
 */
export interface ScanResult {
    [key: string]: any | any;

    /**
     * Scan result primary key
     * @type {number}
     * @memberof ScanResult
     */
    'id': number;
    /**
     * User primary key
     * @type {number}
     * @memberof ScanResult
     */
    'userId': number;
    /**
     * Organization primary key
     * @type {number}
     * @memberof ScanResult
     */
    'organizationId': number;
    /**
     * Scan primary key
     * @type {number}
     * @memberof ScanResult
     */
    'scanId': number;
    /**
     * Device primary key
     * @type {number}
     * @memberof ScanResult
     */
    'deviceId': number;
    /**
     * Result source id depend of source type (e.g queryId for queries)
     * @type {number}
     * @memberof ScanResult
     */
    'sourceId': number;
    /**
     * 
     * @type {ScanTopic}
     * @memberof ScanResult
     */
    'scanTopic'?: ScanTopic;
    /**
     * 
     * @type {ScanTarget}
     * @memberof ScanResult
     */
    'scanTarget'?: ScanTarget;
    /**
     * Device type definition
     * @type {string}
     * @memberof ScanResult
     */
    'deviceType'?: string | null;
    /**
     * Scan result source definition
     * @type {any}
     * @memberof ScanResult
     */
    'source'?: any | null;
    /**
     * Result of scan execution
     * @type {any}
     * @memberof ScanResult
     */
    'result'?: any | null;
    /**
     * OSQuery log result timestamp or null
     * @type {string}
     * @memberof ScanResult
     */
    'timestamp'?: string | null;
    /**
     * Scan result active state
     * @type {boolean}
     * @memberof ScanResult
     */
    'active': boolean;
}
/**
 * Scan result expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const ScanResultExpand = {
    UserFirstName: 'userFirstName',
    UserLastName: 'userLastName',
    DeviceType: 'deviceType',
    DeviceInfo: 'deviceInfo',
    DeviceTimestamp: 'deviceTimestamp',
    ResultExpand: 'resultExpand'
} as const;

export type ScanResultExpand = typeof ScanResultExpand[keyof typeof ScanResultExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface ScanResultFields
 */
export interface ScanResultFields {
    /**
     * Scan result source definition
     * @type {any}
     * @memberof ScanResultFields
     */
    'source'?: any | null;
    /**
     * 
     * @type {ScanTopic}
     * @memberof ScanResultFields
     */
    'scanTopic'?: ScanTopic;
    /**
     * Device type definition
     * @type {string}
     * @memberof ScanResultFields
     */
    'deviceType'?: string | null;
    /**
     * OSQuery log result timestamp or null
     * @type {string}
     * @memberof ScanResultFields
     */
    'timestamp'?: string | null;
    /**
     * Scan result active state
     * @type {boolean}
     * @memberof ScanResultFields
     */
    'active'?: boolean;
    /**
     * 
     * @type {ScanTarget}
     * @memberof ScanResultFields
     */
    'scanTarget'?: ScanTarget;
    /**
     * Result of scan execution
     * @type {any}
     * @memberof ScanResultFields
     */
    'result'?: any | null;
}
/**
 * Device collection single page representation
 * @export
 * @interface ScanResultPage
 */
export interface ScanResultPage {
    /**
     * 
     * @type {Array<ScanResult>}
     * @memberof ScanResultPage
     */
    'data': Array<ScanResult>;
    /**
     * 
     * @type {Pagination}
     * @memberof ScanResultPage
     */
    'page': Pagination;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface ScanResultParams
 */
export interface ScanResultParams {
    /**
     * Scan result source definition
     * @type {any}
     * @memberof ScanResultParams
     */
    'source'?: any | null;
    /**
     * Organization primary key
     * @type {number}
     * @memberof ScanResultParams
     */
    'organizationId': number;
    /**
     * User primary key
     * @type {number}
     * @memberof ScanResultParams
     */
    'userId': number;
    /**
     * 
     * @type {ScanTopic}
     * @memberof ScanResultParams
     */
    'scanTopic'?: ScanTopic;
    /**
     * Device type definition
     * @type {string}
     * @memberof ScanResultParams
     */
    'deviceType'?: string | null;
    /**
     * OSQuery log result timestamp or null
     * @type {string}
     * @memberof ScanResultParams
     */
    'timestamp'?: string | null;
    /**
     * Scan result active state
     * @type {boolean}
     * @memberof ScanResultParams
     */
    'active': boolean;
    /**
     * Device primary key
     * @type {number}
     * @memberof ScanResultParams
     */
    'deviceId': number;
    /**
     * Scan primary key
     * @type {number}
     * @memberof ScanResultParams
     */
    'scanId': number;
    /**
     * Result source id depend of source type (e.g queryId for queries)
     * @type {number}
     * @memberof ScanResultParams
     */
    'sourceId': number;
    /**
     * 
     * @type {ScanTarget}
     * @memberof ScanResultParams
     */
    'scanTarget'?: ScanTarget;
    /**
     * Result of scan execution
     * @type {any}
     * @memberof ScanResultParams
     */
    'result'?: any | null;
}
/**
 * Scan target definition
 * @export
 * @enum {string}
 */

export const ScanTarget = {
    None: 'NONE',
    HealthCheck: 'HEALTH_CHECK',
    Software: 'SOFTWARE',
    Vulnerabilities: 'VULNERABILITIES',
    Inventory: 'INVENTORY',
    Compliance: 'COMPLIANCE'
} as const;

export type ScanTarget = typeof ScanTarget[keyof typeof ScanTarget];


/**
 * Scan topic definition
 * @export
 * @enum {string}
 */

export const ScanTopic = {
    None: 'NONE',
    Devices: 'DEVICES',
    Clouds: 'CLOUDS',
    Networks: 'NETWORKS',
    Software: 'SOFTWARE'
} as const;

export type ScanTopic = typeof ScanTopic[keyof typeof ScanTopic];


/**
 * Scan type definition
 * @export
 * @enum {string}
 */

export const ScanType = {
    None: 'NONE',
    Mandatory: 'MANDATORY',
    Recommended: 'RECOMMENDED',
    Optional: 'OPTIONAL',
    MandatoryLater: 'MANDATORY_LATER',
    System: 'SYSTEM',
    VioletX: 'VIOLET_X',
    Custom: 'CUSTOM'
} as const;

export type ScanType = typeof ScanType[keyof typeof ScanType];


/**
 * Scan collection single page representation
 * @export
 * @interface ScansPage
 */
export interface ScansPage {
    /**
     * 
     * @type {Array<Scan>}
     * @memberof ScansPage
     */
    'data': Array<Scan>;
    /**
     * 
     * @type {Pagination}
     * @memberof ScansPage
     */
    'page': Pagination;
}
/**
 * Scans status result execution statistic
 * @export
 * @interface ScansStatus
 */
export interface ScansStatus {
    [key: string]: any | any;

    /**
     * Total count of all active scans
     * @type {number}
     * @memberof ScansStatus
     */
    'total': number;
}
/**
 * Organizations status collection single page representation
 * @export
 * @interface ScansStatusPage
 */
export interface ScansStatusPage {
    /**
     * 
     * @type {Array<ScansStatus>}
     * @memberof ScansStatusPage
     */
    'data': Array<ScansStatus>;
    /**
     * 
     * @type {Pagination}
     * @memberof ScansStatusPage
     */
    'page': Pagination;
}
/**
 * Task definition.
 * @export
 * @interface Task
 */
export interface Task {
    [key: string]: any | any;

    /**
     * Task primary key
     * @type {number}
     * @memberof Task
     */
    'id': number;
    /**
     * Project primary key
     * @type {number}
     * @memberof Task
     */
    'projectId': number;
    /**
     * Organization primary key
     * @type {number}
     * @memberof Task
     */
    'organizationId': number;
    /**
     * User primary key
     * @type {number}
     * @memberof Task
     */
    'userId': number;
    /**
     * User assignee primary key
     * @type {number}
     * @memberof Task
     */
    'assigneeId'?: number | null;
    /**
     * User assignee primary key
     * @type {number}
     * @memberof Task
     */
    'assigneeId2'?: number | null;
    /**
     * Task phase primary key
     * @type {number}
     * @memberof Task
     */
    'taskPhaseId'?: number | null;
    /**
     * Task index
     * @type {number}
     * @memberof Task
     */
    'index': number;
    /**
     * Task taskKey
     * @type {string}
     * @memberof Task
     */
    'key': string;
    /**
     * Task name
     * @type {string}
     * @memberof Task
     */
    'name': string;
    /**
     * Task prefix
     * @type {string}
     * @memberof Task
     */
    'prefix': string;
    /**
     * 
     * @type {TaskPriority}
     * @memberof Task
     */
    'priority'?: TaskPriority;
    /**
     * 
     * @type {TaskStatus}
     * @memberof Task
     */
    'status'?: TaskStatus;
    /**
     * Task description
     * @type {string}
     * @memberof Task
     */
    'description'?: string;
    /**
     * log result should be a date
     * @type {string}
     * @memberof Task
     */
    'dateDueTo'?: string | null;
    /**
     * log result should be a date
     * @type {string}
     * @memberof Task
     */
    'startDate'?: string | null;
    /**
     * log result should be a date
     * @type {string}
     * @memberof Task
     */
    'endDate'?: string | null;
    /**
     * task duration
     * @type {number}
     * @memberof Task
     */
    'duration'?: number | null;
    /**
     * Task active state
     * @type {boolean}
     * @memberof Task
     */
    'active': boolean;
}
/**
 * Task Comment definition.
 * @export
 * @interface TaskComment
 */
export interface TaskComment {
    [key: string]: any | any;

    /**
     * Task Comment primary key
     * @type {number}
     * @memberof TaskComment
     */
    'id': number;
    /**
     * Task primary key
     * @type {number}
     * @memberof TaskComment
     */
    'taskId': number;
    /**
     * Project primary key
     * @type {number}
     * @memberof TaskComment
     */
    'projectId': number;
    /**
     * Organization primary key
     * @type {number}
     * @memberof TaskComment
     */
    'organizationId': number;
    /**
     * Author primary key
     * @type {number}
     * @memberof TaskComment
     */
    'authorId': number;
    /**
     * Task Comment description
     * @type {string}
     * @memberof TaskComment
     */
    'text': string;
    /**
     * Task Comment date created
     * @type {string}
     * @memberof TaskComment
     */
    'dateCreated'?: string | null;
    /**
     * Task Comment active state
     * @type {boolean}
     * @memberof TaskComment
     */
    'active': boolean;
}
/**
 * Task comment expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const TaskCommentExpand = {
    UserFirstName: 'userFirstName',
    UserLastName: 'userLastName',
    UserFileName: 'userFileName'
} as const;

export type TaskCommentExpand = typeof TaskCommentExpand[keyof typeof TaskCommentExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface TaskCommentsFields
 */
export interface TaskCommentsFields {
    /**
     * Organization primary key
     * @type {number}
     * @memberof TaskCommentsFields
     */
    'organizationId'?: number;
    /**
     * Task Comment active state
     * @type {boolean}
     * @memberof TaskCommentsFields
     */
    'active'?: boolean;
    /**
     * Project primary key
     * @type {number}
     * @memberof TaskCommentsFields
     */
    'projectId'?: number;
    /**
     * Author primary key
     * @type {number}
     * @memberof TaskCommentsFields
     */
    'authorId'?: number;
    /**
     * Task Comment description
     * @type {string}
     * @memberof TaskCommentsFields
     */
    'text'?: string;
    /**
     * Task Comment date created
     * @type {string}
     * @memberof TaskCommentsFields
     */
    'dateCreated'?: string | null;
    /**
     * Task primary key
     * @type {number}
     * @memberof TaskCommentsFields
     */
    'taskId'?: number;
}
/**
 * 
 * @export
 * @interface TaskCommentsPage
 */
export interface TaskCommentsPage {
    /**
     * 
     * @type {Array<TaskComment>}
     * @memberof TaskCommentsPage
     */
    'data': Array<TaskComment>;
    /**
     * 
     * @type {Pagination}
     * @memberof TaskCommentsPage
     */
    'page': Pagination;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface TaskCommentsParams
 */
export interface TaskCommentsParams {
    /**
     * Organization primary key
     * @type {number}
     * @memberof TaskCommentsParams
     */
    'organizationId': number;
    /**
     * Task Comment active state
     * @type {boolean}
     * @memberof TaskCommentsParams
     */
    'active': boolean;
    /**
     * Project primary key
     * @type {number}
     * @memberof TaskCommentsParams
     */
    'projectId': number;
    /**
     * Author primary key
     * @type {number}
     * @memberof TaskCommentsParams
     */
    'authorId': number;
    /**
     * Task Comment description
     * @type {string}
     * @memberof TaskCommentsParams
     */
    'text': string;
    /**
     * Task Comment date created
     * @type {string}
     * @memberof TaskCommentsParams
     */
    'dateCreated'?: string | null;
    /**
     * Task primary key
     * @type {number}
     * @memberof TaskCommentsParams
     */
    'taskId': number;
}
/**
 * Task expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const TaskExpand = {
    OrganizationName: 'organizationName',
    ProjectName: 'projectName',
    LastMessage: 'lastMessage',
    TaskLinkage: 'taskLinkage'
} as const;

export type TaskExpand = typeof TaskExpand[keyof typeof TaskExpand];


/**
 * Task Phase definition.
 * @export
 * @interface TaskPhase
 */
export interface TaskPhase {
    [key: string]: any | any;

    /**
     * Task Phase primary key
     * @type {number}
     * @memberof TaskPhase
     */
    'id': number;
    /**
     * Project primary key
     * @type {number}
     * @memberof TaskPhase
     */
    'projectId': number;
    /**
     * Organization primary key
     * @type {number}
     * @memberof TaskPhase
     */
    'organizationId': number;
    /**
     * Task Phase description
     * @type {string}
     * @memberof TaskPhase
     */
    'name': string;
    /**
     * Task Phase active state
     * @type {boolean}
     * @memberof TaskPhase
     */
    'active': boolean;
}
/**
 * Task comment expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const TaskPhaseExpand = {
    TasksCount: 'tasksCount'
} as const;

export type TaskPhaseExpand = typeof TaskPhaseExpand[keyof typeof TaskPhaseExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface TaskPhasesFields
 */
export interface TaskPhasesFields {
    /**
     * Organization primary key
     * @type {number}
     * @memberof TaskPhasesFields
     */
    'organizationId'?: number;
    /**
     * Task Phase active state
     * @type {boolean}
     * @memberof TaskPhasesFields
     */
    'active'?: boolean;
    /**
     * Task Phase description
     * @type {string}
     * @memberof TaskPhasesFields
     */
    'name'?: string;
    /**
     * Project primary key
     * @type {number}
     * @memberof TaskPhasesFields
     */
    'projectId'?: number;
}
/**
 * 
 * @export
 * @interface TaskPhasesPage
 */
export interface TaskPhasesPage {
    /**
     * 
     * @type {Array<TaskPhase>}
     * @memberof TaskPhasesPage
     */
    'data': Array<TaskPhase>;
    /**
     * 
     * @type {Pagination}
     * @memberof TaskPhasesPage
     */
    'page': Pagination;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface TaskPhasesParams
 */
export interface TaskPhasesParams {
    /**
     * Organization primary key
     * @type {number}
     * @memberof TaskPhasesParams
     */
    'organizationId': number;
    /**
     * Task Phase active state
     * @type {boolean}
     * @memberof TaskPhasesParams
     */
    'active': boolean;
    /**
     * Task Phase description
     * @type {string}
     * @memberof TaskPhasesParams
     */
    'name': string;
    /**
     * Project primary key
     * @type {number}
     * @memberof TaskPhasesParams
     */
    'projectId': number;
}
/**
 * Task priority definition
 * @export
 * @enum {string}
 */

export const TaskPriority = {
    Default: 'DEFAULT',
    Critical: 'CRITICAL',
    Blocker: 'BLOCKER'
} as const;

export type TaskPriority = typeof TaskPriority[keyof typeof TaskPriority];


/**
 * Task status definition
 * @export
 * @enum {string}
 */

export const TaskStatus = {
    Todo: 'TODO',
    InProgress: 'IN PROGRESS',
    Done: 'DONE'
} as const;

export type TaskStatus = typeof TaskStatus[keyof typeof TaskStatus];


/**
 * Tasks status collection single page representation
 * @export
 * @interface TaskStatusPage
 */
export interface TaskStatusPage {
    /**
     * 
     * @type {Array<TasksStatus>}
     * @memberof TaskStatusPage
     */
    'data': Array<TasksStatus>;
    /**
     * 
     * @type {Pagination}
     * @memberof TaskStatusPage
     */
    'page': Pagination;
}
/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface TasksFields
 */
export interface TasksFields {
    /**
     * Organization primary key
     * @type {number}
     * @memberof TasksFields
     */
    'organizationId'?: number;
    /**
     * User primary key
     * @type {number}
     * @memberof TasksFields
     */
    'userId'?: number;
    /**
     * Task active state
     * @type {boolean}
     * @memberof TasksFields
     */
    'active'?: boolean;
    /**
     * Task name
     * @type {string}
     * @memberof TasksFields
     */
    'name'?: string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof TasksFields
     */
    'status'?: TaskStatus;
    /**
     * Project primary key
     * @type {number}
     * @memberof TasksFields
     */
    'projectId'?: number;
    /**
     * Task description
     * @type {string}
     * @memberof TasksFields
     */
    'description'?: string;
    /**
     * Task prefix
     * @type {string}
     * @memberof TasksFields
     */
    'prefix'?: string;
    /**
     * log result should be a date
     * @type {string}
     * @memberof TasksFields
     */
    'dateDueTo'?: string | null;
    /**
     * User assignee primary key
     * @type {number}
     * @memberof TasksFields
     */
    'assigneeId'?: number | null;
    /**
     * User assignee primary key
     * @type {number}
     * @memberof TasksFields
     */
    'assigneeId2'?: number | null;
    /**
     * Task phase primary key
     * @type {number}
     * @memberof TasksFields
     */
    'taskPhaseId'?: number | null;
    /**
     * 
     * @type {TaskPriority}
     * @memberof TasksFields
     */
    'priority'?: TaskPriority;
    /**
     * log result should be a date
     * @type {string}
     * @memberof TasksFields
     */
    'startDate'?: string | null;
    /**
     * log result should be a date
     * @type {string}
     * @memberof TasksFields
     */
    'endDate'?: string | null;
    /**
     * task duration
     * @type {number}
     * @memberof TasksFields
     */
    'duration'?: number | null;
}
/**
 * Tasks linkage entity definition.
 * @export
 * @interface TasksLinkage
 */
export interface TasksLinkage {
    [key: string]: any | any;

    /**
     * Tasks linkage primary key
     * @type {number}
     * @memberof TasksLinkage
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof TasksLinkage
     */
    'taskId': number;
    /**
     * 
     * @type {number}
     * @memberof TasksLinkage
     */
    'boundTaskId': number;
}
/**
 * Tasks linkage expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const TasksLinkageExpand = {
    ExpandInfo: 'expandInfo'
} as const;

export type TasksLinkageExpand = typeof TasksLinkageExpand[keyof typeof TasksLinkageExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface TasksLinkageFields
 */
export interface TasksLinkageFields {
    /**
     * 
     * @type {number}
     * @memberof TasksLinkageFields
     */
    'taskId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TasksLinkageFields
     */
    'boundTaskId'?: number;
}
/**
 * 
 * @export
 * @interface TasksLinkagePage
 */
export interface TasksLinkagePage {
    /**
     * 
     * @type {Array<TasksLinkage>}
     * @memberof TasksLinkagePage
     */
    'data': Array<TasksLinkage>;
    /**
     * 
     * @type {Pagination}
     * @memberof TasksLinkagePage
     */
    'page': Pagination;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface TasksLinkageParams
 */
export interface TasksLinkageParams {
    /**
     * 
     * @type {number}
     * @memberof TasksLinkageParams
     */
    'taskId': number;
    /**
     * 
     * @type {number}
     * @memberof TasksLinkageParams
     */
    'boundTaskId': number;
}
/**
 * 
 * @export
 * @interface TasksPage
 */
export interface TasksPage {
    /**
     * 
     * @type {Array<Task>}
     * @memberof TasksPage
     */
    'data': Array<Task>;
    /**
     * 
     * @type {Pagination}
     * @memberof TasksPage
     */
    'page': Pagination;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface TasksParams
 */
export interface TasksParams {
    /**
     * Organization primary key
     * @type {number}
     * @memberof TasksParams
     */
    'organizationId': number;
    /**
     * User primary key
     * @type {number}
     * @memberof TasksParams
     */
    'userId': number;
    /**
     * Task active state
     * @type {boolean}
     * @memberof TasksParams
     */
    'active': boolean;
    /**
     * Task name
     * @type {string}
     * @memberof TasksParams
     */
    'name': string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof TasksParams
     */
    'status'?: TaskStatus;
    /**
     * Project primary key
     * @type {number}
     * @memberof TasksParams
     */
    'projectId': number;
    /**
     * Task description
     * @type {string}
     * @memberof TasksParams
     */
    'description'?: string;
    /**
     * Task prefix
     * @type {string}
     * @memberof TasksParams
     */
    'prefix': string;
    /**
     * log result should be a date
     * @type {string}
     * @memberof TasksParams
     */
    'dateDueTo'?: string | null;
    /**
     * User assignee primary key
     * @type {number}
     * @memberof TasksParams
     */
    'assigneeId'?: number | null;
    /**
     * User assignee primary key
     * @type {number}
     * @memberof TasksParams
     */
    'assigneeId2'?: number | null;
    /**
     * Task phase primary key
     * @type {number}
     * @memberof TasksParams
     */
    'taskPhaseId'?: number | null;
    /**
     * 
     * @type {TaskPriority}
     * @memberof TasksParams
     */
    'priority'?: TaskPriority;
    /**
     * log result should be a date
     * @type {string}
     * @memberof TasksParams
     */
    'startDate'?: string | null;
    /**
     * log result should be a date
     * @type {string}
     * @memberof TasksParams
     */
    'endDate'?: string | null;
    /**
     * task duration
     * @type {number}
     * @memberof TasksParams
     */
    'duration'?: number | null;
}
/**
 * Task status result execution statistic
 * @export
 * @interface TasksStatus
 */
export interface TasksStatus {
    [key: string]: any | any;

    /**
     * Total count of all active tasks
     * @type {number}
     * @memberof TasksStatus
     */
    'total_tasks': number;
    /**
     * Total count of all active completed tasks
     * @type {number}
     * @memberof TasksStatus
     */
    'completed': number;
    /**
     * Total count of all active to do tasks
     * @type {number}
     * @memberof TasksStatus
     */
    'to_do': number;
    /**
     * Total count of all active tasks in progress
     * @type {number}
     * @memberof TasksStatus
     */
    'in_progress': number;
}
/**
 * Templates entity
 * @export
 * @interface Template
 */
export interface Template {
    [key: string]: any | any;

    /**
     * Template primary key
     * @type {number}
     * @memberof Template
     */
    'id': number;
    /**
     * Template title
     * @type {string}
     * @memberof Template
     */
    'title': string;
    /**
     * Template description
     * @type {string}
     * @memberof Template
     */
    'description'?: string;
    /**
     * Template file name
     * @type {string}
     * @memberof Template
     */
    'fileName'?: string | null;
    /**
     * User primary key
     * @type {number}
     * @memberof Template
     */
    'userId'?: number | null;
    /**
     * id of typeform
     * @type {string}
     * @memberof Template
     */
    'formId'?: string | null;
    /**
     * 
     * @type {ProjectType}
     * @memberof Template
     */
    'projectType': ProjectType;
    /**
     * id of typeform
     * @type {number}
     * @memberof Template
     */
    'estimate'?: number | null;
    /**
     * array of selected clients id
     * @type {any}
     * @memberof Template
     */
    'selectedClients'?: any | null;
    /**
     * array of selected clients groups id
     * @type {any}
     * @memberof Template
     */
    'selectedClientsGroups'?: any | null;
    /**
     * 
     * @type {TemplatesType}
     * @memberof Template
     */
    'type': TemplatesType;
    /**
     * Template active state
     * @type {boolean}
     * @memberof Template
     */
    'active': boolean;
}
/**
 * Template expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const TemplateExpand = {
    UserFirstName: 'userFirstName',
    UserLastName: 'userLastName'
} as const;

export type TemplateExpand = typeof TemplateExpand[keyof typeof TemplateExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface TemplateFields
 */
export interface TemplateFields {
    /**
     * User primary key
     * @type {number}
     * @memberof TemplateFields
     */
    'userId'?: number | null;
    /**
     * Template active state
     * @type {boolean}
     * @memberof TemplateFields
     */
    'active'?: boolean;
    /**
     * Template description
     * @type {string}
     * @memberof TemplateFields
     */
    'description'?: string;
    /**
     * 
     * @type {TemplatesType}
     * @memberof TemplateFields
     */
    'type'?: TemplatesType;
    /**
     * Template title
     * @type {string}
     * @memberof TemplateFields
     */
    'title'?: string;
    /**
     * Template file name
     * @type {string}
     * @memberof TemplateFields
     */
    'fileName'?: string | null;
    /**
     * id of typeform
     * @type {string}
     * @memberof TemplateFields
     */
    'formId'?: string | null;
    /**
     * 
     * @type {ProjectType}
     * @memberof TemplateFields
     */
    'projectType'?: ProjectType;
    /**
     * id of typeform
     * @type {number}
     * @memberof TemplateFields
     */
    'estimate'?: number | null;
    /**
     * array of selected clients id
     * @type {any}
     * @memberof TemplateFields
     */
    'selectedClients'?: any | null;
    /**
     * array of selected clients groups id
     * @type {any}
     * @memberof TemplateFields
     */
    'selectedClientsGroups'?: any | null;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface TemplateParams
 */
export interface TemplateParams {
    /**
     * User primary key
     * @type {number}
     * @memberof TemplateParams
     */
    'userId'?: number | null;
    /**
     * Template active state
     * @type {boolean}
     * @memberof TemplateParams
     */
    'active': boolean;
    /**
     * Template description
     * @type {string}
     * @memberof TemplateParams
     */
    'description'?: string;
    /**
     * 
     * @type {TemplatesType}
     * @memberof TemplateParams
     */
    'type': TemplatesType;
    /**
     * Template title
     * @type {string}
     * @memberof TemplateParams
     */
    'title': string;
    /**
     * Template file name
     * @type {string}
     * @memberof TemplateParams
     */
    'fileName'?: string | null;
    /**
     * id of typeform
     * @type {string}
     * @memberof TemplateParams
     */
    'formId'?: string | null;
    /**
     * 
     * @type {ProjectType}
     * @memberof TemplateParams
     */
    'projectType': ProjectType;
    /**
     * id of typeform
     * @type {number}
     * @memberof TemplateParams
     */
    'estimate'?: number | null;
    /**
     * array of selected clients id
     * @type {any}
     * @memberof TemplateParams
     */
    'selectedClients'?: any | null;
    /**
     * array of selected clients groups id
     * @type {any}
     * @memberof TemplateParams
     */
    'selectedClientsGroups'?: any | null;
}
/**
 * Task template definition.
 * @export
 * @interface TemplateTask
 */
export interface TemplateTask {
    [key: string]: any | any;

    /**
     * Task template primary key
     * @type {number}
     * @memberof TemplateTask
     */
    'id': number;
    /**
     * Template primary key
     * @type {number}
     * @memberof TemplateTask
     */
    'templateId': number;
    /**
     * Task template phase primary key
     * @type {number}
     * @memberof TemplateTask
     */
    'templateTaskPhaseId': number | null;
    /**
     * Task template name
     * @type {string}
     * @memberof TemplateTask
     */
    'name': string;
    /**
     * 
     * @type {TaskPriority}
     * @memberof TemplateTask
     */
    'priority'?: TaskPriority;
    /**
     * Task template description
     * @type {string}
     * @memberof TemplateTask
     */
    'description'?: string;
    /**
     * Task template duration
     * @type {number}
     * @memberof TemplateTask
     */
    'duration'?: number | null;
    /**
     * Task template active state
     * @type {boolean}
     * @memberof TemplateTask
     */
    'active': boolean;
}
/**
 * Task template expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const TemplateTaskExpand = {
    UserFirstName: 'userFirstName',
    UserLastName: 'userLastName',
    UserFileName: 'userFileName',
    AssigneeInfo: 'assigneeInfo',
    TemplateTaskLinkage: 'templateTaskLinkage'
} as const;

export type TemplateTaskExpand = typeof TemplateTaskExpand[keyof typeof TemplateTaskExpand];


/**
 * Task Template Phase definition.
 * @export
 * @interface TemplateTaskPhase
 */
export interface TemplateTaskPhase {
    [key: string]: any | any;

    /**
     * Task template phase primary key
     * @type {number}
     * @memberof TemplateTaskPhase
     */
    'id': number;
    /**
     * Template primary key
     * @type {number}
     * @memberof TemplateTaskPhase
     */
    'templateId': number;
    /**
     * Task template phase name
     * @type {string}
     * @memberof TemplateTaskPhase
     */
    'name': string;
    /**
     * Task template phase active state
     * @type {boolean}
     * @memberof TemplateTaskPhase
     */
    'active': boolean;
}
/**
 * Task template expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const TemplateTaskPhaseExpand = {
    TasksCount: 'tasksCount'
} as const;

export type TemplateTaskPhaseExpand = typeof TemplateTaskPhaseExpand[keyof typeof TemplateTaskPhaseExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface TemplateTaskPhasesFields
 */
export interface TemplateTaskPhasesFields {
    /**
     * Task template phase active state
     * @type {boolean}
     * @memberof TemplateTaskPhasesFields
     */
    'active'?: boolean;
    /**
     * Task template phase name
     * @type {string}
     * @memberof TemplateTaskPhasesFields
     */
    'name'?: string;
    /**
     * Template primary key
     * @type {number}
     * @memberof TemplateTaskPhasesFields
     */
    'templateId'?: number;
}
/**
 * 
 * @export
 * @interface TemplateTaskPhasesPage
 */
export interface TemplateTaskPhasesPage {
    /**
     * 
     * @type {Array<TemplateTaskPhase>}
     * @memberof TemplateTaskPhasesPage
     */
    'data': Array<TemplateTaskPhase>;
    /**
     * 
     * @type {Pagination}
     * @memberof TemplateTaskPhasesPage
     */
    'page': Pagination;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface TemplateTaskPhasesParams
 */
export interface TemplateTaskPhasesParams {
    /**
     * Task template phase active state
     * @type {boolean}
     * @memberof TemplateTaskPhasesParams
     */
    'active': boolean;
    /**
     * Task template phase name
     * @type {string}
     * @memberof TemplateTaskPhasesParams
     */
    'name': string;
    /**
     * Template primary key
     * @type {number}
     * @memberof TemplateTaskPhasesParams
     */
    'templateId': number;
}
/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface TemplateTasksFields
 */
export interface TemplateTasksFields {
    /**
     * Task template active state
     * @type {boolean}
     * @memberof TemplateTasksFields
     */
    'active'?: boolean;
    /**
     * Task template name
     * @type {string}
     * @memberof TemplateTasksFields
     */
    'name'?: string;
    /**
     * Task template description
     * @type {string}
     * @memberof TemplateTasksFields
     */
    'description'?: string;
    /**
     * Template primary key
     * @type {number}
     * @memberof TemplateTasksFields
     */
    'templateId'?: number;
    /**
     * 
     * @type {TaskPriority}
     * @memberof TemplateTasksFields
     */
    'priority'?: TaskPriority;
    /**
     * Task template duration
     * @type {number}
     * @memberof TemplateTasksFields
     */
    'duration'?: number | null;
    /**
     * Task template phase primary key
     * @type {number}
     * @memberof TemplateTasksFields
     */
    'templateTaskPhaseId'?: number | null;
}
/**
 * TemplateTasks linkage entity definition.
 * @export
 * @interface TemplateTasksLinkage
 */
export interface TemplateTasksLinkage {
    [key: string]: any | any;

    /**
     * TemplateTasks linkage primary key
     * @type {number}
     * @memberof TemplateTasksLinkage
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof TemplateTasksLinkage
     */
    'templateTaskId': number;
    /**
     * 
     * @type {number}
     * @memberof TemplateTasksLinkage
     */
    'boundTemplateTaskId': number;
}
/**
 * Template tasks linkage expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const TemplateTasksLinkageExpand = {
    ExpandInfo: 'expandInfo'
} as const;

export type TemplateTasksLinkageExpand = typeof TemplateTasksLinkageExpand[keyof typeof TemplateTasksLinkageExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface TemplateTasksLinkageFields
 */
export interface TemplateTasksLinkageFields {
    /**
     * 
     * @type {number}
     * @memberof TemplateTasksLinkageFields
     */
    'templateTaskId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TemplateTasksLinkageFields
     */
    'boundTemplateTaskId'?: number;
}
/**
 * 
 * @export
 * @interface TemplateTasksLinkagePage
 */
export interface TemplateTasksLinkagePage {
    /**
     * 
     * @type {Array<TemplateTasksLinkage>}
     * @memberof TemplateTasksLinkagePage
     */
    'data': Array<TemplateTasksLinkage>;
    /**
     * 
     * @type {Pagination}
     * @memberof TemplateTasksLinkagePage
     */
    'page': Pagination;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface TemplateTasksLinkageParams
 */
export interface TemplateTasksLinkageParams {
    /**
     * 
     * @type {number}
     * @memberof TemplateTasksLinkageParams
     */
    'templateTaskId': number;
    /**
     * 
     * @type {number}
     * @memberof TemplateTasksLinkageParams
     */
    'boundTemplateTaskId': number;
}
/**
 * 
 * @export
 * @interface TemplateTasksPage
 */
export interface TemplateTasksPage {
    /**
     * 
     * @type {Array<TemplateTask>}
     * @memberof TemplateTasksPage
     */
    'data': Array<TemplateTask>;
    /**
     * 
     * @type {Pagination}
     * @memberof TemplateTasksPage
     */
    'page': Pagination;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface TemplateTasksParams
 */
export interface TemplateTasksParams {
    /**
     * Task template active state
     * @type {boolean}
     * @memberof TemplateTasksParams
     */
    'active': boolean;
    /**
     * Task template name
     * @type {string}
     * @memberof TemplateTasksParams
     */
    'name': string;
    /**
     * Task template description
     * @type {string}
     * @memberof TemplateTasksParams
     */
    'description'?: string;
    /**
     * Template primary key
     * @type {number}
     * @memberof TemplateTasksParams
     */
    'templateId': number;
    /**
     * 
     * @type {TaskPriority}
     * @memberof TemplateTasksParams
     */
    'priority'?: TaskPriority;
    /**
     * Task template duration
     * @type {number}
     * @memberof TemplateTasksParams
     */
    'duration'?: number | null;
    /**
     * Task template phase primary key
     * @type {number}
     * @memberof TemplateTasksParams
     */
    'templateTaskPhaseId': number | null;
}
/**
 * 
 * @export
 * @interface TemplatesPage
 */
export interface TemplatesPage {
    /**
     * 
     * @type {Array<Template>}
     * @memberof TemplatesPage
     */
    'data': Array<Template>;
    /**
     * 
     * @type {Pagination}
     * @memberof TemplatesPage
     */
    'page': Pagination;
}
/**
 * template type definition
 * @export
 * @enum {string}
 */

export const TemplatesType = {
    Public: 'PUBLIC',
    VxOnly: 'VX_ONLY',
    SelectedClients: 'SELECTED_CLIENTS'
} as const;

export type TemplatesType = typeof TemplatesType[keyof typeof TemplatesType];


/**
 * User entity definition.
 * @export
 * @interface User
 */
export interface User {
    [key: string]: any | any;

    /**
     * User primary key
     * @type {number}
     * @memberof User
     */
    'id': number;
    /**
     * User organization primary key
     * @type {number}
     * @memberof User
     */
    'organizationId': number;
    /**
     * User role primary key
     * @type {number}
     * @memberof User
     */
    'roleId': number;
    /**
     * User email
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * User first name
     * @type {string}
     * @memberof User
     */
    'firstName'?: string | null;
    /**
     * User last name
     * @type {string}
     * @memberof User
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {UserType}
     * @memberof User
     */
    'type'?: UserType;
    /**
     * 
     * @type {UserStatus}
     * @memberof User
     */
    'status'?: UserStatus;
    /**
     * User keycloak id
     * @type {string}
     * @memberof User
     */
    'authKey'?: string | null;
    /**
     * User job title
     * @type {string}
     * @memberof User
     */
    'jobTitle'?: string | null;
    /**
     * User disabled state
     * @type {boolean}
     * @memberof User
     */
    'active': boolean;
    /**
     * User file name
     * @type {string}
     * @memberof User
     */
    'fileName'?: string | null;
    /**
     * Slack Id
     * @type {string}
     * @memberof User
     */
    'slackId'?: string | null;
    /**
     * User invite sent expired timestamp or null
     * @type {string}
     * @memberof User
     */
    'inviteExpired'?: string | null;
}
/**
 * User expandable (or embedded) data defined by request parameter
 * @export
 * @enum {string}
 */

export const UserExpand = {
    UserEmail: 'userEmail',
    DeviceCount: 'deviceCount',
    AgentCount: 'agentCount',
    DistributedQueryCount: 'distributedQueryCount',
    DeviceFilter: 'deviceFilter'
} as const;

export type UserExpand = typeof UserExpand[keyof typeof UserExpand];


/**
 * Used for patch request that should not change entity relationships.
 * @export
 * @interface UserFields
 */
export interface UserFields {
    /**
     * User organization primary key
     * @type {number}
     * @memberof UserFields
     */
    'organizationId'?: number;
    /**
     * User disabled state
     * @type {boolean}
     * @memberof UserFields
     */
    'active'?: boolean;
    /**
     * 
     * @type {UserStatus}
     * @memberof UserFields
     */
    'status'?: UserStatus;
    /**
     * 
     * @type {UserType}
     * @memberof UserFields
     */
    'type'?: UserType;
    /**
     * User first name
     * @type {string}
     * @memberof UserFields
     */
    'firstName'?: string | null;
    /**
     * User last name
     * @type {string}
     * @memberof UserFields
     */
    'lastName'?: string | null;
    /**
     * User email
     * @type {string}
     * @memberof UserFields
     */
    'email'?: string;
    /**
     * User file name
     * @type {string}
     * @memberof UserFields
     */
    'fileName'?: string | null;
    /**
     * User role primary key
     * @type {number}
     * @memberof UserFields
     */
    'roleId'?: number;
    /**
     * User job title
     * @type {string}
     * @memberof UserFields
     */
    'jobTitle'?: string | null;
    /**
     * Slack Id
     * @type {string}
     * @memberof UserFields
     */
    'slackId'?: string | null;
    /**
     * User invite sent expired timestamp or null
     * @type {string}
     * @memberof UserFields
     */
    'inviteExpired'?: string | null;
}
/**
 * Used for post request that should not contain an id.
 * @export
 * @interface UserParams
 */
export interface UserParams {
    /**
     * User organization primary key
     * @type {number}
     * @memberof UserParams
     */
    'organizationId': number;
    /**
     * User disabled state
     * @type {boolean}
     * @memberof UserParams
     */
    'active': boolean;
    /**
     * 
     * @type {UserStatus}
     * @memberof UserParams
     */
    'status'?: UserStatus;
    /**
     * 
     * @type {UserType}
     * @memberof UserParams
     */
    'type'?: UserType;
    /**
     * User first name
     * @type {string}
     * @memberof UserParams
     */
    'firstName'?: string | null;
    /**
     * User last name
     * @type {string}
     * @memberof UserParams
     */
    'lastName'?: string | null;
    /**
     * User email
     * @type {string}
     * @memberof UserParams
     */
    'email': string;
    /**
     * User file name
     * @type {string}
     * @memberof UserParams
     */
    'fileName'?: string | null;
    /**
     * User role primary key
     * @type {number}
     * @memberof UserParams
     */
    'roleId': number;
    /**
     * User keycloak id
     * @type {string}
     * @memberof UserParams
     */
    'authKey'?: string | null;
    /**
     * User job title
     * @type {string}
     * @memberof UserParams
     */
    'jobTitle'?: string | null;
    /**
     * Slack Id
     * @type {string}
     * @memberof UserParams
     */
    'slackId'?: string | null;
    /**
     * User invite sent expired timestamp or null
     * @type {string}
     * @memberof UserParams
     */
    'inviteExpired'?: string | null;
}
/**
 * User status definition
 * @export
 * @enum {string}
 */

export const UserStatus = {
    None: 'NONE',
    InviteSent: 'INVITE_SENT',
    AllSet: 'ALL_SET'
} as const;

export type UserStatus = typeof UserStatus[keyof typeof UserStatus];


/**
 * User type definition
 * @export
 * @enum {string}
 */

export const UserType = {
    None: 'NONE',
    Employee: 'EMPLOYEE',
    Contractor: 'CONTRACTOR',
    Consultant: 'CONSULTANT'
} as const;

export type UserType = typeof UserType[keyof typeof UserType];


/**
 * User collection single page representation
 * @export
 * @interface UsersPage
 */
export interface UsersPage {
    /**
     * 
     * @type {Array<User>}
     * @memberof UsersPage
     */
    'data': Array<User>;
    /**
     * 
     * @type {Pagination}
     * @memberof UsersPage
     */
    'page': Pagination;
}
/**
 * Users status result execution statistic
 * @export
 * @interface UsersStatus
 */
export interface UsersStatus {
    [key: string]: any | any;

    /**
     * Total count of all active users
     * @type {number}
     * @memberof UsersStatus
     */
    'total': number;
}
/**
 * Organizations status collection single page representation
 * @export
 * @interface UsersStatusPage
 */
export interface UsersStatusPage {
    /**
     * 
     * @type {Array<UsersStatus>}
     * @memberof UsersStatusPage
     */
    'data': Array<UsersStatus>;
    /**
     * 
     * @type {Pagination}
     * @memberof UsersStatusPage
     */
    'page': Pagination;
}
/**
 * 
 * @export
 * @interface ValidateErrorJSON
 */
export interface ValidateErrorJSON {
    /**
     * 
     * @type {string}
     * @memberof ValidateErrorJSON
     */
    'message': string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ValidateErrorJSON
     */
    'details': { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface VulnerabilitiesCount
 */
export interface VulnerabilitiesCount {
    /**
     * Count of total vulnerabilities severity
     * @type {number}
     * @memberof VulnerabilitiesCount
     */
    'info': number;
    /**
     * 
     * @type {number}
     * @memberof VulnerabilitiesCount
     */
    'low': number;
    /**
     * 
     * @type {number}
     * @memberof VulnerabilitiesCount
     */
    'medium': number;
    /**
     * 
     * @type {number}
     * @memberof VulnerabilitiesCount
     */
    'high': number;
    /**
     * 
     * @type {number}
     * @memberof VulnerabilitiesCount
     */
    'critical': number;
}

/**
 * AssignmentApi - axios parameter creator
 * @export
 */
export const AssignmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single assignment instance
         * @param {AssignmentParams} assignmentParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentCreate: async (assignmentParams: AssignmentParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentParams' is not null or undefined
            assertParamExists('assignmentCreate', 'assignmentParams', assignmentParams)
            const localVarPath = `/assignments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignmentParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete assignment by its primary key
         * @param {number} id assignment primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignmentDelete', 'id', id)
            const localVarPath = `/assignments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single assignment instance
         * @param {number} id -- assignment primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignmentGetById', 'id', id)
            const localVarPath = `/assignments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of registered assignments
         * @param {Array<string>} [q] query for search in user fields (e.g. assignmentValue)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]action&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;target:DEVICES&#x60;)
         * @param {Array<AssignmentExpand>} [expand] define distributed assignment expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<AssignmentExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assignments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch assignment instance
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {AssignmentFields} assignmentFields instance properties to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentPatch: async (filter: Array<string>, assignmentFields: AssignmentFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('assignmentPatch', 'filter', filter)
            // verify required parameter 'assignmentFields' is not null or undefined
            assertParamExists('assignmentPatch', 'assignmentFields', assignmentFields)
            const localVarPath = `/assignments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignmentFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update assignment instance
         * @param {number} id assignment primary key
         * @param {AssignmentParams} assignmentParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentUpdate: async (id: number, assignmentParams: AssignmentParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignmentUpdate', 'id', id)
            // verify required parameter 'assignmentParams' is not null or undefined
            assertParamExists('assignmentUpdate', 'assignmentParams', assignmentParams)
            const localVarPath = `/assignments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignmentParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssignmentApi - functional programming interface
 * @export
 */
export const AssignmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssignmentApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single assignment instance
         * @param {AssignmentParams} assignmentParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentCreate(assignmentParams: AssignmentParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentCreate(assignmentParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete assignment by its primary key
         * @param {number} id assignment primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single assignment instance
         * @param {number} id -- assignment primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Assignment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of registered assignments
         * @param {Array<string>} [q] query for search in user fields (e.g. assignmentValue)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]action&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;target:DEVICES&#x60;)
         * @param {Array<AssignmentExpand>} [expand] define distributed assignment expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<AssignmentExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch assignment instance
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {AssignmentFields} assignmentFields instance properties to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentPatch(filter: Array<string>, assignmentFields: AssignmentFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentPatch(filter, assignmentFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update assignment instance
         * @param {number} id assignment primary key
         * @param {AssignmentParams} assignmentParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentUpdate(id: number, assignmentParams: AssignmentParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentUpdate(id, assignmentParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssignmentApi - factory interface
 * @export
 */
export const AssignmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssignmentApiFp(configuration)
    return {
        /**
         * Create single assignment instance
         * @param {AssignmentParams} assignmentParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentCreate(assignmentParams: AssignmentParams, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.assignmentCreate(assignmentParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete assignment by its primary key
         * @param {number} id assignment primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.assignmentDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single assignment instance
         * @param {number} id -- assignment primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentGetById(id: number, options?: any): AxiosPromise<Assignment> {
            return localVarFp.assignmentGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of registered assignments
         * @param {Array<string>} [q] query for search in user fields (e.g. assignmentValue)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]action&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;target:DEVICES&#x60;)
         * @param {Array<AssignmentExpand>} [expand] define distributed assignment expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<AssignmentExpand>, page?: number, limit?: number, options?: any): AxiosPromise<AssignmentPage> {
            return localVarFp.assignmentList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch assignment instance
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {AssignmentFields} assignmentFields instance properties to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentPatch(filter: Array<string>, assignmentFields: AssignmentFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.assignmentPatch(filter, assignmentFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Update assignment instance
         * @param {number} id assignment primary key
         * @param {AssignmentParams} assignmentParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentUpdate(id: number, assignmentParams: AssignmentParams, options?: any): AxiosPromise<void> {
            return localVarFp.assignmentUpdate(id, assignmentParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssignmentApi - interface
 * @export
 * @interface AssignmentApi
 */
export interface AssignmentApiInterface {
    /**
     * Create single assignment instance
     * @param {AssignmentParams} assignmentParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApiInterface
     */
    assignmentCreate(assignmentParams: AssignmentParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse201>;

    /**
     * Delete assignment by its primary key
     * @param {number} id assignment primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApiInterface
     */
    assignmentDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return single assignment instance
     * @param {number} id -- assignment primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApiInterface
     */
    assignmentGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<Assignment>;

    /**
     * Return collection of registered assignments
     * @param {Array<string>} [q] query for search in user fields (e.g. assignmentValue)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]action&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;target:DEVICES&#x60;)
     * @param {Array<AssignmentExpand>} [expand] define distributed assignment expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApiInterface
     */
    assignmentList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<AssignmentExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<AssignmentPage>;

    /**
     * Patch assignment instance
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {AssignmentFields} assignmentFields instance properties to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApiInterface
     */
    assignmentPatch(filter: Array<string>, assignmentFields: AssignmentFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Update assignment instance
     * @param {number} id assignment primary key
     * @param {AssignmentParams} assignmentParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApiInterface
     */
    assignmentUpdate(id: number, assignmentParams: AssignmentParams, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AssignmentApi - object-oriented interface
 * @export
 * @class AssignmentApi
 * @extends {BaseAPI}
 */
export class AssignmentApi extends BaseAPI implements AssignmentApiInterface {
    /**
     * Create single assignment instance
     * @param {AssignmentParams} assignmentParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public assignmentCreate(assignmentParams: AssignmentParams, options?: AxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).assignmentCreate(assignmentParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete assignment by its primary key
     * @param {number} id assignment primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public assignmentDelete(id: number, options?: AxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).assignmentDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single assignment instance
     * @param {number} id -- assignment primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public assignmentGetById(id: number, options?: AxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).assignmentGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of registered assignments
     * @param {Array<string>} [q] query for search in user fields (e.g. assignmentValue)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]action&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;target:DEVICES&#x60;)
     * @param {Array<AssignmentExpand>} [expand] define distributed assignment expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public assignmentList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<AssignmentExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).assignmentList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch assignment instance
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {AssignmentFields} assignmentFields instance properties to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public assignmentPatch(filter: Array<string>, assignmentFields: AssignmentFields, options?: AxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).assignmentPatch(filter, assignmentFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update assignment instance
     * @param {number} id assignment primary key
     * @param {AssignmentParams} assignmentParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public assignmentUpdate(id: number, assignmentParams: AssignmentParams, options?: AxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).assignmentUpdate(id, assignmentParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return authorization of logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Email to the user with a link they can click to execute UPDATE_PASSWORD action.
         * @param {ExecuteActionEmail} executeActionEmail user primary key and redirect uri (optionally)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeActionsEmail: async (executeActionEmail: ExecuteActionEmail, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'executeActionEmail' is not null or undefined
            assertParamExists('executeActionsEmail', 'executeActionEmail', executeActionEmail)
            const localVarPath = `/auth/executeActionsEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(executeActionEmail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * Return authorization of logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Email to the user with a link they can click to execute UPDATE_PASSWORD action.
         * @param {ExecuteActionEmail} executeActionEmail user primary key and redirect uri (optionally)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executeActionsEmail(executeActionEmail: ExecuteActionEmail, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.executeActionsEmail(executeActionEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * Return authorization of logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUser(options?: any): AxiosPromise<AuthUser> {
            return localVarFp.authUser(options).then((request) => request(axios, basePath));
        },
        /**
         * Email to the user with a link they can click to execute UPDATE_PASSWORD action.
         * @param {ExecuteActionEmail} executeActionEmail user primary key and redirect uri (optionally)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeActionsEmail(executeActionEmail: ExecuteActionEmail, options?: any): AxiosPromise<void> {
            return localVarFp.executeActionsEmail(executeActionEmail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - interface
 * @export
 * @interface AuthApi
 */
export interface AuthApiInterface {
    /**
     * Return authorization of logged-in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authUser(options?: AxiosRequestConfig): AxiosPromise<AuthUser>;

    /**
     * Email to the user with a link they can click to execute UPDATE_PASSWORD action.
     * @param {ExecuteActionEmail} executeActionEmail user primary key and redirect uri (optionally)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    executeActionsEmail(executeActionEmail: ExecuteActionEmail, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI implements AuthApiInterface {
    /**
     * Return authorization of logged-in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authUser(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Email to the user with a link they can click to execute UPDATE_PASSWORD action.
     * @param {ExecuteActionEmail} executeActionEmail user primary key and redirect uri (optionally)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public executeActionsEmail(executeActionEmail: ExecuteActionEmail, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).executeActionsEmail(executeActionEmail, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeviceApi - axios parameter creator
 * @export
 */
export const DeviceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single device instance
         * @param {DeviceParams} deviceParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceCreate: async (deviceParams: DeviceParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceParams' is not null or undefined
            assertParamExists('deviceCreate', 'deviceParams', deviceParams)
            const localVarPath = `/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete device by its primary key
         * @param {number} id device primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deviceDelete', 'id', id)
            const localVarPath = `/devices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single device instance
         * @param {number} id -- device primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deviceGetById', 'id', id)
            const localVarPath = `/devices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of registered devices
         * @param {Array<string>} [q] query for search in user fields (e.g. deviceInfo)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;userId:1&#x60;)
         * @param {Array<DeviceExpand>} [expand] define distributed device expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DeviceExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch device instance
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {DeviceFields} deviceFields instance properties to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devicePatch: async (filter: Array<string>, deviceFields: DeviceFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('devicePatch', 'filter', filter)
            // verify required parameter 'deviceFields' is not null or undefined
            assertParamExists('devicePatch', 'deviceFields', deviceFields)
            const localVarPath = `/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of devices
         * @param {Array<string>} [q] query for search in user fields (e.g. deviceInfo)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;userId:1&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceStatus: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/devices/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update device instance
         * @param {number} id device primary key
         * @param {DeviceParams} deviceParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceUpdate: async (id: number, deviceParams: DeviceParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deviceUpdate', 'id', id)
            // verify required parameter 'deviceParams' is not null or undefined
            assertParamExists('deviceUpdate', 'deviceParams', deviceParams)
            const localVarPath = `/devices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return device OSQuery agent enroll_secret
         * @param {number} id -- device primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnrollSecret: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEnrollSecret', 'id', id)
            const localVarPath = `/devices/enroll-secret/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceApi - functional programming interface
 * @export
 */
export const DeviceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single device instance
         * @param {DeviceParams} deviceParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceCreate(deviceParams: DeviceParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2011>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceCreate(deviceParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete device by its primary key
         * @param {number} id device primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single device instance
         * @param {number} id -- device primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Device>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of registered devices
         * @param {Array<string>} [q] query for search in user fields (e.g. deviceInfo)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;userId:1&#x60;)
         * @param {Array<DeviceExpand>} [expand] define distributed device expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DeviceExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch device instance
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {DeviceFields} deviceFields instance properties to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async devicePatch(filter: Array<string>, deviceFields: DeviceFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.devicePatch(filter, deviceFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of devices
         * @param {Array<string>} [q] query for search in user fields (e.g. deviceInfo)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;userId:1&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceStatus(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesStatusPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceStatus(q, sort, filter, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update device instance
         * @param {number} id device primary key
         * @param {DeviceParams} deviceParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceUpdate(id: number, deviceParams: DeviceParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceUpdate(id, deviceParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return device OSQuery agent enroll_secret
         * @param {number} id -- device primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnrollSecret(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnrollSecret(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceApi - factory interface
 * @export
 */
export const DeviceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceApiFp(configuration)
    return {
        /**
         * Create single device instance
         * @param {DeviceParams} deviceParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceCreate(deviceParams: DeviceParams, options?: any): AxiosPromise<InlineResponse2011> {
            return localVarFp.deviceCreate(deviceParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete device by its primary key
         * @param {number} id device primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deviceDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single device instance
         * @param {number} id -- device primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceGetById(id: number, options?: any): AxiosPromise<Device> {
            return localVarFp.deviceGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of registered devices
         * @param {Array<string>} [q] query for search in user fields (e.g. deviceInfo)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;userId:1&#x60;)
         * @param {Array<DeviceExpand>} [expand] define distributed device expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DeviceExpand>, page?: number, limit?: number, options?: any): AxiosPromise<DevicesPage> {
            return localVarFp.deviceList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch device instance
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {DeviceFields} deviceFields instance properties to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devicePatch(filter: Array<string>, deviceFields: DeviceFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.devicePatch(filter, deviceFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of devices
         * @param {Array<string>} [q] query for search in user fields (e.g. deviceInfo)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;userId:1&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceStatus(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: any): AxiosPromise<DevicesStatusPage> {
            return localVarFp.deviceStatus(q, sort, filter, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Update device instance
         * @param {number} id device primary key
         * @param {DeviceParams} deviceParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceUpdate(id: number, deviceParams: DeviceParams, options?: any): AxiosPromise<void> {
            return localVarFp.deviceUpdate(id, deviceParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Return device OSQuery agent enroll_secret
         * @param {number} id -- device primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnrollSecret(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.getEnrollSecret(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceApi - interface
 * @export
 * @interface DeviceApi
 */
export interface DeviceApiInterface {
    /**
     * Create single device instance
     * @param {DeviceParams} deviceParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    deviceCreate(deviceParams: DeviceParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse2011>;

    /**
     * Delete device by its primary key
     * @param {number} id device primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    deviceDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return single device instance
     * @param {number} id -- device primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    deviceGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<Device>;

    /**
     * Return collection of registered devices
     * @param {Array<string>} [q] query for search in user fields (e.g. deviceInfo)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;userId:1&#x60;)
     * @param {Array<DeviceExpand>} [expand] define distributed device expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    deviceList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DeviceExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<DevicesPage>;

    /**
     * Patch device instance
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {DeviceFields} deviceFields instance properties to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    devicePatch(filter: Array<string>, deviceFields: DeviceFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Return collection of devices
     * @param {Array<string>} [q] query for search in user fields (e.g. deviceInfo)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;userId:1&#x60;)
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    deviceStatus(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<DevicesStatusPage>;

    /**
     * Update device instance
     * @param {number} id device primary key
     * @param {DeviceParams} deviceParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    deviceUpdate(id: number, deviceParams: DeviceParams, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return device OSQuery agent enroll_secret
     * @param {number} id -- device primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApiInterface
     */
    getEnrollSecret(id: number, options?: AxiosRequestConfig): AxiosPromise<string>;

}

/**
 * DeviceApi - object-oriented interface
 * @export
 * @class DeviceApi
 * @extends {BaseAPI}
 */
export class DeviceApi extends BaseAPI implements DeviceApiInterface {
    /**
     * Create single device instance
     * @param {DeviceParams} deviceParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceCreate(deviceParams: DeviceParams, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceCreate(deviceParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete device by its primary key
     * @param {number} id device primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceDelete(id: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single device instance
     * @param {number} id -- device primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceGetById(id: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of registered devices
     * @param {Array<string>} [q] query for search in user fields (e.g. deviceInfo)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;userId:1&#x60;)
     * @param {Array<DeviceExpand>} [expand] define distributed device expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DeviceExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch device instance
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {DeviceFields} deviceFields instance properties to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public devicePatch(filter: Array<string>, deviceFields: DeviceFields, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).devicePatch(filter, deviceFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of devices
     * @param {Array<string>} [q] query for search in user fields (e.g. deviceInfo)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;userId:1&#x60;)
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceStatus(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceStatus(q, sort, filter, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update device instance
     * @param {number} id device primary key
     * @param {DeviceParams} deviceParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceUpdate(id: number, deviceParams: DeviceParams, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deviceUpdate(id, deviceParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return device OSQuery agent enroll_secret
     * @param {number} id -- device primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public getEnrollSecret(id: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).getEnrollSecret(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DistributedQueriesApi - axios parameter creator
 * @export
 */
export const DistributedQueriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single distributed query instance
         * @param {DistributedQueryParams} distributedQueryParams distributed query params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryCreate: async (distributedQueryParams: DistributedQueryParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'distributedQueryParams' is not null or undefined
            assertParamExists('distributedQueryCreate', 'distributedQueryParams', distributedQueryParams)
            const localVarPath = `/distributed-queries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(distributedQueryParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete distributed query by its primary key
         * @param {number} id query primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('distributedQueryDelete', 'id', id)
            const localVarPath = `/distributed-queries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single distributed query instance
         * @param {number} id -- query primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('distributedQueryGetById', 'id', id)
            const localVarPath = `/distributed-queries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of registered distributed queries
         * @param {Array<string>} [q] query for search in distributed query fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]name&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:EMPLOYEE&#x60;)
         * @param {Array<DistributedQueryExpand>} [expand] define distributed query expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DistributedQueryExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/distributed-queries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch distributed query instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {DistributedQueryFields} distributedQueryFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryPatch: async (filter: Array<string>, distributedQueryFields: DistributedQueryFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('distributedQueryPatch', 'filter', filter)
            // verify required parameter 'distributedQueryFields' is not null or undefined
            assertParamExists('distributedQueryPatch', 'distributedQueryFields', distributedQueryFields)
            const localVarPath = `/distributed-queries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(distributedQueryFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update distributed query instance
         * @param {number} id -- distributed query primary key
         * @param {DistributedQueryParams} distributedQueryParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryUpdate: async (id: number, distributedQueryParams: DistributedQueryParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('distributedQueryUpdate', 'id', id)
            // verify required parameter 'distributedQueryParams' is not null or undefined
            assertParamExists('distributedQueryUpdate', 'distributedQueryParams', distributedQueryParams)
            const localVarPath = `/distributed-queries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(distributedQueryParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DistributedQueriesApi - functional programming interface
 * @export
 */
export const DistributedQueriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DistributedQueriesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single distributed query instance
         * @param {DistributedQueryParams} distributedQueryParams distributed query params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async distributedQueryCreate(distributedQueryParams: DistributedQueryParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2012>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.distributedQueryCreate(distributedQueryParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete distributed query by its primary key
         * @param {number} id query primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async distributedQueryDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.distributedQueryDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single distributed query instance
         * @param {number} id -- query primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async distributedQueryGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DistributedQuery>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.distributedQueryGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of registered distributed queries
         * @param {Array<string>} [q] query for search in distributed query fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]name&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:EMPLOYEE&#x60;)
         * @param {Array<DistributedQueryExpand>} [expand] define distributed query expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async distributedQueryList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DistributedQueryExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DistributedQueriesPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.distributedQueryList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch distributed query instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {DistributedQueryFields} distributedQueryFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async distributedQueryPatch(filter: Array<string>, distributedQueryFields: DistributedQueryFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.distributedQueryPatch(filter, distributedQueryFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update distributed query instance
         * @param {number} id -- distributed query primary key
         * @param {DistributedQueryParams} distributedQueryParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async distributedQueryUpdate(id: number, distributedQueryParams: DistributedQueryParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.distributedQueryUpdate(id, distributedQueryParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DistributedQueriesApi - factory interface
 * @export
 */
export const DistributedQueriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DistributedQueriesApiFp(configuration)
    return {
        /**
         * Create single distributed query instance
         * @param {DistributedQueryParams} distributedQueryParams distributed query params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryCreate(distributedQueryParams: DistributedQueryParams, options?: any): AxiosPromise<InlineResponse2012> {
            return localVarFp.distributedQueryCreate(distributedQueryParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete distributed query by its primary key
         * @param {number} id query primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.distributedQueryDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single distributed query instance
         * @param {number} id -- query primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryGetById(id: number, options?: any): AxiosPromise<DistributedQuery> {
            return localVarFp.distributedQueryGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of registered distributed queries
         * @param {Array<string>} [q] query for search in distributed query fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]name&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:EMPLOYEE&#x60;)
         * @param {Array<DistributedQueryExpand>} [expand] define distributed query expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DistributedQueryExpand>, page?: number, limit?: number, options?: any): AxiosPromise<DistributedQueriesPage> {
            return localVarFp.distributedQueryList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch distributed query instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {DistributedQueryFields} distributedQueryFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryPatch(filter: Array<string>, distributedQueryFields: DistributedQueryFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.distributedQueryPatch(filter, distributedQueryFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Update distributed query instance
         * @param {number} id -- distributed query primary key
         * @param {DistributedQueryParams} distributedQueryParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryUpdate(id: number, distributedQueryParams: DistributedQueryParams, options?: any): AxiosPromise<void> {
            return localVarFp.distributedQueryUpdate(id, distributedQueryParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DistributedQueriesApi - interface
 * @export
 * @interface DistributedQueriesApi
 */
export interface DistributedQueriesApiInterface {
    /**
     * Create single distributed query instance
     * @param {DistributedQueryParams} distributedQueryParams distributed query params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueriesApiInterface
     */
    distributedQueryCreate(distributedQueryParams: DistributedQueryParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse2012>;

    /**
     * Delete distributed query by its primary key
     * @param {number} id query primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueriesApiInterface
     */
    distributedQueryDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return single distributed query instance
     * @param {number} id -- query primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueriesApiInterface
     */
    distributedQueryGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<DistributedQuery>;

    /**
     * Return collection of registered distributed queries
     * @param {Array<string>} [q] query for search in distributed query fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]name&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:EMPLOYEE&#x60;)
     * @param {Array<DistributedQueryExpand>} [expand] define distributed query expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueriesApiInterface
     */
    distributedQueryList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DistributedQueryExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<DistributedQueriesPage>;

    /**
     * Patch distributed query instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {DistributedQueryFields} distributedQueryFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueriesApiInterface
     */
    distributedQueryPatch(filter: Array<string>, distributedQueryFields: DistributedQueryFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Update distributed query instance
     * @param {number} id -- distributed query primary key
     * @param {DistributedQueryParams} distributedQueryParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueriesApiInterface
     */
    distributedQueryUpdate(id: number, distributedQueryParams: DistributedQueryParams, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * DistributedQueriesApi - object-oriented interface
 * @export
 * @class DistributedQueriesApi
 * @extends {BaseAPI}
 */
export class DistributedQueriesApi extends BaseAPI implements DistributedQueriesApiInterface {
    /**
     * Create single distributed query instance
     * @param {DistributedQueryParams} distributedQueryParams distributed query params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueriesApi
     */
    public distributedQueryCreate(distributedQueryParams: DistributedQueryParams, options?: AxiosRequestConfig) {
        return DistributedQueriesApiFp(this.configuration).distributedQueryCreate(distributedQueryParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete distributed query by its primary key
     * @param {number} id query primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueriesApi
     */
    public distributedQueryDelete(id: number, options?: AxiosRequestConfig) {
        return DistributedQueriesApiFp(this.configuration).distributedQueryDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single distributed query instance
     * @param {number} id -- query primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueriesApi
     */
    public distributedQueryGetById(id: number, options?: AxiosRequestConfig) {
        return DistributedQueriesApiFp(this.configuration).distributedQueryGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of registered distributed queries
     * @param {Array<string>} [q] query for search in distributed query fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]name&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:EMPLOYEE&#x60;)
     * @param {Array<DistributedQueryExpand>} [expand] define distributed query expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueriesApi
     */
    public distributedQueryList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DistributedQueryExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return DistributedQueriesApiFp(this.configuration).distributedQueryList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch distributed query instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {DistributedQueryFields} distributedQueryFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueriesApi
     */
    public distributedQueryPatch(filter: Array<string>, distributedQueryFields: DistributedQueryFields, options?: AxiosRequestConfig) {
        return DistributedQueriesApiFp(this.configuration).distributedQueryPatch(filter, distributedQueryFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update distributed query instance
     * @param {number} id -- distributed query primary key
     * @param {DistributedQueryParams} distributedQueryParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueriesApi
     */
    public distributedQueryUpdate(id: number, distributedQueryParams: DistributedQueryParams, options?: AxiosRequestConfig) {
        return DistributedQueriesApiFp(this.configuration).distributedQueryUpdate(id, distributedQueryParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DistributedQueryResultsApi - axios parameter creator
 * @export
 */
export const DistributedQueryResultsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single distributed query result instance
         * @param {DistributedQueryResultParams} distributedQueryResultParams distributed query result instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryResultCreate: async (distributedQueryResultParams: DistributedQueryResultParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'distributedQueryResultParams' is not null or undefined
            assertParamExists('distributedQueryResultCreate', 'distributedQueryResultParams', distributedQueryResultParams)
            const localVarPath = `/distributed-query-results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(distributedQueryResultParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete distributed query result by primary keys
         * @param {number} id distributed query result primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryResultDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('distributedQueryResultDelete', 'id', id)
            const localVarPath = `/distributed-query-results/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single distributed query result instance
         * @param {number} id distributed query result primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryResultGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('distributedQueryResultGetById', 'id', id)
            const localVarPath = `/distributed-query-results/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of distributed query results
         * @param {Array<string>} [q] query for search in user fields (e.g. message, raw_data)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;distributedQueryId:1&#x60;)
         * @param {Array<DistributedQueryResultExpand>} [expand] define distributed query result expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryResultList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DistributedQueryResultExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/distributed-query-results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch distributed query result instance
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;distributedQueryId:1&#x60;)
         * @param {DistributedQueryResultFields} distributedQueryResultFields instance properties to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryResultPatch: async (filter: Array<string>, distributedQueryResultFields: DistributedQueryResultFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('distributedQueryResultPatch', 'filter', filter)
            // verify required parameter 'distributedQueryResultFields' is not null or undefined
            assertParamExists('distributedQueryResultPatch', 'distributedQueryResultFields', distributedQueryResultFields)
            const localVarPath = `/distributed-query-results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(distributedQueryResultFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of distributed query execution status
         * @param {Array<string>} [q] query for search in user fields (e.g. raw_data, status, message)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;distributedQueryId:1&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryResultStatusList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/distributed-query-results/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update distributed query result instance
         * @param {number} id distributed query result primary key
         * @param {DistributedQueryResultParams} distributedQueryResultParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryResultUpdate: async (id: number, distributedQueryResultParams: DistributedQueryResultParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('distributedQueryResultUpdate', 'id', id)
            // verify required parameter 'distributedQueryResultParams' is not null or undefined
            assertParamExists('distributedQueryResultUpdate', 'distributedQueryResultParams', distributedQueryResultParams)
            const localVarPath = `/distributed-query-results/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(distributedQueryResultParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DistributedQueryResultsApi - functional programming interface
 * @export
 */
export const DistributedQueryResultsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DistributedQueryResultsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single distributed query result instance
         * @param {DistributedQueryResultParams} distributedQueryResultParams distributed query result instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async distributedQueryResultCreate(distributedQueryResultParams: DistributedQueryResultParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2012>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.distributedQueryResultCreate(distributedQueryResultParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete distributed query result by primary keys
         * @param {number} id distributed query result primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async distributedQueryResultDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.distributedQueryResultDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single distributed query result instance
         * @param {number} id distributed query result primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async distributedQueryResultGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DistributedQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.distributedQueryResultGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of distributed query results
         * @param {Array<string>} [q] query for search in user fields (e.g. message, raw_data)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;distributedQueryId:1&#x60;)
         * @param {Array<DistributedQueryResultExpand>} [expand] define distributed query result expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async distributedQueryResultList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DistributedQueryResultExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DistributedQueryResultPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.distributedQueryResultList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch distributed query result instance
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;distributedQueryId:1&#x60;)
         * @param {DistributedQueryResultFields} distributedQueryResultFields instance properties to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async distributedQueryResultPatch(filter: Array<string>, distributedQueryResultFields: DistributedQueryResultFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.distributedQueryResultPatch(filter, distributedQueryResultFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of distributed query execution status
         * @param {Array<string>} [q] query for search in user fields (e.g. raw_data, status, message)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;distributedQueryId:1&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async distributedQueryResultStatusList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DistributedQueryStatusPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.distributedQueryResultStatusList(q, sort, filter, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update distributed query result instance
         * @param {number} id distributed query result primary key
         * @param {DistributedQueryResultParams} distributedQueryResultParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async distributedQueryResultUpdate(id: number, distributedQueryResultParams: DistributedQueryResultParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.distributedQueryResultUpdate(id, distributedQueryResultParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DistributedQueryResultsApi - factory interface
 * @export
 */
export const DistributedQueryResultsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DistributedQueryResultsApiFp(configuration)
    return {
        /**
         * Create single distributed query result instance
         * @param {DistributedQueryResultParams} distributedQueryResultParams distributed query result instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryResultCreate(distributedQueryResultParams: DistributedQueryResultParams, options?: any): AxiosPromise<InlineResponse2012> {
            return localVarFp.distributedQueryResultCreate(distributedQueryResultParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete distributed query result by primary keys
         * @param {number} id distributed query result primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryResultDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.distributedQueryResultDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single distributed query result instance
         * @param {number} id distributed query result primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryResultGetById(id: number, options?: any): AxiosPromise<DistributedQueryResult> {
            return localVarFp.distributedQueryResultGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of distributed query results
         * @param {Array<string>} [q] query for search in user fields (e.g. message, raw_data)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;distributedQueryId:1&#x60;)
         * @param {Array<DistributedQueryResultExpand>} [expand] define distributed query result expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryResultList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DistributedQueryResultExpand>, page?: number, limit?: number, options?: any): AxiosPromise<DistributedQueryResultPage> {
            return localVarFp.distributedQueryResultList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch distributed query result instance
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;distributedQueryId:1&#x60;)
         * @param {DistributedQueryResultFields} distributedQueryResultFields instance properties to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryResultPatch(filter: Array<string>, distributedQueryResultFields: DistributedQueryResultFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.distributedQueryResultPatch(filter, distributedQueryResultFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of distributed query execution status
         * @param {Array<string>} [q] query for search in user fields (e.g. raw_data, status, message)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;distributedQueryId:1&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryResultStatusList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: any): AxiosPromise<DistributedQueryStatusPage> {
            return localVarFp.distributedQueryResultStatusList(q, sort, filter, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Update distributed query result instance
         * @param {number} id distributed query result primary key
         * @param {DistributedQueryResultParams} distributedQueryResultParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        distributedQueryResultUpdate(id: number, distributedQueryResultParams: DistributedQueryResultParams, options?: any): AxiosPromise<void> {
            return localVarFp.distributedQueryResultUpdate(id, distributedQueryResultParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DistributedQueryResultsApi - interface
 * @export
 * @interface DistributedQueryResultsApi
 */
export interface DistributedQueryResultsApiInterface {
    /**
     * Create single distributed query result instance
     * @param {DistributedQueryResultParams} distributedQueryResultParams distributed query result instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueryResultsApiInterface
     */
    distributedQueryResultCreate(distributedQueryResultParams: DistributedQueryResultParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse2012>;

    /**
     * Delete distributed query result by primary keys
     * @param {number} id distributed query result primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueryResultsApiInterface
     */
    distributedQueryResultDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return single distributed query result instance
     * @param {number} id distributed query result primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueryResultsApiInterface
     */
    distributedQueryResultGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<DistributedQueryResult>;

    /**
     * Return collection of distributed query results
     * @param {Array<string>} [q] query for search in user fields (e.g. message, raw_data)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;distributedQueryId:1&#x60;)
     * @param {Array<DistributedQueryResultExpand>} [expand] define distributed query result expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueryResultsApiInterface
     */
    distributedQueryResultList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DistributedQueryResultExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<DistributedQueryResultPage>;

    /**
     * Patch distributed query result instance
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;distributedQueryId:1&#x60;)
     * @param {DistributedQueryResultFields} distributedQueryResultFields instance properties to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueryResultsApiInterface
     */
    distributedQueryResultPatch(filter: Array<string>, distributedQueryResultFields: DistributedQueryResultFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Return collection of distributed query execution status
     * @param {Array<string>} [q] query for search in user fields (e.g. raw_data, status, message)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;distributedQueryId:1&#x60;)
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueryResultsApiInterface
     */
    distributedQueryResultStatusList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<DistributedQueryStatusPage>;

    /**
     * Update distributed query result instance
     * @param {number} id distributed query result primary key
     * @param {DistributedQueryResultParams} distributedQueryResultParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueryResultsApiInterface
     */
    distributedQueryResultUpdate(id: number, distributedQueryResultParams: DistributedQueryResultParams, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * DistributedQueryResultsApi - object-oriented interface
 * @export
 * @class DistributedQueryResultsApi
 * @extends {BaseAPI}
 */
export class DistributedQueryResultsApi extends BaseAPI implements DistributedQueryResultsApiInterface {
    /**
     * Create single distributed query result instance
     * @param {DistributedQueryResultParams} distributedQueryResultParams distributed query result instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueryResultsApi
     */
    public distributedQueryResultCreate(distributedQueryResultParams: DistributedQueryResultParams, options?: AxiosRequestConfig) {
        return DistributedQueryResultsApiFp(this.configuration).distributedQueryResultCreate(distributedQueryResultParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete distributed query result by primary keys
     * @param {number} id distributed query result primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueryResultsApi
     */
    public distributedQueryResultDelete(id: number, options?: AxiosRequestConfig) {
        return DistributedQueryResultsApiFp(this.configuration).distributedQueryResultDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single distributed query result instance
     * @param {number} id distributed query result primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueryResultsApi
     */
    public distributedQueryResultGetById(id: number, options?: AxiosRequestConfig) {
        return DistributedQueryResultsApiFp(this.configuration).distributedQueryResultGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of distributed query results
     * @param {Array<string>} [q] query for search in user fields (e.g. message, raw_data)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;distributedQueryId:1&#x60;)
     * @param {Array<DistributedQueryResultExpand>} [expand] define distributed query result expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueryResultsApi
     */
    public distributedQueryResultList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DistributedQueryResultExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return DistributedQueryResultsApiFp(this.configuration).distributedQueryResultList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch distributed query result instance
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;distributedQueryId:1&#x60;)
     * @param {DistributedQueryResultFields} distributedQueryResultFields instance properties to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueryResultsApi
     */
    public distributedQueryResultPatch(filter: Array<string>, distributedQueryResultFields: DistributedQueryResultFields, options?: AxiosRequestConfig) {
        return DistributedQueryResultsApiFp(this.configuration).distributedQueryResultPatch(filter, distributedQueryResultFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of distributed query execution status
     * @param {Array<string>} [q] query for search in user fields (e.g. raw_data, status, message)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;distributedQueryId:1&#x60;)
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueryResultsApi
     */
    public distributedQueryResultStatusList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return DistributedQueryResultsApiFp(this.configuration).distributedQueryResultStatusList(q, sort, filter, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update distributed query result instance
     * @param {number} id distributed query result primary key
     * @param {DistributedQueryResultParams} distributedQueryResultParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributedQueryResultsApi
     */
    public distributedQueryResultUpdate(id: number, distributedQueryResultParams: DistributedQueryResultParams, options?: AxiosRequestConfig) {
        return DistributedQueryResultsApiFp(this.configuration).distributedQueryResultUpdate(id, distributedQueryResultParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single task instance
         * @param {DocumentsParams} documentsParams task params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsCreate: async (documentsParams: DocumentsParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentsParams' is not null or undefined
            assertParamExists('documentsCreate', 'documentsParams', documentsParams)
            const localVarPath = `/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentsParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete task by its primary key
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('documentsDelete', 'id', id)
            const localVarPath = `/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single task result instance
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('documentsGetById', 'id', id)
            const localVarPath = `/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of tasks
         * @param {Array<string>} [q] query for search in task fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<string>} [expand] define task expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<string>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch task instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {DocumentsFields} documentsFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsPatch: async (filter: Array<string>, documentsFields: DocumentsFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('documentsPatch', 'filter', filter)
            // verify required parameter 'documentsFields' is not null or undefined
            assertParamExists('documentsPatch', 'documentsFields', documentsFields)
            const localVarPath = `/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentsFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update task instance
         * @param {number} id -- task primary key
         * @param {DocumentsParams} documentsParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsUpdate: async (id: number, documentsParams: DocumentsParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('documentsUpdate', 'id', id)
            // verify required parameter 'documentsParams' is not null or undefined
            assertParamExists('documentsUpdate', 'documentsParams', documentsParams)
            const localVarPath = `/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentsParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single task instance
         * @param {DocumentsParams} documentsParams task params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsCreate(documentsParams: DocumentsParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2014>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsCreate(documentsParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete task by its primary key
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single task result instance
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Documents>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of tasks
         * @param {Array<string>} [q] query for search in task fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<string>} [expand] define task expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch task instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {DocumentsFields} documentsFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsPatch(filter: Array<string>, documentsFields: DocumentsFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsPatch(filter, documentsFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update task instance
         * @param {number} id -- task primary key
         * @param {DocumentsParams} documentsParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsUpdate(id: number, documentsParams: DocumentsParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsUpdate(id, documentsParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentApiFp(configuration)
    return {
        /**
         * Create single task instance
         * @param {DocumentsParams} documentsParams task params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsCreate(documentsParams: DocumentsParams, options?: any): AxiosPromise<InlineResponse2014> {
            return localVarFp.documentsCreate(documentsParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete task by its primary key
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.documentsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single task result instance
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsGetById(id: number, options?: any): AxiosPromise<Documents> {
            return localVarFp.documentsGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of tasks
         * @param {Array<string>} [q] query for search in task fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<string>} [expand] define task expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<string>, page?: number, limit?: number, options?: any): AxiosPromise<DocumentsPage> {
            return localVarFp.documentsList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch task instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {DocumentsFields} documentsFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsPatch(filter: Array<string>, documentsFields: DocumentsFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.documentsPatch(filter, documentsFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Update task instance
         * @param {number} id -- task primary key
         * @param {DocumentsParams} documentsParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsUpdate(id: number, documentsParams: DocumentsParams, options?: any): AxiosPromise<void> {
            return localVarFp.documentsUpdate(id, documentsParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentApi - interface
 * @export
 * @interface DocumentApi
 */
export interface DocumentApiInterface {
    /**
     * Create single task instance
     * @param {DocumentsParams} documentsParams task params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApiInterface
     */
    documentsCreate(documentsParams: DocumentsParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse2014>;

    /**
     * Delete task by its primary key
     * @param {number} id task primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApiInterface
     */
    documentsDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return single task result instance
     * @param {number} id task primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApiInterface
     */
    documentsGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<Documents>;

    /**
     * Return collection of tasks
     * @param {Array<string>} [q] query for search in task fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<string>} [expand] define task expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApiInterface
     */
    documentsList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<DocumentsPage>;

    /**
     * Patch task instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {DocumentsFields} documentsFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApiInterface
     */
    documentsPatch(filter: Array<string>, documentsFields: DocumentsFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Update task instance
     * @param {number} id -- task primary key
     * @param {DocumentsParams} documentsParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApiInterface
     */
    documentsUpdate(id: number, documentsParams: DocumentsParams, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI implements DocumentApiInterface {
    /**
     * Create single task instance
     * @param {DocumentsParams} documentsParams task params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentsCreate(documentsParams: DocumentsParams, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentsCreate(documentsParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete task by its primary key
     * @param {number} id task primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentsDelete(id: number, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentsDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single task result instance
     * @param {number} id task primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentsGetById(id: number, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentsGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of tasks
     * @param {Array<string>} [q] query for search in task fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<string>} [expand] define task expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentsList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentsList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch task instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {DocumentsFields} documentsFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentsPatch(filter: Array<string>, documentsFields: DocumentsFields, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentsPatch(filter, documentsFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update task instance
     * @param {number} id -- task primary key
     * @param {DocumentsParams} documentsParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public documentsUpdate(id: number, documentsParams: DocumentsParams, options?: AxiosRequestConfig) {
        return DocumentApiFp(this.configuration).documentsUpdate(id, documentsParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentCommentApi - axios parameter creator
 * @export
 */
export const DocumentCommentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single project comment instance
         * @param {DocumentCommentsParams} documentCommentsParams project comment params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCommentCreate: async (documentCommentsParams: DocumentCommentsParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentCommentsParams' is not null or undefined
            assertParamExists('documentCommentCreate', 'documentCommentsParams', documentCommentsParams)
            const localVarPath = `/document-comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentCommentsParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete document by its primary key
         * @param {number} id project primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCommentDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('documentCommentDelete', 'id', id)
            const localVarPath = `/document-comments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single document comment result instance
         * @param {number} id project primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCommentGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('documentCommentGetById', 'id', id)
            const localVarPath = `/document-comments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of document comments
         * @param {Array<string>} [q] query for search in project comment fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<DocumentCommentExpand>} [expand] define project expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCommentList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DocumentCommentExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/document-comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch project comment instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {DocumentCommentsFields} documentCommentsFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCommentPatch: async (filter: Array<string>, documentCommentsFields: DocumentCommentsFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('documentCommentPatch', 'filter', filter)
            // verify required parameter 'documentCommentsFields' is not null or undefined
            assertParamExists('documentCommentPatch', 'documentCommentsFields', documentCommentsFields)
            const localVarPath = `/document-comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentCommentsFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update document comment instance
         * @param {number} id -- document comment primary key
         * @param {DocumentCommentsParams} documentCommentsParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCommentUpdate: async (id: number, documentCommentsParams: DocumentCommentsParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('documentCommentUpdate', 'id', id)
            // verify required parameter 'documentCommentsParams' is not null or undefined
            assertParamExists('documentCommentUpdate', 'documentCommentsParams', documentCommentsParams)
            const localVarPath = `/document-comments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentCommentsParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentCommentApi - functional programming interface
 * @export
 */
export const DocumentCommentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentCommentApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single project comment instance
         * @param {DocumentCommentsParams} documentCommentsParams project comment params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentCommentCreate(documentCommentsParams: DocumentCommentsParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2013>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentCommentCreate(documentCommentsParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete document by its primary key
         * @param {number} id project primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentCommentDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentCommentDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single document comment result instance
         * @param {number} id project primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentCommentGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentCommentGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of document comments
         * @param {Array<string>} [q] query for search in project comment fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<DocumentCommentExpand>} [expand] define project expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentCommentList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DocumentCommentExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentCommentsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentCommentList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch project comment instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {DocumentCommentsFields} documentCommentsFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentCommentPatch(filter: Array<string>, documentCommentsFields: DocumentCommentsFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentCommentPatch(filter, documentCommentsFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update document comment instance
         * @param {number} id -- document comment primary key
         * @param {DocumentCommentsParams} documentCommentsParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentCommentUpdate(id: number, documentCommentsParams: DocumentCommentsParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentCommentUpdate(id, documentCommentsParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentCommentApi - factory interface
 * @export
 */
export const DocumentCommentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentCommentApiFp(configuration)
    return {
        /**
         * Create single project comment instance
         * @param {DocumentCommentsParams} documentCommentsParams project comment params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCommentCreate(documentCommentsParams: DocumentCommentsParams, options?: any): AxiosPromise<InlineResponse2013> {
            return localVarFp.documentCommentCreate(documentCommentsParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete document by its primary key
         * @param {number} id project primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCommentDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.documentCommentDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single document comment result instance
         * @param {number} id project primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCommentGetById(id: number, options?: any): AxiosPromise<DocumentComment> {
            return localVarFp.documentCommentGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of document comments
         * @param {Array<string>} [q] query for search in project comment fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<DocumentCommentExpand>} [expand] define project expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCommentList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DocumentCommentExpand>, page?: number, limit?: number, options?: any): AxiosPromise<DocumentCommentsPage> {
            return localVarFp.documentCommentList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch project comment instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {DocumentCommentsFields} documentCommentsFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCommentPatch(filter: Array<string>, documentCommentsFields: DocumentCommentsFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.documentCommentPatch(filter, documentCommentsFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Update document comment instance
         * @param {number} id -- document comment primary key
         * @param {DocumentCommentsParams} documentCommentsParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCommentUpdate(id: number, documentCommentsParams: DocumentCommentsParams, options?: any): AxiosPromise<void> {
            return localVarFp.documentCommentUpdate(id, documentCommentsParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentCommentApi - interface
 * @export
 * @interface DocumentCommentApi
 */
export interface DocumentCommentApiInterface {
    /**
     * Create single project comment instance
     * @param {DocumentCommentsParams} documentCommentsParams project comment params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentCommentApiInterface
     */
    documentCommentCreate(documentCommentsParams: DocumentCommentsParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse2013>;

    /**
     * Delete document by its primary key
     * @param {number} id project primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentCommentApiInterface
     */
    documentCommentDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return single document comment result instance
     * @param {number} id project primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentCommentApiInterface
     */
    documentCommentGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<DocumentComment>;

    /**
     * Return collection of document comments
     * @param {Array<string>} [q] query for search in project comment fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<DocumentCommentExpand>} [expand] define project expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentCommentApiInterface
     */
    documentCommentList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DocumentCommentExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<DocumentCommentsPage>;

    /**
     * Patch project comment instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {DocumentCommentsFields} documentCommentsFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentCommentApiInterface
     */
    documentCommentPatch(filter: Array<string>, documentCommentsFields: DocumentCommentsFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Update document comment instance
     * @param {number} id -- document comment primary key
     * @param {DocumentCommentsParams} documentCommentsParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentCommentApiInterface
     */
    documentCommentUpdate(id: number, documentCommentsParams: DocumentCommentsParams, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * DocumentCommentApi - object-oriented interface
 * @export
 * @class DocumentCommentApi
 * @extends {BaseAPI}
 */
export class DocumentCommentApi extends BaseAPI implements DocumentCommentApiInterface {
    /**
     * Create single project comment instance
     * @param {DocumentCommentsParams} documentCommentsParams project comment params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentCommentApi
     */
    public documentCommentCreate(documentCommentsParams: DocumentCommentsParams, options?: AxiosRequestConfig) {
        return DocumentCommentApiFp(this.configuration).documentCommentCreate(documentCommentsParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete document by its primary key
     * @param {number} id project primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentCommentApi
     */
    public documentCommentDelete(id: number, options?: AxiosRequestConfig) {
        return DocumentCommentApiFp(this.configuration).documentCommentDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single document comment result instance
     * @param {number} id project primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentCommentApi
     */
    public documentCommentGetById(id: number, options?: AxiosRequestConfig) {
        return DocumentCommentApiFp(this.configuration).documentCommentGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of document comments
     * @param {Array<string>} [q] query for search in project comment fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<DocumentCommentExpand>} [expand] define project expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentCommentApi
     */
    public documentCommentList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DocumentCommentExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return DocumentCommentApiFp(this.configuration).documentCommentList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch project comment instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {DocumentCommentsFields} documentCommentsFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentCommentApi
     */
    public documentCommentPatch(filter: Array<string>, documentCommentsFields: DocumentCommentsFields, options?: AxiosRequestConfig) {
        return DocumentCommentApiFp(this.configuration).documentCommentPatch(filter, documentCommentsFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update document comment instance
     * @param {number} id -- document comment primary key
     * @param {DocumentCommentsParams} documentCommentsParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentCommentApi
     */
    public documentCommentUpdate(id: number, documentCommentsParams: DocumentCommentsParams, options?: AxiosRequestConfig) {
        return DocumentCommentApiFp(this.configuration).documentCommentUpdate(id, documentCommentsParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return osquery.flags
         * @param {string} os 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        config: async (os: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'os' is not null or undefined
            assertParamExists('config', 'os', os)
            const localVarPath = `/files/config/{os}`
                .replace(`{${"os"}}`, encodeURIComponent(String(os)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return SQL editor scheme
         * @param {string} plugin -- plugin name (e.g. \&#39;osquery\&#39;, \&#39;steampipe\&#39;)
         * @param {Array<string>} [keys] -- plugin scheme specified part (e.g. [\&#39;linux\&#39;,\&#39;darwin\&#39;,\&#39;windows\&#39;] for osquery or [\&#39;aws\&#39;, \&#39;gcp\&#39;] for steampipe)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editorScheme: async (plugin: string, keys?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plugin' is not null or undefined
            assertParamExists('editorScheme', 'plugin', plugin)
            const localVarPath = `/files/editor-scheme/{plugin}`
                .replace(`{${"plugin"}}`, encodeURIComponent(String(plugin)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (keys) {
                localVarQueryParameter['keys'] = keys;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete organization image by its name
         * @param {BucketName} bucket --(e.g \&#39;ORGANIZATIONS\&#39;, \&#39;TEMPLATES\&#39;, \&#39;USERS\&#39;)
         * @param {ImageDelete} imageDelete 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageDeleteByFileName: async (bucket: BucketName, imageDelete: ImageDelete, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bucket' is not null or undefined
            assertParamExists('imageDeleteByFileName', 'bucket', bucket)
            // verify required parameter 'imageDelete' is not null or undefined
            assertParamExists('imageDeleteByFileName', 'imageDelete', imageDelete)
            const localVarPath = `/files/delete-image/{bucket}`
                .replace(`{${"bucket"}}`, encodeURIComponent(String(bucket)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imageDelete, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single organization image
         * @param {string} fileName -- image fileName
         * @param {BucketName} bucket --(e.g \&#39;ORGANIZATIONS\&#39;, \&#39;TEMPLATES\&#39;, \&#39;USERS\&#39;)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageGetByFileName: async (fileName: string, bucket: BucketName, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('imageGetByFileName', 'fileName', fileName)
            // verify required parameter 'bucket' is not null or undefined
            assertParamExists('imageGetByFileName', 'bucket', bucket)
            const localVarPath = `/files/{fileName}/{bucket}`
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)))
                .replace(`{${"bucket"}}`, encodeURIComponent(String(bucket)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return saved image
         * @param {BucketName} bucket -- (e.g \&#39;ORGANIZATIONS\&#39;, \&#39;TEMPLATES\&#39;, \&#39;USERS\&#39;)
         * @param {any} image --uploadFile (e.g FormData)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage: async (bucket: BucketName, image: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bucket' is not null or undefined
            assertParamExists('uploadImage', 'bucket', bucket)
            // verify required parameter 'image' is not null or undefined
            assertParamExists('uploadImage', 'image', image)
            const localVarPath = `/files/upload-image/{bucket}`
                .replace(`{${"bucket"}}`, encodeURIComponent(String(bucket)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * Return osquery.flags
         * @param {string} os 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async config(os: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.config(os, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return SQL editor scheme
         * @param {string} plugin -- plugin name (e.g. \&#39;osquery\&#39;, \&#39;steampipe\&#39;)
         * @param {Array<string>} [keys] -- plugin scheme specified part (e.g. [\&#39;linux\&#39;,\&#39;darwin\&#39;,\&#39;windows\&#39;] for osquery or [\&#39;aws\&#39;, \&#39;gcp\&#39;] for steampipe)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editorScheme(plugin: string, keys?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editorScheme(plugin, keys, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete organization image by its name
         * @param {BucketName} bucket --(e.g \&#39;ORGANIZATIONS\&#39;, \&#39;TEMPLATES\&#39;, \&#39;USERS\&#39;)
         * @param {ImageDelete} imageDelete 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageDeleteByFileName(bucket: BucketName, imageDelete: ImageDelete, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageDeleteByFileName(bucket, imageDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single organization image
         * @param {string} fileName -- image fileName
         * @param {BucketName} bucket --(e.g \&#39;ORGANIZATIONS\&#39;, \&#39;TEMPLATES\&#39;, \&#39;USERS\&#39;)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageGetByFileName(fileName: string, bucket: BucketName, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageGetByFileName(fileName, bucket, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return saved image
         * @param {BucketName} bucket -- (e.g \&#39;ORGANIZATIONS\&#39;, \&#39;TEMPLATES\&#39;, \&#39;USERS\&#39;)
         * @param {any} image --uploadFile (e.g FormData)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadImage(bucket: BucketName, image: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadImage(bucket, image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * Return osquery.flags
         * @param {string} os 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        config(os: string, options?: any): AxiosPromise<void> {
            return localVarFp.config(os, options).then((request) => request(axios, basePath));
        },
        /**
         * Return SQL editor scheme
         * @param {string} plugin -- plugin name (e.g. \&#39;osquery\&#39;, \&#39;steampipe\&#39;)
         * @param {Array<string>} [keys] -- plugin scheme specified part (e.g. [\&#39;linux\&#39;,\&#39;darwin\&#39;,\&#39;windows\&#39;] for osquery or [\&#39;aws\&#39;, \&#39;gcp\&#39;] for steampipe)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editorScheme(plugin: string, keys?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.editorScheme(plugin, keys, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete organization image by its name
         * @param {BucketName} bucket --(e.g \&#39;ORGANIZATIONS\&#39;, \&#39;TEMPLATES\&#39;, \&#39;USERS\&#39;)
         * @param {ImageDelete} imageDelete 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageDeleteByFileName(bucket: BucketName, imageDelete: ImageDelete, options?: any): AxiosPromise<void> {
            return localVarFp.imageDeleteByFileName(bucket, imageDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single organization image
         * @param {string} fileName -- image fileName
         * @param {BucketName} bucket --(e.g \&#39;ORGANIZATIONS\&#39;, \&#39;TEMPLATES\&#39;, \&#39;USERS\&#39;)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageGetByFileName(fileName: string, bucket: BucketName, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.imageGetByFileName(fileName, bucket, options).then((request) => request(axios, basePath));
        },
        /**
         * Return saved image
         * @param {BucketName} bucket -- (e.g \&#39;ORGANIZATIONS\&#39;, \&#39;TEMPLATES\&#39;, \&#39;USERS\&#39;)
         * @param {any} image --uploadFile (e.g FormData)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage(bucket: BucketName, image: any, options?: any): AxiosPromise<void> {
            return localVarFp.uploadImage(bucket, image, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - interface
 * @export
 * @interface FileApi
 */
export interface FileApiInterface {
    /**
     * Return osquery.flags
     * @param {string} os 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    config(os: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return SQL editor scheme
     * @param {string} plugin -- plugin name (e.g. \&#39;osquery\&#39;, \&#39;steampipe\&#39;)
     * @param {Array<string>} [keys] -- plugin scheme specified part (e.g. [\&#39;linux\&#39;,\&#39;darwin\&#39;,\&#39;windows\&#39;] for osquery or [\&#39;aws\&#39;, \&#39;gcp\&#39;] for steampipe)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    editorScheme(plugin: string, keys?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Delete organization image by its name
     * @param {BucketName} bucket --(e.g \&#39;ORGANIZATIONS\&#39;, \&#39;TEMPLATES\&#39;, \&#39;USERS\&#39;)
     * @param {ImageDelete} imageDelete 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    imageDeleteByFileName(bucket: BucketName, imageDelete: ImageDelete, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return single organization image
     * @param {string} fileName -- image fileName
     * @param {BucketName} bucket --(e.g \&#39;ORGANIZATIONS\&#39;, \&#39;TEMPLATES\&#39;, \&#39;USERS\&#39;)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    imageGetByFileName(fileName: string, bucket: BucketName, options?: AxiosRequestConfig): AxiosPromise<InlineResponse2001>;

    /**
     * Return saved image
     * @param {BucketName} bucket -- (e.g \&#39;ORGANIZATIONS\&#39;, \&#39;TEMPLATES\&#39;, \&#39;USERS\&#39;)
     * @param {any} image --uploadFile (e.g FormData)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    uploadImage(bucket: BucketName, image: any, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI implements FileApiInterface {
    /**
     * Return osquery.flags
     * @param {string} os 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public config(os: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).config(os, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return SQL editor scheme
     * @param {string} plugin -- plugin name (e.g. \&#39;osquery\&#39;, \&#39;steampipe\&#39;)
     * @param {Array<string>} [keys] -- plugin scheme specified part (e.g. [\&#39;linux\&#39;,\&#39;darwin\&#39;,\&#39;windows\&#39;] for osquery or [\&#39;aws\&#39;, \&#39;gcp\&#39;] for steampipe)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public editorScheme(plugin: string, keys?: Array<string>, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).editorScheme(plugin, keys, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete organization image by its name
     * @param {BucketName} bucket --(e.g \&#39;ORGANIZATIONS\&#39;, \&#39;TEMPLATES\&#39;, \&#39;USERS\&#39;)
     * @param {ImageDelete} imageDelete 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public imageDeleteByFileName(bucket: BucketName, imageDelete: ImageDelete, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).imageDeleteByFileName(bucket, imageDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single organization image
     * @param {string} fileName -- image fileName
     * @param {BucketName} bucket --(e.g \&#39;ORGANIZATIONS\&#39;, \&#39;TEMPLATES\&#39;, \&#39;USERS\&#39;)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public imageGetByFileName(fileName: string, bucket: BucketName, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).imageGetByFileName(fileName, bucket, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return saved image
     * @param {BucketName} bucket -- (e.g \&#39;ORGANIZATIONS\&#39;, \&#39;TEMPLATES\&#39;, \&#39;USERS\&#39;)
     * @param {any} image --uploadFile (e.g FormData)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public uploadImage(bucket: BucketName, image: any, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).uploadImage(bucket, image, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupsApi - axios parameter creator
 * @export
 */
export const GroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single groups instance
         * @param {GroupsParams} groupsParams groups params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsCreate: async (groupsParams: GroupsParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupsParams' is not null or undefined
            assertParamExists('groupsCreate', 'groupsParams', groupsParams)
            const localVarPath = `/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupsParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete groups  by its primary key
         * @param {number} id groups primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('groupsDelete', 'id', id)
            const localVarPath = `/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single groups result instance
         * @param {number} id groups primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('groupsGetById', 'id', id)
            const localVarPath = `/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of groups
         * @param {Array<string>} [q] query for search in groups fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<GroupsExpand>} [expand] define groups expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<GroupsExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch groups instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {GroupsFields} groupsFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsPatch: async (filter: Array<string>, groupsFields: GroupsFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('groupsPatch', 'filter', filter)
            // verify required parameter 'groupsFields' is not null or undefined
            assertParamExists('groupsPatch', 'groupsFields', groupsFields)
            const localVarPath = `/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupsFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update groups instance
         * @param {number} id -- groups primary key
         * @param {GroupsParams} groupsParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsUpdate: async (id: number, groupsParams: GroupsParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('groupsUpdate', 'id', id)
            // verify required parameter 'groupsParams' is not null or undefined
            assertParamExists('groupsUpdate', 'groupsParams', groupsParams)
            const localVarPath = `/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupsParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupsApi - functional programming interface
 * @export
 */
export const GroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single groups instance
         * @param {GroupsParams} groupsParams groups params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupsCreate(groupsParams: GroupsParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2015>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupsCreate(groupsParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete groups  by its primary key
         * @param {number} id groups primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupsDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single groups result instance
         * @param {number} id groups primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupsGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Groups>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupsGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of groups
         * @param {Array<string>} [q] query for search in groups fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<GroupsExpand>} [expand] define groups expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupsList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<GroupsExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupsList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch groups instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {GroupsFields} groupsFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupsPatch(filter: Array<string>, groupsFields: GroupsFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupsPatch(filter, groupsFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update groups instance
         * @param {number} id -- groups primary key
         * @param {GroupsParams} groupsParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupsUpdate(id: number, groupsParams: GroupsParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupsUpdate(id, groupsParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupsApi - factory interface
 * @export
 */
export const GroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupsApiFp(configuration)
    return {
        /**
         * Create single groups instance
         * @param {GroupsParams} groupsParams groups params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsCreate(groupsParams: GroupsParams, options?: any): AxiosPromise<InlineResponse2015> {
            return localVarFp.groupsCreate(groupsParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete groups  by its primary key
         * @param {number} id groups primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.groupsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single groups result instance
         * @param {number} id groups primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsGetById(id: number, options?: any): AxiosPromise<Groups> {
            return localVarFp.groupsGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of groups
         * @param {Array<string>} [q] query for search in groups fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<GroupsExpand>} [expand] define groups expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<GroupsExpand>, page?: number, limit?: number, options?: any): AxiosPromise<GroupsPage> {
            return localVarFp.groupsList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch groups instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {GroupsFields} groupsFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsPatch(filter: Array<string>, groupsFields: GroupsFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.groupsPatch(filter, groupsFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Update groups instance
         * @param {number} id -- groups primary key
         * @param {GroupsParams} groupsParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupsUpdate(id: number, groupsParams: GroupsParams, options?: any): AxiosPromise<void> {
            return localVarFp.groupsUpdate(id, groupsParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupsApi - interface
 * @export
 * @interface GroupsApi
 */
export interface GroupsApiInterface {
    /**
     * Create single groups instance
     * @param {GroupsParams} groupsParams groups params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApiInterface
     */
    groupsCreate(groupsParams: GroupsParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse2015>;

    /**
     * Delete groups  by its primary key
     * @param {number} id groups primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApiInterface
     */
    groupsDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return single groups result instance
     * @param {number} id groups primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApiInterface
     */
    groupsGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<Groups>;

    /**
     * Return collection of groups
     * @param {Array<string>} [q] query for search in groups fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<GroupsExpand>} [expand] define groups expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApiInterface
     */
    groupsList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<GroupsExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<GroupsPage>;

    /**
     * Patch groups instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {GroupsFields} groupsFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApiInterface
     */
    groupsPatch(filter: Array<string>, groupsFields: GroupsFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Update groups instance
     * @param {number} id -- groups primary key
     * @param {GroupsParams} groupsParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApiInterface
     */
    groupsUpdate(id: number, groupsParams: GroupsParams, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * GroupsApi - object-oriented interface
 * @export
 * @class GroupsApi
 * @extends {BaseAPI}
 */
export class GroupsApi extends BaseAPI implements GroupsApiInterface {
    /**
     * Create single groups instance
     * @param {GroupsParams} groupsParams groups params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public groupsCreate(groupsParams: GroupsParams, options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).groupsCreate(groupsParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete groups  by its primary key
     * @param {number} id groups primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public groupsDelete(id: number, options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).groupsDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single groups result instance
     * @param {number} id groups primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public groupsGetById(id: number, options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).groupsGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of groups
     * @param {Array<string>} [q] query for search in groups fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<GroupsExpand>} [expand] define groups expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public groupsList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<GroupsExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).groupsList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch groups instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {GroupsFields} groupsFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public groupsPatch(filter: Array<string>, groupsFields: GroupsFields, options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).groupsPatch(filter, groupsFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update groups instance
     * @param {number} id -- groups primary key
     * @param {GroupsParams} groupsParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public groupsUpdate(id: number, groupsParams: GroupsParams, options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).groupsUpdate(id, groupsParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImportApi - axios parameter creator
 * @export
 */
export const ImportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single import instance
         * @param {ImportParams} importParams import params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCreate: async (importParams: ImportParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'importParams' is not null or undefined
            assertParamExists('importCreate', 'importParams', importParams)
            const localVarPath = `/imports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete import by its primary keys
         * @param {number} id import primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('importDelete', 'id', id)
            const localVarPath = `/imports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single import instance
         * @param {number} id import primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('importGetById', 'id', id)
            const localVarPath = `/imports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of registered imports
         * @param {Array<string>} [q] query for search in user fields (e.g. rawData)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;userId:1&#x60;)
         * @param {Array<ImportExpand>} [expand] define distributed import expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ImportExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/imports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch import instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {ImportFields} importFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importPatch: async (filter: Array<string>, importFields: ImportFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('importPatch', 'filter', filter)
            // verify required parameter 'importFields' is not null or undefined
            assertParamExists('importPatch', 'importFields', importFields)
            const localVarPath = `/imports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update import instance
         * @param {number} id import primary key to update
         * @param {ImportParams} importParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importUpdate: async (id: number, importParams: ImportParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('importUpdate', 'id', id)
            // verify required parameter 'importParams' is not null or undefined
            assertParamExists('importUpdate', 'importParams', importParams)
            const localVarPath = `/imports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImportApi - functional programming interface
 * @export
 */
export const ImportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImportApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single import instance
         * @param {ImportParams} importParams import params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importCreate(importParams: ImportParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2016>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importCreate(importParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete import by its primary keys
         * @param {number} id import primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single import instance
         * @param {number} id import primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Import>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of registered imports
         * @param {Array<string>} [q] query for search in user fields (e.g. rawData)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;userId:1&#x60;)
         * @param {Array<ImportExpand>} [expand] define distributed import expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ImportExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch import instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {ImportFields} importFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importPatch(filter: Array<string>, importFields: ImportFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importPatch(filter, importFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update import instance
         * @param {number} id import primary key to update
         * @param {ImportParams} importParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importUpdate(id: number, importParams: ImportParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importUpdate(id, importParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImportApi - factory interface
 * @export
 */
export const ImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImportApiFp(configuration)
    return {
        /**
         * Create single import instance
         * @param {ImportParams} importParams import params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importCreate(importParams: ImportParams, options?: any): AxiosPromise<InlineResponse2016> {
            return localVarFp.importCreate(importParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete import by its primary keys
         * @param {number} id import primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.importDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single import instance
         * @param {number} id import primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importGetById(id: number, options?: any): AxiosPromise<Import> {
            return localVarFp.importGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of registered imports
         * @param {Array<string>} [q] query for search in user fields (e.g. rawData)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;userId:1&#x60;)
         * @param {Array<ImportExpand>} [expand] define distributed import expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ImportExpand>, page?: number, limit?: number, options?: any): AxiosPromise<ImportPage> {
            return localVarFp.importList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch import instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {ImportFields} importFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importPatch(filter: Array<string>, importFields: ImportFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.importPatch(filter, importFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Update import instance
         * @param {number} id import primary key to update
         * @param {ImportParams} importParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importUpdate(id: number, importParams: ImportParams, options?: any): AxiosPromise<void> {
            return localVarFp.importUpdate(id, importParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImportApi - interface
 * @export
 * @interface ImportApi
 */
export interface ImportApiInterface {
    /**
     * Create single import instance
     * @param {ImportParams} importParams import params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    importCreate(importParams: ImportParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse2016>;

    /**
     * Delete import by its primary keys
     * @param {number} id import primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    importDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return single import instance
     * @param {number} id import primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    importGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<Import>;

    /**
     * Return collection of registered imports
     * @param {Array<string>} [q] query for search in user fields (e.g. rawData)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;userId:1&#x60;)
     * @param {Array<ImportExpand>} [expand] define distributed import expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    importList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ImportExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<ImportPage>;

    /**
     * Patch import instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {ImportFields} importFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    importPatch(filter: Array<string>, importFields: ImportFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Update import instance
     * @param {number} id import primary key to update
     * @param {ImportParams} importParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    importUpdate(id: number, importParams: ImportParams, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ImportApi - object-oriented interface
 * @export
 * @class ImportApi
 * @extends {BaseAPI}
 */
export class ImportApi extends BaseAPI implements ImportApiInterface {
    /**
     * Create single import instance
     * @param {ImportParams} importParams import params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public importCreate(importParams: ImportParams, options?: AxiosRequestConfig) {
        return ImportApiFp(this.configuration).importCreate(importParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete import by its primary keys
     * @param {number} id import primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public importDelete(id: number, options?: AxiosRequestConfig) {
        return ImportApiFp(this.configuration).importDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single import instance
     * @param {number} id import primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public importGetById(id: number, options?: AxiosRequestConfig) {
        return ImportApiFp(this.configuration).importGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of registered imports
     * @param {Array<string>} [q] query for search in user fields (e.g. rawData)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;userId:1&#x60;)
     * @param {Array<ImportExpand>} [expand] define distributed import expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public importList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ImportExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return ImportApiFp(this.configuration).importList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch import instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {ImportFields} importFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public importPatch(filter: Array<string>, importFields: ImportFields, options?: AxiosRequestConfig) {
        return ImportApiFp(this.configuration).importPatch(filter, importFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update import instance
     * @param {number} id import primary key to update
     * @param {ImportParams} importParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public importUpdate(id: number, importParams: ImportParams, options?: AxiosRequestConfig) {
        return ImportApiFp(this.configuration).importUpdate(id, importParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single notification instance
         * @param {NotificationParams} notificationParams notification params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationCreate: async (notificationParams: NotificationParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationParams' is not null or undefined
            assertParamExists('notificationCreate', 'notificationParams', notificationParams)
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete notification by its primary key
         * @param {number} id notification primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('notificationDelete', 'id', id)
            const localVarPath = `/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single notification result instance
         * @param {number} id notification primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('notificationGetById', 'id', id)
            const localVarPath = `/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of notification
         * @param {Array<string>} [q] query for search in notification fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<NotificationExpand>} [expand] define notification expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<NotificationExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch notification instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {NotificationFields} notificationFields 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationPatch: async (filter: Array<string>, notificationFields: NotificationFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('notificationPatch', 'filter', filter)
            // verify required parameter 'notificationFields' is not null or undefined
            assertParamExists('notificationPatch', 'notificationFields', notificationFields)
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update notification instance
         * @param {number} id -- notification primary key
         * @param {NotificationParams} notificationParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationUpdate: async (id: number, notificationParams: NotificationParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('notificationUpdate', 'id', id)
            // verify required parameter 'notificationParams' is not null or undefined
            assertParamExists('notificationUpdate', 'notificationParams', notificationParams)
            const localVarPath = `/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single notification instance
         * @param {NotificationParams} notificationParams notification params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationCreate(notificationParams: NotificationParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2017>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationCreate(notificationParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete notification by its primary key
         * @param {number} id notification primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single notification result instance
         * @param {number} id notification primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of notification
         * @param {Array<string>} [q] query for search in notification fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<NotificationExpand>} [expand] define notification expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<NotificationExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch notification instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {NotificationFields} notificationFields 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationPatch(filter: Array<string>, notificationFields: NotificationFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationPatch(filter, notificationFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update notification instance
         * @param {number} id -- notification primary key
         * @param {NotificationParams} notificationParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationUpdate(id: number, notificationParams: NotificationParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationUpdate(id, notificationParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * Create single notification instance
         * @param {NotificationParams} notificationParams notification params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationCreate(notificationParams: NotificationParams, options?: any): AxiosPromise<InlineResponse2017> {
            return localVarFp.notificationCreate(notificationParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete notification by its primary key
         * @param {number} id notification primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.notificationDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single notification result instance
         * @param {number} id notification primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationGetById(id: number, options?: any): AxiosPromise<Notification> {
            return localVarFp.notificationGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of notification
         * @param {Array<string>} [q] query for search in notification fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<NotificationExpand>} [expand] define notification expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<NotificationExpand>, page?: number, limit?: number, options?: any): AxiosPromise<NotificationPage> {
            return localVarFp.notificationList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch notification instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {NotificationFields} notificationFields 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationPatch(filter: Array<string>, notificationFields: NotificationFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.notificationPatch(filter, notificationFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Update notification instance
         * @param {number} id -- notification primary key
         * @param {NotificationParams} notificationParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationUpdate(id: number, notificationParams: NotificationParams, options?: any): AxiosPromise<void> {
            return localVarFp.notificationUpdate(id, notificationParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - interface
 * @export
 * @interface NotificationsApi
 */
export interface NotificationsApiInterface {
    /**
     * Create single notification instance
     * @param {NotificationParams} notificationParams notification params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApiInterface
     */
    notificationCreate(notificationParams: NotificationParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse2017>;

    /**
     * Delete notification by its primary key
     * @param {number} id notification primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApiInterface
     */
    notificationDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return single notification result instance
     * @param {number} id notification primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApiInterface
     */
    notificationGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<Notification>;

    /**
     * Return collection of notification
     * @param {Array<string>} [q] query for search in notification fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<NotificationExpand>} [expand] define notification expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApiInterface
     */
    notificationList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<NotificationExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<NotificationPage>;

    /**
     * Patch notification instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {NotificationFields} notificationFields 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApiInterface
     */
    notificationPatch(filter: Array<string>, notificationFields: NotificationFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Update notification instance
     * @param {number} id -- notification primary key
     * @param {NotificationParams} notificationParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApiInterface
     */
    notificationUpdate(id: number, notificationParams: NotificationParams, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI implements NotificationsApiInterface {
    /**
     * Create single notification instance
     * @param {NotificationParams} notificationParams notification params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationCreate(notificationParams: NotificationParams, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationCreate(notificationParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete notification by its primary key
     * @param {number} id notification primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationDelete(id: number, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single notification result instance
     * @param {number} id notification primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationGetById(id: number, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of notification
     * @param {Array<string>} [q] query for search in notification fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<NotificationExpand>} [expand] define notification expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<NotificationExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch notification instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {NotificationFields} notificationFields 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationPatch(filter: Array<string>, notificationFields: NotificationFields, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationPatch(filter, notificationFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update notification instance
     * @param {number} id -- notification primary key
     * @param {NotificationParams} notificationParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationUpdate(id: number, notificationParams: NotificationParams, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationUpdate(id, notificationParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotifierApi - axios parameter creator
 * @export
 */
export const NotifierApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return status of projects
         * @param {number} [orgId] organization id of status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifierStatus: async (orgId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifier/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotifierApi - functional programming interface
 * @export
 */
export const NotifierApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotifierApiAxiosParamCreator(configuration)
    return {
        /**
         * Return status of projects
         * @param {number} [orgId] organization id of status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notifierStatus(orgId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotifierStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notifierStatus(orgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotifierApi - factory interface
 * @export
 */
export const NotifierApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotifierApiFp(configuration)
    return {
        /**
         * Return status of projects
         * @param {number} [orgId] organization id of status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifierStatus(orgId?: number, options?: any): AxiosPromise<Array<NotifierStatus>> {
            return localVarFp.notifierStatus(orgId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotifierApi - interface
 * @export
 * @interface NotifierApi
 */
export interface NotifierApiInterface {
    /**
     * Return status of projects
     * @param {number} [orgId] organization id of status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotifierApiInterface
     */
    notifierStatus(orgId?: number, options?: AxiosRequestConfig): AxiosPromise<Array<NotifierStatus>>;

}

/**
 * NotifierApi - object-oriented interface
 * @export
 * @class NotifierApi
 * @extends {BaseAPI}
 */
export class NotifierApi extends BaseAPI implements NotifierApiInterface {
    /**
     * Return status of projects
     * @param {number} [orgId] organization id of status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotifierApi
     */
    public notifierStatus(orgId?: number, options?: AxiosRequestConfig) {
        return NotifierApiFp(this.configuration).notifierStatus(orgId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OSQueryApi - axios parameter creator
 * @export
 */
export const OSQueryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single query instance
         * @param {OsqueryParams} osqueryParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryCreate: async (osqueryParams: OsqueryParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'osqueryParams' is not null or undefined
            assertParamExists('queryCreate', 'osqueryParams', osqueryParams)
            const localVarPath = `/queries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(osqueryParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete query by its primary key
         * @param {number} id query primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('queryDelete', 'id', id)
            const localVarPath = `/queries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single query instance
         * @param {number} id -- query primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('queryGetById', 'id', id)
            const localVarPath = `/queries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of registered queries
         * @param {Array<string>} [q] query for search in query fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;result_type:raw&#x60;)
         * @param {Array<OsqueryExpand>} [expand] define query expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<OsqueryExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/queries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch query instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {OsqueryFields} osqueryFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryPatch: async (filter: Array<string>, osqueryFields: OsqueryFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('queryPatch', 'filter', filter)
            // verify required parameter 'osqueryFields' is not null or undefined
            assertParamExists('queryPatch', 'osqueryFields', osqueryFields)
            const localVarPath = `/queries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(osqueryFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update query instance
         * @param {number} id query primary key
         * @param {OsqueryParams} osqueryParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryUpdate: async (id: number, osqueryParams: OsqueryParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('queryUpdate', 'id', id)
            // verify required parameter 'osqueryParams' is not null or undefined
            assertParamExists('queryUpdate', 'osqueryParams', osqueryParams)
            const localVarPath = `/queries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(osqueryParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OSQueryApi - functional programming interface
 * @export
 */
export const OSQueryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OSQueryApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single query instance
         * @param {OsqueryParams} osqueryParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryCreate(osqueryParams: OsqueryParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2012>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryCreate(osqueryParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete query by its primary key
         * @param {number} id query primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single query instance
         * @param {number} id -- query primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Osquery>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of registered queries
         * @param {Array<string>} [q] query for search in query fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;result_type:raw&#x60;)
         * @param {Array<OsqueryExpand>} [expand] define query expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<OsqueryExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueriesPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch query instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {OsqueryFields} osqueryFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryPatch(filter: Array<string>, osqueryFields: OsqueryFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryPatch(filter, osqueryFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update query instance
         * @param {number} id query primary key
         * @param {OsqueryParams} osqueryParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryUpdate(id: number, osqueryParams: OsqueryParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryUpdate(id, osqueryParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OSQueryApi - factory interface
 * @export
 */
export const OSQueryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OSQueryApiFp(configuration)
    return {
        /**
         * Create single query instance
         * @param {OsqueryParams} osqueryParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryCreate(osqueryParams: OsqueryParams, options?: any): AxiosPromise<InlineResponse2012> {
            return localVarFp.queryCreate(osqueryParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete query by its primary key
         * @param {number} id query primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.queryDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single query instance
         * @param {number} id -- query primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryGetById(id: number, options?: any): AxiosPromise<Osquery> {
            return localVarFp.queryGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of registered queries
         * @param {Array<string>} [q] query for search in query fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;result_type:raw&#x60;)
         * @param {Array<OsqueryExpand>} [expand] define query expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<OsqueryExpand>, page?: number, limit?: number, options?: any): AxiosPromise<QueriesPage> {
            return localVarFp.queryList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch query instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {OsqueryFields} osqueryFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryPatch(filter: Array<string>, osqueryFields: OsqueryFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.queryPatch(filter, osqueryFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Update query instance
         * @param {number} id query primary key
         * @param {OsqueryParams} osqueryParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryUpdate(id: number, osqueryParams: OsqueryParams, options?: any): AxiosPromise<void> {
            return localVarFp.queryUpdate(id, osqueryParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OSQueryApi - interface
 * @export
 * @interface OSQueryApi
 */
export interface OSQueryApiInterface {
    /**
     * Create single query instance
     * @param {OsqueryParams} osqueryParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OSQueryApiInterface
     */
    queryCreate(osqueryParams: OsqueryParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse2012>;

    /**
     * Delete query by its primary key
     * @param {number} id query primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OSQueryApiInterface
     */
    queryDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return single query instance
     * @param {number} id -- query primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OSQueryApiInterface
     */
    queryGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<Osquery>;

    /**
     * Return collection of registered queries
     * @param {Array<string>} [q] query for search in query fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;result_type:raw&#x60;)
     * @param {Array<OsqueryExpand>} [expand] define query expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OSQueryApiInterface
     */
    queryList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<OsqueryExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<QueriesPage>;

    /**
     * Patch query instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {OsqueryFields} osqueryFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OSQueryApiInterface
     */
    queryPatch(filter: Array<string>, osqueryFields: OsqueryFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Update query instance
     * @param {number} id query primary key
     * @param {OsqueryParams} osqueryParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OSQueryApiInterface
     */
    queryUpdate(id: number, osqueryParams: OsqueryParams, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * OSQueryApi - object-oriented interface
 * @export
 * @class OSQueryApi
 * @extends {BaseAPI}
 */
export class OSQueryApi extends BaseAPI implements OSQueryApiInterface {
    /**
     * Create single query instance
     * @param {OsqueryParams} osqueryParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OSQueryApi
     */
    public queryCreate(osqueryParams: OsqueryParams, options?: AxiosRequestConfig) {
        return OSQueryApiFp(this.configuration).queryCreate(osqueryParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete query by its primary key
     * @param {number} id query primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OSQueryApi
     */
    public queryDelete(id: number, options?: AxiosRequestConfig) {
        return OSQueryApiFp(this.configuration).queryDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single query instance
     * @param {number} id -- query primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OSQueryApi
     */
    public queryGetById(id: number, options?: AxiosRequestConfig) {
        return OSQueryApiFp(this.configuration).queryGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of registered queries
     * @param {Array<string>} [q] query for search in query fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;result_type:raw&#x60;)
     * @param {Array<OsqueryExpand>} [expand] define query expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OSQueryApi
     */
    public queryList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<OsqueryExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return OSQueryApiFp(this.configuration).queryList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch query instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {OsqueryFields} osqueryFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OSQueryApi
     */
    public queryPatch(filter: Array<string>, osqueryFields: OsqueryFields, options?: AxiosRequestConfig) {
        return OSQueryApiFp(this.configuration).queryPatch(filter, osqueryFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update query instance
     * @param {number} id query primary key
     * @param {OsqueryParams} osqueryParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OSQueryApi
     */
    public queryUpdate(id: number, osqueryParams: OsqueryParams, options?: AxiosRequestConfig) {
        return OSQueryApiFp(this.configuration).queryUpdate(id, osqueryParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single organization instance
         * @param {OrganizationParams} organizationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationCreate: async (organizationParams: OrganizationParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationParams' is not null or undefined
            assertParamExists('organizationCreate', 'organizationParams', organizationParams)
            const localVarPath = `/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete organization by its primary key
         * @param {number} id organization primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationDelete', 'id', id)
            const localVarPath = `/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single organization instance
         * @param {number} id -- organization primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationGetById', 'id', id)
            const localVarPath = `/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of organizations
         * @param {Array<string>} [q] query for search in organization fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;active:false&#x60;)
         * @param {Array<OrganizationExpand>} [expand] define organization expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<OrganizationExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch organization instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {OrganizationFields} organizationFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationPatch: async (filter: Array<string>, organizationFields: OrganizationFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('organizationPatch', 'filter', filter)
            // verify required parameter 'organizationFields' is not null or undefined
            assertParamExists('organizationPatch', 'organizationFields', organizationFields)
            const localVarPath = `/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of organizations
         * @param {Array<string>} [q] query for search in organization fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;active:false&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationStatus: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update organization instance
         * @param {number} id -- organization primary key
         * @param {OrganizationParams} organizationParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationUpdate: async (id: number, organizationParams: OrganizationParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationUpdate', 'id', id)
            // verify required parameter 'organizationParams' is not null or undefined
            assertParamExists('organizationUpdate', 'organizationParams', organizationParams)
            const localVarPath = `/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single organization instance
         * @param {OrganizationParams} organizationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationCreate(organizationParams: OrganizationParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20110>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationCreate(organizationParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete organization by its primary key
         * @param {number} id organization primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single organization instance
         * @param {number} id -- organization primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of organizations
         * @param {Array<string>} [q] query for search in organization fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;active:false&#x60;)
         * @param {Array<OrganizationExpand>} [expand] define organization expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<OrganizationExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch organization instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {OrganizationFields} organizationFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationPatch(filter: Array<string>, organizationFields: OrganizationFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationPatch(filter, organizationFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of organizations
         * @param {Array<string>} [q] query for search in organization fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;active:false&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationStatus(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationsStatusPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationStatus(q, sort, filter, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update organization instance
         * @param {number} id -- organization primary key
         * @param {OrganizationParams} organizationParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationUpdate(id: number, organizationParams: OrganizationParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationUpdate(id, organizationParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationApiFp(configuration)
    return {
        /**
         * Create single organization instance
         * @param {OrganizationParams} organizationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationCreate(organizationParams: OrganizationParams, options?: any): AxiosPromise<InlineResponse20110> {
            return localVarFp.organizationCreate(organizationParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete organization by its primary key
         * @param {number} id organization primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.organizationDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single organization instance
         * @param {number} id -- organization primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationGetById(id: number, options?: any): AxiosPromise<Organization> {
            return localVarFp.organizationGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of organizations
         * @param {Array<string>} [q] query for search in organization fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;active:false&#x60;)
         * @param {Array<OrganizationExpand>} [expand] define organization expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<OrganizationExpand>, page?: number, limit?: number, options?: any): AxiosPromise<OrganizationsPage> {
            return localVarFp.organizationList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch organization instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {OrganizationFields} organizationFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationPatch(filter: Array<string>, organizationFields: OrganizationFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationPatch(filter, organizationFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of organizations
         * @param {Array<string>} [q] query for search in organization fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;active:false&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationStatus(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: any): AxiosPromise<OrganizationsStatusPage> {
            return localVarFp.organizationStatus(q, sort, filter, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Update organization instance
         * @param {number} id -- organization primary key
         * @param {OrganizationParams} organizationParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationUpdate(id: number, organizationParams: OrganizationParams, options?: any): AxiosPromise<void> {
            return localVarFp.organizationUpdate(id, organizationParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationApi - interface
 * @export
 * @interface OrganizationApi
 */
export interface OrganizationApiInterface {
    /**
     * Create single organization instance
     * @param {OrganizationParams} organizationParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    organizationCreate(organizationParams: OrganizationParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse20110>;

    /**
     * Delete organization by its primary key
     * @param {number} id organization primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    organizationDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return single organization instance
     * @param {number} id -- organization primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    organizationGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<Organization>;

    /**
     * Return collection of organizations
     * @param {Array<string>} [q] query for search in organization fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;active:false&#x60;)
     * @param {Array<OrganizationExpand>} [expand] define organization expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    organizationList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<OrganizationExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<OrganizationsPage>;

    /**
     * Patch organization instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {OrganizationFields} organizationFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    organizationPatch(filter: Array<string>, organizationFields: OrganizationFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Return collection of organizations
     * @param {Array<string>} [q] query for search in organization fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;active:false&#x60;)
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    organizationStatus(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<OrganizationsStatusPage>;

    /**
     * Update organization instance
     * @param {number} id -- organization primary key
     * @param {OrganizationParams} organizationParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    organizationUpdate(id: number, organizationParams: OrganizationParams, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI implements OrganizationApiInterface {
    /**
     * Create single organization instance
     * @param {OrganizationParams} organizationParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationCreate(organizationParams: OrganizationParams, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationCreate(organizationParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete organization by its primary key
     * @param {number} id organization primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationDelete(id: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single organization instance
     * @param {number} id -- organization primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationGetById(id: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of organizations
     * @param {Array<string>} [q] query for search in organization fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;active:false&#x60;)
     * @param {Array<OrganizationExpand>} [expand] define organization expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<OrganizationExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch organization instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {OrganizationFields} organizationFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationPatch(filter: Array<string>, organizationFields: OrganizationFields, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationPatch(filter, organizationFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of organizations
     * @param {Array<string>} [q] query for search in organization fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;active:false&#x60;)
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationStatus(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationStatus(q, sort, filter, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update organization instance
     * @param {number} id -- organization primary key
     * @param {OrganizationParams} organizationParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationUpdate(id: number, organizationParams: OrganizationParams, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).organizationUpdate(id, organizationParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationsGroupsApi - axios parameter creator
 * @export
 */
export const OrganizationsGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single organizations groups instance
         * @param {OrganizationsGroupsParams} organizationsGroupsParams organizations groups params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsGroupsCreate: async (organizationsGroupsParams: OrganizationsGroupsParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationsGroupsParams' is not null or undefined
            assertParamExists('organizationsGroupsCreate', 'organizationsGroupsParams', organizationsGroupsParams)
            const localVarPath = `/organizations-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationsGroupsParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete organizations groups  by its primary key
         * @param {number} id organizations groups primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsGroupsDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsGroupsDelete', 'id', id)
            const localVarPath = `/organizations-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete organizations groups  by its primary key
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)y
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsGroupsDeleteMany: async (filter: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('organizationsGroupsDeleteMany', 'filter', filter)
            const localVarPath = `/organizations-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single organizations groups result instance
         * @param {number} id organizations groups primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsGroupsGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsGroupsGetById', 'id', id)
            const localVarPath = `/organizations-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of organizations groups
         * @param {Array<string>} [q] query for search in organizations groups fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<OrganizationsGroupsExpand>} [expand] define organizations groups expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsGroupsList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<OrganizationsGroupsExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch organizations groups instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {OrganizationsGroupsFields} organizationsGroupsFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsGroupsPatch: async (filter: Array<string>, organizationsGroupsFields: OrganizationsGroupsFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('organizationsGroupsPatch', 'filter', filter)
            // verify required parameter 'organizationsGroupsFields' is not null or undefined
            assertParamExists('organizationsGroupsPatch', 'organizationsGroupsFields', organizationsGroupsFields)
            const localVarPath = `/organizations-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationsGroupsFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update organizations groups instance
         * @param {number} id -- organizations groups primary key
         * @param {OrganizationsGroupsParams} organizationsGroupsParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsGroupsUpdate: async (id: number, organizationsGroupsParams: OrganizationsGroupsParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsGroupsUpdate', 'id', id)
            // verify required parameter 'organizationsGroupsParams' is not null or undefined
            assertParamExists('organizationsGroupsUpdate', 'organizationsGroupsParams', organizationsGroupsParams)
            const localVarPath = `/organizations-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationsGroupsParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationsGroupsApi - functional programming interface
 * @export
 */
export const OrganizationsGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationsGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single organizations groups instance
         * @param {OrganizationsGroupsParams} organizationsGroupsParams organizations groups params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsGroupsCreate(organizationsGroupsParams: OrganizationsGroupsParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2019>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsGroupsCreate(organizationsGroupsParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete organizations groups  by its primary key
         * @param {number} id organizations groups primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsGroupsDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsGroupsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete organizations groups  by its primary key
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)y
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsGroupsDeleteMany(filter: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsGroupsDeleteMany(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single organizations groups result instance
         * @param {number} id organizations groups primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsGroupsGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationsGroups>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsGroupsGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of organizations groups
         * @param {Array<string>} [q] query for search in organizations groups fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<OrganizationsGroupsExpand>} [expand] define organizations groups expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsGroupsList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<OrganizationsGroupsExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationsGroupsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsGroupsList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch organizations groups instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {OrganizationsGroupsFields} organizationsGroupsFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsGroupsPatch(filter: Array<string>, organizationsGroupsFields: OrganizationsGroupsFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsGroupsPatch(filter, organizationsGroupsFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update organizations groups instance
         * @param {number} id -- organizations groups primary key
         * @param {OrganizationsGroupsParams} organizationsGroupsParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsGroupsUpdate(id: number, organizationsGroupsParams: OrganizationsGroupsParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsGroupsUpdate(id, organizationsGroupsParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationsGroupsApi - factory interface
 * @export
 */
export const OrganizationsGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationsGroupsApiFp(configuration)
    return {
        /**
         * Create single organizations groups instance
         * @param {OrganizationsGroupsParams} organizationsGroupsParams organizations groups params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsGroupsCreate(organizationsGroupsParams: OrganizationsGroupsParams, options?: any): AxiosPromise<InlineResponse2019> {
            return localVarFp.organizationsGroupsCreate(organizationsGroupsParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete organizations groups  by its primary key
         * @param {number} id organizations groups primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsGroupsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.organizationsGroupsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete organizations groups  by its primary key
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)y
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsGroupsDeleteMany(filter: Array<string>, options?: any): AxiosPromise<boolean> {
            return localVarFp.organizationsGroupsDeleteMany(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single organizations groups result instance
         * @param {number} id organizations groups primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsGroupsGetById(id: number, options?: any): AxiosPromise<OrganizationsGroups> {
            return localVarFp.organizationsGroupsGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of organizations groups
         * @param {Array<string>} [q] query for search in organizations groups fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<OrganizationsGroupsExpand>} [expand] define organizations groups expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsGroupsList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<OrganizationsGroupsExpand>, page?: number, limit?: number, options?: any): AxiosPromise<OrganizationsGroupsPage> {
            return localVarFp.organizationsGroupsList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch organizations groups instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {OrganizationsGroupsFields} organizationsGroupsFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsGroupsPatch(filter: Array<string>, organizationsGroupsFields: OrganizationsGroupsFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsGroupsPatch(filter, organizationsGroupsFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Update organizations groups instance
         * @param {number} id -- organizations groups primary key
         * @param {OrganizationsGroupsParams} organizationsGroupsParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsGroupsUpdate(id: number, organizationsGroupsParams: OrganizationsGroupsParams, options?: any): AxiosPromise<void> {
            return localVarFp.organizationsGroupsUpdate(id, organizationsGroupsParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationsGroupsApi - interface
 * @export
 * @interface OrganizationsGroupsApi
 */
export interface OrganizationsGroupsApiInterface {
    /**
     * Create single organizations groups instance
     * @param {OrganizationsGroupsParams} organizationsGroupsParams organizations groups params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsGroupsApiInterface
     */
    organizationsGroupsCreate(organizationsGroupsParams: OrganizationsGroupsParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse2019>;

    /**
     * Delete organizations groups  by its primary key
     * @param {number} id organizations groups primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsGroupsApiInterface
     */
    organizationsGroupsDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Delete organizations groups  by its primary key
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)y
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsGroupsApiInterface
     */
    organizationsGroupsDeleteMany(filter: Array<string>, options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * Return single organizations groups result instance
     * @param {number} id organizations groups primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsGroupsApiInterface
     */
    organizationsGroupsGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<OrganizationsGroups>;

    /**
     * Return collection of organizations groups
     * @param {Array<string>} [q] query for search in organizations groups fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<OrganizationsGroupsExpand>} [expand] define organizations groups expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsGroupsApiInterface
     */
    organizationsGroupsList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<OrganizationsGroupsExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<OrganizationsGroupsPage>;

    /**
     * Patch organizations groups instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {OrganizationsGroupsFields} organizationsGroupsFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsGroupsApiInterface
     */
    organizationsGroupsPatch(filter: Array<string>, organizationsGroupsFields: OrganizationsGroupsFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Update organizations groups instance
     * @param {number} id -- organizations groups primary key
     * @param {OrganizationsGroupsParams} organizationsGroupsParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsGroupsApiInterface
     */
    organizationsGroupsUpdate(id: number, organizationsGroupsParams: OrganizationsGroupsParams, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * OrganizationsGroupsApi - object-oriented interface
 * @export
 * @class OrganizationsGroupsApi
 * @extends {BaseAPI}
 */
export class OrganizationsGroupsApi extends BaseAPI implements OrganizationsGroupsApiInterface {
    /**
     * Create single organizations groups instance
     * @param {OrganizationsGroupsParams} organizationsGroupsParams organizations groups params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsGroupsApi
     */
    public organizationsGroupsCreate(organizationsGroupsParams: OrganizationsGroupsParams, options?: AxiosRequestConfig) {
        return OrganizationsGroupsApiFp(this.configuration).organizationsGroupsCreate(organizationsGroupsParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete organizations groups  by its primary key
     * @param {number} id organizations groups primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsGroupsApi
     */
    public organizationsGroupsDelete(id: number, options?: AxiosRequestConfig) {
        return OrganizationsGroupsApiFp(this.configuration).organizationsGroupsDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete organizations groups  by its primary key
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)y
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsGroupsApi
     */
    public organizationsGroupsDeleteMany(filter: Array<string>, options?: AxiosRequestConfig) {
        return OrganizationsGroupsApiFp(this.configuration).organizationsGroupsDeleteMany(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single organizations groups result instance
     * @param {number} id organizations groups primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsGroupsApi
     */
    public organizationsGroupsGetById(id: number, options?: AxiosRequestConfig) {
        return OrganizationsGroupsApiFp(this.configuration).organizationsGroupsGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of organizations groups
     * @param {Array<string>} [q] query for search in organizations groups fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<OrganizationsGroupsExpand>} [expand] define organizations groups expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsGroupsApi
     */
    public organizationsGroupsList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<OrganizationsGroupsExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return OrganizationsGroupsApiFp(this.configuration).organizationsGroupsList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch organizations groups instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {OrganizationsGroupsFields} organizationsGroupsFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsGroupsApi
     */
    public organizationsGroupsPatch(filter: Array<string>, organizationsGroupsFields: OrganizationsGroupsFields, options?: AxiosRequestConfig) {
        return OrganizationsGroupsApiFp(this.configuration).organizationsGroupsPatch(filter, organizationsGroupsFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update organizations groups instance
     * @param {number} id -- organizations groups primary key
     * @param {OrganizationsGroupsParams} organizationsGroupsParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsGroupsApi
     */
    public organizationsGroupsUpdate(id: number, organizationsGroupsParams: OrganizationsGroupsParams, options?: AxiosRequestConfig) {
        return OrganizationsGroupsApiFp(this.configuration).organizationsGroupsUpdate(id, organizationsGroupsParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationsLinkageApi - axios parameter creator
 * @export
 */
export const OrganizationsLinkageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single organizations linkage instance
         * @param {OrganizationsLinkageParams} organizationsLinkageParams organizations linkage params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsLinkageCreate: async (organizationsLinkageParams: OrganizationsLinkageParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationsLinkageParams' is not null or undefined
            assertParamExists('organizationsLinkageCreate', 'organizationsLinkageParams', organizationsLinkageParams)
            const localVarPath = `/organizations-linkage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationsLinkageParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete organizations linkage by its primary key
         * @param {number} id organizations linkage primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsLinkageDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsLinkageDelete', 'id', id)
            const localVarPath = `/organizations-linkage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete organizations linkage by its primary key
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsLinkageDeleteMany: async (filter: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('organizationsLinkageDeleteMany', 'filter', filter)
            const localVarPath = `/organizations-linkage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single organizations linkage result instance
         * @param {number} id organizations linkage primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsLinkageGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsLinkageGetById', 'id', id)
            const localVarPath = `/organizations-linkage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of organizations linkage
         * @param {Array<string>} [q] query for search in organizations linkage fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<OrganizationsLinkageExpand>} [expand] define organizations linkage expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsLinkageList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<OrganizationsLinkageExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations-linkage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch organizations linkage instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {OrganizationsLinkageFields} organizationsLinkageFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsLinkagePatch: async (filter: Array<string>, organizationsLinkageFields: OrganizationsLinkageFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('organizationsLinkagePatch', 'filter', filter)
            // verify required parameter 'organizationsLinkageFields' is not null or undefined
            assertParamExists('organizationsLinkagePatch', 'organizationsLinkageFields', organizationsLinkageFields)
            const localVarPath = `/organizations-linkage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationsLinkageFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update organizations linkages instance
         * @param {number} id -- organizations linkage primary key
         * @param {OrganizationsLinkageParams} organizationsLinkageParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsLinkageUpdate: async (id: number, organizationsLinkageParams: OrganizationsLinkageParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsLinkageUpdate', 'id', id)
            // verify required parameter 'organizationsLinkageParams' is not null or undefined
            assertParamExists('organizationsLinkageUpdate', 'organizationsLinkageParams', organizationsLinkageParams)
            const localVarPath = `/organizations-linkage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationsLinkageParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationsLinkageApi - functional programming interface
 * @export
 */
export const OrganizationsLinkageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationsLinkageApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single organizations linkage instance
         * @param {OrganizationsLinkageParams} organizationsLinkageParams organizations linkage params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsLinkageCreate(organizationsLinkageParams: OrganizationsLinkageParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2018>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsLinkageCreate(organizationsLinkageParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete organizations linkage by its primary key
         * @param {number} id organizations linkage primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsLinkageDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsLinkageDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete organizations linkage by its primary key
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsLinkageDeleteMany(filter: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsLinkageDeleteMany(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single organizations linkage result instance
         * @param {number} id organizations linkage primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsLinkageGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationsLinkage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsLinkageGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of organizations linkage
         * @param {Array<string>} [q] query for search in organizations linkage fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<OrganizationsLinkageExpand>} [expand] define organizations linkage expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsLinkageList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<OrganizationsLinkageExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationsLinkagePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsLinkageList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch organizations linkage instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {OrganizationsLinkageFields} organizationsLinkageFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsLinkagePatch(filter: Array<string>, organizationsLinkageFields: OrganizationsLinkageFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsLinkagePatch(filter, organizationsLinkageFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update organizations linkages instance
         * @param {number} id -- organizations linkage primary key
         * @param {OrganizationsLinkageParams} organizationsLinkageParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsLinkageUpdate(id: number, organizationsLinkageParams: OrganizationsLinkageParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsLinkageUpdate(id, organizationsLinkageParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationsLinkageApi - factory interface
 * @export
 */
export const OrganizationsLinkageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationsLinkageApiFp(configuration)
    return {
        /**
         * Create single organizations linkage instance
         * @param {OrganizationsLinkageParams} organizationsLinkageParams organizations linkage params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsLinkageCreate(organizationsLinkageParams: OrganizationsLinkageParams, options?: any): AxiosPromise<InlineResponse2018> {
            return localVarFp.organizationsLinkageCreate(organizationsLinkageParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete organizations linkage by its primary key
         * @param {number} id organizations linkage primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsLinkageDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.organizationsLinkageDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete organizations linkage by its primary key
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsLinkageDeleteMany(filter: Array<string>, options?: any): AxiosPromise<boolean> {
            return localVarFp.organizationsLinkageDeleteMany(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single organizations linkage result instance
         * @param {number} id organizations linkage primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsLinkageGetById(id: number, options?: any): AxiosPromise<OrganizationsLinkage> {
            return localVarFp.organizationsLinkageGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of organizations linkage
         * @param {Array<string>} [q] query for search in organizations linkage fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<OrganizationsLinkageExpand>} [expand] define organizations linkage expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsLinkageList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<OrganizationsLinkageExpand>, page?: number, limit?: number, options?: any): AxiosPromise<OrganizationsLinkagePage> {
            return localVarFp.organizationsLinkageList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch organizations linkage instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {OrganizationsLinkageFields} organizationsLinkageFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsLinkagePatch(filter: Array<string>, organizationsLinkageFields: OrganizationsLinkageFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsLinkagePatch(filter, organizationsLinkageFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Update organizations linkages instance
         * @param {number} id -- organizations linkage primary key
         * @param {OrganizationsLinkageParams} organizationsLinkageParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsLinkageUpdate(id: number, organizationsLinkageParams: OrganizationsLinkageParams, options?: any): AxiosPromise<void> {
            return localVarFp.organizationsLinkageUpdate(id, organizationsLinkageParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationsLinkageApi - interface
 * @export
 * @interface OrganizationsLinkageApi
 */
export interface OrganizationsLinkageApiInterface {
    /**
     * Create single organizations linkage instance
     * @param {OrganizationsLinkageParams} organizationsLinkageParams organizations linkage params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsLinkageApiInterface
     */
    organizationsLinkageCreate(organizationsLinkageParams: OrganizationsLinkageParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse2018>;

    /**
     * Delete organizations linkage by its primary key
     * @param {number} id organizations linkage primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsLinkageApiInterface
     */
    organizationsLinkageDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Delete organizations linkage by its primary key
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsLinkageApiInterface
     */
    organizationsLinkageDeleteMany(filter: Array<string>, options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * Return single organizations linkage result instance
     * @param {number} id organizations linkage primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsLinkageApiInterface
     */
    organizationsLinkageGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<OrganizationsLinkage>;

    /**
     * Return collection of organizations linkage
     * @param {Array<string>} [q] query for search in organizations linkage fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<OrganizationsLinkageExpand>} [expand] define organizations linkage expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsLinkageApiInterface
     */
    organizationsLinkageList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<OrganizationsLinkageExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<OrganizationsLinkagePage>;

    /**
     * Patch organizations linkage instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {OrganizationsLinkageFields} organizationsLinkageFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsLinkageApiInterface
     */
    organizationsLinkagePatch(filter: Array<string>, organizationsLinkageFields: OrganizationsLinkageFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Update organizations linkages instance
     * @param {number} id -- organizations linkage primary key
     * @param {OrganizationsLinkageParams} organizationsLinkageParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsLinkageApiInterface
     */
    organizationsLinkageUpdate(id: number, organizationsLinkageParams: OrganizationsLinkageParams, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * OrganizationsLinkageApi - object-oriented interface
 * @export
 * @class OrganizationsLinkageApi
 * @extends {BaseAPI}
 */
export class OrganizationsLinkageApi extends BaseAPI implements OrganizationsLinkageApiInterface {
    /**
     * Create single organizations linkage instance
     * @param {OrganizationsLinkageParams} organizationsLinkageParams organizations linkage params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsLinkageApi
     */
    public organizationsLinkageCreate(organizationsLinkageParams: OrganizationsLinkageParams, options?: AxiosRequestConfig) {
        return OrganizationsLinkageApiFp(this.configuration).organizationsLinkageCreate(organizationsLinkageParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete organizations linkage by its primary key
     * @param {number} id organizations linkage primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsLinkageApi
     */
    public organizationsLinkageDelete(id: number, options?: AxiosRequestConfig) {
        return OrganizationsLinkageApiFp(this.configuration).organizationsLinkageDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete organizations linkage by its primary key
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsLinkageApi
     */
    public organizationsLinkageDeleteMany(filter: Array<string>, options?: AxiosRequestConfig) {
        return OrganizationsLinkageApiFp(this.configuration).organizationsLinkageDeleteMany(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single organizations linkage result instance
     * @param {number} id organizations linkage primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsLinkageApi
     */
    public organizationsLinkageGetById(id: number, options?: AxiosRequestConfig) {
        return OrganizationsLinkageApiFp(this.configuration).organizationsLinkageGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of organizations linkage
     * @param {Array<string>} [q] query for search in organizations linkage fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<OrganizationsLinkageExpand>} [expand] define organizations linkage expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsLinkageApi
     */
    public organizationsLinkageList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<OrganizationsLinkageExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return OrganizationsLinkageApiFp(this.configuration).organizationsLinkageList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch organizations linkage instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {OrganizationsLinkageFields} organizationsLinkageFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsLinkageApi
     */
    public organizationsLinkagePatch(filter: Array<string>, organizationsLinkageFields: OrganizationsLinkageFields, options?: AxiosRequestConfig) {
        return OrganizationsLinkageApiFp(this.configuration).organizationsLinkagePatch(filter, organizationsLinkageFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update organizations linkages instance
     * @param {number} id -- organizations linkage primary key
     * @param {OrganizationsLinkageParams} organizationsLinkageParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsLinkageApi
     */
    public organizationsLinkageUpdate(id: number, organizationsLinkageParams: OrganizationsLinkageParams, options?: AxiosRequestConfig) {
        return OrganizationsLinkageApiFp(this.configuration).organizationsLinkageUpdate(id, organizationsLinkageParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single project instance
         * @param {ProjectParams} projectParams project params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCreate: async (projectParams: ProjectParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectParams' is not null or undefined
            assertParamExists('projectCreate', 'projectParams', projectParams)
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete project by its primary key
         * @param {number} id project primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectDelete', 'id', id)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single project result instance
         * @param {number} id project primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectGetById', 'id', id)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of projects
         * @param {Array<string>} [q] query for search in project fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<ProjectExpand>} [expand] define project expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ProjectExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch project instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {ProjectFields} projectFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectPatch: async (filter: Array<string>, projectFields: ProjectFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('projectPatch', 'filter', filter)
            // verify required parameter 'projectFields' is not null or undefined
            assertParamExists('projectPatch', 'projectFields', projectFields)
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return status of projects
         * @param {Array<string>} [q] query for search in project fields (e.g. firstName, lastName, email)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectStatus: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update project instance
         * @param {number} id -- project primary key
         * @param {ProjectParams} projectParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdate: async (id: number, projectParams: ProjectParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectUpdate', 'id', id)
            // verify required parameter 'projectParams' is not null or undefined
            assertParamExists('projectUpdate', 'projectParams', projectParams)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns survey result for project
         * @param {number} id of project to get survey result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResult: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('surveyResult', 'id', id)
            const localVarPath = `/projects/survey-result/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single project instance
         * @param {ProjectParams} projectParams project params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectCreate(projectParams: ProjectParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20112>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectCreate(projectParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete project by its primary key
         * @param {number} id project primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single project result instance
         * @param {number} id project primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of projects
         * @param {Array<string>} [q] query for search in project fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<ProjectExpand>} [expand] define project expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ProjectExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch project instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {ProjectFields} projectFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectPatch(filter: Array<string>, projectFields: ProjectFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectPatch(filter, projectFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return status of projects
         * @param {Array<string>} [q] query for search in project fields (e.g. firstName, lastName, email)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectStatus(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectStatusPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectStatus(q, sort, filter, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update project instance
         * @param {number} id -- project primary key
         * @param {ProjectParams} projectParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdate(id: number, projectParams: ProjectParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdate(id, projectParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns survey result for project
         * @param {number} id of project to get survey result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyResult(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20113>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyResult(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * Create single project instance
         * @param {ProjectParams} projectParams project params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCreate(projectParams: ProjectParams, options?: any): AxiosPromise<InlineResponse20112> {
            return localVarFp.projectCreate(projectParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete project by its primary key
         * @param {number} id project primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.projectDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single project result instance
         * @param {number} id project primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetById(id: number, options?: any): AxiosPromise<Project> {
            return localVarFp.projectGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of projects
         * @param {Array<string>} [q] query for search in project fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<ProjectExpand>} [expand] define project expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ProjectExpand>, page?: number, limit?: number, options?: any): AxiosPromise<ProjectPage> {
            return localVarFp.projectList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch project instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {ProjectFields} projectFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectPatch(filter: Array<string>, projectFields: ProjectFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.projectPatch(filter, projectFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Return status of projects
         * @param {Array<string>} [q] query for search in project fields (e.g. firstName, lastName, email)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectStatus(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: any): AxiosPromise<ProjectStatusPage> {
            return localVarFp.projectStatus(q, sort, filter, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Update project instance
         * @param {number} id -- project primary key
         * @param {ProjectParams} projectParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdate(id: number, projectParams: ProjectParams, options?: any): AxiosPromise<void> {
            return localVarFp.projectUpdate(id, projectParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns survey result for project
         * @param {number} id of project to get survey result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResult(id: number, options?: any): AxiosPromise<InlineResponse20113> {
            return localVarFp.surveyResult(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - interface
 * @export
 * @interface ProjectApi
 */
export interface ProjectApiInterface {
    /**
     * Create single project instance
     * @param {ProjectParams} projectParams project params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectCreate(projectParams: ProjectParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse20112>;

    /**
     * Delete project by its primary key
     * @param {number} id project primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return single project result instance
     * @param {number} id project primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<Project>;

    /**
     * Return collection of projects
     * @param {Array<string>} [q] query for search in project fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<ProjectExpand>} [expand] define project expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ProjectExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<ProjectPage>;

    /**
     * Patch project instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {ProjectFields} projectFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectPatch(filter: Array<string>, projectFields: ProjectFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Return status of projects
     * @param {Array<string>} [q] query for search in project fields (e.g. firstName, lastName, email)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectStatus(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<ProjectStatusPage>;

    /**
     * Update project instance
     * @param {number} id -- project primary key
     * @param {ProjectParams} projectParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectUpdate(id: number, projectParams: ProjectParams, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Returns survey result for project
     * @param {number} id of project to get survey result
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    surveyResult(id: number, options?: AxiosRequestConfig): AxiosPromise<InlineResponse20113>;

}

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI implements ProjectApiInterface {
    /**
     * Create single project instance
     * @param {ProjectParams} projectParams project params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectCreate(projectParams: ProjectParams, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectCreate(projectParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete project by its primary key
     * @param {number} id project primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectDelete(id: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single project result instance
     * @param {number} id project primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetById(id: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of projects
     * @param {Array<string>} [q] query for search in project fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<ProjectExpand>} [expand] define project expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ProjectExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch project instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {ProjectFields} projectFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectPatch(filter: Array<string>, projectFields: ProjectFields, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectPatch(filter, projectFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return status of projects
     * @param {Array<string>} [q] query for search in project fields (e.g. firstName, lastName, email)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectStatus(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectStatus(q, sort, filter, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update project instance
     * @param {number} id -- project primary key
     * @param {ProjectParams} projectParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdate(id: number, projectParams: ProjectParams, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdate(id, projectParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns survey result for project
     * @param {number} id of project to get survey result
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public surveyResult(id: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).surveyResult(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectCommentApi - axios parameter creator
 * @export
 */
export const ProjectCommentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single project comment instance
         * @param {ProjectCommentsParams} projectCommentsParams project comment params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCommentCreate: async (projectCommentsParams: ProjectCommentsParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectCommentsParams' is not null or undefined
            assertParamExists('projectCommentCreate', 'projectCommentsParams', projectCommentsParams)
            const localVarPath = `/project-comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectCommentsParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete project by its primary key
         * @param {number} id project primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCommentDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectCommentDelete', 'id', id)
            const localVarPath = `/project-comments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single project comment result instance
         * @param {number} id project primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCommentGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectCommentGetById', 'id', id)
            const localVarPath = `/project-comments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of project comments
         * @param {Array<string>} [q] query for search in project comment fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<ProjectCommentExpand>} [expand] define project expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCommentList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ProjectCommentExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project-comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch project instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {ProjectCommentsFields} projectCommentsFields 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCommentPatch: async (filter: Array<string>, projectCommentsFields: ProjectCommentsFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('projectCommentPatch', 'filter', filter)
            // verify required parameter 'projectCommentsFields' is not null or undefined
            assertParamExists('projectCommentPatch', 'projectCommentsFields', projectCommentsFields)
            const localVarPath = `/project-comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectCommentsFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update project instance
         * @param {number} id -- project primary key
         * @param {ProjectCommentsParams} projectCommentsParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCommentUpdate: async (id: number, projectCommentsParams: ProjectCommentsParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectCommentUpdate', 'id', id)
            // verify required parameter 'projectCommentsParams' is not null or undefined
            assertParamExists('projectCommentUpdate', 'projectCommentsParams', projectCommentsParams)
            const localVarPath = `/project-comments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectCommentsParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectCommentApi - functional programming interface
 * @export
 */
export const ProjectCommentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectCommentApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single project comment instance
         * @param {ProjectCommentsParams} projectCommentsParams project comment params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectCommentCreate(projectCommentsParams: ProjectCommentsParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20111>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectCommentCreate(projectCommentsParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete project by its primary key
         * @param {number} id project primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectCommentDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectCommentDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single project comment result instance
         * @param {number} id project primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectCommentGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectCommentGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of project comments
         * @param {Array<string>} [q] query for search in project comment fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<ProjectCommentExpand>} [expand] define project expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectCommentList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ProjectCommentExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectCommentsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectCommentList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch project instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {ProjectCommentsFields} projectCommentsFields 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectCommentPatch(filter: Array<string>, projectCommentsFields: ProjectCommentsFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectCommentPatch(filter, projectCommentsFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update project instance
         * @param {number} id -- project primary key
         * @param {ProjectCommentsParams} projectCommentsParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectCommentUpdate(id: number, projectCommentsParams: ProjectCommentsParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectCommentUpdate(id, projectCommentsParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectCommentApi - factory interface
 * @export
 */
export const ProjectCommentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectCommentApiFp(configuration)
    return {
        /**
         * Create single project comment instance
         * @param {ProjectCommentsParams} projectCommentsParams project comment params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCommentCreate(projectCommentsParams: ProjectCommentsParams, options?: any): AxiosPromise<InlineResponse20111> {
            return localVarFp.projectCommentCreate(projectCommentsParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete project by its primary key
         * @param {number} id project primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCommentDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.projectCommentDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single project comment result instance
         * @param {number} id project primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCommentGetById(id: number, options?: any): AxiosPromise<ProjectComment> {
            return localVarFp.projectCommentGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of project comments
         * @param {Array<string>} [q] query for search in project comment fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<ProjectCommentExpand>} [expand] define project expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCommentList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ProjectCommentExpand>, page?: number, limit?: number, options?: any): AxiosPromise<ProjectCommentsPage> {
            return localVarFp.projectCommentList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch project instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {ProjectCommentsFields} projectCommentsFields 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCommentPatch(filter: Array<string>, projectCommentsFields: ProjectCommentsFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.projectCommentPatch(filter, projectCommentsFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Update project instance
         * @param {number} id -- project primary key
         * @param {ProjectCommentsParams} projectCommentsParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCommentUpdate(id: number, projectCommentsParams: ProjectCommentsParams, options?: any): AxiosPromise<void> {
            return localVarFp.projectCommentUpdate(id, projectCommentsParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectCommentApi - interface
 * @export
 * @interface ProjectCommentApi
 */
export interface ProjectCommentApiInterface {
    /**
     * Create single project comment instance
     * @param {ProjectCommentsParams} projectCommentsParams project comment params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectCommentApiInterface
     */
    projectCommentCreate(projectCommentsParams: ProjectCommentsParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse20111>;

    /**
     * Delete project by its primary key
     * @param {number} id project primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectCommentApiInterface
     */
    projectCommentDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return single project comment result instance
     * @param {number} id project primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectCommentApiInterface
     */
    projectCommentGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<ProjectComment>;

    /**
     * Return collection of project comments
     * @param {Array<string>} [q] query for search in project comment fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<ProjectCommentExpand>} [expand] define project expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectCommentApiInterface
     */
    projectCommentList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ProjectCommentExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<ProjectCommentsPage>;

    /**
     * Patch project instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {ProjectCommentsFields} projectCommentsFields 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectCommentApiInterface
     */
    projectCommentPatch(filter: Array<string>, projectCommentsFields: ProjectCommentsFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Update project instance
     * @param {number} id -- project primary key
     * @param {ProjectCommentsParams} projectCommentsParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectCommentApiInterface
     */
    projectCommentUpdate(id: number, projectCommentsParams: ProjectCommentsParams, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ProjectCommentApi - object-oriented interface
 * @export
 * @class ProjectCommentApi
 * @extends {BaseAPI}
 */
export class ProjectCommentApi extends BaseAPI implements ProjectCommentApiInterface {
    /**
     * Create single project comment instance
     * @param {ProjectCommentsParams} projectCommentsParams project comment params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectCommentApi
     */
    public projectCommentCreate(projectCommentsParams: ProjectCommentsParams, options?: AxiosRequestConfig) {
        return ProjectCommentApiFp(this.configuration).projectCommentCreate(projectCommentsParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete project by its primary key
     * @param {number} id project primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectCommentApi
     */
    public projectCommentDelete(id: number, options?: AxiosRequestConfig) {
        return ProjectCommentApiFp(this.configuration).projectCommentDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single project comment result instance
     * @param {number} id project primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectCommentApi
     */
    public projectCommentGetById(id: number, options?: AxiosRequestConfig) {
        return ProjectCommentApiFp(this.configuration).projectCommentGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of project comments
     * @param {Array<string>} [q] query for search in project comment fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<ProjectCommentExpand>} [expand] define project expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectCommentApi
     */
    public projectCommentList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ProjectCommentExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return ProjectCommentApiFp(this.configuration).projectCommentList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch project instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {ProjectCommentsFields} projectCommentsFields 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectCommentApi
     */
    public projectCommentPatch(filter: Array<string>, projectCommentsFields: ProjectCommentsFields, options?: AxiosRequestConfig) {
        return ProjectCommentApiFp(this.configuration).projectCommentPatch(filter, projectCommentsFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update project instance
     * @param {number} id -- project primary key
     * @param {ProjectCommentsParams} projectCommentsParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectCommentApi
     */
    public projectCommentUpdate(id: number, projectCommentsParams: ProjectCommentsParams, options?: AxiosRequestConfig) {
        return ProjectCommentApiFp(this.configuration).projectCommentUpdate(id, projectCommentsParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScanApi - axios parameter creator
 * @export
 */
export const ScanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return collection of devices assigned to the scan
         * @param {string} id scan primary key
         * @param {Array<string>} [q] query for search in scan fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:MANDATORY&#x60;)
         * @param {Array<DeviceExpand>} [expand] define scan expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignedDevices: async (id: string, q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DeviceExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignedDevices', 'id', id)
            const localVarPath = `/scans/{id}/assignedDevices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of users assigned to the scan
         * @param {string} id scan primary key
         * @param {Array<string>} [q] query for search in scan fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:MANDATORY&#x60;)
         * @param {Array<UserExpand>} [expand] define scan expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignedUsers: async (id: string, q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<UserExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignedUsers', 'id', id)
            const localVarPath = `/scans/{id}/assignedUsers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create single scan instance
         * @param {ScanParams} scanParams scan params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanCreate: async (scanParams: ScanParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scanParams' is not null or undefined
            assertParamExists('scanCreate', 'scanParams', scanParams)
            const localVarPath = `/scans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scanParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete scan by its primary key
         * @param {number} id scan primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scanDelete', 'id', id)
            const localVarPath = `/scans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single scan instance
         * @param {number} id -- scan primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scanGetById', 'id', id)
            const localVarPath = `/scans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of registered scans
         * @param {Array<string>} [q] query for search in scan fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:MANDATORY&#x60;)
         * @param {Array<ScanExpand>} [expand] define scan expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ScanExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch scan instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {ScanFields} scanFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanPatch: async (filter: Array<string>, scanFields: ScanFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('scanPatch', 'filter', filter)
            // verify required parameter 'scanFields' is not null or undefined
            assertParamExists('scanPatch', 'scanFields', scanFields)
            const localVarPath = `/scans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scanFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of scans
         * @param {Array<string>} [q] query for search in scan fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:MANDATORY&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanStatus: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scans/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update scan instance
         * @param {number} id -- scan primary key
         * @param {ScanParams} scanParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanUpdate: async (id: number, scanParams: ScanParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scanUpdate', 'id', id)
            // verify required parameter 'scanParams' is not null or undefined
            assertParamExists('scanUpdate', 'scanParams', scanParams)
            const localVarPath = `/scans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scanParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScanApi - functional programming interface
 * @export
 */
export const ScanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScanApiAxiosParamCreator(configuration)
    return {
        /**
         * Return collection of devices assigned to the scan
         * @param {string} id scan primary key
         * @param {Array<string>} [q] query for search in scan fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:MANDATORY&#x60;)
         * @param {Array<DeviceExpand>} [expand] define scan expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignedDevices(id: string, q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DeviceExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignedDevices(id, q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of users assigned to the scan
         * @param {string} id scan primary key
         * @param {Array<string>} [q] query for search in scan fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:MANDATORY&#x60;)
         * @param {Array<UserExpand>} [expand] define scan expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignedUsers(id: string, q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<UserExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignedUsers(id, q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create single scan instance
         * @param {ScanParams} scanParams scan params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanCreate(scanParams: ScanParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20116>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanCreate(scanParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete scan by its primary key
         * @param {number} id scan primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single scan instance
         * @param {number} id -- scan primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of registered scans
         * @param {Array<string>} [q] query for search in scan fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:MANDATORY&#x60;)
         * @param {Array<ScanExpand>} [expand] define scan expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ScanExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScansPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch scan instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {ScanFields} scanFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanPatch(filter: Array<string>, scanFields: ScanFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanPatch(filter, scanFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of scans
         * @param {Array<string>} [q] query for search in scan fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:MANDATORY&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanStatus(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScansStatusPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanStatus(q, sort, filter, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update scan instance
         * @param {number} id -- scan primary key
         * @param {ScanParams} scanParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanUpdate(id: number, scanParams: ScanParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanUpdate(id, scanParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScanApi - factory interface
 * @export
 */
export const ScanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScanApiFp(configuration)
    return {
        /**
         * Return collection of devices assigned to the scan
         * @param {string} id scan primary key
         * @param {Array<string>} [q] query for search in scan fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:MANDATORY&#x60;)
         * @param {Array<DeviceExpand>} [expand] define scan expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignedDevices(id: string, q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DeviceExpand>, page?: number, limit?: number, options?: any): AxiosPromise<DevicesPage> {
            return localVarFp.assignedDevices(id, q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of users assigned to the scan
         * @param {string} id scan primary key
         * @param {Array<string>} [q] query for search in scan fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:MANDATORY&#x60;)
         * @param {Array<UserExpand>} [expand] define scan expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignedUsers(id: string, q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<UserExpand>, page?: number, limit?: number, options?: any): AxiosPromise<UsersPage> {
            return localVarFp.assignedUsers(id, q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Create single scan instance
         * @param {ScanParams} scanParams scan params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanCreate(scanParams: ScanParams, options?: any): AxiosPromise<InlineResponse20116> {
            return localVarFp.scanCreate(scanParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete scan by its primary key
         * @param {number} id scan primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.scanDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single scan instance
         * @param {number} id -- scan primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanGetById(id: number, options?: any): AxiosPromise<Scan> {
            return localVarFp.scanGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of registered scans
         * @param {Array<string>} [q] query for search in scan fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:MANDATORY&#x60;)
         * @param {Array<ScanExpand>} [expand] define scan expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ScanExpand>, page?: number, limit?: number, options?: any): AxiosPromise<ScansPage> {
            return localVarFp.scanList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch scan instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {ScanFields} scanFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanPatch(filter: Array<string>, scanFields: ScanFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.scanPatch(filter, scanFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of scans
         * @param {Array<string>} [q] query for search in scan fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:MANDATORY&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanStatus(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: any): AxiosPromise<ScansStatusPage> {
            return localVarFp.scanStatus(q, sort, filter, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Update scan instance
         * @param {number} id -- scan primary key
         * @param {ScanParams} scanParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanUpdate(id: number, scanParams: ScanParams, options?: any): AxiosPromise<void> {
            return localVarFp.scanUpdate(id, scanParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScanApi - interface
 * @export
 * @interface ScanApi
 */
export interface ScanApiInterface {
    /**
     * Return collection of devices assigned to the scan
     * @param {string} id scan primary key
     * @param {Array<string>} [q] query for search in scan fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:MANDATORY&#x60;)
     * @param {Array<DeviceExpand>} [expand] define scan expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApiInterface
     */
    assignedDevices(id: string, q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DeviceExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<DevicesPage>;

    /**
     * Return collection of users assigned to the scan
     * @param {string} id scan primary key
     * @param {Array<string>} [q] query for search in scan fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:MANDATORY&#x60;)
     * @param {Array<UserExpand>} [expand] define scan expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApiInterface
     */
    assignedUsers(id: string, q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<UserExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<UsersPage>;

    /**
     * Create single scan instance
     * @param {ScanParams} scanParams scan params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApiInterface
     */
    scanCreate(scanParams: ScanParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse20116>;

    /**
     * Delete scan by its primary key
     * @param {number} id scan primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApiInterface
     */
    scanDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return single scan instance
     * @param {number} id -- scan primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApiInterface
     */
    scanGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<Scan>;

    /**
     * Return collection of registered scans
     * @param {Array<string>} [q] query for search in scan fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:MANDATORY&#x60;)
     * @param {Array<ScanExpand>} [expand] define scan expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApiInterface
     */
    scanList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ScanExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<ScansPage>;

    /**
     * Patch scan instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {ScanFields} scanFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApiInterface
     */
    scanPatch(filter: Array<string>, scanFields: ScanFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Return collection of scans
     * @param {Array<string>} [q] query for search in scan fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:MANDATORY&#x60;)
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApiInterface
     */
    scanStatus(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<ScansStatusPage>;

    /**
     * Update scan instance
     * @param {number} id -- scan primary key
     * @param {ScanParams} scanParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApiInterface
     */
    scanUpdate(id: number, scanParams: ScanParams, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ScanApi - object-oriented interface
 * @export
 * @class ScanApi
 * @extends {BaseAPI}
 */
export class ScanApi extends BaseAPI implements ScanApiInterface {
    /**
     * Return collection of devices assigned to the scan
     * @param {string} id scan primary key
     * @param {Array<string>} [q] query for search in scan fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:MANDATORY&#x60;)
     * @param {Array<DeviceExpand>} [expand] define scan expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public assignedDevices(id: string, q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<DeviceExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).assignedDevices(id, q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of users assigned to the scan
     * @param {string} id scan primary key
     * @param {Array<string>} [q] query for search in scan fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:MANDATORY&#x60;)
     * @param {Array<UserExpand>} [expand] define scan expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public assignedUsers(id: string, q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<UserExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).assignedUsers(id, q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create single scan instance
     * @param {ScanParams} scanParams scan params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public scanCreate(scanParams: ScanParams, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).scanCreate(scanParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete scan by its primary key
     * @param {number} id scan primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public scanDelete(id: number, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).scanDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single scan instance
     * @param {number} id -- scan primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public scanGetById(id: number, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).scanGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of registered scans
     * @param {Array<string>} [q] query for search in scan fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:MANDATORY&#x60;)
     * @param {Array<ScanExpand>} [expand] define scan expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public scanList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ScanExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).scanList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch scan instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {ScanFields} scanFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public scanPatch(filter: Array<string>, scanFields: ScanFields, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).scanPatch(filter, scanFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of scans
     * @param {Array<string>} [q] query for search in scan fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:MANDATORY&#x60;)
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public scanStatus(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).scanStatus(q, sort, filter, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update scan instance
     * @param {number} id -- scan primary key
     * @param {ScanParams} scanParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public scanUpdate(id: number, scanParams: ScanParams, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).scanUpdate(id, scanParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScanResultsApi - axios parameter creator
 * @export
 */
export const ScanResultsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single scan result instance
         * @param {ScanResultParams} scanResultParams scan result params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanResultCreate: async (scanResultParams: ScanResultParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scanResultParams' is not null or undefined
            assertParamExists('scanResultCreate', 'scanResultParams', scanResultParams)
            const localVarPath = `/scan-results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scanResultParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete scan result by its primary keys
         * @param {number} id scan result primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanResultDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scanResultDelete', 'id', id)
            const localVarPath = `/scan-results/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single scan result instance
         * @param {number} id scan result primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanResultGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scanResultGetById', 'id', id)
            const localVarPath = `/scan-results/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of registered scan results
         * @param {Array<string>} [q] query for search in user fields (e.g. rawData)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;deviceId:1&#x60;)
         * @param {Array<ScanResultExpand>} [expand] define distributed scan result expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanResultList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ScanResultExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scan-results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch scan result instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {ScanResultFields} scanResultFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanResultPatch: async (filter: Array<string>, scanResultFields: ScanResultFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('scanResultPatch', 'filter', filter)
            // verify required parameter 'scanResultFields' is not null or undefined
            assertParamExists('scanResultPatch', 'scanResultFields', scanResultFields)
            const localVarPath = `/scan-results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scanResultFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update scan result instance
         * @param {number} id scan result primary key to update
         * @param {ScanResultParams} scanResultParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanResultUpdate: async (id: number, scanResultParams: ScanResultParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scanResultUpdate', 'id', id)
            // verify required parameter 'scanResultParams' is not null or undefined
            assertParamExists('scanResultUpdate', 'scanResultParams', scanResultParams)
            const localVarPath = `/scan-results/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scanResultParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return vulnerabilities count by scan result id
         * @param {Array<string>} [q] query for search in user fields (e.g. deviceInfo)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;userId:1&#x60;)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanResultVulnerabilitiesCount: async (q?: Array<string>, filter?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scan-results/vulnerabilities-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScanResultsApi - functional programming interface
 * @export
 */
export const ScanResultsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScanResultsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single scan result instance
         * @param {ScanResultParams} scanResultParams scan result params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanResultCreate(scanResultParams: ScanResultParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20114>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanResultCreate(scanResultParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete scan result by its primary keys
         * @param {number} id scan result primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanResultDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanResultDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single scan result instance
         * @param {number} id scan result primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanResultGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScanResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanResultGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of registered scan results
         * @param {Array<string>} [q] query for search in user fields (e.g. rawData)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;deviceId:1&#x60;)
         * @param {Array<ScanResultExpand>} [expand] define distributed scan result expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanResultList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ScanResultExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScanResultPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanResultList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch scan result instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {ScanResultFields} scanResultFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanResultPatch(filter: Array<string>, scanResultFields: ScanResultFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanResultPatch(filter, scanResultFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update scan result instance
         * @param {number} id scan result primary key to update
         * @param {ScanResultParams} scanResultParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanResultUpdate(id: number, scanResultParams: ScanResultParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanResultUpdate(id, scanResultParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return vulnerabilities count by scan result id
         * @param {Array<string>} [q] query for search in user fields (e.g. deviceInfo)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;userId:1&#x60;)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanResultVulnerabilitiesCount(q?: Array<string>, filter?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VulnerabilitiesCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scanResultVulnerabilitiesCount(q, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScanResultsApi - factory interface
 * @export
 */
export const ScanResultsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScanResultsApiFp(configuration)
    return {
        /**
         * Create single scan result instance
         * @param {ScanResultParams} scanResultParams scan result params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanResultCreate(scanResultParams: ScanResultParams, options?: any): AxiosPromise<InlineResponse20114> {
            return localVarFp.scanResultCreate(scanResultParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete scan result by its primary keys
         * @param {number} id scan result primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanResultDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.scanResultDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single scan result instance
         * @param {number} id scan result primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanResultGetById(id: number, options?: any): AxiosPromise<ScanResult> {
            return localVarFp.scanResultGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of registered scan results
         * @param {Array<string>} [q] query for search in user fields (e.g. rawData)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;deviceId:1&#x60;)
         * @param {Array<ScanResultExpand>} [expand] define distributed scan result expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanResultList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ScanResultExpand>, page?: number, limit?: number, options?: any): AxiosPromise<ScanResultPage> {
            return localVarFp.scanResultList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch scan result instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {ScanResultFields} scanResultFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanResultPatch(filter: Array<string>, scanResultFields: ScanResultFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.scanResultPatch(filter, scanResultFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Update scan result instance
         * @param {number} id scan result primary key to update
         * @param {ScanResultParams} scanResultParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanResultUpdate(id: number, scanResultParams: ScanResultParams, options?: any): AxiosPromise<void> {
            return localVarFp.scanResultUpdate(id, scanResultParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Return vulnerabilities count by scan result id
         * @param {Array<string>} [q] query for search in user fields (e.g. deviceInfo)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;userId:1&#x60;)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanResultVulnerabilitiesCount(q?: Array<string>, filter?: Array<string>, options?: any): AxiosPromise<VulnerabilitiesCount> {
            return localVarFp.scanResultVulnerabilitiesCount(q, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScanResultsApi - interface
 * @export
 * @interface ScanResultsApi
 */
export interface ScanResultsApiInterface {
    /**
     * Create single scan result instance
     * @param {ScanResultParams} scanResultParams scan result params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanResultsApiInterface
     */
    scanResultCreate(scanResultParams: ScanResultParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse20114>;

    /**
     * Delete scan result by its primary keys
     * @param {number} id scan result primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanResultsApiInterface
     */
    scanResultDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return single scan result instance
     * @param {number} id scan result primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanResultsApiInterface
     */
    scanResultGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<ScanResult>;

    /**
     * Return collection of registered scan results
     * @param {Array<string>} [q] query for search in user fields (e.g. rawData)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;deviceId:1&#x60;)
     * @param {Array<ScanResultExpand>} [expand] define distributed scan result expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanResultsApiInterface
     */
    scanResultList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ScanResultExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<ScanResultPage>;

    /**
     * Patch scan result instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {ScanResultFields} scanResultFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanResultsApiInterface
     */
    scanResultPatch(filter: Array<string>, scanResultFields: ScanResultFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Update scan result instance
     * @param {number} id scan result primary key to update
     * @param {ScanResultParams} scanResultParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanResultsApiInterface
     */
    scanResultUpdate(id: number, scanResultParams: ScanResultParams, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return vulnerabilities count by scan result id
     * @param {Array<string>} [q] query for search in user fields (e.g. deviceInfo)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;userId:1&#x60;)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanResultsApiInterface
     */
    scanResultVulnerabilitiesCount(q?: Array<string>, filter?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<VulnerabilitiesCount>;

}

/**
 * ScanResultsApi - object-oriented interface
 * @export
 * @class ScanResultsApi
 * @extends {BaseAPI}
 */
export class ScanResultsApi extends BaseAPI implements ScanResultsApiInterface {
    /**
     * Create single scan result instance
     * @param {ScanResultParams} scanResultParams scan result params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanResultsApi
     */
    public scanResultCreate(scanResultParams: ScanResultParams, options?: AxiosRequestConfig) {
        return ScanResultsApiFp(this.configuration).scanResultCreate(scanResultParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete scan result by its primary keys
     * @param {number} id scan result primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanResultsApi
     */
    public scanResultDelete(id: number, options?: AxiosRequestConfig) {
        return ScanResultsApiFp(this.configuration).scanResultDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single scan result instance
     * @param {number} id scan result primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanResultsApi
     */
    public scanResultGetById(id: number, options?: AxiosRequestConfig) {
        return ScanResultsApiFp(this.configuration).scanResultGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of registered scan results
     * @param {Array<string>} [q] query for search in user fields (e.g. rawData)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]timestamp&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;deviceId:1&#x60;)
     * @param {Array<ScanResultExpand>} [expand] define distributed scan result expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanResultsApi
     */
    public scanResultList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<ScanResultExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return ScanResultsApiFp(this.configuration).scanResultList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch scan result instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {ScanResultFields} scanResultFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanResultsApi
     */
    public scanResultPatch(filter: Array<string>, scanResultFields: ScanResultFields, options?: AxiosRequestConfig) {
        return ScanResultsApiFp(this.configuration).scanResultPatch(filter, scanResultFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update scan result instance
     * @param {number} id scan result primary key to update
     * @param {ScanResultParams} scanResultParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanResultsApi
     */
    public scanResultUpdate(id: number, scanResultParams: ScanResultParams, options?: AxiosRequestConfig) {
        return ScanResultsApiFp(this.configuration).scanResultUpdate(id, scanResultParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return vulnerabilities count by scan result id
     * @param {Array<string>} [q] query for search in user fields (e.g. deviceInfo)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;userId:1&#x60;)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanResultsApi
     */
    public scanResultVulnerabilitiesCount(q?: Array<string>, filter?: Array<string>, options?: AxiosRequestConfig) {
        return ScanResultsApiFp(this.configuration).scanResultVulnerabilitiesCount(q, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaskApi - axios parameter creator
 * @export
 */
export const TaskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single task instance
         * @param {TasksParams} tasksParams task params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskCreate: async (tasksParams: TasksParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tasksParams' is not null or undefined
            assertParamExists('taskCreate', 'tasksParams', tasksParams)
            const localVarPath = `/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tasksParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete task by its primary key
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taskDelete', 'id', id)
            const localVarPath = `/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single task result instance
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taskGetById', 'id', id)
            const localVarPath = `/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of tasks
         * @param {Array<string>} [q] query for search in task fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<TaskExpand>} [expand] define task expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TaskExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch task instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TasksFields} tasksFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskPatch: async (filter: Array<string>, tasksFields: TasksFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('taskPatch', 'filter', filter)
            // verify required parameter 'tasksFields' is not null or undefined
            assertParamExists('taskPatch', 'tasksFields', tasksFields)
            const localVarPath = `/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tasksFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return status of tasks
         * @param {Array<string>} [q] query for search in task fields (e.g. firstName, lastName, email)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskStatus: async (q?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tasks/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update task instance
         * @param {number} id -- task primary key
         * @param {TasksParams} tasksParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskUpdate: async (id: number, tasksParams: TasksParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taskUpdate', 'id', id)
            // verify required parameter 'tasksParams' is not null or undefined
            assertParamExists('taskUpdate', 'tasksParams', tasksParams)
            const localVarPath = `/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tasksParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskApi - functional programming interface
 * @export
 */
export const TaskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaskApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single task instance
         * @param {TasksParams} tasksParams task params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskCreate(tasksParams: TasksParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20120>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskCreate(tasksParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete task by its primary key
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single task result instance
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of tasks
         * @param {Array<string>} [q] query for search in task fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<TaskExpand>} [expand] define task expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TaskExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TasksPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch task instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TasksFields} tasksFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskPatch(filter: Array<string>, tasksFields: TasksFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskPatch(filter, tasksFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return status of tasks
         * @param {Array<string>} [q] query for search in task fields (e.g. firstName, lastName, email)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskStatus(q?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskStatusPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskStatus(q, filter, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update task instance
         * @param {number} id -- task primary key
         * @param {TasksParams} tasksParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskUpdate(id: number, tasksParams: TasksParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskUpdate(id, tasksParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaskApi - factory interface
 * @export
 */
export const TaskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaskApiFp(configuration)
    return {
        /**
         * Create single task instance
         * @param {TasksParams} tasksParams task params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskCreate(tasksParams: TasksParams, options?: any): AxiosPromise<InlineResponse20120> {
            return localVarFp.taskCreate(tasksParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete task by its primary key
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.taskDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single task result instance
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskGetById(id: number, options?: any): AxiosPromise<Task> {
            return localVarFp.taskGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of tasks
         * @param {Array<string>} [q] query for search in task fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<TaskExpand>} [expand] define task expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TaskExpand>, page?: number, limit?: number, options?: any): AxiosPromise<TasksPage> {
            return localVarFp.taskList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch task instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TasksFields} tasksFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskPatch(filter: Array<string>, tasksFields: TasksFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.taskPatch(filter, tasksFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Return status of tasks
         * @param {Array<string>} [q] query for search in task fields (e.g. firstName, lastName, email)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskStatus(q?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: any): AxiosPromise<TaskStatusPage> {
            return localVarFp.taskStatus(q, filter, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Update task instance
         * @param {number} id -- task primary key
         * @param {TasksParams} tasksParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskUpdate(id: number, tasksParams: TasksParams, options?: any): AxiosPromise<void> {
            return localVarFp.taskUpdate(id, tasksParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaskApi - interface
 * @export
 * @interface TaskApi
 */
export interface TaskApiInterface {
    /**
     * Create single task instance
     * @param {TasksParams} tasksParams task params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApiInterface
     */
    taskCreate(tasksParams: TasksParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse20120>;

    /**
     * Delete task by its primary key
     * @param {number} id task primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApiInterface
     */
    taskDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return single task result instance
     * @param {number} id task primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApiInterface
     */
    taskGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<Task>;

    /**
     * Return collection of tasks
     * @param {Array<string>} [q] query for search in task fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<TaskExpand>} [expand] define task expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApiInterface
     */
    taskList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TaskExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<TasksPage>;

    /**
     * Patch task instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {TasksFields} tasksFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApiInterface
     */
    taskPatch(filter: Array<string>, tasksFields: TasksFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Return status of tasks
     * @param {Array<string>} [q] query for search in task fields (e.g. firstName, lastName, email)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApiInterface
     */
    taskStatus(q?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<TaskStatusPage>;

    /**
     * Update task instance
     * @param {number} id -- task primary key
     * @param {TasksParams} tasksParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApiInterface
     */
    taskUpdate(id: number, tasksParams: TasksParams, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * TaskApi - object-oriented interface
 * @export
 * @class TaskApi
 * @extends {BaseAPI}
 */
export class TaskApi extends BaseAPI implements TaskApiInterface {
    /**
     * Create single task instance
     * @param {TasksParams} tasksParams task params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public taskCreate(tasksParams: TasksParams, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).taskCreate(tasksParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete task by its primary key
     * @param {number} id task primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public taskDelete(id: number, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).taskDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single task result instance
     * @param {number} id task primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public taskGetById(id: number, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).taskGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of tasks
     * @param {Array<string>} [q] query for search in task fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<TaskExpand>} [expand] define task expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public taskList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TaskExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).taskList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch task instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {TasksFields} tasksFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public taskPatch(filter: Array<string>, tasksFields: TasksFields, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).taskPatch(filter, tasksFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return status of tasks
     * @param {Array<string>} [q] query for search in task fields (e.g. firstName, lastName, email)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public taskStatus(q?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).taskStatus(q, filter, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update task instance
     * @param {number} id -- task primary key
     * @param {TasksParams} tasksParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public taskUpdate(id: number, tasksParams: TasksParams, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).taskUpdate(id, tasksParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaskCommentApi - axios parameter creator
 * @export
 */
export const TaskCommentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single task instance
         * @param {TaskCommentsParams} taskCommentsParams task params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskCommentCreate: async (taskCommentsParams: TaskCommentsParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskCommentsParams' is not null or undefined
            assertParamExists('taskCommentCreate', 'taskCommentsParams', taskCommentsParams)
            const localVarPath = `/task-comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskCommentsParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete task by its primary key
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskCommentDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taskCommentDelete', 'id', id)
            const localVarPath = `/task-comments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single task result instance
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskCommentGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taskCommentGetById', 'id', id)
            const localVarPath = `/task-comments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of task comments
         * @param {Array<string>} [q] query for search in task fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<TaskCommentExpand>} [expand] define task expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskCommentList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TaskCommentExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/task-comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch task instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TaskCommentsFields} taskCommentsFields 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskCommentPatch: async (filter: Array<string>, taskCommentsFields: TaskCommentsFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('taskCommentPatch', 'filter', filter)
            // verify required parameter 'taskCommentsFields' is not null or undefined
            assertParamExists('taskCommentPatch', 'taskCommentsFields', taskCommentsFields)
            const localVarPath = `/task-comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskCommentsFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update task instance
         * @param {number} id -- task primary key
         * @param {TaskCommentsParams} taskCommentsParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskCommentUpdate: async (id: number, taskCommentsParams: TaskCommentsParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taskCommentUpdate', 'id', id)
            // verify required parameter 'taskCommentsParams' is not null or undefined
            assertParamExists('taskCommentUpdate', 'taskCommentsParams', taskCommentsParams)
            const localVarPath = `/task-comments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskCommentsParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskCommentApi - functional programming interface
 * @export
 */
export const TaskCommentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaskCommentApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single task instance
         * @param {TaskCommentsParams} taskCommentsParams task params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskCommentCreate(taskCommentsParams: TaskCommentsParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20117>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskCommentCreate(taskCommentsParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete task by its primary key
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskCommentDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskCommentDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single task result instance
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskCommentGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskCommentGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of task comments
         * @param {Array<string>} [q] query for search in task fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<TaskCommentExpand>} [expand] define task expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskCommentList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TaskCommentExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskCommentsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskCommentList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch task instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TaskCommentsFields} taskCommentsFields 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskCommentPatch(filter: Array<string>, taskCommentsFields: TaskCommentsFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskCommentPatch(filter, taskCommentsFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update task instance
         * @param {number} id -- task primary key
         * @param {TaskCommentsParams} taskCommentsParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskCommentUpdate(id: number, taskCommentsParams: TaskCommentsParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskCommentUpdate(id, taskCommentsParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaskCommentApi - factory interface
 * @export
 */
export const TaskCommentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaskCommentApiFp(configuration)
    return {
        /**
         * Create single task instance
         * @param {TaskCommentsParams} taskCommentsParams task params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskCommentCreate(taskCommentsParams: TaskCommentsParams, options?: any): AxiosPromise<InlineResponse20117> {
            return localVarFp.taskCommentCreate(taskCommentsParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete task by its primary key
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskCommentDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.taskCommentDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single task result instance
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskCommentGetById(id: number, options?: any): AxiosPromise<TaskComment> {
            return localVarFp.taskCommentGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of task comments
         * @param {Array<string>} [q] query for search in task fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<TaskCommentExpand>} [expand] define task expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskCommentList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TaskCommentExpand>, page?: number, limit?: number, options?: any): AxiosPromise<TaskCommentsPage> {
            return localVarFp.taskCommentList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch task instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TaskCommentsFields} taskCommentsFields 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskCommentPatch(filter: Array<string>, taskCommentsFields: TaskCommentsFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.taskCommentPatch(filter, taskCommentsFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Update task instance
         * @param {number} id -- task primary key
         * @param {TaskCommentsParams} taskCommentsParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskCommentUpdate(id: number, taskCommentsParams: TaskCommentsParams, options?: any): AxiosPromise<void> {
            return localVarFp.taskCommentUpdate(id, taskCommentsParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaskCommentApi - interface
 * @export
 * @interface TaskCommentApi
 */
export interface TaskCommentApiInterface {
    /**
     * Create single task instance
     * @param {TaskCommentsParams} taskCommentsParams task params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskCommentApiInterface
     */
    taskCommentCreate(taskCommentsParams: TaskCommentsParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse20117>;

    /**
     * Delete task by its primary key
     * @param {number} id task primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskCommentApiInterface
     */
    taskCommentDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return single task result instance
     * @param {number} id task primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskCommentApiInterface
     */
    taskCommentGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<TaskComment>;

    /**
     * Return collection of task comments
     * @param {Array<string>} [q] query for search in task fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<TaskCommentExpand>} [expand] define task expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskCommentApiInterface
     */
    taskCommentList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TaskCommentExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<TaskCommentsPage>;

    /**
     * Patch task instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {TaskCommentsFields} taskCommentsFields 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskCommentApiInterface
     */
    taskCommentPatch(filter: Array<string>, taskCommentsFields: TaskCommentsFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Update task instance
     * @param {number} id -- task primary key
     * @param {TaskCommentsParams} taskCommentsParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskCommentApiInterface
     */
    taskCommentUpdate(id: number, taskCommentsParams: TaskCommentsParams, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * TaskCommentApi - object-oriented interface
 * @export
 * @class TaskCommentApi
 * @extends {BaseAPI}
 */
export class TaskCommentApi extends BaseAPI implements TaskCommentApiInterface {
    /**
     * Create single task instance
     * @param {TaskCommentsParams} taskCommentsParams task params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskCommentApi
     */
    public taskCommentCreate(taskCommentsParams: TaskCommentsParams, options?: AxiosRequestConfig) {
        return TaskCommentApiFp(this.configuration).taskCommentCreate(taskCommentsParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete task by its primary key
     * @param {number} id task primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskCommentApi
     */
    public taskCommentDelete(id: number, options?: AxiosRequestConfig) {
        return TaskCommentApiFp(this.configuration).taskCommentDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single task result instance
     * @param {number} id task primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskCommentApi
     */
    public taskCommentGetById(id: number, options?: AxiosRequestConfig) {
        return TaskCommentApiFp(this.configuration).taskCommentGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of task comments
     * @param {Array<string>} [q] query for search in task fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<TaskCommentExpand>} [expand] define task expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskCommentApi
     */
    public taskCommentList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TaskCommentExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return TaskCommentApiFp(this.configuration).taskCommentList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch task instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {TaskCommentsFields} taskCommentsFields 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskCommentApi
     */
    public taskCommentPatch(filter: Array<string>, taskCommentsFields: TaskCommentsFields, options?: AxiosRequestConfig) {
        return TaskCommentApiFp(this.configuration).taskCommentPatch(filter, taskCommentsFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update task instance
     * @param {number} id -- task primary key
     * @param {TaskCommentsParams} taskCommentsParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskCommentApi
     */
    public taskCommentUpdate(id: number, taskCommentsParams: TaskCommentsParams, options?: AxiosRequestConfig) {
        return TaskCommentApiFp(this.configuration).taskCommentUpdate(id, taskCommentsParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaskPhaseApi - axios parameter creator
 * @export
 */
export const TaskPhaseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single task phase instance
         * @param {TaskPhasesParams} taskPhasesParams task phase params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskPhaseCreate: async (taskPhasesParams: TaskPhasesParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskPhasesParams' is not null or undefined
            assertParamExists('taskPhaseCreate', 'taskPhasesParams', taskPhasesParams)
            const localVarPath = `/task-phase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskPhasesParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete task phase by its primary key
         * @param {number} id task phase primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskPhaseDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taskPhaseDelete', 'id', id)
            const localVarPath = `/task-phase/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single task phase result instance
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskPhaseGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taskPhaseGetById', 'id', id)
            const localVarPath = `/task-phase/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of task phases
         * @param {Array<string>} [q] query for search in task fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<TaskPhaseExpand>} [expand] define task expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskPhaseList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TaskPhaseExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/task-phase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch task phase instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TaskPhasesFields} taskPhasesFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskPhasePatch: async (filter: Array<string>, taskPhasesFields: TaskPhasesFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('taskPhasePatch', 'filter', filter)
            // verify required parameter 'taskPhasesFields' is not null or undefined
            assertParamExists('taskPhasePatch', 'taskPhasesFields', taskPhasesFields)
            const localVarPath = `/task-phase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskPhasesFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update task phase instance
         * @param {number} id -- task primary key
         * @param {TaskPhasesParams} taskPhasesParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskPhaseUpdate: async (id: number, taskPhasesParams: TaskPhasesParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taskPhaseUpdate', 'id', id)
            // verify required parameter 'taskPhasesParams' is not null or undefined
            assertParamExists('taskPhaseUpdate', 'taskPhasesParams', taskPhasesParams)
            const localVarPath = `/task-phase/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskPhasesParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskPhaseApi - functional programming interface
 * @export
 */
export const TaskPhaseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaskPhaseApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single task phase instance
         * @param {TaskPhasesParams} taskPhasesParams task phase params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskPhaseCreate(taskPhasesParams: TaskPhasesParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20118>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskPhaseCreate(taskPhasesParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete task phase by its primary key
         * @param {number} id task phase primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskPhaseDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskPhaseDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single task phase result instance
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskPhaseGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskPhase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskPhaseGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of task phases
         * @param {Array<string>} [q] query for search in task fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<TaskPhaseExpand>} [expand] define task expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskPhaseList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TaskPhaseExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskPhasesPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskPhaseList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch task phase instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TaskPhasesFields} taskPhasesFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskPhasePatch(filter: Array<string>, taskPhasesFields: TaskPhasesFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskPhasePatch(filter, taskPhasesFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update task phase instance
         * @param {number} id -- task primary key
         * @param {TaskPhasesParams} taskPhasesParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskPhaseUpdate(id: number, taskPhasesParams: TaskPhasesParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskPhaseUpdate(id, taskPhasesParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaskPhaseApi - factory interface
 * @export
 */
export const TaskPhaseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaskPhaseApiFp(configuration)
    return {
        /**
         * Create single task phase instance
         * @param {TaskPhasesParams} taskPhasesParams task phase params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskPhaseCreate(taskPhasesParams: TaskPhasesParams, options?: any): AxiosPromise<InlineResponse20118> {
            return localVarFp.taskPhaseCreate(taskPhasesParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete task phase by its primary key
         * @param {number} id task phase primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskPhaseDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.taskPhaseDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single task phase result instance
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskPhaseGetById(id: number, options?: any): AxiosPromise<TaskPhase> {
            return localVarFp.taskPhaseGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of task phases
         * @param {Array<string>} [q] query for search in task fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<TaskPhaseExpand>} [expand] define task expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskPhaseList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TaskPhaseExpand>, page?: number, limit?: number, options?: any): AxiosPromise<TaskPhasesPage> {
            return localVarFp.taskPhaseList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch task phase instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TaskPhasesFields} taskPhasesFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskPhasePatch(filter: Array<string>, taskPhasesFields: TaskPhasesFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.taskPhasePatch(filter, taskPhasesFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Update task phase instance
         * @param {number} id -- task primary key
         * @param {TaskPhasesParams} taskPhasesParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskPhaseUpdate(id: number, taskPhasesParams: TaskPhasesParams, options?: any): AxiosPromise<void> {
            return localVarFp.taskPhaseUpdate(id, taskPhasesParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaskPhaseApi - interface
 * @export
 * @interface TaskPhaseApi
 */
export interface TaskPhaseApiInterface {
    /**
     * Create single task phase instance
     * @param {TaskPhasesParams} taskPhasesParams task phase params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskPhaseApiInterface
     */
    taskPhaseCreate(taskPhasesParams: TaskPhasesParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse20118>;

    /**
     * Delete task phase by its primary key
     * @param {number} id task phase primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskPhaseApiInterface
     */
    taskPhaseDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return single task phase result instance
     * @param {number} id task primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskPhaseApiInterface
     */
    taskPhaseGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<TaskPhase>;

    /**
     * Return collection of task phases
     * @param {Array<string>} [q] query for search in task fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<TaskPhaseExpand>} [expand] define task expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskPhaseApiInterface
     */
    taskPhaseList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TaskPhaseExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<TaskPhasesPage>;

    /**
     * Patch task phase instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {TaskPhasesFields} taskPhasesFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskPhaseApiInterface
     */
    taskPhasePatch(filter: Array<string>, taskPhasesFields: TaskPhasesFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Update task phase instance
     * @param {number} id -- task primary key
     * @param {TaskPhasesParams} taskPhasesParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskPhaseApiInterface
     */
    taskPhaseUpdate(id: number, taskPhasesParams: TaskPhasesParams, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * TaskPhaseApi - object-oriented interface
 * @export
 * @class TaskPhaseApi
 * @extends {BaseAPI}
 */
export class TaskPhaseApi extends BaseAPI implements TaskPhaseApiInterface {
    /**
     * Create single task phase instance
     * @param {TaskPhasesParams} taskPhasesParams task phase params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskPhaseApi
     */
    public taskPhaseCreate(taskPhasesParams: TaskPhasesParams, options?: AxiosRequestConfig) {
        return TaskPhaseApiFp(this.configuration).taskPhaseCreate(taskPhasesParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete task phase by its primary key
     * @param {number} id task phase primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskPhaseApi
     */
    public taskPhaseDelete(id: number, options?: AxiosRequestConfig) {
        return TaskPhaseApiFp(this.configuration).taskPhaseDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single task phase result instance
     * @param {number} id task primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskPhaseApi
     */
    public taskPhaseGetById(id: number, options?: AxiosRequestConfig) {
        return TaskPhaseApiFp(this.configuration).taskPhaseGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of task phases
     * @param {Array<string>} [q] query for search in task fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<TaskPhaseExpand>} [expand] define task expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskPhaseApi
     */
    public taskPhaseList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TaskPhaseExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return TaskPhaseApiFp(this.configuration).taskPhaseList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch task phase instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {TaskPhasesFields} taskPhasesFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskPhaseApi
     */
    public taskPhasePatch(filter: Array<string>, taskPhasesFields: TaskPhasesFields, options?: AxiosRequestConfig) {
        return TaskPhaseApiFp(this.configuration).taskPhasePatch(filter, taskPhasesFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update task phase instance
     * @param {number} id -- task primary key
     * @param {TaskPhasesParams} taskPhasesParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskPhaseApi
     */
    public taskPhaseUpdate(id: number, taskPhasesParams: TaskPhasesParams, options?: AxiosRequestConfig) {
        return TaskPhaseApiFp(this.configuration).taskPhaseUpdate(id, taskPhasesParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TasksLinkageApi - axios parameter creator
 * @export
 */
export const TasksLinkageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single tasks linkage instance
         * @param {TasksLinkageParams} tasksLinkageParams tasks linkage params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksLinkageCreate: async (tasksLinkageParams: TasksLinkageParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tasksLinkageParams' is not null or undefined
            assertParamExists('tasksLinkageCreate', 'tasksLinkageParams', tasksLinkageParams)
            const localVarPath = `/tasks-linkage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tasksLinkageParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete tasks linkage by its primary key
         * @param {number} id tasks linkage primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksLinkageDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tasksLinkageDelete', 'id', id)
            const localVarPath = `/tasks-linkage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete tasks linkage by its primary key
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksLinkageDeleteMany: async (filter: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('tasksLinkageDeleteMany', 'filter', filter)
            const localVarPath = `/tasks-linkage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single tasks linkage result instance
         * @param {number} id tasks linkage primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksLinkageGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tasksLinkageGetById', 'id', id)
            const localVarPath = `/tasks-linkage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of tasks linkage
         * @param {Array<string>} [q] query for search in tasks linkage fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<TasksLinkageExpand>} [expand] define tasks linkage expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksLinkageList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TasksLinkageExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tasks-linkage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch tasks linkage instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TasksLinkageFields} tasksLinkageFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksLinkagePatch: async (filter: Array<string>, tasksLinkageFields: TasksLinkageFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('tasksLinkagePatch', 'filter', filter)
            // verify required parameter 'tasksLinkageFields' is not null or undefined
            assertParamExists('tasksLinkagePatch', 'tasksLinkageFields', tasksLinkageFields)
            const localVarPath = `/tasks-linkage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tasksLinkageFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update tasks linkages instance
         * @param {number} id -- tasks linkage primary key
         * @param {TasksLinkageParams} tasksLinkageParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksLinkageUpdate: async (id: number, tasksLinkageParams: TasksLinkageParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('tasksLinkageUpdate', 'id', id)
            // verify required parameter 'tasksLinkageParams' is not null or undefined
            assertParamExists('tasksLinkageUpdate', 'tasksLinkageParams', tasksLinkageParams)
            const localVarPath = `/tasks-linkage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tasksLinkageParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TasksLinkageApi - functional programming interface
 * @export
 */
export const TasksLinkageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TasksLinkageApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single tasks linkage instance
         * @param {TasksLinkageParams} tasksLinkageParams tasks linkage params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksLinkageCreate(tasksLinkageParams: TasksLinkageParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20119>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksLinkageCreate(tasksLinkageParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete tasks linkage by its primary key
         * @param {number} id tasks linkage primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksLinkageDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksLinkageDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete tasks linkage by its primary key
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksLinkageDeleteMany(filter: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksLinkageDeleteMany(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single tasks linkage result instance
         * @param {number} id tasks linkage primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksLinkageGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TasksLinkage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksLinkageGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of tasks linkage
         * @param {Array<string>} [q] query for search in tasks linkage fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<TasksLinkageExpand>} [expand] define tasks linkage expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksLinkageList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TasksLinkageExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TasksLinkagePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksLinkageList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch tasks linkage instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TasksLinkageFields} tasksLinkageFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksLinkagePatch(filter: Array<string>, tasksLinkageFields: TasksLinkageFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksLinkagePatch(filter, tasksLinkageFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update tasks linkages instance
         * @param {number} id -- tasks linkage primary key
         * @param {TasksLinkageParams} tasksLinkageParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksLinkageUpdate(id: number, tasksLinkageParams: TasksLinkageParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksLinkageUpdate(id, tasksLinkageParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TasksLinkageApi - factory interface
 * @export
 */
export const TasksLinkageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TasksLinkageApiFp(configuration)
    return {
        /**
         * Create single tasks linkage instance
         * @param {TasksLinkageParams} tasksLinkageParams tasks linkage params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksLinkageCreate(tasksLinkageParams: TasksLinkageParams, options?: any): AxiosPromise<InlineResponse20119> {
            return localVarFp.tasksLinkageCreate(tasksLinkageParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete tasks linkage by its primary key
         * @param {number} id tasks linkage primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksLinkageDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.tasksLinkageDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete tasks linkage by its primary key
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksLinkageDeleteMany(filter: Array<string>, options?: any): AxiosPromise<boolean> {
            return localVarFp.tasksLinkageDeleteMany(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single tasks linkage result instance
         * @param {number} id tasks linkage primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksLinkageGetById(id: number, options?: any): AxiosPromise<TasksLinkage> {
            return localVarFp.tasksLinkageGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of tasks linkage
         * @param {Array<string>} [q] query for search in tasks linkage fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<TasksLinkageExpand>} [expand] define tasks linkage expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksLinkageList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TasksLinkageExpand>, page?: number, limit?: number, options?: any): AxiosPromise<TasksLinkagePage> {
            return localVarFp.tasksLinkageList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch tasks linkage instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TasksLinkageFields} tasksLinkageFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksLinkagePatch(filter: Array<string>, tasksLinkageFields: TasksLinkageFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.tasksLinkagePatch(filter, tasksLinkageFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Update tasks linkages instance
         * @param {number} id -- tasks linkage primary key
         * @param {TasksLinkageParams} tasksLinkageParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksLinkageUpdate(id: number, tasksLinkageParams: TasksLinkageParams, options?: any): AxiosPromise<void> {
            return localVarFp.tasksLinkageUpdate(id, tasksLinkageParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TasksLinkageApi - interface
 * @export
 * @interface TasksLinkageApi
 */
export interface TasksLinkageApiInterface {
    /**
     * Create single tasks linkage instance
     * @param {TasksLinkageParams} tasksLinkageParams tasks linkage params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksLinkageApiInterface
     */
    tasksLinkageCreate(tasksLinkageParams: TasksLinkageParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse20119>;

    /**
     * Delete tasks linkage by its primary key
     * @param {number} id tasks linkage primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksLinkageApiInterface
     */
    tasksLinkageDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Delete tasks linkage by its primary key
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksLinkageApiInterface
     */
    tasksLinkageDeleteMany(filter: Array<string>, options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * Return single tasks linkage result instance
     * @param {number} id tasks linkage primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksLinkageApiInterface
     */
    tasksLinkageGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<TasksLinkage>;

    /**
     * Return collection of tasks linkage
     * @param {Array<string>} [q] query for search in tasks linkage fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<TasksLinkageExpand>} [expand] define tasks linkage expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksLinkageApiInterface
     */
    tasksLinkageList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TasksLinkageExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<TasksLinkagePage>;

    /**
     * Patch tasks linkage instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {TasksLinkageFields} tasksLinkageFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksLinkageApiInterface
     */
    tasksLinkagePatch(filter: Array<string>, tasksLinkageFields: TasksLinkageFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Update tasks linkages instance
     * @param {number} id -- tasks linkage primary key
     * @param {TasksLinkageParams} tasksLinkageParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksLinkageApiInterface
     */
    tasksLinkageUpdate(id: number, tasksLinkageParams: TasksLinkageParams, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * TasksLinkageApi - object-oriented interface
 * @export
 * @class TasksLinkageApi
 * @extends {BaseAPI}
 */
export class TasksLinkageApi extends BaseAPI implements TasksLinkageApiInterface {
    /**
     * Create single tasks linkage instance
     * @param {TasksLinkageParams} tasksLinkageParams tasks linkage params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksLinkageApi
     */
    public tasksLinkageCreate(tasksLinkageParams: TasksLinkageParams, options?: AxiosRequestConfig) {
        return TasksLinkageApiFp(this.configuration).tasksLinkageCreate(tasksLinkageParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete tasks linkage by its primary key
     * @param {number} id tasks linkage primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksLinkageApi
     */
    public tasksLinkageDelete(id: number, options?: AxiosRequestConfig) {
        return TasksLinkageApiFp(this.configuration).tasksLinkageDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete tasks linkage by its primary key
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksLinkageApi
     */
    public tasksLinkageDeleteMany(filter: Array<string>, options?: AxiosRequestConfig) {
        return TasksLinkageApiFp(this.configuration).tasksLinkageDeleteMany(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single tasks linkage result instance
     * @param {number} id tasks linkage primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksLinkageApi
     */
    public tasksLinkageGetById(id: number, options?: AxiosRequestConfig) {
        return TasksLinkageApiFp(this.configuration).tasksLinkageGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of tasks linkage
     * @param {Array<string>} [q] query for search in tasks linkage fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<TasksLinkageExpand>} [expand] define tasks linkage expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksLinkageApi
     */
    public tasksLinkageList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TasksLinkageExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return TasksLinkageApiFp(this.configuration).tasksLinkageList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch tasks linkage instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {TasksLinkageFields} tasksLinkageFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksLinkageApi
     */
    public tasksLinkagePatch(filter: Array<string>, tasksLinkageFields: TasksLinkageFields, options?: AxiosRequestConfig) {
        return TasksLinkageApiFp(this.configuration).tasksLinkagePatch(filter, tasksLinkageFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update tasks linkages instance
     * @param {number} id -- tasks linkage primary key
     * @param {TasksLinkageParams} tasksLinkageParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksLinkageApi
     */
    public tasksLinkageUpdate(id: number, tasksLinkageParams: TasksLinkageParams, options?: AxiosRequestConfig) {
        return TasksLinkageApiFp(this.configuration).tasksLinkageUpdate(id, tasksLinkageParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TemplateApi - axios parameter creator
 * @export
 */
export const TemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return forms list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/templates/forms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Added response from typeform
         * @param {string} projectId id of project to update
         * @param {string} formId id of submitted form
         * @param {string} responseId response id of form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveResponse: async (projectId: string, formId: string, responseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('saveResponse', 'projectId', projectId)
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('saveResponse', 'formId', formId)
            // verify required parameter 'responseId' is not null or undefined
            assertParamExists('saveResponse', 'responseId', responseId)
            const localVarPath = `/templates/saveResponse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (formId !== undefined) {
                localVarQueryParameter['formId'] = formId;
            }

            if (responseId !== undefined) {
                localVarQueryParameter['responseId'] = responseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create single template instance
         * @param {TemplateParams} templateParams template params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateCreate: async (templateParams: TemplateParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateParams' is not null or undefined
            assertParamExists('templateCreate', 'templateParams', templateParams)
            const localVarPath = `/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete template by its primary key
         * @param {number} id template primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('templateDelete', 'id', id)
            const localVarPath = `/templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single template result instance
         * @param {number} id template primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('templateGetById', 'id', id)
            const localVarPath = `/templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of templates
         * @param {Array<string>} [q] query for search in template fields (e.g. title)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;active:true&#x60;)
         * @param {Array<TemplateExpand>} [expand] define template expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TemplateExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch template instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TemplateFields} templateFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templatePatch: async (filter: Array<string>, templateFields: TemplateFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('templatePatch', 'filter', filter)
            // verify required parameter 'templateFields' is not null or undefined
            assertParamExists('templatePatch', 'templateFields', templateFields)
            const localVarPath = `/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update template instance
         * @param {number} id -- template primary key
         * @param {TemplateParams} templateParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateUpdate: async (id: number, templateParams: TemplateParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('templateUpdate', 'id', id)
            // verify required parameter 'templateParams' is not null or undefined
            assertParamExists('templateUpdate', 'templateParams', templateParams)
            const localVarPath = `/templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplateApi - functional programming interface
 * @export
 */
export const TemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * Return forms list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async formsList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.formsList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Added response from typeform
         * @param {string} projectId id of project to update
         * @param {string} formId id of submitted form
         * @param {string} responseId response id of form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveResponse(projectId: string, formId: string, responseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveResponse(projectId, formId, responseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create single template instance
         * @param {TemplateParams} templateParams template params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateCreate(templateParams: TemplateParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20122>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateCreate(templateParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete template by its primary key
         * @param {number} id template primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single template result instance
         * @param {number} id template primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Template>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of templates
         * @param {Array<string>} [q] query for search in template fields (e.g. title)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;active:true&#x60;)
         * @param {Array<TemplateExpand>} [expand] define template expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TemplateExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplatesPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch template instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TemplateFields} templateFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templatePatch(filter: Array<string>, templateFields: TemplateFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templatePatch(filter, templateFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update template instance
         * @param {number} id -- template primary key
         * @param {TemplateParams} templateParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateUpdate(id: number, templateParams: TemplateParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateUpdate(id, templateParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TemplateApi - factory interface
 * @export
 */
export const TemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplateApiFp(configuration)
    return {
        /**
         * Return forms list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsList(options?: any): AxiosPromise<any> {
            return localVarFp.formsList(options).then((request) => request(axios, basePath));
        },
        /**
         * Added response from typeform
         * @param {string} projectId id of project to update
         * @param {string} formId id of submitted form
         * @param {string} responseId response id of form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveResponse(projectId: string, formId: string, responseId: string, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.saveResponse(projectId, formId, responseId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create single template instance
         * @param {TemplateParams} templateParams template params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateCreate(templateParams: TemplateParams, options?: any): AxiosPromise<InlineResponse20122> {
            return localVarFp.templateCreate(templateParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete template by its primary key
         * @param {number} id template primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.templateDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single template result instance
         * @param {number} id template primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateGetById(id: number, options?: any): AxiosPromise<Template> {
            return localVarFp.templateGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of templates
         * @param {Array<string>} [q] query for search in template fields (e.g. title)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;active:true&#x60;)
         * @param {Array<TemplateExpand>} [expand] define template expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TemplateExpand>, page?: number, limit?: number, options?: any): AxiosPromise<TemplatesPage> {
            return localVarFp.templateList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch template instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TemplateFields} templateFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templatePatch(filter: Array<string>, templateFields: TemplateFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.templatePatch(filter, templateFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Update template instance
         * @param {number} id -- template primary key
         * @param {TemplateParams} templateParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateUpdate(id: number, templateParams: TemplateParams, options?: any): AxiosPromise<void> {
            return localVarFp.templateUpdate(id, templateParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplateApi - interface
 * @export
 * @interface TemplateApi
 */
export interface TemplateApiInterface {
    /**
     * Return forms list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApiInterface
     */
    formsList(options?: AxiosRequestConfig): AxiosPromise<any>;

    /**
     * Added response from typeform
     * @param {string} projectId id of project to update
     * @param {string} formId id of submitted form
     * @param {string} responseId response id of form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApiInterface
     */
    saveResponse(projectId: string, formId: string, responseId: string, options?: AxiosRequestConfig): AxiosPromise<Array<number>>;

    /**
     * Create single template instance
     * @param {TemplateParams} templateParams template params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApiInterface
     */
    templateCreate(templateParams: TemplateParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse20122>;

    /**
     * Delete template by its primary key
     * @param {number} id template primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApiInterface
     */
    templateDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return single template result instance
     * @param {number} id template primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApiInterface
     */
    templateGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<Template>;

    /**
     * Return collection of templates
     * @param {Array<string>} [q] query for search in template fields (e.g. title)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;active:true&#x60;)
     * @param {Array<TemplateExpand>} [expand] define template expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApiInterface
     */
    templateList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TemplateExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<TemplatesPage>;

    /**
     * Patch template instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {TemplateFields} templateFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApiInterface
     */
    templatePatch(filter: Array<string>, templateFields: TemplateFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Update template instance
     * @param {number} id -- template primary key
     * @param {TemplateParams} templateParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApiInterface
     */
    templateUpdate(id: number, templateParams: TemplateParams, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * TemplateApi - object-oriented interface
 * @export
 * @class TemplateApi
 * @extends {BaseAPI}
 */
export class TemplateApi extends BaseAPI implements TemplateApiInterface {
    /**
     * Return forms list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public formsList(options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).formsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Added response from typeform
     * @param {string} projectId id of project to update
     * @param {string} formId id of submitted form
     * @param {string} responseId response id of form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public saveResponse(projectId: string, formId: string, responseId: string, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).saveResponse(projectId, formId, responseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create single template instance
     * @param {TemplateParams} templateParams template params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public templateCreate(templateParams: TemplateParams, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).templateCreate(templateParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete template by its primary key
     * @param {number} id template primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public templateDelete(id: number, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).templateDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single template result instance
     * @param {number} id template primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public templateGetById(id: number, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).templateGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of templates
     * @param {Array<string>} [q] query for search in template fields (e.g. title)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;active:true&#x60;)
     * @param {Array<TemplateExpand>} [expand] define template expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public templateList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TemplateExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).templateList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch template instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {TemplateFields} templateFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public templatePatch(filter: Array<string>, templateFields: TemplateFields, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).templatePatch(filter, templateFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update template instance
     * @param {number} id -- template primary key
     * @param {TemplateParams} templateParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public templateUpdate(id: number, templateParams: TemplateParams, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).templateUpdate(id, templateParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TemplateTaskPhasesApi - axios parameter creator
 * @export
 */
export const TemplateTaskPhasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single task template phase instance
         * @param {TemplateTaskPhasesParams} templateTaskPhasesParams task template phase params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTaskPhasesCreate: async (templateTaskPhasesParams: TemplateTaskPhasesParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateTaskPhasesParams' is not null or undefined
            assertParamExists('templateTaskPhasesCreate', 'templateTaskPhasesParams', templateTaskPhasesParams)
            const localVarPath = `/template-task-phases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateTaskPhasesParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete task template phase by its primary key
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTaskPhasesDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('templateTaskPhasesDelete', 'id', id)
            const localVarPath = `/template-task-phases/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of template task phases
         * @param {Array<string>} [q] query for search in task templates fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<TemplateTaskPhaseExpand>} [expand] define task expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTaskPhasesList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TemplateTaskPhaseExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/template-task-phases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch task template phase instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TemplateTaskPhasesFields} templateTaskPhasesFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTaskPhasesPatch: async (filter: Array<string>, templateTaskPhasesFields: TemplateTaskPhasesFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('templateTaskPhasesPatch', 'filter', filter)
            // verify required parameter 'templateTaskPhasesFields' is not null or undefined
            assertParamExists('templateTaskPhasesPatch', 'templateTaskPhasesFields', templateTaskPhasesFields)
            const localVarPath = `/template-task-phases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateTaskPhasesFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update task template phase instance
         * @param {number} id -- task template phase primary key
         * @param {TemplateTaskPhasesParams} templateTaskPhasesParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTaskPhasesUpdate: async (id: number, templateTaskPhasesParams: TemplateTaskPhasesParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('templateTaskPhasesUpdate', 'id', id)
            // verify required parameter 'templateTaskPhasesParams' is not null or undefined
            assertParamExists('templateTaskPhasesUpdate', 'templateTaskPhasesParams', templateTaskPhasesParams)
            const localVarPath = `/template-task-phases/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateTaskPhasesParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single task template phase result instance
         * @param {number} id task template phase primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksPhaseGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('templateTasksPhaseGetById', 'id', id)
            const localVarPath = `/template-task-phases/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplateTaskPhasesApi - functional programming interface
 * @export
 */
export const TemplateTaskPhasesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplateTaskPhasesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single task template phase instance
         * @param {TemplateTaskPhasesParams} templateTaskPhasesParams task template phase params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateTaskPhasesCreate(templateTaskPhasesParams: TemplateTaskPhasesParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20118>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateTaskPhasesCreate(templateTaskPhasesParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete task template phase by its primary key
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateTaskPhasesDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateTaskPhasesDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of template task phases
         * @param {Array<string>} [q] query for search in task templates fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<TemplateTaskPhaseExpand>} [expand] define task expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateTaskPhasesList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TemplateTaskPhaseExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateTaskPhasesPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateTaskPhasesList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch task template phase instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TemplateTaskPhasesFields} templateTaskPhasesFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateTaskPhasesPatch(filter: Array<string>, templateTaskPhasesFields: TemplateTaskPhasesFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateTaskPhasesPatch(filter, templateTaskPhasesFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update task template phase instance
         * @param {number} id -- task template phase primary key
         * @param {TemplateTaskPhasesParams} templateTaskPhasesParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateTaskPhasesUpdate(id: number, templateTaskPhasesParams: TemplateTaskPhasesParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateTaskPhasesUpdate(id, templateTaskPhasesParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single task template phase result instance
         * @param {number} id task template phase primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateTasksPhaseGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateTaskPhase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateTasksPhaseGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TemplateTaskPhasesApi - factory interface
 * @export
 */
export const TemplateTaskPhasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplateTaskPhasesApiFp(configuration)
    return {
        /**
         * Create single task template phase instance
         * @param {TemplateTaskPhasesParams} templateTaskPhasesParams task template phase params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTaskPhasesCreate(templateTaskPhasesParams: TemplateTaskPhasesParams, options?: any): AxiosPromise<InlineResponse20118> {
            return localVarFp.templateTaskPhasesCreate(templateTaskPhasesParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete task template phase by its primary key
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTaskPhasesDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.templateTaskPhasesDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of template task phases
         * @param {Array<string>} [q] query for search in task templates fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<TemplateTaskPhaseExpand>} [expand] define task expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTaskPhasesList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TemplateTaskPhaseExpand>, page?: number, limit?: number, options?: any): AxiosPromise<TemplateTaskPhasesPage> {
            return localVarFp.templateTaskPhasesList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch task template phase instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TemplateTaskPhasesFields} templateTaskPhasesFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTaskPhasesPatch(filter: Array<string>, templateTaskPhasesFields: TemplateTaskPhasesFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.templateTaskPhasesPatch(filter, templateTaskPhasesFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Update task template phase instance
         * @param {number} id -- task template phase primary key
         * @param {TemplateTaskPhasesParams} templateTaskPhasesParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTaskPhasesUpdate(id: number, templateTaskPhasesParams: TemplateTaskPhasesParams, options?: any): AxiosPromise<void> {
            return localVarFp.templateTaskPhasesUpdate(id, templateTaskPhasesParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single task template phase result instance
         * @param {number} id task template phase primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksPhaseGetById(id: number, options?: any): AxiosPromise<TemplateTaskPhase> {
            return localVarFp.templateTasksPhaseGetById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplateTaskPhasesApi - interface
 * @export
 * @interface TemplateTaskPhasesApi
 */
export interface TemplateTaskPhasesApiInterface {
    /**
     * Create single task template phase instance
     * @param {TemplateTaskPhasesParams} templateTaskPhasesParams task template phase params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTaskPhasesApiInterface
     */
    templateTaskPhasesCreate(templateTaskPhasesParams: TemplateTaskPhasesParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse20118>;

    /**
     * Delete task template phase by its primary key
     * @param {number} id task primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTaskPhasesApiInterface
     */
    templateTaskPhasesDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return collection of template task phases
     * @param {Array<string>} [q] query for search in task templates fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<TemplateTaskPhaseExpand>} [expand] define task expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTaskPhasesApiInterface
     */
    templateTaskPhasesList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TemplateTaskPhaseExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<TemplateTaskPhasesPage>;

    /**
     * Patch task template phase instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {TemplateTaskPhasesFields} templateTaskPhasesFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTaskPhasesApiInterface
     */
    templateTaskPhasesPatch(filter: Array<string>, templateTaskPhasesFields: TemplateTaskPhasesFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Update task template phase instance
     * @param {number} id -- task template phase primary key
     * @param {TemplateTaskPhasesParams} templateTaskPhasesParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTaskPhasesApiInterface
     */
    templateTaskPhasesUpdate(id: number, templateTaskPhasesParams: TemplateTaskPhasesParams, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return single task template phase result instance
     * @param {number} id task template phase primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTaskPhasesApiInterface
     */
    templateTasksPhaseGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<TemplateTaskPhase>;

}

/**
 * TemplateTaskPhasesApi - object-oriented interface
 * @export
 * @class TemplateTaskPhasesApi
 * @extends {BaseAPI}
 */
export class TemplateTaskPhasesApi extends BaseAPI implements TemplateTaskPhasesApiInterface {
    /**
     * Create single task template phase instance
     * @param {TemplateTaskPhasesParams} templateTaskPhasesParams task template phase params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTaskPhasesApi
     */
    public templateTaskPhasesCreate(templateTaskPhasesParams: TemplateTaskPhasesParams, options?: AxiosRequestConfig) {
        return TemplateTaskPhasesApiFp(this.configuration).templateTaskPhasesCreate(templateTaskPhasesParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete task template phase by its primary key
     * @param {number} id task primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTaskPhasesApi
     */
    public templateTaskPhasesDelete(id: number, options?: AxiosRequestConfig) {
        return TemplateTaskPhasesApiFp(this.configuration).templateTaskPhasesDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of template task phases
     * @param {Array<string>} [q] query for search in task templates fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<TemplateTaskPhaseExpand>} [expand] define task expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTaskPhasesApi
     */
    public templateTaskPhasesList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TemplateTaskPhaseExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return TemplateTaskPhasesApiFp(this.configuration).templateTaskPhasesList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch task template phase instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {TemplateTaskPhasesFields} templateTaskPhasesFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTaskPhasesApi
     */
    public templateTaskPhasesPatch(filter: Array<string>, templateTaskPhasesFields: TemplateTaskPhasesFields, options?: AxiosRequestConfig) {
        return TemplateTaskPhasesApiFp(this.configuration).templateTaskPhasesPatch(filter, templateTaskPhasesFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update task template phase instance
     * @param {number} id -- task template phase primary key
     * @param {TemplateTaskPhasesParams} templateTaskPhasesParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTaskPhasesApi
     */
    public templateTaskPhasesUpdate(id: number, templateTaskPhasesParams: TemplateTaskPhasesParams, options?: AxiosRequestConfig) {
        return TemplateTaskPhasesApiFp(this.configuration).templateTaskPhasesUpdate(id, templateTaskPhasesParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single task template phase result instance
     * @param {number} id task template phase primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTaskPhasesApi
     */
    public templateTasksPhaseGetById(id: number, options?: AxiosRequestConfig) {
        return TemplateTaskPhasesApiFp(this.configuration).templateTasksPhaseGetById(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TemplateTasksApi - axios parameter creator
 * @export
 */
export const TemplateTasksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single task template instance
         * @param {TemplateTasksParams} templateTasksParams task params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksCreate: async (templateTasksParams: TemplateTasksParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateTasksParams' is not null or undefined
            assertParamExists('templateTasksCreate', 'templateTasksParams', templateTasksParams)
            const localVarPath = `/template-tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateTasksParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete task template by its primary key
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('templateTasksDelete', 'id', id)
            const localVarPath = `/template-tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single task template result instance
         * @param {number} id task template primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('templateTasksGetById', 'id', id)
            const localVarPath = `/template-tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of tasks
         * @param {Array<string>} [q] query for search in task templates fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<TemplateTaskExpand>} [expand] define task expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TemplateTaskExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/template-tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch task template instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TemplateTasksFields} templateTasksFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksPatch: async (filter: Array<string>, templateTasksFields: TemplateTasksFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('templateTasksPatch', 'filter', filter)
            // verify required parameter 'templateTasksFields' is not null or undefined
            assertParamExists('templateTasksPatch', 'templateTasksFields', templateTasksFields)
            const localVarPath = `/template-tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateTasksFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update task template instance
         * @param {number} id -- task template primary key
         * @param {TemplateTasksParams} templateTasksParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksUpdate: async (id: number, templateTasksParams: TemplateTasksParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('templateTasksUpdate', 'id', id)
            // verify required parameter 'templateTasksParams' is not null or undefined
            assertParamExists('templateTasksUpdate', 'templateTasksParams', templateTasksParams)
            const localVarPath = `/template-tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateTasksParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplateTasksApi - functional programming interface
 * @export
 */
export const TemplateTasksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplateTasksApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single task template instance
         * @param {TemplateTasksParams} templateTasksParams task params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateTasksCreate(templateTasksParams: TemplateTasksParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20120>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateTasksCreate(templateTasksParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete task template by its primary key
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateTasksDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateTasksDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single task template result instance
         * @param {number} id task template primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateTasksGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateTasksGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of tasks
         * @param {Array<string>} [q] query for search in task templates fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<TemplateTaskExpand>} [expand] define task expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateTasksList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TemplateTaskExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateTasksPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateTasksList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch task template instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TemplateTasksFields} templateTasksFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateTasksPatch(filter: Array<string>, templateTasksFields: TemplateTasksFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateTasksPatch(filter, templateTasksFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update task template instance
         * @param {number} id -- task template primary key
         * @param {TemplateTasksParams} templateTasksParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateTasksUpdate(id: number, templateTasksParams: TemplateTasksParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateTasksUpdate(id, templateTasksParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TemplateTasksApi - factory interface
 * @export
 */
export const TemplateTasksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplateTasksApiFp(configuration)
    return {
        /**
         * Create single task template instance
         * @param {TemplateTasksParams} templateTasksParams task params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksCreate(templateTasksParams: TemplateTasksParams, options?: any): AxiosPromise<InlineResponse20120> {
            return localVarFp.templateTasksCreate(templateTasksParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete task template by its primary key
         * @param {number} id task primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.templateTasksDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single task template result instance
         * @param {number} id task template primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksGetById(id: number, options?: any): AxiosPromise<TemplateTask> {
            return localVarFp.templateTasksGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of tasks
         * @param {Array<string>} [q] query for search in task templates fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<TemplateTaskExpand>} [expand] define task expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TemplateTaskExpand>, page?: number, limit?: number, options?: any): AxiosPromise<TemplateTasksPage> {
            return localVarFp.templateTasksList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch task template instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TemplateTasksFields} templateTasksFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksPatch(filter: Array<string>, templateTasksFields: TemplateTasksFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.templateTasksPatch(filter, templateTasksFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Update task template instance
         * @param {number} id -- task template primary key
         * @param {TemplateTasksParams} templateTasksParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksUpdate(id: number, templateTasksParams: TemplateTasksParams, options?: any): AxiosPromise<void> {
            return localVarFp.templateTasksUpdate(id, templateTasksParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplateTasksApi - interface
 * @export
 * @interface TemplateTasksApi
 */
export interface TemplateTasksApiInterface {
    /**
     * Create single task template instance
     * @param {TemplateTasksParams} templateTasksParams task params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksApiInterface
     */
    templateTasksCreate(templateTasksParams: TemplateTasksParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse20120>;

    /**
     * Delete task template by its primary key
     * @param {number} id task primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksApiInterface
     */
    templateTasksDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return single task template result instance
     * @param {number} id task template primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksApiInterface
     */
    templateTasksGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<TemplateTask>;

    /**
     * Return collection of tasks
     * @param {Array<string>} [q] query for search in task templates fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<TemplateTaskExpand>} [expand] define task expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksApiInterface
     */
    templateTasksList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TemplateTaskExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<TemplateTasksPage>;

    /**
     * Patch task template instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {TemplateTasksFields} templateTasksFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksApiInterface
     */
    templateTasksPatch(filter: Array<string>, templateTasksFields: TemplateTasksFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Update task template instance
     * @param {number} id -- task template primary key
     * @param {TemplateTasksParams} templateTasksParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksApiInterface
     */
    templateTasksUpdate(id: number, templateTasksParams: TemplateTasksParams, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * TemplateTasksApi - object-oriented interface
 * @export
 * @class TemplateTasksApi
 * @extends {BaseAPI}
 */
export class TemplateTasksApi extends BaseAPI implements TemplateTasksApiInterface {
    /**
     * Create single task template instance
     * @param {TemplateTasksParams} templateTasksParams task params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksApi
     */
    public templateTasksCreate(templateTasksParams: TemplateTasksParams, options?: AxiosRequestConfig) {
        return TemplateTasksApiFp(this.configuration).templateTasksCreate(templateTasksParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete task template by its primary key
     * @param {number} id task primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksApi
     */
    public templateTasksDelete(id: number, options?: AxiosRequestConfig) {
        return TemplateTasksApiFp(this.configuration).templateTasksDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single task template result instance
     * @param {number} id task template primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksApi
     */
    public templateTasksGetById(id: number, options?: AxiosRequestConfig) {
        return TemplateTasksApiFp(this.configuration).templateTasksGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of tasks
     * @param {Array<string>} [q] query for search in task templates fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<TemplateTaskExpand>} [expand] define task expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksApi
     */
    public templateTasksList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TemplateTaskExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return TemplateTasksApiFp(this.configuration).templateTasksList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch task template instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {TemplateTasksFields} templateTasksFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksApi
     */
    public templateTasksPatch(filter: Array<string>, templateTasksFields: TemplateTasksFields, options?: AxiosRequestConfig) {
        return TemplateTasksApiFp(this.configuration).templateTasksPatch(filter, templateTasksFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update task template instance
     * @param {number} id -- task template primary key
     * @param {TemplateTasksParams} templateTasksParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksApi
     */
    public templateTasksUpdate(id: number, templateTasksParams: TemplateTasksParams, options?: AxiosRequestConfig) {
        return TemplateTasksApiFp(this.configuration).templateTasksUpdate(id, templateTasksParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TemplateTasksLinkageApi - axios parameter creator
 * @export
 */
export const TemplateTasksLinkageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single template tasks linkage instance
         * @param {TemplateTasksLinkageParams} templateTasksLinkageParams template tasks linkage params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksLinkageCreate: async (templateTasksLinkageParams: TemplateTasksLinkageParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateTasksLinkageParams' is not null or undefined
            assertParamExists('templateTasksLinkageCreate', 'templateTasksLinkageParams', templateTasksLinkageParams)
            const localVarPath = `/template-tasks-linkage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateTasksLinkageParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete template tasks linkage by its primary key
         * @param {number} id template tasks linkage primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksLinkageDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('templateTasksLinkageDelete', 'id', id)
            const localVarPath = `/template-tasks-linkage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete template tasks linkage by its primary key
         * @param {Array<string>} filter template tasks linkage primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksLinkageDeleteMany: async (filter: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('templateTasksLinkageDeleteMany', 'filter', filter)
            const localVarPath = `/template-tasks-linkage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single template tasks linkage result instance
         * @param {number} id template tasks linkage primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksLinkageGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('templateTasksLinkageGetById', 'id', id)
            const localVarPath = `/template-tasks-linkage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of template tasks linkage
         * @param {Array<string>} [q] query for search in template tasks linkage fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<TemplateTasksLinkageExpand>} [expand] define template tasks linkage expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksLinkageList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TemplateTasksLinkageExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/template-tasks-linkage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch template tasks linkage instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TemplateTasksLinkageFields} templateTasksLinkageFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksLinkagePatch: async (filter: Array<string>, templateTasksLinkageFields: TemplateTasksLinkageFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('templateTasksLinkagePatch', 'filter', filter)
            // verify required parameter 'templateTasksLinkageFields' is not null or undefined
            assertParamExists('templateTasksLinkagePatch', 'templateTasksLinkageFields', templateTasksLinkageFields)
            const localVarPath = `/template-tasks-linkage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateTasksLinkageFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update template tasks linkages instance
         * @param {number} id -- template tasks linkage primary key
         * @param {TemplateTasksLinkageParams} templateTasksLinkageParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksLinkageUpdate: async (id: number, templateTasksLinkageParams: TemplateTasksLinkageParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('templateTasksLinkageUpdate', 'id', id)
            // verify required parameter 'templateTasksLinkageParams' is not null or undefined
            assertParamExists('templateTasksLinkageUpdate', 'templateTasksLinkageParams', templateTasksLinkageParams)
            const localVarPath = `/template-tasks-linkage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateTasksLinkageParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplateTasksLinkageApi - functional programming interface
 * @export
 */
export const TemplateTasksLinkageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplateTasksLinkageApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single template tasks linkage instance
         * @param {TemplateTasksLinkageParams} templateTasksLinkageParams template tasks linkage params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateTasksLinkageCreate(templateTasksLinkageParams: TemplateTasksLinkageParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20121>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateTasksLinkageCreate(templateTasksLinkageParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete template tasks linkage by its primary key
         * @param {number} id template tasks linkage primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateTasksLinkageDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateTasksLinkageDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete template tasks linkage by its primary key
         * @param {Array<string>} filter template tasks linkage primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateTasksLinkageDeleteMany(filter: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateTasksLinkageDeleteMany(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single template tasks linkage result instance
         * @param {number} id template tasks linkage primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateTasksLinkageGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateTasksLinkage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateTasksLinkageGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of template tasks linkage
         * @param {Array<string>} [q] query for search in template tasks linkage fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<TemplateTasksLinkageExpand>} [expand] define template tasks linkage expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateTasksLinkageList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TemplateTasksLinkageExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateTasksLinkagePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateTasksLinkageList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch template tasks linkage instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TemplateTasksLinkageFields} templateTasksLinkageFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateTasksLinkagePatch(filter: Array<string>, templateTasksLinkageFields: TemplateTasksLinkageFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateTasksLinkagePatch(filter, templateTasksLinkageFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update template tasks linkages instance
         * @param {number} id -- template tasks linkage primary key
         * @param {TemplateTasksLinkageParams} templateTasksLinkageParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateTasksLinkageUpdate(id: number, templateTasksLinkageParams: TemplateTasksLinkageParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateTasksLinkageUpdate(id, templateTasksLinkageParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TemplateTasksLinkageApi - factory interface
 * @export
 */
export const TemplateTasksLinkageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplateTasksLinkageApiFp(configuration)
    return {
        /**
         * Create single template tasks linkage instance
         * @param {TemplateTasksLinkageParams} templateTasksLinkageParams template tasks linkage params instance to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksLinkageCreate(templateTasksLinkageParams: TemplateTasksLinkageParams, options?: any): AxiosPromise<InlineResponse20121> {
            return localVarFp.templateTasksLinkageCreate(templateTasksLinkageParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete template tasks linkage by its primary key
         * @param {number} id template tasks linkage primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksLinkageDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.templateTasksLinkageDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete template tasks linkage by its primary key
         * @param {Array<string>} filter template tasks linkage primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksLinkageDeleteMany(filter: Array<string>, options?: any): AxiosPromise<boolean> {
            return localVarFp.templateTasksLinkageDeleteMany(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single template tasks linkage result instance
         * @param {number} id template tasks linkage primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksLinkageGetById(id: number, options?: any): AxiosPromise<TemplateTasksLinkage> {
            return localVarFp.templateTasksLinkageGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of template tasks linkage
         * @param {Array<string>} [q] query for search in template tasks linkage fields (e.g. name)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
         * @param {Array<TemplateTasksLinkageExpand>} [expand] define template tasks linkage expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksLinkageList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TemplateTasksLinkageExpand>, page?: number, limit?: number, options?: any): AxiosPromise<TemplateTasksLinkagePage> {
            return localVarFp.templateTasksLinkageList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch template tasks linkage instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {TemplateTasksLinkageFields} templateTasksLinkageFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksLinkagePatch(filter: Array<string>, templateTasksLinkageFields: TemplateTasksLinkageFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.templateTasksLinkagePatch(filter, templateTasksLinkageFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Update template tasks linkages instance
         * @param {number} id -- template tasks linkage primary key
         * @param {TemplateTasksLinkageParams} templateTasksLinkageParams instance to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateTasksLinkageUpdate(id: number, templateTasksLinkageParams: TemplateTasksLinkageParams, options?: any): AxiosPromise<void> {
            return localVarFp.templateTasksLinkageUpdate(id, templateTasksLinkageParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplateTasksLinkageApi - interface
 * @export
 * @interface TemplateTasksLinkageApi
 */
export interface TemplateTasksLinkageApiInterface {
    /**
     * Create single template tasks linkage instance
     * @param {TemplateTasksLinkageParams} templateTasksLinkageParams template tasks linkage params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksLinkageApiInterface
     */
    templateTasksLinkageCreate(templateTasksLinkageParams: TemplateTasksLinkageParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse20121>;

    /**
     * Delete template tasks linkage by its primary key
     * @param {number} id template tasks linkage primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksLinkageApiInterface
     */
    templateTasksLinkageDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Delete template tasks linkage by its primary key
     * @param {Array<string>} filter template tasks linkage primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksLinkageApiInterface
     */
    templateTasksLinkageDeleteMany(filter: Array<string>, options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * Return single template tasks linkage result instance
     * @param {number} id template tasks linkage primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksLinkageApiInterface
     */
    templateTasksLinkageGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<TemplateTasksLinkage>;

    /**
     * Return collection of template tasks linkage
     * @param {Array<string>} [q] query for search in template tasks linkage fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<TemplateTasksLinkageExpand>} [expand] define template tasks linkage expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksLinkageApiInterface
     */
    templateTasksLinkageList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TemplateTasksLinkageExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<TemplateTasksLinkagePage>;

    /**
     * Patch template tasks linkage instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {TemplateTasksLinkageFields} templateTasksLinkageFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksLinkageApiInterface
     */
    templateTasksLinkagePatch(filter: Array<string>, templateTasksLinkageFields: TemplateTasksLinkageFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Update template tasks linkages instance
     * @param {number} id -- template tasks linkage primary key
     * @param {TemplateTasksLinkageParams} templateTasksLinkageParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksLinkageApiInterface
     */
    templateTasksLinkageUpdate(id: number, templateTasksLinkageParams: TemplateTasksLinkageParams, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * TemplateTasksLinkageApi - object-oriented interface
 * @export
 * @class TemplateTasksLinkageApi
 * @extends {BaseAPI}
 */
export class TemplateTasksLinkageApi extends BaseAPI implements TemplateTasksLinkageApiInterface {
    /**
     * Create single template tasks linkage instance
     * @param {TemplateTasksLinkageParams} templateTasksLinkageParams template tasks linkage params instance to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksLinkageApi
     */
    public templateTasksLinkageCreate(templateTasksLinkageParams: TemplateTasksLinkageParams, options?: AxiosRequestConfig) {
        return TemplateTasksLinkageApiFp(this.configuration).templateTasksLinkageCreate(templateTasksLinkageParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete template tasks linkage by its primary key
     * @param {number} id template tasks linkage primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksLinkageApi
     */
    public templateTasksLinkageDelete(id: number, options?: AxiosRequestConfig) {
        return TemplateTasksLinkageApiFp(this.configuration).templateTasksLinkageDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete template tasks linkage by its primary key
     * @param {Array<string>} filter template tasks linkage primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksLinkageApi
     */
    public templateTasksLinkageDeleteMany(filter: Array<string>, options?: AxiosRequestConfig) {
        return TemplateTasksLinkageApiFp(this.configuration).templateTasksLinkageDeleteMany(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single template tasks linkage result instance
     * @param {number} id template tasks linkage primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksLinkageApi
     */
    public templateTasksLinkageGetById(id: number, options?: AxiosRequestConfig) {
        return TemplateTasksLinkageApiFp(this.configuration).templateTasksLinkageGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of template tasks linkage
     * @param {Array<string>} [q] query for search in template tasks linkage fields (e.g. name)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;alerts:0&#x60;)
     * @param {Array<TemplateTasksLinkageExpand>} [expand] define template tasks linkage expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksLinkageApi
     */
    public templateTasksLinkageList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<TemplateTasksLinkageExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return TemplateTasksLinkageApiFp(this.configuration).templateTasksLinkageList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch template tasks linkage instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {TemplateTasksLinkageFields} templateTasksLinkageFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksLinkageApi
     */
    public templateTasksLinkagePatch(filter: Array<string>, templateTasksLinkageFields: TemplateTasksLinkageFields, options?: AxiosRequestConfig) {
        return TemplateTasksLinkageApiFp(this.configuration).templateTasksLinkagePatch(filter, templateTasksLinkageFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update template tasks linkages instance
     * @param {number} id -- template tasks linkage primary key
     * @param {TemplateTasksLinkageParams} templateTasksLinkageParams instance to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateTasksLinkageApi
     */
    public templateTasksLinkageUpdate(id: number, templateTasksLinkageParams: TemplateTasksLinkageParams, options?: AxiosRequestConfig) {
        return TemplateTasksLinkageApiFp(this.configuration).templateTasksLinkageUpdate(id, templateTasksLinkageParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create single user instance
         * @param {UserParams} userParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreate: async (userParams: UserParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userParams' is not null or undefined
            assertParamExists('userCreate', 'userParams', userParams)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete user by its primary key
         * @param {number} id user primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userDelete', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of user registered devices
         * @param {number} id user primary key
         * @param {Array<string>} [q] query for search in user fields (e.g. firstName, lastName, email)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:EMPLOYEE&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDeviceList: async (id: number, q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userDeviceList', 'id', id)
            const localVarPath = `/users/{id}/devices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single user instance
         * @param {number} id -- user primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userGetById', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of registered users
         * @param {Array<string>} [q] query for search in user fields (e.g. firstName, lastName, email)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:EMPLOYEE&#x60;)
         * @param {Array<UserExpand>} [expand] define user expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userList: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<UserExpand>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (expand) {
                localVarQueryParameter['expand'] = expand;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch user instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {UserFields} userFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatch: async (filter: Array<string>, userFields: UserFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('userPatch', 'filter', filter)
            // verify required parameter 'userFields' is not null or undefined
            assertParamExists('userPatch', 'userFields', userFields)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return collection of users
         * @param {Array<string>} [q] query for search in user fields (e.g. firstName, lastName, email)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:EMPLOYEE&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userStatus: async (q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)

            if (q) {
                localVarQueryParameter['q'] = q;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user instance
         * @param {number} id -- user primary key
         * @param {UserParams} userParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate: async (id: number, userParams: UserParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userUpdate', 'id', id)
            // verify required parameter 'userParams' is not null or undefined
            assertParamExists('userUpdate', 'userParams', userParams)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthJWT required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AuthJWT", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Create single user instance
         * @param {UserParams} userParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCreate(userParams: UserParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20115>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userCreate(userParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete user by its primary key
         * @param {number} id user primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of user registered devices
         * @param {number} id user primary key
         * @param {Array<string>} [q] query for search in user fields (e.g. firstName, lastName, email)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:EMPLOYEE&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDeviceList(id: number, q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDeviceList(id, q, sort, filter, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single user instance
         * @param {number} id -- user primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of registered users
         * @param {Array<string>} [q] query for search in user fields (e.g. firstName, lastName, email)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:EMPLOYEE&#x60;)
         * @param {Array<UserExpand>} [expand] define user expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<UserExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userList(q, sort, filter, expand, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch user instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {UserFields} userFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPatch(filter: Array<string>, userFields: UserFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPatch(filter, userFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return collection of users
         * @param {Array<string>} [q] query for search in user fields (e.g. firstName, lastName, email)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:EMPLOYEE&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userStatus(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersStatusPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userStatus(q, sort, filter, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update user instance
         * @param {number} id -- user primary key
         * @param {UserParams} userParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdate(id: number, userParams: UserParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdate(id, userParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Create single user instance
         * @param {UserParams} userParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreate(userParams: UserParams, options?: any): AxiosPromise<InlineResponse20115> {
            return localVarFp.userCreate(userParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete user by its primary key
         * @param {number} id user primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.userDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of user registered devices
         * @param {number} id user primary key
         * @param {Array<string>} [q] query for search in user fields (e.g. firstName, lastName, email)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:EMPLOYEE&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20, maximum 50)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDeviceList(id: number, q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: any): AxiosPromise<DevicesPage> {
            return localVarFp.userDeviceList(id, q, sort, filter, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Return single user instance
         * @param {number} id -- user primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetById(id: number, options?: any): AxiosPromise<User> {
            return localVarFp.userGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of registered users
         * @param {Array<string>} [q] query for search in user fields (e.g. firstName, lastName, email)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:EMPLOYEE&#x60;)
         * @param {Array<UserExpand>} [expand] define user expandable (or embedded) data
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<UserExpand>, page?: number, limit?: number, options?: any): AxiosPromise<UsersPage> {
            return localVarFp.userList(q, sort, filter, expand, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch user instances
         * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
         * @param {UserFields} userFields instance properties to patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatch(filter: Array<string>, userFields: UserFields, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.userPatch(filter, userFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Return collection of users
         * @param {Array<string>} [q] query for search in user fields (e.g. firstName, lastName, email)
         * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
         * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:EMPLOYEE&#x60;)
         * @param {number} [page] page number to display (default 1)
         * @param {number} [limit] per page items limit (default 20)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userStatus(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: any): AxiosPromise<UsersStatusPage> {
            return localVarFp.userStatus(q, sort, filter, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Update user instance
         * @param {number} id -- user primary key
         * @param {UserParams} userParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate(id: number, userParams: UserParams, options?: any): AxiosPromise<void> {
            return localVarFp.userUpdate(id, userParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - interface
 * @export
 * @interface UserApi
 */
export interface UserApiInterface {
    /**
     * Create single user instance
     * @param {UserParams} userParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userCreate(userParams: UserParams, options?: AxiosRequestConfig): AxiosPromise<InlineResponse20115>;

    /**
     * Delete user by its primary key
     * @param {number} id user primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userDelete(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Return collection of user registered devices
     * @param {number} id user primary key
     * @param {Array<string>} [q] query for search in user fields (e.g. firstName, lastName, email)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:EMPLOYEE&#x60;)
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userDeviceList(id: number, q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<DevicesPage>;

    /**
     * Return single user instance
     * @param {number} id -- user primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userGetById(id: number, options?: AxiosRequestConfig): AxiosPromise<User>;

    /**
     * Return collection of registered users
     * @param {Array<string>} [q] query for search in user fields (e.g. firstName, lastName, email)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:EMPLOYEE&#x60;)
     * @param {Array<UserExpand>} [expand] define user expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<UserExpand>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<UsersPage>;

    /**
     * Patch user instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {UserFields} userFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userPatch(filter: Array<string>, userFields: UserFields, options?: AxiosRequestConfig): AxiosPromise<InlineResponse200>;

    /**
     * Return collection of users
     * @param {Array<string>} [q] query for search in user fields (e.g. firstName, lastName, email)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:EMPLOYEE&#x60;)
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userStatus(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<UsersStatusPage>;

    /**
     * Update user instance
     * @param {number} id -- user primary key
     * @param {UserParams} userParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userUpdate(id: number, userParams: UserParams, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI implements UserApiInterface {
    /**
     * Create single user instance
     * @param {UserParams} userParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userCreate(userParams: UserParams, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userCreate(userParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete user by its primary key
     * @param {number} id user primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userDelete(id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of user registered devices
     * @param {number} id user primary key
     * @param {Array<string>} [q] query for search in user fields (e.g. firstName, lastName, email)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:EMPLOYEE&#x60;)
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20, maximum 50)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userDeviceList(id: number, q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userDeviceList(id, q, sort, filter, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single user instance
     * @param {number} id -- user primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetById(id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of registered users
     * @param {Array<string>} [q] query for search in user fields (e.g. firstName, lastName, email)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:EMPLOYEE&#x60;)
     * @param {Array<UserExpand>} [expand] define user expandable (or embedded) data
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userList(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, expand?: Array<UserExpand>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userList(q, sort, filter, expand, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch user instances
     * @param {Array<string>} filter filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;id:1&#x60;)
     * @param {UserFields} userFields instance properties to patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userPatch(filter: Array<string>, userFields: UserFields, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userPatch(filter, userFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return collection of users
     * @param {Array<string>} [q] query for search in user fields (e.g. firstName, lastName, email)
     * @param {Array<string>} [sort] field ordering definition in format &#x60;[+|-]fieldName&#x60; where first character determinate ordering direction: &#x60;-&#x60; - mean descending direction and &#x60;+&#x60; - ascending direction (default &#x60;ASC&#x60;)
     * @param {Array<string>} [filter] filters by field value in format &#x60;fieldName:fieldValue&#x60; (e.g. &#x60;type:EMPLOYEE&#x60;)
     * @param {number} [page] page number to display (default 1)
     * @param {number} [limit] per page items limit (default 20)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userStatus(q?: Array<string>, sort?: Array<string>, filter?: Array<string>, page?: number, limit?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userStatus(q, sort, filter, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user instance
     * @param {number} id -- user primary key
     * @param {UserParams} userParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdate(id: number, userParams: UserParams, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userUpdate(id, userParams, options).then((request) => request(this.axios, this.basePath));
    }
}


