import { Tooltip } from 'antd';
import {useEffect, useRef, useState} from "react";

export default function TooltipComponent({ children }) {
    const textComponentRef = useRef(null);
    const [tooltipVisible, setTooltipVisible] = useState(null);

    const compareSize = () => {
        const element = textComponentRef.current;
        const compare = element
            ?
            element.offsetHeight < element.scrollHeight
            : false;
        setTooltipVisible(compare);
    };

    useEffect(() => {
        compareSize();
    }, [textComponentRef]);

    return (
        <Tooltip title={ tooltipVisible ? children : ''} >
            <div ref={textComponentRef} >
                {children}
            </div>
        </Tooltip>
    );
};