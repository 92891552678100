import './listPage.css';
import {Table, Input,} from 'antd';
import {useEffect, useState} from "react";
import selectedScansState from "../../atoms/selectedScans";
import selectedScansKeysState from "../../atoms/selectedScansKeys";
import {useRecoilState} from "recoil";
import scanDeletedState from "../../atoms/scanDeleted";

let searchValue = '';
let page = 1;

const ListPage = ({
        title,
        emptyPage,
        getData,
        searchPlaceholder,
        columns,
        commandButtons,
        scroll,
        limit,
        location,
        showTitle
    }) => {

    const { Search } = Input;

    const [isEmptyPage, setIsEmptyPage] = useState(false);
    const [gridData, setGridData] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(true);
    const [, setSelectedScans] = useRecoilState(selectedScansState);
    const [selectedScansKeys, setSelectedScansKeys] = useRecoilState(selectedScansKeysState);
    const [scanDeleted, setScanDeleted ] = useRecoilState(scanDeletedState);

    const fetchData = async () => {
        if ((gridData.totalPages - selectedScansKeys.length === (page - 1) * limit) && page !== 1) {
            page -= 1;
        }
        setIsDataLoaded(false);
        const loadedData = await getData(page, searchValue);
        setIsEmptyPage(loadedData.data.length === 0);
        setGridData(loadedData);
        setIsDataLoaded(true);
    }

    const onSearch = async (value) => {
        searchValue = value;
        page = 1;
        await fetchData();
    }

    const handlePagination = async (currentPage) => {
        page = currentPage;
        setSelectedScans([]);
        setSelectedScansKeys([]);
        await fetchData();
    }

    useEffect(() => {
        return () => {
            setSelectedScans([]);
            setSelectedScansKeys([]);
        }
    },[]);

    useEffect(async () => {
        searchValue = '';
        page = 1;
        await fetchData();
    }, [location]);

    useEffect(async () => {
        if (scanDeleted) {
            await fetchData();
            setScanDeleted(null);
        }
    }, [scanDeleted]);

    const onRowChanged = (selectedRowsKeys, selectedItems) => {
        const selectedScans = [];
        selectedItems.forEach(el => selectedScans.push(el.id));
        setSelectedScans(selectedScans);
        setSelectedScansKeys(selectedRowsKeys);
    }

    return <div className="list-page" data-testid="list-page">
        {showTitle && <h1>{title}</h1>}
        <div className="search-container">
            <div>
                <Search key={`search-${title}`} disabled={isEmptyPage && searchValue.length === 0} placeholder={searchPlaceholder} className="search-input" data-testid="items-search" onSearch={onSearch} />
            </div>
            <div className="command-buttons">
                {commandButtons}
            </div>
        </div>

        <div className="filter">
            <div> <span className="plus">+</span>Add Filter</div>
        </div>

        {!(isEmptyPage && searchValue.length <= 0) && <div className={isEmptyPage ? 'empty-container' : ''}>
            <Table
                dataSource={gridData.data}
                columns={columns}
                scroll={scroll}
                rowKey={'id'}
                loading={!isDataLoaded}
                rowSelection={{
                    selectedRowKeys: selectedScansKeys,
                    onChange: onRowChanged
                }}
                pagination={
                    {
                        current: page,
                        total: gridData.totalPages,
                        showSizeChanger: false,
                        pageSize: limit,
                        onChange: handlePagination
                    }
                }
            />
        </div>}
        {isEmptyPage && <div data-testid="empty-list" className="empty-list-page-wrapper">{emptyPage}</div>}
    </div>
}

export default ListPage;
