import "./projectStatusReportSchedule.css";
import {Button, Checkbox, InputNumber, Modal, Radio, Select, Space, Typography} from "antd";
import {FieldTimeOutlined} from "@ant-design/icons";
import React, {useCallback, useEffect, useReducer, useState} from "react";
import {
    projectStatusReportScheduleReducer,
    REPORT_SCHEDULE_ACTIONS,
    reportScheduleInitialState
} from "./projectStatusReportScheduleReducer";
import {
    DAY_OF_MONTH_OPTIONS,
    DAY_OF_WEEK_OPTIONS,
    REPORT_SCHEDULE_PERIODICITY,
    REPORT_SCHEDULE_TYPE
} from "../../hooks/useProjectStatusReportOperations";

const ProjectStatusReportSchedule = ({reportScheduleState, onSave, buttonTitle='Change'}) => {

    const [isStatusReportScheduleVisible, setStatusReportScheduleVisible] = useState(false);

    const [scheduleState, reportScheduleDispatch] = useReducer(projectStatusReportScheduleReducer, reportScheduleInitialState);

    useEffect(async () => {
        if(isStatusReportScheduleVisible) {
            await projectScheduleInit();
        }
    }, [reportScheduleState, isStatusReportScheduleVisible]);

    const projectScheduleInit = async () => {
        reportScheduleDispatch({type: REPORT_SCHEDULE_ACTIONS.RESET, payload: reportScheduleState});
    }

    const handleStatusReportScheduleVisible = useCallback(async () => {
        setStatusReportScheduleVisible(!isStatusReportScheduleVisible);
    }, [isStatusReportScheduleVisible]);

    const handleScheduleTypeChange = useCallback((e) => {
        reportScheduleDispatch({type: REPORT_SCHEDULE_ACTIONS.SET_TYPE, payload: e.target.value});
    }, []);

    const handleSchedulePeriodicityChange = useCallback((e) => {
        reportScheduleDispatch({type: REPORT_SCHEDULE_ACTIONS.SET_PERIODICITY, payload: e.target.value});
    }, []);

    const handleScheduleWeekDayChange = useCallback((e) => {
        reportScheduleDispatch({type: REPORT_SCHEDULE_ACTIONS.SET_WEEK_DAY, payload: e});
    }, []);

    const handleScheduleMonthDayChange = useCallback((e) => {
        reportScheduleDispatch({type: REPORT_SCHEDULE_ACTIONS.SET_MONTH_DAY, payload: e});
    }, []);

    const handleScheduleFrequencyChange = useCallback((e) => {
        reportScheduleDispatch({type: REPORT_SCHEDULE_ACTIONS.SET_FREQUENCY, payload: e});
    }, []);

    const handleStatusReportScheduleSave = useCallback(async () => {
        onSave(scheduleState);
        setStatusReportScheduleVisible(false);
    }, [scheduleState]);

    const handleStatusReportScheduleCancel = useCallback(async () => {
        setStatusReportScheduleVisible(false);
    }, []);

    const enableTypePeriodic = scheduleState.type === REPORT_SCHEDULE_TYPE.PERIODICITY;
    const enablePeriodicityWeek = scheduleState.type === REPORT_SCHEDULE_TYPE.PERIODICITY
        && scheduleState.periodicity === REPORT_SCHEDULE_PERIODICITY.WEEK;
    const enablePeriodicityMonth = scheduleState.type === REPORT_SCHEDULE_TYPE.PERIODICITY
        && scheduleState.periodicity === REPORT_SCHEDULE_PERIODICITY.MONTH;

    return <>
        <Button className="project-status-report-schedule-button"
                data-testid="project-status-report-schedule-button"
                onClick={handleStatusReportScheduleVisible}>
            <FieldTimeOutlined/> {buttonTitle}
        </Button>
        <Modal title="Report generation schedule" width={686}
               className="project-status-report-schedule-modal"
               data-testid="project-status-report-schedule-modal"
               wrapClassName="project-status-report-schedule-modal-wrapper"
               open={isStatusReportScheduleVisible}
               key={isStatusReportScheduleVisible ? 'opened-modal' : 'closed-modal'}
               okText={"Save"}
               okButtonProps={{'data-testid': 'project-status-report-schedule-modal-save'}}
               cancelButtonProps={{'data-testid': 'project-status-report-schedule-modal-cancel'}}
               onOk={handleStatusReportScheduleSave}
               onCancel={handleStatusReportScheduleCancel}>
            <div >
                <Radio.Group className="project-status-report-schedule-type-radio-group"
                             data-testid="project-status-report-schedule-type-radio-group"
                             value={scheduleState.type}
                             onChange={handleScheduleTypeChange}>
                    <Space direction="vertical" size={24}>
                        <Radio value={REPORT_SCHEDULE_TYPE.NOT_SET}
                               data-testid="project-status-report-schedule-type-not-set">
                            Not set
                        </Radio>
                        <Radio value={REPORT_SCHEDULE_TYPE.PERIODICITY}
                               data-testid="project-status-report-schedule-type-periodicity">
                            Every
                            <InputNumber className="project-status-report-schedule-frequency"
                                         data-testid="project-status-report-schedule-frequency"
                                         min={1} max={31}
                                         value={scheduleState.frequency}
                                         disabled={!enableTypePeriodic}
                                         onChange={handleScheduleFrequencyChange}/>
                            <Radio.Group data-testid="project-status-report-schedule-periodicity-radio-group"
                                         className="project-status-report-schedule-periodicity-radio-group"
                                         onChange={handleSchedulePeriodicityChange}
                                         value={scheduleState.periodicity}
                                         disabled={!enableTypePeriodic}>
                                <Radio.Button data-testid='report-schedule-periodicity-day'
                                              value={REPORT_SCHEDULE_PERIODICITY.DAY}>Day</Radio.Button>
                                <Radio.Button data-testid='report-schedule-periodicity-week'
                                              value={REPORT_SCHEDULE_PERIODICITY.WEEK}>Week</Radio.Button>
                                <Radio.Button data-testid='report-schedule-periodicity-month'
                                              value={REPORT_SCHEDULE_PERIODICITY.MONTH}>Month</Radio.Button>
                            </Radio.Group>
                        </Radio>
                        {enablePeriodicityWeek && <Space direction="vertical" size={16}>
                            <Typography className="project-status-report-schedule-label">On days</Typography>
                            <Checkbox.Group style={{width: '100%'}}
                                            data-testid="project-status-report-schedule-week-checkbox-group"
                                            value={scheduleState.week_day}
                                            options={DAY_OF_WEEK_OPTIONS}
                                            onChange={handleScheduleWeekDayChange}
                            />
                        </Space>}
                        {enablePeriodicityMonth && <Space direction="horizontal" size={16}>
                            <Typography className="project-status-report-schedule-label">On day</Typography>
                            <Select className="project-status-report-schedule-month-select"
                                    data-testid="project-status-report-schedule-month-select"
                                    value={scheduleState.month_day}
                                    onChange={handleScheduleMonthDayChange}
                                    options={DAY_OF_MONTH_OPTIONS}>
                            </Select>
                        </Space>}
                    </Space>
                </Radio.Group>
            </div>
        </Modal>
    </>
}

export default ProjectStatusReportSchedule;
