import React, { useRef, useEffect } from 'react';

const ClickOutsideComponent = ({ children, onClickOutside, cssClassName }) => {
    const componentRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (componentRef.current && !componentRef.current.contains(event.target)) {
                onClickOutside();
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [onClickOutside]);
    return <div ref={componentRef} className={cssClassName}>{children}</div>;
};

export default ClickOutsideComponent;
