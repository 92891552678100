import './gcpForm.css';

import {useEffect, useState} from "react";

import {
    Form,
    Input,
    Button
} from 'antd';

import UploadContainer from "../uploadContainer/uploadContainer";

const formData = {
    projectId: '',
    privateKey: '',
    deviceConfig: {},
}

const GCPForm = ({onDataChanged}) => {

    const errorMessages = {
        WRONG_FILE_TYPE: 'Uploaded file has wrong format. \n' +
            'Only .JSON format is  supported.',
        FAILED_TO_PARSE: 'Uploaded file does not have expected data'
    }

    const [projectId, setProjectId] = useState(formData.projectId);
    const [privateKey, setPrivateKey] = useState(formData.privateKey);

    function parseJSON(data) {
        const parsedData = JSON.parse(data);
        if (parsedData.private_key_id && parsedData.project_id) {
            setPrivateKey(parsedData.private_key_id);
            setProjectId(parsedData.project_id);
            formData.privateKey = parsedData.private_key_id;
            formData.projectId = parsedData.project_id;
            formData.deviceConfig = parsedData;
            onDataChanged(formData);
        } else {
            throw new Error(errorMessages.FAILED_TO_PARSE);
        }
    }

    useEffect(() => {
        onDataChanged(formData);
        return () => {
            formData.privateKey = '';
            formData.projectId = '';
            formData.deviceConfig = {};
            onDataChanged({});
        }
    }, []);

    useEffect(() => {
        setProjectId('');
        setPrivateKey('');
        onDataChanged(formData);
    }, []);

    return <div className="gcp-form" data-testid="gcp-form">
        <div className="desc"><div>Generate and download a JSON key for an existing service account using <a href="https://console.cloud.google.com/projectselector2/iam-admin/serviceaccounts?supportedpurview=project">service account key page</a> and upload it here, or put the data manually.</div></div>
        <Form
            layout="vertical"
        >
            <div className="form-layout">
                <div className="col-1">
                    <Form.Item label="Project ID" required>
                        <Input required={true} placeholder="Project ID" value={projectId}
                               onChange={(e) => {
                                   setProjectId(e.target.value);
                                   formData.projectId = e.target.value;
                                   onDataChanged(formData);
                               }}
                               data-testid="project-id" style={{height: 40}}/>
                    </Form.Item>
                    <Form.Item label="Private Key" required>
                        <Input placeholder="*****" value={privateKey} onChange={(e) => {
                            setPrivateKey(e.target.value);
                            formData.privateKey = e.target.value;
                            onDataChanged(formData);
                        }} data-testid="private-key" style={{height: 40}}/>
                    </Form.Item>
                    <Form.Item>
                        <Button className='test-connection' type='primary' disabled={true}>Test connection</Button>
                    </Form.Item>
                </div>
                <div className="col-2">-or-</div>
                <div className="col-3">
                    <UploadContainer contentType="application/json" parseFile={parseJSON} messages={errorMessages} title="Drop GCP access key file here"/>
                </div>
            </div>
        </Form>
    </div>
}

export default GCPForm;