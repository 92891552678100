import './tableFilter.css';

const TableFilter = () => {
  return (
    <div className="documents-page-table-filter" data-testid="documents-page-filder">
      <div><span className="plus">+</span>Add Filter</div>
    </div>
  );
};

export default TableFilter;
