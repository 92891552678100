import './awsSummary.css';
import {
    Form,
    Input,
    Button
} from 'antd';
import loadedAssetDeviceState from '../../atoms/loadedAssetDevice';
import {useRecoilValue} from 'recoil';
import * as utils from "../utils";
import {useEffect, useState} from "react";
import {formatDate} from "../utils";

const formData = {
    name: '',
    accessKey: '',
    regions: '',
    secretKey: ''
};

const AwsSummary = ({device, onFormChanged}) => {

    const loadedAssetDevice = useRecoilValue(loadedAssetDeviceState);
    const deviceToUse = device || loadedAssetDevice;
    const [name, setName] = useState('');
    const [accessKey, setAccessKey] = useState('');
    const [regions, setRegions] = useState('');
    const [secretKey, setSecretKey] = useState('');

    useEffect( () => {
        if (deviceToUse) {
            const parsedData = deviceToUse.deviceConfig && JSON.parse(deviceToUse.deviceConfig);
            formData.name = deviceToUse.deviceInfo?.name;
            formData.accessKey = parsedData?.access_key;
            formData.regions = parsedData?.regions;
            onFormChanged(formData);
            setName(formData.name);
            setAccessKey(formData.accessKey);
            setRegions(formData.regions);
        }
        return () => {
            formData.secretKey = '';
            formData.accessKey = '';
            formData.regions = '';
            formData.name = '';
        }
    },[]);

    const getDeviceInfo = (info) => {
        const deviceName = utils.devicePlatformName(info.deviceType);
        return {
            platform: deviceName,
            scanned: info.timestamp ? formatDate(info.timestamp) : 'unknown',
        }
    }

    const awsInfo = getDeviceInfo(deviceToUse);

    return <div className="aws-summary" data-testid="device-aws-edit">
        <h1>Cloud account summary</h1>
        <Form
            layout="vertical"
            className="device-view-edit-form"
        >
            <Form.Item label="Name">
                <Input placeholder="Name" value={name} onChange={(e) => {
                    setName(e.target.value);
                    formData.name = e.target.value;
                    onFormChanged(formData);
                }} data-testid="text-name"/>
            </Form.Item>
            <div className="sub-menu">Configuration</div>
            <Form.Item label="Access key">
                <Input placeholder="Access key" value={accessKey} onChange={(e) => {
                    setAccessKey(e.target.value);
                    formData.accessKey = e.target.value;
                    onFormChanged(formData);
                }} data-testid="access-key"/>
            </Form.Item>
            <Form.Item label="Secret key">
                <Input placeholder="*****" value={secretKey} onChange={(e) => {
                    setSecretKey(e.target.value);
                    formData.secretKey = e.target.value;
                    onFormChanged(formData);
                }} data-testid="secret-key"/>
            </Form.Item>
            <Form.Item label="Regions">
                <Input placeholder="Regions" value={regions} onChange={(e) => {
                    setRegions(e.target.value);
                    formData.regions = e.target.value;
                    onFormChanged(formData);
                }} data-testid="regions"/>
            </Form.Item>
        </Form>
        <Button className="test-connection" type="primary" disabled={true} >Test connection</Button>
        <div className="scan-details">
            <h2>Scan Details</h2>
            <table>
                <thead></thead>
                <tbody>
                {awsInfo.platform &&
                    <tr>
                        <th>Platform:</th>
                        <td>{awsInfo.platform}</td>
                    </tr>
                }
                {awsInfo.scanned &&
                    <tr>
                        <th>Scanned:</th>
                        <td>{awsInfo.scanned}</td>
                    </tr>
                }
                {regions &&
                    <tr>
                        <th>Region:</th>
                        <td>{regions}</td>
                    </tr>
                }
                </tbody>
            </table>
        </div>
    </div>
}

export default AwsSummary;