import './usersList.css'
import React, {useEffect, useState} from 'react';
import ApiClient from "../../apiClient";
import { Statistic, Input, Table, Button, Modal, message } from 'antd';
import { DeleteOutlined, PlusOutlined, SettingOutlined, ExportOutlined, SendOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {useNavigate, useParams} from "react-router-dom";
import EmptyGridMessage from "../emptyGrid/emptyGridMessage";
import EmptyUser from "../../assets/images/empty-users.svg";
import {getTag, showDeactivateConfirm} from "../utils";
import {UserAvatar} from "../userAvatar/userAvatar";

const limit = 20;

export default function UsersList ({api}) {

    const [searchValue, setSearchValue] = useState('');
    const [isUsersLoaded, setIsUsersLoaded] = useState(false);
    const [users, setUsers] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [selectedRow, setSelectedRow] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isEmptyPage, setIsEmptyPage] = useState(false);
    const [total, setTotal] = useState(0);
    const [usersStatus, setUsersStatus] = useState(null);

    const { Search } = Input;
    const apiClient = api ? api : new ApiClient();
    const navigate = useNavigate();
    const {orgId} = useParams();

    const columns = [
        {
            title: 'Name',
            dataIndex: ['firstName', 'lastName', 'email', 'index', 'id'],
            key: 'index',
            width: '50%',
            render: (text, record) => {
                return <div className="name-col" data-testid={`item-${record.id}`}>
                    <UserAvatar api={apiClient} name={record.firstName + ' ' + record.lastName} fileName={record.fileName} bucketName='users'/>
                    <div>
                        <a data-testid={`name${record.id}`} onClick={() => redirectToUser(record.id)}>{record.firstName} {record.lastName}</a>
                        <div>{record.email}</div>
                    </div>
                </div>

            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: 'Job title',
            dataIndex: 'jobTitle',
            key: 'jobTitle',
        },
        {
            title: 'Agent Installed',
            dataIndex: 'agentCount',
            key: 'agentInstalled',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'left',
            width: '100px',
            render: (_, { status }) => getTag(status)
        },
    ];

    useEffect(async () => {
        await loadUsers();
    },[page, orgId, searchValue]);

    useEffect(async () => {
        await loadStatus();
    },[orgId]);

    const redirectToUser = (item) => {
        navigate(`${item}/info`);
    }

    const addUser = () => {
        navigate(`add`);

    }

    const showDeactivateUserConfirm = () => {
        const user = selectedRow.length <= 1 ? 'user' : 'users'
        showDeactivateConfirm(user, () => onDeactivate());
    }

    async function onDeactivate () {
        const filter = [];
        selectedRow.forEach(el => filter.push(`id:${el}`));
            const plural = filter.length <= 1 ? '' : 's';
        try {
            await apiClient.get().userApi.userPatch(filter, {active: false});
            message.success(`User${plural} has been successfully deleted`);
            if ((total - filter.length) === ((page - 1) * limit) && page !== 1) {
                setPage(page - 1);
                await loadUsers(page - 1);
            } else {
                await loadUsers();
            }
            await loadStatus();
            setSelectedRowKeys([]);
            setSelectedRow([]);
        } catch (err) {
            message.error(`An error occurred while try to delete user${plural}`);
        }
    }

    const loadStatus = async () => {
        const status = await apiClient.get().userApi.userStatus(
            '',
            [``],
            ['active:true', `organizationId:${orgId}`],
            page,
            20
        );
        setUsersStatus(status.data.data[0]);
    }

   async function loadUsers(currentPage) {
        setIsUsersLoaded(false);
        const loadedUsers = await apiClient.get().userApi.userList(
            searchValue,
            ['+lastName'],
            ['active:true', `organizationId:${orgId}`, 'scanTopic: DEVICES'],
            ['deviceCount', 'agentCount', 'userEmail'],
            currentPage ?? page,
            limit
        );
        setIsUsersLoaded(true);
        setTotal(loadedUsers.data.page.total);
        const convertedData = loadedUsers.data.data.map((item, index) => {
            return {
                key: `user-${item.id}-${index}`,
                index: index,
                id: item.id,
                firstName: item.firstName,
                lastName: item.lastName,
                email: item.email,
                type: item.type[0] + item.type?.toLowerCase().slice(1),
                jobTitle: item.jobTitle,
                agentCount: item.agentCount,
                status: item.status,
                fileName: item.fileName
            }
        });
       if (convertedData.length <= 0) {
           setIsEmptyPage(true);
       } else {
           setIsEmptyPage(false);
       }
        setUsers(convertedData);
        setTotalPages(loadedUsers.data.page.total);
    }

    const onRowChange = (selectedRowKeys, selectedRows) => {
            const selectedUsers = [];
            selectedRows.map(el =>{
                selectedUsers.push(el.id);
            } )
        setSelectedRow(selectedUsers);
        setSelectedRowKeys(selectedRowKeys)
        }

    const onSearch = async (value) => {
        setSearchValue(value);
        setPage(1);
    }

    const handlePagination = async (currentPage) => {
        setPage(currentPage);
        setSelectedRow([]);
        setSelectedRowKeys([]);
    }

    return <div className="users-list" data-testid="users-list">
        <h1>People</h1>
        <div className="stats-container">
            <div>
                <Statistic title="People" value={usersStatus?.total} />
            </div>
            <div>
                <Statistic title="People’s risk score" value='0.12' />
            </div>
        </div>
        <div className="search-container">
            <div>
                <Search disabled={isEmptyPage && searchValue.length === 0} placeholder="Users search" className="search-input" data-testid="user-search" onSearch={onSearch} />
            </div>
            <div className="command-buttons">
                <Button icon={<SendOutlined style={{ fontSize: '12px'}} />} disabled={true} />
                <Button icon={<DeleteOutlined style={{ fontSize: '12px'}} />} danger data-testid="deactivate-user" disabled={selectedRow?.length < 1} onClick={showDeactivateUserConfirm} />
                <Button icon={<ExportOutlined style={{ fontSize: '12px'}} />} disabled={true} />
                <Button type="primary" className='add' icon={<PlusOutlined style={{ fontSize: '11px'}} />} data-testid="add-user" onClick={addUser}>Add</Button>
                <Button icon={<SettingOutlined style={{ fontSize: '12px'}} />} disabled={true} />
            </div>
        </div>
        <div className='filter'>
            <div> <span className='plus'>+</span>Add Filter</div>
        </div>
        {!(isEmptyPage && searchValue.length <= 0) && <div className={isEmptyPage ? 'empty-container' : ''}>
            <Table
                rowSelection={{
                    selectedRowKeys: selectedRowKeys,
                    onChange: onRowChange
                }}
                dataSource={users}
                columns={columns}
                scroll={{y: 'calc(100vh - 456px)'}}
                loading={!isUsersLoaded}
                pagination={
                    {
                        current: page,
                        total: totalPages,
                        showSizeChanger: false,
                        pageSize: limit,
                        onChange: handlePagination
                    }
                }
            />
        </div>}
        {isEmptyPage && <EmptyGridMessage entity={'user'} img={EmptyUser} onAddNew={addUser}/>}
    </div>
}
