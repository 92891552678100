import SidebarLayout from "../sidebarLayout/sidebarLayout";
import { PieChartOutlined, LaptopOutlined, CloudOutlined, AppstoreOutlined, CodeOutlined, SecurityScanOutlined, LinkOutlined } from '@ant-design/icons';
import AssetsSummary from "../assetsSummary/assetsSummary";
import CloudPlatforms from "../cloudPlatforms/cloudPlatforms";
import Devices from "../devices/devices";
import DeviceSummary from "../deviceSummary/deviceSummary";
import AddCloud from "../addCloud/addCloud";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

export default function AssetInventoryPage({ match, api, userId }) {

    const navigate = useNavigate();
    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState(['tab-assets-devices', 'devices']);

    const onTabSelect = (tab) => {
        navigate(`/clients/${match.params.orgId}/assets/${tab[tab.length - 1]}`);
    }

    useEffect(() => {
        const path = match.pathname.split('/');
        const selectedTab = tabs.find((t) => path.includes(t.key));
        if (!selectedTab) {
            tabs.forEach((tab, ) => {
                const selectedChildrenTab = tab.children?.find((t) => path.includes(t.key));
                if (selectedChildrenTab) {
                    return setDefaultSelectedKeys([ tab.key, selectedChildrenTab.key]);
                }
            })
        } else {
            setDefaultSelectedKeys([selectedTab.key]);
        }
    },[match.pathname]);

    const tabs = [
        { "data-testid": 'tab-assets-summary', key: 'summary', title: 'Summary', label: 'Summary', icon: <PieChartOutlined />, disabled: false, page: <AssetsSummary/>, onClick: () => { onTabSelect(['summary'])}  },
        { "data-testid": 'tab-assets-devices', key: 'tab-assets-devices', title: 'Devices', label: 'Devices', icon: <LaptopOutlined />, disabled: false,
            children: [
                { "data-testid": 'tab-assets-devices-list', key: 'devices', title: 'Devices list', label: 'Devices list', disabled: false, page: <Devices api={api}/>, onClick: () => { onTabSelect(['tab-assets-devices', 'devices'])} },
                { "data-testid": 'tab-assets-device-vulnerabilities', key: 'tab-assets-device-vulnerabilities', title: 'Device vulnerabilities', label: 'Device vulnerabilities', disabled: true },
            ]
        },
        { "data-testid": 'tab-assets-cloud', key: 'tab-assets-clouds', title: 'Cloud platforms', label: 'Cloud platforms', icon: <CloudOutlined />, disabled: false,
            children: [
                { "data-testid": 'tab-assets-cloud-account', key: 'clouds', title: 'Cloud accounts', label: 'Cloud accounts', disabled: false, page: <CloudPlatforms/>,  onClick: () => { onTabSelect(['tab-assets-cloud', 'clouds'])} },
                { "data-testid": 'tab-assets-cloud-issues', key: 'tab-assets-cloud-issues', title: 'Clouds issues', label: 'Clouds issues', disabled: true },
            ]
        },
        { "data-testid": 'tab-assets-applications', key: 'tab-assets-applications', title: 'Applications', label: 'Applications', icon: <AppstoreOutlined />, disabled: true },
        { "data-testid": 'tab-assets-my-app', key: 'tab-assets-my-app', title: 'My app', label: 'My app', icon: <CodeOutlined />, disabled: true },
        { "data-testid": 'tab-assets-external-surface', key: 'tab-assets-external-surface', title: 'My app', label: 'External surface', icon: <SecurityScanOutlined />, disabled: true },
        { "data-testid": 'tab-assets-supply-chain', key: 'tab-assets-supply-chain', title: 'Supply chain', label: 'Supply chain', icon: <LinkOutlined />, disabled: true },
    ];

    const deviceSummaryTab =
        {
            "data-testid": 'tab-assets-device-summary',
            key: 'tab-assets-device-summary',
            title: 'Device Summary',
            label: 'Device Summary',
            disabled: false,
            page: <DeviceSummary match={match} api={api}/>
        }

    const getSelectedTab = () => {

        let result = null;

        const lastSelectedKey = defaultSelectedKeys.length > 0
            ? defaultSelectedKeys[defaultSelectedKeys.length - 1]
            : null;

        if (lastSelectedKey) {
            for (const tab of tabs) {
                if (tab.key === lastSelectedKey) {
                    result = tab;
                }
                if (!result && tab.children && tab.children.length > 0) {
                    result = tab.children.find(c => c.key === lastSelectedKey);
                }
            }
        }

        if (result === null) {
            result = tabs[1].children[0];
        }

        return result;
    }

    const cloudSummaryTab =
        {
            "data-testid": 'tab-assets-cloud-summary',
            key: 'tab-assets-cloud-summary',
            title: 'Cloud Summary',
            label: 'Cloud Summary',
            disabled: false,
            page: <DeviceSummary type={'cloud'} match={match} api={api}/>
        }

    const addCloudSummaryTab =
        {
            "data-testid": 'tab-assets-cloud-summary',
            key: 'tab-assets-cloud-summary',
            title: 'Cloud Summary',
            label: 'Cloud Summary',
            disabled: false,
            page: <AddCloud type={'cloud'} userId={userId} deviceId={match.params.deviceId} orgId={match.params.orgId} api={api}/>
        };
    let defaultTab;
    let defaultOpenKeys = ['tab-assets-devices'];
    const pathname = match.pathname;

    if (match.pathname.includes('clouds')) {
        defaultOpenKeys = ['tab-assets-clouds'];
    }

    switch (true) {
        case pathname.includes('device-summary') || pathname.includes('vulnerabilities'):
            defaultTab = deviceSummaryTab;
            break;
        case pathname.includes('add-cloud'):
            defaultTab = addCloudSummaryTab;
            break;
        case pathname.includes('cloud-summary') || pathname.includes('issues'):
            defaultTab = cloudSummaryTab;
            break;
        default:
            defaultTab = getSelectedTab();
    }

    return <SidebarLayout
                defaultSelectedPage={defaultTab}
                defaultSelectedKeys={defaultSelectedKeys}
                defaultOpenKeys={defaultOpenKeys}
                sidebarTabs={tabs}
                collapsed={true}
                sideBarStyle={
                    {
                        width: '256px',
                        height: '100%',
                        backgroundColor: '#F5F5F5'
                    }
                }
            />
}
