import TemplateTaskLinkageController from "../controllers/templateTaskLinkageController";

const useTemplateTaskLinkageOperations = (api) => {


    const templateTaskLinkageController = new TemplateTaskLinkageController({apiClient: api});

    const createTemplateTasksLinkage = async (params) => {
        await templateTaskLinkageController.templateTasksLinkageCreate(params);
    }

    const deleteTemplateTasksLinkage = async (filter) => {
        await templateTaskLinkageController.templateTasksLinkageDelete(filter);
    }

    return [createTemplateTasksLinkage, deleteTemplateTasksLinkage];
};

export default useTemplateTaskLinkageOperations;