import './templateSummaryPage.css';
import {
    ArrowLeftOutlined,
    CheckSquareOutlined,
    DeleteOutlined, FileTextOutlined,
    ProjectOutlined,
} from "@ant-design/icons";
import {Button, Spin} from "antd";
import SidebarLayout from "../sidebarLayout/sidebarLayout";
import {useEffect, useState} from "react";
import TemplateSummary from "../templateSummary/templateSummary";
import EditTemplateSummary from "../editTemplateSummary/editTemplateSummary";
import {useNavigate} from "react-router-dom";
import {RolesMapper} from "../../constants";
import ApiClient from "../../apiClient";
import {showDeactivateConfirm} from "../utils";
import TasksTemplates from '../tasksTemplates/tasksTemplates';

const TemplateSummaryPage = ({match, api, accessScope}) => {

    const navigate = useNavigate();
    const [orgUsers, setOrgUsers] = useState([]);
    const apiClient = api || new ApiClient();
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [selectedTabKey, setSelectedTabKey] = useState(match.params.page || 'summary');

    const onTemplateSaved = async (template) => {
        const loadedTemplate = await loadTemplate(template);
        if (loadedTemplate) {
            setSelectedTemplate(loadedTemplate);
        }
    }

    const changeTab = (key) => {
        setSelectedTabKey(key);
        navigate(`/projects/templates/${key}/${selectedTemplate?.id ?? match?.params.templateId}`);
    }

    const getPage = () =>{
        if(selectedTemplate?.id){
           return <EditTemplateSummary users={orgUsers} onSave={onTemplateSaved} loggedUser={accessScope} template={selectedTemplate} api={apiClient}/>
        } else {
            return <TemplateSummary users={orgUsers} onSave={onTemplateSaved} loggedUser={accessScope} template={selectedTemplate} api={apiClient}/>
        }
    }

    const tabs = [
        {
            'data-testid': 'tab-template-summary',
            key: 'summary',
            title: 'Summary',
            label: 'Summary',
            icon: <ProjectOutlined/>,
            disabled: false,
            onClick: () => changeTab('summary'),
            page: getPage()
        },
        {
            'data-testid': 'tab-project-tasks',
            key: 'template-tasks',
            title: 'Tasks',
            label: 'Tasks',
            icon: <CheckSquareOutlined/>,
            disabled: selectedTemplate === null,
            page: <TasksTemplates api={apiClient} template={selectedTemplate} accessScope={accessScope} />,
            onClick: () => changeTab('template-tasks')
        },
        {
            'data-testid': 'tab-template-documents',
            key: 'documents',
            title: 'Documents',
            label: 'Documents',
            icon: <FileTextOutlined/>,
            disabled: true,
            page: <div>Documents</div>,
        }
    ];

    useEffect(async() => {
        await loadUsers();
    }, []);

    useEffect(async () => {
        if (match.params.templateId || match.params.page) {
            const template = await loadTemplate(match.params.templateId);
            setSelectedTemplate(template);
            changeTab(match.params.page);
        }
    }, [match.params]);

    const loadTemplate = async (templateId) => {
        const loadedTemplate = await apiClient.get().templateApi.templateGetById(templateId);
        if (loadedTemplate.data) {
            return loadedTemplate.data;
        }
        return null;
    }

    const loadUsers = async () => {
        const data = [];
        if (accessScope.roleId === RolesMapper.UBER_ADMIN) {
            let currentPage = 1;
            let load = true;
            while (load) {
                const users = await apiClient.get().userApi.userList(
                    '',
                    ['+lastName'],
                    ['active:true', `roleId:${RolesMapper.UBER_ADMIN}`],
                    [],
                    currentPage,
                    50
                );
                for (const element of users.data.data) {
                    data.push({
                        id: element.id,
                        name: `${element.firstName} ${element.lastName}`,
                        fileName: element.fileName
                    });
                }
                load = users.data.page.next !== null;
                currentPage = users.data.page.next;
            }
        }
        setOrgUsers(data);
    }

    const deleteTemplate = async (id) => {
        await apiClient.get().templateApi.templatePatch([`id:${id}`], {active: false});
        navigate('/projects/templates')
    }

    const doDeleteConfirmation = (id) => {
        showDeactivateConfirm('template', () => deleteTemplate(id));
    }

    const selectedTab = tabs.find(t => t.key === selectedTabKey) || tabs[0];
    let title  = selectedTemplate ? selectedTemplate.title : 'Template Name';

    if (match.params.templateId && !selectedTemplate) {
        return <div className="template-summary-page-loader"><Spin size="large" /></div>
    }

    return <div className="template-summary-page">
        <div className="template-summary-header" data-testid="template-summary-header">
            <div>
                <ArrowLeftOutlined onClick={() => navigate(`/projects/templates`)} style={{ fontSize: '17px', color: '#262626', cursor: 'pointer' }}  /> <span className="template-name">{title}</span>
            </div>
            <div>
                {(match.params.templateId && selectedTemplate) ? <Button icon={<DeleteOutlined onClick={() => doDeleteConfirmation(selectedTemplate.id)} style={{ fontSize: '12px', color: '#F5222D' }}/>} danger></Button> : <div className="button-placeholder"/>}
            </div>
        </div>
        <SidebarLayout
            containerCssClass="template-tasks-layout"
            defaultSelectedPage={selectedTab}
            defaultSelectedKeys={selectedTab.key}
            defaultOpenKeys={[selectedTab.key]}
            sidebarTabs={tabs}
            menuContainerCssClass="template-layout-menu"
            collapsed={false}
            sideBarStyle={
                {
                    width: '220px',
                    height: '100%',
                    backgroundColor: '#FFFFFF'
                }
            }
        />
    </div>
}

export default TemplateSummaryPage;