import './deviceSummaryHeader.css';
import HeaderBack from '../../assets/images/header-back.svg';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import * as utils from "../utils";

const DeviceSummaryHeader = ({onBack, onSave, onDelete, device, disabled, showDelete, showCancel, onCancel, showOnlineStatus}) => {

    const getDeviceIcon = () => {

        const icon = utils.devicePlatformIcon(device.deviceType);
        const isOnline = utils.deviceIsOnline(device.timestamp);
        const cssClassName = isOnline ? 'icon-status status-online' : 'icon-status status-offline';
        const showOnlineIcon = showOnlineStatus === undefined ? true : showOnlineStatus;
        return <div className="query-icon position-right">
            <img src={icon} className="title-img" alt=""/>
            {showOnlineIcon && <div className={cssClassName}/>}
        </div>;
    }

    const isDeleteAvailable = showDelete === undefined ? false : showDelete;
    const isCancelAvailable = showCancel === undefined ? false : showCancel;

    return <div className="device-summary-header" data-testid="device-summary-header">
        <div>
            <img src={HeaderBack} alt="Back" onClick={onBack} data-testid="back-button" />
            {getDeviceIcon()}
            <div className="inventory-name" data-testid="inventory-name" title={device.deviceName}>{device.deviceName ? device.deviceName: 'Unknown name'}</div>
        </div>
        <div>
            {isDeleteAvailable && <Tooltip title="Delete">
                <Button danger icon={<DeleteOutlined />} data-testid="delete-button" onClick={onDelete}/>
            </Tooltip>
            }
            {isCancelAvailable && <Button data-testid="cancel-button" onClick={onCancel}>Cancel</Button>}
            <Button data-testid="save-button" type="primary" onClick={onSave} disabled={disabled === undefined ? false : disabled}>Save</Button>
        </div>
    </div>
}

export default DeviceSummaryHeader;