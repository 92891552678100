import './tasksList.css';
import {
    Button,
    Checkbox,
    Collapse,
    DatePicker,
    Divider,
    Form,
    Input,
    Layout,
    message,
    Modal,
    Radio,
    Select,
    Space,
    Table,
    Tag,
    Tooltip,
    Typography,
} from 'antd';
import {
    CaretRightOutlined,
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    MinusCircleOutlined,
    NodeExpandOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import {useEffect, useRef, useState} from "react";
import EmptyPage from "../emptyPage/emptyPage";
import ApiClient from "../../apiClient";
import emptySearchResult from "../../assets/images/empty-search-result.svg";
import moment from "moment";
import {useNavigate, useParams} from "react-router-dom";
import EditTaskSidebar from "../editTaskSidebar/editTaskSidebar";
import {useRecoilValue} from "recoil";
import isRuleMenuCollapsed from "../../atoms/isRuleMenuCollapsed";
import {UserAvatar} from "../userAvatar/userAvatar";
import Stat from "../stat/stat";
import {formatMessageDate, showDeactivateConfirm} from "../utils";
import {RolesMapper} from "../../constants";
import ChartList from "../chartList/chartList";
import taskAssignedNotificationsState from "../../atoms/taskAssignedNotifications";
import deadlineTomorrowNotificationsState from "../../atoms/deadlineTomorrowNotification";

const { Panel } = Collapse;
const { Content, Sider } = Layout;
const { RangePicker } = DatePicker;

let page = 1;
const limit = 10;
const DELETE_TASKS = 'DELETE_TASKS';
const MOVE_TO_PHASE = 'MOVE_TO_PHASE';

const TasksList = ({api, accessScope, project, taskPrefix, isTemplates, template}) => {
    const { Search } = Input;
    const [searchValue, setSearchValue] = useState('');
    const [isEmptyPage, setIsEmptyPage] = useState(false);
    const [isTasksLoaded, setIsTasksLoaded] = useState(false);
    const [isAddTaskOpenModal, setIsAddTaskOpenModal] = useState(false);
    const [isAddPhaseOpenModal, setIsAddPhaseOpenModal] = useState(false);
    const [phases, setPhases] = useState([]);
    const [activeKeys, setActiveKeys] = useState(null);
    const [phaseId, setPhaseId] = useState(null);
    const [phaseName, setPhaseName] = useState('');
    const [editPhase, setEditPhase] = useState(null);
    const [editPhaseName, setEditPhaseName] = useState('');
    const [tasks, setTasks] = useState([]);
    const [editTask, setEditTask] = useState(null);
    const [totalPages, setTotalPages] = useState(0);
    const [taskName, setTaskName] = useState('');
    const [description, setDescription] = useState('');
    const [priority, setPriority] = useState('DEFAULT');
    const [allowDueDate, setAllowDueDate] = useState(false);
    const [dueDate, setDueDate] = useState(null);
    const [owner, setOwner] = useState(null);
    const [users, setUsers] = useState([]);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [tasksStatus, setTaskStatus] = useState(null);
    const [selectedRow, setSelectedRow] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedPhaseName, setSelectedPhaseName] = useState([]);
    const [type, setType] = useState('LIST');
    const [dateRange, setDateRange] = useState(null);
    const [duration, setDuration] = useState(null);

    const {projectId, orgId} = useParams();
    const isCollapsed = useRecoilValue(isRuleMenuCollapsed);
    const critical = 'CRITICAL';
    const blocker = 'BLOCKER';
    const navigate = useNavigate();
    const editPhaseNameRef = useRef(null);
    const editPhaseNameCheckRef = useRef(null);
    const phaseNameInputRef = useRef(null);
    const addTaskInputRef = useRef(null);
    const assignedTasksNotifications = useRecoilValue(taskAssignedNotificationsState);
    const deadlineTomorrowNotifications = useRecoilValue(deadlineTomorrowNotificationsState);

    const apiClient = api || new ApiClient();
    const customFormat = (value) => value.format('MMM DD, YYYY');
    const isUber = accessScope.roleId === RolesMapper.UBER_ADMIN;

    const statusColumn = {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'left',
        width: '105px',
        render: (_, { status }) => {
            let color;
            let title;
            if(status==='TODO') {
                color = 'orange';
                title = 'To do';
            } else if(status==='IN PROGRESS') {
                color = 'green';
                title = 'In progress';
            } else if(status==='DONE') {
                color = 'blue';
                title = 'Done';
            }
            return (
                <>
                    <Tag color={color} key={status}>
                        {title}
                    </Tag>
                </>
            )},
    };

    const lastCommentColumn = editTask ? {} : {
        title: 'Last comment',
        dataIndex: 'comment',
        key: 'comment',
        render: (text, comment) => {
            if (!comment.dateCreated) {
                return;
            }
            const commentDate = formatMessageDate(comment.dateCreated, null, true, true);
            return <div className={`message-wrapper ${comment.authorRoleId === RolesMapper.UBER_ADMIN ? 'uber' : ''}`}>
                <div>
                    <span>{comment.authorFirstName} {comment.authorLastName}</span>
                    <span>{commentDate.date}</span>
                </div>
                <div>{comment.text}</div>
            </div>
        },
        width: 196
    };

    const columnsTask = project ? [
        {
            title: 'Prefix',
            dataIndex: 'prefix',
            key: 'prefix',
            align: 'left',
            width: '110px',
            render: (text, record) => {
                const notificationsCount = assignedTasksNotifications.filter(n => n.params.taskKey === record.prefix).length
                    + deadlineTomorrowNotifications.filter(n => n.params.taskKey === record.prefix).length;
                return <>{notificationsCount > 0 ?
                    <div className="notification-informer"/> :
                    <div className="notification-informer notification-informer-empty"/>}{text}</>
            }
        },
        {
            title: 'Task name',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
            width: editTask ? '44.74%' : '159px',
            render: (text, record) => {
                return <Tooltip className="task-name" placement="topLeft" title={record.name}>{record.name}</Tooltip>
            }
        },
        editTask ? {} : {
            title: 'Due to',
            dataIndex: 'dueDate',
            key: 'dueDate',
            align: 'right',
            width: '150px'
        },
        {
            title: 'Assignee',
            dataIndex: 'assignee',
            key: 'assignee',
            align: 'left',
            width: '95px',
            render: (text, record) => {
                return record.assignees?.map((assignee)=>{
                    if (assignee?.assigneeName) {
                        return <Tooltip key={assignee.assigneeId}
                                        placement="top" title={assignee?.assigneeName}
                                        className={record.assignees[1].assigneeId ? `avatar-assignee` : ''}>
                            <span className={record.assignees[1].assigneeId ? `avatar-assignee` : ''}>
                                <UserAvatar name={assignee.assigneeName} fileName={assignee.fileName}/>
                            </span>
                        </Tooltip>
                    }
                })
            }
        },
        !isCollapsed && editTask ? {width: 0} :
            {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority',
            align: 'left',
            width: '90px',
            render: (_, { priority }) => {
                let color;
                let title;
                if(priority===critical) {
                    color = 'red';
                    title = 'Critical';
                }else if (priority===blocker) {
                    color = 'warning';
                    title = 'Blocker';
                } else {
                    return;
                }
                return (
                    <>
                        <Tag color={color} key={priority}>
                            {title}
                        </Tag>
                    </>
                )
            }
        },
        statusColumn,
        lastCommentColumn
    ] : [
        {
            title: 'Task name',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
            render: (text, record) => {
                const notificationsCount = assignedTasksNotifications.filter(n => n.params.taskKey === record.prefix).length
                    + deadlineTomorrowNotifications.filter(n => n.params.taskKey === record.prefix).length;
                return <Tooltip className="task-name" placement="topLeft" title={record.name}>{notificationsCount > 0 ?
                    <div className="notification-informer"/> :
                    <div className="notification-informer notification-informer-empty"/>}{record.name}</Tooltip>
            }
        },
        {
            title: 'Project',
            dataIndex: 'projectName',
            key: 'project',
            align: 'left',
            width: '170px',
            ellipsis: {
                showTitle: false
            },
            render: (text, record) => {
                return <Tooltip placement="topLeft" title={record.projectName}>{record.projectName}</Tooltip>
            }
        },
        !orgId ? {
            title: 'Clients',
            dataIndex: 'organizationName',
            key: 'clients',
            align: 'left',
            width: '150px',
        } : {width: 0},
        statusColumn,
        lastCommentColumn
    ];

    const columnsTemplateTask = [
        {
            title: 'Task name',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
            render: (text, record) => {
                return <Tooltip className="task-name" placement="topLeft" title={record.name}>{record.name}</Tooltip>
            }
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority',
            align: 'left',
            width: '100px',
            render: (_, { priority }) => {
                let color;
                let title;
                if(priority === critical) {
                    color = 'red';
                    title = 'Critical';
                } else if(priority === blocker) {
                    color = 'warning';
                    title = 'Blocker';
                } else {
                    return;
                }
                return (
                    <>
                        <Tag color={color} key={priority}>
                            {title}
                        </Tag>
                    </>
                )
            }
        }
    ];

    useEffect(() => {
        return () => {
            page = 1;
        }
    }, []);

    useEffect( async () => {
        await loadTasks();
    },[project, template]);

    useEffect( async () => {
        if (!isTemplates && orgId) {
            await loadUsers();
        }
    },[]);

    useEffect( async () => {
        if (!isTemplates && (isAddTaskOpenModal || editTask)) {
                await loadUsers();
        }
    },[isAddTaskOpenModal, editTask]);

    useEffect(() => {
        if (phaseNameInputRef.current) {
            phaseNameInputRef.current.focus();
        }
    }, [isAddPhaseOpenModal]);

    useEffect(() => {
        if (addTaskInputRef.current) {
            addTaskInputRef.current.focus();
        }
    }, [isAddTaskOpenModal]);


    useEffect(() => {
        function handleClickOutsideEditPhaseName(event) {
            if (editPhaseNameRef.current
                && !editPhaseNameRef.current.contains(event.target)
                && editPhaseNameCheckRef
                && !editPhaseNameCheckRef.current.contains(event.target)) {
                editPhaseNameCancel();
            }
        }
        document.addEventListener("mousedown", handleClickOutsideEditPhaseName);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideEditPhaseName);
        };
    }, [editPhaseNameRef, editPhaseNameCheckRef]);

    const onRowChange = (selectedRowKeys, selectedRows, phaseName) => {
        const selectedTasks = [];
        selectedRows.map(el =>{
            selectedTasks.push(el.id);
        } )
        setSelectedRow(selectedTasks);
        setSelectedRowKeys(selectedRowKeys);
        setSelectedPhaseName(phaseName);
    }

    const showDeactivateTasksConfirm = (e) => {
        const tasks = selectedRow.length <= 1 ? 'task' : 'tasks'
        showDeactivateConfirm(tasks, () => onDeactivate());
        e.stopPropagation();
    }

    const phasesToDisplay = phases
        .filter(phase => phase.name !== selectedPhaseName)
        .map(phase => {
        return {
            label: <div className="user-item">
                 <span style={{marginLeft: '4px'}}>{phase.name}</span>
            </div>,
            value: phase.id
        }
    });

    function showPhaseMoveConfirm(title) {
        const phase = phases.find(phase => phase.name === selectedPhaseName);
        let phaseId = phases.find(p => p.id > phase.id)?.id??[...phases].reverse().find(p => p.id < phase.id).id;
        return Modal.confirm({
            title: `Move ${title} to the phase`,
            icon: null,
            closable: true,
            content: <Form layout="vertical"
                           className="phase-move-form">
                <Form.Item label="Phase">
                    <Select
                        filterOption={(input, option) => {
                            const phase = phases.find(p => p.name === option.value);
                            return phase && phase.name.toLowerCase().includes(input.toLowerCase());
                        }}
                        data-testid="phaseName"
                        style={{width: '100%'}}
                        onChange={(value) => phaseId = value}
                        defaultValue={phaseId}
                        options={phasesToDisplay}
                    />
                </Form.Item>
            </Form>,
            okText: 'Move',
            cancelText: 'Cancel',
            className: `move-phase`,
            cancelButtonProps: {type: 'default'},
            okButtonProps: {'data-testid': `move-${title}-confirm`, type: 'primary'},
            async onOk() {
                await onMovePhase(phaseId)
            }
        })
    }

    const showMovePhaseModal = (e) => {
        const tasks = selectedRow.length <= 1 ? 'task' : 'tasks'
        showPhaseMoveConfirm(tasks)
        e.stopPropagation();
    }

    async function onDeactivate () {
        const filter = [];
        selectedRow.forEach(el => filter.push(`id:${el}`));
        const plural = filter.length <= 1 ? '' : 's';
        try {
            if (isTemplates) {
                await apiClient.get().templateTasksApi.templateTasksPatch(filter, {active: false});
            } else {
                await apiClient.get().tasksApi.taskPatch(filter, {active: false});
            }
            message.success(`Task${plural} has been successfully deleted`);
            await onRefresh(null, true);
            setSelectedRowKeys([]);
            setSelectedRow([]);
            setSelectedPhaseName([]);
        } catch (err) {
            message.error(`An error occurred while try to delete task${plural}`);
        }
    }

    async function onMovePhase(phaseId) {
        const filter = [];
        selectedRow.forEach(el => filter.push(`id:${el}`));
        const plural = filter.length <= 1 ? '' : 's';
        try {
            if (isTemplates) {
                await apiClient.get().templateTasksApi.templateTasksPatch(filter,  {templateTaskPhaseId: phaseId});
            } else {
                await apiClient.get().tasksApi.taskPatch(filter, {taskPhaseId: phaseId});
            }
            message.success(`Task${plural} has been successfully moved`);
            await onRefresh(null, true);
            setSelectedRowKeys([]);
            setSelectedRow([]);
            setSelectedPhaseName([]);
        } catch (err) {
            message.error(`An error occurred while try to move phase${plural}`);
        }
    }

    const loadTasks = async (editTaskId) => {
        if (isTemplates && !template) {
            return;
        }
        setIsTasksLoaded(false);
        let editTaskIdItem;
        if((project?.type === 'VX_SERVICE' || template?.projectType === 'VX_SERVICE')) {
            const phaseItems = await loadPhaseItems();
            (await updatePhaseCollapsedTasks(phaseItems))
                .find((p) => p.tasks.find((t) => {
                    if(t.id === editTaskId) {
                        editTaskIdItem = t;
                        return true;
                    } else {
                        return false;
                    }
                }));
        } else {
            const {tasks, total} = await loadTaskItems(page);
            setTasks(tasks);
            setTotalPages(total);
            editTaskIdItem = tasks.find((t) => t.id === editTaskId);
            if (tasks.length <= 0 && searchValue.length === 0) {
                setIsEmptyPage(true);
            } else {
                setIsEmptyPage(false);
            }
        }
        setEditTask(editTaskIdItem);
        if (taskPrefix) {
            await loadTaskDetails(taskPrefix);
        }
        setIsTasksLoaded(true);
    }

    const loadPhaseItems = async () => {
        const currentProjectId = taskPrefix ? project.id : projectId;
        let loadedPhases;
        if(isTemplates) {
            loadedPhases = await apiClient.get().templateTaskPhasesApi.templateTaskPhasesList(
                [],
                [`+id`],
                ['active:true', `templateId:${template.id}`],
                ['tasksCount'],
                1,
                50
            );
        } else {
            loadedPhases = await apiClient.get().taskPhaseApi.taskPhaseList(
                [],
                [`+id`],
                ['active:true', `projectId:${currentProjectId}`],
                ['tasksCount'],
                1,
                50
            );
        }
        return loadedPhases.data.data.map((phase, index) => {
            return {
                ...phase,
                key: `phase-${phase.id}`,
                collapsed: phases.find(f => f.id === phase.id)?.collapsed || index === 0,
                tasks: [],
                tasksPage: phases.find(f => f.id === phase.id)?.tasksPage || 1,
                tasksLoaded: false,
            }
        });
    }

    const loadTaskItems = async (currentPage, phaseId = null) => {
        const currentProjectId = taskPrefix ? project.id : projectId;
        let loadedTasks;
        const searchItems = searchValue.split(' ');
        if (isTemplates) {
            loadedTasks = await apiClient.get().templateTasksApi.templateTasksList(
                searchItems,
                [`+id`],
                ['active:true', `templateId:${template.id}`, `templateTaskPhaseId:${phaseId}`],
                ['templateTaskLinkage'],
                currentPage,
                limit
            );
        } else if (currentProjectId) {
            loadedTasks = await apiClient.get().tasksApi.taskList(
                searchItems,
                [`+id`],
                ['active:true', `projectId:${currentProjectId}`, `taskPhaseId:${phaseId}`],
                ['lastMessage', 'taskLinkage'],
                currentPage,
                limit
            );
        } else {
            const filter = orgId
                ? ['active:true', `assignees:${accessScope.userId}`, `organizationId: ${orgId}`]
                : ['active:true', `assignees:${accessScope.userId}`]
            const taskStatus = await apiClient.get().tasksApi.taskStatus('',  filter);
            const newStatus = {
                toDo: taskStatus.data.data[0].to_do,
                done: taskStatus.data.data[0].completed,
                inProgress: taskStatus.data.data[0].in_progress
            }
            setTaskStatus({...newStatus});
            loadedTasks = await apiClient.get().tasksApi.taskList(
                searchItems,
                [`+id`],
                filter,
                ['organizationName', 'projectName', 'lastMessage', 'taskLinkage'],
                currentPage,
                limit
            );
        }

        const taskItems = loadedTasks.data.data.map((item, index) => {
            return {
                id: item.id,
                key: `task-${item.id}-${index}`,
                prefix: item.key,
                projectPrefix: item.prefix,
                name: item.name,
                dueDate: item.dateDueTo && moment(Date.parse(item.dateDueTo)).format("MMMM DD, YYYY"),
                assignees: [
                    {
                        assigneeName: item.assigneeFirstName ? `${item.assigneeFirstName} ${item.assigneeLastName}` : '',
                        assigneeId: item.assigneeId,
                        fileName: item.assigneeFileName
                    },
                    {
                        assigneeName: item.assignee2FirstName ? `${item.assignee2FirstName} ${item.assignee2LastName}` : '',
                        assigneeId: item.assigneeId2,
                        fileName: item.assignee2FileName
                    }],
                createdBy: item.userFirstName ? `${item.userFirstName} ${item.userLastName}` : '',
                start: item.startDate,
                end: item.endDate,
                description: item.description,
                priority: item.priority,
                fileName: item.userFileName,
                projectId: item.projectId,
                orgId: item.organizationId,
                status: item.status,
                authorFirstName: item.authorFirstName,
                authorLastName: item.authorLastName,
                authorRoleId: item.authorRoleId,
                phaseId: isTemplates ? item.templateTaskPhaseId : item.taskPhaseId,
                linkageName: item.linkageName,
                linkageKey: item.linkageKey,
                text: item.text,
                dateCreated: item.dateCreated,
                organizationName: item.organizationName,
                duration: item.duration,
                projectName: item.projectName
            }
        });
        return {tasks: taskItems, total: loadedTasks.data.page.total, lastPage: loadedTasks.data.page.last, currentPage: loadedTasks.data.page.current};
    }

    const loadTaskDetails = async (prefix) => {
        if (prefix) {
            const taskIndex = prefix.split('-')[1];
            const loadedTaskDetails = await apiClient.get().tasksApi.taskList(
                [],
                [`+id`],
                ['active:true', `projectId:${project.id}`, `index:${taskIndex}`],
                ['taskLinkage'],
                1,
                50
            );
            if (loadedTaskDetails && loadedTaskDetails.data.data.length > 0) {
                const task = loadedTaskDetails.data.data[0];
                setDefaultTask({
                    id: task.id,
                    key: `task-${task.id}-${taskIndex}`,
                    prefix: task.key,
                    name: task.name,
                    assignees: [
                        {
                            assigneeName: task.assigneeFirstName ? `${task.assigneeFirstName} ${task.assigneeLastName}` : '',
                            assigneeId: task.assigneeId,
                            fileName: task.assigneeFileName

                        },
                        {
                            assigneeName: task.assignee2FirstName ? `${task.assignee2FirstName} ${task.assignee2LastName}` : '',
                            assigneeId: task.assigneeId2,
                            fileName: task.assignee2FileName

                        }],
                    dueDate: task.dateDueTo && moment(Date.parse(task.dateDueTo)).format("MMMM DD, YYYY"),
                    start: task.startDate,
                    end: task.endDate,
                    createdBy: task.userFirstName ? `${task.userFirstName} ${task.userLastName}` : '',
                    description: task.description,
                    priority: task.priority,
                    fileName: task.userFileName,
                    status: task.status,
                    orgId: task.organizationId,
                    projectId: task.projectId,
                    phaseId: task.taskPhaseId,
                    linkageName: task.linkageName,
                    linkageKey: task.linkageKey,
                    authorRoleId: task.authorRoleId,
                    projectPrefix: task.prefix,
                    organizationName: task.organizationName,
                    duration: task.duration,
                    projectName: task.projectName
                });
            } else {
                navigate(`/clients/${orgId}/projects/details/${project.id}/tasks`);
            }
        }
    }

    const loadUsers = async () => {
        let page = 1;
        const result = [];
        let loadMore = true;
        while (loadMore) {
            const users = await apiClient.get().userApi.userList(
                null,
                ['+lastName'],
                ['active:true', `organizationId:${orgId ?? editTask?.orgId}`, `roleId:2`],
                [],
                page,
                50
            );
            users.data.data.forEach(u => {
                result.push({
                    id: u.id,
                    name: `${u.firstName} ${u.lastName}`,
                    fileName: u.fileName
                })
            });
            page = users.data.page.next;
            loadMore = users.data.page.next !== null;
        }
        if (isUber) {
            let loadMore = true;
            let page = 1;
            while (loadMore) {
                const users = await apiClient.get().userApi.userList(
                    null,
                    ['+lastName'],
                    ['active:true', `roleId:1`],
                    [],
                    page,
                    50
                );
                for (const user of users.data.data) {
                    result.push({
                        id: user.id,
                        name: `${user.firstName} ${user.lastName}`,
                        fileName: user.fileName
                    });
                }
                page = users.data.page.next;
                loadMore = users.data.page.next !== null;
            }
        }
        setUsers(result);
    }

    const updatePhaseCollapsedTasks = async (phaseItems) => {
        for(let i=0; phaseItems.length > i; i++) {
            if(phaseItems[i].collapsed && !phaseItems[i].tasksLoaded) {
                let taskLoaded = await loadTaskItems(phaseItems[i].tasksPage, phaseItems[i].id);
                if(taskLoaded.lastPage < phaseItems[i].tasksPage) {
                    taskLoaded = await loadTaskItems(phaseItems[i].tasksPage-1, phaseItems[i].id);
                }
                phaseItems[i].tasks = taskLoaded.tasks;
                phaseItems[i].tasksTotal = taskLoaded.total;
                phaseItems[i].tasksPage = taskLoaded.currentPage;
                phaseItems[i].tasksLoaded = true;
            } else if (!phaseItems[i].collapsed) {
                phaseItems[i].tasks = [];
                phaseItems[i].tasksTotal = 0;
                phaseItems[i].tasksPage = 1;
                phaseItems[i].tasksLoaded = false;
            }
        }
        setPhases([...phaseItems]);
        if (phaseItems.length <= 0 && searchValue.length === 0) {
            setIsEmptyPage(true);
        } else {
            setIsEmptyPage(false);
        }
        return phaseItems;
    }

    const setDefaultTask = (task) => {
        setShowDetailsModal(true);
        setEditTask(task);
    }

    const closeModal = () => {
        setIsAddTaskOpenModal(false);
        setIsAddPhaseOpenModal(false);
        setShowDetailsModal(false);
        setTaskName('');
        setPhaseId(null);
        setPhaseName('');
        setDescription('');
        setPriority('DEFAULT');
        setAllowDueDate(false);
        setDueDate(null);
        setOwner(null);
        setUsers([]);
        setDuration(null);
        setDateRange(null);
    }

    const onSearch = async (value) => {
        page = 1;
        setSearchValue(value);
        await loadTasks();
    }

    const createTask = async () => {
        try {
            if (isTemplates) {
                const task = {
                    templateId: template.id,
                    priority: priority,
                    active: true,
                    duration: duration ?? 0,
                    name: taskName,
                    description: description,
                    templateTaskPhaseId: phaseId,
                };
                await apiClient.get().templateTasksApi.templateTasksCreate(task);
            } else {
                const task = {
                    organizationId: orgId,
                    userId: accessScope.userId,
                    projectId: project.id,
                    priority: priority,
                    active: true,
                    name: taskName,
                    dateDueTo: allowDueDate ? dueDate : null,
                    prefix: project.prefix,
                    description: description,
                    startDate: dateRange ? dateRange[0] : null,
                    endDate: dateRange ? dateRange[1] : null,
                    duration: duration,
                    taskPhaseId: phaseId,
                };
                if (owner) {
                    task.assigneeId = owner;
                }
                await apiClient.get().tasksApi.taskCreate(task);
            }
            await loadTasks();
            message.success('Task has been successfully created');
            closeModal();
        }
        catch (err) {
            message.error('An error occurred while try to save project');
        }
    }

    const createPhase = async () => {
        try {
            if (isTemplates) {
                const phase = {
                    templateId: template.id,
                    name: phaseName,
                    active: true,
                };
                await apiClient.get().templateTaskPhasesApi.templateTaskPhasesCreate(phase);
            } else {
                const phase = {
                    organizationId: orgId,
                    projectId: project.id,
                    name: phaseName,
                    active: true,
                };
                await apiClient.get().taskPhaseApi.taskPhaseCreate(phase);
            }
            await loadTasks();
            message.success('Phase has been successfully created');
            closeModal();
        }
        catch (err) {
            message.error('An error occurred while try to create phase');
        }
    }

    const updatePhase = async (phase, fields) => {
        if (isTemplates) {
            await apiClient.get().templateTaskPhasesApi.templateTaskPhasesPatch([`id:${phase.id}`], fields);
        } else {
            await apiClient.get().taskPhaseApi.taskPhasePatch([`id:${phase.id}`], fields);
        }
    }

    const deletePhase = async (phase) => {
        if(isTemplates) {
            await apiClient.get().templateTaskPhasesApi.templateTaskPhasesPatch([`id:${phase.id}`], { active: false });
        } else {
            await apiClient.get().taskPhaseApi.taskPhasePatch([`id:${phase.id}`], { active: false });
        }
        message.success('Phase has been successfully deleted');
        await loadTasks();
    }

    const getPhaseActiveTaskCount = async (phase) => {
        let tasks;
        if(isTemplates) {
            tasks = await apiClient.get().templateTasksApi.templateTasksList(
                null,
                ['+name'],
                [`templateTaskPhaseId:${phase.id}`, 'active:true'],
                [],
                1,
                1
            );
        } else {
            tasks = await apiClient.get().tasksApi.taskList(
                null,
                ['+name'],
                [`taskPhaseId:${phase.id}`, 'active:true'],
                [],
                1,
                1
            );
        }
        return tasks.data.page.total;
    }

    const onAddClickEmptyPageButton = async () => {
        if(project?.type === 'VX_SERVICE' || template?.projectType === 'VX_SERVICE') {
            setIsAddPhaseOpenModal(true);
        } else {
            setIsAddTaskOpenModal(true);
        }
    }

    const onRefresh = async (taskId, handleDelete) => {
        if (handleDelete && (totalPages - 1) === ((page - 1) * limit) && page !== 1) {
            page -= 1;
        }
        await loadTasks(taskId);
    }

    const onPhaseTaskAdd = async (e, phaseId) => {
        setPhaseId(phaseId);
        setIsAddTaskOpenModal(true);
        e.stopPropagation();
    }

    const onPhaseDeleteOk = async (close, phase, phaseDeleteTaskAction, phaseDeleteMovePhaseId, phaseDeleteTaskCount) => {
        try {
            if(phaseDeleteTaskCount > 0) {
                if (phaseDeleteTaskAction === MOVE_TO_PHASE && phaseDeleteMovePhaseId) {
                    if (isTemplates) {
                        await apiClient.get().templateTasksApi.templateTasksPatch(
                            [`templateTaskPhaseId:${phase.id}`], { templateTaskPhaseId: phaseDeleteMovePhaseId, });
                    } else {
                        await apiClient.get().tasksApi.taskPatch(
                            [`taskPhaseId:${phase.id}`], { taskPhaseId: phaseDeleteMovePhaseId, });
                    }
                } else {
                    if (isTemplates) {
                        await apiClient.get().templateTasksApi.templateTasksPatch(
                            [`templateTaskPhaseId:${phase.id}`], { active: false, });
                    } else {
                        await apiClient.get().tasksApi.taskPatch(
                            [`taskPhaseId:${phase.id}`], { active: false, });
                    }
                }
            }
            await deletePhase(phase)
        } catch (err) {
            message.error('An error occurred while try to delete phase');
        }
        return close;
    }
    const onChangePhaseCollapse = async(value) => {
        setActiveKeys([...value])
        phases.forEach(phase => {
            phase.collapsed = value.find(v => v === phase.key) !== undefined;
        });
        await updatePhaseCollapsedTasks(phases);
    }

    const onModalClose = () => {
        if (taskPrefix) {
            navigate(`/clients/${orgId}/projects/details/${project.id}/tasks`);
        }
    }

    const handlePagination = async (currentPage) => {
        page = currentPage;
        await loadTasks();
    }

    const handlePaginationPhaseTasks = async (currentPage, phaseId) => {
        const phase = phases.find(p => p.id === phaseId);
        phase.tasksPage = currentPage;
        phase.tasksLoaded = false;
        if (phase.name === selectedPhaseName) {
            setSelectedRowKeys([]);
            setSelectedRow([]);
            setSelectedPhaseName([]);
        }
        await updatePhaseCollapsedTasks(phases);
    }

    const usersToDisplay = users.map(u => {
        return {
            label: <div className="user-item">
                <UserAvatar size={26} name={u.name} fileName={u.fileName}/>
                <span style={{marginLeft: '4px'}}>{u.name}</span>
            </div>,
            value: u.id
        }
    });

    const onEditPhaseNameClick = (e, phase) =>{
        setEditPhase(phase);
        setEditPhaseName(phase.name);
        e.stopPropagation();
    }

    const onEditPhaseNameChange = (e) => {
        setEditPhaseName(e.target.value);
        e.stopPropagation();
    }

    const onEditPhaseNameKeyDown = (e) => {
        if(e.keyCode === 13) {
            editPhaseNameSave().then(() => {});
            e.preventDefault();
        }
    }

    const onEditPhaseNameCloseClick = (e) => {
        editPhaseNameCancel();
        e.stopPropagation();
    }

    const onEditPhaseNameCheckClick = (e) => {
        editPhaseNameSave().then(() => {});
        e.stopPropagation();
    }

    const editPhaseNameCancel = () => {
        setEditPhase(null);
        setEditPhaseName('');
    }

    const editPhaseNameSave = async () => {
        if (editPhaseName.trim().length > 0) {
            await updatePhase(editPhase, {name: editPhaseName.trim()})
        }
        setEditPhase(null);
        setEditPhaseName('');
        await loadTasks();
    };
    const onDateChanged = (date) => {
        const [start, end] = date;
        setDateRange([...date]);
        const duration = (new Date(end).getTime() - new Date(start).getTime()) / (1000 * 60 * 60 * 24);
        setDuration(Math.round(duration));
    }

    const phaseDeleteConfirm = async (e, phase) => {
        e.stopPropagation();
        const phaseDeleteTaskCount = await getPhaseActiveTaskCount(phase);
        const plural = phaseDeleteTaskCount <= 1 ? '' : 's';
        if(phaseDeleteTaskCount > 0 && phases.length > 1) {
            const phaseIndex = phases.findIndex(p => p.id === phase.id);
            let phaseDeleteMovePhaseId = phases.length - 1 > phaseIndex
                ? phases[phaseIndex + 1].id
                : phases[phaseIndex - 1].id;
            let phaseDeleteTaskAction = MOVE_TO_PHASE;
            Modal.confirm({
                className: 'delete-phase-confirm',
                width: '447px',
                title: <strong  className="delete-phase-confirm-title">This phase have task{plural}</strong>,
                cancelText: "Cancel",
                okText: "Delete phase",
                okButtonProps: {'data-testid': 'delete-phase-button-ok', type: 'danger'},
                onOk: (close) => onPhaseDeleteOk(close, phase, phaseDeleteTaskAction, phaseDeleteMovePhaseId, phaseDeleteTaskCount),
                content: (
                    <>
                        <Typography className="delete-phase-confirm-text">
                            You currently have {phaseDeleteTaskCount} task{plural} in this phase. <br/>
                            What would you like to do with them?
                        </Typography>
                        <Radio.Group
                            className="delete-phase-confirm-radio-group"
                            name="delete-phase-confirm-radio-group"
                            defaultValue={phaseDeleteTaskAction}
                            onChange={e => phaseDeleteTaskAction = e.target.value}>
                                <Radio
                                    name={MOVE_TO_PHASE}
                                    checked={phaseDeleteTaskAction === MOVE_TO_PHASE}
                                    value={MOVE_TO_PHASE}>Move tasks to the phase
                                </Radio>
                                <Select className="delete-phase-confirm-select"
                                        defaultValue={phaseDeleteMovePhaseId}
                                        disabled={phaseDeleteTaskAction === DELETE_TASKS}
                                        options={phases
                                            .filter(p => p.id !== phase.id)
                                            .map((phase) => ({
                                                label: phase.name,
                                                value: phase.id
                                            }))}
                                        onChange={e => phaseDeleteMovePhaseId = e}/>
                                <Radio
                                    name={DELETE_TASKS}
                                    checked={phaseDeleteTaskAction === DELETE_TASKS}
                                    value={DELETE_TASKS}>Delete tasks in the phase</Radio>
                        </Radio.Group>
                    </>
                )
            });
        } else if (phaseDeleteTaskCount > 0) {
            Modal.confirm({
                className: 'delete-phase-with-tasks-confirm',
                width: '447px',
                title: <strong className="delete-phase-with-tasks-confirm-title">This phase have task{plural}</strong>,
                cancelText: "Cancel",
                okText: "Delete phase & tasks",
                okButtonProps: {'data-testid': 'delete-phase-with-tasks-button-ok', type: 'danger'},
                onOk: async (close) => { await deletePhase(phase); return close; },
                content: (
                    <>
                        <Typography className="delete-phase-with-tasks-confirm-text">
                            You currently have {phaseDeleteTaskCount} task{plural} in this phase. <br/>
                            Deleting the phase will permanently remove all tasks associated with it.
                        </Typography>
                    </>
                )
            });
        } else {
            Modal.confirm({
                className: 'delete-phase-confirm',
                title: <strong className="delete-phase-confirm-title">Delete phase</strong>,
                icon: <ExclamationCircleOutlined style={{ color: '#FAAD14'}} />,
                content: `You about to delete the phase. Do you want to proceed?`,
                okText: "Delete",
                cancelText: "Cancel",
                cancelButtonProps: {type: 'primary'},
                okButtonProps: {'data-testid': 'delete-phase-button-ok', type: 'default', danger: 'danger' },
                onOk: async (close) => { await deletePhase(phase); return close; },
            });
        }
    }

    const showEditablePhaseName = (phase) => {
        const selected = selectedPhaseName === phase.name;
        const tasksCount = selected ? selectedRowKeys.length + '/' + phase.tasksCount : phase.tasksCount??0;

        return <div className="panel-phase-header">
            {(editPhase?.id === phase.id) && <div className="panel-phase-header-edit">
                <span ref={editPhaseNameRef}>
                    <Input maxLength={255}
                           data-testid="edit-phase-name-value"
                           value={editPhaseName}
                           onChange={(e) => onEditPhaseNameChange(e)}
                           onKeyDown={(e)=> onEditPhaseNameKeyDown(e)}
                           style={{width: '100%'}}
                           onClick={(e) => e.stopPropagation()}/>
                </span>
                <div className="edit-phase-name-edit-icons" data-testid="edit-phase-name-edit-icons">
                    <Button data-testid="edit-phase-name-edit-icon-check"
                            className="edit-phase-name-edit-icon-check"
                            type="text" icon={<CheckOutlined />} size={24}
                            ref={editPhaseNameCheckRef}
                            disabled={!(editPhaseName.trim() && editPhaseName.trim().length > 0)}
                            onClick={(e) => onEditPhaseNameCheckClick(e)}/>
                    <Button data-testid="edit-phase-name-edit-icon-close"
                            className="edit-phase-name-edit-icon-close"
                            type="text" icon={<CloseOutlined />} size={24}
                            onClick={(e) => onEditPhaseNameCloseClick(e)}/>
                </div>
            </div>}
            {editPhase?.id !== phase.id && <div className="panel-phase-header-view">
                <span className="panel-phase-name" data-testid={`edit-phase-name-${phase.id}`}
                      onClick={(e) => isUber ? onEditPhaseNameClick(e, phase) : null}>
                    {phase.name} {isUber && <EditOutlined className="panel-phase-name-outlined"/>}</span>
                <span className="panel-phase-tasks-count">{tasksCount} tasks</span>
            </div>}
        </div>
    }

    const showTaskTable = (tasks, tasksLoaded, tasksPage, tasksTotal, handlePagination, phaseName) => {
        return <Table
            dataSource={tasks}
            rowSelection={phaseName ? {
                selectedRowKeys: selectedRowKeys,
                columnWidth: '38px',
                onChange: (key, row) => onRowChange(key, row, phaseName)
            } : null}
            className="tasks-list"
            columns={isTemplates ? columnsTemplateTask : columnsTask}
            onRow={(record) => {
                return {
                    onClick: () => setEditTask(record)
                }
            }}
            style={{maxWidth: '100%'}}
            loading={!tasksLoaded}
            rowClassName={ (record) => record?.id === editTask?.id ? 'ant-table-row-selected' : ''   }
            pagination={
                {
                    current: tasksPage,
                    total: tasksTotal,
                    showSizeChanger: false,
                    pageSize: 10,
                    onChange: handlePagination
                }
            }
        />
    }

    function showCollapseExpandIcon(isActive) {
        return <CaretRightOutlined rotate={isActive ? 90 : 0} />
    }

    return <div className={`tasks-list-container ${!project ? 'my-tasks' : ''}`}>
        {!isEmptyPage && (project || isTemplates) && <div className="tasks-option-container">
            <h1 className="tasks-title" data-testid="tasks-title">Tasks</h1>
            <Radio.Group className="buttons" onChange={(e) => setType(e.target.value)} value={type}>
                <Radio.Button value="LIST">List</Radio.Button>
                <Radio.Button data-testid="chart" value="CHART">Gantt chart</Radio.Button>
            </Radio.Group>
        </div>
        }
        {(!isTemplates && !project) && <Stat items={[
            { title: 'To do', value: tasksStatus?.toDo },
            { title: 'In progress', value: tasksStatus?.inProgress },
            { title: 'Done', value: tasksStatus?.done },
        ]}/>}
            <div className="search-container">
                <div>
                    {!isEmptyPage && <Search disabled={isEmptyPage} placeholder="Tasks search" className="search-input"
                                   onSearch={onSearch} value={searchValue} onChange={(e) => setSearchValue(e.target.value) } />}
                </div>
                {(project || isTemplates) && type === 'LIST' && <div className="command-buttons">
                    {(project?.type === 'VX_SERVICE' || template?.projectType === 'VX_SERVICE')
                        ? <Button data-testid="add-task-phase" type="primary" disabled={!isUber}
                            icon={<PlusOutlined style={{ fontSize: '11px', width: '16px'}} />}
                                onClick={() => setIsAddPhaseOpenModal(true)}>Add Phase</Button>
                        : <Button data-testid="add-task" type="primary"
                            icon={<PlusOutlined style={{ fontSize: '11px', width: '16px'}} />}
                            onClick={() => setIsAddTaskOpenModal(true)}>Add</Button>}
                </div>}
            </div>
        { type === 'CHART' && <ChartList isTemplates={isTemplates} api={apiClient} accessScope={accessScope} onRefresh={onRefresh} onModalClose={onModalClose} users={users} id={isTemplates ? template?.id : projectId} hasPhases={phases.length > 0}/> }
        {!isEmptyPage && type === 'LIST' && <div className='filter'>
            <div><span className='plus'>+</span>Add Filter</div>
        </div>}
        {!(isEmptyPage && searchValue.length === 0) && type === 'LIST' && <Layout style={{background:'none', height: 'calc(100vh - 300px)'}}>
            <Content style={{overflow:'auto', background:'none'}}>
                {(project?.type === 'VX_SERVICE' || template?.projectType === 'VX_SERVICE')
                    && <Collapse
                            bordered={false}
                            ghost={true}
                            activeKey={activeKeys ?? phases.filter((phase) => phase.collapsed).map((phase) => phase.key)}
                            expandIcon={({isActive}) => showCollapseExpandIcon(isActive)}
                            className="task-phase-collapse"
                            onChange={(value) => onChangePhaseCollapse(value)}>
                    {phases.map((phase) => {
                        return <Panel
                                    header={ showEditablePhaseName(phase) }
                                    key={phase.key}
                                    className="task-phase-collapse-panel"
                                    extra={
                                        <div className="task-phase-collapse-panel-buttons">
                                            <Space className="task-phase-button-edit">
                                                <Divider type="vertical" className="task-phase-button-divider" />
                                                <Tooltip  title="Delete selected tasks">
                                                    <Button data-testid={`phase-delete-task-${phase.id}`} size={'small'}
                                                            icon={<MinusCircleOutlined />}
                                                            disabled={selectedRow?.length < 1 || phase.name !== selectedPhaseName}
                                                            onClick={showDeactivateTasksConfirm} />
                                                </Tooltip>
                                                <Button data-testid={`phase-move-task-${phase.id}`} size={'small'}
                                                        icon={<NodeExpandOutlined />}
                                                        onClick={showMovePhaseModal}
                                                        disabled={selectedRow?.length < 1 || phase.name !== selectedPhaseName || phases.length < 2}>Move tasks</Button>
                                                <Divider type="vertical" className="task-phase-button-divider" />
                                                <Button data-testid="phase-add-task" size={'small'} type="primary"
                                                        icon={<PlusOutlined />}
                                                        onClick={(e) => onPhaseTaskAdd(e, phase.id)}>Add task</Button>
                                                <Divider type="vertical" className="task-phase-button-divider" />
                                                <Tooltip title="Delete phase">
                                                    <Button data-testid="phase-delete" size={'small'}
                                                            icon={<DeleteOutlined />}
                                                            disabled={!isUber}
                                                            onClick={(e) => phaseDeleteConfirm(e, phase)} />
                                                </Tooltip>
                                            </Space>
                                            <div className="task-phase-button-delete">
                                                <Tooltip title="Delete phase">
                                                    <Button data-testid={`phase-delete-${phase.id}`} size={'small'}
                                                            icon={<DeleteOutlined />}
                                                            disabled={!isUber}
                                                            onClick={(e) => phaseDeleteConfirm(e, phase)} />
                                                </Tooltip>
                                            </div>
                                        </div>
                                    }>
                            {showTaskTable(phase.tasks, phase.tasksLoaded, phase.tasksPage, phase.tasksTotal, (page) => handlePaginationPhaseTasks(page, phase.id), phase.name)}
                        </Panel>
                    })}
                    </Collapse>}

                {(project?.type !== 'VX_SERVICE' && template?.projectType !== 'VX_SERVICE')
                    && showTaskTable(tasks, isTasksLoaded, page, totalPages, handlePagination)}
            </Content>

            { editTask && <Sider style={{background:'none', overflow: "hidden"}} width="441px"><EditTaskSidebar
                loggedUserId={accessScope.userId}
                showDetailsModal={showDetailsModal}
                customFormat={customFormat}
                key={editTask.id}
                api={apiClient}
                task={editTask}
                templateId={template?.id}
                refresh={(handleDelete) => onRefresh(editTask.id, handleDelete)}
                users={users}
                isTemplates={isTemplates}
                orgId={orgId ?? editTask?.orgId}
                isShortUrl={taskPrefix !== undefined}
                onModalClose={onModalClose}
                closeSidebar={() => {setEditTask(false); setShowDetailsModal(false)}}
            /></Sider>}

        </Layout>}

        {isAddTaskOpenModal && type === 'LIST' && <Modal title="Create a new task"
                               open={isAddTaskOpenModal}
                               className="add-task-modal"
                               onOk={createTask}
                               okText="Create"
                               cancelButtonProps={{'data-testid': 'cancel-add' }}
                               okButtonProps={{ disabled: !(taskName.trim() && taskName.trim().length > 0), 'data-testid': 'add-task-ok' }}
                               onCancel={()=>closeModal()}>
            <Form
                layout="vertical"
                className="project-view-edit-form"
            >
                <Form.Item label="Task name">
                    <Input placeholder="Task name" ref={addTaskInputRef} onPressEnter={createTask} value={taskName} data-testid="text-task-name" onChange={(e) => setTaskName(e.target.value)} />
                </Form.Item>
                <div title="Priority" className="radio-label">Priority</div>
                <Radio.Group className="buttons" onChange={(e) => setPriority(e.target.value)} value={priority} >
                    <Radio.Button data-testid='DEFAULT' value="DEFAULT">Default</Radio.Button>
                    <Radio.Button data-testid='CRITICAL' value="CRITICAL">Critical</Radio.Button>
                    <Radio.Button data-testid='BLOCKER' value="BLOCKER">Blocker</Radio.Button>
                </Radio.Group>
                {isTemplates &&
                <Form.Item label="Estimate">
                    <Input type="number" className="estimate" placeholder="Estimate" min={0} max={1000} value={duration} data-testid="duration" onChange={(e) => setDuration( Math.abs(+e.target.value))} />
                    <span>days</span>
                </Form.Item>
                }
                <Form.Item label="Description">
                    <Input.TextArea rows={4} placeholder="Put your description here" value={description} data-testid="text-description" onChange={(e) => setDescription(e.target.value)}/>
                </Form.Item>
                {!isTemplates &&
                    <Form.Item label={<div>
                        <Checkbox checked={allowDueDate} onChange={(e) => setAllowDueDate(e.target.checked)}>
                            Due to
                        </Checkbox>
                    </div>}>
                        <DatePicker format={customFormat} placeholder="Deadline date" style={{ width: '100%'}} onChange={(date) => { setDueDate(date)}} disabled={!allowDueDate} value={dueDate} />
                    </Form.Item>
                }
                {!isTemplates &&
                    <Form.Item label="Start and end date">
                    <RangePicker onChange={onDateChanged}
                                 data-testid="date-picker"
                                 style={{width: '100%'}}
                                 allowClear={false}
                                 format={customFormat}
                                 value={dateRange ? dateRange : null}/>
                    </Form.Item>}
                {!isTemplates &&
                    <Form.Item label="Assignee">
                        <Select
                            showSearch
                            filterOption={(input, option) => {
                                const user = users.find(u => u.id === option.value);
                                return user && user.name.toLowerCase().includes(input.toLowerCase());
                            }}
                            data-testid="assignee"
                            style={{ width: '100%' }}
                            value={owner}
                            onChange={(value) => setOwner(value)}
                            options={usersToDisplay}
                        />
                    </Form.Item>
                }
            </Form>
        </Modal>}

        {isAddPhaseOpenModal && type === 'LIST' && <Modal title="Add new phase"
                               open={isAddPhaseOpenModal}
                               className="add-phase-modal"
                               okText="Add"
                               okButtonProps={{ disabled: !(phaseName.trim() && phaseName.trim().length > 0), 'data-testid': 'add-phase-ok' }}
                               cancelButtonProps={{'data-testid': 'add-phase-cancel' }}
                               onOk={createPhase}
                               onCancel={()=>closeModal()}>
            <Form layout="vertical"
                  className="project-view-edit-form">
                <Form.Item label="Phase name">
                    <Input placeholder="Put phase name here" ref={phaseNameInputRef} value={phaseName} data-testid="add-phase-text-name"
                           onChange={(e) => setPhaseName(e.target.value)} onPressEnter={() => {
                            createPhase();
                            closeModal();
                    }} />
                </Form.Item>
            </Form>
        </Modal>}

        {isEmptyPage && <EmptyPage
            image={emptySearchResult}
            cssClassName="templates-tasks-list-empty"
            title="There are no tasks yet"
            readOnlySubTitle={!project && !isTemplates ? "Stay tuned for updates" : undefined}
            subTitle="Please add a new task to start."
            addButtonText={project?.type === 'VX_SERVICE' || template?.projectType === 'VX_SERVICE'
                ? 'Add new task phase' : 'Add new task' }
            isReadOnly={!project && !isTemplates}
            onAddClick={(project?.type === 'VX_SERVICE' || template?.projectType === 'VX_SERVICE') && !isUber ?
                null :  () => onAddClickEmptyPageButton()}
        />}
        {(!isEmptyPage && tasks.length <= 0 && phases.length <= 0 && isTasksLoaded) && <EmptyPage
            image={emptySearchResult}
            title="No matches found"
            cssClassName="templates-tasks-list-empty-search"
            subTitle="Please, try another search."
        />}
    </div>
}

export default TasksList;
