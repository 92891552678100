import './projectChat.css';
import {Button, Input, Spin} from "antd";
import {PaperClipOutlined, PictureOutlined, ReloadOutlined, SmileOutlined} from "@ant-design/icons";
import ApiClient from "../../apiClient";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import AppComment from "../appComment/appComment";
import {ALLOW_CHAT_COMMENTS} from "../../constants";
import EmptyPage from "../emptyPage/emptyPage";
import noMessages from "../../assets/images/no-messages.svg";
import AppCommentTextArea from "../appCommentTextArea/appCommentTextArea";

let page = 1;

const ProjectChat = ({accessScope, api, isEditAllowed}) => {

    const apiClient = api || new ApiClient();
    const [projectComments, setProjectComments] = useState([]);
    const [commentText, setCommentText] = useState('');
    const contentWrapperRef = useRef(null);
    const [isCommentsLoaded, setIsCommentsLoaded] = useState(false);
    const [loggedUser, setLoggedUser] = useState(null);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const {projectId, orgId} = useParams();

    useEffect(async () => {
        if (projectId) {
            page = 1;
            setIsCommentsLoaded(false);
            await loadProjectComments();
            setIsCommentsLoaded(true);
            if (contentWrapperRef.current) {
                const wrapper = contentWrapperRef.current;
                wrapper.scrollTop = wrapper.scrollHeight;
            }
            const loggedUser = await apiClient.get().userApi.userGetById(accessScope.userId);
            setLoggedUser(loggedUser.data);
        }
    }, []);

    const loadProjectComments = async () => {
        if (!page) {
            return;
        }
        let allProjectComments = projectComments;
        const loadedComments = await apiClient.get().projectCommentApi.projectCommentList(
            [],
            [`-dateCreated`],
            ['active:true', `projectId:${projectId}`, `organizationId:${orgId}`],
            ['userFirstName', 'userLastName', 'userFileName', 'isExternal'],
            page,
            20
        );
        if (loadedComments.data.data.length > 0) {
            allProjectComments = loadedComments.data.data.reverse().concat(allProjectComments);
            const uniqueCommentsSet = new Set();
            allProjectComments = allProjectComments.filter(comment => {
                const commentString = JSON.stringify(comment);
                if (!uniqueCommentsSet.has(commentString)) {
                    uniqueCommentsSet.add(commentString);
                    return true;
                }
                return false;
            });
            page = loadedComments.data.page.next;
            setShowLoadMore(!!loadedComments.data.page.next);
        }
        setProjectComments(allProjectComments.length > 0 ? allProjectComments : []);
    }

    const getComments = (comments) => {
        return comments.length > 0 ? <div className="task-comments">
            { comments.map((c) => <AppComment isEditAllowed={isEditAllowed || ALLOW_CHAT_COMMENTS} key={`task-comment-${c.id}`} comment={c} onEdit={onCommentEdit} onDelete={onCommentDelete} loggedUserId={accessScope.userId} />)}
        </div> : null;
    }

    const handleKeyPress = useCallback(async (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            await saveComment();
        }
    }, [commentText]);

    const saveComment = async () => {

        if (commentText.trim().length === 0) {
            return;
        }
        const dateCreated = (new Date()).toISOString();
        const commentId = await apiClient.get().projectCommentApi.projectCommentCreate({
            organizationId: orgId * 1,
            projectId: projectId * 1,
            text: commentText,
            authorId: accessScope.userId,
            active: true,
            dateCreated: dateCreated
        });
        setCommentText('');
        const copyComments = JSON.parse(JSON.stringify(projectComments));
        copyComments.push({
            active: true,
            authorId: accessScope.userId,
            dateCreated: dateCreated,
            id: commentId.data.projectCommentId,
            organizationId: orgId * 1,
            projectId: projectId * 1,
            text: commentText,
            userFileName: loggedUser?.fileName,
            userFirstName: loggedUser?.firstName,
            userLastName: loggedUser?.lastName
        });
        setProjectComments(copyComments);
        setTimeout(() => {
            contentWrapperRef.current?.scroll({ top: contentWrapperRef.current.scrollHeight, behavior: 'smooth' });
        }, 500);

    }

    const onCommentDelete = async (comment) => {
        await apiClient.get().projectCommentApi.projectCommentPatch([`id:${comment.id}`], {active: false});
        const commentIndex = projectComments.findIndex(c => c.id === comment.id);
        if (commentIndex > -1) {
            const commentsCopy = JSON.parse(JSON.stringify(projectComments));
            commentsCopy.splice(commentIndex, 1);
            setProjectComments(commentsCopy);
        }
    }

    const onCommentEdit = async (comment) => {
        if (comment.text.trim().length === 0) {
            return;
        }
        await apiClient.get().projectCommentApi.projectCommentPatch([`id:${comment.id}`], {text: comment.text});
        const commentIndex = projectComments.findIndex(c => c.id === comment.id);
        if (commentIndex > -1) {
            const commentsCopy = JSON.parse(JSON.stringify(projectComments));
            commentsCopy[commentIndex] = comment;
            setProjectComments(commentsCopy);
        }
    }

    const onLoadMore = async () => {
        await loadProjectComments();
    }

    return <div className="project-chat" data-testid="project-chat-container">
        {projectComments.length === 0 && isCommentsLoaded ? <EmptyPage
                image={noMessages}
                title="There are no messages yet"
                subTitle="Start project communication here."
            /> :
            <div className="messages" data-testid="messages" ref={contentWrapperRef} >
                {showLoadMore && <div onClick={onLoadMore} className={"load-more"} data-testid="load-more">
                    <ReloadOutlined/>
                    <div>Load more</div>
                </div>}
                {!isCommentsLoaded && <div className="spinner" data-testid="loading-spinner"><Spin/></div>}
                {isCommentsLoaded && getComments(projectComments)}
            </div>}
            <div className="message-input">
                <AppCommentTextArea commentText={commentText} handleKeyPress={handleKeyPress} saveComment={saveComment} setCommentText={setCommentText}/>
            </div>
    </div>
}

export default ProjectChat;
