import {Input, Menu, Modal} from "antd";
import React, {useEffect, useReducer, useRef, useState} from "react";
import {FolderOpenOutlined} from "@ant-design/icons";
import GroupsController from "../../controllers/groupsController";
import {initialState, groupsReducer, ACTIONS} from "./groupsReducer";

const staticState = {
    page: 1,
    hasNextPage: true
}

const AddToGroupModal = ({ isModalOpened, api, requestModalClose, onAddToGroup, selectedClient }) => {

    const { Search } = Input;
    const groupsController = new GroupsController({apiClient: api});

    const [state, dispatch] = useReducer(groupsReducer, initialState);
    const groupsRef = useRef(null);
    const [client, setClient] = useState(null);

    useEffect(() => {
        return () => {
            dispatch({type: ACTIONS.RESET_STATE, payload: initialState});
            staticState.page = 1;
            staticState.hasNextPage = true;
        }
    }, []);

    useEffect(() => {
        setClient(selectedClient);
    }, [selectedClient]);

    useEffect(async () => {
        if (isModalOpened) {
            await loadGroups();
        }
    }, [isModalOpened]);

    const loadGroups = async (resetResults) => {
        if (!staticState.hasNextPage) { return; }
        const loadedGroups = await groupsController.list(staticState.page, state.searchValue);
        staticState.hasNextPage = loadedGroups.length > 0;
        const results = resetResults
            ? loadedGroups : JSON.parse(JSON.stringify(state.groupsList)).concat(loadedGroups);
        dispatch({type: ACTIONS.GROUPS_LIST, payload: results});
    }

    const getGroupMenuItems = (index, group) => {
        return {
            key: `groups-menu-${group.id}`,
            icon:<FolderOpenOutlined />,
            label: group.name,
            data: group,
            'data-testid': `group-to-add-${index}`
        }
    }

    const getGroupsMenuItems = () => {
        return JSON.parse(JSON.stringify(state.groupsList))
            .map((g, index) => getGroupMenuItems(index, g));
    }

    const groupsItemClicked = (item) => {
        const selectedKeys = JSON.parse(JSON.stringify(state.selectedGroupsKeys));
        if (selectedKeys.includes(item.key)) {
            const index = selectedKeys.indexOf(item.key);
            selectedKeys.splice(index, 1);
        } else {
            selectedKeys.push(item.key);
        }
        dispatch({type: ACTIONS.SELECTED_GROUPS_KEYS, payload: selectedKeys});
    }

    const loadDataIfNeeded = async () => {
        const target = groupsRef.current;
        const bottom = target.scrollHeight - target.scrollTop === target.clientHeight;
        if (bottom && staticState.hasNextPage) {
            staticState.page += 1;
            await loadGroups();
        }
    }

    const performSearch = async () => {
        staticState.page = 1;
        staticState.hasNextPage = true;
        await loadGroups(true);
    }

    const addToGroup = async () => {
        if (client) {
            const selectedKeys = JSON.parse(JSON.stringify(state.selectedGroupsKeys));
            const selectedIds = selectedKeys.map(k => k.split('-')[2]);
            onAddToGroup(selectedIds, client.id);
        }
    }

    const groupsMenuItems = getGroupsMenuItems();

    return <Modal
        title="Add to group"
        open={isModalOpened}
        okText="Add"
        onOk={addToGroup}
        onCancel={requestModalClose}
        width={303}
        bodyStyle={{height: 162}}
        className="add-to-group-modal"
        okButtonProps={{
            'data-testid': 'add-to-group',
            disabled: state.selectedGroupsKeys.length === 0
        }}
        cancelButtonProps={{
            'data-testid': 'cancel-add-to-group'
        }}
    >
        <Search
            placeholder="Search"
            onSearch={performSearch}
            onPressEnter={performSearch}
            className="clients-search-wrapper search-wrapper"
            data-testid="modal-search"
            value={state.searchValue}
            onChange={(e) => {
                dispatch({type: 'searchValue', payload: e.target.value})
            }}
        />

        <div
            style={{height: '100px', overflowY: 'scroll'}}
            ref={groupsRef}
            onScroll={loadDataIfNeeded}>

            <Menu
                items={groupsMenuItems}
                selectable={true}
                selectedKeys={state.selectedGroupsKeys}
                onClick={groupsItemClicked}
            />

        </div>

    </Modal>
}

export default AddToGroupModal;