import {Button, Dropdown, Menu, Input} from "antd";
import {FolderAddOutlined, FolderOpenOutlined} from "@ant-design/icons";
import React, {useEffect, useReducer, useRef, useState} from "react";
import GroupsController from "../../controllers/groupsController";
import {addClientsToGroupsReducer, ACTIONS, initialState} from "./addClientsToGroupsReducer";
import {getShortLabel} from "../utils";

const staticState = {
    page: 1,
    hasNextPage: true
}


const AddClientsToGroupButton = ({ isDisabled, onAddToGroup, onAddToNewGroup }) => {

    const groupsController = new GroupsController({});

    const [state, dispatch] = useReducer(addClientsToGroupsReducer, initialState);
    const groupsRef = useRef(null);

    const Search = Input.Search;
    const [isGroupsDropdownVisible, setIsGroupsDropdownVisible] = useState(false);

    useEffect(async () => {
        if (isGroupsDropdownVisible) {
            await loadGroups(true);
        }
    }, [isGroupsDropdownVisible]);

    const resetState = () => {
        staticState.page = 1;
        staticState.hasNextPage = true;
    }

    const setSearchValue = (value) => {
        dispatch({type: ACTIONS.SEARCH_VALUE, payload: value});
    }

    const getMenuItems = () => {
        return state.groupsList.map(g => {
            return {
                key: g.id,
                icon:<FolderOpenOutlined />,
                onClick: async () => {
                    onAddToGroup(g);
                } ,
                label: <span>{getShortLabel(g.name)}</span>
            }
        });
    }

    const loadGroups = async (resetResults) => {
        if (!staticState.hasNextPage) { return; }
        const loadedGroups = await groupsController.list(staticState.page, state.searchValue);
        staticState.hasNextPage = loadedGroups.length > 0;
        const results = resetResults
            ? loadedGroups : JSON.parse(JSON.stringify(state.groupsList)).concat(loadedGroups);
        dispatch({type: ACTIONS.GROUPS_LIST, payload: results});
    }

    const onSearch = async () => {
        staticState.hasNextPage = true;
        staticState.page = 1;
        await loadGroups(true);
    }

    const loadDataIfNeeded = async () => {
        const target = groupsRef.current;
        const bottom = target.scrollHeight - target.scrollTop === target.clientHeight;
        if (bottom && staticState.hasNextPage) {
            staticState.page += 1;
            await loadGroups();
        }
    }

    const dropdownStateChanged = async (isOpened) => {
        setIsGroupsDropdownVisible(isOpened);
        if (!isOpened) {
            resetState();
        }
    }

    const getSearchOverlay = () => {
        const items = getMenuItems();
        return <div style={{minWidth: '248px'}} className="app-header-dropdown add-to-group-dropdown">
            <Search
                placeholder="Search"
                onSearch={onSearch}
                onPressEnter={onSearch}
                className="clients-search-wrapper search-wrapper"
                data-testid="search"
                value={state.searchValue}
                onChange={(e) => {
                    setSearchValue(e.target.value)
                }}
            />
            <div style={{height: '160px', overflowY: 'scroll'}}
                 data-testid="scrollable-div" ref={groupsRef} onScroll={loadDataIfNeeded}><Menu
                items={items}/></div>
            <div>
                <div className="underline"/>
                <div data-testid="redirect" className="all-results" onClick={() => { onAddToNewGroup()}}><FolderAddOutlined
                    style={{fontSize: '16px', marginRight: '8px'}}/>Add to a new group
                </div>
            </div>
        </div>;
    }

    return (
        <Dropdown
            onOpenChange={dropdownStateChanged}
            destroyPopupOnHide={true}
            disabled={isDisabled}
            open={isGroupsDropdownVisible}
            data-testid="clients-dropdown"
            placement="bottomLeft"
            trigger="click"
            dropdownRender={getSearchOverlay}>
            <Button type="default" disabled={isDisabled}
                    icon={<FolderAddOutlined style={{fontSize: '16px'}}/>} data-testid="open-dropdown">
                Add to Group</Button>
        </Dropdown>
    );
}

export default AddClientsToGroupButton;
