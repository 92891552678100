import {Dropdown} from "antd";
import {CloseOutlined, DownOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import "./dateFilter.css"
import {getDeadlines} from "../utils";

export const DateFilter = ({filterText, onFilterChange, dueDates}) => {

    const [isDropdownActive, setIsDropdownActive] = useState(false);
    const [deadline, setDeadline] = useState(null);

    const deadlines = dueDates ?? getDeadlines();

    useEffect(() => {
        if (deadline) {
            const timestamp = deadlines.find(el => el.key === deadline).timestamp;
            onFilterChange(timestamp)
        }
    }, [deadline]);


    const getDropdownCssClassName = (selected, dropdown) => {
        const baseClassName = 'dropdown';
        const activeClass = isDropdownActive === dropdown ? 'active' : '';
        const selectedClass = selected ? 'selected' : '';
        return `${baseClassName} ${activeClass} ${selectedClass}`;
    };

    const clearDeadline = (e) => {
        setDeadline(null);
        onFilterChange(null);
        setIsDropdownActive(false);
        e.stopPropagation();
    };

    return <div className="date-filter">
        <Dropdown
        trigger={['click']}
        onOpenChange={(e)=> setIsDropdownActive(e ? 'deadline' : false)}
        menu={{items: deadlines, onClick: (e) => {
                setDeadline(e.key);
                setIsDropdownActive(false);
            }
        }}
    >
        <div data-testid="deadline-dropdown" className={getDropdownCssClassName(deadline, 'deadline')} >
            <span>{filterText}{deadline && `: ${deadline}`}</span>
            {deadline ? <CloseOutlined data-testid="close-deadline" style={{fontSize: '12px'}} onClick={clearDeadline}/> : <DownOutlined />}
        </div>
    </Dropdown>
    </div>

}

