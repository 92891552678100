import {useState} from "react";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import {Button, Tooltip} from 'antd';

const QueryItem = ({query, onEdit, onDelete, disabled, style}) => {

    const [showEditControls, setShowEditControls] = useState(false);

    const enterEditMode = () => {
        onEdit(query);
        setShowEditControls(false);
    }

    const displayEditControls = () => {
        setShowEditControls(true);
    }

    const hideEditControls = () => {
        setShowEditControls(false);
    }

    return <div className="query-name"
                style={style}
                data-testid={`query-name-${query.id}`}
                onMouseEnter={displayEditControls}
                onMouseLeave={hideEditControls}
    >

        <div className="query-label"><Tooltip title={query.name}>{query.name}</Tooltip></div>

        {!disabled && showEditControls ?
            <div className="query-icons">
                <Button icon={<EditOutlined />} className="custom-button" data-testid={`edit-button-${query.id}`} onClick={enterEditMode} style={{ width: '24px', height: '24px', marginRight: '4px', border: "none"}} />
                <Button icon={<DeleteOutlined />} className="custom-button" data-testid={`query-delete-button-${query.id}`} onClick={(e) => {
                    e.stopPropagation();
                    onDelete(query)
                }} style={{ width: '24px', height: '24px', border: "none" }} danger={true} />
            </div>
            : null}
    </div>
}

export default QueryItem;
