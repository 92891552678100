import React, {useMemo, useEffect, useState, useCallback} from 'react';
import rule from "../../assets/images/logo-VX.svg";
import shortRule from "../../assets/images/logo-short.svg";
import './ruleMenu.css';
import {useNavigate, useParams, useLocation} from "react-router-dom";
import ApiClient from "../../apiClient";
import {useRecoilState, useRecoilValue} from "recoil";
import organizationNameState from "../../atoms/organizationName";
import {Menu, Input, Space, Dropdown, Avatar, Badge, Tooltip } from 'antd';
import defaultOrganization from "../../assets/images/frame.svg"
import arrow from "../../assets/images/arrow-clients.svg"
import {
    ClusterOutlined,
    LinkOutlined,
    CheckSquareOutlined,
    PieChartOutlined,
    ProjectOutlined,
    DatabaseOutlined,
    TableOutlined,
    ShopOutlined
} from '@ant-design/icons';
import isRuleMenuCollapsed from "../../atoms/isRuleMenuCollapsed";
import {RolesMapper} from "../../constants";
import organizationPoliciesState from "../../atoms/organizationPolicies";
import taskAssignedNotificationsState from "../../atoms/taskAssignedNotifications";
import deadlineTomorrowNotificationsState from "../../atoms/deadlineTomorrowNotification";
import projectStatusReportNotificationsState from "../../atoms/projectStatusReportNotification";

export default function RuleMenu ({api, allowedOneOrganization, requestedProjects, accessScope}) {
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [users, setUsers] = useState([]);
    const [orgImage, setOrgImage] = useState(null);
    const [viewText, setViewText] = useState('View all clients');
    const [organizationName, setOrganizationName] = useRecoilState(organizationNameState);
    const [organizationPolicies, setOrganizationPolicies] = useRecoilState(organizationPoliciesState);
    const [isCollapsed, setIsCollapsed] = useRecoilState(isRuleMenuCollapsed);
    const [visible, setVisible] = useState(false);

    const {Search} = Input;
    const apiClient = api ? api : new ApiClient();
    const {orgId} = useParams();
    const match = useLocation();

    const DEFAULT_TAB = 'projects';
    const DEFAULT_MENU_ITEM = 'menu-items-templates';
    const path = match.pathname.split(/[/?]/);

    const assignedTasksNotifications = useRecoilValue(taskAssignedNotificationsState);
    const deadlineTomorrowNotifications = useRecoilValue(deadlineTomorrowNotificationsState);
    const projectStatusReportNotifications = useRecoilValue(projectStatusReportNotificationsState);

    const allowIntegration = () => {
        return accessScope.roleId === RolesMapper.UBER_ADMIN
            || (organizationPolicies?.allowIntegrations !== undefined && organizationPolicies?.allowIntegrations)
    }

    const getDataSetItems = (getLabelStyle) => getItem(<span style={getLabelStyle('data-sets')}>Data sets</span>, 'data-sets',
        <DatabaseOutlined/>, null, 'single', () => goToRoute(`/data-sets/master-data-sets`));

    const getPeopleItems = (getLabelStyle) => getItem(<span style={getLabelStyle('users')}>People</span>, 'users',
        <TableOutlined/>, null, 'single', () => goToRoute(`/users`), !orgId);

    const getInventoryItems = (getLabelStyle) => getItem(<span style={getLabelStyle('assets')}>Inventory</span>, 'assets',
        <PieChartOutlined/>, null, 'single', () => goToRoute(`/assets/devices`), !orgId);

    const geVendorItems = (getLabelStyle) => getItem(<span style={getLabelStyle('vendors')}>Vendors</span>, 'vendors',
        <ShopOutlined/>, null, 'single', () => goToRoute(`/vendors/list`), true);

    const handleCollapse = () => {
        setIsCollapsed(!isCollapsed);
        localStorage.setItem('collapseMenu', !isCollapsed);
    }

    function getItem(label, key, icon, children, type, onClick, disabled) {
        return {
            key,
            icon,
            children,
            label: label ? label : null,
            type,
            onClick: onClick ? onClick : () => {
            },
            disabled,
        };
    }

    const getDatasetItems = (getLabelStyle) => {
        const datasetItems = [];
        if(accessScope.roleId === RolesMapper.UBER_ADMIN) {
            datasetItems.push(getDataSetItems(getLabelStyle));
        }
        if(orgId && accessScope.roleId === RolesMapper.UBER_ADMIN) {
            datasetItems.push(getPeopleItems(getLabelStyle));
            datasetItems.push(geVendorItems(getLabelStyle));
        }
        if(orgId && allowIntegration()) {
            datasetItems.push(getInventoryItems(getLabelStyle));
        }
        return datasetItems
    }

    const avatarCompanyName = () => {
        return (
            organizationName && !orgImage ?
                <Avatar data-testid='avatar' shape="square" className="default-organization"
                        style={{marginRight: !isCollapsed && "8px"}}>
                    {organizationName[0].toUpperCase()}
                </Avatar>
                : <img src={defaultOrganization} data-testid='default-organization' className="default-organization"
                       style={{marginRight: !isCollapsed && "8px"}} alt=""/>
        )
    }

    const processNotifications = (combinedNotifications, uniqueIds) => {
        combinedNotifications.forEach(record => {
            if (!orgId || record.params.organizationId === +orgId) {
                uniqueIds.add(record.params.taskId);
            }
        });
    }

    const calculateUniqueProjectIdsCount = useCallback(() => {
        const combinedNotifications = [...assignedTasksNotifications, ...deadlineTomorrowNotifications, ...projectStatusReportNotifications];
        const uniqueIds = new Set();
        processNotifications(combinedNotifications, uniqueIds);
        return uniqueIds.size;
    }, [assignedTasksNotifications, deadlineTomorrowNotifications, projectStatusReportNotifications, orgId]);

    const calculateTaskIdsCount = useCallback(() => {
        const combinedNotifications = [...assignedTasksNotifications, ...deadlineTomorrowNotifications];
        const uniqueIds = new Set();
        processNotifications(combinedNotifications, uniqueIds);
        return uniqueIds.size === 0 ? '' : uniqueIds.size.toString();
    }, [assignedTasksNotifications, deadlineTomorrowNotifications, orgId]);

    const count = calculateUniqueProjectIdsCount() > 0 ? calculateUniqueProjectIdsCount() : '';

    const getAssignedTasksAmount = () => {
        return calculateTaskIdsCount() > 999 ? '999+' : calculateTaskIdsCount().toString();
    }
    const displayAssignedProjectsAmount = count === '0' ? '' : count;

    const getProjectsLabel = () => <div className='projects-wrapper'>
        <span>Projects</span>
        {displayAssignedProjectsAmount && <div>{displayAssignedProjectsAmount}</div>}
    </div>

    const getMyTasksLabel = () => <div className='projects-wrapper'>
        <span data-testid="my-tasks-header">My Tasks</span>
        {calculateTaskIdsCount() && <div>{getAssignedTasksAmount()}</div>}
    </div>

    const mainMenuItems = useMemo(() => {
        const getLabelStyle = (key) => (path.includes(key) && !path.includes('details')) ? {color: '#1890FF'} : {};
        const enableIntegration = allowIntegration();
        return [
            getItem(<Dropdown
                onOpenChange={setVisible}
                destroyPopupOnHide={true}
                open={visible}
                disabled={allowedOneOrganization}
                data-testid="clients-dropdown"
                placement='bottomRight'
                trigger='click'
                overlayStyle={{maxWidth: '156px', minWidth: '156px'}}
                dropdownRender={() => clientSearchOverlay}>
                <a className="menu" title={organizationName && orgId ? organizationName : null} data-testid='show-item'
                   onClick={(e) => e.preventDefault()}>
                    <Space>
                        <div className={"organization-name"}>{orgImage && orgId ?
                            <img src={orgImage} data-testid='prevImage' className="default-organization"
                                 style={{marginRight: !isCollapsed && "8px"}} alt=""/>
                            :
                            avatarCompanyName()}
                            <div
                                className={orgId ? 'selected-org' : ''}>{isCollapsed ? null : (organizationName ?? 'All clients')}</div>
                            <img src={arrow} className={"arrow"} style={{marginLeft: isCollapsed ? "9.25px" : "8px"}}
                                 alt=""/>
                        </div>
                    </Space>
                </a>
            </Dropdown>, 'menu-item-ant-clients', null, null, 'group'),
            getItem('Overview', 'menu-items-overview', null,
                [getItem(!isCollapsed ? getMyTasksLabel() : <span style={getLabelStyle('tasks')}>My tasks</span>, 'tasks',
                    <Badge dot={isCollapsed && calculateTaskIdsCount()} style={{width: "8px", height: "8px", boxShadow: "none"}} color="#1890FF" offset={[-1, 11]}>
                        <CheckSquareOutlined/>
                    </Badge>, null, 'single', () => goToRoute(`/tasks`)),
                    !orgId && getItem(<span style={getLabelStyle('clients')}>Clients</span>, 'clients',
                        <ClusterOutlined/>, null, 'single', () => goToRoute(`/clients`)),
                ], 'group'),
            getItem(orgId ? 'Project' : 'Templates', 'menu-items-templates', null,
                [getItem(!isCollapsed ? getProjectsLabel() : <span style={getLabelStyle('projects')}>Projects</span>, 'projects',
                    <Badge dot={isCollapsed && displayAssignedProjectsAmount} style={{width: "8px", height: "8px", boxShadow: "none"}} color="#1890FF" offset={[-1, 11]}>
                        <ProjectOutlined/>
                    </Badge>, null, 'single', () => goToRoute(`/projects`))], 'group'),
            enableIntegration ? getItem('Data library', 'menu-items-data-library', null, getDatasetItems(getLabelStyle), 'group') : null,
            enableIntegration ? getItem('', 'menu-items-data-integration', null,
                [orgId ? getItem(<span style={getLabelStyle('integrations')}>Integrations</span>, 'integrations',
                        <LinkOutlined/>, null, 'single', () => goToRoute(`/integrations/scanners`))
                    : getItem(<span style={getLabelStyle('integrations-config')}>Integrations</span>, 'integrations-config',
                        <LinkOutlined/>, null, 'single', () => goToRoute(`/integrations-config/devices/scanner-devices/violetx-scans`))], 'group') : <div></div>
        ]
    }, [organizationName, orgId, visible, isCollapsed, search, users, orgImage, requestedProjects, accessScope, match, assignedTasksNotifications, deadlineTomorrowNotifications]);

    const getMenuSelection = useMemo(() => {
        for (let i = mainMenuItems.length - 1; i >= 0; i--) {
            const selectedChildrenTab = [...mainMenuItems[i]?.children ?? []].reverse().find((t) => path.includes(t.key));
            if (selectedChildrenTab) {
                return [mainMenuItems[i].key, selectedChildrenTab.key];
            }
        }
        return [DEFAULT_MENU_ITEM, DEFAULT_TAB];
    }, [match.pathname]);

    async function doRedirect() {
        const url = viewText === 'View all clients' ? `/clients` : `/clients?q=${search}`
        navigate(url);
        setVisible(false);
        setSearch('');
        await loadCompanies(null, '');
    }

    function goToRoute(route) {
        navigate(orgId ? `/clients/${orgId}${route}` : route);
    }

    async function onClientSelect(user) {
        setOrganizationName(user.name);
        setOrganizationPolicies(user.policies);
        setVisible(false)
        navigate(`/clients/${user.id}/projects`);
    }

    function getSearchResult() {
        const searchResult = [];
        if (users[0] !== null) {
            users.forEach((user, index) => {
                searchResult.push({
                    "data-testid": `item`, key: `orgs-${index}`, className: "search-item",
                    label: <Tooltip title={isCollapsed ? '' : user.name}>{user.name}</Tooltip>, onClick: () => onClientSelect(user)
                })
            });
        }
        return searchResult;
    }

    const searchResult = getSearchResult();

    const displayResult = searchResult.length === 0 ?
        <div className="empty-result-wrapper" key="no-result">
            <div className="empty-result-image"/>
            <div className="empty-result-title">No matches found</div>
        </div> : <Menu className="container-list" items={searchResult}/>

    const clientSearchOverlay = <div className="app-header-dropdown">
        <Search
            placeholder="Search"
            onSearch={onSearch}
            onPressEnter={onEnter}
            className="search-wrapper"
            data-testid="search"
            value={search}
            onChange={(e) => {
                setSearch(e.target.value)
            }}
        />
        {displayResult}
        <div>
            <div className="underline"/>
            <div data-testid="redirect" className="all-results" onClick={doRedirect}>{viewText}</div>
        </div>
    </div>;

    useEffect(async () => {
        if (orgId) {
            try {
                const org = await apiClient.get().orgsApi.organizationList(
                    search,
                    [``],
                    ['active:true', `id:${orgId}`],
                    ['userCount', 'scanCount', 'deviceCount', 'cloudCount'],
                    1,
                    3
                );
                setOrganizationName(org.data.data[0]?.name);
                if (org.data.data[0]?.fileName) {
                    const image = await apiClient.get().filesApi.imageGetByFileName(org.data.data[0].fileName, 'organizations');
                    const uint8Array = new Uint8Array(image.data.resultBuffer.data);
                    const blob = new Blob([uint8Array], {type: image.data.type});
                    const blobURL = URL.createObjectURL(blob);
                    setOrgImage(blobURL);
                } else {
                    setOrgImage(null);
                }
            } catch (err) {
                setOrgImage(null);
            }
        } else {
            setOrgImage(null);
        }
    }, [orgId]);

    useEffect(async () => {
        await loadCompanies(allowedOneOrganization);
    }, []);

    useEffect(async () => {
        if (!orgId) {
            setOrganizationName(null);
            setOrganizationPolicies(null)
        }
    }, [orgId])

    async function onSearch() {
        await loadCompanies();
    }

    async function onEnter(e) {
        e.stopPropagation();
        await loadCompanies();
    }

    async function loadCompanies(id, searchValue) {
        const filter = id ? ['active:true', `id:${id}`] : ['active:true'];
        const searchData = searchValue !== undefined ? searchValue : search;
        const loadedCompanies = await apiClient.get().orgsApi.organizationList(
            searchData,
            [``],
            filter,
            ['userCount', 'scanCount', 'deviceCount', 'cloudCount'],
            1,
            3
        );
        if (loadedCompanies.data.data?.length > 2 && searchData?.length > 0) {
            setViewText('View all results');
        } else {
            setViewText('View all clients');
        }
        if (loadedCompanies.data.data?.length === 0) {
            return setUsers([null]);
        }
        if (id) {
            setOrganizationName(loadedCompanies.data.data[0]?.name);
            setOrganizationPolicies(loadedCompanies.data.data[0]?.policies);
        }
        setUsers(loadedCompanies.data.data);
    }

    return <div className="rule-wrapper" data-testid="rule-menu">
        <img src={isCollapsed ? shortRule : rule} className={`${isCollapsed ? "rule-logo-short" : "rule-logo"}`}
             alt="Notification" onClick={() => {
            allowedOneOrganization ? navigate(`/clients/${orgId}/projects`) : navigate(`/projects`);
        }}/>
        <div data-testid="handle-collapse" className={`${isCollapsed ? 'image-non-collapsed' : 'image-collapsed'}`}
             onClick={handleCollapse}/>
        <div className={`${isCollapsed ? "menu-collapsed" : "main-menu"}`}>
            <Menu style={{width: isCollapsed ? "100px" : "248px"}} theme="dark" selectedKeys={getMenuSelection}
                  className={`${isCollapsed && "menu-collapsed"}`} items={mainMenuItems} mode="inline">
            </Menu>
        </div>
    </div>
};
