import './loggedOut.css';
import Logo from '../../assets/images/logged-out/logo.svg';
import PageLogo from '../../assets/images/logged-out/session-ended.svg';
import useQuery from "../../hooks/useQuery";

const LoggedOut = ({auth}) => {

    const query = useQuery();

    const redirectToSignIn = () => {
        window.location.href = auth.keycloak.createLoginUrl({
            redirectUri: query.get('r')
        });
    }

    return (
        <div className="logged-out">

            <div className="text-container">
                <div className="logo">
                    <img src={Logo} alt="VioletX"/>
                </div>
                <div className="title">
                    You have been logged out
                </div>
                <div className="description">
                    <div>For your safety, you've been logged out.</div>
                    <div>Please go to the Sign in page to re-enter your credentials.</div>
                </div>
                <div className="page-image">
                    <img src={PageLogo} alt="Session Ended"/>
                </div>
                <div className="sign-in">
                    <button onClick={redirectToSignIn}>Go to Sign in Page</button>
                </div>
            </div>

            <div className="image-container">
                <div className="image first-image"></div>
                <div className="image second-image"></div>
                <div className="image third-image"></div>
                <div className="image forth-image"></div>
                <div className="image fifth-image"></div>
                <div className="image six-image"></div>
            </div>
        </div>
    )
}

export default LoggedOut;