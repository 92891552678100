import './vendorList.css'
import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Input, message, Space, Statistic, Table} from 'antd';
import {DeleteOutlined, EnterOutlined, PlusOutlined, SendOutlined} from "@ant-design/icons";
import noProjects from "../../assets/images/no-clients.svg";
import {showDeactivateConfirm} from "../utils";
import {useParams} from "react-router-dom";
import Dots from "../../assets/images/dots-devices.svg";
import useVendorsOperation from "../../hooks/useVendorsOperation";
import EmptyPage from "../emptyPage/emptyPage";
import {UserAvatar} from "../userAvatar/userAvatar";

const limit = 10;

export default function VendorList ({api}) {
    const [isEmptyPage, setIsEmptyPage] = useState(false);
    const [isVendorsLoaded, setIsVendorsLoaded] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1);
    const [total, setTotal] = useState(0);
    const [selectedRow, setSelectedRow] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [vendors, setVendors] = useState([]);

    const { Search } = Input;
    const [listVendors, deleteVendorsLinkage] = useVendorsOperation(api);

    const {orgId} = useParams();


  const getTableMenuItems = (index, record) =>{
      return [
          {
              key: `vendors-${index}`,
              title: "Open vendor's profile",
              label: <div style={{width: '141px'}}>Open vendor's profile</div>,
              disabled: true,
              icon: <EnterOutlined/>
          },
          {
              key: `request-${index}`,
              title: 'Request Evaluation',
              label: <div>
                    <div>Request Evaluation</div>
                    <div className="vendors-list-dropdown-underline"/>
                </div>,
              disabled: true,
              icon: <SendOutlined/>
          },
          {
              key: `delete-vendors-${index}`,
              danger: true,
              title: 'Delete',
              label: <div data-testid={`delete-${index}`}
                          onClick={()=>showDeleteConfirm(record)}>Delete</div>,
              icon: <DeleteOutlined/>
          },
      ]
  }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'vendorName',
            key: 'vendorName',
            width: '300px',
            render: (_, record) => {
                return <div className="vendor-name">
                    <UserAvatar name={record.vendorName} fileName={record.vendorFileName} bucketName='organizations'/>
                    <span style={{marginLeft: '6px', fontWeight: '700'}}>{record.vendorName}</span>
                </div>

            }
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            width: '260px',
            key: 'tags'
        },
        {
            title: 'Point of contact',
            width: '180px',
            dataIndex: 'vendorContact',
            key: 'vendorContact',
            render: (_, record) => {
                return <div className="vendors-contact">{record.vendorContactFirstName} {record.vendorContactLastName}</div>

            }
        },
        {
            title: 'Risk Level',
            dataIndex: 'riskLevel',
            key: 'riskLevel',
            width: '130px'
        },
        {
            title: 'Evaluation status',
            dataIndex: 'status',
            key: 'status',
            width: '130px',
            align: 'left',
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (_, record, index) => {
                const items = getTableMenuItems(index, record);
                return <Dropdown menu={{items}} trigger='click'>
                    <a data-testid={`dots-menu-${index}`} className="action-menu-container">
                        <Space>
                            <img src={Dots} alt=""/>
                        </Space>
                    </a>
                </Dropdown>
            },
            className: 'table-action',
            width: 50
        }
    ];

    useEffect(async () => {
        await loadVendors();
    },[page, orgId, searchValue]);

    const showDeleteConfirm = (record) =>{
        showDeactivateVendorConfirm(record.linkageId)
    }
    const showDeleteIcon = () =>{
        const ids = selectedRow.map((id)=>[`id:${id}`])
        showDeactivateVendorConfirm(ids)
    }

    const getVendors = async (refresh, currentPage) =>
        await listVendors(
            currentPage ?? page,
            refresh ? '' : searchValue,
            ['active:true', `id:${orgId}`], limit, ['vendorInfo']);

    async function loadVendors(currentPage) {
        setIsVendorsLoaded(false);
        const list = await getVendors(false, currentPage);
        const vendorList = list.data.map(item => item.vendorInfo);
        setIsVendorsLoaded(true);
        const convertedData = vendorList.map((item, index) => {
            return {
                key: `vendor-${item.vendorId}-${index}`,
                index: index,
                ...item
            }
        });
        if (convertedData.length <= 0) {
            setIsEmptyPage(true);
        } else {
            setIsEmptyPage(false);
        }
        setVendors(convertedData);
        setTotalPages(list.totalPages);
        if (total === 0 || currentPage) {
            setTotal(list.totalPages);
        }
    }
    const showDeactivateVendorConfirm = (id) => {
        showDeactivateConfirm('vendor', () => onDeactivate(id), false);
    }
    const onDeactivate = async(id) => {
        if(Array.isArray(id)){
            await deleteVendorsLinkage(id)
        } else {
            await deleteVendorsLinkage([`id:${id}`])
        }
        message.success('Vendor has been successfully  deleted');
        setSelectedRowKeys([]);
        setSelectedRow([]);
        let currentPage = page;
        if ((totalPages - 1) === ((page - 1) * limit) && page !== 1) {
            setPage(page - 1);
            currentPage -= 1;
        }
        await loadVendors(currentPage);
    }

    const onSearch = async (value) => {
        setSearchValue(value);
        setPage(1);
    }
    const onRowChange  = (selectedRowKeys, selectedRows) => {
        const selectedVendors = [];
        selectedRows.map(el =>{
            selectedVendors.push(el.linkageId);
        } )
        setSelectedRow(selectedVendors);
        setSelectedRowKeys(selectedRowKeys)
    }

    const handlePagination = async (currentPage) => {
        setPage(currentPage);
        setSelectedRow([]);
        setSelectedRowKeys([]);
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onRowChange,
    };

    return <div className="vendors-list" data-testid="vendors-list">
        <h1>Vendors</h1>
        {!(isEmptyPage && searchValue.length === 0) && <div className="stats-container">
            <div>
                <Statistic title="All vendors" value={total}/>
            </div>
        </div>}
        <div className={`search-container ${isEmptyPage && searchValue.length === 0 && 'container-add-vendor'}`}>
            {!(isEmptyPage && searchValue.length === 0) && <div>
                <Search disabled={isEmptyPage && searchValue.length === 0} placeholder="Vendors search"
                        className="search-input" data-testid="vendors-search" onSearch={onSearch}/>
            </div>}
            <div className="command-buttons">
                {!(isEmptyPage && searchValue.length === 0) && <>
                    <Button icon={<SendOutlined style={{fontSize: '12px'}}/>} disabled={true}>Request evaluation</Button>
                    <Button icon={<DeleteOutlined style={{fontSize: '12px'}} />} danger={true} disabled={selectedRow.length <= 0} onClick={()=>showDeleteIcon()}/>
                </>}
                <Button
                    type="primary"
                    icon={<PlusOutlined style={{fontSize: '16px'}}/>}
                    disabled={true}
                    data-testid="add-vendor"
                >Add Vendor</Button>
            </div>
        </div>
        {!(isEmptyPage && searchValue.length === 0) && <div data-testid='table-container' className={isEmptyPage ? 'empty-container' : 'vendor-table'}>
            <Table
                rowSelection={rowSelection}
                dataSource={vendors}
                columns={columns}
                scroll={{y: 'calc(100vh - 456px)'}}
                loading={!isVendorsLoaded}
                pagination={
                    {
                        current: page,
                        total: totalPages,
                        showSizeChanger: false,
                        pageSize: limit,
                        onChange: handlePagination
                    }
                }
            />
        </div>}
        {isEmptyPage &&
            <EmptyPage title="There are no vendors yet" image={noProjects} dataTestId="empty-page"
                       subTitle="Please add a new vendor to start."
                       addButtonText={'Add new vendor'}
                       onAddClick={()=>{}}
                       isReadOnly={''}
         /> }
    </div>

}