import React, {useState, useRef, useEffect} from 'react'
import ReactCrop, {
    centerCrop,
    makeAspectCrop,

} from 'react-image-crop'
import {PictureOutlined, DeleteOutlined} from '@ant-design/icons';
import 'react-image-crop/dist/ReactCrop.css'
import './uploadImage.css'
import ApiClient from "../../apiClient";
import {message, Upload, Avatar, Slider, Button} from 'antd';
import {useDebounceEffect} from "../../cropConfig/useDebounceEffect";
import {canvasPreview} from "../../cropConfig/canvasPreview";

const UploadImage = ({setIsModalOpened, isImageSaved, api, id, prevFileName, name, redirect, setHandleChange, onImageUploaded, bucketName, uploadText, showUploadTitle, width, height, preferAspect, onImageRendered, setImageSaved }) => {

    const aspect = preferAspect || 1;

    function centerAspectCrop(
        mediaWidth,
        mediaHeight,
        aspect,
    ) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: 'px',
                    width: width || 165,
                    height: height || null
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    }


    const [imgSrc, setImgSrc] = useState('');
    const [fileName, setFileName] = useState('');
    const [previousFileName, setPreviousFileName] = useState('');
    const [orgImage, setOrgImage] = useState(null);
    const previewCanvasRef = useRef(null);
    const imgRef = useRef(null);
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();
    const [scale, setScale] = useState(1);
    const [uploadContainerClassName, setUploadContainerClassName] = useState('upload-image');
    let dragCounter = 0;

    const apiClient =  api || new ApiClient();

    useEffect( async () => {
        if (prevFileName && !isImageSaved) {
            await loadAvatar();
        }
    }, [prevFileName, isImageSaved]);

    useEffect( async () => {
        if (isImageSaved) {
            if (fileName) {
                await onSaveCropClick();
            }
            if (redirect) {
                redirect(id);
            }
        }
    }, [isImageSaved, fileName, redirect]);

    const loadAvatar = async () => {
            try {
                const image = await apiClient.get().filesApi.imageGetByFileName(prevFileName, bucketName);
                setPreviousFileName(prevFileName);
                const uint8Array = new Uint8Array(image.data.resultBuffer.data);
                const blob = new Blob([uint8Array], { type: image.data.type });
                const blobURL = URL.createObjectURL(blob);
                setOrgImage(blobURL);
                deleteImage();
            } catch (err) {
            }
    }

    async function onSelectFile(e) {
        if (e) {
            setFileName(e.name)
            setHandleChange && setHandleChange(true);
            setCrop(undefined);
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(e)
        }
        e.preventDefault()
    }

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    async function onSaveCropClick() {
        if (orgImage) {
            try {
                const params = {
                    fileName: previousFileName,
                    id
                }
                await apiClient.get().filesApi.imageDeleteByFileName(bucketName, params);
            } catch (err) {
                message.error('An error occurred while try to replace image');
            }
        }
        try {
            const blob = await new Promise((resolve) => {
                previewCanvasRef.current.toBlob(resolve,'image/jpeg', 0.8);
            });
            const file = new File([blob], id + fileName)
            const formData = new FormData();
            if (blob) {
                formData.append('image', file, id + '-' + fileName);
                onImageRendered && onImageRendered(file);
            }
            const image = formData.get('image');
            await apiClient.getImage().filesApi.uploadImage(bucketName, image);
            setIsModalOpened && setIsModalOpened(false);
            onImageUploaded && onImageUploaded();
            !redirect && message.success('Image has been successfully created');
        } catch (err) {
            setIsModalOpened && setIsModalOpened(false)
            onImageUploaded && onImageUploaded();
            deleteImage();
            message.error(err.message === 'Network Error' ?
                'Please upload a picture smaller than 1 MB' :
                'An error occurred while try to save image');
        }
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                await canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale
                )
            }
        },
        100,
        [completedCrop, scale],
    )

    const getImage = () => orgImage ? <img data-testid='prevImage' src={orgImage} alt='' className='prevImage'/> :
        <Avatar data-testid='avatar' shape="square" className='avatar-initials'>
            {name}
        </Avatar>

    const uploadButton = () => <div className='upload-wrapper' data-testid='upload-wrapper'>
        {name || orgImage ?
            getImage()
            :
            <div className='upload-img'/>}
        <div>
            {uploadText || "Drop a profile picture here,"} <span className="upload-text">or upload</span>
    </div></div>

    const deleteImage = () => {
        setImgSrc('');
        setHandleChange && setHandleChange(false);
        setFileName('');
        dragCounter = 0;
        setUploadContainerClassName('upload-image');
    }
    return (
        <div className='upload-company-logo' data-testid={'logo'}>
            {(showUploadTitle || showUploadTitle === undefined) && <div className='profile-picture'>Profile picture</div>}
                {imgSrc ? <>
                    <div className='crop-container'>
                    <ReactCrop
                            crop={crop}
                            data-testid='companyLogo'
                            className='react-crop'
                            onChange={(crop) => setCrop(crop)}
                            onComplete={(c) => {setCompletedCrop(c)}}
                            aspect={aspect}
                        >
                        {bucketName !== 'users' && <DeleteOutlined data-testid='delete-image' onClick={deleteImage} className='delete-button'/>}
                        <img
                                ref={imgRef}
                                data-testid="image-to-crop"
                                alt="Crop me"
                                src={imgSrc}
                                style={{transform: `scale(${scale})`, minWidth: '165px', minHeight: '165px'}}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                        {bucketName === 'users' && <DeleteOutlined data-testid='delete-image' onClick={deleteImage} className='delete-button'/>}
                    </div>
                        <div className='slider-container'>
                            <PictureOutlined className='slider-picture'/>
                            <Slider
                                data-testid='slider'
                                min={0.5}
                                max={1.5}
                                onChange={(value) => setScale(value)}
                                value={scale}
                                step={0.01}
                            />
                            <PictureOutlined className='right-slider-picture'/>
                        </div>
                        {setImageSaved && <div className='button-container'>
                            <Button type="default" className='cancel-button' onClick={deleteImage}>Cancel</Button>
                            <Button type="primary" disabled={!imgSrc.length} onClick={()=>setImageSaved(true)}>Save</Button>
                        </div>}
                    </>
                    :
                    <div className={uploadContainerClassName}
                         data-testid='image-active'
                         onDragEnter={() => {setUploadContainerClassName("upload-image-active");
                             dragCounter++;
                         }}
                         onDragOver={(e) => e.preventDefault()}
                         onDragLeave={() => {
                             if (dragCounter === 0) {
                                 !fileName && setUploadContainerClassName('upload-image')
                             }
                             dragCounter--;
                         }}>
                        <Upload
                            name="avatar"
                            data-testid='upload-image'
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={onSelectFile}
                        >
                            {uploadButton()}
                        </Upload>
                    </div>
                }
            <canvas
                ref={previewCanvasRef}
                data-testid="cropped-image"
                style={{display: 'none'}}
            />
        </div>
    )
}

export default UploadImage;