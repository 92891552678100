import Windows from "../assets/images/windows.svg";
import Apple from "../assets/images/apple.svg";
import Linux from "../assets/images/linux.svg";
import AWS from "../assets/images/aws-logo.svg";
import GCP from "../assets/images/gcp-logo.svg";
import Unknown from "../assets/images/unknown.svg";
import appleSmall from "../assets/images/apple-small.svg";
import windowsSmall from "../assets/images/windows-small.svg";
import linuxSmall from "../assets/images/linux-small.svg";
import awsSmall from "../assets/images/aws-small.svg";
import cloud from "../assets/images/cloud.svg";
import {Modal, Tag, Tooltip} from 'antd';
import {
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import moment from "moment/moment";
import React from "react";

export function devicePlatformName(devicePlatform) {
    switch(devicePlatform) {
        case 'darwin':
            return 'MacOS';
        case 'windows':
            return 'Windows';
        case 'linux':
            return 'Linux';
        case 'aws':
            return 'AWS';
        case 'gcp':
            return 'GCP';
        default:
            return devicePlatform;
    }
}

export const getShortLabel = (name) => {
    if(name?.length >= 30) {
        return <Tooltip title={name}>{name.slice(0, 13) + '...' + name.slice(-13)}</Tooltip>
    }
    else return name
}

export function devicePlatformIcon(devicePlatform) {
    switch(devicePlatform) {
        case 'darwin':
            return Apple;
        case 'windows':
            return Windows;
        case 'linux':
            return Linux;
        case 'aws':
            return AWS;
        case 'gcp':
            return GCP;
        default:
            return Unknown;
    }
}

export function devicePlatformSmallIcon(devicePlatform) {
    switch(devicePlatform) {
        case 'darwin':
            return appleSmall;
        case 'windows':
            return windowsSmall;
        case 'linux':
            return linuxSmall;
        case 'aws':
            return awsSmall;
        case 'gcp':
            return cloud;
    }
}

export function deviceIsOnline(timestampStr, interval) {
    if (!timestampStr) {
        return false;
    }
    const date = Date.parse(timestampStr) / 1000;
    const utc = Math.abs(new Date().getTime() / 1000);
    const seconds = Math.floor(utc - date);
    const timeout = interval ? interval : parseInt(process.env.REACT_APP_IS_ONLINE_TIMEOUT);
    return seconds <= timeout
}

export function showDeactivateConfirm(title, onConfirm, useDeactivate) {
    const deactivate = useDeactivate ? 'Deactivate' : 'Delete'
    return Modal.confirm({
        title: `${deactivate} ${title}`,
        icon: <ExclamationCircleOutlined style={{ color: '#FAAD14'}} />,
        content: `You about to ${deactivate.toLowerCase()} the ${title}. Do you want to proceed?`,
        okText: deactivate,
        cancelText: 'Cancel',
        className: `delete-${title}`,
        wrapClassName: `delete-${title}`,
        cancelButtonProps: {type: 'primary'},
        okButtonProps: {'data-testid': `delete-${title}-confirm`, type: 'default', danger: 'danger' },
        async onOk() {
            await onConfirm();
        }
    });
}

export function formatDate(date) {
    return moment(date).format(`MMMM D, ${moment(date).year() === moment().year() ? '' : 'YYYY, '}h:mmA`);
}

export function formatDateNoTime(date) {
    return moment(date).format(`MMMM D, YYYY`);
}

export function formatCustomFields (customFields) {
    if (customFields) {
        const result = [];
        Object.keys(customFields).forEach(key => {
            if (typeof customFields[key] === "object") {
                result.push(`${JSON.stringify(customFields[key])}`);
            } else {
                result.push(`${customFields[key]}`);
            }
        });
        return result.join(', ');
    }
}

export function getUserInitials(name) {
    let result;
    const parts = name.split(' ');
    result = parts[0][0];
    if (parts.length > 1) {
        result += parts[1][0];
    }
    return result.toUpperCase();
}

export function generateProjectPrefix(name) {
    let filtered = name.replace(/[^a-zA-Z0-9\s]/g, '').replace(/^\d+/, '').replace(/\s+/g, ' ');
    if (filtered.length > 1) {
        const parts = filtered.trim().split(' ').filter(s => s !== '');
        let newPrefix = '';
        if (filtered.length > 3 && parts.length > 1) {
            newPrefix = parts[0][0] + parts[1][0];
        } else if (parts.length === 1) {
            newPrefix = parts[0].substring(0, 2);
        }
        return newPrefix.toUpperCase();
    }
    return null;
}

const getDateInfo = (date, useUTC) => {

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'
    ];
    const shortMonth = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July',
        'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ]

    const hours = useUTC ? date.getUTCHours() : date.getHours();

    return {
        year: useUTC ? date.getUTCFullYear() : date.getFullYear(),
        month: useUTC ? months[date.getUTCMonth()] : months[date.getMonth()],
        day: useUTC ? date.getUTCDate() : date.getDate(),
        shortMonth: shortMonth[date.getUTCMonth()],
        hours: hours,
        minutes: useUTC ? date.getUTCMinutes() : date.getMinutes(),
        ampm: hours >= 12 ? 'p.m.' : 'a.m.',
        hours12: (hours % 12) || 12
    }

}

export const formatMessageDate = (date, dateNow, useUTC, useShortFormat) => {

    const givenDate = new Date(date);
    const currentDate = dateNow ? new Date(dateNow) : new Date();

    const timeDifferenceMillis = currentDate - givenDate;
    const totalSeconds = Math.floor(timeDifferenceMillis / 1000);

    const dateInfo = getDateInfo(givenDate, useUTC);

    let result = 'No date';

    switch (true) {
        case totalSeconds < 86400:
            const hours = Math.floor(timeDifferenceMillis / (1000 * 60 * 60));
            const minutes = Math.floor((timeDifferenceMillis % (1000 * 60 * 60)) / (1000 * 60));
            if (useShortFormat) {
                result = hours > 0 ? `${hours}h` : `${minutes} min`;
            } else {
                result = hours > 0 ? `${hours}h ${minutes} min ago` : `${minutes} min ago`;
            }
            break;

        case totalSeconds >= 86400 && totalSeconds < 172800:
            result = useShortFormat ? '1d' :
                `yesterday at ${dateInfo.hours12}:${String(dateInfo.minutes).padStart(2, '0')}${dateInfo.ampm}`;
            break;

        case totalSeconds >= 172800 && totalSeconds < 604800:
            result = useShortFormat ? `${Math.floor(totalSeconds / 86400)}d` :
                `${dateInfo.month} ${dateInfo.day}, ${dateInfo.hours12}:${String(dateInfo.minutes).padStart(2, '0')}${dateInfo.ampm}`;
            break;

        case totalSeconds >= 604800 && totalSeconds < 31556926:
            result = useShortFormat ? `${dateInfo.shortMonth} ${dateInfo.day}` :
                `${dateInfo.month} ${dateInfo.day}`;
            break;

        case totalSeconds >= 31556926:
            result = useShortFormat ? `${dateInfo.year}` :
                `${dateInfo.month} ${dateInfo.day}, ${dateInfo.year}`;
            break;
    }

    return {
        date: result,
        fullDate: `${dateInfo.month} ${dateInfo.day}, ${dateInfo.year} ${dateInfo.hours12}:${String(dateInfo.minutes).padStart(2, '0')} ${dateInfo.ampm}`
    };

}

export const getTag = (status) => {
    let color;
    let title;
    switch (status) {
        case 'ACTIVE':
        case 'ALL_SET':
            color = 'green';
            title = 'Active';
            break;
        case 'REQUESTED':
            color = 'red';
            title = 'Requested';
            break;
        case 'DONE':
            color = 'gold';
            title = 'Done';
            break;
        case 'ASK_TO_CLOSE':
            color = 'geekblue';
            title = 'Asked to close';
            break;
        case 'DRAFT':
            title = 'Archived';
            break;
        // user status >>
        case 'NONE':
            title = 'None';
            break;
        case 'INVITE_SENT':
            color = 'gold';
            title = 'Invited';
            break;
    }
    return (
        <Tag color={color} key={status}>
            {title}
        </Tag>
    )
}

export const getDeadlines = () => {
    const now = moment().startOf('day');
    return [
        {
            key: 'Today',
            timestamp: `${now.utc().format()}..${moment().startOf('day').add(1, 'days').utc().format()}`,
            label: 'Today',
        },
        {
            key: 'Next 7 days',
            timestamp: `${now.utc().format()}..${moment().startOf('day').add(7, 'days').utc().format()}`,
            label: 'Next 7 days',
        },
        {
            key: 'Next 14 days',
            timestamp: `${now.utc().format()}..${moment().startOf('day').add(14, 'days').utc().format()}`,
            label: 'Next 14 days',
        },
        {
            key: 'More than 14 days',
            timestamp: `${moment().startOf('day').add(14, 'days').utc().format()}..`,
            label: 'More than 14 days',
        },
        {
            key: 'Overdue',
            timestamp: `..${now.utc().format()}`,
            label: 'Overdue',
        },
    ];
}
