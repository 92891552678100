import './clientsGroups.css'
import {Input, Menu, message} from 'antd';
import {FolderOpenOutlined, PlusOutlined, ProjectOutlined} from '@ant-design/icons';
import {useCallback, useContext, useEffect, useState} from "react";
import ApiClient from "../../apiClient";
import QueryEditItem from "../queryEditItem/queryEditItem";
import QueryItem from "../queryItem/queryItem";

import CurrentGroupContext from '../clientsList/selectedGroupContext';
import {showDeactivateConfirm} from "../utils";

const staticState = {
    page: 1,
    hasNextPage: true
}

export default function ClientsGroups ({api}) {

    const { Search } = Input;

    const {currentGroup, setCurrentGroup} = useContext(CurrentGroupContext);

    const apiClient = api || new ApiClient();

    const allClientsItems = [{
        key: 'all clients',
        icon: <ProjectOutlined/>,
        label: 'All clients'
    }];

    const [activeTab, setActiveTab] = useState('all clients');

    const [searchValue, setSearchValue] = useState('');
    const [groups, setGroups] = useState([]);
    const [groupsToEdit, setGroupsToEdit] = useState(null);

    useEffect(() => {
        if (!currentGroup) {
            setActiveTab('all clients');
            return;
        }
         const groupsCopy = JSON.parse(JSON.stringify(groups));
        const groupIndex = groupsCopy.findIndex(g => g.id === currentGroup.id);
        groupsCopy[groupIndex] = currentGroup;
        setGroups(groupsCopy);
        onQueryItemSelected(currentGroup);
    }, [currentGroup]);

    const onEdit = useCallback((q) => {
        setGroupsToEdit(q);
    }, []);

    const updateCurrentGroupName = (name) => {
        if (currentGroup) {
            const currentGroupCopy = JSON.parse(JSON.stringify(currentGroup));
            currentGroupCopy.name = name;
            setCurrentGroup(currentGroupCopy);
        }
    }

    const saveGroup = async (group, name) => {
        try {
            const groupToEdit = Object.assign({}, group);
            groupToEdit.name = name;
            const params = Object.assign({}, groupToEdit);
            delete params.id;
            await apiClient.get().groupsApi.groupsUpdate(groupToEdit.id, params);
            await getOrganizationsGroups();
            updateCurrentGroupName(name);
            setGroupsToEdit(null);
        }
        catch(err) {
            message.error('Unable to update group');
        }
    }
    const deleteGroup = useCallback(async (group) => {
        try {
            const current = await apiClient.get().groupsApi.groupsGetById(group.id);
            const params = JSON.parse(JSON.stringify(current.data));
            params.active = false;
            delete params.id;
            await apiClient.get().groupsApi.groupsUpdate(group.id, params);
            await getOrganizationsGroups();
            if(currentGroup && currentGroup.id === group.id) {
                setCurrentGroup(null);
            }
            message.success('Group has been successfully deleted');
        } catch (err) {
            await message.error('Unable to delete group');
        }
    }, [currentGroup]);

    const handleDeleteGroup = useCallback((group) => {
        showDeactivateConfirm('group', () => deleteGroup(group))
    }, [currentGroup]);

    const onQueryItemSelected = useCallback((query) => {
        if (query) {
            setActiveTab(query.id?.toString());
            setCurrentGroup(query);
        }
    }, []);

    const items = groups.map(g => {
        const label = groupsToEdit && groupsToEdit.id === g.id ?
            <QueryEditItem query={g} onSaveTitle={saveGroup} onCancelEdit={() => setGroupsToEdit(null)} disabled={false} />
            : <QueryItem style={{display: "flex", justifyContent: "space-between"}}
                         disabled={false}
                         query={g}
                         onEdit={onEdit}
                         onDelete={handleDeleteGroup}
                         onSelect={onQueryItemSelected} />

        return {
            key: g.id,
            icon:<FolderOpenOutlined />,
            label: label,
        }
    });

    const loadDataIfNeeded = async (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && staticState.hasNextPage) {
            await getOrganizationsGroups(staticState.page, true);
        }
    }

    const getOrganizationsGroups = async (page, loadMore) => {
        try  {
            const loadedGroups = await apiClient.get().groupsApi.groupsList(
                searchValue,
                ['+name'],
                ['active: true'],
                [],
                page,
                50
            );
            staticState.page = loadedGroups.data.page.next;
            staticState.hasNextPage = loadedGroups.data.page.next !== null;
            setGroups(loadMore ? [...groups ,...loadedGroups.data.data] : loadedGroups.data.data);
        } catch (e) {
            message.error(e.message);
        }
    }

    useEffect( () => {
        return () => {
            setActiveTab('all clients');
            setCurrentGroup(null);
        }
    },[]);

    useEffect(async () => {
        await getOrganizationsGroups();
    },[activeTab]);

    const addNewGroup = async () => {
        const dateNow = Date.now();
        const newGroupId = await apiClient.get().groupsApi.groupsCreate({
            name: `Group ${dateNow}`,
            active: true
        });
        await getOrganizationsGroups();
        setActiveTab(newGroupId.data.GroupsId.toString());
        updateCurrentGroupName(`Group ${dateNow}`);
    }

    const onSearch = async () => {
        await getOrganizationsGroups(1);
    }

    const changeActiveGroup = (e) => {
        setActiveTab(e.key);
        setCurrentGroup(groups.find(g => g.id === +e.key));
    }

    return <div className="clients-groups">
        <Menu
            onClick={(e) => {setActiveTab(e.key);
                setCurrentGroup(null);
                }
            }
            style={{
                width: 250,
                marginBottom: '8px',
                borderBottom: '1px solid #F5F5F5'
            }}
            defaultSelectedKeys={['all clients']}
            selectedKeys={[activeTab]}
            mode="inline"
            items={allClientsItems}
        />
        <div className="groups">Groups <PlusOutlined data-testid="add-group" onClick={addNewGroup}/></div>
        <Search disabled={false} placeholder="Search" className="search-input" data-testid="groups-search"
                onSearch={onSearch} value={searchValue} onChange={(e) => setSearchValue(e.target.value) } />
        <div
            style={{
                width: 250,
                maxHeight: 'calc(100% - 180px)',
                overflowY: 'auto',
                overflowX: 'hidden'
            }}
            onScrollCapture={loadDataIfNeeded}>
            <Menu
                onClick={changeActiveGroup}
                style={{width: '258px',
                    marginLeft: '-8px'}}
                className="groups-menu"
                defaultSelectedKeys={['all clients']}
                selectedKeys={[activeTab]}
                mode="inline"
                items={items}
            />
        </div>
    </div>
}
