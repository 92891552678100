import './clientsFilter.css';
import React, {useEffect, useState} from "react";
import SearchableUsersFilter from "../searchableUsersFilter/searchableUsersFilter";

const ClientsFilter = ({ onChange, api, defaultSelected }) => {

    const [clients, setClients] = useState([]);
    const [clientsList,] = useState([]);

    useEffect(async () => {
        await loadClients();
    }, []);

    useEffect(() => {
        const ids = clientsList.map(c => c?.id);
        onChange(ids);
    }, [clientsList]);

    const loadClients = async () => {
        let page = 1;
        const result = [];
        let loadMore = true;
        const filter = ['active:true'];
        while (loadMore) {
            const clients = await api.get().orgsApi.organizationList(
                null,
                ['+name'],
                filter,
                [],
                page,
                50
            );
            clients.data.data.forEach(u => {
                result.push({
                    id: u.id,
                    name: u.name
                })
            });
            page = clients.data.page.next;
            loadMore = clients.data.page.next !== null;
        }
        setClients(result);
    }

    return (
        <div>
            {clients.length > 0 && <SearchableUsersFilter defaultSelected={defaultSelected} ubersAdmins={[]} users={clients} onChange={onChange} label="Client" className="clients-filter-" />}
        </div>
    );
}

export default ClientsFilter;