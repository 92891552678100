import "./queryEditableTitle.css"
import {Menu, Dropdown, Space} from 'antd';
import { DownOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import {useState} from "react";
import QueryEditItem from "../queryEditItem/queryEditItem";
import {useRecoilValue} from "recoil";
import openedDebugQueriesState from "../../atoms/openedDebugQueries";

const getPopupContainer = (node) => {
    return node.parentNode;
}

const QueryEditableTitle = ({query, onEdit, onDelete, disabled}) => {

    const [isEditMode, setIsEditMode] = useState(false);
    const openedDebugQueries = useRecoilValue(openedDebugQueriesState);

    const editTitle = () => {
        setIsEditMode(true);
    }

    const deleteTitle = () => {
        onDelete(query);
    }

    const saveTitle = (query, title) => {
        onEdit(query, title, openedDebugQueries);
    }

    return <div className="query-editable-title" data-testid="query-editable-title">
        {isEditMode ? <QueryEditItem disabled={disabled} query={query} onCancelEdit={() => setIsEditMode(false)} onSaveTitle={saveTitle}/> :
            <Dropdown disabled={disabled}
                      overlayStyle={{maxWidth: '145px', padding: 0, minWidth: '145px'}}
                      dropdownRender={() =>
                <Menu
                    style={{
                        width: '145px'
                    }}
                    items={[
                        {
                            label: 'Edit name',
                            key: 'query-title-edit-name',
                            icon: <EditOutlined/>,
                            onClick: editTitle

                        },
                        {
                            label: 'Delete',
                            key: 'query-title-delete',
                            icon: <DeleteOutlined style={{ color: '#FF4D4F'}}/>,
                            style: {color: '#FF4D4F'},
                            onClick: deleteTitle
                        }
                    ]}
                />} trigger={['click']} getPopupContainer={getPopupContainer} placement="bottomRight">
                <a className="query-dropdown" onClick={(e) => e.preventDefault()}>
                    <Space>
                        {query.name}
                        <DownOutlined />
                    </Space>
                </a>
            </Dropdown>
        }
    </div>
}

export default QueryEditableTitle;