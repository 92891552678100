
export const TabNavigation = {
    dashboard: {
        enabled: false,
        route: '/dashboard',
        title: 'Dashboard'
    },
    users: {
        enabled: true,
        route: '/users',
        title: 'Users'
    },
    scans: {
        enabled: true,
        route: '/scans',
        title: 'Scans'
    },
    inventory: {
        enabled: true,
        route: '/inventory',
        title: 'Inventory'
    },
    projects: {
        enabled: false,
        route: 'projects',
        title: 'Projects'
    },
}

export const userPicColors = [
    {
        text: 'text-purple-2',
        bg: 'bg-pink-3'
    },
    {
        text: 'text-green',
        bg: 'bg-green-3'
    },
    {
        text: 'text-violet-2',
        bg: 'bg-pink'
    },
    {
        text: 'text-blue-2',
        bg: 'bg-blue-2'
    },
];

export const RolesMapper = {
    'UBER_ADMIN': 1,
    'ORG_ADMIN': 2
}

export const scanSubTypes = {
    SYSTEM: 'SYSTEM',
    VIOLET_X: 'VIOLET_X',
    CUSTOM: 'CUSTOM'
}

export const Tables = {
    groups: ['groups.f1', 'groups.f2', 'groups.f3', 'groups.f4', 'groups.f5'],
    users: ['users.f1', 'users.f2', 'users.f3', 'users.f4', 'users.f5'],
    programs: ['programs.f1', 'programs.f2', 'programs.f3', 'programs.f4', 'programs.f5'],
    python_packages: ['python_packages.f1', 'python_packages.f2', 'python_packages.f3', 'python_packages.f4', 'python_packages.f5'],
    ie_extensions: ['ie_extensions.f1', 'ie_extensions.f2', 'ie_extensions.f3', 'ie_extensions.f4', 'ie_extensions.f5'],
    cached_users: ['cached_users.f1', 'cached_users.f2', 'cached_users.f3', 'cached_users.f4', 'cached_users.f5'],
    chocolatey_packages: ['chocolatey_packages.f1', 'chocolatey_packages.f2', 'chocolatey_packages.f3', 'chocolatey_packages.f4', 'chocolatey_packages.f5'],
}

export const FakeProjects = [
    { id: 1, name: 'Employee’s devices compliant', dueDate: '12 Aug,  2023', alerts: '5'},
    { id: 2, name: '30,60, 90 days cyber security roadmap', dueDate: '12 Aug,  2023', alerts: '3'},
];

export const ALLOW_CHAT_COMMENTS = false;