import ApiClient from "../apiClient";
import moment from "moment";

export const COMPANY_EXISTS_ERROR = 'COMPANY_EXISTS_ERROR';
export const GENERAL_ERROR = 'GENERAL_ERROR';

const formatDate = (date) => {
    const now = moment();
    const inputDate = moment(date);

    if (now.diff(inputDate, 'years') < 1) {
        return inputDate.format('MMM D, hh:mm A');
    }

    return inputDate.format('MMM D, YYYY');
}

export default class ClientsController {
    apiClient = null;

    constructor(props) {
        this.apiClient = props?.apiClient || new ApiClient();
    }

    listClients = async (page, searchValue, exceptGroupId, limit, listFilter) => {
        try {
            let filter = ['active:true'];
            if (exceptGroupId) {
                filter.push(`exceptGroupId: ${exceptGroupId}`);
            }
            const loadedClients = await this.apiClient.get().orgsApi.organizationList(
                searchValue,
                [`+name`],
                listFilter ?? filter,
                [],
                page,
                limit ?? 20
            );
            return loadedClients.data?.data;
        } catch (e) {
            console.error(e);
            return GENERAL_ERROR;
        }
    }

    find = async (text, sort, filter, expand, page) => {
        let loadedClients = [];
        try {
            loadedClients = await this.apiClient.get().orgsApi.organizationList(
                text,
                sort,
                filter,
                expand,
                page,
                10
            );
        } catch (e) {
            console.error(e);
            return GENERAL_ERROR;
        }

        const clients = this.mapClients(loadedClients.data.data);

        return  {
            data: clients,
            totalPages: loadedClients.data.page.total,
            hasNextPage: loadedClients.data.page.next !== null
        };
    }

    async addNewCompany(companyName) {
        const companyCreateParams = {
            name: companyName,
            active: true
        }
        try {
            const response = await this.apiClient
                .get()
                .orgsApi
                .organizationCreate(companyCreateParams);
            await this.apiClient
                .get()
                .assignmentApi
                .assignmentCreate({
                    action: 'INCLUDE',
                    source: 'SCAN_TOPIC',
                    sourceValue: 'CLOUDS',
                    target: 'DEVICES',
                    targetType: 'SCAN_TOPIC',
                    targetValue: 'CLOUDS',
                    organizationId: response.data.organizationId,
                });
            await this.apiClient
                .get()
                .assignmentApi
                .assignmentCreate({
                    action: 'INCLUDE',
                    source: 'SCAN_TOPIC',
                    sourceValue: 'DEVICES',
                    target: 'DEVICES',
                    targetType: 'SCAN_TOPIC',
                    targetValue: 'DEVICES',
                    organizationId: response.data.organizationId,
                });
            return response.data.organizationId;
        } catch (e) {
            if (e.response?.data.details?.organization?.message === `Key (name)=(${companyName}) already exists.`) {
                return COMPANY_EXISTS_ERROR;
            } else {
                console.error(e);
                return GENERAL_ERROR
            }
        }
    }

    loadStatistic = async (clientGroup) => {
        const filter =  !clientGroup ? ['active:true'] : ['active:true', `groupId: ${clientGroup}`];
        try {
            const loadedStatistic = await this.apiClient.get().orgsApi.organizationStatus(
                '',
                [``],
                filter,
                1,
                10
            );
            return loadedStatistic.data?.data[0];
        } catch (e) {
            console.error(e);
            return GENERAL_ERROR;
        }
    }

    deactivateCompany = async (companyId) => {
        try {
            await this.apiClient.get().orgsApi.organizationPatch(
                    [`id:${companyId}`],
                    { active: false }
                );
            return true;
        }
        catch (e) {
            console.error(e);
            return GENERAL_ERROR;
        }
    }

    removeFromGroup = async (clientsIds, groupId) => {
        try {
            const idsToRemove = clientsIds.map(e => `organizationId:${e}`);
            await this.apiClient.get().organizationsGroupsApi.organizationsGroupsDeleteMany([...idsToRemove, `groupId:${groupId}`]);
            return true;
        }
        catch (e) {
            console.error(e);
            return GENERAL_ERROR;
        }
    }

    addToGroup = async (clientId, groupId) => {
        await this.apiClient.get().organizationsGroupsApi.organizationsGroupsCreate({
            organizationId: clientId,
            groupId: groupId
        });
    }

    // service methods

    mapClients = (clients) => {
        return clients.map((item, index) => {
            return {
                id: item.id,
                key: `client-${item.id}-${index}`,
                name: item.name,
                activeProjects: item.activeProjectsCount,
                requestedProjects: item.requestedProjectsCount,
                fileName: item.fileName,
                contact: item.pointOfContact,
                vxContact: item.vxPointOfContact,
                lastInteraction: item.lastInteraction && formatDate(item.lastInteraction),
                dueDate: item.dueDate && moment(Date.parse(item.dueDate)).format("MMM D, YYYY")
            }
        });
    }

}