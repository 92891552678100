import './datasets.css'
import {useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import { Menu } from 'antd';
import { CrownOutlined, ShareAltOutlined, FileOutlined, PartitionOutlined} from '@ant-design/icons';

export default function DataSets() {
    const navigate = useNavigate();
    const {orgId} = useParams();
    const location = useLocation();

    const tabs = [
        { "data-testid": 'tab-assets-master-datasets', title: 'Master datasets', key:'master-datasets', label: 'Master datasets', icon: <CrownOutlined />, disabled: false, onClick: () => { onTabSelect('master-data-sets')}},
        { "data-testid": 'tab-assets-shared-datasets', title: 'Shared datasets', key:'shared-datasets', label: 'Shared datasets', icon: <ShareAltOutlined />, disabled: false, onClick: () => { onTabSelect('shared-data-sets')}},
        { "data-testid": 'tab-assets-my-datasets', title: 'My datasets', key:'my-datasets', label: 'My datasets', icon: <FileOutlined />, disabled: false, onClick: () => { onTabSelect('my-data-sets')}},
        { "data-testid": 'tab-assets-data-schema', title: 'Data schema', key:'data-schema', label: 'Data schema', icon: <PartitionOutlined />, disabled: false, onClick: () => { onTabSelect('data-schema')}},
    ];

    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState(['master-datasets']);

    const onTabSelect = (tab) => {
        navigate(`/clients/${orgId}/data-sets/${tab}`);
    }

    useEffect(() => {
        const path = location.pathname.split('/');
        const selectedTab = tabs.find((t) => path.includes(t.key)) ?? tabs[0];
        setDefaultSelectedKeys([selectedTab.key]);
    },[location.pathname]);


return <>
    <h3 className="page-title">Datasets</h3>
    <div className="datasets-wrapper">
        <Menu
            items={tabs}
            defaultSelectedKeys={defaultSelectedKeys}
            mode="inline"
        />
        <Outlet/>
    </div>
</>

}