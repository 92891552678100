import {useState} from "react";
import './uploadContainer.css';

import {CloseCircleFilled} from '@ant-design/icons';

import {
    Button
} from 'antd';

const UploadContainer = ({parseFile, messages, title, contentType}) => {

    let dragCounter = 0;

    const [uploadContainerClassName, setUploadContainerClassName] = useState('upload-wrapper');
    const [fileName, setFileName] = useState('');
    const [showErrorText, setShowErrorText] = useState('');

    const showFileInfo = (fileName) => {
        return(
            <>
                <div className="icon-wrapper">
                    <span className="icon-file"/>
                    <span className="file-name">
                        {fileName}
                </span>
                </div>
                <Button shape="circle"
                        data-testid="remove-file"
                        className="query-export-modal-close-btn remove-file"
                        icon={<CloseCircleFilled className="query-export-modal-close-btn-icon"/>}
                        onClick={() => removeFile()}/>
            </>
        )
    }

    async function removeFile() {
        setShowErrorText('');
        setFileName('');
        setUploadContainerClassName("upload-wrapper");
    }

    const onFileDrop = (e) => {
        e.preventDefault();
        setFileName(e.dataTransfer.files[0].name);
        if (e.dataTransfer.files[0].type === contentType) {
            setUploadContainerClassName('upload-wrapper-uploaded');
            (async () => {
                try {
                    setShowErrorText('');
                    const data = await e.dataTransfer.files[0]?.text();
                    parseFile(data);
                } catch (err) {
                    setShowErrorText(messages.FAILED_TO_PARSE);
                    setUploadContainerClassName('upload-wrapper-error');
                }
            })();
        } else {
            setUploadContainerClassName('upload-wrapper-error');
            setShowErrorText(messages.WRONG_FILE_TYPE)
        }
    }

    const onChange = async (e) => {
        try {
            setFileName(e.target.files[0].name);
            setUploadContainerClassName('upload-wrapper-uploaded');
            const data = await e.target.files[0].text();
            parseFile(data);
        } catch (err) {
            console.log(err);
            setShowErrorText(messages.FAILED_TO_PARSE);
            setUploadContainerClassName('upload-wrapper-error');
        }
    }

    return <div className="upload-container">
            <div className={uploadContainerClassName} data-testid="drop-zone"
             onDragEnter={() => {setUploadContainerClassName("upload-wrapper-active");
                 dragCounter++;
             }}
             onDrop={(e) => {onFileDrop(e)}}
             onDragOver={(e) => e.preventDefault()}
             onDragLeave={() => {
                 if (dragCounter === 0) {
                     !fileName && setUploadContainerClassName('upload-wrapper')
                 }
                 dragCounter--;
             }}
        >
            {fileName ? <><div className="file-wrapper" data-testid="file-wrapper">
                    {showFileInfo(fileName)}
                </div>
                    {showErrorText && <div className="error-wrapper"><span className="icon-error"/>
                        <span className="error-text" data-testid="error-text">{showErrorText}</span>
                    </div>}</>:
                <>
                    <div className="upload-image"/>
                    <div className="upload-image-description">{title} <label htmlFor="file" className="link">or upload
                        <input id="file" accept={contentType} onChange={async e => { await onChange(e)}}
                               type="file" style={{display: 'none'}}/>
                    </label>
                    </div>
                </>}
        </div>
    </div>
}

export default UploadContainer;