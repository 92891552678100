import React from 'react';
import './usersEmptyGridMessage.css';

export default function EmptyGridMessage({ onAddNew, entity, img }) {
    return <div className="users-empty" data-testid="users-empty">
        <div><img src={img}/></div>
        <div>
            {entity[0].toUpperCase() + entity.slice(1)}s list is empty
        </div>
        <div>
            This company is ready for adding new {entity}s.
        </div>
        <div>
            <div className="add-user-button" onClick={onAddNew}>Add new {entity}</div>
        </div>
    </div>
}