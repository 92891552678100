import React, {useEffect, useState} from 'react';
import './devicesList.css';
import { Button, message, Checkbox, Dropdown, Modal } from 'antd';
import { DeleteOutlined, PlusOutlined, SendOutlined, DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import * as utils from "../utils";
import sendAgentDisabled from "../../assets/images/send-agent-disabled.svg";
import more from "../../assets/images/vertical-dots.svg";
import moment from "moment";
import {showDeactivateConfirm} from "../utils";


const DevicesList = ({orgId, api, userId}) => {
    const apiClient = api;
    const [devices, setDevices] = useState([]);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [openedCard, setOpenedCard] = useState(null);

    const downloadLinks = [
        {
            key: '1',
            label: (
                <a target="_blank" href={process.env.REACT_APP_VIOLETX_AGENT_URL + '/VioletX-Agent+Setup+1.0.0.exe'}>
                    Download for Windows
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a target="_blank" href={process.env.REACT_APP_VIOLETX_AGENT_URL + '/VioletX-Agent-1.0.0.dmg'}>
                    Download for MacOs
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a target="_blank" href={process.env.REACT_APP_VIOLETX_AGENT_URL + '/VioletX-Agent-1.0.0.deb'}>
                    Download for Linux
                </a>
            ),
        },
    ];

    useEffect( async () => {
        await loadDevices();
    },[]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            loadDevices();
        }, 60 * 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [userId]);

    async function loadDevices() {
        const response = await apiClient.get().userApi.userDeviceList(
            userId,
            [],
            ['id'],
            ['active:true', 'scan_topic:DEVICES', 'scan_topic:NONE'],
            1,
            50);
        const responseData = response.data;
        const devices = [];
        if (responseData.data && responseData.data.length > 0) {
            responseData.data.forEach(item => {
                if (item.deviceInfo) {
                    devices.push({
                        ...item,
                        ...item.deviceInfo,
                    });
                } else {
                    devices.push({
                        id: item.id,
                        arch: 'unknown',
                        os: 'unknown',
                        version: 'unknown',
                        platform: 'unknown',
                        build: 'unknown',
                        name: 'unknown',
                        timestamp: null
                    });
                }
            });
        }
        setDevices([...devices]);
    }

    async function createAgent() {
        await apiClient.get().devicesApi.deviceCreate(
            {
                organizationId: orgId,
                enrollSecret: '',
                scanTopic: 'DEVICES',
                active: true,
                userId: userId,
                timestamp: null,
            }
        );
        await loadDevices();
    }

    function getDeviceInfo(device) {
        const deviceName = utils.devicePlatformName(device.deviceType);
        const deviceIcon = utils.devicePlatformIcon(device.deviceType);
        const scanned = () => {
            if(device.timestamp) {
                return moment(device.timestamp).format(`${moment(device.timestamp).year() === moment().year() ? 'MMMM D, ' : 'MMM D, YYYY, '}h:mmA`);
            } else return 'unknown';
        }
        const build = !device.version.includes(device.build)
            ? device.build
            : '';

        return {
            arch: device.arch || 'unknown',
            OS: device.name || 'unknown',
            icon: deviceIcon,
            version: device.version,
            platform: deviceName,
            build: build,
            scanned: scanned()
        }
    }

    function calculateIfOnline(timestamp) {
        if (timestamp === null) {
            return <div className="device-status status-offline"/>;
        }
        return utils.deviceIsOnline(timestamp)
            ? <div className="device-status status-online"/>
            : <div className="device-status status-offline"/>
    }

    const showDeactivateDeviceConfirm = (item) => {
        const plural = !item && selectedDevices.length > 1 ? 's' : ''
        showDeactivateConfirm(`device${plural}`, () => deactivateDevice(item));
    }

    async function deactivateDevice(device) {
        await apiClient.get().devicesApi.devicePatch(device ? [`id:${device}`] : selectedDevices.map(e => `id:${e}`), {active: false});
        setSelectedDevices([]);
        message.success('Device has been successfully deleted');
        await loadDevices();
    }

    function onChange (e, id) {
        setSelectedDevices(e.target.checked ? [...selectedDevices, id]: [...selectedDevices.filter(i => i !== id)]);
    }


    function getDevicesList() {
        return devices.map((device, index) => {
            const deviceInfo = getDeviceInfo(device);
            const items = [
                { key: `send-${index}`, label: <div>Send Agent</div>, icon: <img src={sendAgentDisabled}/>, disabled: true},
                { key: `deactivate-${index}`, title: 'Delete', label: <div data-testid={`deactivate-${index}`} onClick={() => showDeactivateDeviceConfirm(device.id)}>Delete</div>, icon: <DeleteOutlined />, danger: true},
            ];
            const status = deviceInfo.platform ? calculateIfOnline(device.timestamp) :
                <div className="device-status status-offline"/>
            return <div className="device" key={device.id}>
                <div className="device-header">
                    <div>
                        <div className="checkbox-container">
                            <Checkbox data-testid="checkbox" checked={selectedDevices.includes(device.id)} onChange={(e)=>{onChange(e, device.id)}}></Checkbox>
                        </div>
                        <div className="device-icon">
                            <img src={deviceInfo.icon}/>
                            {status}
                        </div>
                        <div title={device.deviceInfo ? device.deviceInfo.name : 'Unknown device'} className="device-name">{device.deviceInfo ? device.deviceInfo.name : 'Unknown device'}</div>
                    </div>
                    <div className="menu" data-testid={`menu-${index}`} onClick={e => e.stopPropagation()}>
                        <div className="action-menu">
                            <Dropdown trigger="click" data-testid="dropdown" className={"list-dropdown"} menu={{items}} placement="bottomRight">
                                <img src={more} alt="More" data-testid={`action-menu-${index}`}/>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                {deviceInfo.platform
                    ? <div className="main">
                        <div>
                            <div className="title">Arch:</div>
                            <div className="value">{deviceInfo.arch}</div>
                        </div>
                        <div>
                            <div className="title">OS:</div>
                            <div className="value device-info" title={deviceInfo.OS}>{deviceInfo.OS}</div>

                        </div>
                        {deviceInfo.version &&
                            <div>
                                <div className="title">Version:</div>
                                <div className="value device-version"
                                     title={deviceInfo.version}>{deviceInfo.version}</div>
                            </div>
                        }
                        {deviceInfo.platform &&
                            <div>
                                <div className="title">Platform:</div>
                                <div className="value">{deviceInfo.platform}</div>
                            </div>
                        }
                        <div>
                            <div className="title">Scanned:</div>
                            <div className="value">{deviceInfo.scanned}</div>
                        </div>
                    </div>
                    : <div className="no-device">
                        <div className="card-setting disabled">
                            <SendOutlined />
                            <span className='setting'>Send agent</span>
                        </div>
                        <Dropdown menu={{items: downloadLinks}} onOpenChange={(i) =>  i ? setOpenedCard(index) : setOpenedCard(null)}>
                            <div className={openedCard === index ? "card-setting-open" :  "card-setting"}>
                                <DownloadOutlined />
                                <span className='setting'>Setup inventory</span>
                            </div>
                        </Dropdown>
                    </div>}
            </div>
        });
    }

    return <div className="users-summary-container" data-testid="devices-list">
        <div className="header">
            <h2>Devices</h2>
            <div className="command-buttons">
                <Button icon={<SendOutlined style={{ fontSize: '12px'}} />} disabled={true} />
                <Button icon={<DeleteOutlined style={{ fontSize: '12px'}} />} danger data-testid="deactivate" onClick={() => showDeactivateDeviceConfirm()} disabled={selectedDevices?.length < 1} />
                <Button type="primary" className='add' icon={<PlusOutlined style={{ fontSize: '13px'}} />} data-testid="add" onClick={createAgent}>Add device</Button>
            </div>
        </div>
        <div>
            <div className="save-container devices-list">
                {devices.length === 0 ? <div>No devices found</div> : getDevicesList()}
            </div>
        </div>
    </div>
}

export default DevicesList;
