import {Button} from "antd";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {formatMessageDate} from "../../utils";
import {UserAvatar} from "../../userAvatar/userAvatar";
import './assignedNotification.css';

const AssignedNotification = ({data, onActionCallback, apiClient}) => {
    const [notificationData, setNotificationData] = useState(data);
    const navigate = useNavigate();

    useEffect(() => {
        setNotificationData(data);
    }, [data]);

    const onActionButtonClicked = async () => {
        await onActionCallback(notificationData.id);
        navigate(`/${notificationData.params.organizationId}/${notificationData.params.taskKey}`);
    }
    return (
        <>
            <div className="notification-item-header">
                <div>
                    <UserAvatar size={16} fileName={notificationData.params.creatorFileName} bucketName={"users"}
                                name={notificationData.params.creatorName} api={apiClient}/>
                    <span className="assignee-notification-name">{notificationData.params.creatorName}</span> from <span>{notificationData.params.organizationName}</span> assigned  you for <span>{notificationData.params.projectName}</span> task
                </div>
                <div>{formatMessageDate(notificationData.dateCreated, new Date(), true, true).date}</div>
            </div>
            <div className="notification-action">
                <Button size="small" onClick={onActionButtonClicked}>See the task</Button>
            </div>
        </>
    );
}

export default AssignedNotification;
