import {CaretDownOutlined, CloseOutlined, UserAddOutlined} from '@ant-design/icons';
import {Fragment, useState} from "react";
import {Avatar, Tooltip, Select, Radio, Input} from 'antd';
import './assigneesContainer.css';
import {UserAvatar} from "../userAvatar/userAvatar";

const AssigneesContainer = ({task, users, updateTask}) => {
    const [activeSelect, setActiveSelect] = useState('');
    const [assigneeSearchValue, setAssigneeSearchValue] = useState('');
    const [assigneeSearchFilter, setAssigneeSearchFilter] = useState('');
    const { Search } = Input;

    const items = users.map(u => {
        return {
            label: <div className="task-assignee-item" data-testid={`option-item-${u.id}`}>
                <UserAvatar name={u.name} fileName={u.fileName}/>
                <span style={{marginLeft: '4px'}}>{u.name}</span>
            </div>,
            value: u.id
        }
    });

    const getUberAdminName = (assignee) => {
        return {
            label: <div className="task-assignee-item">
                <UserAvatar name={assignee.assigneeName} fileName={assignee.fileName}/>
                <span style={{marginLeft: '4px'}}>{assignee.assigneeName}</span>
            </div>
        }
    }

    const addAssignee = () => <>
        {activeSelect === 'new-assignee' ? (
            getSelect()
        ) : (
            <Radio.Button
                onClick={() => setActiveSelect('new-assignee')}
                className="not-assignee"
                data-testid="not-assignee"
            >
                <UserAddOutlined/>
            </Radio.Button>)}
    </>

    const onSelectChange = async (value, assigneeCount) => {
        let updateData = {};
        if (assigneeCount === 2 || !assigneeCount && task.assignees[0].assigneeId) {
            updateData.assigneeId2 = value ?? null;
        } else if (assigneeCount === 1 && !value && task.assignees[1].assigneeId) {
            updateData.assigneeId2 = null;
            updateData.assigneeId = task.assignees[1].assigneeId;
        } else {
            updateData.assigneeId = value ?? null;
        }

        await updateTask(updateData);
        setActiveSelect(null);
    };

    const onAssigneeSearch = () => {
        setAssigneeSearchFilter(assigneeSearchValue.toLowerCase());
    }

    const handleSearchValue = (e) => {
        if (e.key === 'Enter') {
            e.stopPropagation();
            return;
        }
        setAssigneeSearchValue(e.target.value);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.stopPropagation();
        }
    }

    const getSelect = (assignee, assigneeCount) => {
        let filteredAssignees;
        if (assigneeCount === 2 || !assigneeCount && task.assignees[0]?.assigneeId) {
            filteredAssignees = items.filter(item => item.value !== task.assignees[0]?.assigneeId);
        } else {
            filteredAssignees = items.filter(item => item.value !== task.assignees[1]?.assigneeId);
        }
        if (assignee && !users.find(u => u.id === assignee?.assigneeId)) {
            assignee.assigneeId = getUberAdminName(assignee);
        }

        if (assigneeSearchFilter.length > 0) {
            filteredAssignees = filteredAssignees.filter(a => {
                const user = users.find(u => u.id === a.value && u.name.toLowerCase().includes(assigneeSearchFilter));
                if (user) {
                    return a;
                }
                return false;
            });
        }

        return <Select
            dropdownRender={(menu) => (
                <>
                    <Search placeholder="Search" style={{ height: 24 }}
                            className="assignee-options-search"
                            data-testid="options-search"
                            onSearch={onAssigneeSearch} value={assigneeSearchValue}
                            onChange={handleSearchValue}
                            onKeyDown={handleKeyDown}
                    />
                    {menu}
                </>
            )}

            className="select-assignee"
            popupClassName="assignee-searchable-select-popup"
            data-testid="select"
            clearIcon={<CloseOutlined className="close-icon" data-testid="remove"/>}
            defaultValue={activeSelect !== 'new-assignee' ? assignee.assigneeId : undefined}
            allowClear={true}
            defaultOpen={true}
            maxTagTextLength={17}
            autoFocus={true}
            value={activeSelect !== 'new-assignee' ? assignee.assigneeId : undefined}
            onDropdownVisibleChange={() => {
                setActiveSelect(null);
                setAssigneeSearchFilter('');
                setAssigneeSearchValue('')}}
            onChange={(value) => onSelectChange(value, assigneeCount)}
            options={filteredAssignees}
        />;
    }

    const getAssignees = () =>
        task.assignees?.map((assignee, i) => <Fragment key={`assignee-${assignee?.assigneeId??i}`}>
                {assignee.assigneeId && (
                    <>
                        {activeSelect === `${assignee.assigneeId}` ? (
                            getSelect(assignee, i + 1)
                        ) : (
                            <div onClick={() => setActiveSelect(`${assignee.assigneeId}`)}
                                 className="assignee-wrapper" data-testid={`assignee-wrapper-${assignee.assigneeId}`}>

                                <Tooltip title={task.assignees[1]?.assigneeId ? assignee.assigneeName : ''}>
                                    <span>
                                        <UserAvatar name={assignee.assigneeName} fileName={assignee.fileName}/>
                                    </span>
                                </Tooltip>
                                <CaretDownOutlined className="dropdown-icon"/>
                                {!task.assignees[1]?.assigneeId && !activeSelect && (
                                    <p className="created-by" data-testid="created-by">{assignee.assigneeName}</p>
                                )}
                            </div>
                        )}
                    </>
                )}
            </Fragment>
        );

    return <div className="assignees-container">
        <div className="assignee" data-testid="dropdown-assignee">
            {getAssignees()}
            {!task.assignees[1]?.assigneeId && addAssignee()}
        </div>
    </div>

}
export default AssigneesContainer;
