import './emptyPage.css';
import {Button} from 'antd';

const EmptyPage = ({
    title,
    subTitle,
    readOnlySubTitle,
    addButtonText,
    onAddClick,
    isReadOnly,
    cssClassName,
    image,
    dataTestId,
    customAddButton,
}) => {

    const className = `empty-list-page ${cssClassName}`

    return <div className={className} data-testid={dataTestId}>
        <div className="page-empty" data-testid="empty-list">
            <div>
                <img src={image} alt=""/>
            </div>
            <div>{title}</div>
            {readOnlySubTitle && <div>{readOnlySubTitle}</div>}
            {!isReadOnly &&
                <>
                <div>{subTitle}</div>
                    <div>
                        {onAddClick && <Button
                            className="add"
                            disabled={!onAddClick}
                            data-testid="add-item-button"
                            onClick={onAddClick}
                        >{addButtonText}</Button>}
                        {customAddButton && customAddButton()}
                    </div>
                </>
            }
        </div>
    </div>
}

export default EmptyPage;
