import './deviceViewEdit.css';
import {
    Form,
    Input,
} from 'antd';
import loadedAssetDeviceState from '../../atoms/loadedAssetDevice';
import {useRecoilState} from 'recoil';
import * as utils from "../utils";
import {formatDate} from "../utils";

const DeviceViewEdit = ({device}) => {

    const [loadedAssetDevice, ] = useRecoilState(loadedAssetDeviceState);
    const deviceToUse = device || loadedAssetDevice;

    const getDeviceInfo = (info) => {
        const deviceName = utils.devicePlatformName(info.deviceType);
        return {
            name: info.deviceInfo?.computer_name || 'Unknown name',
            arch: info.deviceInfo?.arch || 'unknown',
            OS: info.deviceInfo?.name || 'unknown',
            version: info.deviceInfo?.version,
            platform: deviceName,
            scanned: info.timestamp ? formatDate(info.timestamp) : 'unknown',
            build: info.deviceInfo?.build
        }
    }

    const deviceInfo = getDeviceInfo(deviceToUse);

    return <div className="device-view-edit" data-testid="device-view-edit">
        <h1>Summary</h1>
        <Form
            layout="vertical"
            className="device-view-edit-form"
        >
            <Form.Item label="Name">
                <Input placeholder="Name" disabled={true} value={deviceInfo.name} data-testid="text-name"/>
            </Form.Item>
            <Form.Item label="Owner">
                <Input placeholder="Owner" disabled={true} value={`${deviceToUse.userFirstName} ${deviceToUse.userLastName}`} data-testid="text-owner"/>
            </Form.Item>
        </Form>
        <div className="scan-details">
            <h2>Scan Details</h2>
            <table>
                <thead></thead>
                <tbody>
                    {deviceInfo.arch &&
                        <tr>
                            <th>Arch:</th>
                            <td>{deviceInfo.arch}</td>
                        </tr>
                    }
                    {deviceInfo.OS &&
                        <tr>
                            <th>OS:</th>
                            <td>{deviceInfo.OS}</td>
                        </tr>
                    }
                    {deviceInfo.version &&
                        <tr>
                            <th>Version:</th>
                            <td>{deviceInfo.version}</td>
                        </tr>
                    }
                    {deviceInfo.build &&
                        <tr>
                            <th>Build:</th>
                            <td>{deviceInfo.build}</td>
                        </tr>
                    }
                    {deviceInfo.platform &&
                        <tr>
                            <th>Platform:</th>
                            <td>{deviceInfo.platform}</td>
                        </tr>
                    }
                    {deviceInfo.scanned &&
                        <tr>
                            <th>Scanned:</th>
                            <td>{deviceInfo.scanned}</td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    </div>
}

export default DeviceViewEdit;