import './documentCommentsSection.css';
import DocumentComments from "../documetComments/documentComments";
import {Button, Input} from "antd";
import {PaperClipOutlined, PictureOutlined, SmileOutlined} from "@ant-design/icons";
import React, {useCallback, useEffect, useState} from "react";
import AppCommentTextArea from "../../appCommentTextArea/appCommentTextArea";

const DocumentCommentsSection = ({api, document, loggedUserId, onDocumentCommentSaved}) => {

    const [refreshComments, setRefreshComments] = useState(Date.now());
    const [documentCommentText, setDocumentCommentText] = useState('');
    const [currentDocument, setCurrentDocument] = useState(document);

    useEffect(() => {
        setCurrentDocument(document);
    }, [document]);

    const saveDocumentComment = useCallback(async (commentText) => {
        await api.get().documentCommentApi.documentCommentCreate({
            organizationId: currentDocument.organizationId,
            projectId: currentDocument.projectId,
            text: commentText,
            authorId: loggedUserId,
            documentId: currentDocument.id,
            active: true,
            dateCreated: (new Date()).toISOString()
        });
        setDocumentCommentText('');
        setRefreshComments(Date.now());
        onDocumentCommentSaved();
    }, [currentDocument]);

    const handleKeyPress = useCallback(async (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            await saveDocumentComment(documentCommentText);
        }
    }, [documentCommentText]);

    return <div className="comments-section">
        <div>
            <DocumentComments
                api={api}
                documentId={currentDocument.id}
                loggedUserId={loggedUserId}
                requestReload={refreshComments}
                projectId={currentDocument.projectId}
                orgId={currentDocument.organizationId}
            />
        </div>
        <div>
            <AppCommentTextArea commentText={documentCommentText} handleKeyPress={handleKeyPress} saveComment={saveDocumentComment} setCommentText={setDocumentCommentText}/>
        </div>
    </div>
}

export default DocumentCommentsSection;