import './projectDescriptionPage.css';
import { BarChartOutlined, ProjectOutlined, CheckSquareOutlined, WarningOutlined, PieChartOutlined, PartitionOutlined, FileTextOutlined, ArrowLeftOutlined, DeleteOutlined, CommentOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import SidebarLayout from "../sidebarLayout/sidebarLayout";
import ProjectSummary from "../projectSummary/projectSummary";
import {Button, message} from 'antd';
import ApiClient from "../../apiClient";
import TasksList from "../tasksList/tasksList";
import {RolesMapper} from "../../constants";
import {showDeactivateConfirm} from "../utils";
import ProjectChat from "../projectChat/projectChat";
import DocumentsList from '../documentList/documentsList';
import ProjectStatusReport from "../projectStatusReport/projectStatusReport";
import {useRecoilValue} from "recoil";
import taskAssignedNotificationsState from "../../atoms/taskAssignedNotifications";
import deadlineTomorrowNotificationsState from "../../atoms/deadlineTomorrowNotification";
import projectStatusReportNotificationsState from "../../atoms/projectStatusReportNotification";

const ProjectDescriptionPage = ({ match, api, allowOwner, accessScope }) => {

    const apiClient = api || new ApiClient();

    const navigate = useNavigate();
    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState(['tab-projects-summary', 'summary']);
    const [orgUsers, setOrgUsers] = useState([]);
    const [project, setProject] = useState({
        id: null,
       name: '',
       description: '',
        prefix: '',
        owner: '',
        dueDate: null,
        formId: null
    });

    const assignedTasksNotifications = useRecoilValue(taskAssignedNotificationsState);
    const deadlineTomorrowNotifications = useRecoilValue(deadlineTomorrowNotificationsState);
    const projectStatusReportNotifications = useRecoilValue(projectStatusReportNotificationsState);

    useEffect(async () => {
        let loadedProject;
        if (match.params.taskPrefix) {
            const prefix = match.params.taskPrefix.split('-')[0];
            loadedProject = await loadProjectDetailsByPrefix(prefix);
            setDefaultSelectedKeys(['tasks']);
        } else {
            loadedProject = await loadProjectDetails();
        }
        await loadUsers(loadedProject);
    }, [match.params]);

    const calculateTaskIdsCount = useCallback(() => {
        const combinedNotifications = [...assignedTasksNotifications, ...deadlineTomorrowNotifications];
        const uniqueIds = new Set();
        combinedNotifications.forEach(record => {
            if (project.id === record.params.projectId) {
                uniqueIds.add(record.params.taskId);
            }
        });
        return uniqueIds.size === 0 ? '' : uniqueIds.size.toString();
    }, [assignedTasksNotifications, deadlineTomorrowNotifications, project]);

    const loadProjectDetails = async () => {
        let loadedProject = null;
        if (match.params.projectId) {
            let project = await apiClient.get().projectApi.projectList(
                [],
                [`+name`],
                ['active:true', `id:${match.params.projectId}`],
                ['userFirstName', 'userLastName', 'organizationName'],
                1,
                1
            );
            project = project.data.data[0];
            if (!project) {
                return navigate(`/clients/${match.params.orgId}/projects`);
            }
            loadedProject = {
                id: project.id,
                name: project.name,
                description: project.description || '',
                prefix: project.prefix,
                owner: project.userId,
                status: project.status,
                responsibleId: project.responsibleId,
                templateId: project.templateId,
                type: project.type,
                organizationName: project.organizationName,
                dueDate: project.dateDueTo,
                formId: project.formId,
                response: project.response
            };
            setProject(loadedProject);
        } else {
            setProject({
                id: null,
                name: '',
                description: '',
                prefix: '',
                owner: accessScope.userId,
                dueDate: null,
                formId: null
            })
        }
        return loadedProject;
    }

    const loadProjectDetailsByPrefix = async (prefix) => {
        const loadedProject = await apiClient.get().projectApi.projectList(
            [],
            [`+name`],
            ['active:true', `organizationId:${match.params.orgId}`, `prefix:${prefix}`],
            ['userFirstName', 'userLastName', 'organizationName'],
            1,
            1
        );
        let projectData = null;
        if (loadedProject.data.data.length > 0) {
            const project = loadedProject.data.data[0];
            projectData = {
                userId: project.userId,
                id: project.id,
                name: project.name,
                description: project.description || '',
                prefix: project.prefix,
                owner: project.userId,
                status: project.status,
                responsibleId: project.responsibleId,
                templateId: project.templateId,
                type: project.type,
                organizationName: project.organizationName,
                dueDate: project.dateDueTo
            };
            setProject(projectData);
        } else {
            navigate('/');
        }
        return projectData;
    }

    const loadUsers = async (loadedProject) => {
        let page = 1;
        const result = [];
        let loadMore = true;
        while (loadMore) {
            const users = await apiClient.get().userApi.userList(
                null,
                ['+lastName'],
                ['active:true', `organizationId:${match.params.orgId}`, `roleId:${RolesMapper.ORG_ADMIN}`],
                ['userEmail'],
                page,
                50
            );
            users.data.data.forEach(u => {
                result.push({
                    id: u.id,
                    name: `${u.firstName} ${u.lastName}`,
                    fileName: u.fileName
                })
            });
            page = users.data.page.next;
            loadMore = users.data.page.next !== null;
        }
        if (accessScope.roleId === RolesMapper.UBER_ADMIN) {
            let loadMore = true;
            let page = 1;
            while (loadMore) {
                const users = await apiClient.get().userApi.userList(
                    null,
                    ['+lastName'],
                    ['active:true', `roleId:${RolesMapper.UBER_ADMIN}`],
                    [],
                    page,
                    50
                );
                for (const user of users.data.data) {
                    result.push({
                        id: user.id,
                        name: `${user.firstName} ${user.lastName}`,
                        fileName: user.fileName
                    });
                }
                page = users.data.page.next;
                loadMore = users.data.page.next !== null;
            }
        }

        if (loadedProject) {
            const ownerId = loadedProject.owner;
            const isUserLoaded = result.some(u => u.id === ownerId);
            if (!isUserLoaded) {
                const owner = await apiClient.get().userApi.userGetById(ownerId);
                if (owner.data) {
                    result.push({
                        id: owner.data.id,
                        name: `${owner.data.firstName} ${owner.data.lastName}`,
                        fileName: owner.data.fileName
                    });
                }
            }
        }
        setOrgUsers(result);
    }

    useEffect(() => {
        const path = match.pathname.split('/');
        const selectedTab = getTabs(project, orgUsers).find((t) => path.includes(t.key));
        if (!selectedTab) {
            setDefaultSelectedKeys(['tab-projects-summary', 'summary']);
        } else {
            setDefaultSelectedKeys([selectedTab.key]);
        }
    },[match.pathname]);

    const saveProject = async (data, reloadDetails) => {
        if (match.params.projectId) {
            await updateProject(data, reloadDetails);
            await loadUsers(data);
        } else {
            await createProject(data);
        }
    }

    const onTabSelect = (tab) => {
        if (match.params.projectId) {
            navigate(`/clients/${match.params.orgId}/projects/details/${match.params.projectId}/${tab}`);
        }
    }

    const onDeactivate = async() =>{
        try {
            await apiClient.get().projectApi.projectPatch([`id:${match.params.projectId}`], {active: false});
            message.success('Project has been successfully deleted');
            navigate(`/clients/${match.params.orgId}/projects/`);
        } catch (err) {
            message.error(`An error occurred while try to delete project`);
        }
    }

    const updateProject = async (data, reloadDetails) => {
        try {
            await apiClient.get().projectApi.projectPatch(
                [`id:${project.id}`],
                {
                    userId: data.owner,
                    name: data.name,
                    dateDueTo: data.dueDate,
                    status: data.status,
                    responsibleId: data.responsibleId,
                    description: data.description
                }
            );
            message.success('Project has been successfully updated');
            if (reloadDetails !== false) {
                await loadProjectDetails();
            }
        } catch (err) {
            if (err.response?.data?.details?.["projectParams.prefix"]) {
                message.error('Project prefix already exists');
            } else {
                message.error('An error occurred while trying to save project');
            }
        }
    }

    const createProject = async (data) => {
        try {
            const response = await apiClient.get().projectApi.projectCreate(
                {
                    organizationId: match.params.orgId,
                    userId: data.owner,
                    active: true,
                    name: data.name,
                    dateDueTo: data.dueDate,
                    prefix: data.prefix,
                    status: 'ACTIVE',
                    type: data.type,
                    description: data.description
                }
            );
            if (navigate) {
                navigate(`/clients/${match.params.orgId}/projects/details/${response.data.projectId}`);
            }
            message.success('Project has been successfully created');
        }
        catch (err) {
            if (err.response?.data?.details?.["projectParams.prefix"]) {
                message.error('Project prefix already exists');
            } else {
                message.error('An error occurred while trying to save project');
            }
        }
    }

    const getTabs = (currentProject, users) => {
        const tasksNotificationsCount = calculateTaskIdsCount();
        const statusReportNotificationsCount = projectStatusReportNotifications.filter(n => n.params.projectId === currentProject.id).length;
        const tasksNotificationsCountInformer = tasksNotificationsCount > 0 ? <div className="tasks-notifications-informer">{tasksNotificationsCount}</div> : null;
        const statusReportNotificationsCountInformer = statusReportNotificationsCount > 0 ? <div className="tasks-notifications-informer">{statusReportNotificationsCount}</div> : null;

        return [
            {
                'data-testid': 'tab-project-summary',
                key: 'summary',
                title: 'Summary',
                label: 'Summary',
                icon: <ProjectOutlined/>,
                disabled: false,
                onClick: () => { onTabSelect('summary')},
                page: <ProjectSummary projectId={match.params.projectId} orgId={match.params.orgId} accessScope={accessScope} loggedUser={accessScope} project={currentProject} users={users} onSave={saveProject} allowOwner={allowOwner}/>,
            },
            currentProject.type !== 'DEFAULT' ? {
                'data-testid': 'tab-project-chat',
                key: 'chat',
                title: 'Chat',
                label: 'Chat',
                icon: <CommentOutlined/>,
                disabled: !match.params.projectId && !match.params.taskPrefix,
                page: <ProjectChat accessScope={accessScope} projectId={project.id}/>,
                onClick: () => { onTabSelect('chat')},
            } : null,
            {
                'data-testid': 'tab-project-tasks',
                key: 'tasks',
                title: 'Tasks',
                label: <div className="label-notifications">Tasks {tasksNotificationsCountInformer}</div>,
                icon: <CheckSquareOutlined/>,
                disabled: !match.params.projectId && !match.params.taskPrefix,
                page: <TasksList api={apiClient} accessScope={accessScope} project={project} orgUsers={orgUsers} taskPrefix={match.params.taskPrefix} ></TasksList>,
                onClick: () => { onTabSelect('tasks')},
            },
            {
                'data-testid': 'tab-project-alerts',
                key: 'alerts',
                title: 'Alerts',
                label: 'Alerts',
                icon: <WarningOutlined/>,
                disabled: true,
                page: <div>Alerts</div>,
            },
            {
                'data-testid': 'tab-project-dashboards',
                key: 'dashboards',
                title: 'Dashboards',
                label: 'Dashboards',
                icon: <PieChartOutlined/>,
                disabled: true,
                page: <div>Dashboards</div>,
            },
            {
                'data-testid': 'tab-project-workspaces',
                key: 'workspaces',
                title: 'Workspaces',
                label: 'Workspaces',
                icon: <PartitionOutlined/>,
                disabled: true,
                page: <div>Workspaces</div>,
            },
            currentProject.type !== 'DEFAULT' ? {
                'data-testid': 'tab-project-report',
                key: 'report',
                title: 'Status report',
                label: <div className="label-notifications">Status report {statusReportNotificationsCountInformer}</div>,
                icon: <BarChartOutlined/>,
                disabled: !match.params.projectId,
                page: <ProjectStatusReport accessScope={accessScope} loggedUser={accessScope} api={apiClient}
                                           projectId={project.id}/>,
                onClick: () => {
                    onTabSelect('report');
                },
            } : null,
            {
                'data-testid': 'tab-project-documents',
                key: 'documents',
                title: 'Documents',
                label: 'Documents',
                icon: <FileTextOutlined/>,
                disabled: !match.params.projectId,
                page: <DocumentsList accessScope={accessScope} loggedUser={accessScope} />,
                onClick: () => {
                  onTabSelect('documents');
                },
            },
        ].filter(Boolean);
    }, getSelectedTab = () => {
        let result = null;
        const tabs = getTabs(project, orgUsers);

        const prevSelectedKey = defaultSelectedKeys.length > 0
            ? defaultSelectedKeys[defaultSelectedKeys.length - 1]
            : null;

        if (prevSelectedKey) {
            for (const tab of tabs) {
                if (tab.key === prevSelectedKey) {
                    result = tab;
                }
            }
        }

        return result;
    };

    let defaultTab = getSelectedTab();
    let defaultOpenKeys = ['tab-project-summary'];

    return <div className="project-description-page" data-testid="project-description-page">
        <div className="project-summary-header">
            <div>
                <ArrowLeftOutlined onClick={() => navigate(`/clients/${match.params.orgId}/projects`)} style={{ fontSize: '17px', color: '#262626', cursor: 'pointer' }}  /> <span className="project-name">{project.name || 'Project Name'}</span>
            </div>
            <div>
                {match.params.projectId && <Button icon={<DeleteOutlined onClick={() => showDeactivateConfirm('project', onDeactivate)} style={{ fontSize: '12px', color: '#F5222D' }}/>} danger></Button>}
            </div>
        </div>
            <SidebarLayout
                containerCssClass="projects-layout"
                defaultSelectedPage={defaultTab}
                defaultSelectedKeys={defaultSelectedKeys}
                defaultOpenKeys={defaultOpenKeys}
                sidebarTabs={getTabs(project, orgUsers)}
                menuContainerCssClass="projects-layout-menu"
                collapsed={false}
                sideBarStyle={
                    {
                        width: '220px',
                        height: '100%',
                        backgroundColor: '#FFFFFF'
                    }
                }
            />
    </div>

}

export default ProjectDescriptionPage;
