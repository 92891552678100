import './taskDetailsModal.css';
import {
    CheckOutlined,
    CloseOutlined,
    PictureOutlined,
    PaperClipOutlined,
    SmileOutlined,
    EditOutlined
} from '@ant-design/icons';

import {Input, Button, Radio, Checkbox, DatePicker, InputNumber} from 'antd';
import React, {useState, useRef, useCallback, useEffect} from "react";
import dayjs from 'dayjs';

import ClickOutsideComponent from '../clickOutsideHandler/clickOutsideHandler';
import AssigneesContainer from "../assigneesContainer/assigneesContainer";
import {UserAvatar} from "../userAvatar/userAvatar";
import TaskComments from "../taskComments/taskComments";
import AppCommentTextArea from "../appCommentTextArea/appCommentTextArea";

const TaskDetailsModal = ({
                              api,
                              task,
                              customFormat,
                              users,
                              onTaskUpdate,
                              allowDueDate,
                              setAllowDueDate,
                              editedName,
                              setEditedName,
                              onNameSave,
                              description,
                              setDescription,
                              projectId,
                              orgId,
                              refresh,
                              loggedUserId,
                              onCommentsRequested,
                              taskList,
                              select,
                              getTask,
                              dependencyTask
                          }) => {

    const [editingName, setEditingName] = useState(false);
    const [changedDescription, setChangedDescription] = useState(description);
    const [duration, setDuration] = useState(task.duration);
    const [isDurationActive, setIsDurationActive] = useState(false);
    const [isTextareaActive, setIsTextAreaActive] = useState(false);
    const nameRef = useRef(null);
    const closeNameRef = useRef(null);
    const descriptionRef = useRef(null);
    const closeDescriptionRef = useRef(null);
    const descriptionContainerRef = useRef(null);
    const [commentText, setCommentText] = useState('');
    const [requestReload, setRequestReload] = useState(Date.now());
    const [showDropdownTask, setShowDropdownTask] = useState(false);
    const [commentTextAreaHeight, setCommentTextAreaHeight] = useState(30);

    const taskNameInputRef = useRef(null);
    const descriptionInputRef = useRef(null);

    const { TextArea } = Input;
    const { RangePicker } = DatePicker;
    const day = 1000 * 60 * 60 * 24;

    useEffect(() => {
        if (isTextareaActive) {
            descriptionInputRef.current.focus();
        }
    }, [isTextareaActive]);

    useEffect(() => {
        setDuration(task.duration);
    }, [task.duration]);

    const onNameClickedOutside = async () => {
        await onNameSave();
        setEditingName(false);
    }

    useEffect(() => {
        if (editingName) {
            taskNameInputRef.current.focus();
        }
    }, [editingName]);

    const onDescriptionClickedOutside = async () => {
        await handleSaveDescription();
    }

    const onCommentSave = async () => {
        await api.get().taskCommentApi.taskCommentCreate({
           organizationId: orgId,
           projectId: projectId,
           taskId: task.id,
           text: commentText,
           authorId: loggedUserId,
           active: true,
           dateCreated: (new Date()).toISOString()
        });
        setCommentText('');
        setRequestReload(Date.now());
        refresh();
        setTimeout(() => {
            descriptionContainerRef.current.scroll({ top: descriptionContainerRef.current.scrollHeight, behavior: 'smooth' });
        }, 500);

    }

    const textArea = () => {
        return (
            <TextArea
                className="description"
                data-testid="textarea"
                placeholder="Add description"
                onFocus={() => setIsTextAreaActive(true)}
                value={changedDescription}
                onChange={(e) => {setChangedDescription(e.target.value)}}
                autoSize={{ minRows: 1, maxRows: 18 }}
                ref={descriptionInputRef}
            />
        )
    }

    const handleSaveDescription = async () => {
        setIsTextAreaActive(false);
        setDescription(changedDescription);
        await onTaskUpdate({description: changedDescription.trim()});
    };

    const handleCloseEditDescription = () => {
        setChangedDescription(description);
        setIsTextAreaActive(false);
    }

    const handleEditDuration = (e) => {
        e.stopPropagation();
        setIsDurationActive(true);
    }

    const handleCloseDurationEdit = () => {
        setDuration(task.duration);
        setIsDurationActive(false);
    }

    const getEndDate = () => new Date(new Date(task.start).getTime() + (day * duration));

    const handleSaveDuration = async () => {
        await onTaskUpdate({duration, endDate: getEndDate()});
        setIsDurationActive(false);
    }

    const onDateChange = async (date) => {
        const [start, end] = date;
        const duration = (new Date(end).getTime() - new Date(start).getTime()) / day;
        await onTaskUpdate({
            startDate: start,
            endDate: end,
            duration: Math.round(duration)
        });
        setDuration(Math.round(duration));
    }

    const handleOnKeyDown = (e) => {
        if (e.key === 'Escape') {
            handleCloseDurationEdit();
            e.stopPropagation();
        }
    }

    const handleContentWrapperCommentHeight = (currentCommentHeight) => {
        setCommentTextAreaHeight(currentCommentHeight);
    }

    const handleEditName = (e) => {
        if (e) {
            e.stopPropagation();
        }
        setEditingName(true);
    }

    const handleCloseEdit = () => {
        setEditedName(task.name);
        setEditingName(false);
    }

    const handleSaveName = async () => {
        await onNameSave();
        setEditingName(false);
    }

    const handleKeyPress = useCallback(async (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            await onCommentSave();
        }
    }, [commentText]);

    const getTaskName = () => {
        return <div className="task-name">
                {editingName ?
                    <span ref={nameRef} className="edit-area">
                           <ClickOutsideComponent onClickOutside={onNameClickedOutside}> <TextArea ref={taskNameInputRef} maxLength={255} autoSize={{ minRows: 1, maxRows: 19 }} data-testid="input-value" value={editedName} onChange={(e)=> setEditedName(e.target.value)} /></ClickOutsideComponent>
                        </span>
                    :
                    <span className="title" onClick={handleEditName} data-testid="edit-name">{editedName}</span>}
                {!editingName  && <EditOutlined data-testid="edit-name-button" onClick={(e) => {e.stopPropagation(); handleEditName(e)}}/>}
                {editingName && <div className="edit-icons" data-testid="edit-icons"><span className="check-outlined">
                        <CheckOutlined data-testid="check-name" onClick={async (e) => {e.stopPropagation(); await handleSaveName()}}/> </span><CloseOutlined ref={closeNameRef} data-testid="close" onClick={(e) => { e.stopPropagation(); handleCloseEdit()}}/>
                </div> }
        </div>
    }

    const getDescription = () => {
        return <div className="description-container">
                <div className="subtitle"><span>Description</span>
                    {!isTextareaActive ? <div className="edit-icon"><EditOutlined onClick={(e) => { e.stopPropagation();setIsTextAreaActive(true)}}/></div> : null}
                    {isTextareaActive ? <div className="edit-icons edit-icons-description"><span className="check-outlined">
                            <CheckOutlined data-testid="save-description" onClick={async (e)=> {e.stopPropagation(); await handleSaveDescription()}} /> </span><CloseOutlined ref={closeDescriptionRef} data-testid="close-icon" onClick={()=>handleCloseEditDescription()}/>
                    </div> : null}
                </div>
            <div className="description-text">
                {isTextareaActive ?
                    <div className="text-area-container">
                        <ClickOutsideComponent onClickOutside={onDescriptionClickedOutside} cssClassName="description-click-container">
                           <span ref={descriptionRef}>{textArea()}</span>
                        </ClickOutsideComponent>
                    </div> :
                    <>
                        <div data-testid="textarea" onClick={(e) => { e.stopPropagation();setIsTextAreaActive(true)}}>{description}</div>
                    </>}
            </div>
        </div>
    }

    const getDuration = () => {
        return <div className="stats-container duration-container">
            <p className="subtitle">Duration</p>
            {isDurationActive ?
                <ClickOutsideComponent onClickOutside={handleSaveDuration}>
                <span>
                    <InputNumber onKeyDown={handleOnKeyDown} onPressEnter={handleSaveDuration} autoFocus={true} data-testid="input-duration" min={1} max={1000} value={duration} onChange={(value)=> setDuration(value)} />
                    </span>
                </ClickOutsideComponent>
                :
                <div onClick={(e) => handleEditDuration(e)} data-testid="edit-duration">{duration ?? 0} day{+duration !== 1 ? 's' : ''}</div>}
            {!isDurationActive  && <EditOutlined onClick={(e) => handleEditDuration(e)}/>}
            {isDurationActive && <div className="edit-icons" data-testid="edit-icons"><span className="check-outlined">
                    <CheckOutlined data-testid="check" onClick={handleSaveDuration} /> </span><CloseOutlined data-testid="close" onClick={handleCloseDurationEdit}/>
            </div>}
        </div>
    }

    const onOpenDropdown = () => setShowDropdownTask(true);

    const onSelectedValueChanged = async (value) => {
        await api.get().tasksLinkageApi.tasksLinkageDeleteMany([`taskId: ${task.id}`]);
        setShowDropdownTask(false);
        if (value === 'none' || !value) {
            refresh();
            return;
        }
        await api.get().tasksLinkageApi.tasksLinkageCreate({taskId: task.id, boundTaskId: value});
        refresh();
    }

    const taskDropDownLabel = taskList?.find(t => t.name === dependencyTask?.name)?.name ?? 'None';

    return <div className="task-details-modal" data-testid="task-details-modal">
        <div className="task-description">
            <div ref={descriptionContainerRef} style={{height: `calc(100% - ${commentTextAreaHeight + 76}px)`}}>
                {getTaskName()}
                <div className="task-description-layout">
                    {getDescription()}
                </div>
                <div className="task-comments-section">
                    <TaskComments refresh={refresh} api={api} orgId={orgId} taskId={task.id} projectId={projectId} loggedUserId={loggedUserId} requestReload={requestReload} onCommentsRequested={onCommentsRequested} />
                </div>
            </div>
            <AppCommentTextArea onResize={handleContentWrapperCommentHeight} commentText={commentText} handleKeyPress={handleKeyPress} saveComment={onCommentSave} setCommentText={setCommentText}/>
        </div>
        <div className="task-options">
            <div className="header">Tasks details</div>
            <div className="stats-container status">
                <p className="subtitle">Status</p>
                <Radio.Group data-testid="status" className="buttons" onChange={(e) => onTaskUpdate({status: e.target.value})} value={task.status} >
                    <Radio.Button data-testid='TODO' value="TODO">To do</Radio.Button>
                    <Radio.Button data-testid='IN PROGRESS' value="IN PROGRESS">In progress</Radio.Button>
                    <Radio.Button data-testid='DONE' value="DONE">Done</Radio.Button>
                </Radio.Group>
            </div>
            <div className="stats-container">
                <p className="subtitle">Priority</p>
                <Radio.Group data-testid="priority" className="buttons" onChange={(e) => onTaskUpdate({priority: e.target.value})} value={task.priority} >
                    <Radio.Button data-testid='DEFAULT'  value="DEFAULT">Default</Radio.Button>
                    <Radio.Button data-testid='CRITICAL' value="CRITICAL">Critical</Radio.Button>
                    <Radio.Button data-testid='BLOCKER' value="BLOCKER">Blocker</Radio.Button>
                </Radio.Group>
            </div>
            <div className="stats-container date-picker">
                <div className="subtitle"><Checkbox data-testid="checkbox" className="checkbox" checked={allowDueDate} onChange={(e) => setAllowDueDate(e.target.checked)}/><span className="date-label">Due to</span></div>
                <DatePicker format={customFormat}
                            style={{
                                width: 160
                            }}
                            placeholder="Deadline date"
                            data-testid="data-picker"
                            onChange={async (date) => {
                                await onTaskUpdate({dateDueTo: date})
                            }}
                            disabled={!allowDueDate}
                            value={task.dueDate ? dayjs(task.dueDate) : null}
                />
            </div>
            <div className="stats-container duration">
                <p className="subtitle">Start and end date</p>
                <RangePicker onChange={onDateChange}
                             data-testid="date-picker"
                             allowClear={false}
                             format={customFormat}
                             value={task.start ? [dayjs(task.start), dayjs(task.end)] : null} />
            </div>
            {getDuration()}
            <div className="dependency-task">
                <p className="subtitle">Dependency on task</p>
                {!showDropdownTask && (taskList?.length > 0 || !taskList?.length) && getTask(onOpenDropdown)}
                {showDropdownTask && select(taskDropDownLabel, onSelectedValueChanged, taskList, setShowDropdownTask)}
            </div>
            <div className="stats-container">
                <p className="subtitle">Created by</p>
                <div className="assignees-container">
                    <UserAvatar name={task.createdBy} fileName={task.fileName}/>
                    <div className="created-by">{task.createdBy}</div>
                </div>
            </div>
            <div className="stats-container">
                <p className="subtitle">Assignee</p>
                <AssigneesContainer task={task} users={users} updateTask={onTaskUpdate}/>
            </div>
        </div>
    </div>

}

export default TaskDetailsModal;