import TemplateController from "../controllers/templateController.ts";

const useTemplateTaskOperations = (api) => {


    const templateController = new TemplateController({apiClient: api});

    const updateTemplate = async (filter, params) => {
        await templateController.patchTemplate(filter, params);
    }

    const loadForms = async () => {
       return await templateController.loadForms();
    }

    return [updateTemplate, loadForms];
};

export default useTemplateTaskOperations;