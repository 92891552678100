import ApiClient from "../apiClient";
import {RolesMapper} from "../constants";

export const GENERAL_ERROR = 'GENERAL_ERROR';

export default class UsersController {
    apiClient = null;

    constructor(props) {
        this.apiClient = props.apiClient || new ApiClient();
    }

    loadUbers = async () => {
        try {
            let loadMore = true;
            let page = 1;
            const users = [];
            while (loadMore) {
                const uberAdmins = await this.apiClient.get().userApi.userList(
                    '',
                    ['+firstName'],
                    ['active:true', `roleId:${RolesMapper.UBER_ADMIN}`],
                    [],
                    page,
                    50
                );
                for (const uberAdmin of uberAdmins.data.data) {
                    users.push({
                        id: uberAdmin.id,
                        name: `${uberAdmin.firstName} ${uberAdmin.lastName}`,
                        fileName: uberAdmin.fileName
                    });
                }
                page = uberAdmins.data.page.next;
                loadMore = uberAdmins.data.page.next !== null;
            }
            return users;
        } catch (e) {
            return GENERAL_ERROR;
        }
    }
}