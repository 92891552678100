import './searchableSelect.css';
import {Input, Select} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {CloseOutlined} from "@ant-design/icons";

const SearchableSelect = ({options, selectedValueLabel, onSelectedValueChanged, optionsRenderer, className, disabled, defaultOpen, onDropdownVisibleChange, allowClear, selectEmptyDropdown, width}) => {

    const { Search } = Input;
    const [searchValue, setSearchValue] = useState('');
    const [searchFilter, setSearchFilter] = useState('');
    const searchRef = useRef(null);

    useEffect(() => {
        searchRef.current?.focus();
    }, [searchRef]);

    const onSearch = () => {
        setSearchFilter(searchValue);
    }

    const handleSearchValue = (e) => {
        if (e.key === 'Enter') {
            e.stopPropagation();
            return;
        }
        setSearchValue(e.target.value);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.stopPropagation();
        }
    }

    const filterOptionsIfNeeded = (items, filter) => {

        const optionsToDisplay = filter.length === 0
            ? items
            : items.filter(u => u.name.toLowerCase().includes(filter.toLowerCase()));

        return optionsToDisplay.map((o, i) => {
            const label = optionsRenderer(o, i);
            return {
                label: label,
                value: o.id
            }
        });
    }

    const handleDropdownVisibility = (isOpen) => {
        onDropdownVisibleChange && onDropdownVisibleChange();
        if (isOpen) {
            setSearchFilter('');
            setSearchValue('');
        }
    }

    const optionsToUse = filterOptionsIfNeeded(options, searchFilter);

    if(options.length === 0 && selectEmptyDropdown){
        return <Select className={`searchable-select ${className}`} popupClassName="searchable-select-popup"
                       clearIcon={<CloseOutlined className="close-icon" data-testid="remove"/>}
                       allowClear={allowClear}
                       onDropdownVisibleChange={handleDropdownVisibility}
                       defaultOpen={true}
        />
    }

    return options.length === 0 ?
        <Select
            disabled={true}
            data-testid="searchable-select-empty"
            style={{ width: '100%' }}
            defaultValue={'Loading...'}
            value={selectedValueLabel}
        />
        :
        <Select
            dropdownRender={(menu) => (
                <>
                    <Search placeholder="Search" style={{ height: 24 }}
                            className={`options-search ${className}`}
                            data-testid="options-search"
                            onSearch={onSearch} value={searchValue}
                            onChange={handleSearchValue}
                            onKeyDown={handleKeyDown}
                            ref={searchRef}
                    />
                    {menu}
                </>
            )}
            className={`searchable-select ${className}`}
            clearIcon={<CloseOutlined className="close-icon" data-testid="remove"/>}
            allowClear={allowClear}
            popupClassName="searchable-select-popup"
            data-testid="searchable-select"
            style={width ? { width } : { width: '100%'}}
            defaultValue={selectedValueLabel}
            value={selectedValueLabel}
            defaultOpen={defaultOpen}
            onChange={(value) => onSelectedValueChanged(value)}
            options={optionsToUse}
            onDropdownVisibleChange={handleDropdownVisibility}
            disabled={disabled !== undefined ? disabled : false}
        />
}

export default SearchableSelect;