import './resultMapping.css';
import { Empty } from 'antd';
import MappingGrid from '../mappingGrid/mappingGrid';
import {QUERY_TRANSFORM_RESERVED_FIELDS} from "@violetx-mvp/commons";

const ResultContainer = ({result, onMappingChanged, allowedMenuItems, mappingInfo, disabled, isInProgress}) => {
    if ((!result || result.length === 0)) {
        return <div className="result-mapping-empty"><Empty description="Fields not found. Please run query first."/></div>
    }
    return  <MappingGrid isInProgress={isInProgress} disabled={disabled} fields={result} mappingInfo={mappingInfo} onMappingChanged={onMappingChanged} allowedMenuItems={allowedMenuItems}/>
}

const ResultMapping = ({result, onMappingChanged, mappingInfo, disabled, isInProgress}) => {
    const allowedMappingItems = [];
        QUERY_TRANSFORM_RESERVED_FIELDS.forEach(field => {
            if (!mappingInfo || !mappingInfo.fields.some(m => m.target === field)) {
                allowedMappingItems.push(field);
            }
        });
    return (
        <div className="result-mapping" data-testid="result-mapping">
            <div className="result-mapping-title">Data mapping</div>
            <ResultContainer allowedMenuItems={allowedMappingItems} isInProgress={isInProgress} disabled={disabled} result={result} onMappingChanged={onMappingChanged} mappingInfo={mappingInfo}/>
        </div>
    );
}

export default ResultMapping;
