import {useEffect, useState} from "react";
import ApiClient from "../../apiClient";
import { Avatar } from 'antd';

export function UserAvatar({api, fileName, bucketName, name, size, showOnlyFirstLetter }) {
    const [orgImage, setOrgImage] = useState(null);
    const apiClient = api ? api : new ApiClient();

    useEffect(async() => {
        if (fileName) {
            await loadImage();
        } else {
            setOrgImage(null);
        }
    }, [fileName]);

    const loadImage = async () => {
            try {
                const image = await apiClient.get().filesApi.imageGetByFileName(fileName, bucketName ?? 'users');
                const uint8Array = new Uint8Array(image.data.resultBuffer?.data);
                const blob = new Blob([uint8Array], { type: image.data.type });
                const blobURL = URL.createObjectURL(blob);
                setOrgImage(blobURL);
            } catch (error) {
            }
        }

    function getInitials(name) {
        if (!name) {
            return '';
        }
        const nameParts = name.split(' ');
        let result = '';
        if (nameParts[0]) {
            result += nameParts[0][0];
        }
        if (nameParts[1]) {
            result += nameParts[1][0];
        }
        return result?.toUpperCase();
    }

    return (
        <>
            {orgImage ? <Avatar className="user-menu-avatar"
                                 shape="square" size={size ?? 28} src={orgImage} data-testid='user-photo'/>
                :
                <Avatar className="user-menu-avatar"
                        shape="square" size={size ?? 28}>
                    {showOnlyFirstLetter ? name[0].toUpperCase() : getInitials(name)}
                </Avatar>
            }
        </>
    );

}