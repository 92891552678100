import {Button, message, Modal, Tooltip} from "antd";
import {ExclamationCircleOutlined, MinusCircleOutlined} from "@ant-design/icons";
import React from "react";
import ClientsController from "../../controllers/clientsController";

const RemoveClientsFromGroupButton = ({selectedIds, onClientsRemoved, groupId, api}) => {

    const clientsController = new ClientsController({apiClient: api});

    const removeClientsFromGroup = async () => {
        await clientsController.removeFromGroup(selectedIds, groupId);
        message.success('Clients removed from group successfully');
        onClientsRemoved();
    }

    const onRemoveClientsFromGroup = () => {
        const plural = selectedIds.length <= 1 ? 'y' : 'ies';
        Modal.confirm({
            className: 'remove-clients',
            title: <strong className="delete-phase-confirm-title">Remove compan{plural} from the group</strong>,
            icon: <ExclamationCircleOutlined style={{ color: '#FAAD14'}} />,
            content: `You about remove compan${plural} from the group. Do you want to proceed?`,
            okText: "Remove",
            cancelText: "Cancel",
            cancelButtonProps: {type: 'primary'},
            okButtonProps: {'data-testid': 'delete-phase-button-ok', type: 'default', danger: 'danger' },
            onOk: async (close) => { await removeClientsFromGroup(); return close; },
        });
    }

    return (
        <Tooltip title="Remove selected companies from the group">
            <Button className="remove-client"
                    icon={<MinusCircleOutlined data-testid="remove-from-group"
                                               style={{fontSize: '16px'}}/>}
                    onClick={onRemoveClientsFromGroup} disabled={selectedIds.length === 0}/>
        </Tooltip>
    );
}

export default RemoveClientsFromGroupButton;