import './childSidebarPage.css';
import SidebarLayout from "../sidebarLayout/sidebarLayout";

const ChildSidebarPage = ({header, sidebarTabs, path, defaultSelectedPage, defaultSelectedKeys, childSideBarStyle, sidebarControls, menuContainerCssClass, className, contentStyle}) => {
    return <div className="child-sidebar-page" data-testid="child-sidebar-page">
        <div>{header}</div>
        <div className={className}>
            <SidebarLayout
                contentStyle={contentStyle}
                defaultSelectedPage={defaultSelectedPage}
                sidebarControls={sidebarControls}
                sidebarTabs={sidebarTabs}
                path={path}
                defaultSelectedKeys={defaultSelectedKeys}
                menuContainerCssClass={menuContainerCssClass}
                sideBarStyle={childSideBarStyle ? childSideBarStyle : {
                    width: '220px'
                }}
            />
        </div>
    </div>
}

export default ChildSidebarPage;