import './ownerFilter.css';
import React, {useEffect, useState} from "react";
import {RolesMapper} from "../../constants";
import SearchableUsersFilter from "../searchableUsersFilter/searchableUsersFilter";
const OwnerFilter = ({ onChange, api, clients, ubersAdmins }) => {

    const [owners, setOwners] = useState([]);
    const [clearSelectedOwners, setClearSelectedOwners] = useState(new Date());

    useEffect(async () => {
        await loadOwners();
    }, []);


    useEffect(async () => {
        await loadOwners();
    }, [clients]);

    const loadOwners = async () => {
        let page = 1;
        const result = [];
        let loadMore = true;
        const filter = ['active:true', `roleId:${RolesMapper.ORG_ADMIN}`];
        if (clients.length > 0) {
            filter.push(...clients.map(el => `organizationId:${el}`));
        }
        while (loadMore) {
            const users = await api.get().userApi.userList(
                null,
                ['+firstName'],
                filter,
                ['userEmail'],
                page,
                50
            );
            users.data.data.forEach(u => {
                result.push({
                    id: u.id,
                    name: `${u.firstName} ${u.lastName}`,
                    fileName: u.fileName
                })
            });
            page = users.data.page.next;
            loadMore = users.data.page.next !== null;
        }
        setOwners(result);
        setClearSelectedOwners(new Date());
    }

    return (
        <div className="owner-filter">
            <SearchableUsersFilter
                onChange={onChange}
                users={owners}
                label="Owner"
                ubersAdmins={ubersAdmins}
                clearSelectedItems={clearSelectedOwners}
                className="owner-filter"
            />
        </div>
    );
}

export default OwnerFilter;


