import './awsForm.css';

import {useEffect, useState} from "react";
import Papa from "papaparse";

import {
    Form,
    Input,
    Button
} from 'antd';

import UploadContainer from "../uploadContainer/uploadContainer";

const formData = {
    accessKey: '',
    regions: '',
    secretKey: ''
};

const AWSForm = ({ onDataChanged }) => {

    const errorMessages = {
        WRONG_FILE_TYPE: 'Uploaded file has wrong format. \n' +
            'Only .CSV format is  supported.',
        FAILED_TO_PARSE: 'Uploaded file does not have expected data'
    }

    const [accessKey, setAccessKey] = useState(formData.accessKey);
    const [regions, setRegions] = useState(formData.regions);
    const [secretKey, setSecretKey] = useState(formData.secretKey);

    const parseCSV = (data) => {
        const keys = Object.values(Papa.parse(data, {header: true}).data[0]);
        if (keys[0].length === 20) {
            setAccessKey(keys[0]);
            setSecretKey(keys[1]);
                formData.accessKey = keys[0];
            formData.secretKey = keys[1];
            onDataChanged(formData);
        } else {
            throw new Error('Failed to parse file');
        }
    }

    useEffect(() => {
        onDataChanged(formData);
        return () => {
            formData.secretKey = '';
            formData.accessKey = '';
            formData.regions = '';
            onDataChanged({});
        }
    }, []);

    return <div className="aws-form" data-testid="aws-form">
        <div className="desc">Generate and download a CSV key for an existing service account in your AWS and upload it there, or put the data manually.</div>
        <Form
            layout="vertical"
        >
            <div className="form-layout">
                <div className="col-1">
                    <Form.Item label="Access key" required>
                        <Input required={true} placeholder="Access key" value={accessKey} onChange={(e) => {
                            setAccessKey(e.target.value);
                            formData.accessKey = e.target.value;
                            onDataChanged(formData);
                        }} data-testid="access-key" style={{height: 40}}/>
                    </Form.Item>
                    <Form.Item label="Secret key" required>
                        <Input placeholder="*****" value={secretKey} onChange={(e) => {
                            setSecretKey(e.target.value);
                            formData.secretKey = e.target.value;
                            onDataChanged(formData);
                        }} data-testid="secret-key" style={{height: 40}}/>
                    </Form.Item>
                    <Form.Item label="Regions" required>
                        <Input placeholder="Regions" value={regions} onChange={(e) => {
                            setRegions(e.target.value)
                            formData.regions = e.target.value;
                            onDataChanged(formData);
                        }} data-testid="regions" style={{height: 40}}/>
                    </Form.Item>
                    <Form.Item>
                        <Button className='test-connection' type='primary' disabled={true}>Test connection</Button>
                    </Form.Item>
                </div>
                <div className="col-2">-or-</div>
                <div className="col-3">
                    <UploadContainer contentType="text/csv" parseFile={parseCSV} messages={errorMessages} title="Drop AWS access key file here"/>
                </div>
            </div>
        </Form>
    </div>
}

export default AWSForm;