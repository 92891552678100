import './documentDetails.css';
import {
    CloseOutlined,
    DeleteOutlined,
    SendOutlined,
    EditOutlined,
    CheckOutlined
} from "@ant-design/icons";
import UploadDocumentIcon from "../../../assets/images/upload-document.svg";
import {Tooltip, Popover, Input, Button} from "antd";
import {UserAvatar} from "../../userAvatar/userAvatar";
import {useCallback, useEffect, useRef, useState} from "react";
import DocumentCommentsSection from "../documentCommentsSection/documentCommentsSection";

const DocumentDetails = ({document, onClose, onDocumentDelete, onDocumentEdit, loggedUserId, api}) => {

    const [isEditOpened, setIsEditOpened] = useState(false);
    const [name, setName] = useState(document.name);
    const [link, setLink] = useState(document.url);
    const [description, setDescription] = useState(document.description);
    const contentWrapperRef = useRef(null);

    const [isDescEditActive, setIsDescEditActive] = useState(false);

    const descRef = useRef(null);
    const closeDescRef = useRef(null);
    const documentDescRef = useRef(null);

    const { TextArea } = Input;

    useEffect(() => {
        if (isDescEditActive) {
            documentDescRef.current.focus();
        }
    }, [isDescEditActive]);

    useEffect(() => {
        async function handleClickOutsideDesc(event) {
            if (descRef.current && !descRef.current.contains(event.target) && closeDescRef && !closeDescRef.current.contains(event.target)) {
                await handleSaveDesc();
            }
        }
        window.document.addEventListener('mousedown', handleClickOutsideDesc);
        return () => {
            window.document.removeEventListener('mousedown', handleClickOutsideDesc);
        };
    }, [closeDescRef, description, descRef]);

    useEffect(() => {
        setName(document.name);
        setLink(document.url);
        setDescription(document.description);
    }, [document]);

    const handleSaveDesc = async () => {
        setIsDescEditActive(false);
        if (description.trim().length === 0) {
            setDescription('');
        }
        document.description = description;
        await onDocumentEdit(document);
    };

    const hide = () => {
        setIsEditOpened(false);
        setName(document.name);
        setLink(document.url);
    };
    const handleOpenChange = (newOpen) => {
        setIsEditOpened(newOpen);
    };

    const doEdit = () => {
        if (onDocumentEdit) {
            document.name = name;
            document.url = link;
            onDocumentEdit(document);
            hide();
        }
    }

    const allowSave = () => {
        return !((name?.trim() !== document.name ||
            link?.trim() !== document.url) && (name?.trim().length > 0 && link?.trim().length > 0))
    }

    const getTextArea = () => {
        return (
            <TextArea
                className="description"
                data-testid="textarea"
                placeholder="Add description"
                onFocus={() => setIsDescEditActive(true)}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                autoSize={true}
                rows={18}
                ref={documentDescRef}
            />
        )
    }

    const handleCloseEditDesc = () => {
        setDescription(document.description);
        setIsDescEditActive(false);
    }

    const onDocumentSaved = useCallback(() => {
        setTimeout(() => {
            contentWrapperRef.current.scroll({ top: contentWrapperRef.current.scrollHeight, behavior: 'smooth' });
        }, 500);
    }, []);

    const isEditCssClass = isEditOpened ? 'show-icon' : ''

    const content = <div className="document-edit">
        <div className="document-edit-body">
            <div className="form-row">
                <div className="form-label">Document name</div>
                <div><Input data-testid="edit-name" maxLength={128} className="edit-textbox" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} /></div>
            </div>
            <div className="form-row">
                <div data-testid="edit-link" className="form-label">Link to a document</div>
                <div><Input placeholder="Url" data-testid="edit-link-input" className="edit-textbox" value={link} onChange={(e) => setLink(e.target.value)} /></div>
            </div>
        </div>
        <div className="document-edit-footer">
            <Button onClick={hide} data-testid="edit-cancel">Cancel</Button>
            <Button onClick={doEdit} data-testid="edit-apply" disabled={allowSave()} type="primary">Apply</Button>
        </div>
    </div>

    return <div className="document-details" ref={contentWrapperRef}>
        <div className="document-details-header">
            <div>File</div>
            <div className="document-commands">
                <div><SendOutlined style={{ fontSize: '16px', color: '#262626' }} /></div>
                <div><DeleteOutlined data-testid="delete-document-button" style={{ fontSize: '16px', color: '#F5222D' }} onClick={async () => { await onDocumentDelete(document.id, false, null)}} /></div>
                <div><CloseOutlined onClick={onClose} style={{ fontSize: '16px', color: '#262626' }} data-testid="close-doc-details"/></div>
            </div>
        </div>
        <div className="document-details-body">
            <div className="document-row">
                <div>File</div>
                <div className="document-name-row" data-testid="document-name-row">
                    <img src={UploadDocumentIcon} alt="upload-document"/>
                    <a className="document-download" href={document.url} target="_blank" title={document.name}>{document.name}</a>
                    <Popover open={isEditOpened} onOpenChange={handleOpenChange} overlayClassName="document-edit-popover" content={content} placement="bottom" title="Edit document" trigger="click">
                        <EditOutlined className={`document-edit-button ${isEditCssClass}`} data-testid="document-edit-button" style={{ fontSize: '14px', color: '#262626', padding: '4px', cursor: 'pointer' }} />
                    </Popover>
                </div>
            </div>
            <div className="document-row">
                <div>Description</div>
                <div className="description-container">
                    {isDescEditActive ?
                        <div className="text-area-container">
                            <div className="edit-icons"><span className="check-outlined">
                            <CheckOutlined data-testid="save-description" onClick={()=>handleSaveDesc()} /> </span><CloseOutlined ref={closeDescRef} data-testid="close-icon" onClick={()=>handleCloseEditDesc()}/>
                            </div>
                            <span ref={descRef}>{getTextArea()}</span>
                        </div> :
                        <>
                            {getTextArea()}
                            <EditOutlined onClick={() => setIsDescEditActive(true)}/>
                        </>}
                </div>
            </div>
            <div className="document-row">
                <div>Added by</div>
                <div>
                    <Tooltip placement="top" title={`${document.userFirstName} ${document.userLastName}`}>
                    <span>
                        <UserAvatar name={`${document.userFirstName} ${document.userLastName}`} fileName={document.userFileName}/>
                    </span>
                        <span className="user-name">{document.userFirstName} {document.userLastName}</span>
                    </Tooltip>
                </div>
            </div>
            <DocumentCommentsSection
                api={api}
                loggedUserId={loggedUserId}
                document={document}
                onDocumentCommentSaved={onDocumentSaved}
            />
        </div>
    </div>
}

export default DocumentDetails;