import {QueryTransform, QueryTransformResult, ResultItem, ResultItemFields} from './queryMappingType';

export const EMPTY_DATA_RESULT = 'No data';

export const QUERY_TRANSFORM_RESERVED_FIELDS = ["Inventory", "Owner", "Timestamp"];

export const QUERY_TRANSFORM_FIELDS = ["Inventory", "Owner", "Timestamp", "Result", "Status"];

export const SOFTWARE_TRANSFORM_FIELDS = ["Inventory","Owner","Timestamp","Name","Version","Type","Vendor"];

export function snapshotQueryMapping(rawData: any, queryTransform: QueryTransform, snapshotEvaluation: any): QueryTransformResult {
    return queryTransformCalculation(rawData, queryTransform, snapshotEvaluation);
}

export function distributedQueryMapping(distributedQueryResult: any, queryTransform: QueryTransform): QueryTransformResult {
    const rawData = distributedQueryResult.rawData ? JSON.parse(distributedQueryResult.rawData) : [];
    return queryTransformCalculation(rawData, queryTransform, distributedQueryResult);
}

function queryTransformCalculation(rawData: any, queryTransform: QueryTransform, calculationSource: any) {
    let result: ResultItem[] = [];
    if(rawData && rawData.length > 0) {
        for(const rawDataItem of rawData) {
            let resultItem: ResultItem = processMappingFields(rawDataItem, queryTransform, calculationSource);
            resultItem.customFields = processMappingCustomFields(rawDataItem, queryTransform, calculationSource);
            result.push(resultItem);
        }
    } else {
        result.push(reservedFieldsCalculation({}, calculationSource));
    }
    return {result: result} as QueryTransformResult;
}

function processMappingFields(rawDataItem: any, queryTransform: QueryTransform, calculationSource: any) {
    let resultItem: ResultItem = {};
    for(const mappingField of queryTransform.fields) {
        switch(mappingField.source) {
            case 'Inventory':
                resultItem[mappingField.target] = devicePlatformName(calculationSource.deviceType);
                break;
            case 'Owner':
                resultItem[mappingField.target] = calculationSource.deviceInfo && calculationSource.scanTopic === 'CLOUDS'
                    ? calculationSource.deviceInfo.name
                    : `${calculationSource.userFirstName} ${calculationSource.userLastName}`;
                break;
            case 'Timestamp':
                resultItem[mappingField.target] = calculationSource.timestamp;
                break;
            default:
                resultItem[mappingField.target] = typeof rawDataItem[mappingField.source] === 'object' ? JSON.stringify(rawDataItem[mappingField.source]) : rawDataItem[mappingField.source] ?? EMPTY_DATA_RESULT;
        }
    }
    return resultItem;
}

function processMappingCustomFields(rawDataItem: any, queryTransform: QueryTransform, _calculationSource: any) {
    let customFields: ResultItemFields = {};
    for(const customField of queryTransform.customFields??[]) {
        customFields[customField] = JSON.stringify(rawDataItem[customField]);
    }
    return customFields;
}

function reservedFieldsCalculation(resultItem: ResultItem, reservedFieldsSource: any) {
    for(const fieldName of QUERY_TRANSFORM_RESERVED_FIELDS) {
        if(!resultItem.hasOwnProperty(fieldName)) {
            switch(fieldName) {
                case 'Inventory':
                    resultItem[fieldName] = devicePlatformName(reservedFieldsSource.deviceType);
                    break;
                case 'Owner':
                    resultItem[fieldName] = `${reservedFieldsSource.userFirstName} ${reservedFieldsSource.userLastName} `;
                    break;
                case 'Timestamp':
                    resultItem[fieldName] = reservedFieldsSource.timestamp;
                    break;
            }
        }
    }
    return resultItem;
}

export function devicePlatformName(devicePlatform: string) {
    switch(devicePlatform) {
        case 'darwin':
            return 'MacOS';
        case 'windows':
            return 'Windows';
        case 'linux':
            return 'Linux';
        case 'aws':
            return 'AWS';
        case 'gcp':
            return 'GCP';
        default:
            return devicePlatform;
    }
}
