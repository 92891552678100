import './projectSummary.css';
import {
    Form,
    Input,
    Checkbox,
    DatePicker,
    Button,
    Modal,
    Dropdown,
} from 'antd';
import {SaveOutlined, CheckOutlined, CloseOutlined, PaperClipOutlined, EditOutlined, DownOutlined} from '@ant-design/icons';
import {useEffect, useRef, useState} from "react";
import dayjs from 'dayjs'

import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import SearchableSelect from "../searchableSelect/searchableSelect";
import {UserAvatar} from "../userAvatar/userAvatar";
import {generateProjectPrefix, getTag} from "../utils";
import ApiClient from "../../apiClient";
import {RolesMapper} from "../../constants";
import {useNavigate} from "react-router-dom";
import ClickOutsideComponent from "../clickOutsideHandler/clickOutsideHandler";

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const ProjectSummary = ({ project, projectId, orgId, users, onSave, allowOwner, api, accessScope }) => {

    const customFormat = (value) => value.format('MMMM DD, YYYY');

    const getItems = () => {
        const itemsToDisplay = [
            {
            label: 'Requested',
            key: 'REQUESTED'
            },
            {
                label: 'Active',
                key: 'ACTIVE'
            },
            {
                label: 'Done',
                key: 'DONE'
            }
        ];
        if (project.type === 'VX_SERVICE') {
            return itemsToDisplay.concat([
                {
                    label: 'Ask to close',
                    key: 'ASK_TO_CLOSE'
                },
                {
                    label: 'Archived',
                    key: 'DRAFT'
                }
                ]);
        }
        return itemsToDisplay;
    }


    const [name, setName] = useState(project.name);
    const [description, setDescription] = useState(project.description);
    const [prefix, setPrefix] = useState(project.prefix);
    const [allowDueDate, setAllowDueDate] = useState(project.dueDate !== null);
    const [dueDate, setDueDate] = useState(project.dueDate ? dayjs(project.dueDate) : null);
    const [owner, setOwner] = useState(project.owner);
    const [status, setStatus] = useState(project.status);
    const [currentStatus, setCurrentStatus] = useState(project.status);
    const [responsible, setResponsible] = useState(project.responsibleId);
    const [prefixError, setPrefixError] = useState('');
    const isOwnerRequired = allowOwner === undefined || allowOwner === true;
    const [uberAdmins, setUberAdmins] = useState([]);
    const [response, setResponse] = useState(null);
    const [showResponseModal, setShowResponseModal] = useState(false);
    const [responseNotReady, setResponseNotReady] = useState(false);
    const [editingName, setEditingName] = useState(false);
    const [editingDescription, setEditingDescription] = useState(false);
    const [showOwnerDropdown, setShowOwnerDropdown] = useState(false);

    const projectNameInputRef = useRef(null);
    const nameRef = useRef(null);
    const closeNameRef = useRef(null);
    const descriptionRef = useRef(null);
    const closeDescriptionRef = useRef(null);
    const descriptionInputRef = useRef(null);

    const navigate = useNavigate();

    const apiClient = api ? api : new ApiClient();

    useEffect(async () => {
        setName(project.name);
        setDescription(project.description);
        setPrefix(project.prefix);
        setAllowDueDate(project.dueDate !== null);
        setDueDate(project.dueDate ? dayjs(project.dueDate) : null);
        setOwner(project.owner);
        setStatus(project.status);
        setResponsible(project.responsibleId);
        project.responsibleId && await loadUberAdmins();
        setCurrentStatus(project.status);
        if (project.formId && project.response) {
            setResponse(JSON.parse(project.response));
        }
        if (project.formId && !project.response) {
            await loadProjectResponse(project.id);
        }
    }, [project]);

    useEffect(() => {
        if (editingDescription) {
            descriptionInputRef.current.focus();
        }
    }, [project, editingDescription]);

    useEffect(() => {
        async function handleClickOutsideProjectName(event) {
            if (nameRef.current && !nameRef.current.contains(event.target) && closeNameRef && !closeNameRef.current.contains(event.target)) {
                await handleSaveName();
            }
        }
        document.addEventListener("mousedown", handleClickOutsideProjectName);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideProjectName);
        };
    }, [project, nameRef, name, closeNameRef]);


    useEffect(() => {
        async function handleClickOutsideProjectDescription(event) {
            if (descriptionRef.current && !descriptionRef.current.contains(event.target) && closeDescriptionRef && !closeDescriptionRef.current.contains(event.target)) {
                await handleSaveDescription();
            }
        }
        document.addEventListener("mousedown", handleClickOutsideProjectDescription);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideProjectDescription);
        };
    }, [project, closeDescriptionRef, description, descriptionRef]);

    useEffect(() => {
        if (editingName) {
            projectNameInputRef.current.focus();
        }
    }, [project, editingName]);


    const changeProjectName = (value) => {
        setName(value);
    }

    const loadProjectResponse = async (projectId) => {
        const responseData = await apiClient.get().projectApi.surveyResult(projectId);
        if (responseData.data && responseData.data.status === 0) {
            setResponse(responseData.data.data);
        }
        if (responseData.data.status === 1) {
            setResponseNotReady(true);
        }
    }

    function saveProject(newStatus, newOwner, newDate, newPrefix) {
        return onSave(
            {
                name,
                description,
                prefix: newPrefix ?? prefix,
                dueDate: newDate && allowDueDate ? newDate.format() : null,
                status : newStatus ?? status,
                responsibleId: responsible ? responsible : null,
                templateId: project.templateId ? project.templateId : null,
                type: project.type ?? 'DEFAULT',
                owner: newOwner ?? owner
            }
        )
    }

    const onProjectSave = async (newStatus, newOwner, newDate) => {
        let newPrefix = null;
        newDate = newDate !== undefined ? newDate : dueDate;
        if (!projectId) {
            let load = true;
            let page = 1;
            const result = [];
            while (load) {
                const projects = await apiClient.get().projectApi.projectList(
                    `${prefix}`,
                    [''],
                    [`organizationId:${orgId}`],
                    [],
                    page,
                    50
                );
                for (const project of projects.data.data) {
                    result.push({
                        prefix: project.prefix
                    });
                }
                page = projects.data.page.next;
                load = projects.data.page.next !== null;
            }
            const prefixes = result.filter(el => el.prefix.startsWith(prefix));
            let lastPrefixIndex = Math.max(...prefixes.map(el => el.prefix.slice(2)), -1);
            if (lastPrefixIndex !== -1) {
                lastPrefixIndex++;
                newPrefix = prefix + lastPrefixIndex;
            }
        }
        saveProject(newStatus, newOwner, newDate, newPrefix)
    }
    const navigateToClient = () => {
        navigate(`/clients/${orgId}/summary`)
    }

    const setProjectPrefix = () => {
        if (project.id !== null) {
            return;
        }
        const prefix = generateProjectPrefix(name);
        if (prefix !== null) {
            changePrefix(prefix.toUpperCase());
        }
    }

    const loadUberAdmins = async () => {
        let load = true;
        let page = 1;
        const result = [];
        while (load) {
            const uberAdmins = await apiClient.get().userApi.userList(
                '',
                [''],
                ['active:true', `roleId:${RolesMapper.UBER_ADMIN}`],
                [],
                page,
                50
            );
            for (const uberAdmin of uberAdmins.data.data) {
                result.push({
                    id: uberAdmin.id,
                    name: `${uberAdmin.firstName} ${uberAdmin.lastName}`,
                    fileName: uberAdmin.fileName
                });
            }
            page = uberAdmins.data.page.next;
            load = uberAdmins.data.page.next !== null;
        }
            const responsibleId = project.responsibleId;
            const isUserLoaded = result.some(u => u.id === responsibleId);
            if (!isUserLoaded) {
                const responsibleId = await apiClient.get().userApi.userGetById(project.responsibleId);
                if (responsibleId.data) {
                    result.push({
                        id: responsibleId.data.id,
                        name: `${responsibleId.data.firstName} ${responsibleId.data.lastName}`,
                        fileName: responsibleId.data.fileName
                    });
                }
            }
        setUberAdmins(result);
    }

    const allowSave = () => {
        let result = isOwnerRequired ? name && owner : name;
        return !(result && !/^\d/.test(name) && name.length > 1 );
    }

    const ownerDropDownLabel = users.find(u => u.id === owner) ? owner : 'Loading...';

    const ownerOptionRenderer = (option, index) => {
        return <div className="lead-item" data-testid={`option-item-${index}`}>
            <UserAvatar fileName={option.fileName} key={option.name} name={option.name}/>
            <span style={{marginLeft: '4px'}}>{option.name}</span>
        </div>
    }

    const changePrefix = (prefix) => {
        setPrefixError('');
        if (/^\d/.test(prefix)) {
            setPrefixError('First symbol should always be alphabetical');
        }
        setPrefix(prefix);
    }

    const setStatusAndSave = (status) => {
        setStatus(status);
        setCurrentStatus(status);
        saveProject(status);
    }

    const handleSaveDescription = async () => {
        setEditingDescription(false);
        if (project.description === description) {
            return;
        }
        if (description.trim().length === 0) {
            setDescription('');
        }
        await onProjectSave();
    };

    const handleCloseEditDescription = () => {
        setDescription(project.description);
        setEditingDescription(false);
    }

    const handleEditDescription = () =>{
        setEditingDescription(true);
    }

    const handleSaveName = async () => {
        setEditingName(false);
        if (project.name === name) {
            return;
        }
        if (name.trim().length > 0) {
            await onProjectSave();
        } else {
            setName(project.name);
        }
    };

    const handleEditName = () =>{
        setEditingName(true);
    }

    const handleCloseEdit = () => {
        setName(project.name);
        setEditingName(false);
    }
    const onCheckBoxChanged = async (isChecked) => {
        setAllowDueDate(isChecked);
        if (projectId && !isChecked) {
            await onProjectSave(null, null, null);
        }
    }

    const getStatusForm = () => {
        let result = null;
        if (accessScope.roleId === RolesMapper.UBER_ADMIN || accessScope.roleId === RolesMapper.ORG_ADMIN && project.type !== 'VX_SERVICE') {
            result = <div className="label-wrapper">
                <span>Status</span>
                <Dropdown
                    menu={{
                        items: getItems(),
                        onClick: (e) => onProjectSave(e.key),
                    }}
                trigger="click">
                    <Button>
                        {getItems().find(i => i.key === project.status).label}
                        <DownOutlined />
                    </Button>
                </Dropdown>
            </div>
        }
        if (accessScope.roleId === RolesMapper.ORG_ADMIN && currentStatus !== 'ASK_TO_CLOSE' && project.type === 'VX_SERVICE') {
            result = <div className="project-summary-status">
                <div>Status</div>
                <div>{getTag(currentStatus)}</div>
            </div>
        }
        if (accessScope.roleId === RolesMapper.UBER_ADMIN && currentStatus === 'REQUESTED' && project.type === 'VX_SERVICE') {
            result = <div className="project-summary-status-banner requested">
                <div className="project-summary-status">
                    <div>Status</div>
                    <div>{getTag(currentStatus)}</div>
                </div>
                <div className="request-message">
                    {project.organizationName} requested this project. Do you accept?
                </div>
                <div className="buttons request">
                    <div onClick={() => setStatusAndSave('ACTIVE')} className={status === 'ACTIVE' ? 'selected' : ''}><CheckOutlined style={{
                        color: '#52C41A',
                        width: '16px',
                        height: '16px'
                    }} data-testid="accept-button" /><label>Accept & start project</label></div>
                    <div onClick={() => setStatusAndSave('DRAFT')} className={status === 'DRAFT' ? 'selected' : ''}><CloseOutlined style={{
                        color: '#FF4D4F',
                        width: '16px',
                        height: '16px'
                    }} data-testid="decline-button" /><label>Decline</label></div>
                </div>
            </div>
        }
        if (accessScope.roleId === RolesMapper.ORG_ADMIN && project.type === 'VX_SERVICE' && currentStatus === 'ASK_TO_CLOSE') {
            result = <div className="project-summary-status-banner">
                <div className="project-summary-status">
                    <div>Status</div>
                    <div>{getTag(currentStatus)}</div>
                </div>
                <div className="request-message">
                    VioletX requested to close the project. Do you accept?
                </div>
                <div className="buttons">
                    <div onClick={() => setStatusAndSave('DONE')} className={status === 'DONE' ? 'selected' : ''}><CheckOutlined style={{
                        color: '#52C41A',
                        width: '16px',
                        height: '16px'
                    }} data-testid="accept-button" /><label>Accept & сlose</label></div>
                    <div onClick={() => setStatusAndSave('ACTIVE')} className={status === 'ACTIVE' ? 'selected' : ''}><CloseOutlined style={{
                        color: '#FF4D4F',
                        width: '16px',
                        height: '16px'
                    }} data-testid="decline-button" /><label>Decline & continue</label></div>
                </div>
            </div>
        }
        return result;
    }

    const getResponseItems = () => {
        return response?.data.map((r, index) => <div className="response-item" key={`response-item-${index}`}>
            <div>{r.question}</div>
            <div>{r.answer}</div>
        </div>);
    }

    const getResponsible = () => {
        const responsible = uberAdmins.find(u => u.id === project.responsibleId)
        return ownerOptionRenderer(responsible, responsible.id)
    }

    const getOwner = () => {
        const ownerData = users.find(u => u.id === owner)
        return <div onClick={() => setShowOwnerDropdown(true)}>
            {ownerOptionRenderer(ownerData, ownerData.id)}
        </div>
    }

    const onSelectedValueChanged = async (value) => {
        setOwner(value);
        if (projectId) {
            await onProjectSave(null, value);
        }
        setShowOwnerDropdown(false);
    }

    const renderSearchableSelect = (className) => (
        <SearchableSelect
            selectedValueLabel={ownerDropDownLabel}
            className={`project-summary-select ${className}`}
            onSelectedValueChanged={onSelectedValueChanged}
            options={users}
            optionsRenderer={ownerOptionRenderer}
            onDropdownVisibleChange={setShowOwnerDropdown}
            defaultOpen={true}
        />
    );

    const getOwnerSelect = (dropdown, picker, menu, wrapper) => {
        return <>
            <div className={menu}>
            <span>
                <Checkbox checked={allowDueDate} onChange={(e) => onCheckBoxChanged(e.target.checked)}>
                    Due to
                </Checkbox>
            </span>
                <DatePicker className={picker} onChange={async (date) => {
                    setDueDate(date);
                    if(projectId) {
                        await onProjectSave(null, null, date);
                    }
                }} disabled={!allowDueDate} value={dueDate} format={customFormat}/>
            </div>
            <div className={wrapper} style={{height: '28px'}}>
                <span className="owner-text">Owner</span>
                {projectId && users.length > 0 && !dropdown && getOwner()}
                {projectId && dropdown && renderSearchableSelect('right-select')}
                {!projectId && renderSearchableSelect('left-select')}
            </div>
            {project && project.responsibleId && uberAdmins.length > 0 && <div className="label-wrapper">
                <span>Responsible</span>
                {getResponsible()}
            </div>}
        </>
    }

    const getProjectName = () => {
       return <div className="project-name">
            {editingName ?
                <span ref={nameRef}>
                        <Input
                            placeholder="Name"
                            value={name}
                            data-testid="text-name"
                            onChange={(e) => changeProjectName(e.target.value)}
                            onBlur={setProjectPrefix}
                            ref={projectNameInputRef}
                            onPressEnter={handleSaveName}
                            onKeyUp={(e) => {
                                if (e.key === 'Escape') {
                                    handleCloseEdit();
                                }
                            }}
                        />
                    </span>
                :
                <p onClick={handleEditName} data-testid="edit-name">{name}</p>}
            {!editingName  && <EditOutlined onClick={handleEditName}/>}
            {editingName && <div className="edit-icons" data-testid="edit-icons"><span className="check-outlined">
                    <CheckOutlined data-testid="check" onClick={handleSaveName} /> </span><CloseOutlined ref={closeNameRef} data-testid="close" onClick={handleCloseEdit}/>
            </div> }
        </div>
    }

    const getDescriptionProject = () => {
        return <div className={editingDescription ? "description-container active" : "description-container"}>
            <div className="subtitle"><span>Description</span>
                {!editingDescription && <EditOutlined className="edit-icon" onClick={handleEditDescription}/>}
                {editingDescription ? <div className="edit-icons edit-icons-description"><span className="check-outlined">
                       <CheckOutlined data-testid="save-description" onClick={handleSaveDescription} /> </span><CloseOutlined ref={closeDescriptionRef} data-testid="close-icon" onClick={handleCloseEditDescription}/>
                </div> : null}
            </div>
                {editingDescription ?
                    <div className="text-area-container">
                        <ClickOutsideComponent onClickOutside={async()=> await handleSaveDescription()} cssClassName="description-click-container">
                            <span ref={descriptionRef}>
                                 <Input.TextArea rows={4} placeholder="Description" value={description} data-testid="text-description" onChange={(e) => setDescription(e.target.value)} ref={descriptionInputRef}/>
                            </span>
                        </ClickOutsideComponent>
                    </div> :
                    <>
                        <div onClick={handleEditDescription} className="project-description" placeholder="Description" data-testid="description">{description}</div>
                    </>}
        </div>
    }

    return <div className="project-summary">
        {response && <Modal className="project-summary-response" title="Questionnarie answers" open={showResponseModal} onCancel={() => setShowResponseModal(false)}
            cancelText="Close" width="644" footer={[
                <Button style={{width: '90px'}} key="back" onClick={() => setShowResponseModal(false)}>Close</Button>
            ]}
        >
            {getResponseItems()}
        </Modal>}
        <div className="form-header">
            {!projectId ? <h1 data-testid="project-summary-title">Project summary</h1> : getProjectName()}
            {!projectId && <Button type="primary" data-testid="save-project" onClick={onProjectSave} disabled={allowSave()}><SaveOutlined/> Save</Button> }
        </div>
        <div className="project-summary-view">
            <div>
                <Form
                    layout="vertical"
                    className="project-view-edit-form"
                >
                    {projectId && <><Form.Item className="form-item" label="Project prefix">
                        <p placeholder="Prefix" data-testid="text-prefix">{prefix}</p>
                        <div className="error">{prefixError}</div>
                    </Form.Item><Form.Item className="form-item">{getDescriptionProject()}</Form.Item></>
                    }
                    {!projectId && <>
                        <Form.Item label="Name">
                        <Input placeholder="Name" value={name} data-testid="text-name"
                               onChange={(e) => changeProjectName(e.target.value)} onBlur={setProjectPrefix}/>
                        </Form.Item>
                        <Form.Item label="Description">
                            <Input.TextArea rows={4} placeholder="Description" value={description} data-testid="text-description" onChange={(e) => setDescription(e.target.value)}/>
                        </Form.Item>
                        <Form.Item label="Project prefix">
                        <Input placeholder="Prefix" value={prefix} disabled={true} data-testid="text-prefix"/>
                        <div className="error">{prefixError}</div>
                        </Form.Item>
                    </>
                    }
                </Form>
                {response &&
                    <div className="form-info">
                        <div className="form-item">Questionnaire</div>
                        <div data-testid="show-responses" onClick={() => setShowResponseModal(true)}><PaperClipOutlined style={{
                            color: '#1890FF',
                            width: '24px',
                            height: '24px',
                            fontSize: '24px',
                            marginRight: '16px'
                        }} />{response.title}</div>
                    </div>
                }
                {responseNotReady &&
                    <div className="form-info">
                        <div>Questionnaire</div>
                        <div>Not ready yet</div>
                    </div>
                }
                {!projectId &&
                    <>
                        {getOwnerSelect(showOwnerDropdown, "date-picker", "left-menu", "left-menu-wrapper")}
                    </>
                }
            </div>
            {projectId &&
                <div>
                    <span className="separator"></span>
                    <div className="project-summary-right-column">
                        <div className="title">Project details</div>
                        {project.type === 'VX_SERVICE' ? <>
                            <div className="label-wrapper">
                                <span>Type</span>
                                <div className="template-label">vX Service</div>
                            </div>
                            <div className="label-wrapper">
                                <span>Client</span>
                                <div className="client-label"
                                     onClick={navigateToClient}>{project.organizationName}</div>
                            </div>
                        </> : <div className="label-wrapper">
                            <span>Type</span>
                            <div className="type-default">Default</div>
                        </div>
                        }
                        <div>
                            {project.id && getStatusForm()}
                        </div>
                        {getOwnerSelect(showOwnerDropdown, "", "label-wrapper", "label-wrapper")}
                    </div>
                </div>}
        </div>
    </div>
}

export default ProjectSummary;
