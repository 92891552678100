import {
    AssignmentApi,
    Configuration,
    DeviceApi,
    DistributedQueriesApi,
    DistributedQueryResultsApi,
    FileApi,
    ScanResultsApi,
    OrganizationApi,
    OSQueryApi,
    ScanApi,
    UserApi,
    AuthApi,
    ProjectApi,
    TaskApi,
    TaskPhaseApi,
    TaskCommentApi,
    TemplateApi, NotifierApi,
    TemplateTasksApi,
    TemplateTaskPhasesApi,
    ProjectCommentApi,
    DocumentApi,
    OrganizationsGroupsApi,
    GroupsApi,
    DocumentCommentApi,
    TasksLinkageApi,
    TemplateTasksLinkageApi,
    NotificationsApi,
    OrganizationsLinkageApi
} from "@violetx-mvp/api-service-client";

import axios from 'axios';

const axiosApiInstance = axios.create();
axiosApiInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    if ((error.response?.status === 401 || error.response?.status === 403) && !originalRequest._retry) {
        originalRequest._retry = true;
        await window.authInstance.refreshToken();
        await window.authInstance.storeToken();
        originalRequest.headers['Authorization'] = 'Bearer ' + window.authInstance.keycloak.token;
        return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
});


export default class ApiClient {

    getWrapperApi(api, config) {
        return new api(config, process.env.REACT_APP_API_SERVICE_URL, axiosApiInstance);
    }

    constructor(config) {
        this.authApi = this.getWrapperApi(AuthApi, config);
        this.userApi = this.getWrapperApi(UserApi, config);
        this.filesApi = this.getWrapperApi(FileApi, config);
        this.devicesApi = this.getWrapperApi(DeviceApi, config);
        this.scansApi = this.getWrapperApi(ScanApi, config);
        this.queriesApi = this.getWrapperApi(OSQueryApi, config);
        this.distributedQueryResultsApi = this.getWrapperApi(DistributedQueryResultsApi, config);
        this.distributedQueriesApi = this.getWrapperApi(DistributedQueriesApi, config);
        this.scanResultsApi = this.getWrapperApi(ScanResultsApi, config);
        this.orgsApi = this.getWrapperApi(OrganizationApi, config);
        this.assignmentApi = this.getWrapperApi(AssignmentApi, config);
        this.projectApi = this.getWrapperApi(ProjectApi, config);
        this.tasksApi = this.getWrapperApi(TaskApi, config);
        this.taskPhaseApi = this.getWrapperApi(TaskPhaseApi, config);
        this.taskCommentApi = this.getWrapperApi(TaskCommentApi, config);
        this.templateApi = this.getWrapperApi(TemplateApi, config);
        this.notifierApi = this.getWrapperApi(NotifierApi, config);
        this.templateTasksApi = this.getWrapperApi(TemplateTasksApi, config);
        this.templateTaskPhasesApi = this.getWrapperApi(TemplateTaskPhasesApi, config);
        this.projectCommentApi = this.getWrapperApi(ProjectCommentApi, config);
        this.documentApi = this.getWrapperApi(DocumentApi, config);
        this.documentCommentApi = this.getWrapperApi(DocumentCommentApi, config);
        this.organizationsGroupsApi = this.getWrapperApi(OrganizationsGroupsApi, config);
        this.groupsApi = this.getWrapperApi(GroupsApi, config);
        this.tasksLinkageApi = this.getWrapperApi(TasksLinkageApi, config);
        this.templateTaskLinkageApi = this.getWrapperApi(TemplateTasksLinkageApi, config);
        this.notificationsApi = this.getWrapperApi(NotificationsApi, config);
        this.vendorsApi = this.getWrapperApi(OrganizationsLinkageApi, config);
    }

    getConfig() {
        return new Configuration({
            accessToken: localStorage.getItem('react-token'),
            basePath: process.env.REACT_APP_API_SERVICE_URL,
            baseOptions: {
                headers: { Authorization: `Bearer ${localStorage.getItem('react-token')}` }
            }
        });
    }
    getConfigImage(){
        return new Configuration({
            accessToken: localStorage.getItem('react-token'),
            basePath: process.env.REACT_APP_API_SERVICE_URL,
            baseOptions: {
                headers: { Authorization: `Bearer ${localStorage.getItem('react-token')}`, 'ContentType': 'multipart/form-data'}
            }
        });
    }
    getImage(){
        const config = this.getConfigImage();
        return new ApiClient(config);
    }

    get() {
        const config = this.getConfig();
        return new ApiClient(config);
    }
}
