export const initialState = {
    groupsList: [],
    selectedGroupsKeys: [],
    searchValue: ''
}

export const ACTIONS = {
    GROUPS_LIST: 'groupsList',
    SELECTED_GROUPS_KEYS: 'selectedGroupsKeys',
    SEARCH_VALUE: 'searchValue',
    RESET_STATE: 'resetState'
}

export function groupsReducer(state, action) {
    switch (action.type) {
        case ACTIONS.GROUPS_LIST:
            return {
                ...state,
                groupsList: action.payload,
            };
        case ACTIONS.SELECTED_GROUPS_KEYS:
            return {
                ...state,
                selectedGroupsKeys: action.payload,
            };
        case ACTIONS.SEARCH_VALUE:
            return {
                ...state,
                searchValue: action.payload,
            };
        case ACTIONS.RESET_STATE:
            return action.payload;
            default:
                throw Error('Unknown action.');
    }
}