import './searchableUsersFilter.css';
import {Checkbox, Dropdown, Input, Tooltip} from "antd";
import {DownOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import EmptyImage from "../../assets/images/searchable-filter-empty.svg";
import {UserAvatar} from "../userAvatar/userAvatar";

const SearchableUsersFilter = ({ onChange, users, ubersAdmins, label, clearSelectedItems, className, defaultSelected }) => {

    const [isDropdownActive, setIsDropdownActive] = useState(false);
    const { Search } = Input;
    const [searchValue, setSearchValue] = useState('');
    const [searchFilter, setSearchFilter] = useState('');
    const [usersList, setUsersList] = useState(users.filter(u => defaultSelected?.includes(u.id.toString())) ?? []);

    useEffect(() => {
        onChange(usersList.map(o => o.id));
    }, [usersList]);

    useEffect(() => {
        if (clearSelectedItems) {
            setUsersList([]);
        }
    }, [clearSelectedItems]);

    const getDropdownCssClassName = (selected, dropdown) => {
        const baseClassName = 'dropdown';
        const activeClass = isDropdownActive === dropdown ? 'active' : '';
        const selectedClass = selected ? 'selected' : '';
        return `${baseClassName} ${activeClass} ${selectedClass}`;
    };

    const getFilterText = (value) =>{
        return value.length > 1 ? `${value[0]}+${value.length -1}` : value[0];
    }

    const filterOptionsIfNeeded = (items) => {
        return searchFilter.length === 0
            ? items
            : items.filter(u => u.name.toLowerCase().includes(searchFilter.toLowerCase()));
    }

    const onCheckboxChange = async (item, checked) => {
        const usersListCopy = JSON.parse(JSON.stringify(usersList));
        if (checked) {
            usersListCopy.push(item);
            setUsersList(usersListCopy);
        } else {
            const filtered = usersListCopy.filter(el => el?.id !== item.id);
            setUsersList(filtered);
        }
    }

    const handleSearchValue = (e) => {
        if (e.key === 'Enter') {
            e.stopPropagation();
            return;
        }
        setSearchValue(e.target.value);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.stopPropagation();
        }
    }

    const onSearch = () => {
        setSearchFilter(searchValue);
    }

    const trimLabelIfNeeded = (text) => {
        if (label === "Owner") {
            text = text.substring(0, 27) + '...';
        } else {
            text = text.substring(0, 13) + '...' + text.substring(text.length - 14, text.length);
        }
        return text;
    }

    const usersToDisplay = () => {

        const merged = users.concat(ubersAdmins);
        merged.sort((a, b) => a.name.localeCompare(b.name));

        merged.sort((a, b) => {
            const aInUsersList = usersList.find(el => el?.id === a.id);
            const bInUsersList = usersList.find(el => el?.id === b.id);
            if (aInUsersList && !bInUsersList) {
                return -1;
            } else if (!aInUsersList && bInUsersList) {
                return 1;
            } else {
                return 0;
            }
        });
        return <div className={`searchable-users-filter-wrapper searchable-users-filter-data ${className}`}>
            <div className="searchable-users-filter-search">
                <Search placeholder="Search" style={{ height: 24}}
                        data-testid="options-search"
                        onSearch={onSearch} value={searchValue}
                        onChange={handleSearchValue}
                        onKeyDown={handleKeyDown}
                />
            </div>
            {filterOptionsIfNeeded(merged).length === 0 && <div className="no-users">
                <div>
                    <img src={EmptyImage} alt="No matches found"/>
                </div>
                <div>
                    No matches found
                </div>
            </div>}
            <div className="dropdown">
                {
                    filterOptionsIfNeeded(merged).map(u => {
                        return <div className="user-item" key={u.id}>
                            <Checkbox data-testid={`checkbox-${u.id}`} checked={usersList.find(el => el?.id === u.id)} onChange={(e) => onCheckboxChange(u, e.target.checked)}/>
                            {u.fileName !== undefined && <UserAvatar size={26} name={u.name} fileName={u.fileName}/>}
                            {u.name.length >= 30 && <Tooltip title={u.name}>
                                <span className="name" title={u.name}>{trimLabelIfNeeded(u.name)}</span>
                            </Tooltip>}
                            {u.name.length < 30 &&
                                <span className="name" title={u.name}>{u.name}</span>
                            }
                        </div>
                    })}
            </div>
        </div>
    }

    return <div className="searchable-users-filter">
        <Dropdown
            trigger={['click']}
            overlayStyle={{minWidth: '260px', maxWidth: '290px'}}
            placement={"bottomRight"}
            dropdownRender={() => usersToDisplay()}
            onOpenChange={(e) => {
                setIsDropdownActive(e ? 'requested' : false)
                if (e) {
                    setSearchFilter('');
                    setSearchValue('');
                }
            }}
            defaultValue={defaultSelected || []}
        >
            <div data-testid="searchable-users-filter-dropdown"
                 className={getDropdownCssClassName(usersList.length !== 0, 'requested')}>
                <span>{`${label}${usersList.length > 0 ? ': ' + getFilterText(usersList.map(u => u?.name)) : ''}`}</span>
                <DownOutlined/>
            </div>
        </Dropdown>
    </div>
}

export default SearchableUsersFilter;




